export enum MaterialType {
    GOLD18 = 0,
    GOLD22 = 1,
    SILVER = 2,
    PLATINUM = 3,
    GOLD24 = 4,
    GOLD_OTHERS = 5,
    SILVER_999 = 6
  }
  
  export const MaterialTypeNames: { [key: string]: MaterialType } = {
    "18k": MaterialType.GOLD18,
    "22k": MaterialType.GOLD22,
    "sil": MaterialType.SILVER,
    "Platinum": MaterialType.PLATINUM,
    "24k": MaterialType.GOLD24,
    "other": MaterialType.GOLD_OTHERS,
    "999": MaterialType.SILVER_999
  };

  export function getMaterialTypeFromString(typeString: string): MaterialType | undefined {
    return MaterialTypeNames[typeString];
  }
