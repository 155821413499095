import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {
  constructor(private http: HttpClient) { }

  uploadImages(images: File[]): Observable<HttpEvent<any>> {
    const formData = new FormData();
    images.forEach(image => formData.append('images', image));

    const req = new HttpRequest('POST', 'https://freeimage.host/api/1/upload', formData, {
      reportProgress: true
    });

    return this.http.request(req);
  }
}
