<div class="overall-container" style="height:90vh; width:100%; overflow-y:scroll;">
  <div class="div">
    <h3 class="back_title" style="margin-left: 20px;" (click)="back()">
      <mat-icon class="right_back">arrow_back_ios</mat-icon>
      Create Estimation
    </h3>
  </div>
  <div>
    <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event)">

      <mat-tab label="Create New Estimate">
        <div class="create-container"
          style="justify-content: center; align-items: center; flex-direction: column;">
          <mat-card class="blue-background" style="box-shadow: none; margin-top: 20px; margin-left: 20px; margin-right: 20px;">
            <mat-card-content
              style="align-items: center; justify-content: center; display: flex; flex-direction: column;">
              <p style="font-family: 'Rubik'; font-weight: 400; font-size: 12px; color: #DFDFDF; margin-top: 0px;">
                Rate/gm:</p>
              <input type="text" class="form-control"
                style="width: 150px; background-color: #FFFFFF17; color: white; border-color: #FFFFFF17; align-items: center; text-align: center;"
                [(ngModel)]="rate" (change)="calculateWastage()" />
            </mat-card-content>
            <div style="display: flex; justify-content: center; align-items: center; justify-content: space-evenly;">
              <mat-card-content style="width: 45%; background-color: white; border-radius: 8px;">
                <div class="form-control"
                  style="width: 100%; display: flex; flex-direction: row; text-align: center; align-items: center;"
                   [matMenuTriggerFor]="purityMenu" #menuTrigger="matMenuTrigger">
                  <div style="align-items: center;">
                    <span
                    style="font-family: 'Rubik'; font-weight: 500; color: #606060; font-size: 14px; text-align: center; align-items: center;">
                    {{ this.selected_metal == 0 ? 'Gold' + '' : this.selected_metal == 2 ? 'Silver'+'' :
                    'Platinum'+'' }}
                  </span>
                  <span
                    style="font-family: 'Rubik'; font-weight: 500; color: #606060; font-size: 14px; text-align: center; align-items: center;"
                    *ngFor="let purity of metal_purity[activePurity]">
                    {{ selected_purity == purity.id ? purity.name : '' }}
                  </span>
                  </div>
                  <mat-menu #purityMenu="matMenu" class="metal-menu">
                    <div class="metal_purity_menu">
                      <div class="d-flex justify-content-between">
                        <div class="modal-body">
                          <div class="grid-container" style="display: flex; align-items: start; justify-items: start;">
                            <div class="metals">
                              <mat-button-toggle-group class="mat-elevation-z0" style="flex-direction: column; border:none; width: 100%;">
                                <div *ngFor="let mp of metals; let i = index" style="display: flex; flex-direction: row;">
                                  <div [ngClass]="{'divider': mp === selected_metal}" style="width: 1px; height: 50px; background-color: white;"></div>
                                  <mat-button-toggle class="mat-elevation-z0 d-flex justify-content: start" style="width: 100%; text-align: start; border: none;" (click)="$event.stopPropagation(); getSelectedMetal(i)">
                                    {{ mp == 0 ? 'Gold' : mp == 2 ? 'Silver' : 'Platinum' }}
                                  </mat-button-toggle>
                                </div>
                              </mat-button-toggle-group>
                            </div>
                            <div style="width: 1px; height: 250px; background-color: #DFDFDF;">
                            </div>
                            <div class="purities" style="margin-left: 10px; margin-right: 20px;">
                              <mat-radio-group aria-label="Select an option">
                                <mat-radio-button
                                  style="align-items: start; display: flex; flex-direction: column; margin-top: 15px;"
                                  *ngFor="let p of metal_purity[activePurity]" [value]="selected_purity != null ? selected_purity?.toString() : '0'"
                                  (click)="getSelectedPurity(p['id'])">
                                  {{ p['name'] }}
                                </mat-radio-button>
                              </mat-radio-group>
                            </div>
                          </div>                      
                        </div>
                      </div>
                    </div>
                  </mat-menu> 
                </div>
              </mat-card-content>
              <mat-card-content style="background-color: white; border-radius: 8px; width: 45%; height: 40px; position: relative; text-overflow: ellipsis; overflow: hidden;">
                <div class="form-control">
                  <div *ngIf="!imageUrl && !product_link" (click)="openModal('imageUpload')"
                    style="align-items: center; justify-content: center; flex-direction: row; display: flex; flex-direction: row;">
                    <span *ngIf="!imageUrl && !product_link"
                      style="font-family: 'Rubik'; font-weight: 500; color: #606060; font-size: 14px;">
                      Add Image
                    </span>
                    <mat-icon *ngIf="!imageUrl && !product_link" style="color: #4070F4;">add</mat-icon>
                  </div>
                  <img *ngIf="imageUrl" [src]="imageUrl" alt="Selected Image"
                    style="height: 25px; width: 25px; border-radius: 4px;" />
                    <span *ngIf="product_link" style="width: 70%; height: auto; overflow-x: hidden; text-overflow: ellipsis;">
                      {{ product_link ? product_link : '' }}
                    </span>         
                  <button *ngIf="imageUrl || product_link" (click)="removeImage()"
                    style="position: absolute; top: 0; right: 0; border: none; background-color: white;">
                    <mat-icon style="color: #4070F4;">close</mat-icon>
                  </button>
                </div>
              </mat-card-content>
            </div>
            <mat-card-content
            style="align-items: center; justify-content: center; display: flex; flex-direction: column;">
            <button *ngIf="product_link" (click)="viewImage()" style="align-items: center; justify-content: center; display: flex; flex-direction: column; height: 35px; background-color: white; color: #606060; font-size: small; border: none; border-radius: 5px;">
              <span>View Image</span>
            </button>
          </mat-card-content>
          </mat-card>
          <div class="estimation-box" style="margin-top: 20px; margin-left: 20px; margin-right: 20px;">
            <table>
              <thead>
                <tr>
                  <th>
                    Details
                  </th>
                  <th>
                    Values
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Gross Weight (Gms)
                  </td>
                  <td><input #grossWeightInput id="gross-wt-id" type="number" class="form-control"
                      style="border-bottom: 1px dashed #6e668a44 !important; border: none;" [(ngModel)]="gross_wt"
                      [autofocus]="true" (change)="updateGrossWeight(gross_wt)" /></td>
                </tr>
                <tr>
                  <td>
                    Net Weight (Gms)*
                  </td>
                  <td><input #nettWeightInput type="number" class="form-control"
                      style="border-bottom: 1px dashed #6e668a44 !important; border: none;" [(ngModel)]="nett_wt"
                      (change)="updateNetWeight(nett_wt)" (keydown.tab)="openModal('wastagePopup')" /></td>
                </tr>
                <tr>
                  <td>
                    Wastage
                  </td>
                  <td><input #wastageInput type="number" class="form-control"
                      style="border-bottom: 1px dashed #6e668a44 !important; border: none;" [(ngModel)]="wastage"
                      (change)="calculateWastage()" (click)="openModal('wastagePopup')"
                      focus="openModal('wastagePopup')" />
                  </td>
                </tr>
                <tr>
                  <td>
                    Making Charge
                  </td>
                  <td><input #makingChargeInput type="number" class="form-control"
                      style="border-bottom: 1px dashed #6e668a44 !important; border: none;" [(ngModel)]="making_charge"
                      (change)="calculateMakingCharge()" (click)="openModal('makingChargePopup')" />
                  </td>
                </tr>
                <tr>
                  <td>
                    Stone Cost
                  </td>
                  <td><input #stoneInput type="number" class="form-control"
                      style="border-bottom: 1px dashed #6e668a44 !important; border: none;" [(ngModel)]="stone"
                      (change)="addStoneCharge(stoneCount)" (click)="openModal('addStoneChargePopup')"
                      (click)="stoneData.length == 0 ? addRow() : null" /></td>
                </tr>
                <tr>
                  <td>
                    GST % (Optional)
                  </td>
                  <td><input type="number" class="form-control"
                      style="border-bottom: 1px dashed #6e668a44 !important; border: none;" [(ngModel)]="gst"
                      (change)="calculateTotal(gst)" /></td>
                </tr>
                <tr>
                  <td>
                    Total (₹)
                  </td>
                  <td><input type="number" class="form-control"
                      style="border-bottom: 1px dashed #6e668a44 !important; border: none;" [(ngModel)]="total" /></td>
                </tr>
              </tbody>
            </table>

            <div style="display: flex; align-items: start; justify-content: start;">
              <button type="button" class="clear-all-btn" (click)="clearAll()">Clear All</button>
            </div>
          </div>
          <div class="save-est-cont" *ngIf="!is_share_visible" style="display: flex; margin-bottom: 100px; justify-content: center; align-items: center;">
            <button type="button" [ngClass]="total != 0 && total ? 'save-est-btn' : 'save-est-btn-disabled'"
              [disabled]="!total && total ==0" (click)="createEstimate()">Save
              Estimate</button>
          </div>
          
          <ng-template #successModal let-modal>
            <div class="save-est-cont" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
              <mat-icon style="color: #4070F4;">check_circle</mat-icon>
              <h3 style="font-family: 'Rubik'; font-weight: 500; color: #606060; font-size: 14px; margin-top: 30px;">Estimate
                created Successfully</h3>
              <div style="position: relative; margin-bottom: 20px; width: 90%;">
                <input matInput class="form-control" style="width: 90%; height: 48px; padding-right: 30px; background-color: white;" [(ngModel)]="share_url" [disabled]="true" />
                <button style="position: absolute; top: 0; right: 0; bottom: 0; background-color: transparent; border: none;" (click)="copyShareUrl(share_url)">
                  <mat-icon style="color: #4070F4;">file_copy</mat-icon>
                </button>
              </div>
              <button type="button" class="add-making-btn" style="margin-bottom: 20px;"
                (click)="clearAll()">Create
                New</button>
            </div>
          </ng-template>

          <ng-template #wastagePopup let-modal>
            <div class="modal-header" (keydown.enter)="calculateWastage()">
              <h3 class="modal-title" id="wastagePopupLabel" style="margin-top: 10px;">Wastage %</h3>
              <button type="button" style="outline: none;" class="close btn" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="wastage-options">
                <mat-button-toggle-group [(value)]="selectedWastageType">
                  <mat-button-toggle *ngFor="let wastage_type of wastage_types" [value]="wastage_type.value"
                    (click)="chooseWastage(wastage_type.value)">
                    {{ wastage_type.name }}
                  </mat-button-toggle>
                </mat-button-toggle-group>
                <br><br>
              </div>
              <input #wastageChargeTypeInput [autofocus]="true" class="wastage-input" type="number" [(ngModel)]="wastage"
                (keydown.enter)="calculateWastage()" />
            </div>
            <div class="modal-footer" style="justify-content: center; margin-top: 20px;">
              <button type="button" class="add-wastage-btn" (click)="calculateWastage()">Save</button>
            </div>
          </ng-template>

          <ng-template #makingChargePopup let-modal>
            <div class="modal-header" (keydown.enter)="calculateMakingCharge()">
              <h3 class="modal-title" id="makingChargePopupLabel" style="margin-top: 10px;">Making Charge</h3>
              <button type="button" style="outline: none;" class="close btn" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="making-charge-options" style="margin-bottom: 10px;">
                <mat-button-toggle-group [(value)]="selectedMakingChargeType">
                  <mat-button-toggle *ngFor="let making_type of making_charge_types" [value]="making_type.value"
                    (click)="chooseMakingCharge(making_type.value)">
                    {{ making_type.name }}
                  </mat-button-toggle>
                </mat-button-toggle-group>
                <br><br>
                <!-- <label for="wastage-choice">{{ selectedMakingChargeType }}</label> -->
              </div>
              <input type="number" [autofocus]="true" class="making-input" id="making-charge-input-id"
                [(ngModel)]="making_charge" (keydown.enter)="calculateMakingCharge()" />
            </div>
            <div class="modal-footer" style="justify-content: center; margin-top: 20px;">
              <button type="button" class="add-making-btn" (click)="calculateMakingCharge()">Save</button>
            </div>
          </ng-template>


          <ng-template #addStoneChargePopup let-modal>
            <div class="modal-header">
              <h3 class="modal-title" id="addStoneChargeLabel" style="margin-top: 10px;">Stone Cost</h3>
              <button type="button" style="outline: none;" class="close btn" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" (keydown.shift.enter)="addRow()" (keydown.cntrl.s)="saveStoneCharges(stoneCount)">
              <table class="table">
                <tbody *ngFor="let stone of stoneData; let i = index" style="margin: 10px; border-radius: 10px;">
                  <tr>
                    <th>
                      Stone Name
                    </th>
                    <td><input #stoneNameInput type="text" style="border-bottom: 1px dashed #6e668a44 !important; border: none;" class="stone-form-control"
                        [(ngModel)]="stone.name" /></td>
                  </tr>
                  <tr>
                    <th>
                      Quantity
                    </th>
                    <td><input style="border-bottom: 1px dashed #6e668a44 !important; border: none;" type="number" class="stone-form-control" [(ngModel)]="stone.quantity" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Weight
                    </th>
                    <td><input style="border-bottom: 1px dashed #6e668a44 !important; border: none;" type="number" class="stone-form-control" [(ngModel)]="stone.weight" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Carat
                    </th>
                    <td><input style="border-bottom: 1px dashed #6e668a44 !important; border: none;" type="number" class="stone-form-control" [(ngModel)]="stone.carat" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Rate
                    </th>
                    <td>
                      <div class="input-group" style="display: inline-flexbox;">
                        <input [autofocus]="true" style="border-bottom: 1px dashed #6e668a44 !important; border: none;" class="stone-form-control"
                          [(ngModel)]="stone.rate" (keydown.enter)="addStoneCharge(stoneCount)" (change)="addStoneCharge(stoneCount)"/>
                          <select class="category-box" [(ngModel)]="stone.charge_type"
                          (keydown.enter)="addStoneCharge(stoneCount)" style="padding: 0px;">
                          <option *ngFor="let stone_charge of stone_charge_types" [value]="stone_charge.value">
                            {{ stone_charge.name }}
                          </option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Amount
                    </th>
                    <td><input type="number" style="border-bottom: 1px dashed #6e668a44 !important; border: none;" class="stone-form-control"
                        (focus)="addStoneCharge(stoneCount)" [(ngModel)]="stone.cost" />
                    </td>
                  </tr>
                  <td *ngIf="i > 0">
                    <mat-icon style="color: #4070F4;" type="button" class="delete-stone-row"
                      (click)="removeRow(i)">delete</mat-icon>
                  </td>
                </tbody>
              </table>
              <div>
                <button type="button" class="add-stone-row" (click)="addRow()">+ ADD ITEM</button>
              </div>
            </div>
            <div class="modal-footer" style="justify-content: center; margin-top: 20px;">
              <button type="button" class="add-stone-btn" (click)="saveStoneCharges(stoneCount)">Save</button>
            </div>
            <!-- </div>
            </div>
          </div> -->
          </ng-template>

          <ng-template #imageUpload let-modal>
            <div style="background-color: white; display: flex; flex-direction: column; align-items: start; justify-content: center;">
              <div style="width: 65px; height: 3px; background-color: #606060; border-radius: 8px; display: flex; align-items: center; justify-content: center; margin: 0 auto;"></div>
              <div>
                <div style="margin: 20px; margin-top: 30px; display: flex; align-items: center; justify-content: center;" (click)="fileInput.click()">
                  <mat-icon style="color: #4070F4; size: 10px; margin-right: 10px;">
                    image
                  </mat-icon>
                  <span style="font-family: 'Rubik'; font-weight: 400; color: #606060; font-size: 14px;">Upload from Gallery</span>
                  <input type="file" #fileInput accept="image/jpeg, image/png, image/webp" style="display: none;" (change)="onFileSelected($event)" />
                </div>
                <!-- <div style="margin: 20px; margin-top: 30px; display: flex; align-items: center; justify-content: start;" (click)="openCamera()">
                  <mat-icon style="color: #4070F4; size: 10px; margin-right: 10px;">
                    camera_alt
                  </mat-icon>
                  <span style="font-family: 'Rubik'; font-weight: 400; color: #606060; font-size: 14px;">Take a Picture</span>
                  <input type="file" accept="image/jpeg, image/png, image/webp" style="display: none;" capture="camera" (change)="onFileSelected($event)"/>
                </div> -->
                <div style="margin: 20px; margin-top: 30px; display: flex; align-items: center; justify-content: start;" (click)="openModal('attachImageLink')">
                  <mat-icon style="color: #4070F4; size: 10px; margin-right: 10px;">
                    link
                  </mat-icon>
                  <span style="font-family: 'Rubik'; font-weight: 400; color: #606060; font-size: 14px;">Image Url</span>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template #attachImageLink let-modal style="margin-right:  5px;">
            <!-- Modal Body -->
            <div style="background-color: white; border-radius: 16px, 16px, 0px, 0px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
              <div style="width: 65px; height: 3px; background-color: #606060; border-radius: 8px;"></div>
              <div class="form-group" style="width: 90%; margin: 20px;">
                <input type="text" style="height: 48px;" class="form-control" placeholder="Enter or Paste Image URL" id="imageUrl" [(ngModel)]="product_link" (change)="saveImageUrl(product_link)">
                <button style="margin-top: 20px; width: 90%; align-items: center; text-align: center;" type="button" class="save-est-btn" (click)='onFileSelected($event)'>Link</button>
              </div>
            </div>
          </ng-template>

        </div>
      </mat-tab>

      <mat-tab label="View Estimates" style="overflow: auto; height: 100vh;">
        <div class="view_estimations" *ngIf="estimationList?.count">
          <mat-nav-list style="margin-left: 20px; margin-right: 20px;">
            <mat-list-item style="border: #9F9F9F; border-radius: 10px; background-color: white; margin-top: 20px;"
              *ngFor="let est of estimationList?.results; let i=index">
              <div class="col" style="width: 100%; display: flex; flex-direction: column;">
                <div class="col"
                  style="display: flex; flex-wrap: wrap; flex-direction: row; justify-content: space-between;">
                  <span mat-line style="font-family: 'Rubik'; font-weight: 500; color: #606060; font-size: 14px;">
                    {{ est?.estimation_no }}
                  </span>
                  <span mat-line style="font-family: 'Rubik'; font-weight: 500; color: #606060; font-size: 14px;">
                    {{ est?.date | date: 'dd/MM/yyyy' }}
                  </span>
                  <div [matMenuTriggerFor]="menu"> <!-- Add a trigger here -->
                    <mat-icon>more_vert</mat-icon>
                  </div>
                </div>
                <span (click)="copyShareUrl(est.estimation_share_url)" mat-line
                  style="width: 90%; font-family: 'Rubik'; font-weight: 500; color: #4070F4; font-size: 14px; overflow: hidden;">
                  {{ est?.estimation_share_url }}
                </span>
              </div>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="viewEstimate(est?.id)">View</button>
                <button mat-menu-item (click)="deleteEstimate(est?.id)">Delete</button>
              </mat-menu>
            </mat-list-item>

          </mat-nav-list>
        </div>
        <div class="no-designs-container md-5 align-items-center"
          style="align-items: center; justify-content: center; display: flex; flex-direction: column; margin: 20px;"
          *ngIf="!estimationList?.count">
          <img class="no_designs_illus_img" src="assets/images/no_designs_illus.png" alt="No Designs Banner">
          <h1>No estimations found</h1>
        </div>
      </mat-tab>

    </mat-tab-group>

  </div>
</div>