import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { PopUpManager } from 'src/app/manager/Popup';
import { ResponseMessage } from 'src/app/models/message';
import { CustomerDataSource } from './customer-details.dataSource';
import { CustomerdetailsService } from './customerdetails.service';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from "@angular/common";
import { HostListener } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { AnalyticService } from '../services/analytics.service';
import { TitleService } from '../services/title.service';
import { ActionService } from 'src/app/services/actions.service';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css'],
})
export class CustomerDetailsComponent implements OnInit {

  date = formatDate(new Date(), 'dd/MM/yyyy', 'en-US');
  id = "";
  displayedColumns: string[] = [
    'first_name',
    'phone',
    'dob',
    'email',
    'gender',
    'date_joined',
    'address',
    'pincode',
    'state',
    'kebab'
  ];
  allowed!:any;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  dataSource!: CustomerDataSource;
  responseMessage!: ResponseMessage;
  popUpManager!: PopUpManager;
  status = '';
  search = '';
  email = '';
  f_date: string = "";
  t_date: string = "";
  download_from_date = "";
  download_to_date = "";
  ordering: string = '-id';
  @ViewChild(MatSort, { static: true })
  sort!: MatSort;
  search_disable!: boolean;
  constructor(
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private _location: Location,
    private customerDetailService: CustomerdetailsService,
    private router: Router,
    private toastr: ToastrService,
    private dateAdapter: DateAdapter<Date>,
    private analytics: AnalyticService,
    private titleService:TitleService,
    private actionService : ActionService,

  ) {
    this.dateAdapter.setLocale('en-IN');
  }

  searchOption() {
    this.search = this.search.replace(/\s+/g, '');
    this.resetAndDisplayTable();
  }
  fetchStatus(value: any) {
    this.status = value;
    this.resetAndDisplayTable();
  }
  clearInput() {
    this.search = "";
  }

  back() {
    this._location.back();
  }

  showWarning(content: any, id: any) {
    this.id = id;
    this.modalService.open(content, { centered: true });
  }
    handleRouteEvents() {
    gtag('event', 'page_view', {
      page_title: "Customer Page",
      page_path: "/dashboard/customer-details",
    })
}

  ngOnInit(): void {
    this.handleRouteEvents();
    this.allowed=this.actionService.getActions();
    this.actionAllowed();
    // this.analytics.logPageView(this.router.url);
    this.titleService.setTitle('Customers');
    this.dataSource = new CustomerDataSource(this.customerDetailService);
    this.dataSource.loadData(0, this.search, this.ordering, this.status);
    this.popUpManager = new PopUpManager(this.snackBar);
    // this.analytics.logEvent("Customer Detail Page Loaded", {})
    // localStorage.setItem('title',"Customer")

  }

  
 actionAllowed()
 {
   if (this.actionsCheck(34))
   {
     this.search_disable=false;
   }
   else
   {
     this.search_disable=true;
   }

 }
  //Download Model from Date and To date
  downloadfDate(event: any) {
    this.f_date = moment(event).format("DD-MM-YYYY");
    this.download_from_date = moment(event).format('YYYY-MM-DD');

  }
  downloadtDate(event: any) {
    this.t_date = moment(event).format("DD-MM-YYYY");
    this.download_to_date = moment(event).format('YYYY-MM-DD');

  }
  //end

  ngAfterViewInit(): void {
    this.dataSource.counter$.subscribe((count) => {
      this.paginator.length = count;
    });
    this.paginator.page.subscribe(() => {
      this.popUpManager.openSnackBar('Loading', 'cancel');

      this.dataSource.loadData(
        this.paginator.pageIndex,
        this.search,
        this.ordering,
        this.status
      );
    });
  }
  sortbyId() {
    if (this.ordering == 'id') {
      this.ordering = '-id';
      this.resetAndDisplayTable();
    } else {
      this.ordering = 'id';
      this.resetAndDisplayTable();
    }
  }

  resetAndDisplayTable() {
    this.popUpManager.openSnackBar('Loading', 'cancel');

    this.paginator.pageIndex = 0;
    this.dataSource.loadData(0, this.search, this.ordering, this.status);
  }

  navAndEdit(id: any) {
    this.analytics.logEvent("Customer Edit using Kebab", {})
    this.router.navigate(['/dashboard/new-customer/', { id: id }]);
  }

  generateCsvData(data: any[]): string {
    var csvData = '';
  
  
    // Add the column names to the CSV data
    for (const key of Object.keys(data[0])) {
      csvData += `${key}\t`;
    }
    csvData += '\n';
    // Iterate over the data and add it to the CSV string
    for (const row of data) {
      for (const key in row) {
        csvData += `${row[key]},`;
      }
      csvData += '\n';
    }
    return csvData;
  }
  
  // Download the CSV data
  downloadCsvData(data:any) {
    // Get the three months of data
   
  
    // Generate the CSV data
    const csvData = this.generateCsvData(data);
  
    // Create a Blob object from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });
  
    // Create a URL object from the Blob object
    const url = URL.createObjectURL(blob);
  
    // Create a new anchor element and set its href attribute to the URL object
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = 'customers.csv';
  
    // Click the anchor element to start the download
    anchorElement.click();
  }
  
  



  fileDownload() {


    this.analytics.logEvent("Customer Pressed Download", {})

    if (this.f_date == "") {
      this.toastr.info("", "Please do select from date");
      return;
    } else if (this.t_date == "") {
      this.toastr.info("", "Please do select to date");
      return;

    } 

    const fromDate = moment(this.download_from_date);
    const toDate = moment(this.download_to_date);
    const differenceInDays = toDate.diff(fromDate, 'days');

    if(differenceInDays>90){
      this.toastr.info(
        '',
        'You can download only 90 days of data.please do select from and to date within 90 days.',
        { positionClass: 'toast-bottom-right' }
      );
      return;
    }



    this.modalService.dismissAll();
    this.toastr.info(
      '',
      'Downloading...',
      { positionClass: 'toast-bottom-right' }
    );

    this.customerDetailService.downloadCustomerData(this.f_date, this.t_date, this.email).subscribe(
      (data:any) => {
        this.responseMessage = data;

        var final_data=data['data'];
        if(final_data.length==0){
          this.toastr.info(
            '',
            'No data found in the selected duration.',
            { positionClass: 'toast-bottom-right' }
          );
        }else{
          this.downloadCsvData(final_data);
          this.toastr.success('', this.responseMessage.message, {
            positionClass: 'toast-bottom-right',
          });
          this.analytics.logEvent("Customer Detail Data Successfully Sent", {});
        }

      },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );

  }
  download(content: any) {
    this.modalService.open(content, { centered: true });
  }

  deleteCustomer() {
    this.customerDetailService.deleteCustomerService(this.id).subscribe((data) => {
      this.responseMessage = data;
      this.toastr.success('', "Delete Customer Successfully",
        {
          positionClass: "toast-bottom-right",
        });
      window.setTimeout(() => { location.reload() }, 1000)
    },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );

  }

  actionsCheck(value:any)
  {
    if (this.allowed=="admin")
    {
      return true;
    }
    if(this.allowed.includes(value))
    {

      return true;

    }
    else{
      return false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalService.dismissAll()
    }
  }


  @HostListener('window:keydown.control.e', ['$event'])
  handleDeleteKeyboard(event: KeyboardEvent) {

    if (event.ctrlKey && event.key == "e") {
      event.preventDefault();
      this.router.navigate(['/dashboard/new-customer/']);

    }
  }
}



