<ng-container *ngTemplateOutlet="show_all_images" >
    
</ng-container>


<ng-template #show_all_images let-modal >
    <div class="show_all_images" style="size: xl;">
      <div class="modal-header px-5 py-1">
        <!-- <mat-icon class="back-arrow px-3" (click)="closeModal()" style="cursor: pointer;">keyboard_arrow_left</mat-icon> -->
        <h3 class="modal-title mt-3">All Images</h3>
        <button type="button" style="outline: none;" class="close btn" aria-label="Close"
          (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center px-3">
        <!-- <h3>All Images</h3> -->
        <div *ngIf="selectedImage">
          <img [src]="selectedImage" alt="Selected Image" style="width: 50%; height: 50%;">
        </div>
        <br>
        <div class="row px-5">
            <div class="col-3 py-2" *ngFor="let fileAsset of filesAssets">
                <img [src]="fileAsset.icon" alt="{{ fileAsset.name }}" class="img-thumbnail" style="cursor: pointer;" (click)="selectedImage=fileAsset.file">
            </div>
        </div>
        <br>
        <button class="okay-btn" type="button" (click)="closeModal()" >Okay</button>

      </div>
    </div>
    
  </ng-template>