<div class="cc_content" (keydown.ctrl.s)="captureComponent($event)">
  <!-- form and preview -->
  <div class="form_nd_preview">
    <!-- form -->
    <div class="form">
      <div class="form-box">
        <!-- icons -->
        <div class="flex_icons">
          <div class="c_no">Certificate No : {{certificate_content.certificate_number}}</div>
            <input type="file" accept="image/*" (change)="onFileSelected($event,'item_image')" #image_input style="display: none;" />
            <div *ngIf="!certificate_content.item_image" (click)="image_input.click()">
              <span>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="50" height="50" rx="4" fill="#F6F8FE" />
                  <path
                    d="M36.5797 14.4849H15.3854C15.1629 14.4849 14.9825 14.6652 14.9825 14.8877V36.082C14.9825 36.3045 15.1629 36.4849 15.3854 36.4849H31.7444L36.9825 31.2468V14.8877C36.9825 14.6652 36.8022 14.4849 36.5797 14.4849Z"
                    fill="#3268FB"
                  />
                  <path
                    d="M26.312 21.8174V29.8089"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.3162 25.8155H30.3077"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M31.7434 36.4849V32.784C31.7434 31.9351 32.4446 31.2468 33.3092 31.2468H36.9815"
                    fill="#D9D9D9"
                  />
                </svg>
              </span>
              Add Photo
            </div>
            <div *ngIf="certificate_content.item_image">
              <img style="max-width: 50px; max-height: 50px;" [src]="certificate_content.item_image">
              <span (click)="certificate_content.item_image=null">
                <svg class="ms-2 me-1" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <rect width="16" height="16" fill="url(#pattern0_664_1322)"/>
                  <defs>
                  <pattern id="pattern0_664_1322" patternContentUnits="objectBoundingBox" width="1" height="1">
                  <use xlink:href="#image0_664_1322" transform="scale(0.0104167)"/>
                  </pattern>
                  <image id="image0_664_1322" width="96" height="96" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAACl0lEQVR4nO2dO44TQRCGK4GA5SDgwHKXEx4ZC0cg23i6zSkIkYDV7nF4ZexyALgIGCRThTSotJnFSthu+++Z/T+pJMtByfM/ZsbB2CKEEEIIIYQQ0hAp+0st3u802V+hj+Pmil9oAl78QhPw4peRmzDP9liLfUzFfu1NvNLK2M841tnCHkkr4qdihhfGD23E79TZQ7T+krJ9wovhkEnF3qP1l6tK4sVQxGRbovWnAWjiggRPYrnBp6C4G4gLEloMPfjEMdsDaYG4G9BsH+KciBfG9zvZlpH8ZsQnhBBCCCGEEPgXpbLfad5htEBKA/AiKRuAF0p5ChrnSOugBVIagBdJ2QC8UMpT0P+LkIp/Tp0fz7v+zvSkP4rXmv2ilog190vrbCPOvOtvre+ZPO9va/HLGuLX3C+ts4VIT67bNc/+rEIDqu6X1tn0gKYn/dF1uyYv+ru7GlB7v7RO7QPSHQ049OeFgxZIaQANgIJOqLIBNAAKOqHKBtAAKOiEKhtAA6CgE6psAA2Agk6osgE0AAo6ocoG0AAo6IQqG0ADoKATqmwADYCCTqiyATQACjqhygbQACjohCobQAOgoBOqbAANgIJOqLIBNAAKOqHKBtAAKOiEKhtAA6CgE6psAA2Agk6osgGbCTTlQ3rYBqTOj/f5mGrt/dI6G4uU/SIeml7fE++l7F92NaD2fmmdLYW6nC38aTy3GxPJrCL+HvZL66RiPyoK17c0Kdt3aR3N/m28BvhXaR0tfjpaA4q/ltaZ5dUkFfszvvSbz7vVPRkCKfs5WjCtP6cyFOL3ea7+ZWI06X/3r98cGoAJfhbVHbDwHskfnPjr1wQt/jbuIAbxHzPZlvFZU/Y3abG6j9aPEEIIIYQQIqPjL0mh8tZyMj7vAAAAAElFTkSuQmCC"/>
                  </defs>
                  </svg>
                  
                Delete Logo
              </span>
          </div>
        </div>
        <hr>

        <!-- input forms -->
        <div class="row">
          <div class="col-md-4">
            <div>Item Code/HUID</div>
            <div>Description</div>
      

          </div>
          <div class="col-md-8">
           
            <!-- <input class="form-control" 
            [(ngModel)]="certificate_content.item_code_or_huid"
            placeholder="Enter Item Code/HUID"
            (keydown.enter)="description.focus()"
            />
            <div *ngIf="certificateForm.get('item_code_or_huid')?.invalid && certificateForm.get('item_code_or_huid')?.touched">
              <small class="text-danger" *ngIf="certificateForm.get('item_code_or_huid')?.errors?.['required']">
                Item Code/HUID is required.
              </small>
              <small class="text-danger" *ngIf="certificateForm.get('item_code_or_huid')?.errors?.['minlength']">
                Item Code/HUID must be at least 3 characters long.
              </small>
            </div>

            <input class="form-control"
             #description  
             (keydown.enter)="focusNextKey(-1)"
             [(ngModel)]="certificate_content.description"
              placeholder="Enter Description" />
            
              <div *ngIf="certificateForm.get('description')?.invalid && certificateForm.get('description')?.touched">
                <small class="text-danger" *ngIf="certificateForm.get('description')?.errors?.['required']">
                  Description is required.
                </small>
              </div> -->
              <form [formGroup]="certificateForm">
                <div class="form-group">
                  <input
                  [(ngModel)]="certificate_content.item_code_or_huid"
                    class="form-control"
                    (keydown.enter)="description.focus()"
                    formControlName="item_code_or_huid"
                    placeholder="Enter Item Code/HUID (Max 10 Characters)"
                    [ngStyle]="certificateForm.get('item_code_or_huid')?.invalid && certificateForm.get('item_code_or_huid')?.touched || certificateForm.get('item_code_or_huid')?.errors?.['maxlength']? {'margin-bottom':'0px'}:{'margin-bottom':'4.5%'}"
                  />
                  <div class="text-right" *ngIf="certificateForm.get('item_code_or_huid')?.invalid && certificateForm.get('item_code_or_huid')?.touched">
                    <small class="text-danger" *ngIf="certificateForm.get('item_code_or_huid')?.errors?.['required'] || certificateForm.get('item_code_or_huid')?.errors?.['pattern']">
                      Item Code/HUID is required.
                    </small>
                    <small class="text-danger" *ngIf="certificateForm.get('item_code_or_huid')?.errors?.['maxlength']">
                      Item Code/HUID must be at most 10 characters long.
                    </small>
                  </div>
                </div>
              
                <div class="form-group">
                  <input
                    class="form-control"
                    #description
                    formControlName="description"
                    placeholder="Enter Description  (Max 200 Characters)"
                    (keydown.enter)="focusNextKey(-1)"
                    [ngStyle]="certificateForm.get('description')?.invalid && certificateForm.get('description')?.touched? {'margin-bottom':'0px'}:{'margin-bottom':'4.5%'}"
                    [(ngModel)]="certificate_content.description"
                  />
                  <div class="text-right" *ngIf="certificateForm.get('description')?.invalid && certificateForm.get('description')?.touched">
                    <small class="text-danger" *ngIf="certificateForm.get('description')?.errors?.['required']">
                      Description is required.
                    </small>
                    <small class="text-danger" *ngIf="certificateForm.get('description')?.errors?.['maxlength']">
                      Description must be at most 200 characters long.
                    </small>
                  </div>
                </div>
              </form>
          </div>
        </div>
        <div 
          class="row"
          *ngFor="let raw_data of raw_certificate_content; let i=index"
        >
          <div class="col-md-4">
            <input
            #keyInput
              class="form-control"
              [(ngModel)]="raw_data.key"
              placeholder="Enter label"
              (keydown.enter)="focusNextValue(i-1)"
            />
          </div>
          <div class="col-md-8">
            <input
            #valueInput
              class="form-control"
              [(ngModel)]="raw_data.value"
              placeholder="Enter value"
              (keydown.enter)="focusNextKey(i)"
            />
          </div>
        </div>
        <div class="add_section">
          <div (click)="addSection()">
            <span>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 0.75V6M6 11.25V6M6 6H11.25M6 6H0.75"
                  stroke="#3268FB"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            Add Section(Shift+ Enter)
          </div>

          <div
            (click)="removeSection()"
            class="remove_sections"
            *ngIf="raw_certificate_content.length"
          >
            - Remove Section (Shift+ Delete)
          </div>
        </div>
      </div>
    </div>
    <!-- preview -->
    <div class="preview">
      <div class="preview-box " >
        <div  #screenElement class="certificate-download">
            <div class="text-center">Front</div>
            <div class="">
              <app-basic-template
              id="id-card-front"
                [certificate_content]="certificate_content"
                [raw_certificate_content]="raw_certificate_content.slice(0,4)"
              ></app-basic-template>
            </div>
    
        </div>
        
        <div *ngIf="certificate_content.is_overflow">
            <div class="text-center mt-2">
                Back
              </div>
              <div >
                <app-back-basic-template
              [certificate_content]="certificate_content"
              [raw_certificate_content]="raw_certificate_content.slice(4)"
              ></app-back-basic-template>
              </div>
              
        </div>
       
      </div>
    </div>
  </div>
  <div class="button_block"  >
    <button class="btn"  
    [disabled]="certificate_content.is_saved"
    (click)="!certificate_content.is_saved?captureComponent($event):''">
      <div>Save<span>(ctrl+s)</span></div>
    </button>
  </div>
</div>

<ng-template #completed>
  <app-lottie-popup
  (proceedButtonClickEvent)="afterPopup($event)"
  [pop_up_data]="certificate_completion_popup"
  [lottie_file_path]="setup_completion_lottie_file_path"
  ></app-lottie-popup>
</ng-template>