import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {
  static accessToken = 'jwt_access_token';
  static refreshToken = 'jwt_refresh_token';
  static fail_count = 0;
  refresh = false;
  constructor(private http: HttpClient, private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const req = request.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem(
          InterceptorInterceptor.accessToken
        )}`,
      },
    });
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && !this.refresh) {
          this.refresh = true;

          return this.http
            .post(
              environment.ip + 'shop/refresh/',
              {
                refresh: localStorage.getItem(
                  InterceptorInterceptor.refreshToken
                ),
              },
              { withCredentials: true }
            )
            .pipe(
              switchMap((res: any) => {
                localStorage.setItem(
                  InterceptorInterceptor.accessToken,
                  res['access']
                );
                localStorage.setItem(
                  InterceptorInterceptor.refreshToken,
                  res['refresh']
                );

                return next.handle(
                  request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${localStorage.getItem(
                        InterceptorInterceptor.accessToken
                      )}`,
                    },
                  })
                );
              })
            );
        }

        this.refresh = false;
        return throwError((data:any) => {
         

          if (err.status==401)
          {
            localStorage.clear();
            this.router.navigate(['/login']);
            location.reload();
            return
          }
          else{
            return err;
          }

        });
      })
    );
  }
}
