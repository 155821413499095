<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:50vh;">

<div class="custom-nav mb-2">
  <button class="mr-2" mat-icon-button (click)="back()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span class="bold-small">Privacy policy</span>
</div>
<div class="container">
  <h1 class="bold-25 center">Privacy Policy &mdash; Jewellers.live</h1>

  <h2 class="semibold-20">Introduction:</h2>

  <p class="regular-blue">
    The domain name www.Goldlane.in (hereinafter referred to as the "Website")
    is absolutely owned and operated by SMJ Solutions Pvt Ltd, a company
    incorporated under the Companies Act, 2013 with its registered office at 18,
    3rd floor, Haralur Main Rd, Royal Placid Phase 1, PWD Quarters, 1st Sector,
    HSR Layout, Bengaluru, Karnataka 560068, exclusively selling gold and silver
    in the name and style of Sumangali Jewellers. At Jewellers.live, your
    privacy and trust are secured and given utmost importance. This Privacy
    Policy is carefully made to help you understand how your data is received,
    stored and used by Jewellers.live. You expressly consent to use & disclose
    your personal information in accordance with this Privacy Policy by logging
    into any of Jewellers.live&apos;s platforms on any medium. Note: Our privacy
    policy may change at any time without prior notification. Kindly review the
    privacy policy to be updated with this Privacy Policy. It shall apply
    uniformly against all of Jewellers.live&apos;s mode of transmission of
    portal.
  </p>

  <h2 class="semibold-20">General:</h2>

  <p class="regular-blue">
    Jewellers.live warrants and covenants not to sell, rent or share for
    benefit, any of the personal data of the user so as to make it available for
    subscription for promotional advertisements/unsolicited enquiries by way of
    emails/calls. Jewellers.live further warrants that any email/call from it
    shall only be in connection with its services as mentioned in the terms and
    policy. Jewellers.live may reveal general statistical information about
    Jewellers.live & its users, on the average traffic on its website along with
    purchase trend on Jewellers.live etc. All the legal compliant requests for
    disclosures of personal data shall be accepted by Jewellers.live and it
    shall not amount to violation of privacy of the user.
  </p>

  <h2 class="semibold-20">Personal Data:</h2>

  <p class="regular-blue">
    Personal Information means and includes all information such as name,
    address, mailing id, telephone number, all the details on the credit/debit
    card , UPI , account details , information about mobile phone and any other
    details that may have been voluntarily provide by the user in connection
    with availing any of the services on Jewellers.live. In addition,
    information regarding the domain, server, host providing the internet. IP
    address of the system/ISP and any other anonymous site data may be accessed
    by Jewellers.live
  </p>
  <h2 class="semibold-20">Use of Personal Information</h2>

  <p class="regular-blue">
    We use personal information to provide you with the below: a. To resolve any
    technical snag, troubleshoot concerns, promote safe services, to perform
    financial transactions if any, measure consumer statistics in our services,
    b. To inform you about offers, products, services, updates, customize your
    experience, detect & protect us against error, fraud and other criminal
    activity, enforce our terms and conditions, etc. c. To send you offers based
    on your previous orders and interests. d. To customize your experience at
    Jewellers.live, by providing you with content that we think you might be
    interested in and to display content according to your preferences.
  </p>

  <h2 class="semibold-20">Cookies</h2>
  <p class="regular-blue">
    A &quot;cookie&quot; is a small piece of information stored by a web server
    on a web browser so it can be later read back from that browser. No personal
    information will be collected via cookies and other tracking technology;
    however, if you previously provided personally identifiable information,
    cookies may be tied to such information. Aggregate cookie and tracking
    information may be shared with third parties.
  </p>
  <h2 class="semibold-20">Security</h2>
  <p class="regular-blue">
    Jewellers.live has installed a secure server with stringent security
    measures in place to safeguard user&apos;s personal data from misuse,
    destruction and alteration of the information. Once your information is in
    our possession we adhere to strict security guidelines, protecting it
    against unauthorized access.
  </p>

  <h2 class="semibold-20">Links to Other Sites</h2>
  <p class="regular-blue">
    Jewellers.live may have linked up with a few websites to carry out the
    functions at its optimum. Therefore, is not responsible for the privacy
    policy or the content of the other websites linked/to be linked on
    Jewellers.live.
  </p>

  <h2 class="semibold-20">Consent</h2>
  <p class="regular-blue">
    By using Jewellers.live and/or by providing your information, you consent to
    the collection, storage and use of the information you disclose on
    Jewellers.live in accordance with this Privacy Policy, including but not
    limited to your consent for sharing your information as per this privacy
    policy.
  </p>
</div>
</div>