import {  DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Rest } from "../../models/rest_model";
import { CustomItem } from "src/app/models/customItem";
import { DesignService } from "./design.service";


export class ViewOrderDataSource extends DataSource<any> {

  private customData=new BehaviorSubject<CustomItem[]>([]);
  private loadingcustomdata = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingcustomdata.asObservable();
  private countledgers=new BehaviorSubject<number>(0);
  public counter$=this.countledgers.asObservable();



  constructor(private designService:DesignService) {
    super();
  }
  connect(): Observable<CustomItem[]> {
    return this.customData.asObservable();

  }
  disconnect() {
    this.customData.complete();
    this.loadingcustomdata.complete();
  }
  loadData(page:number=0,search: any,filter:any,vendors:any){


      page=page+1;
      this.loadingcustomdata.next(true);
      this.designService.getCustomOrders(page=page,search=search,filter=filter,vendors=vendors).pipe(
        catchError(()=> of([])),
        finalize(()=>{
          this.loadingcustomdata.next(false);
        })

      ).subscribe(ledgerLogs=>this.renderOrders(ledgerLogs))

  }

  renderOrders(ledgerLogs:any){
    console.log(ledgerLogs);
    this.countledgers.next(ledgerLogs.count);
    this.customData.next(ledgerLogs.results);


  }




}