import { Component, Input } from '@angular/core';
import { CertificateData } from '../../models/Certificate-content';

@Component({
  selector: 'app-back-basic-template',
  templateUrl: './back-basic-template.component.html',
  styleUrls: ['./back-basic-template.component.css','../basic-template/basic-template.component.css']
})
export class BackBasicTemplateComponent {
  @Input()  certificate_content!: CertificateData;
  @Input()  raw_certificate_content!: { key: string; value: string }[];
}
