import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[numbersOnly]'
})
export class NumbersOnlyDirective {

  constructor(private el: ElementRef<HTMLInputElement>) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const key = event.key;

    // Allow only numbers, backspace, and arrow keys
    if (
      /^[0-9]$/.test(key) || // Numbers 0-9
      key === 'Backspace' || // Backspace
      key === 'ArrowLeft' || // Arrow Left
      key === 'ArrowRight' // Arrow Right
    ) {
      return;
    }

    event.preventDefault();
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const newValue = input.value.replace(/[^0-9]/g, '');
    input.value = newValue;
  }
}
