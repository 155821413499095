<div class="roww mt-4">

    <div>
      <div class="nw">
        <div class="left-buttons">
          <div class="input-group mt-1">
            <mat-icon
              class="searchIcon cursor"
              id="23"
              [ngStyle]="{ color: '#9A9AB0' }"
              >search</mat-icon
            >
  
            <input
            [(ngModel)]="search"
              type="text"
              class="form-control search_box roundSquare text-indent cursor"
              id="23"
              style="border: 0; height: 48px"
              placeholder="Search phone, email, code, name"
              (keyup.enter)="searchOption(); txtInput.blur()"
              #txtInput
            />
            <mat-icon

            *ngIf="search"
            (click)="clearInput(); searchOption()"
              class="crossIcon cursor"
              [ngStyle]="{ color: '#9A9AB0' }"
              >cancel</mat-icon
            >

            <button
            (click)="searchOption()"
              mat-raised-button
              id="23"
              class="searchButton cursor"
              style="
                background: #4070f4;
                color: #ffffff;
                height: 48px;
                box-shadow: none;
              "
            >
              Search
            </button>
          </div>
          <div></div>
        </div>
        <div class="right-buttons">
          <div>
            <a>
              <button
                mat-raised-button
                color="primary"
                class="mt-2 add_booking_button"
               
                id="4"
                (click)="openVendor(add_vendor)"
              >
                + New Vendor
              </button>
            </a>
          </div>
  
          <div
            class="rcorners1 cursor"
            style="cursor: pointer; height: 45px"
            id="24"
            
          >
            
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5 mt-2"></div>
  
    <div class="col-md-1 mt-4"></div>
  </div>
  <div class="row mb-2">
    <div class="col-md-12 text-center">
      <mat-spinner
        *ngIf="dataSource.loading$ | async"
        style="margin: 0 auto"
        mode="indeterminate"
        [diameter]="50"
      ></mat-spinner>
    </div>
  </div>



  
  <div class="flex_center mx-3">
    <div class="mat-elevation table-responsive">
      <table mat-table [dataSource]="dataSource" class="mat-table-container">
       
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.display_name }}</td>
        </ng-container>
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>Phone</th>
          <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
        </ng-container>
        
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>
        <ng-container matColumnDef="code_name">
          <th mat-header-cell *matHeaderCellDef>Code Name</th>
          <td mat-cell *matCellDef="let element">{{ element.code_name }}</td>
        </ng-container>

  
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element" [matMenuTriggerFor]="close_menu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="18"
              viewBox="0 0 5 18"
              fill="none"
            >
              <rect
                x="0.5"
                y="0.333252"
                width="4"
                height="4"
                rx="2"
                fill="#515151"
              />
              <rect x="0.5" y="7" width="4" height="4" rx="2" fill="#515151" />
              <rect
                x="0.5"
                y="13.6665"
                width="4"
                height="4"
                rx="2"
                fill="#515151"
              />
            </svg>
            <mat-menu #close_menu="matMenu" class="action_mat">
              <div>
                <button (click)="getVendorInfo(element.id,edit_vendor)" mat-menu-item>
                  <span><mat-icon>edit</mat-icon></span>
                  Edit
                </button>

                <button (click)="openDeleteOption(element,cancel_vendor)" mat-menu-item>
                    <span><mat-icon>delete</mat-icon></span>
                        Delete
                  </button>
              </div>
            </mat-menu>
          </td>
        </ng-container>

        
  
       
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
  
      <mat-paginator
        style="background-color: #f5f6f8"
        [pageSizeOptions]="[popupManager.page_size_number]"
        showFirstLastButtons
        (page)="handlePage($event)"
      ></mat-paginator>

      
    </div>
  </div>

  
  <ng-template #add_vendor let-modal>
    <div class="myModal cardClass" (keydown.ctrl.s)="preventEvent($event);createVendor()">
      <div class="modal-header cus-mod-header" >
        <div class="modal-title bold_title">Add New Vendors/Goldsmiths</div>
        <button
          type="button"
          style="border: none; border-top: none; outline: none"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="mt-4 px-4 pb-4">
        <div class="row">
          <div class="col-md-6">
            <p class="title_name">Mobile No <span class="req">*</span> </p>
            <input type="text" class="form-control" id="mobile_number" (keydown.enter)="focus('full_name')" style="height: 44px;"/>
          </div>
          <div class="col-md-6">
            <p class="title_name">Full Name <span class="req">*</span></p>
            <input type="text" class="form-control" id="full_name"(keydown.enter)="focus('code_name')"  style="height: 44px;"/>
          </div>
          <div class="mt-3"></div>
          <div class="col-md-6">
            <p class="title_name">Vendor Code <span class="req">*</span></p>
            <input type="text" class="form-control" id="code_name"  (keydown.enter)="focus('vendor_email')"style="height: 44px;"/>
          </div>
          <div class="col-md-6">
            <p class="title_name">Email</p>
            <input type="email" class="form-control" id="vendor_email" (keydown.enter)="focus('save_button_vendor')" style="height: 44px;"/>
          </div>
          <div class="mt-4"></div>
          <div class="col-md-12 text-center">
            <button (click)="createVendor()" id="save_button_vendor" class="upload-btn" style="width: 30%;" type="button">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>





  <ng-template #edit_vendor let-modal>
    <div class="myModal cardClass" (keydown.ctrl.s)="preventEvent($event);createVendor()">
      <div class="modal-header cus-mod-header" >
        <div class="modal-title bold_title">Edit Vendor</div>
        <button
          type="button"
          style="border: none; border-top: none; outline: none"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="mt-4 px-4 pb-4">
        <div class="row">
          <div class="col-md-6">
            <p class="title_name">Mobile No <span class="req">*</span> </p>
            <input type="text" [disabled]="true" value="{{vendorData.phone}}" class="form-control" id="mobile_number" (keydown.enter)="focus('full_name')" style="height: 44px;"/>
          </div>
          <div class="col-md-6">
            <p class="title_name">Full Name <span class="req">*</span></p>
            <input type="text" value="{{vendorData.display_name}}" class="form-control" id="full_name"(keydown.enter)="focus('code_name')"  style="height: 44px;"/>
          </div>
          <div class="mt-3"></div>
          <div class="col-md-6">
            <p class="title_name">Vendor Code <span class="req">*</span></p>
            <input type="text"  value="{{vendorData.code_name}}" class="form-control" id="code_name"  (keydown.enter)="focus('vendor_email')"style="height: 44px;"/>
          </div>
          <div class="col-md-6">
            <p class="title_name">Email</p>
            <input type="email" value="{{vendorData.email}}" class="form-control" id="vendor_email" (keydown.enter)="focus('save_button_vendor')" style="height: 44px;"/>
          </div>
          <div class="mt-4"></div>
          <div class="col-md-12 text-center">
            <button (click)="editVendor()" id="save_button_vendor" class="upload-btn" style="width: 30%;" type="button">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>


  <ng-template #cancel_vendor let-modal>
    <div class="myModal">
      <div class="modal-content complete">
        <div class="modal-header header-box" style="border: 0; height: 0">
          <h1 class="modal-title header-title">Delete Vendor</h1>
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body body_model text-center">
          <div class="cancel_info mt-3">
            Are you sure you want to delete the vendor ?
          </div>
          <div class="cancel_order_body">
  
            <div class="left_cancel_info">
              Vendor Code
            </div>
            <div class="right_cancel_info_1st" id="cancel_order_no"  #elementRef>
              {{vendorData.code_name}}
            </div>
          </div>
          <!-- 2nd row -->
          <div class="cancel_order_body">
            <div class="left_cancel_info">
             Vendor Name
            </div>
            <div class="right_cancel_info_2nd" id="cancel_customer_name">
                {{vendorData.display_name}}
            </div>
          </div>
          <div class="btn-group mt-4 mb-3 text-center">
            <button
              class="btn_completion"
              (click)="deleteVendorInfo();modal.dismiss('Cross click')"
            >
             Delete 
            </button>

            <button
            class="btn_completion_cancel"

            (click)="modal.dismiss('Cross click')"
          >
           Cancel 
          </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
