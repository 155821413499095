import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { RateCutOptions } from 'src/app/models/ratecut';
import { BookingData } from 'src/app/models/booking';
import { Rest } from 'src/app/models/rest_model';

@Injectable({
  providedIn: 'root'
})
export class RateCutService {
  url = environment.ip + 'shop/';
  download_url = environment.uploaderIp+"shop/";
  constructor(private http: HttpClient) { }

  sendRateCut(data:any)
  {
    const headers = { 'content-type': 'application/json' };
    // data= JSON.stringify(data.options)

    data={"setup_options":data.options}
    console.log(data)
    return this.http.post<RateCutOptions>(this.url+'advance_booking/add_setup/',data);
  }
  getRateCut()
  {
    return this.http.get<RateCutOptions>(this.url+'advance_booking/get_setup/');
  }

  addBooking(data:any)
  {
    return this.http.post(this.url+'advance_booking/add_booking/',data)
  }
  getBookings(page:number,search:string,ordering:string,status:string,filter:string){
    return this.http.get<Rest<BookingData>>(this.url+'advance_booking/',{params:{"search":search,"page":page,"ordering":ordering,"status":status,"filter":filter}});
  }
  editBooking(data:any)
  {
    return this.http.post(this.url+'advance_booking/edit_booking/',data)
  }
  addAdvance(data:any)
  {
    console.log(data)
    const headers = { 'content-type': 'application/json'}
    return this.http.post(this.url+'advance_booking/add_advance/',data,{headers:headers})
  }
  viewPayment(id:any)
  {
    return this.http.get(this.url+`advance_booking/${id}/view_payment/`)
  }

  allowedRateCut()
  {
    return this.http.get(this.url+'advance_booking/allowed_booking/')
  }
  getBookings2(
    page: number,
    from_date: string,
    to_date: string,
    search: string,
    filter: string,
    settlement_id: string = '',
  ) {
    var online = "";
    var selected_types = filter.split(',');

    selected_types.forEach((element: any, index: any) => {
      if (element == '999') {
        online = "true";
        selected_types.splice(index, 1);
      }
    });

    return this.http.get<Rest<BookingData>>(this.url + 'advance_booking_report/', {
      params: {
        from_date: from_date,
        to_date: to_date,
        page: page,
        search: search,
        settlement_id: settlement_id,
        payment_mode__in: `${selected_types.toString()}`,
        order__is_online_mode: online,
      },
    });
  }
  cancelBooking(
    id: any,
    deduction: any,
    amount_payable: any,
    cancel_date: any,
    payment_mode: any,
    bank: any,
    payment_date: any,
    comments: any
  ) {
    var data = {
      booking_id: id,
      deduction: Number(deduction),
      amount_payable: Number(amount_payable),
      cancel_date: cancel_date,
      payment_mode: Number(payment_mode),
      bank: Number(bank),
      payment_date: payment_date,
      comments: comments,
    };


    const headers = { 'content-type': 'application/json' };

    return this.http.post(this.url + 'advance_booking/cancel_booking/', data, {
      headers: headers,
    });
  }

  completeBooking(id:any,completion_date:any,invoice:any)
  {
    var data={
      booking_id:id,
      completion_date:completion_date,
      invoice:invoice
    }
    return this.http.post(this.url+'advance_booking/complete_booking/',data)
  }

  downlaodBookings(fd:FormData){
    return this.http.post(this.download_url+'advance_booking/download_booking/',fd);
  }

  removeRowRateCut(id:any)
  {
    return this.http.post(this.url+`advance_booking/${id}/remove_setup_row/`,{})
  }


}
