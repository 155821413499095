export class PaymentType{
    id!:string;
    amount !:string;
    date !:string;
    bank !:string;

    payment_info!:string;
    constructor(id:string,amount:string,date:string,bank:string,payment_info:string){
        this.id=id;
        this.amount=amount;
        this.date=date;
        this.bank = bank;

        this.payment_info=payment_info;
    }
}

export class PaymentOptions{
    type !:string;
    mode!:number;
    options !:PaymentType[]
    constructor(type:string,options:PaymentType[],mode:number){
        this.type = type;
        this.options = options;
        this.mode=mode;
    }
}


export class Roles{
    actions!:Actions[]
    id!:string;
    name !:string;
    status !:string;
    type!:string;

    constructor(id:string,name:string,status:string,action:Actions[])
    {
        this.id=id;
        this.name=name;
        this.status=status;
        this.actions=action;
    }

}

export class Actions{
    id!:string;
    name !:string;
    status !:string;
    constructor(id:string,name:string,status:string)
    {
        this.id=id;
        this.name=name;
        this.status=status;
    }
}


// general model for permission

export class Groups{

    name !:string;
    roles !:Roles[];
    constructor(name:string,roles:Roles[])
    {
        this.name=name;
        this.roles=roles;
    }

}