import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  send_otp_url=environment.ip+"shop/send_otp/";
  send_otp_reg = environment.ip+"shop/send_otp_register/";
  login_url = environment.ip+"shop/login/";
  re_send_otp_url=environment.ip+"shop/re_send_otp/"; 
  re_send_otp_reg=environment.ip+"shop/re_send_otp_register/"; 


  counter_login_url=environment.ip+"shop/counter_login/";
  verify_register=environment.ip+"shop/verify_register_otp/";
  create_shop= environment.ip+"shop/create_shop/";
  counter_resned_otp=environment.ip+"shop/counter_resend_otp/";
  counter_send_otp=environment.ip+"shop/counter_send_otp/"

  constructor(private http:HttpClient) {

   }

   counterLogin(counter_id:any,counter_store_id:any,pin:any,otp:any)
   {
    let fd=new FormData();
    fd.append('counter_id',counter_id);
    fd.append('shop_id',counter_store_id);
    fd.append('pin',pin);
    fd.append('otp',otp);
    return this.http.post(this.counter_login_url,fd);

   }

   counterResendOtp(counter_id:any,counter_store_id:any,pin:any,voice:any)
   {
    let fd=new FormData();
    fd.append('counter_id',counter_id);
    fd.append('shop_id',counter_store_id);
    fd.append('pin',pin);
    fd.append('voice',voice);
    return this.http.post(this.counter_resned_otp,fd)
   }

   counterSendOtp(counter_id:any,counter_store_id:any,pin:any)
   {
  
    let fd=new FormData();
    fd.append('counter_id',counter_id);
    fd.append('shop_id',counter_store_id);
    fd.append('pin',pin);
    return this.http.post(this.counter_send_otp,fd)
  }



    sendOtp(mobile_number:any,shop_id:any){
      let fd = new FormData();
      fd.append("mobile",mobile_number);
      fd.append("shop_id",shop_id);
    return this.http.post(this.send_otp_url,fd);

   }

   sendRegisterOtp(mobile_number:any){
    let fd = new FormData();
    fd.append("mobile",mobile_number);
    return this.http.post(this.send_otp_reg,fd);

 }


   resendOtp(fd:FormData){
    return this.http.post(this.re_send_otp_url,fd);

   }


   resendRegisterOtp(fd:FormData){
    return this.http.post(this.re_send_otp_reg,fd);

   }

   login(mobile_number:any,otp_value:any,shop_id:any){
    let fd = new FormData();
    fd.append("mobile",mobile_number);
    fd.append("otp",otp_value);
    fd.append("shop_id",shop_id);

    return this.http.post(this.login_url,fd);

   }
   verifyOtp(mobile_number:any,otp_value:any){
    let fd = new FormData();
    fd.append("mobile",mobile_number);
    fd.append("otp",otp_value);


    return this.http.post(this.verify_register,fd);
   }
   createShop(mobile_number:any,otp_value:any,shop_name:any,shop_type:any){
    let fd = new FormData();
    fd.append("mobile",mobile_number);
    fd.append("otp",otp_value);
    fd.append("shop_name",shop_name);
    fd.append("shop_type",shop_type);

    return this.http.post(this.create_shop,fd);
   }

  getDGStatus(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${environment.ip}shop/digital_coin/setup/`, { observe: 'response' }).pipe(take(1))
  }

}
