<div style="height:90vh; width:100%; overflow-y:scroll;">
  <div class="container-fluid mt-2">
    <h2 class="font-weight-bolder mb-0 heading">Actions</h2>
    <div class="header-body">
      <div class="row mt-3">
        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" id="5" (click)="navigateToInstallment()" style="cursor: pointer;">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="card-title font-weight-bold mb-0">Add Installments</h4>
                  <span class="text-muted mb-0">(Ctrl+I)</span>
                </div>
                <div class="col-auto">
                  <div class="avatar rounded-2 text-bg-primary text-white">
                    <img class="p-2" src="assets/images/1.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" id="4" (click)="navigateToAdd()" style="cursor: pointer;">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="card-title font-weight-bold mb-0">Start Jewellery Plan</h4>
                  <span class="text-muted mb-0">(Ctrl+E)</span>
                </div>
                <div class="col-auto">
                  <div class="avatar rounded-2 text-bg-primary text-white">
                    <img class="p-2" src="assets/images/2.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" (click)="allowed_actions['NewBooking']?navigateToBooking():'' " [style]="allowed_actions['NewBooking']?{'cursor':'pointer'}:{'cursor':'not-allowed'}">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="card-title font-weight-bold mb-0">Advance Booking</h4>
                  <span class="text-muted mb-0">(Ctrl+B)</span>
                </div>
                <div class="col-auto">
                  <div class="avatar rounded-2 text-bg-primary text-white">
                    <img class="p-2" src="assets/images/4.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" (click)="allowed_actions['AddOrder']?navigateToOrder():'' " [style]="allowed_actions['AddOrder']?{'cursor':'pointer'}:{'cursor':'not-allowed'}">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="card-title font-weight-bold mb-0">Create New Order</h4>
                  <span class="text-muted mb-0">(Ctrl+O)</span>
                </div>
                <div class="col-auto">
                  <div class="avatar rounded-2 text-bg-primary text-white">
                    <img class="p-2" src="assets/images/3.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" (click)="isCardEnabled ? navigateToBuy() : null" [style.cursor]="isCardEnabled ? 'pointer' : 'not-allowed'" [ngClass]="{'disabled-card': !isCardEnabled}">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="card-title font-weight-bold mb-0">Digital Gold Buy</h4>
                  <span class="text-muted mb-0">(Ctrl+D)</span>
                </div>
                <div class="col-auto">
                  <div class="avatar rounded-2 text-bg-primary text-white">
                    <img class="p-2" src="assets/images/5.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" (click)="isCardEnabled ? navigateToDelivery() : null" [style.cursor]="isCardEnabled ? 'pointer' : 'not-allowed'" [ngClass]="{'disabled-card': !isCardEnabled}">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="card-title font-weight-bold mb-0">Digital Gold Delivery</h4>
                  <span class="text-muted mb-0">(Ctrl+Y)</span>
                </div>
                <div class="col-auto">
                  <div class="avatar rounded-2 text-bg-primary text-white">
                    <img class="p-2" src="assets/images/img3.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="felx mt-3">
        <div class="heading">
          Todays's Transactions
        </div>
        <div class="rightbutton" (click)="viewReport()">
          View Detailed Reports
        </div>
      </div>

      <!-- today's transaction starting -->
    <div class="row mt-3">
      <div class="col">
        <div class="card shadow">
          <div (click)="toggleFlip()" [class.flipped]="isFlipped" class="icon" id="icon" mat-icon-button
              [matMenuTriggerFor]="menu">
                <img src="assets/images/dropdownicon.svg" />
              </div>
              <mat-menu #menu="matMenu" class="matmenu_" (matMenuClosed)="toggleFlip()" (close)="toggleFlip()">
                <div class="col">
                  <div class="table-responsive">
                    <table class="mat_menu_table align-items-center">
                      <thead>
                        <tr>
                          <th>Payment Mode</th>
                          <th>Jewellery Plans</th>
                          <th>Advance Bookings</th>
                          <th>Orders</th>
                          <th> Digital Gold</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of dropdown_data">
                          <td style="width:20%">{{ row.payment_mode }}</td>
                          <td style="width:20%">₹ {{ row.jewellery_plan  | indianNumeration}}</td>
                          <td style="width:20%">₹ {{ row.advance_booking | indianNumeration }}</td>
                          <td style="width:20%">₹ {{ row.oms | indianNumeration }}</td>
                          <td style="width:20%">₹ {{ row.digital_gold | indianNumeration }}</td>
                          <td style="width:20%">₹ {{ row.total | indianNumeration}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
      
              </mat-menu>
              <div class="row">
                <div class="col-3">
                  <div class="blue_box">
                    <div class="t_amount">
                      Total Amount
                    </div>
                    <div class="t_amount_val mt-1">
                      ₹ {{dashdata.total | indianNumeration}}
                    </div>
                  </div>
                </div>
                <div class="col-9">
                  <div class="closing_bal mt-3 mb-2">Closing Balance</div>
                <div class="white_box_first">
      
                  <div class="total_in_store">
                    <div class="title_mode">Total In-store Payment</div>
                    <div class="total_in_store_val"> ₹ {{dashdata.store_amount_total | indianNumeration}}</div>
                  </div>
                  <div class="left-line">
                    <div class="ms-2 in_box">
                    <div class="title_mode">Cash</div>
                    <div class="title_mode_val"> ₹ {{dashdata.closing_balance_cash | indianNumeration}}</div>
                    </div>
                  </div>
                  <div  class="left-line">
                    <div class="ms-2 in_box">
                    <div class="title_mode">Card</div>
                    <div class="title_mode_val"> ₹ {{dashdata.store_amount_total_card | indianNumeration}}</div>
                    </div>
                  </div>
                  <div  class="left-line">
                    <div class="ms-2 in_box">
                    <div class="title_mode">UPI</div>
                    <div class="title_mode_val"> ₹ {{dashdata.store_amount_total_upi | indianNumeration}}</div>
                  </div>
                  </div>
                  <div  class="left-line">
                    <div class="ms-2 in_box">
                    <div class="title_mode">NEFT/RTGS</div>
                    <div class="title_mode_val"> ₹ {{dashdata.store_amount_total_net_banking | indianNumeration}}</div>
                    </div>
                  </div>
                  <div  class="left-line">
                    <div class="ms-2 in_box">
                    <div class="title_mode">Cheque</div>
                    <div class="title_mode_val"> ₹ {{dashdata.store_amount_total_net_cheque | indianNumeration}}</div>
                  </div>
                </div>
                <div  class="left-line">
                  <div class="ms-2 in_box">
                  <div class="title_mode">Old Gold</div>
                  <div class="title_mode_val">  {{dashdata.old_gold_wt | indianNumeration}} Gms</div>
                </div>
              </div>
                </div>
                </div>
                <hr class="line_">
              </div>
              <div class="row">
                <div class="col">
                  <div class="white_box_second">
                    <div class="px-2" style="width: 24.3%;">
                      <div class="title_mode">Jewellery Plans ({{dashdata.count_jewellery_plans}})</div>
                      <div class="split_block">
                        <div class="total_in_store_val">  ₹ {{dashdata.total_jewellery_plans_payments | indianNumeration}} </div>
                        <div  class="total_in_store_val" style="font-size: 14px;"> {{dashdata.total_jewellery_plan_weight | indianNumeration}} gm</div>
                      </div>
                    </div>
                    <div class="left-line2 px-2">
                      <div class="title_mode">Advance Booking ({{dashdata.count_advance_booking}})</div>
                      <div class="split_block">
                        <div class="total_in_store_val">  ₹ {{dashdata.total_advance_booking_payments | indianNumeration}}</div>
                        <div  class="total_in_store_val" style="font-size: 14px;"> {{dashdata.total_advance_booking_weight | indianNumeration}} gm</div>
                      </div>
                    </div>
                    <div class="left-line2 px-2">
                      <div class="title_mode">Orders ({{dashdata.order_count}})</div>
                      <div class="split_block">
                        <div class="total_in_store_val">  ₹ {{dashdata.order_total | indianNumeration}}</div>
                        <div  class="total_in_store_val" style="font-size: 14px;"> {{dashdata.order_total_weight | indianNumeration}} gm</div>
                      </div>
                    </div>
                    <div class="left-line2 px-2">
                      <div class="title_mode">Digital Gold ({{dashdata.count_digital_coin_order}})</div>
                      <div class="split_block">
                        <div class="total_in_store_val">  ₹ {{dashdata.total_digital_coin_order_payments | indianNumeration}}</div>
                        <div  class="total_in_store_val" style="font-size: 14px;"> {{dashdata.digital_coin_order_total_weight }} gm</div>
                      </div>
                    </div>
                    <!-- <div class="left-line px-2" style="border-left: none;">
                      <div class="title_mode" style="display: inline-flex;"></div>
                      <div class="total_in_store_val" style="font-size: 14px;"> {{dashdata.digital_coin_order_total_weight}} gms</div>
                    </div> -->
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
    <!-- counter view -->
    <div id="counter_view">
      <div class="heading mt-4 mb-2">
        Counter wise Transactions 
      </div>
      <div  class="counter_name mb-2" >
        Counter Name : {{counter_data_list.counter_data[counter_data_list.index].counter_name}} 
      </div>
      <div class="counter_wise_box">
      <span class="counter_wise_box_before" *ngIf="counter_data_list.index!=0" (click)="getPreviousCounter()" ></span>
      <span class="counter_wise_box_after" *ngIf="counter_data_list.counter_data.length-1!=counter_data_list.index" (click)="getNextCounter()" ></span>
        <div class="col">
          <div class="table-responsive">
            <table class="mat_menu_table align-items-center">
              <thead>
                <tr>
                  <th>Transaction Type</th>
                  <th>Cash</th>
                  <th>Card</th>
                  <th>UPI/IMPS</th>
                  <th> RTGS/NEFT</th>
                  <th>Cheque</th>
                  <!-- <th>Total</th> -->
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="width:20%">Jewellery Plan</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].jewellery_plan_payments_cash_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].jewellery_plan_payments_card_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].jewellery_plan_payments_upi_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].jewellery_plan_payments_rtgs_neft_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].jewellery_plan_payments_cheque_payments}}</td>
                  <!-- <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].total_jewellery_plan_payments}}</td> -->
                </tr>
                <tr>
                  <td style="width:20%">Advance Booking</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].advance_booking_payments_cash_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].advance_booking_payments_card_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].advance_booking_payments_upi_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].advance_booking_payments_rtgs_neft_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].advance_booking_payments_cheque_payments}}</td>
                  <!-- <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].total_advance_booking_payments}}</td> -->
                </tr>
                <tr>
                  <td style="width:20%">Orders</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].order_payments_cash_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].order_payments_card_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].order_payments_upi_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].order_payments_rtgs_neft_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].order_payments_cheque_payments}}</td>
                  <!-- <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].total_order_payments}}</td> -->
                </tr>
                <tr>
                  <td style="width:20%">Digital Gold</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].digital_payment_cash_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].digital_payment_card_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].digital_payment_upi_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].digital_payment_rtgs_neft_payments}}</td>
                  <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].digital_payment_cheque_payments}}</td>
                  <!-- <td style="width:20%">₹ {{counter_data_list.counter_data[counter_data_list.index].total_digital_payments}}</td> -->
                </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
   
    <!-- counter view end -->
  <div class="graph_div multi_axis mt-4">
    <div class="first_row_gd">
      <div>
        All Activations
      </div>
      <div class="dropdown_graph">
        <select class="form-select" [(ngModel)]="selected_month" (change)="getDaysInMonth(selected_month);multiAxisGraph()">
          <option disabled>Select Month</option>
          <option  value=1>Janaury</option>
          <option value=2>February</option>
          <option value=3>March</option>
          <option value=4>April</option>
          <option value=5>May</option>
          <option value=6>June</option>
          <option value=7>July</option>
          <option value=8>August</option>
          <option value=9>September</option>
          <option value=10>October</option>
          <option value=11>November</option>
          <option value=12>December</option>
        </select>
      </div>

    </div>

    <canvas id="multi_axis_graph" [height]="100">
      {{ multi_axis_chart }}
    </canvas>
  </div>
  <div class="row mt-3 mb-4" style="padding: 0">
    <div class="col-md-6">
      <div class="card">
        <div class="row p-3">
          <div
            class="col-md-13"
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 14.5352px;
            "
          >
          Advance Bookings

            <!-- <span class="modesPayment">
                Last 7 Days
              </span> -->
          </div>

          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-6" style="height: 292px">
              <!-- <div class="insideDoughnut">
              $23,400
              <span>
                <div class="insideDLabel text-center">
                  Total 120 Plans
                </div>
              </span>
            </div> -->
              <canvas id="advDoughtnut">
                {{ chartDoughnut_adv }}
              </canvas>
            </div>
            <div class="col-md-5 mt-5">
              <div class="row ms-3">
                <div class="col-md-1 ms-3 nopadding">
                  <div class="vertical-line"></div>
                </div>
                <div class="col-md-9 nopadding">
                  <div class="barLabel">
                    {{
                      doughnut.completed_advance_count ? doughnut.completed_advance_count : 0
                    }}
                     Completed Bookings
                    <span>
                      <span>
                        <div class="barAmount mt-2">
                          ₹
                          {{
                            (CTI(doughnut.completed_advance)
                              ? CTI(doughnut.completed_advance)
                              : 0) | indianNumeration
                          }}
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="row ms-3 mt-5">
                <div class="col-md-1 ms-3 nopadding">
                  <div
                    class="vertical-line"
                    style="background-color: #329963"
                  ></div>
                </div>
                <div class="col-md-9 nopadding">
                  <div class="barLabel">
                    {{
                      (doughnut.in_progress_advance_count
                        ? doughnut.in_progress_advance_count
                        : 0) | indianNumeration
                    }}
                     in Progress Bookings
                    <span>
                      <span>
                        <div class="barAmount mt-2">
                          ₹
                          {{
                            (CTI(doughnut.in_progress_advance)
                              ? CTI(doughnut.in_progress_advance)
                              : 0) | indianNumeration
                          }}
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row ms-3 mt-5">
                <div class="col-md-1 ms-3 nopadding">
                  <div
                    class="vertical-line"
                    style="background-color: #e10000"
                  ></div>
                </div>
                <div class="col-md-9 nopadding">
                  <div class="barLabel">
                    {{
                      doughnut.cancelled_advance_count ? doughnut.cancelled_advance_count : 0
                    }}
                    Cancelled Bookings
                    <span>
                      <span>
                        <div class="barAmount mt-2">
                          ₹
                          {{
                            (CTI(doughnut.cancelled_advance)
                              ? CTI(doughnut.cancelled_advance)
                              : 0) | indianNumeration
                          }}
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="mt-5 ms-3" style="border-left: 5px solid #329963">
                <div class="ms-2"></div>
              </div>
              <div class="mt-5 ms-3" style="border-left: 5px solid #e10000">
                <div class="ms-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="row p-3">
          <div
            class="col-md-13"
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 600;
              font-size: 14.5352px;
            "
          >
          Jewellery Plans

            <!-- <span class="modesPayment">
                Last 7 Days
              </span> -->
          </div>

          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-6" style="height: 292px">
              <!-- <div class="insideDoughnut">
              $23,400
              <span>
                <div class="insideDLabel text-center">
                  Total 120 Plans
                </div>
              </span>
            </div> -->
              <canvas id="myChartDoughnut">
                {{ chartDoughnut }}
              </canvas>
            </div>
            <div class="col-md-5 mt-5">
              <div class="row ms-3">
                <div class="col-md-1 ms-3 nopadding">
                  <div class="vertical-line"></div>
                </div>
                <div class="col-md-9 nopadding">
                  <div class="barLabel">
                    {{
                      doughnut.completed_count ? doughnut.completed_count : 0
                    }}
                    Plans Completed
                    <span>
                      <span>
                        <div class="barAmount mt-2">
                          ₹
                          {{
                            (CTI(doughnut.completed_subscription)
                              ? CTI(doughnut.completed_subscription)
                              : 0) | indianNumeration
                          }}
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="row ms-3 mt-5">
                <div class="col-md-1 ms-3 nopadding">
                  <div
                    class="vertical-line"
                    style="background-color: #329963"
                  ></div>
                </div>
                <div class="col-md-9 nopadding">
                  <div class="barLabel">
                    {{
                      (doughnut.in_progress_count
                        ? doughnut.in_progress_count
                        : 0) | indianNumeration
                    }}
                    Plans in Progress
                    <span>
                      <span>
                        <div class="barAmount mt-2">
                          ₹
                          {{
                            (CTI(doughnut.in_progress_subscription)
                              ? CTI(doughnut.in_progress_subscription)
                              : 0) | indianNumeration
                          }}
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row ms-3 mt-5">
                <div class="col-md-1 ms-3 nopadding">
                  <div
                    class="vertical-line"
                    style="background-color: #e10000"
                  ></div>
                </div>
                <div class="col-md-9 nopadding">
                  <div class="barLabel">
                    {{
                      doughnut.cancelled_count ? doughnut.cancelled_count : 0
                    }}
                    Plans Cancelled
                    <span>
                      <span>
                        <div class="barAmount mt-2">
                          ₹
                          {{
                            (CTI(doughnut.cancelled_subscription)
                              ? CTI(doughnut.cancelled_subscription)
                              : 0) | indianNumeration
                          }}
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="mt-5 ms-3" style="border-left: 5px solid #329963">
                <div class="ms-2"></div>
              </div>
              <div class="mt-5 ms-3" style="border-left: 5px solid #e10000">
                <div class="ms-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  </div>
  
  <!-- <div class="col-md-2" style="padding-left: 0">
    <div
      class="card text-center router_cursor"
      id="5"
      (click)="navigateToInstallment()"
      style="cursor: pointer; border: 0"
    >
      <div
        class="card-body"
        style="
          height: 120px;
          box-shadow: 0px 5px 7px 1px rgba(64, 112, 244, 0.1);
          border-radius: 5px;
        "
      >
        <img class="mt-1" src="assets/images/paymentIcon.svg" />
        <p
          class="card-title mt-3"
          style="
            font-family: Rubik;

            font-size: 14px;
            font-weight: 600;

            text-align: center;
          "
        >
          Add Installments
          <br />
          <span style="color: #1f183799">(Ctrl+I)</span>
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-2" style="padding-left: 0">
    <div
      class="card router_cursor"
      id="4"
      (click)="navigateToAdd()"
      style="cursor: pointer; border: 0; border-radius: 5px"
    >
      <div
        class="card-body text-center"
        style="
          height: 120px;
          box-shadow: 0px 5px 7px 1px rgba(64, 112, 244, 0.1);
          border-radius: 5px;
        "
      >
      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="40" viewBox="0 0 42 46" fill="none">
        <circle cx="30.818" cy="4.1149" r="4.1149" fill="#262626"/>
        <path opacity="0.31" d="M19.6124 16.708C19.1221 16.708 18.7247 16.3126 18.7247 15.8247C18.7247 15.3369 19.1221 14.9414 19.6124 14.9414C20.1026 14.9414 20.5 15.3369 20.5 15.8247C20.5 16.3126 20.1026 16.708 19.6124 16.708Z" stroke="#262626" stroke-width="3" stroke-miterlimit="10"/>
        <path d="M14.1023 43.5643V40.86C16.3713 41.9913 18.8721 42.5861 21.41 42.5979C23.9478 42.6096 26.4542 42.0382 28.7337 40.9279V43.5623C28.7337 44.2317 29.0009 44.8736 29.4765 45.3469C29.9521 45.8202 30.5971 46.0861 31.2698 46.0861C31.9424 46.0861 32.5874 45.8202 33.0631 45.3469C33.5387 44.8736 33.8059 44.2317 33.8059 43.5623V37.0821C36.4115 34.1485 37.874 30.3825 37.9281 26.4676C37.9822 22.5526 36.6243 18.7481 34.1006 15.7443C31.577 12.7404 28.055 10.7364 24.1735 10.0957C20.2921 9.45497 16.3085 10.2201 12.9454 12.2522L12.1027 12.0387L8.31023 11.1127C8.14221 11.0717 7.96635 11.0751 7.80008 11.1226C7.6338 11.1701 7.48287 11.2599 7.36223 11.3833C7.2416 11.5067 7.15545 11.6593 7.11231 11.826C7.06918 11.9927 7.07055 12.1677 7.11631 12.3338L8.10539 15.9136L8.2517 16.5271C7.2989 17.8111 6.54041 19.2271 6.00042 20.7301C5.81779 20.7016 5.6332 20.6873 5.44833 20.6874C4.5327 20.6879 3.65471 21.0501 3.00726 21.6944C2.35981 22.3387 1.99585 23.2124 1.99533 24.1236V25.1312C1.99576 26.0015 2.32808 26.8393 2.92519 27.4753C3.52229 28.1113 4.33969 28.4981 5.21228 28.5576C5.65408 31.6547 6.98289 34.5595 9.03984 36.9249V43.5623C9.03984 44.2317 9.30704 44.8736 9.78265 45.3469C10.2583 45.8202 10.9033 46.0861 11.5759 46.0861C12.2486 46.0861 12.8936 45.8202 13.3692 45.3469C13.8449 44.8736 14.112 44.2317 14.112 43.5623L14.1023 43.5643Z" fill="white" stroke="#262626" stroke-width="3" stroke-miterlimit="10"/>
        <path d="M12.2062 20.1722C11.6858 20.1722 11.2639 19.7524 11.2639 19.2345C11.2639 18.7167 11.6858 18.2969 12.2062 18.2969C12.7266 18.2969 13.1484 18.7167 13.1484 19.2345C13.1484 19.7524 12.7266 20.1722 12.2062 20.1722Z" fill="#262626"/>
        <path d="M36.4383 19.1748C36.5574 19.4554 36.747 19.7007 36.9889 19.8874C37.2308 20.0741 37.5167 20.1958 37.8195 20.2407C38.1223 20.2856 38.4317 20.2522 38.7178 20.1438C39.0039 20.0354 39.2571 19.8557 39.4532 19.6217C39.6493 19.3878 39.7812 19.1075 39.8371 18.8079C39.8929 18.5084 39.8709 18.1996 39.7723 17.9112C39.6736 17.6228 39.502 17.3645 39.2741 17.1612C39.0462 16.9578 38.7699 16.8163 38.4711 16.75" stroke="#262626" stroke-width="3" stroke-miterlimit="10"/>
        <path d="M18.4692 21.3945H26.4297" stroke="#606060" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.4749 20.325C18.4749 19.2992 18.8825 18.3153 19.6078 17.5899C20.3332 16.8646 21.3171 16.457 22.3429 16.457C23.3688 16.457 24.3526 16.8646 25.078 17.5899C25.8034 18.3153 26.2109 19.2992 26.2109 20.325L22.3429 20.325L18.4749 20.325Z" fill="#262626"/>
      </svg>
        <p
          class="card-title mt-3"
          style="
            font-family: Rubik;

            font-size: 14px;
            font-weight: 600;
            text-align: center;
          "
        >
          Start Jewellery Plan
          <br>
          <span style="color: #1f183799">(Ctrl+E)</span>
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-2" style="padding-left: 0">
    <div
      class="card"
      (click)="allowed_actions['NewBooking']?navigateToBooking():'' "
      [style]="allowed_actions['NewBooking']?{'cursor':'pointer'}:{'cursor':'not-allowed'}"
      style="cursor: pointer; border: 0; border-radius: 5px"
    >
      <div
        class="card-body text-center"
        style="
          height: 120px;
          box-shadow: 0px 5px 7px 1px rgba(64, 112, 244, 0.1);
          border-radius: 5px;
        "
      >
        <img src="assets/images/adv_booking.svg" />
        <p
          class="card-title mt-3"
          style="
            font-family: Rubik;

            font-size: 14px;
            font-weight: 600;
            text-align: center;
          "
        >
          New Booking
      <br>
          <span style="color: #1f183799">(Ctrl+B)</span>
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-2" style="padding-left: 0">
    <div
      class="card"

      (click)="allowed_actions['AddOrder']?navigateToOrder():'' "
      [style]="allowed_actions['AddOrder']?{'cursor':'pointer'}:{'cursor':'not-allowed'}"
      style="cursor: pointer; border: 0; border-radius: 5px"
    >
      <div
        class="card-body text-center"
        style="
          height: 120px;
          box-shadow: 0px 5px 7px 1px rgba(64, 112, 244, 0.1);
          border-radius: 5px;
        "
      >
        <img  src="assets/images/order_ring.svg" />
        <p
          class="card-title mt-3"
          style="
            font-family: Rubik;

            font-size: 14px;
            font-weight: 600;
            text-align: center;
          "
        >
      Create New Order
        <br>
          <span style="color: #1f183799">(Ctrl+O)</span>
        </p>
      </div>
    </div>
  </div>

  <div class="col-md-2" style="padding-left: 0">
    <div class="card" (click)="navigateToBuy()" style="cursor: pointer; border: 0; border-radius: 5px">
      <div class="card-body text-center"
        style="height: 120px;box-shadow: 0px 5px 7px 1px rgba(64, 112, 244, 0.1);border-radius: 5px;">
        <img  src="assets/images/adv_booking.svg" />
        <p class="card-title mt-3" style="font-family: Rubik;font-size: 14px;font-weight: 600;text-align: center;">
          Digital Gold Buy <br>
          <span style="color: #1f183799">(Ctrl+I)</span>
        </p>
      </div>
    </div>
  </div>

  <div class="col-md-2" style="padding-left: 0">
    <div class="card" (click)="navigateToDelivery()" style="cursor: pointer; border: 0; border-radius: 5px">
      <div class="card-body text-center"
        style="height: 120px;box-shadow: 0px 5px 7px 1px rgba(64, 112, 244, 0.1);border-radius: 5px;">
        <img  src="assets/images/order_ring.svg" />
        <p class="card-title mt-3" style="font-family: Rubik;font-size: 14px;font-weight: 600;text-align: center;">
          Digital Gold Delivery <br>
          <span style="color: #1f183799">(Ctrl+I)</span>
        </p>
      </div>
    </div>
  </div> -->
</div>

