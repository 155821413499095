import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
// import { CatalogueService } from '../catalogue/catalogue-service.service';

@Component({
  selector: 'app-show-all-images-dialog',
  templateUrl: './show-all-images-dialog.component.html',
  styleUrls: ['./show-all-images-dialog.component.css']
})
export class ShowAllImagesDialogComponent {
  @Input() id!: number;
  files: any[] = [];
  @Input() filesAssets: any[] = [];
  selectedImage: string = '';

  constructor(
    private modalService:NgbModal,
    public activeModal: NgbActiveModal, 
    private http: HttpClient,
    // private catalogueService: CatalogueService,

  ){}

  closeModal(){
    this.modalService.dismissAll();
    // this.catalogueComponent.ngOnInit();
  }

  selectImage(image: string) {
      this.selectedImage = image;
  }

  ngOnInit() {
    this.selectedImage = this.filesAssets[0].file;
  }
}
