export class AssetFile {
    id!: number;
    file!: string;
    file_l!: string;
    file_xl!: string;
    file_xxl!: string;
    icon!: string;
    file_type!: number;
}

export class Purity {
    id!: number;
    name!: string;
}

export class JewelleryModel {
    id!: number;
    asset_files!: AssetFile[];
    name!: string;
    material!: number;
    purity!: Purity[];
    category!: number;
    status!: number;
}

export class JewelleryApiResponse {
    count!: number;
    next!: string | null;
    previous!: string | null;
    results!: JewelleryModel[];
}
