import { Injectable } from '@angular/core';
import { Rest } from 'src/app/models/rest_model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PendingInstallment } from 'src/app/models/installments';


@Injectable({
  providedIn: 'root'
})
export class PendingInstallmentService {

  pending_installment_url = environment.ip + "shop/pending-installments/"
  constructor(private http: HttpClient) { }

  getPendingPlans(page: number, search: string, ordering: string, status: string) {
    return this.http.get<Rest<PendingInstallment>>(this.pending_installment_url, { params: { "search": search, "page": page, "ordering": ordering, "payment_status": status } });
  }
  downloadPendingPlans(fd:FormData){
    return this.http.post(this.pending_installment_url+'download/',fd);
  }
}
