import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnquiryService {
  private sendOtpUrl = environment.ip + 'shop/enquiry/sendOtp';
  private otpVerificationUrl = environment.ip + 'shop/enquiry/verifyOtp';
  private verficationStatusUrl = environment.ip + 'shop/enquiry/getEnquiryNumber/'
  private saveEnquiryNumberUrl = environment.ip + 'shop/enquiry/saveEnquiry'

  constructor(private http: HttpClient) {}

  sendOTP(phoneNumber: string): Observable<any> {
    const url = `${this.sendOtpUrl}/`;
    const data = { 'whatsapp_number': phoneNumber };
    return this.http.post(url, data);
  }

  validateOTP(phoneNumber: string, otp: string): Observable<any> {
    const url = `${this.otpVerificationUrl}/`;
    const data = { 'whatsapp_number': phoneNumber, 'otp': otp };
    console.log(otp);
    return this.http.post(url, data);
  }
  checkVerificationStatus() {
    return this.http.get<any>(this.verficationStatusUrl);
  }
  saveEnquiryNumber(phoneNumber: string, otp: string): Observable<any> {
    console.log('reached here', otp)
    const url = `${this.saveEnquiryNumberUrl}/`;
    // const data = { 'whatsapp_number': phoneNumber, 'otp': otp };
    const data = { 'whatsapp_number': phoneNumber };

    console.log('otp we received is', otp);
    return this.http.post(url, data);
    // return this.http.get<any>(this.saveEnquiryNumberUrl);
  }
}
