<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:50vh;">
    <div class="row side">
        <h3 (click)="back()" class="back_title"> <mat-icon class="right_back">arrow_back_ios</mat-icon>Create  Account</h3>
    </div>
    <form #f="ngForm" (ngSubmit)="onSubmit()">
      <div ngModelGroup = "userData"></div>
      <div class="row side">
        <div class="col-md-4">
            <h3>Enter mobile Number*</h3>
            <div class="input-group mb-5">
                <span class="input-group-text">+91</span>
                <div class="form-floating">
                  <input required type="tel" class="form-control" name="phoneNumber" ngModel>
                  <label for="floatingInputGroup1">Customer Phone Number</label>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <h3>Enter full name*</h3>
            <input type="text" required name="Name" class="form-control" ngModel>
        </div>
        <div class="col-md-4"></div>       
    </div>
    <div class="row side">
        <div class="col-md-4">
            <h3>Enter date of birth</h3>
            <form class="row row-cols-sm-auto">
                  <div class="input-group">
                    <button
                      class="btn btn-outline-secondary bi bi-calendar3"
                      (click)="d.toggle()"
                      type="button"></button>
                    <input
                      class="form-control"
                      placeholder="--/--/----"
                      required ngModel name="dob"
                      [(ngModel)]="model"
                      ngbDatepicker
                      [dayTemplate]="customDay"
                      #d="ngbDatepicker"/>
                  </div>
              </form>
        </div>
        <div class="col-md-4 side1"> 
            <h3>Select gender*</h3>
            <select class="form-select mt-1"  ngModel name="gender"  style="color: #3B325F;font-size:18px; height: 50px;color: #9A9AB0;">
                <option value="IP">Male</option>
                <option value="CP">Female</option>
                <option value="CL">Transgender</option>
              </select>
        </div>
        <div class="col-md-4"></div>
    </div><br>
    <div class="row side">
        <div class="col-md-8">
            <h3>Enter email</h3> 
            <input type="email" required class="form-control" ngModel name="email" email  #email ="ngModel">
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row side">
      <div class="col-md-8">
          <h3>Enter Address</h3> 
          <input type="address" required class="form-control add" ngModel name="address" >
      </div>
      <div class="col-md-4"></div>
  </div>
    <div class="row side">
        <div class="col-md-4">
            <h3>Enter pincode*</h3>
            <input type="pincode" required class="form-control" ngModel name="pincode">
        </div>
        <div class="col-md-4">
          <h3>State</h3>
          <select class="form-select mt-1"style="color: #3B325F;font-size:18px; height: 50px;  color: #9A9AB0;">
            <option value="">--</option>
            <option value="IP">Andhra Pradesh</option>
            <option value="CP">Arunachal Pradesh</option>
            <option value="CL">Assam</option>
            <option value="CL">Bihar</option>
            <option value="CL">Chhattisgarh</option>
            <option value="CL">Goa</option>
            <option value="CL">Gujarat</option>
            <option value="CL">Haryana</option>
            <option value="CL">Himachal Pradesh</option>
            <option value="CL">Jharkhand</option>
            <option value="CL">Karnataka</option>
            <option value="CL">Kerala</option>
            <option value="CL">Madhya Pradesh</option>
            <option value="CL">Maharashtra</option>
            <option value="CL">Manipur</option>
            <option value="CL">Meghalaya</option>
            <option value="CL">Mizoram</option>
            <option value="CL">Nagaland</option>
            <option value="CL">Odisha</option>
            <option value="CL">Punjab</option>
            <option value="CL">Rajasthan</option>
            <option value="CL">Sikkim</option>
            <option value="CL">Tamil Nadu</option>
            <option value="CL">Telangana</option>
            <option value="CL">Uttarakhand</option>
            <option value="CL">	Uttar Pradesh</option>
            <option value="CL">West Bengal</option>


          </select>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row side">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <h3></h3>
        <button class="btn btn-primary save" type="submit" [disabled]="!f.valid">Save</button>
      </div>
      <div class="col-md-4"></div>
    </div>
    </form>
</div>
  <ng-template
    #customDay
    let-date
    let-currentMonth="currentMonth"
    let-selected="selected"
    let-disabled="disabled"
    let-focused="focused"
  >
    <span
      class="custom-day"
      [class.focused]="focused"
      [class.bg-primary]="selected"
      [class.hidden]="date.month !== currentMonth"
      [class.text-muted]="disabled"
    >
      {{ date.day }}
    </span>
  </ng-template>
  