// import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { ApiServiceService } from './api-service.service';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from 'src/app/models/message';
import { SettlementServiceService } from 'src/app/report/reports/settlement-service.service';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { PopUpManager } from 'src/app/manager/Popup';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopupService } from '@ng-bootstrap/ng-bootstrap/util/popup';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { UserDataSource } from './select-dataSource';
import { ApiServiceService } from './api-service.service';
import { User } from 'src/app/models/user_model';
import { Title } from '@angular/platform-browser';
import { TitleService } from 'src/app/services/title.service';
import { ActionService } from 'src/app/services/actions.service';

export interface Data {
  userId: string;
  id: number;
  title: number;
  completed: boolean;
  isSelected: boolean;
  first_name: string;
}
const ELEMENT_DATA: Data[] = [];
export class SelectDisabledExample {
  disableSelect = new FormControl(false);
}

@Component({
  selector: 'app-select-users',
  templateUrl: './select-users.component.html',
  styleUrls: ['./select-users.component.css'],
})
// export class SelectUsersComponent implements OnInit {
//   ab:boolean = false;
//   displayedColumns: string[] = ['plan_id','plan_name','customer_name','phone','plan_type','cost','duration','start_date','total_or_weight','status','installments'];
//   dataSource : any[] = [];

//   constructor(private modalService:NgbModal,private service : ApiServiceService) {
//     this.service.getData().then((data) => {
//       this.dataSource = data;
//     });

//   }

//   ngOnInit(): void {
//   }

//   Onclick(){
//     this.ab = !this.ab
//   }
//   open_sent(content:any){
//     this.modalService.open(content);
//   }

// }
export class SelectUsersComponent implements OnInit {
  allowed!: any;
  displayedColumns: string[] = ['select', 'phone', 'customer_name'];
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  dataSource!: UserDataSource;
  responseMessage!: ResponseMessage;
  popupManager!: PopUpManager;
  currentDate = new Date();
  from_date: string = '';
  to_date: string = '';
  ordering: string = 'id';
  search = '';
  sort!: MatSort;
  element: any;
  listOfUser: any[] = [];
  send_users: any[] = [];
  showUser: any[] = [];
  isToggled: boolean = false;
  ab: boolean = false;
  private saveUsername: boolean = true;
  selection = new SelectionModel<Data>(true, []);

  limit!: any;
  current_selected = 0;
  allSel: any;
  count_selected = 0;

  constructor(
    private titleService: TitleService,
    private settlementService: ApiServiceService,
    private modalService: NgbModal,
    private router: Router,
    private _location: Location,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private actionService: ActionService
  ) {}

  searchOption() {
    this.resetAndDisplayTable();
  }
  ngOnInit(): void {
    console.log(localStorage.getItem('deep_selection')!)
    this.allowed = this.actionService.getActions();

    this.titleService.setTitle('Send Notifications');
    this.popupManager = new PopUpManager(this.snackBar);
    this.dataSource = new UserDataSource(this.settlementService);
    this.dataSource.loadData(0, this.from_date, this.to_date, this.search);
  }

  clearInput() {
    this.search = '';
  }

  Onclick() {
    this.ab = !this.ab;
  }
  open_sent(content: any) {
    if (this.send_users.length == 0 && this.isToggled == false) {
      this.toastr.info(
        '',
        'You have not selected any user, please do select minimum one user to send notification',
        { positionClass: 'toast-bottom-right' }
      );
      return;
    }
    var title = localStorage.getItem('title')!;
    var description = localStorage.getItem('description')!;
    var web_url = localStorage.getItem('web_url')!;
    var deep_selection = localStorage.getItem('deep_selection')!;

    this.settlementService
      .notifyUsers(
        title,
        description,
        this.send_users,
        web_url,
        deep_selection,
        this.isToggled
      )
      .subscribe(
        (data) => {
          this.modalService.open(content,{centered:true});
        },
        (error: any) => {
          this.toastr.info(
            '',
            'Something went wrong, please do try again later.',
            { positionClass: 'toast-bottom-right' }
          );
        }
      );
  }
  onLessonToggled(element: User) {
    //this.selection.toggle(element);
    element.isSelected = !element.isSelected;

    if (element.isSelected) {
      this.count_selected += 1;
      this.listOfUser.push({ id: element.id, name: element.first_name });
      this.send_users.push(element.id);
    } else {
      this.count_selected -= 1;
      this.listOfUser = this.listOfUser.filter(function (obj) {
        return obj.id !== element.id;
      });

      this.send_users = this.send_users.filter(function (obj) {
        return obj !== element.id;
      });
    }

    this.showUser = this.listOfUser.map((val) => val.name);
    this.current_selected = this.showUser.length;
  }

  isAllSelected() {
    return this.selection.selected?.length == this.element?.length;
  }
  toggelAll() {
    this.count_selected = this.paginator.length;
    this.isToggled = !this.isToggled;

    if (!this.isToggled) {
      this.count_selected = 0;
    }
    this.allSel = true;
    this.listOfUser = [];
    this.send_users = [];

    this.dataSource.connect().subscribe((count) => {
      count.forEach((val: any) => {
       
        val['isSelected'] = this.isToggled;
      });
    });
  }

  deselectRow(id:any)
  {
    this.dataSource.connect().subscribe((count) => {
      count.forEach((val: any) => {
        if (val.id==id)
        {
          val['isSelected'] = false;
        }

      });
    });
  }

  back() {
    this._location.back();
  }

  openHome() {
    this.modalService.dismissAll();
    this.router.navigate(['/dashboard/notification']);
  }

  // sendMessage() {
  //   var title = localStorage.getItem('title')!;
  //   var description = localStorage.getItem('description')!;
  //   var web_url = localStorage.getItem('web_url')!;
  //   var deep_selection = localStorage.getItem('deep_selection')!;
  //   console.log(deep_selection)
  //   this.settlementService.notifyUsers(
  //     title,
  //     description,
  //     this.send_users,
  //     web_url,
  //     deep_selection,
  //     this.isToggled
  //   );
  // }

  ngAfterViewInit(): void {
    // if(this.sort.sortChange.subscribe(()=> {
    //   this.sortbyId()
    // }))

    this.dataSource.counter$.subscribe((count) => {
      this.paginator.length = count;
    });
    this.paginator.page.subscribe(() => {
      this.dataSource.loadData(
        this.paginator.pageIndex,
        this.from_date,
        this.to_date,
        this.search
      );
    });
  }

  sortbyId() {
    if (this.sort.direction == 'asc') {
      this.ordering = 'id';
      this.resetAndDisplayTable();
    }
    if (this.sort.direction == 'desc') {
      this.ordering = '-id';
      this.resetAndDisplayTable();
    }
  }

  resetAndDisplayTable() {
    this.paginator.pageIndex = 0;
    this.dataSource.loadData(0, this.from_date, this.to_date, this.search);
  }

  searchData() {
    this.search = (<HTMLInputElement>document.getElementById('search')).value;
    this.popupManager.openSnackBar('Loading ...', 'close');

    this.resetAndDisplayTable();
  }

  // submit(){
  //   this.dataSource.connect().subscribe(data=>{
  //     data.forEach((res:any)=>{
  //       var fd = new FormData();
  //       fd.append("plan_id",res.plan_id);
  //       fd.append("customer",res.customer_name);
  //       fd.append("phone",res.phone);
  //       this.settlementService.saveReport(fd).subscribe((res:any)=>{
  //       console.log(data)
  //     })
  //     })
  //   })

  // }

  // downloadReport(){
  //   var fd = new FormData();
  //   fd.append("from_date",this.from_date);
  //   fd.append("to_date",this.to_date);
  //   this.settlementService.downloadAllOrders(fd).subscribe((data : any)=>{
  //     this.responseMessage = data;
  //     this.toastr.success("",this.responseMessage.message, { positionClass: "toast-bottom-right" });
  //   })
  // }

  notifi(content: any) {
    this.modalService.open(content, { centered: true });
  }
  actionsCheck(value: any) {
    if (this.allowed == 'admin') {
      return true;
    }
    if (this.allowed.includes(value)) {
      return true;
    } else {
      return false;
    }
  }
}
