import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { param } from 'jquery';
import { ResponseMessage } from 'src/app/models/message';
import { Rest } from 'src/app/models/rest_model';
import { Settlement, SettlementOrder } from 'src/app/models/settlement';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettlementServiceService {
  settlement_url = environment.ip + 'shop/settlements/';
  settlement_download_url = environment.uploaderIp+'shop/settlements/';
  settlement_detail_url = environment.ip + 'shop/settlement_details/';
  orders_url = environment.ip + 'shop/orders/';
  download_orders_url = environment.uploaderIp+'shop/orders/';
  //commenting
  constructor(private http: HttpClient) {}
  getSettlementLogs(page: number, from_date: string, to_date: string) {
    return this.http.get<Rest<Settlement>>(this.settlement_url, {
      params: { from_date: from_date, to_date: to_date, page: page },
    });
  }

  getAllSettlementOrders(
    page: number,
    from_date: string,
    to_date: string,
    search: string,
    settlement_id: string = ''
  ) {
    return this.http.get<Rest<SettlementOrder>>(this.settlement_detail_url, {
      params: {
        from_date: from_date,
        to_date: to_date,
        page: page,
        search: search,
        settlement_id: settlement_id,
      },
    });
  }

  getSettlementOrders(
    url_id: string,
    search: string,
    from_date: string,
    to_date: string
  ) {
    return this.http.get<Array<SettlementOrder>>(
      this.settlement_url + url_id + '/get_orders/',
      { params: { from_date: from_date, to_date: to_date, search: search } }
    );
  }

  getDetailReports(
    page: number,
    from_date: string,
    to_date: string,
    search: string,
    filter: string,
    settlement_id: string = '',



  ) {

    var online = "";
    var selected_types = filter.split(',');

    selected_types.forEach((element: any, index: any) => {
      if (element == '999') {
        online = "true";
        selected_types.splice(index, 1);
      }
    });


    return this.http.get<Rest<SettlementOrder>>(this.orders_url, {
      params: {
        from_date: from_date,
        to_date: to_date,
        page: page,
        search: search,
        settlement_id: settlement_id,
        payment_mode__in: `${selected_types.toString()}`,
        order__is_online_mode: online,
      },
    });
  }

  downloadReport(f_date: any, t_date: any, email: any) {
    var fd = new FormData();
    fd.append('from_date', f_date);
    fd.append('to_date', t_date);
    fd.append('email', email);
    return this.http.post(
      this.settlement_download_url + 'download_reports/',
      fd
    );
  }
  downloadOrders(settlement_id: string, fd: FormData) {
    return this.http.post<ResponseMessage>(
      this.settlement_download_url + settlement_id + 'download_orders/',
      fd
    );
  }


  downloadSettlementDetails(from_date: any, to_date: any, email: any,settlement_id:any) {
    var fd = new FormData();
    fd.append('from_date', from_date);
    fd.append('to_date', to_date);
    fd.append('email', email);
    fd.append('settlement_id',settlement_id)
    return this.http.post<ResponseMessage>(
      this.settlement_detail_url + 'download_settlement_orders/',
      fd
    );
  }

  downloadAllOrders(f_date: any, t_date: any, email: any) {
    var fd = new FormData();
    fd.append('from_date', f_date);
    fd.append('to_date', t_date);
    fd.append('email', email);
    return this.http.post(
      this.download_orders_url + 'download_orders/',
      fd
    );
  }
  downloadAllAdvanceOrders(f_date: any, t_date: any, email: any) {
    var fd = new FormData();
    fd.append('from_date', f_date);
    fd.append('to_date', t_date);
    fd.append('email', email);
    return this.http.post(
      this.download_orders_url + 'download_advance_orders/',
      fd
    );
  }
  downloadAllOMSOrders(f_date: any, t_date: any, email: any) {
    var fd = new FormData();
    fd.append('from_date', f_date);
    fd.append('to_date', t_date);
    fd.append('email', email);
    return this.http.post(
      this.download_orders_url + 'download_oms_orders/',
      fd
    );
  }
  downloadAllRepairOrders(f_date: any, t_date: any, email: any) {
    var fd = new FormData();
    fd.append('from_date', f_date);
    fd.append('to_date', t_date);
    fd.append('email', email);
    return this.http.post(
      this.download_orders_url + 'download_repair_orders/',
      fd
    );
  }
  downloadAllCancelOrder(f_date: any, t_date: any, email: any) {
    var fd = new FormData();
    fd.append('from_date', f_date);
    fd.append('to_date', t_date);
    fd.append('email', email);
    return this.http.post(
      this.download_orders_url + 'download_cancellation/',
      fd
    );
  }

  downloadDigitalCoinReport(f_date: any, t_date: any, email: any) {
    var fd = new FormData();
    fd.append('from_date', f_date);
    fd.append('to_date', t_date);
    fd.append('email', email);
    return this.http.post(this.download_orders_url + 'download_digital_coin_orders/',fd);
  }
  saveReport(fd: FormData) {
    return this.http.post<ResponseMessage>(
      this.orders_url + 'download_orders/',
      fd
    );
  }


 
}
