import { DataSource } from "@angular/cdk/collections";
import { PaymentService } from "./payment.service";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { offlineBankAccounts } from "../models/offlinebankaccount";
import { catchError, finalize } from 'rxjs/operators';



export class PaymentDataSource extends DataSource<any>
{

    private bankAccountData=new BehaviorSubject<offlineBankAccounts[]>([]);
    private loadingAccountData = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingAccountData.asObservable();   
    private countAccountData=new BehaviorSubject<number>(0);
    public counter$=this.countAccountData.asObservable();


    constructor(private paymentservice:PaymentService) {
      super();
    }

    connect(): Observable<offlineBankAccounts[]> {
        return this.bankAccountData.asObservable();
    
      }
      disconnect() { 
        this.bankAccountData.complete();
        this.loadingAccountData.complete();
      }

      
     loadbankAccount(type:any)
     {
      this.loadingAccountData.next(true);
      this.paymentservice.getBankAccount(type=type).pipe(
        catchError(()=> of([])),
        finalize(()=>{
          this.loadingAccountData.next(false);
        })
        
      ).subscribe(accountLogs=>
        
        
        {
          this.renderAccountDetails(accountLogs)})
    
  }
  renderAccountDetails(accountLogs:any){
      this.countAccountData.next(accountLogs.count);
      
      this.bankAccountData.next(accountLogs.results);

    }

     }





