import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessDeniedModalComponent } from '../access-denied-modal/access-denied-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalService: NgbModal) { }

  openAccessDeniedModal() {
    this.modalService.open(AccessDeniedModalComponent, { centered: true });
  }
}
