import { StringLike } from "@firebase/util";

export class GraphData {
   
    date!:string;
    count!:number;
    type!:string;
}




export class ListGraphData
{
    activation_list!:GraphData[];
}

export class Doughnut
{
    total_subscription!:number;
    total_count!:number;
    in_progress_subscription!:number;
    in_progress_count!:number;
    completed_subscription!:number;
    completed_count!:number;
    cancelled_subscription!:number;
    cancelled_count!:number;
    total_booking!:number;
    total_booking_count!: number;
    in_progress_advance!:number;
    in_progress_advance_count!: number;
    completed_advance_count!: number;
    completed_advance!: number;
    cancelled_advance_count!: number;
    cancelled_advance!: number;

}

