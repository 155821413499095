import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { EnquiryService } from './enquiry-service.service';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {
  @ViewChild('otp_dialog') otp_dialog!: TemplateRef<any>;

  isVerified: boolean = false;

  phoneNumber: string = '';
  isPhoneNumberValid: boolean = false;
  isOTPDialogOpen: boolean = false;
  otp: string = '';
  countdown: number = 60;
  resendDisabled: boolean = true; // Set to true initially
  countdownInterval: any;
  resendClicked: boolean = false;
  otpInputValue: string = '';
  otpValid: boolean = false;
  isOtpDialogOpen: boolean = false;
  otpIncorrect: boolean = false;



  ngOnInit(): void {
    this.openSpinnerDialog();
    this.checkVerificationStatus();

  }

  constructor(
    private enquiryService: EnquiryService,
    private modalService:NgbModal,
    private dialog: MatDialog,
  ){
  }

  // Validate that only numbers are allowed in the input field
  validatePhoneNumber(event: any): void {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^\d+$/.test(inputChar)) {
      event.preventDefault();
    }
  }
  verifyPhoneNumber() {
    if (this.isPhoneNumberValid) {
      // this.openOTPDialog(this.otp_dialog)
      // // Call the sendOTP method from OtpService
      // this.enquiryService.sendOTP(this.phoneNumber).subscribe(
      //   response => {
      //     if (response.success) {
      //       this.openOTPDialog(this.otp_dialog);
      //     } else {
      //       console.log(response.message);
      //     }
      //   },
      //   error => {
      //     console.log('Error sending OTP: to you', error);
      //   }
      // );
      this.enquiryService.saveEnquiryNumber(this.phoneNumber, this.otpInputValue).subscribe(
        response => {
          console.log(response)
          this.isVerified = true;
          localStorage.setItem('whatsapp_number', this.phoneNumber);
        }
      )
    } else {
      console.log('Invalid phone number');
    }
  }
  editPhoneNumber() {
    this.isVerified = false;
    // this.phoneNumber = ''; // Clear the phone number input
}
  // Update the button state based on the phone number's length
  updateVerifyButtonState(): void {
    this.isPhoneNumberValid = this.phoneNumber.length === 10;
  }

  openOTPDialog(content:any) {
    this.modalService.open(content, { centered: true, size: <any>'l' });
    this.startCountdown();

  }
  onOtpChange(otp: string) {
    this.otpInputValue = otp;
    this.otpValid = otp.length === 4;
  }
  
  closeOTPDialog(){
    this.modalService.dismissAll();
    this.otpInputValue = ''
    this.otpIncorrect = false;
    if (this.countdownInterval) {
      // Clear the countdown interval
      clearInterval(this.countdownInterval);
      this.countdown = 60;
    }
  
  }

  startCountdown() {
    this.countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(this.countdownInterval);
        this.resendClicked = true;
      }
    }, 1000);
  }
  openSpinnerDialog(): void {
    const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('spinner Dialog closed:', result); // Handle dialog close event
    });
  }

  resendOTP() {
    // Add your OTP resend logic here
    this.resendClicked = false;
    this.countdown = 60;
    this.closeOTPDialog()
    // this.startCountdown();
    this.verifyPhoneNumber();
  }
  ngOnDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }
  checkVerificationStatus() {
    this.enquiryService.checkVerificationStatus()
      .subscribe(
        response => {
          // Assuming the response contains a property indicating verification status
          // this.isVerified = response.isVerified;
          // this.phoneNumber = response.phoneNumber;
          if(response.detail = "success"){
            console.log(response)
            if(response.whatsapp_number != null){
            this.phoneNumber = response.whatsapp_number;
            localStorage.setItem('whatsapp_number', response.whatsapp_number);
            this.isVerified = true
            this.dialog.closeAll();

          } else {
            this.dialog.closeAll();
            this.isVerified = false;
          }} else {
            this.dialog.closeAll();
            this.isVerified = false;
          }
        },
        error => {
          console.error('Error fetching verification status', error);
          this.dialog.closeAll();

        }
      );
  }

  verifyOTP() {
    // if (this.otp.length === 4) {
    //   // Perform OTP verification logic
    //   // For example, you can send the OTP to a server for verification
    //   console.log('Verifying OTP:', this.otp);
    // } else {
    //   console.log('Invalid OTP length');
    // }
    if (this.otpValid) {
      // Call the validateOTP method from OtpService
      this.enquiryService.validateOTP(this.phoneNumber, this.otpInputValue).subscribe(
        response => {
          if (response.success) {
            console.log('OTP validation successful');
            // Perform further actions after OTP validation
            // For example, close the OTP dialog and proceed with the application flow
            this.closeOTPDialog();
          } else {
            console.log(response.message);
            this.otpIncorrect = false;
            this.enquiryService.saveEnquiryNumber(this.phoneNumber, this.otpInputValue).subscribe(
              response => {
                console.log(response)
                this.isVerified = true;
                localStorage.setItem('whatsapp_number', this.phoneNumber);
              }
            )
            this.closeOTPDialog();

          }
        },
        error => {
          // this.otpInputValue = '';
          // console.log(this.otpInputValue)
          this.otpIncorrect = true;
          console.log('Error validating OTP:', error);
        }
      );
    } else {
      console.log('Invalid OTP length');
    }
  }
  
}
