import {  DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Rest } from "src/app/models/rest_model";
import { User } from "src/app/models/user_model";
import { ApiServiceService } from "./api-service.service";


export class UserDataSource extends DataSource<any> {

  private userData=new BehaviorSubject<User[]>([]);
  private loadingUsers = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingUsers.asObservable();
  private countUsers=new BehaviorSubject<number>(0);
  public counter$=this.countUsers.asObservable();

  

  constructor(private apiService:ApiServiceService) {
    super();
  }
  connect(): Observable<User[]> {
    return this.userData.asObservable();

  }
  disconnect() { 
    this.userData.complete();
    this.loadingUsers.complete();
  }
  loadData(page:number=0,from_date: any,to_date: any,search:any){


      page=page+1;
      this.loadingUsers.next(true);
      this.apiService.getAllUsers(page=page,search=search).pipe(
        catchError(()=> of([])),
        finalize(()=>{
          this.loadingUsers.next(false);
        })
        
      ).subscribe((settlementLogs: any)=>this.renderOrders(settlementLogs))

  }

  renderOrders(settlementLogs:any){
    this.countUsers.next(settlementLogs.count);
    this.userData.next(settlementLogs.results);

  }




}