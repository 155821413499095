import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { CatalogueService } from '../catalogue/catalogue-service.service';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AnalyticService } from '../services/analytics.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {
  @Input() id!: number;
  files: any[] = [];
  @Input() filesAssets: any[] = []; // List of files/assets to display


  constructor(
    private modalService:NgbModal,
    public activeModal: NgbActiveModal, 
    private http: HttpClient,
    private catalogueService: CatalogueService,
    private dialog: MatDialog,
    public toastr: ToastrService,
    private analyticService: AnalyticService
  ){}
  ngOnInit(): void {
    

  }
  openSpinnerDialog(): void {
    const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('spinner Dialog closed:', result); // Handle dialog close event
    });
  }

  closeModal(){
    this.modalService.dismissAll();
    // this.catalogueComponent.ngOnInit();
  }
  // ngOnInit() {
  //   // Perform an HTTP request to fetch files based on the id
  //   this.http.get(`your_api_endpoint/${this.id}`).subscribe((data: any) => {
  //     this.files = data; // Store the fetched files in the 'files' array
  //   });
  // }

  confirmDelete() {
    this.openSpinnerDialog();
    // Perform a DELETE request to delete the files
    this.catalogueService.deleteJewellery(this.id).subscribe((data: any) => {
      console.log(data);
      if (data['success'] == 'deleted') {
        console.log('deleted');
        this.closeModal();    
        this.dialog.closeAll();
        this.analyticService.logEvent("delete_jewellery");
      }
    }, error => {
      this.closeModal();    
      this.dialog.closeAll();

      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
  }


}
