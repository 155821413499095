
    <div class="myModal">
      <div class="modal-content p-4" style="width: 100%">
        <div class="modal-header" style="border: 0; height: 0">
          <h1 class="modal-title fbold"></h1>
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <img
              src="assets/images/errorsign.svg"
              class="img-responsive img-fluid"
            />
  
            <h1 class="fbold mt-2">Access Denied!</h1>

            <p
              class="greyColor"
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
  
                text-align: center;
  
                color: #606060;
              "
            >
            Your plan doesn't have access to this feature. Contact
            our sales team to get this access
            </p>
  
            <button
              (click)="sendQuery();closeModal()"
              type="button"
              class="btn btn-outline-primary pause_btn mt-3"
            >
            Contact Now
            </button>
          </div>
        </div>
      </div>
    </div>


