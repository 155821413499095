import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvoiceDetails } from 'src/app/models/installments';
import { CreateOrderService } from 'src/app/oms/create-order/create-order.service';

@Component({
  selector: 'app-view-order-mobile',
  templateUrl: './view-order-mobile.component.html',
  styleUrls: ['./view-order-mobile.component.css'],
})
export class ViewOrderMobileComponent {
  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  expandedIndex = 0;
  order_id!: Number;
  order_detail!: any;
  invoice_details: InvoiceDetails = new InvoiceDetails({});
  constructor(
    private route: ActivatedRoute,
    private orderSerive: CreateOrderService
  ) {}

  ngOnInit() {
    this.order_id = Number(this.route.snapshot.paramMap.get('id'));
    if (this.order_id) {
      this.orderSerive
        .getOrder(Number(this.order_id))
        .subscribe((data: any) => {
          console.log(data);
          this.order_detail = data;
          this.invoice_details=new InvoiceDetails(this.order_detail.payment_detail)
          this.getTotalWeight();
        });
    }
  }

  getTotalWeight() {
    var total_weight;
    var total_amount;
    var total_og_weight;
    var total_og_amount;

    total_weight = this.order_detail.custom_order_item.reduce(
      (acc: any, curr: any) => {
        return Number(acc) + Number(curr.weight);
      },
      0
    );
    total_amount = this.order_detail.custom_order_item.reduce(
      (acc: any, curr: any) => {
        return Number(acc) + Number(curr.amount);
      },
      0
    );
    total_og_weight=this.order_detail.old_golds.reduce(
      (acc: any, curr: any) => {
        return Number(acc) + Number(curr.weight);
      },
      0
    );
    total_og_amount=this.order_detail.old_golds.reduce(
      (acc: any, curr: any) => {
        return Number(acc) + Number(curr.amount);
      },
      0
    );
    this.order_detail.total_weight = total_weight;
    this.order_detail.total_amount = total_amount;
    this.order_detail.total_og_weight= total_og_weight;
    this.order_detail.total_og_amount= total_og_amount;

    console.log(this.order_detail);
  }

}
