

<div  class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:10vh;">

  <div class="container">
    <!--  Start: no images found section-->
    <div class="no-designs-container mt-5 align-items-center" *ngIf="!has_images">
      <img class="no_designs_illus_img" src="assets/images/no_designs_illus.png" alt="No Designs Banner">
      <h1>No jewellery designs found</h1>
      <p>Get your business online, Start uploading</p> <br>
      <input type="file" id="uploadButton" multiple (change)="onFileSelected($event)">
      <button class="upload-btn" type="button" (click)="openUploadDialog(0)">Upload</button>

    </div>
    <!--End: no images found section-->

    <!--Start: existing-catalog-image-section-->
    <div *ngIf="has_images">
      <div class="top-action-box" style="margin-right: 10px;">
        <div class="row" style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
          <div class="input-group">
            <mat-icon class="greyColor searchIcon">search</mat-icon>
            <input style="border: 0" [(ngModel)]="search" type="text"
              class="form-control search_box roundSquare text-indent" placeholder="Search"
              (keyup.enter)="searchOption(1); txtInput.blur()" #txtInput />
            <mat-icon *ngIf="search" class="crossIcon" (click)="clearInput(); searchOption(1)"
              [ngStyle]="{ color: '#9A9AB0' }">cancel</mat-icon>
            <button (click)="searchOption(1)" mat-raised-button color="primary" class="searchButton"
              style="background: #4070f4; color: #ffffff; box-shadow: none">
              Search
            </button>
          </div>
          <button class="filter-btn" (click)="openCategory(categoryMetalPurity, 0)">
            <img class="filter-icon" src="assets/images/filter_icon.svg" alt="Filter Icon" width="24" height="24"
              style="justify-content: center; align-items: center;">
          </button>
        </div>
        <div class="row" *ngIf="filterApplied" style="cursor: pointer;">
          <div class="col-2 pt-3 pb-0" style="padding-left: 20px; text-align: left; color:red" (click)="clearFilters()">
            <span style="display: flex; flex-direction: row;">
              <mat-icon>cancel</mat-icon>
              <p class="pt-1 px-2" style="color:red">Clear all filters</p>
            </span>
          </div>
        </div>
      </div>
      <div class="floating-menu" *ngIf="is_bottom_modal_visible"
        style="background-color: white; margin-right: 0px; border-radius: 10px; top: 0%">
        <div style="display: flex; flex-direction: column; margin: 10px">

          <div
            style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; margin-top: 5px; margin-bottom: 5px;">
            <div style="display: flex; flex-direction: row;">
              <div style="display:flex; align-items:center;">
                <!-- custom checkbox -->
                <mat-checkbox style="align-items: start; display: flex; flex-direction: column; margin-right: 10px;"
                  [checked]="is_all_items_selected" (click)="toggleAllSelection()">
                </mat-checkbox>
                <!-- End custom checkbox -->
              </div>
              <div class="bold" style="display:flex; font-size:16px; color: #262626;">
                <b>{{this.selected_files.size}} / 25</b>
              </div>
            </div>
            <div style="display: flex; flex-direction: row;">
              <div style="display:flex; flex-grow:3">
                <ng-template #mergeTip><b>Merge</b></ng-template>
                <div class="img-btn" (click)="mergeSelected(merge_modal)">
                  <img src="assets/images/merge-icon.png" style="object-fit:contain" [ngbTooltip]="mergeTip"
                    height="44px" width="44px" alt="merge button">
                </div>
              </div>

              <div style="display:flex;flex-grow:3">
                <ng-template #deleteTip><b>Delete</b></ng-template>
                <div (click)="deleteMultiple()" class="img-btn">
                  <img src="assets/images/delete-icon.png" style="object-fit:contain" [ngbTooltip]="deleteTip"
                    height="24px" width="24px" alt="delete button">
                </div>
              </div>
            </div>
          </div>

          <div style="display: flex; flex-direction: row; margin-bottom: 10px;">
            <div style="display:flex; width: 100%;">
              <div *ngIf="selected_category != null" class="category-drop-down-box form-control"
                style="align-items: center; margin-right: 5px;" [matMenuTriggerFor]="categoryMenu"
                (menuOpened)="openingCategory(0)" #menuTrigger="matMenuTrigger">
                <p style="align-items: center; justify-content: center; margin-bottom: 5px;" id="selcted-category"
                  *ngFor="let category of jewellery_category_model">
                  {{ selected_category == category.id ? category.name : '' }}
                </p>
                <mat-menu #categoryMenu="matMenu" class="two-row-menu">
                  <div class="row">
                    <div class="col-md-6">
                      <button *ngFor="let category of jewellery_category_model"
                        [class.selected-card]="category.is_checked" (click)="toggleCategory(category)">
                        {{category.name}}
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </div>
              <div *ngIf="selected_category == null" class="category-drop-down-box form-control"
                style="align-items: center; margin-right: 5px; width: 100%;" [matMenuTriggerFor]="categoryMenu"
                (menuOpened)="openingCategory(0)" #menuTrigger="matMenuTrigger">
                <p style="align-items: center; justify-content: center; margin-bottom: 5px;" id="selcted-category">
                  {{ 'Category' }}
                </p>
                <mat-menu #categoryMenu="matMenu" class="two-row-menu">
                  <div class="row">
                    <div class="col-md-6">
                      <button *ngFor="let category of jewellery_category_model"
                        [class.selected-card]="category.is_checked" (click)="toggleCategory(category)">
                        {{category.name}}
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </div>

            <button class="category-drop-down-box" style="width: 100%;" (menuOpened)="openingCategory(0)"
              [matMenuTriggerFor]="purityMenu" #menuTrigger="matMenuTrigger">
              <p
                style="margin-left: 5px; margin-top: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; text-align: center; margin-right: 0px; font-family: 'Rubik'; font-weight: 500; color: #606060;">
                {{ this.selected_metal == 0 ? 'Gold' : this.selected_metal == 2 ? 'Silver' : this.selected_metal == 3 ?
                'Platinum' : 'Metal & Purity'}}
                <ng-container *ngFor="let selectedPurity of selected_purities">
                  <span *ngFor="let purity of metal_purity[activeLink]">
                    {{ selectedPurity == purity.id ? purity.name : '' }}
                  </span>
                </ng-container>
                <mat-menu #purityMenu="matMenu" class="metal-menu">
                  <div class="d-flex justify-content-between">
                    <div class="modal-body">
                      <div class="grid-container-metal" style="align-items: start; justify-items: start;">
                        <div class="metals">
                          <mat-button-toggle-group class="mat-elevation-z0"
                            style="flex-direction: column; border:none; width: 100%;">
                            <mat-button-toggle class="mat-elevation-z0 d-flex justify-content: start"
                              style="width: 100%; text-align: start; border: none;"
                              *ngFor="let mp of metals; let i = index"
                              (click)="$event.stopPropagation(); getSelectedMetal(i)">
                              {{ mp == 0 ? 'Gold' : mp == 2 ? 'Silver' : 'Platinum' }}
                            </mat-button-toggle>
                          </mat-button-toggle-group>
                        </div>
                        <div class="divider"></div>
                        <div class="purities">
                          <mat-checkbox
                            style="align-items: start; display: flex; flex-direction: column; margin-top: 20px;"
                            *ngFor="let p of metal_purity[activeLink]" [checked]="is_metal_selected" [value]="p['id']"
                            (click)="$event.stopPropagation()" (change)="getSelectedPurity(p['id'])">
                            {{ p['name'] }}
                          </mat-checkbox>
                        </div>
                      </div>
                      <button type="button" class="primary-btn"
                        style="width: 95%; height:50px; margin-top: 20px; margin-bottom: 5px; margin-left: 5px;"
                        (click)="saveSelectedPurities()">Save</button>
                    </div>
                  </div>
                </mat-menu>
              </p>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="show_loading_progress">
        <div>
          <span style="font-family: 'Rubik'; font-size: 14px; color: #4070f4;">
            Sit relaxed, Image will load in <b style="font-weight: bold;">{{progress_count_down}} seconds...</b>
          </span>
        </div>
      </div>
      <div class="selected-images-table">
        <!-- gridview section start -->
        <div *ngIf="selected_view === 'grid'" [@slideInLeftOnEnter] class="d-flex mb-5">
          <div class="grid-container">
            <div class="grid-item"
              *ngFor="let file of search_jewellery_model.length == 0 && filter_jewellery_model.length == 0 ? jewellery_model : search_jewellery_model.length != 0 ? search_jewellery_model : filter_jewellery_model; let i = index">

              <div *ngIf="file.status != 2" class="d-flex flex-row" style="cursor: pointer;">
                <!-- custom checkbox -->
                <label class="checkbox-container" style="position: relative;">
                  <input type="checkbox" [checked]="file.is_checked" (click)="toggleSelected(file)">
                  <span class="checkmark margin-top: 5px"></span>
                </label>
                <!-- End custom checkbox -->

                <div *ngIf="file.status != 2" class="d-flex flex-column justify-content-center align-items-center"
                  style="cursor: pointer;" (click)="openEditDetailsPage(file.id)">
                  <div style="position: relative;">
                    <div *ngIf="file.asset_files.length > 1" class="images-display-grid position-relative">
                      <img *ngIf="file.asset_files[0].file_type == 1" class="selected-images-grid"
                        [src]="file.asset_files[0].icon" alt="images" />
                      <video *ngIf="file.asset_files[0].file_type == 0" class="selected-images-grid"
                        [poster]="file.asset_files[0].icon" controls>
                        <source [src]="file.asset_files[0].file">
                        Your browser does not support the video tag.
                      </video>
                      <span class="position-absolute top-0 start-100 translate-middle badge roundSquare bg-primary"
                        style="cursor: pointer;z-index: 9999; padding: 5px; margin-top: 5px;">
                        <span class="images-length" style="font-size: 13px;">+ {{file.asset_files.length - 1}}</span>
                      </span>
                    </div>
                    <div *ngIf="file.asset_files.length == 1" class="images-display-grid">
                      <img *ngIf="file.asset_files[0].file_type == 1" class="selected-images-grid"
                        [src]="file.asset_files[0].icon" alt="images"
                        (click)="biggerImage(bigger_image, file.asset_files[0].file)" />
                      <video *ngIf="file.asset_files[0].file_type == 0" class="selected-images-grid"
                        [poster]="file.asset_files[0].icon" controls>
                        <source [src]="file.asset_files[0].file" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <div
                      style="position: absolute; bottom: 0; left: 0; right: 0; background-color: #262626; opacity: 0.67; border-radius: 0px 0px 10px 10px; padding: 10px; cursor: pointer;">
                      <span style="color: white; text-align: center; font-size: 16px;">
                        + Add Details
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="file.status == 2" class="d-flex flex-column justify-content-center align-items-center"
                style="padding-bottom: 0px; margin-left: 0px;">
                <div class="lottie-animation" style="height:100px; margin-right: 0px;" class="w-100">
                  <ng-lottie [options]="options">
                  </ng-lottie>
                </div>
                <span style="color: #4070f4; font-family: 'Rubik'; font-size: 16px; margin-right: 0px;">Image is
                  processing</span>
              </div>

            </div>
          </div>
        </div>

        <!-- gridview section end -->
      </div>
      <mat-paginator style="position:relative;margin-bottom:200px;" *ngIf="jewellery_paginator"
        [length]="jewellery_response?.count" [pageSize]="25" [pageIndex]="0" [showFirstLastButtons]="true"
        (page)="setPagination($event)">
      </mat-paginator>

      <mat-paginator style="position:relative;margin-bottom:200px;" *ngIf="search_jewellery_paginator"
        [length]="search_jewellery_response?.count" [pageSize]="25" [pageIndex]="0" [showFirstLastButtons]="true"
        (page)="setSearchResultPagination($event)">
      </mat-paginator>

      <mat-paginator style="position:relative;margin-bottom:200px;" *ngIf="filter_jewellery_paginator"
        [length]="filter_jewellery_response?.count" [pageSize]="25" [pageIndex]="0" [showFirstLastButtons]="true"
        (page)="setFilterResultPagination($event)">
      </mat-paginator>
    </div>

    <div class="floating-menu-cancel" *ngIf="is_bottom_modal_visible">
      <button class="primary-btn" style="width:150px; height:50px" (click)="saveSelected()">Save</button>
      <button class="secondary-btn" style="width:150px; height:50px" (click)="cancelBottomModal()">Cancel</button>
    </div>
    <!-- End: existing-catalog-image-section -->
  </div>
  <!-- End: of bootstrap container -->
  <!-- Start: Bottom Modal -->
  <!-- <div style="position:fixed; bottom:1vh; width:78vw;">
    <div class="bottom-modal" style="width:95%;margin:auto;" *ngIf="is_bottom_modal_visible" [@slideInUpOnEnter]
      [@slideOutDownOnLeave]>
      <div class="bottom-content">
        <div style="display:flex; flex-grow:3;">
          <button class="primary-btn" style="width:150px; height:50px" (click)="saveSelected()">Save</button>
        </div>

        <div style="display:flex;flex-grow:3;">
          <button class="secondary-btn" style="width:150px; height:50px" (click)="cancelBottomModal()">Cancel</button>
        </div>
      </div>

    </div>
  </div> -->
  <!-- End: Bottom  modal -->


  <ng-template #category let-modal>
    <div class="category_modal" style="margin-top: 5%;">
      <div class="d-flex justify-content-between">
        <div class="modal-body">
          <div class="form-check" *ngFor="let category of jewellery_category_model">
            <mat-button-toggle-group style="flex-direction: column ; border: none; width: 80%; margin-right: 20px;">
              <mat-button-toggle style="height: 40px; width: 100%; border:none; text-align: left;"
                [class.selected-card]="category.is_checked" (click)="toggleCategory(category)">
                {{category.name}}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
  </ng-template>


  <ng-template #metalPurity let-modal>
    <div class="metal_purity_modal">
      <div class="d-flex justify-content-between">
        <div class="modal-body">
          <div class="grid-container-metal" style="align-items: start; justify-items: start;">
            <div class="metals">
              <mat-button-toggle-group class="mat-elevation-z0"
                style="flex-direction: column; border:none; width: 100%;">
                <mat-button-toggle class="mat-elevation-z0 d-flex justify-content: start"
                  style="width: 100%; text-align: start; border: none;"
                  *ngFor="let mp of metals; let i = index"
                  (click)="$event.stopPropagation(); getSelectedMetal(i)">
                  {{ mp == 0 ? 'Gold' : mp == 2 ? 'Silver' : 'Platinum' }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="divider"></div>
            <div class="purities">
              <mat-checkbox
                style="align-items: start; display: flex; flex-direction: column; margin-top: 20px;"
                *ngFor="let p of metal_purity[activeLink]" [checked]="is_metal_selected" [value]="p['id']"
                (click)="$event.stopPropagation()" (change)="getSelectedPurity(p['id'])">
                {{ p['name'] }}
              </mat-checkbox>
            </div>
          </div>
          <button type="button" class="primary-btn"
            style="width: 95%; height:50px; margin-top: 20px; margin-bottom: 5px;"
            (click)="saveSelectedPurities()">Save</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #categoryMetalPurity let-modal>
    <div class="category_metal_purity_modal" style="margin-top: 5%;">
      <div class="justify-content-between align-items-start">
        <mat-tab-group>
          <mat-tab label="Category" class="category_tab" [class.selected_tab]="selected_tab === 'category'"
            (click)="switchTab('category')">
            <mat-button-toggle-group name="categoryToggleGroup">
              <mat-button-toggle *ngFor="let category of jewellery_category_model" class="mat-elevation-z0 text-left"
                style="height: 40px; width: 100%; border:none; text-align: left;"
                [class.selected-card]="category.is_checked" (click)="toggleFilterCategory(category)">
                {{category.name}}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </mat-tab>
          <mat-tab label="Metal & Purity" class="metal_tab" [class.selected_tab]="selected_tab === 'metal'"
            (click)="switchTab('metal')">
            <div class="grid-container-metal"
            style="display: flex; flex-direction: row; align-items: start; justify-items: start;">
            <div class="metals">
                <mat-button-toggle-group class="mat-elevation-z0"
                    style="flex-direction: column; border:none; width: 100%;">
                    <mat-button-toggle class="mat-elevation-z0 d-flex justify-content: start"
                        style="width: 100%; text-align: start; border: none;"
                        *ngFor="let mp of metals; let i = index"
                        (click)="$event.stopPropagation(); getSelectedMetal(i)">
                        {{ mp == 0 ? 'Gold' : mp == 2 ? 'Silver' : 'Platinum' }}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="divider"></div>
            <div class="purities">
                <mat-checkbox
                    style="align-items: start; display: flex; flex-direction: column; margin-top: 20px;"
                    *ngFor="let p of metal_purity[activeLink]" [checked]="is_metal_selected"
                    [value]="p['id']" (click)="$event.stopPropagation()"
                    (change)="getSelectedPurity(p['id'])">
                    {{ p['name'] }}
                </mat-checkbox>
            </div>
        </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div
        style="margin-left: 5px; margin-bottom: 10px; align-items: center; justify-content: center; flex-direction: row;">
        <button type="button" class="secondary-btn" style="width:150px; height:50px"
          (click)="modal.close('Close click')">Close</button>
        <button type="button" class="primary-btn" style="width:150px; height:50px"
          (click)="applyFilters(selected_category, selected_metal, selected_purities, 1)">Apply</button>
      </div>
    </div>
  </ng-template>

  <ng-template #store_details>
    <div *ngIf="onlineStore" class="form-store-details">
      <div id="store-details">
        <div class="row">
          <div class="col">
            <label>Store Name</label>
            <br />
            <input type="text" placeholder="Enter Store Name" [(ngModel)]="storeName"
              (input)="checkInputValues($event)" />
          </div>
          <div class="col-4">
            <label>Name of Business</label>
            <br />
            <select [(ngModel)]="businessName" (change)="checkInputValues($event)">
              <option value="" disabled selected>
                Select your nature of business
              </option>
              <option *ngFor="let business of business" [value]="business.value">
                {{ business.viewValue }}
              </option>
            </select>
          </div>
          <div class="col-4"></div>
        </div>
        <div class="row">
          <div class="col-4">
            <label>Store Address</label>
            <br />
            <textarea [(ngModel)]="storeAddress" (input)="checkInputValues($event)" placeholder="Enter Address"
              type="text" rows="5" cols="47" style="height: 150px"></textarea>
          </div>
          <div class="col-8">
            <label>Store ID</label>
            <br />
            <input placeholder="Enter Store ID" [(ngModel)]="storeID" (input)="checkInputValues($event)" /><span
              id="jewellers.live" style="margin-left: 2%">jewellers.live</span>
            <p>
              Store ID or Domain is a unique identifier of the your store.
              <br />
              Once a "Store ID" is created for a store or domain, it cannot be
              modified or changed.
            </p>
          </div>
          <div class="col-sm"></div>
        </div>

        <div class="row">
          <div class="col-3"></div>
          <div class="col-6">
            <button [disabled]="!areStoreDetailsInputsValid" type="submit" mat-raised-button color="primary">
              Submit
            </button>
          </div>
          <div class="col-3"></div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #merge_modal let-modal>
    <div class="merge_modal" style="size: xl;">
      <div class="modal-header px-3 pt-3 py-0">
        <h5 class="modal-title">Combine these designs into one product?</h5>
        <button type="button" style="outline: none;" class="close btn" aria-label="Close" (click)="closeCategory()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center px-5 pt-0">
        <div class="row px-2">
          <div class="col-3 py-2" *ngFor="let selected of selected_files">
            <div *ngFor="let items of jewellery_model">
              <div *ngIf="selected === items.id" class="image-row" style="display: inline-block;">
                <img [src]="items.asset_files[0].icon" height="50px" width="50px" style="border-radius: 10px;">
              </div>
            </div>
          </div>
        </div>
        <br>
        <!-- <div class="row">
          <div class="row">
            <select class="category-drop-down-box" (click)="openCategory(category, 0)" [(ngModel)]="selected_category">
              <option *ngFor="let category of jewellery_category_model" [value]="category.id">
                {{ selected_category != 0 ? category.name : 'Select Category' }}
              </option>
            </select>
          </div>
          <div class="row">
            <button class="category-drop-down-box" (click)="openCategory(metalPurity, 0)">
              <p
                style="margin-left: 5px; margin-top: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; text-align: center; margin-right: 10px; font-family: 'Rubik'; font-weight: 500; color: #606060;">
                {{ this.selected_metal == 0 ? 'Gold' : this.selected_metal == 2 ? 'Silver' : 'Platinum' }}
                <ng-container *ngFor="let selectedPurity of selected_purities">
                  <span *ngFor="let purity of metal_purity[activeLink]">
                    {{ selectedPurity == purity.id ? purity.name : '' }}
                  </span>
                </ng-container>
              </p>
            </button>
          </div>
        </div> -->
        <button type="button" class="primary-btn" style="width: 95%; height:50px; margin-top: 20px; margin-bottom: 5px;"
          (click)="applyMergeChanges()">Apply Changes</button>
      </div>
    </div>

  </ng-template>

  <ng-template #bigger_image let-modal>
    <div class="merge_modal">
      <div class="modal-header">
        <h5 class="modal-title">Image</h5>
        <button type="button" style="outline: none;" class="close btn" aria-label="Close" (click)="closeCategory()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center px-5 pt-0">
        <img *ngIf="bigImageUrl" [src]="bigImageUrl" alt="big image"
          style="max-width: 100%; max-height: 50%; margin: 0 auto;">
      </div>
      <div class="modal-footer">

      </div>
    </div>
  </ng-template>

  <button *ngIf="has_images" (click)="openUploadDialog(0)" class="floating-button" type="button"
    style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
    <mat-icon style="font-size: 24px;">add</mat-icon>
  </button>

</div>