import { formatDate } from "@angular/common";
import { PaymentOptions } from "./installments";
import { Customer } from "./orderDetail";

export class repairOrderItem {

    id!: string;
    sr_no!: string;
    description!: string;
    pieces!: string;
    purity!: string | number;
    item_rate!:string;
    temp_purity!:string ;
    weight: number | string;
    amount: number | string;
    status !:Number;
    repair_taken_by!:string;

    constructor(
      id: string,
      sr_no: string,
      description: string,
      pieces: string,
      purity: string,
      temp_purity:string,
      weight: number | string,
      amount: number | string,
      repair_taken_by:string,
    ) {
      this.id = id;
      this.sr_no = sr_no;
      this.description = description;
      this.pieces = pieces;
      this.purity = purity;
      this.temp_purity = temp_purity
      this.weight = weight;
      this.amount = amount;
      this.repair_taken_by=repair_taken_by
    }
    
    public checkBeforeAddingItem(): boolean
    {
       return (
            this.description !== '' );
    }
  }

  export class RepairOrder{
    id!:number;
    commodity!:number;
    repair_no!:string;
    repair_date!:string;
    customer_name!:string;
    repair_taken_by!:string;
    order_note!:string;
    user:Customer= new Customer('','','','','','','','','','','');
    repair_order_item : repairOrderItem[] = repairOrderItemArray.repair_order_item;

    payment_data!: PaymentOptions[];
    advance_amount!: number;

    invoice_url:string='';

    constructor(data:any)
    {
        this.id= data.id || '';
        this.commodity= data.commodity || 0;
        this.repair_no = data.repair_no || '';
        this.repair_date = data.repair_date || formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
        this.customer_name = data.customer_name || 0;
        this.repair_taken_by = data.repair_taken_by || '';
    }
    
  }

export class repairOrderItemArray{
    static repair_order_item :repairOrderItem[]=[new repairOrderItem('','1','','','','','','',''),]
  
    static addRepairOrderItem(){
        repairOrderItemArray.repair_order_item.push(new repairOrderItem('','1','','','','','','',''))
    }
  
    static deleteRepairOrderItem(index:number){
        repairOrderItemArray.repair_order_item.splice(index,1)
    }

    static checkBeforeAdding(array:any)
    {
        if (this.checkBeforeAddingItem(array)) {
            return false;
        }
        return true;
    
    }

    static checkBeforeAddingItem(array:any)
    {
        const hasEmptyDescription = array.some((element: repairOrderItem) => {
            return element.description == '';
        });
        return hasEmptyDescription
    }

    static removeRepairOrderItem(index:number)
    {
        if (repairOrderItemArray.repair_order_item.length==1)
        {
            repairOrderItemArray.repair_order_item=[new repairOrderItem('','1','','','','','','',''),]
        }
        else {
            this.deleteRepairOrderItem(index)
        }
    }

    static clearAll()
    {
        repairOrderItemArray.repair_order_item=[new repairOrderItem('','1','','','','','','',''),]
  
    }
    
}