import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { Design, DesingModel } from 'src/app/models/design';
import { ResponseMessage } from 'src/app/models/message';
import { Rest } from 'src/app/models/rest_model';
import { CustomItem } from 'src/app/models/customItem';
import { SearchUserReponse } from 'src/app/models/search-user';
import { repairOrderItem } from 'src/app/models/repairOrder';

@Injectable({
  providedIn: 'root',
})
export class DesignService {
  design_url = environment.ip + 'shop/addDesigns/';
  repair_design_url = environment.ip + 'shop/addRepairDesigns/';
  view_order_url = environment.ip + 'shop/customOrders/';
  view_repair_url = environment.ip + 'shop/repairOrders/';
  
  view_order_mobile_url = environment.ip +'shop/customOrdersMobile/';
  view_repair_mobile_url =  environment.ip +'shop/repairOrdersMobile/';

  search_user = environment.ip + 'shop/customOrders/search_user/';
  search_repair_user = environment.ip + 'shop/repairOrders/search_repair_user/';

  create_vendor = environment.ip + 'shop/customOrders/add_vendor/';
  create_repair_vendor = environment.ip + 'shop/repairOrders/add_vendor/';

  assign_vendor = environment.ip + 'shop/customOrders/assign_vendor/';
  assign_repair_vendor = environment.ip + 'shop/repairOrders/assign_repair_vendor/';

  remove_vendor = environment.ip + 'shop/customOrders/remove_vendor/';
  remove_repair_vendor = environment.ip + 'shop/repairOrders/remove_repair_vendor/';


  cancel_order = environment.ip + 'shop/customOrders/cancel_order/';
  get_vendors = environment.ip + 'shop/customOrders/get_vendors/';
  constructor(private http: HttpClient) {}

  getCustomOrders(page: number, search: string, filter: any,vendors:any) {
    return this.http.get<Rest<CustomItem>>(this.view_order_url, {
      params: { search: search,
         page: page ,
          status__in: filter.toString(),
          assigned_to_vendor__in:vendors.toString()
        },
    });
  }
  
  getCustomOrdersMobile(page: number, search: string, filter: any,vendors:any) {
    console.log(filter)
    return this.http.get<Rest<CustomItem>>(this.view_order_mobile_url, {
      params: { search: search,
         page: page ,
          status__in: filter.toString(),
          assigned_to_vendor__in:vendors.toString()
        },
    });
  }

  getRepairOrders(page: number, search: string, filter: any,vendors:any) {
    return this.http.get<Rest<repairOrderItem>>(this.view_repair_url, {
      params: { search: search, page: page, status__in: filter.toString() },
    });
  }

  getRepairOrdersMobile(page: number, search: string, filter: any,vendors:any) {
    console.log(filter)
    return this.http.get<Rest<CustomItem>>(this.view_repair_mobile_url, {
      params: { search: search,
         page: page ,
          status__in: filter.toString(),
          assigned_to_vendor__in:vendors.toString()
        },
    });
  }

  postDesign(fd: FormData): Observable<number> {
    console.log('this is callled');
    return this.http
      .post(this.design_url, fd, { reportProgress: true, observe: 'events' })
      .pipe(
        map((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            let percentDone = 0;
            if (event.total) {
              percentDone = Math.round((100 * event.loaded) / event.total);
            }
            return percentDone;
          } else if (event instanceof HttpResponse) {
            // Upload completed
            return 100;
          }
          return 0;
        })
      );
  }


  postRepairDesign(fd: FormData): Observable<number> {
    console.log('this is callled');
    return this.http
      .post(this.repair_design_url, fd, { reportProgress: true, observe: 'events' })
      .pipe(
        map((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            let percentDone = 0;
            if (event.total) {
              percentDone = Math.round((100 * event.loaded) / event.total);
            }
            return percentDone;
          } else if (event instanceof HttpResponse) {
            // Upload completed
            return 100;
          }
          return 0;
        })
      );
  }

  getDesigns(id: String) {
    return this.http.get<DesingModel>(
      `${environment.ip}shop/getDesigns/?id=${id}`
    );
  }
  getRepairDesigns(id: String) {
    return this.http.get<DesingModel>(
      `${environment.ip}shop/getRepairDesigns/?id=${id}`
    );
  }

  getVendors(id: any, search: any) {
    return this.http.get<SearchUserReponse>(this.search_user, {
      params: { search: search, item_id: id },
    });
  }

  getRepairVendors(id: any, search: any) {
    return this.http.get<SearchUserReponse>(this.search_repair_user, {
      params: { search: search, item_id: id },
    });
  }

  getVendorsForFilter()
  {
    return this.http.get(this.get_vendors);
  }
  postVendor(fd: FormData) {
    return this.http.post<ResponseMessage>(this.create_vendor, fd);
  }

  postRepairVendor(fd:FormData) {
    return this.http.post<ResponseMessage>(this.create_repair_vendor, fd);

  }

  assignVendor(fd: FormData) {
    return this.http.post<ResponseMessage>(this.assign_vendor, fd);
  }

  assignRepairVendor(fd : FormData)
  {
    return this.http.post<ResponseMessage>(this.assign_repair_vendor, fd);
  }

  removeVendor(fd: FormData) {
    return this.http.post<ResponseMessage>(this.remove_vendor, fd);
  }

  removeRepairVendor(fd: FormData) {
    return this.http.post<ResponseMessage>(this.remove_repair_vendor, fd);
  }
  cancelOrder(fd: FormData) {
    return this.http.post<ResponseMessage>(this.cancel_order, fd);
  }

  cancelEntireOrder(fd: FormData) {
    return this.http.post<ResponseMessage>(
      this.view_order_url + 'cancelFullOrder/',
      fd
    );
  }
  cancelEntireRepair(fd: FormData) {
    return this.http.post<ResponseMessage>(
      this.view_repair_url + 'cancelFullOrder/',
      fd
    );
  }

  deleteDesigns(fd: FormData) {
    return this.http.post<ResponseMessage>(
      `${environment.ip}shop/deleteDesigns/`,
      fd
    );
  }

  deleteRepairDesigns(fd: FormData) {
    return this.http.post<ResponseMessage>(
      `${environment.ip}shop/deleteRepairDesigns/`,
      fd
    );
  }

  changeStatusOfOrder(fd: FormData) {
    return this.http.post<ResponseMessage>(
      `${environment.ip}shop/customOrders/changeStatus/`,
      fd
    );
  }

  changeStatusOfRepair(fd: FormData) {
    return this.http.post<ResponseMessage>(
      `${environment.ip}shop/repairOrders/changeStatus/`,
      fd
    );
  }

  completeOrder(fd: FormData) {
    return this.http.post<ResponseMessage>(
      `${environment.ip}shop/customOrders/completeOrder/`,
      fd
    );
  }
  completeRepair(fd: FormData) {
    return this.http.post<ResponseMessage>(
      `${environment.ip}shop/repairOrders/completeRepair/`,
      fd
    );
  }

  setEstimationDate(fd: FormData) {
    return this.http.post<ResponseMessage>(
      `${environment.ip}shop/customOrders/setEstimationDate/`,
      fd
    );
  }
  setRepairEstimationDate(fd: FormData) {
    return this.http.post<ResponseMessage>(
      `${environment.ip}shop/repairOrders/setEstimationDate/`,
      fd
    );
  }

  downloadOrder(fd: FormData) {
    return this.http.post(
      `${environment.ip}shop/customOrders/download_booking/`,
      fd
    );
  }
  downloadRepair(fd: FormData) {
    return this.http.post(
      `${environment.ip}shop/repairOrders/download_repairs/`,
      fd
    );
  }
  pendingOrderReport(fd:FormData)
  {
    return this.http.post(
      `${environment.uploaderIp}shop/customOrders/download_vendor_report/`,
      fd
    )
  }

  followUpRequest(id:Number)
  {
    return this.http.get(`${environment.ip}shop/customOrders/${id}/followUp/`)
  }
}
