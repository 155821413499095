import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-floating-actions',
  templateUrl: './floating-actions.component.html',
  styleUrls: ['./floating-actions.component.css']
})
export class FloatingActionsComponent {
  showIcons: boolean = false;

  icons = [
    {
      description: 'Book a Demo',
      svgContent: "assets/images/oms.svg",
      url: 'https://calendly.com/business-jewellers-live/30min'
    },
    {
      description: 'How to Add Old Gold ?',
      svgContent: "assets/images/oms.svg",
      url: "https://www.notion.so/goldlane/How-to-Add-Old-Gold-45717cd6816b4e07ada3a38342ffd359"
    },
    {
      description: 'How to add design details ?',
      svgContent: "assets/images/oms.svg",
      url: "https://www.notion.so/goldlane/How-to-add-design-details-843dded665924771891e810fffe60be5"
    },
    {
      description: 'How to open new orders page ?',
      svgContent: "assets/images/oms.svg",
      url: "https://www.notion.so/goldlane/How-to-open-new-orders-page-9933733ae06a4f7d9027ce52e858fdc4"
    },
    {
      description: 'How to assign order to a vendor ?',
      svgContent: "assets/images/oms.svg",
      url: "https://www.notion.so/goldlane/How-to-assign-order-to-a-vendor-2d69d433a38241498a0000b7757bc280"
    },
    
    {
      description: 'How to manage Vendors/Goldsmiths ?',
      svgContent: "assets/images/oms.svg",
      url: "https://www.notion.so/goldlane/How-to-manage-Vendors-Goldsmiths-ff34cc1d00904e779a70ae3c28d0e984"
    },
    {
      description: 'How to Manage Payment and Print Order ?',
      svgContent: "assets/images/oms.svg",
      url: "https://www.notion.so/goldlane/How-to-Manage-Payment-and-Print-Order-ac317632c4384f9e92028742d8bc0c42"
    },
  
   
    {
      description: 'How to add Item Descriptions and Order Details ?',
      svgContent: "assets/images/oms.svg",
      url: "https://www.notion.so/goldlane/How-to-add-Item-Descriptions-and-other-Order-Details-00b230b501be4eaa9daa674647a17fb1"
    },
  
   
  ]
  
constructor(
 private sanitizer: DomSanitizer
)
{}

  toggleIcons() {
    this.showIcons = !this.showIcons;
  }
  redirectToDemo(url:string): void {
    window.open(url, '_blank');
  }
}
