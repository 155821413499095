import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthServiceService } from './auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(private router:Router,private authService:AuthServiceService) { }

  // canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
  //   if(this.authService.isAdmin()){
  //     return true;
  //   } else{
  //   this.router.navigate(['/login']);
  //   return false;
  //   }
  // }
  canActivate(route: ActivatedRouteSnapshot, ): boolean {

    if (route.data['signup']) {
      localStorage.setItem('signup', 'true');
      return false;
    }

    if (this.authService.isLoggedIn()) {
        return true;
    } else {
      localStorage.clear();
      this.router.navigate(['/login']);
        return false;
    }
}
}
