<ng-container *ngTemplateOutlet="delete_modal" >
    
</ng-container>


<ng-template #delete_modal let-modal >
    <div class="delete_modal" style="size: xl;">
      <div class="modal-header px-3 pt-3 py-0">
        <!-- <mat-icon class="back-arrow px-3" (click)="closeModal()" style="cursor: pointer;">keyboard_arrow_left</mat-icon> -->
        <h6 class="modal-title"></h6>
        <button type="button" style="outline: none;" class="close btn" aria-label="Close"
          (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center px-5 pt-0">
        <img src="../assets/images/Warning.png" alt="" style="width: 73px; height: 73px;">
        <br><br>
        <h3>Warning!</h3>
        <div class="row px-2">
            <div class="col-3 py-2" *ngFor="let fileAsset of filesAssets">
                <img [src]="fileAsset.icon" alt="{{ fileAsset.name }}" class="img-thumbnail">
                <!-- <p>{{ fileAsset.name }}</p> -->
            </div>
        </div>
        <br>
        <p>Are you sure you want to delete these designs ?</p>
        <button class="okay-btn mb-3" type="button" (click)="confirmDelete()" >Okay</button>

      </div>
    </div>
    
  </ng-template>