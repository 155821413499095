import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { LedgerDataSource } from '../customer_ledger/customer-ledger/customer_ledger_dataSource';
import { PopUpManager } from 'src/app/manager/Popup';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const ELEMENT_DATA: any[] = [
  { position: 1, name: 'John', weight: 60 },
  { position: 2, name: 'Jane', weight: 55 },
  { position: 3, name: 'Bob', weight: 70 },
];
@Component({
  selector: 'app-jewellers-profile',
  templateUrl: './jewellers-profile.component.html',
  styleUrls: ['./jewellers-profile.component.css'],
})
export class JewellersProfileComponent {
  selected_actions = [];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  options = [
    { label: 'Razor Pay', value: 'option1' },
    { label: 'Option 2', value: 'option2' },
    { label: 'Option 3', value: 'option3' },
  ];
  items = [
    {
      value: 'Rate History',
      selected: false,
      actions: ['Add Rates', 'Edit Commodity Name', 'Filter Date'],
    },
    { value: 'Manage Plans', selected: false ,actions:["Add Plan Model",
      "Pause Plan Model",
      "Edit Plan Model",
      "Delete Plan Model"] },
    { value: 'Settings-Payment Accounts', selected: false },
    { value: 'Start Jewellery Plan', selected: false },
    { value: 'Add Installment', selected: false },
    { value: 'Installment Page', selected: false },
    { value: 'Customer Ledger', selected: false },
    { value: 'Reports', selected: false },
    { value: 'Settlement', selected: false },
    { value: 'Settlement Details', selected: false },
    { value: 'Customers', selected: false },
    { value: 'Send Notifications', selected: false },
  ];
  constructor(public modalService: NgbModal) {}
  selectedValue = 'option1';
  popUpManager!: PopUpManager;
  displayedColumns: string[] = [
    'Jeweler Name',
    'Address',
    'Date Joined',
    'Commission',
    'Bank A/C',
    'URL',
    'Payment Gateway',
    'JSP Subscriptions',
    'Dashboard',
  ];

  modal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  page_to_actions(item: any) {


    if (!item.selected) {
      this.selected_actions = item.actions;
    }
    if(item.selected)
    {
      this.selected_actions=[]
    }
  }
}
