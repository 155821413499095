import {  DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { reportDgCoinData } from "src/app/digital-coin/services/digital-coin";
import { DigitalCoinService } from "src/app/digital-coin/services/digital-coin.service";

export class DigitalCoinDataSource extends DataSource<any> {
  private customOrderData=new BehaviorSubject<reportDgCoinData[]>([]);
  private loadingSettlements = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSettlements.asObservable();
  private countSettlements=new BehaviorSubject<number>(0);
  public counter$=this.countSettlements.asObservable();

  constructor(private digiService:DigitalCoinService) {
    super();
  }

  connect(): Observable<reportDgCoinData[]> {
    return this.customOrderData.asObservable();
  }

  disconnect() {
    this.customOrderData.complete();
    this.loadingSettlements.complete();
  }

  loadData(page:number=0,from_date: any,to_date: any,search:any,filter:any){
      page=page+1;
      this.loadingSettlements.next(true);
      this.digiService.digitalCoinReport(page=page,from_date=from_date,to_date=to_date,search=search,filter=filter).pipe(
        catchError(()=> of([])),
        finalize(()=>{
          this.loadingSettlements.next(false);
        })
      ).subscribe((settlementLogs: any)=>this.renderOrders(settlementLogs))
  }

  renderOrders(settlementLogs:any){
    this.countSettlements.next(settlementLogs.count);
    this.customOrderData.next(settlementLogs.results);
  }

}