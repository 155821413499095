<div class="overall-container" style="height:90vh; width:95%; overflow-y:scroll;">
<div style="margin-right: 20px;">
  <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event)">

    <mat-tab label="Create New Estimate">
      <div class="create-container">
        <div class="container">
          <div class="row">
            <!-- <div class="col-sm-4">
              <label for="estimation_no">Estimation No</label>
              <input type="text" class="form-control" [(ngModel)]="estimation_number" [disabled]="true" />
            </div> -->
            <div class="col-sm-4">
              <label>Estimation Date</label>
              <div style="position: relative;">
                <input
                  matInput
                  class="form-control"
                  id="3"
                  style="padding-right: 30px;"
                  [(ngModel)]="estimation_date"
                  [disabled]="true"
                />
                <mat-datepicker-toggle matSuffix [for]="picker" style="position: absolute; right: 0; top: 0;"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
            </div>            
            <div class="col-sm-4">
              <label for="rate">Rate/gm</label>
              <input type="text" class="form-control" [(ngModel)]="rate" (change)="calculateWastage()" />
            </div>
            <div class="col-sm-4">
              <label for="rate">Metal & Purity</label>
              <div class="form-control"
              style="width: 100%; display: flex; flex-direction: row; text-align: center; align-items: center;"
               [matMenuTriggerFor]="purityMenu" #menuTrigger="matMenuTrigger">
              <div style="align-items: center;">
                <span
                style="font-family: 'Rubik'; font-weight: 500; color: #606060; font-size: 14px; text-align: center; align-items: center;">
                {{ this.selected_metal == 0 ? 'Gold' + '' : this.selected_metal == 2 ? 'Silver'+'' :
                'Platinum'+'' }}
              </span>
              <span
                style="font-family: 'Rubik'; font-weight: 500; color: #606060; font-size: 14px; text-align: center; align-items: center;"
                *ngFor="let purity of metal_purity[activePurity]">
                {{ selected_purity == purity.id ? purity.name : '' }}
              </span>
              </div>
              <mat-menu #purityMenu="matMenu" class="metal-menu">
                <div class="metal_purity_menu">
                  <div class="d-flex justify-content-between">
                    <div class="modal-body">
                      <div class="grid-container" style="display: flex; align-items: start; justify-items: start;">
                        <div class="metals">
                          <mat-button-toggle-group class="mat-elevation-z0" style="flex-direction: column; border:none; width: 100%;">
                            <div *ngFor="let mp of metals; let i = index" style="display: flex; flex-direction: row;">
                              <div [ngClass]="{'divider': mp === selected_metal}" style="width: 1px; height: 50px; background-color: white;"></div>
                              <mat-button-toggle class="mat-elevation-z0 d-flex justify-content: start" style="width: 100%; text-align: start; border: none;" (click)="$event.stopPropagation(); getSelectedMetal(i)">
                                {{ mp == 0 ? 'Gold' : mp == 2 ? 'Silver' : 'Platinum' }}
                              </mat-button-toggle>
                            </div>
                          </mat-button-toggle-group>
                        </div>
                        <div style="width: 1px; height: 250px; background-color: #DFDFDF;">
                        </div>
                        <div class="purities" style="margin-left: 10px; margin-right: 20px;">
                          <mat-radio-group aria-label="Select an option">
                            <mat-radio-button
                              style="align-items: start; display: flex; flex-direction: column; margin-top: 15px;"
                              *ngFor="let p of metal_purity[activePurity]" [value]="selected_purity != null ? selected_purity?.toString() : '0'"
                              (click)="getSelectedPurity(p['id'])">
                              {{ p['name'] }}
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>                      
                    </div>
                  </div>
                </div>
              </mat-menu> 
            </div>
            </div>       
        </div>
        </div>

        <div style="display: flex; flex-direction: row;">
          <!-- Image Upload Section -->
          <div class="row-2 form-control" style="width: fit-content; position: relative;">
            <div *ngIf="!imageUrl && !product_link" (click)="openModal('imageUpload')" style="display: flex; flex-direction: row; align-items: center; justify-content: start;">
              <mat-icon *ngIf="!imageUrl && !product_link" style="color: #4070F4; font-size: 18;">image</mat-icon>
              <label *ngIf="!imageUrl && !product_link" style="margin-left: 5px;">Upload Image</label>
            </div>
            <img *ngIf="imageUrl" [src]="imageUrl" alt="Selected Image" style="height: 100px; width: 100px; border-radius: 4px;">
            <span>{{ product_link ? product_link : '' }}</span>
            <button *ngIf="imageUrl || product_link" (click)="removeImage()" style="position: absolute; top: 0; right: 0; border: none; background-color: white;">
              <mat-icon style="color: #4070F4; background-color: white;">close</mat-icon>
            </button>
          </div>
        
          <!-- Live Rate Toggle Section -->
          <!-- <div class="row-2 form-control" style="width: fit-content; position: relative; margin-left: 20px; display: flex; align-items: center;">
            <label style="margin-right: 5px;">Live Rate</label>
            <mat-checkbox style="margin-left: 10px;" [(ngModel)]="is_live_rate" (click)="toggleLiveRate()" (change)="toggleLiveRate()"></mat-checkbox>
          </div> -->
          <button *ngIf="product_link" (click)="viewImage()" style="width: fit-content; height: 40px; position: relative; margin-left: 20px; display: flex; align-items: center; background-color: #4070F4; color: white; top: 20px; border: none; border-radius: 5px;">
            <span>View Image</span>
          </button>
        </div>
        
        <div class="estimation-box">
          <table>
            <thead>
              <tr>
                <th style="border-radius: 10px 0px 0px 0px;" scope="col">Gross Weight (g)</th>
                <th scope="col">Nett Weight (g)*</th>
                <th scope="col">Wastage</th>
                <th scope="col">MC</th>
                <th scope="col">Stone (₹)</th>
                <th scope="col">GST %</th>
                <th style="border-radius: 0px 10px 0px 0px;" scope="col">Total (₹)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input #grossWeightInput id="gross-wt-id" type="text" class="form-control" style="border: none;"
                    [(ngModel)]="gross_wt" [autofocus]="true" (change)="updateGrossWeight(gross_wt)" /></td>
                <td><input #nettWeightInput type="text" class="form-control" style="border: none;" [(ngModel)]="nett_wt"
                    (change)="updateNetWeight(nett_wt)" (keydown.tab)="openModal('wastagePopup')"/></td>
                <td><input #wastageInput type="text" class="form-control" style="border: none;" [(ngModel)]="wastage" (change)="calculateWastage()"
                  (click)="openModal('wastagePopup')" focus="openModal('wastagePopup')"/>
                </td>
                <td><input #makingChargeInput type="text" class="form-control" style="border: none;" [(ngModel)]="making_charge" (change)="calculateMakingCharge()"
                    (click)="openModal('makingChargePopup')"/>
                </td>
                <td><input #stoneInput type="text" class="form-control" style="border: none;" [(ngModel)]="stone" (change)="addStoneCharge(stoneCount)"
                  (click)="openModal('addStoneChargePopup')" (click)="stoneData.length == 0 ? addRow() : null" /></td>
                <td><input type="text" class="form-control" style="border: none;" [(ngModel)]="gst"
                    (change)="calculateTotal(gst)" /></td>
                <td><input type="text" class="form-control" style="border: none;" [(ngModel)]="total" /></td>
              </tr>
            </tbody>
          </table>

          <div>
            <button type="button" class="clear-all-btn" (click)="clearAll()">Clear All</button>
          </div>
        </div>

        <div class="save-est-cont" *ngIf="!is_share_visible">
          <button type="button" [ngClass]="total != 0 && total ? 'save-est-btn' : 'save-est-btn-disabled'"
            [disabled]="!total && total ==0" (click)="createEstimate()">Save
            Estimate</button>
        </div>
        <div class="save-est-cont" *ngIf="is_share_visible">
          <div style="position: relative;">
            <input
              matInput
              class="form-control"
              id="3"
              style="padding-right: 0px; width: 300px;"
              [(ngModel)]="share_url"
              [disabled]="true"
            />
            <button style="position: absolute; right: 2px; top: 2px; border: none;" (click)="copyShareUrl(share_url)">
              <mat-icon style="color: #4070F4;">
                file_copy
              </mat-icon>
            </button>
          </div>
          <button type="button" class="add-making-btn" style="margin-left: 20px;" (click)="clearAll()">Create New</button>
        </div>
        <ng-template #wastagePopup let-modal>
          <div class="modal-header" (keydown.enter)="calculateWastage()">
            <h5 class="modal-title" id="wastagePopupLabel">Wastage %</h5>
            <button type="button" style="outline: none;" class="close btn" aria-label="Close" (click)="closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="wastage-options">
                <mat-button-toggle-group [(value)]="selectedWastageType">
                  <mat-button-toggle *ngFor="let wastage_type of wastage_types" [value]="wastage_type.value" (click)="chooseWastage(wastage_type.value)">
                    {{ wastage_type.name }}
                  </mat-button-toggle>
                </mat-button-toggle-group>
                <br><br>
            </div>
            <input #wastageChargeTypeInput [autofocus]="true" class="wastage-input" [(ngModel)]="wastage" (keydown.enter)="calculateWastage()"/>
          </div>
          <div class="modal-footer" style="justify-content: center;">
            <button type="button" class="add-wastage-btn" (click)="calculateWastage()">Save</button>
          </div>
        </ng-template>

        <ng-template #makingChargePopup let-modal>
          <div class="modal-header" (keydown.enter)="calculateMakingCharge()">
            <h5 class="modal-title" id="makingChargePopupLabel">Making Charge</h5>
            <button type="button" style="outline: none;" class="close btn" aria-label="Close" (click)="closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="making-charge-options" style="margin-bottom: 10px;">
                <mat-button-toggle-group [(value)]="selectedMakingChargeType">
                  <mat-button-toggle *ngFor="let making_type of making_charge_types" [value]="making_type.value" (click)="chooseMakingCharge(making_type.value)">
                    {{ making_type.name }}
                  </mat-button-toggle>
                </mat-button-toggle-group>
                <br><br>
              <!-- <label for="wastage-choice">{{ selectedMakingChargeType }}</label> -->
            </div>
            <input type="number" [autofocus]="true" class="making-input" id="making-charge-input-id" [(ngModel)]="making_charge" (keydown.enter)="calculateMakingCharge()"/>
          </div>
          <div class="modal-footer" style="justify-content: center;">
            <button type="button" class="add-making-btn" (click)="calculateMakingCharge()">Save</button>
          </div>
        </ng-template>


        <ng-template #addStoneChargePopup let-modal>
          <div class="modal-header">
            <h5 class="modal-title" id="addStoneChargeLabel">Stone Cost</h5>
            <button type="button" style="outline: none;" class="close btn" aria-label="Close" (click)="closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" (keydown.shift.enter)="addRow()" (keydown.cntrl.s)="saveStoneCharges(stoneCount)">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Stone Name</th>
                  <th scope="col">Pcs</th>
                  <th scope="col">Gms</th>
                  <th scope="col">Carat</th>
                  <th scope="col">Rate</th>
                  <th scope="col">Amount</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody *ngFor="let stone of stoneData; let i = index">
                <tr>
                  <td><input #stoneNameInput type="text" style="border: none;" class="stone-form-control"
                      [(ngModel)]="stone.name" /></td>
                  <td><input style="border: none;" class="stone-form-control"
                      [(ngModel)]="stone.quantity" />
                  </td>
                  <td><input style="border: none;" class="stone-form-control" [(ngModel)]="stone.weight" />
                  </td>
                  <td><input style="border: none;" class="stone-form-control" [(ngModel)]="stone.carat" />
                  </td>
                  <td>
                    <div class="input-group">
                      <input [autofocus]="true" style="border: none;" class="stone-form-control" [(ngModel)]="stone.rate" (keydown.enter)="addStoneCharge(stoneCount)" (change)="addStoneCharge(stoneCount)"/>
                      <select class="category-box" [(ngModel)]="stone.charge_type" (keydown.enter)="addStoneCharge(stoneCount)" style="padding: 0px;">
                        <option *ngFor="let stone_charge of stone_charge_types" [value]="stone_charge.value">
                          {{ stone_charge.name }}
                        </option>
                      </select>
                    </div>
                  </td>
                  <td><input type="text" style="border: none;" class="stone-form-control" (focus)="addStoneCharge(stoneCount)" [(ngModel)]="stone.cost" />
                  </td>
                  <td>
                    <mat-icon *ngIf="i > 0" style="color: #4070F4;" type="button" class="delete-stone-row" (click)="removeRow(i)">delete</mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <button type="button" class="add-stone-row" (click)="addRow()">+ ADD ITEM</button>
              <button type="button" class="add-stone-row-shortcut" (click)="addRow()">(Shift + Enter)</button>
            </div>
          </div>
          <div class="modal-footer" style="justify-content: center;">
            <button type="button" class="add-stone-btn" (click)="saveStoneCharges(stoneCount)" >Save</button>
          </div>
          <!-- </div>
          </div>
        </div> -->
        </ng-template>

        <ng-template #imageUpload let-modal>
          <div style="background-color: white; margin: 10px;">
            <mat-tab-group [(selectedIndex)]="selectedImageTabIndex" (selectedTabChange)="onImageTabChanged($event)">
              <div style="background-color: white; margin-top: 20px;">
                <mat-tab label="Upload">
                  <div class="form-group" style="margin: 20px; background-color: white;">
                    <input style="background-color: white !important;" #fileInput class="form-control" type="file" (change)="onFileSelected($event)">
                    <span class="text" style="display: flex; margin-top: 20px; align-items: center; justify-content: center; text-align: center; color: #9F9F9F; font-family: 'Rubik'; font-size: 12px;">Recommended size upto 3mb</span>
                  </div>
                </mat-tab>
                <mat-tab label="Image URL">
                  <div style="margin: 20px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                    <div class="form-group" style="width: 100%; display: flex;">
                      <input type="text" class="form-control" placeholder="Enter or Paste Image URL" id="imageUrl" [(ngModel)]="product_link" (change)="saveImageUrl(product_link)" placeholder="Enter URL">
                    </div>
                    <button style="width: 50%; height: 40px; align-items: center; text-align: center; justify-content: center; margin-top: 20px;" type="button" class="save-est-btn" (click)='onFileSelected($event)'>Link</button>  
                  </div>
                </mat-tab>
              </div>
            </mat-tab-group>
          </div>
    </ng-template>

      </div>
    </mat-tab>

    <mat-tab label="View Estimates" style="overflow: auto; height: 100vh;">
      <div class="view_estimations" *ngIf="estimationList?.count">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th style="border-radius: 10px 0px 0px 0px;">S.No</th>
              <th>Estimate No</th>
              <th>Url</th>
              <th style="border-radius: 0px 10px 0px 0px;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let est of estimationList?.results; let i=index">
              <td>{{ i + 1 }}</td>
              <td (click)="viewEstimate(est.id)">{{ est.estimation_no }}</td>
              <td>{{ est.estimation_share_url }}</td>
              <td>
                <div [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </div>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="viewEstimate(est.id)">
                    <mat-icon>visibility</mat-icon>
                    <span>View</span>
                  </button>
                  <!-- <button mat-menu-item (click)="editEstimate(est.id)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button> -->
                  <button mat-menu-item (click)="deleteEstimate(est.id)">
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="no-designs-container mt-5 align-items-center" style="align-items: center; justify-content: center; display: flex;" *ngIf="!estimationList?.count">
        <img class="no_designs_illus_img" src="assets/images/no_designs_illus.png" alt="No Designs Banner">
        <h1>No estimations found</h1>
      </div>
    </mat-tab>

  </mat-tab-group>

</div>
</div>