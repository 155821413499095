import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CustomersComponent } from './customers/customers.component';
import { LoginComponent } from './login/login.component';
import { SelectUsersComponent } from './notify/select-users/select-users.component';
import { AuthGuardService } from './services/auth-guard.service';
import { PrivacyComponent } from './privacy/privacy.component';

import { RouteLoggerService } from './services/route-logger.service';
import { NormalNavModule } from './normal-nav/normal-nav.module';


 const routes: Routes = [
  { path: '',canActivate:[AuthGuardService], loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
  { path: 'dashboard',canActivate:[AuthGuardService], loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
  { path: 'mobile_rate',canActivate:[AuthGuardService], loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
  {path:'login',component:LoginComponent},
  {path:'privacy',component:PrivacyComponent},
  {path:'signup',canActivate:[AuthGuardService],component:LoginComponent,data:{signup:true}},



  {
    path:"digital-coin",
    canActivate:[AuthGuardService],
    loadChildren: () => import('./digital-coin/digital-coin.module').then(m => m.DigitalCoinModule)
  },
  { path: 'page', loadChildren: () => import('./normal-nav/normal-nav.module').then(m => m.NormalNavModule) },



];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules, initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
