import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class EditEstimationService {

    private editEstimationUrl = environment.ip + 'shop/estimations/editEstimation/';
    private getEstimationsUrl = environment.ip + 'shop/estimations/';

    constructor(private http: HttpClient) { }

    createNewEstimation(data: any): Observable<any> {
        console.log('create new estimation' + data);
        return this.http.post(this.editEstimationUrl, data);
    }

    getEstimation(id: number): Observable<any> {
        console.log('view estimation');
        return this.http.get(this.getEstimationsUrl + id + '/');
    }
}
