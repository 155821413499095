import {  DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { RateTable } from "../models/rate";
import { Rest } from "../models/rest_model";
import { TemplateServiceService } from "./template-service.service";

export class RateDataSource extends DataSource<any> {

  private rateData=new BehaviorSubject<RateTable[]>([]);
  private loadingRates = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingRates.asObservable();
  private countRates=new BehaviorSubject<number>(0);
  public counter$=this.countRates.asObservable();



  constructor(private rateService:TemplateServiceService) {
    super();
  }
  connect(): Observable<RateTable[]> {
    return this.rateData.asObservable();

  }
  disconnect() {
    this.rateData.complete();
    this.loadingRates.complete();
  }
  loadData(page:number=0,from_date: any,to_date: any){


      page=page+1;
      this.loadingRates.next(true);
      this.rateService.getRateLogs(page=page,from_date=from_date,to_date=to_date).pipe(
        catchError(()=> of([])),
        finalize(()=>{
          this.loadingRates.next(false);
        })

      ).subscribe((rateLogs:any)=>this.renderOrders(rateLogs))

  }

  renderOrders(rateLogs:any){
    this.countRates.next(rateLogs.count);
    this.rateData.next(rateLogs.results);

  }




}
