export const environment = {
    production: false,
    ip:"https://sipserver.1ounce.in/",
    uploaderIp:"https://sipserver.1ounce.in/",
    is_analytics_enabled: false,


    google_analytics_measurement: "G-LPLL6HKJZE",
    version: '1.9.2' ,
    firebase: {
      apiKey: "AIzaSyAmpC_YypvSFUMjHjg8sJbznTbr3sRyJdY",
      authDomain: "ounceapp-56fb0.firebaseapp.com",
      databaseURL: "https://ounceapp-56fb0.firebaseio.com",
      projectId: "ounceapp-56fb0",
      storageBucket: "ounceapp-56fb0.appspot.com",
      messagingSenderId: "883625753856",
      appId: "1:883625753856:web:08ae40f90f2efb270f07e5",
      measurementId: "G-FXNVDLH1MW",
      vapidKey:"BNUE9IaV20gNPeTLsnhompbT7rVA_exbM09f0ozbCIX-APKgOqxcExL98rRKANoDd3Y_ubXBWUALmKOr0ebsGNU"
    }
    // ip:"https://sipserver.1ounce.in"

  };
