import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopUpData } from '../warning-popup/warning-popup/warning-popup.component';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-lottie-popup',
  templateUrl: './lottie-popup.component.html',
  styleUrls: ['./lottie-popup.component.css']
})
export class LottiePopupComponent {
  
  @Input() pop_up_data!:PopUpData;
  @Input() lottie_file_path!:AnimationOptions;

  @Output() proceedButtonClickEvent = new EventEmitter<boolean>();
  constructor() { }

  proceedButtonClicked(value:boolean){this.proceedButtonClickEvent.emit(value);}
}
