import { DataSource } from '@angular/cdk/collections';
import { RolepermissionService } from './rolepermission.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { RolesandCounters } from '../models/rolesandcounters';

export class CounterDataSource extends DataSource<any> {
  private counterData = new BehaviorSubject<RolesandCounters[]>([]);
  private loadingcounterData = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingcounterData.asObservable();
  private countAccountData = new BehaviorSubject<number>(0);
  public counter$ = this.countAccountData.asObservable();

  constructor(private roleandpermission: RolepermissionService) {
    super();
  }

  connect(): Observable<RolesandCounters[]> {
    return this.counterData.asObservable();
  }
  disconnect() {
    this.counterData.complete();
    this.loadingcounterData.complete();
  }

  loadCounterAccount(page:number=0,search: any,ordering:any,status:any) {
    page=page+1;
    this.loadingcounterData.next(true);
    this.roleandpermission
      .getCounter(page=page,search=search,ordering=ordering,status=status)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this.loadingcounterData.next(false);
        })
      )
      .subscribe((accountLogs) => {
  
        this.renderAccountDetails(accountLogs);
      });
  }


  renderAccountDetails(accountLogs: any) {
    this.countAccountData.next(accountLogs.count);

    this.counterData.next(accountLogs.results);

  }


}
