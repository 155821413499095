import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  constructor(private http:HttpClient) { }
  query = environment.ip+"shop/ledger/";
  sendQuery()
  {
    return this.http.get(`${environment.ip}shop/customOrders/queryContact/`);
  }
}
