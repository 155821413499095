import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseMessage } from 'src/app/models/message';
import { Rest } from 'src/app/models/rest_model';
import { environment } from 'src/environments/environment';
import { CancelData } from '../models/cancelOrder';

@Injectable({
  providedIn: 'root',
})
export class CancelPlanService {
  url = environment.ip + 'shop/';
  constructor(private http: HttpClient) {}

  getCancelledData(id: any) {
    return this.http.get(this.url + 'get_cancelled_data/', {
      params: { plan_id: id },
    });
  }

  cancelData(id: any) {
    return this.http.get(this.url + 'cancel_data/', {
      params: { plan_id: id },
    });
  }

  cancelPlan(
    id: any,
    deduction: any,
    amount_payable: any,
    cancel_date: any,
    payment_mode: any,
    bank: any,
    payment_date: any,
    comments: any
  ) {
    var data = {
      plan_id: id,
      deduction: Number(deduction),
      amount_payable: Number(amount_payable),
      cancel_date: cancel_date,
      payment_mode: Number(payment_mode),
      bank: Number(bank),
      payment_date: payment_date,
      comments: comments,
    };

    const headers = { 'content-type': 'application/json' };

    return this.http.post(this.url + 'cancel_plan/', data, {
      headers: headers,
    });
  }

  editCancelPlan(
    id: any,
    payment_mode: any,
    bank: any,
    payment_date: any,
    comments: any
  ) {
    var data = {
      plan_id: id,
      payment_mode: Number(payment_mode),
      bank: Number(bank),
      payment_date: payment_date,
      comments: comments,
    };
    const headers = { 'content-type': 'application/json' };
    return this.http.post(this.url + 'edit_cancelled_data/', data, {
      headers: headers,
    });
  }

  getCancel(
    page: number,
    from_date: string,
    to_date: string,
    search: string,
    filter: string,
    settlement_id: string = '',
  ) {
    var online = '';
    var selected_types = filter.split(',');

    selected_types.forEach((element: any, index: any) => {
      if (element == '999') {
        online = 'true';
        selected_types.splice(index, 1);
      }
    });
    console.log(online);
    return this.http.get<Rest<CancelData>>(this.url + 'cancelled_report/', {
      params: {
        from_date: from_date,
        to_date: to_date,
        page: page,
        search: search,
        settlement_id: settlement_id,
        payment_mode__in: online?4:`${selected_types.toString()}`,

      },
    });
  }
}
