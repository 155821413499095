import { Component } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-progress-spinner-dialog',
  templateUrl: './progress-spinner-dialog.component.html',
  styleUrls: ['./progress-spinner-dialog.component.css'],
})
export class ProgressSpinnerDialogComponent {
  options: AnimationOptions = {
    path: '/assets/lottie/circular_loading.json'
  };
  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ProgressSpinnerDialogComponent>
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
