import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ledger} from 'src/app/models/ledger';
import { ResponseMessage } from 'src/app/models/message';
import { Rest } from 'src/app/models/rest_model';
import { environment } from 'src/environments/environment';
import {PlanName} from 'src/app/models/planname';


@Injectable({
  providedIn: 'root'
})
export class SavingcustomerService {

  subscription_url = environment.ip+"shop/ledger/";
  plan_url= environment.ip+"shop/savings-plan/get_plans/";
  createSubUrl=environment.ip+"shop/savings-plan/0/create_subscription/";
  send_otp_url=environment.ip+"shop/users/send_otp/";
  otpValidate=environment.ip+"shop/users/validate_otp/" 
  re_send_otp_url=environment.ip+"shop/users/re_send_otp/"; 

  constructor(private http:HttpClient) { }

 
 
  sendOtp(fd:FormData){

    return this.http.post(this.send_otp_url,fd);

   }

   resendOtp(fd:FormData){
    return this.http.post(this.re_send_otp_url,fd);

   }


   getPlans()
   {
    return this.http.get<Array<PlanName>>(this.plan_url);
   }

   dataForInstallment(fd:FormData)
   {
    return this.http.post<ResponseMessage>(this.createSubUrl,fd);
   }


   validateOtp(fd:FormData)
   {
    return this.http.post(this.otpValidate,fd);
   }
   patchUserbyId(id:string,fd:FormData)
   {
     return this.http.put<ResponseMessage>(`${environment.ip}shop/users/${id}/`,fd);
   }

   
  postUser(fd:FormData)
  {
    return this.http.post<ResponseMessage>(`${environment.ip}shop/users/`,fd);
  }

  getuserbyPhone(phone:any)
  {
    return this.http.get<ResponseMessage>(`${environment.ip}shop/users/get_user_data/?phone=${phone}`,{});
  }
  getState(pincode:any)
  {
   return this.http.get(`${environment.ip}shop/pincode/?pincode=${pincode}/`)
  }
}
