import { Component, ViewChild } from '@angular/core';
import { TitleService } from 'src/app/services/title.service';
import { CertificateDataSource } from './certificate-dataSource';
import { PopUpManager } from 'src/app/manager/Popup';
import { CertificateServiceService } from '../certificate-service.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewOrderComponent } from 'src/app/oms/view-order/view-order.component';
import { ListCertificate } from '../models/Certificate-content';
@Component({
  selector: 'app-list-certificate',
  templateUrl: './list-certificate.component.html',
  styleUrls: ['./list-certificate.component.css','../../oms/view-order/view-order.component.css']
})
export class ListCertificateComponent {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  dataSource!:CertificateDataSource;
  popUpManager!: PopUpManager;

  search = "";
  displayedColumns: string[] = [
    'certificate_number','item_code','description','image'
  ];


  constructor(
    private titleService: TitleService,
    private CertificateService : CertificateServiceService,
    private snackBar: MatSnackBar,

    
  ) { }

  ngOnInit(){
    this.titleService.setTitle('List Certificate')
    this.dataSource = new CertificateDataSource(this.CertificateService);
    this.popUpManager = new PopUpManager(this.snackBar);
    this.resetAndDisplayTable()
  } 
  resetAndDisplayTable() {
    this.popUpManager.openSnackBar('Loading', 'cancel');
    // this.paginator.pageIndex = 0;
    this.dataSource.loadData(this.paginator.pageIndex, this.search);
    console.log(this.dataSource)
  }

  openImage(certificate:ListCertificate){
    window.open(certificate.image_url, '_blank');
  } 
  ngAfterViewInit(): void {
    this.dataSource.counter$.subscribe((count) => {
      this.paginator.length = count;
    });
    this.paginator.page.subscribe(() => {

      this.dataSource.loadData(
        this.paginator.pageIndex,
        this.search,
        );
    });

  }
}
