<div class="certificate">
  <div class="certificate_body">
    <div class="header mt-2">
      <!-- 
      <div>
        <img style="max-width: 50px; max-height: 50px;" [src]="certificate_content.store_image">
      </div>
      <div class="header_text">
        <div class="store_name">{{certificate_content.store_name}}</div>
        <div class="address">{{certificate_content.address}}</div>
      </div>
       -->
      <div class="col-md-2 shop_image">
        <img
        style="max-width: 60px; max-height: 60px;"
          [src]="certificate_content.store_image ?? certificate_content.shop_image_url"
        />
      </div>
      <div class="col-md-8 header_text">
        <!-- <div class="store_name">{{ certificate_content.store_name }}</div>
        <div class="address">{{ certificate_content.address }}</div> -->
      </div>
      <div class="col-md-2 shop_image">
        <img
        style="max-width: 60px; max-height: 60px;"
          [src]="certificate_content.sub_brand_image ?? certificate_content.sub_brand_image_url"
        />
      </div>
    </div>
 
    <div class="content">
      <div class="spliter">
        <div class="col-md-8">
          <div
            class=""
            *ngFor="let raw_data of raw_certificate_content; let index"
          >
            <p>
              <strong>{{ raw_data.key }}:</strong> {{ raw_data.value }}
            </p>
          </div>
        </div>
        <div class="col-md-4 image_box">
  
        </div>
      </div>
    </div>
  
    <div class="footer">
      <!-- <p><strong>{{certificate_content.store_name}}</strong></p> -->
      <!-- <p>{{certificate_content.address}}</p> -->
      <p class="disclaim">{{ certificate_content.footer }}</p>
    </div>
  </div>
 
  </div>
  