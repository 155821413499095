import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router'; 
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { RateDataSource } from 'src/app/rate-page/rate-dataSource';
import {MatPaginator} from '@angular/material/paginator';
import { PopUpManager } from 'src/app/manager/Popup';
import { FormControl } from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettlementServiceService } from 'src/app/report/reports/settlement-service.service';
import { UserDataSource } from '../select-users/select-dataSource';









@Component({
  selector: 'app-notification-analytics',
  templateUrl: './notification-analytics.component.html',
  styleUrls: ['./notification-analytics.component.css']
})
export class NotificationAnalyticsComponent implements OnInit {
  displayedColumns: string[] = ['date','title','description','received','cta','conversion'];
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  dataSource!:UserDataSource;
  popupManager!:PopUpManager;
  sort!: MatSort;
  from_date: string = ""; to_date: string = "";ordering:string="id";
  search= "";
  ab:boolean = false;


  constructor(private _location:Location,private router:Router,private modalService:NgbModal,private snackBar:MatSnackBar,private settlementService:SettlementServiceService) { }

  ngOnInit(): void {
    this.popupManager = new PopUpManager(this.snackBar);
    // this.dataSource=new OrderDataSource(this.settlementService);
    // this.dataSource.loadData(0,this.from_date,this.to_date,this.search);
    // console.log(this.dataSource)
  }
  
  back(){
    this._location.back();
  }
  send_notification(){
    this.ab = !this.ab

  }
  open_sent(content:any){
    this.modalService.open(content);
 }
  
  

}
