import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadDialogComponent } from 'src/app/upload-dialog/upload-dialog.component';
import { JewelleryApiResponse, JewelleryModel } from 'src/app/models/jewelleries-model';
import { JewelleryCategoryApiResponse, JewelleryCategoryModel } from 'src/app/models/jewellery-category-model';
import { JewelleryMetalPurityModel } from 'src/app/models/jewellery-metal-purity-model';
import { AnimationOptions } from 'ngx-lottie';
import { CatalogueService } from '../catalogue-service.service';
import { ActionService } from 'src/app/services/actions.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AnalyticService } from 'src/app/services/analytics.service';
import { HttpClient } from '@angular/common/http';
import { ProgressSpinnerDialogComponent } from 'src/app/progress-spinner-dialog/progress-spinner-dialog.component';

class JewelleryUIModel extends JewelleryModel { }

class JewelleryCategoryUIModel extends JewelleryCategoryModel { 
  is_checked: boolean = false;
}

@Component({
  selector: 'app-edit-catalogue',
  templateUrl: './edit-catalogue.component.html',
  styleUrls: ['./edit-catalogue.component.css',],
})

export class EditCatalogueComponent implements OnInit {
  constructor(
    private catalogueService: CatalogueService,
    private actionService: ActionService,
    private analysis: AnalyticService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    public http: HttpClient,
  ) { }

  options: AnimationOptions = {
    path: '/assets/lottie/circular_loading.json'
  };

  @ViewChild('upload_modal') upload_modal!: UploadDialogComponent;

  selected_purities: number[] = [];// review: save list of ids

  metals: any[] = [];
  selected_metal!: number;
  metal_purity: any[] = [];
  activeLink = 0;

  categories = ["Bangles", "Necklace", "Rings"];
  selected_category!: number;

  current_selected_image: any;
  current_selected_type: any;
  current_selected_icon: any;

  enteredDesignName: any;
  
  is_metal_selected = false;

  jewellery_response: JewelleryApiResponse | undefined; // review: snake case, jewellery_response
  category_response: JewelleryCategoryApiResponse | undefined;// review same as above, class names are perfect dont change

  jewellery_model: JewelleryUIModel = new JewelleryUIModel();
  jewellery_category_model: JewelleryCategoryUIModel[] = new Array<JewelleryCategoryUIModel>();
  jewellery_metal_purity_model: JewelleryMetalPurityModel[] = new Array<JewelleryMetalPurityModel>();
  
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      console.log(id);
      this.getJewelleryDetails(id);
    });
    this.getCategories();
    this.getMetalNPurities();
  }
  getJewelleryDetails(id: any) {
    const jewellery_res = this.catalogueService.getJewelleryDetails(id);
    jewellery_res.subscribe((data: any) => {
      this.jewellery_response = data;
      console.log(data);
      this.jewellery_model = data;
      this.current_selected_image = this.jewellery_model.asset_files[0].file;
      this.current_selected_type = this.jewellery_model.asset_files[0].file_type;
      if(this.current_selected_type == 0){ 
        this.current_selected_icon = this.jewellery_model.asset_files[0].icon;
      }
      console.log(this.jewellery_model.name);
    }, error => {
      console.log(error);
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
  }

  setSelectedImage(image: String, type: number) {
    this.current_selected_image = image;
    this.current_selected_type = type;
  }

  getDesignName(event: any) {
    console.log(event.target.value);
    this.enteredDesignName = event.target.value;
  }

  back() {
    this._location.back();
  }

  getCategories() {
    const jewellery_category_res = this.catalogueService.getJewelleryCategoryData();
    jewellery_category_res.subscribe((data: any) => {
      this.category_response = data;
      this.categories = [];
      for (let i = 0; i < data.count; i++) {
        // this.categories.push(data.results[i].name);
        this.jewellery_category_model.push(data.results[i]);
      }
      console.log(data.count);
      console.log(this.jewellery_category_model);
      console.log(this.jewellery_category_model.length);
    }, error => {
      console.log(error);
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
  }

  getMetalNPurities() {
    const jewelleryPurityRes = this.catalogueService.getJewelleryMetalPurityData();
    jewelleryPurityRes.subscribe((data: any) => {
      console.log(data.results);
      this.metals = [];
      for (let i = 0; i < data.count; i++) {
        // this.metals.push(data.results[i].name);
        this.jewellery_metal_purity_model.push(data.results[i]);
        for (let i = 0; i < data.results.length; i++) {
          // add metals only if it is not same as previous
          if (this.metals.indexOf(data.results[i].metal) == -1) {
            this.metals.push(data.results[i].metal);
          }
        }
        for (let i = 0; i < this.metals.length; i++) {
          this.metal_purity[i] = [];
          for (let j = 0; j < data.count; j++) {
            if (this.metals[i] == data.results[j].metal) {
              this.metal_purity[i].push({
                id: data.results[j].id,
                name: data.results[j].name,
              });
            }
          }
        }
      }
      console.log(this.metals);
      console.log(this.metal_purity);
    }, error => {
      console.log(error);
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
  }
  openUploadDialog(id: number): void {
    this.openSpinnerDialog();
    var whatsapp_number = localStorage.getItem('whatsapp_number');

    if (whatsapp_number != null) {
      this.dialog.closeAll();
      const modalRef = this.modalService.open(UploadDialogComponent, { centered: true, size: 'xl', backdrop: 'static', keyboard: false });
      modalRef.componentInstance.id = id;

      modalRef.dismissed.subscribe((data: any) => {
        console.log('Dialog closed with data:', data);
        this.getJewelleryDetails(id);
      });
    } else {
      this.dialog.closeAll();
      this.router.navigate(['/enquiry']);
      this.toastr.info(
        '',
        'Please update your whatsapp number to upload jewellery.',
        { positionClass: 'toast-bottom-right' }
      );
    }
  }
  openSpinnerDialog(): void {
    const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('spinner Dialog closed:', result); // Handle dialog close event
    });
  }
  toggleCategory(category: any) {
    console.log(category);
    this.jewellery_model.category = category.id;
    category.is_checked = !category.is_checked;
    if (category.is_checked) {
      category.is_checked = false;
      this.selected_category = category.id;
    }
  }
  getSelectedMetal(id: any) {
    this.activeLink = id;
    console.log(this.activeLink);
    this.selected_metal = this.metals[this.activeLink];
    this.selected_purities = [];
  }
  getSelectedPurity(id: number) {
    console.log(id);
    if (this.selected_purities.includes(id)) {
      this.selected_purities.splice(this.selected_purities.indexOf(id), 1);
    } else {
      this.selected_purities.push(id);
    }
    console.log(this.selected_purities);
  }
  saveSelectedPurities() {
    console.log(this.selected_metal);
    console.log(this.selected_purities);
    this.jewellery_model.material = this.selected_metal;
  }
  deleteJewellery() {
    this.openSpinnerDialog();
    this.catalogueService.deleteJewellery(this.jewellery_model.id).subscribe((data: any) => {
      console.log(data);
      this.toastr.success(
        '',
        'Jewellery deleted successfully.',
        { positionClass: 'toast-bottom-right' }
      );
      this.modalService.dismissAll();
      this.back();
    });
  }
  saveJewelleryChanges() {
    this.openSpinnerDialog();
    this.catalogueService.updateJewelleryItem(
      this.jewellery_model.id,
      this.jewellery_model.name,
      this.jewellery_model.category,
      this.jewellery_model.material,
      this.selected_purities,
    ).subscribe((data: any) => {
      console.log(data);
      this.toastr.success(
        '',
        'Jewellery updated successfully.',
        { positionClass: 'toast-bottom-right' }
      );
      this.dialog.closeAll();
      this.getJewelleryDetails(this.jewellery_model.id);
    });
  }
}

