<div class="cc_content">
  <!-- form and preview -->
  <div class="form_nd_preview">
    <!-- form -->
    <div class="form">
      <div class="form-box">
        <!-- icons -->
        <div class="flex_icons">
          <input type="file" accept="image/*" (change)="onFileSelected($event, 'store_image')" #store_image_input
            style="display: none" />
          <div *ngIf="
              !certificate_content.store_image &&
              !certificate_content.shop_image_url
            " (click)="store_image_input.click()">
            <span>
              <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="50" height="50" rx="4" fill="#F6F8FE" />
                <path
                  d="M36.5797 14.4849H15.3854C15.1629 14.4849 14.9825 14.6652 14.9825 14.8877V36.082C14.9825 36.3045 15.1629 36.4849 15.3854 36.4849H31.7444L36.9825 31.2468V14.8877C36.9825 14.6652 36.8022 14.4849 36.5797 14.4849Z"
                  fill="#3268FB" />
                <path d="M26.312 21.8174V29.8089" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M22.3162 25.8155H30.3077" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M31.7434 36.4849V32.784C31.7434 31.9351 32.4446 31.2468 33.3092 31.2468H36.9815" fill="#D9D9D9" />
              </svg>
            </span>
            Add Store Logo
          </div>
          <div *ngIf="
              certificate_content.store_image ??
              certificate_content.shop_image_url
            ">
            <img style="max-width: 50px; max-height: 50px" [src]="
                certificate_content.store_image ??
                certificate_content.shop_image_url
              " />
            <span (click)="deleteShopImage()">
              <svg class="ms-2 me-1" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <rect width="16" height="16" fill="url(#pattern0_664_1322)" />
                <defs>
                  <pattern id="pattern0_664_1322" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlink:href="#image0_664_1322" transform="scale(0.0104167)" />
                  </pattern>
                  <image id="image0_664_1322" width="96" height="96"
                    xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAACl0lEQVR4nO2dO44TQRCGK4GA5SDgwHKXEx4ZC0cg23i6zSkIkYDV7nF4ZexyALgIGCRThTSotJnFSthu+++Z/T+pJMtByfM/ZsbB2CKEEEIIIYQQ0hAp+0st3u802V+hj+Pmil9oAl78QhPw4peRmzDP9liLfUzFfu1NvNLK2M841tnCHkkr4qdihhfGD23E79TZQ7T+krJ9wovhkEnF3qP1l6tK4sVQxGRbovWnAWjiggRPYrnBp6C4G4gLEloMPfjEMdsDaYG4G9BsH+KciBfG9zvZlpH8ZsQnhBBCCCGEEPgXpbLfad5htEBKA/AiKRuAF0p5ChrnSOugBVIagBdJ2QC8UMpT0P+LkIp/Tp0fz7v+zvSkP4rXmv2ilog190vrbCPOvOtvre+ZPO9va/HLGuLX3C+ts4VIT67bNc/+rEIDqu6X1tn0gKYn/dF1uyYv+ru7GlB7v7RO7QPSHQ049OeFgxZIaQANgIJOqLIBNAAKOqHKBtAAKOiEKhtAA6CgE6psAA2Agk6osgE0AAo6ocoG0AAo6IQqG0ADoKATqmwADYCCTqiyATQACjqhygbQACjohCobQAOgoBOqbAANgIJOqLIBNAAKOqHKBtAAKOiEKhtAA6CgE6psAA2Agk6osgGbCTTlQ3rYBqTOj/f5mGrt/dI6G4uU/SIeml7fE++l7F92NaD2fmmdLYW6nC38aTy3GxPJrCL+HvZL66RiPyoK17c0Kdt3aR3N/m28BvhXaR0tfjpaA4q/ltaZ5dUkFfszvvSbz7vVPRkCKfs5WjCtP6cyFOL3ea7+ZWI06X/3r98cGoAJfhbVHbDwHskfnPjr1wQt/jbuIAbxHzPZlvFZU/Y3abG6j9aPEEIIIYQQIqPjL0mh8tZyMj7vAAAAAElFTkSuQmCC" />
                </defs>
              </svg>

              Delete Logo
            </span>
          </div>

          <input type="file" accept="image/*" (change)="onFileSelected($event, 'sub_brand_image')" #sb_image_input
            style="display: none" />
          <div *ngIf="
              !certificate_content.sub_brand_image &&
              !certificate_content.sub_brand_image_url
            " (click)="sb_image_input.click()">
            <span>
              <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="50" height="50" rx="4" fill="#F6F8FE" />
                <path
                  d="M36.5797 14.4849H15.3854C15.1629 14.4849 14.9825 14.6652 14.9825 14.8877V36.082C14.9825 36.3045 15.1629 36.4849 15.3854 36.4849H31.7444L36.9825 31.2468V14.8877C36.9825 14.6652 36.8022 14.4849 36.5797 14.4849Z"
                  fill="#3268FB" />
                <path d="M26.312 21.8174V29.8089" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M22.3162 25.8155H30.3077" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M31.7434 36.4849V32.784C31.7434 31.9351 32.4446 31.2468 33.3092 31.2468H36.9815" fill="#D9D9D9" />
              </svg>
            </span>
            Add Sub Brands Logo
          </div>
          <div *ngIf="
              certificate_content.sub_brand_image ??
              certificate_content.sub_brand_image_url
            ">
            <img style="max-width: 50px; max-height: 50px" [src]="
                certificate_content.sub_brand_image ??
                certificate_content.sub_brand_image_url
              " />
            <span (click)="deleteSubBrandImage()">
              <svg class="ms-2 me-1" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <rect width="16" height="16" fill="url(#pattern0_664_1322)" />
                <defs>
                  <pattern id="pattern0_664_1322" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlink:href="#image0_664_1322" transform="scale(0.0104167)" />
                  </pattern>
                  <image id="image0_664_1322" width="96" height="96"
                    xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAACl0lEQVR4nO2dO44TQRCGK4GA5SDgwHKXEx4ZC0cg23i6zSkIkYDV7nF4ZexyALgIGCRThTSotJnFSthu+++Z/T+pJMtByfM/ZsbB2CKEEEIIIYQQ0hAp+0st3u802V+hj+Pmil9oAl78QhPw4peRmzDP9liLfUzFfu1NvNLK2M841tnCHkkr4qdihhfGD23E79TZQ7T+krJ9wovhkEnF3qP1l6tK4sVQxGRbovWnAWjiggRPYrnBp6C4G4gLEloMPfjEMdsDaYG4G9BsH+KciBfG9zvZlpH8ZsQnhBBCCCGEEPgXpbLfad5htEBKA/AiKRuAF0p5ChrnSOugBVIagBdJ2QC8UMpT0P+LkIp/Tp0fz7v+zvSkP4rXmv2ilog190vrbCPOvOtvre+ZPO9va/HLGuLX3C+ts4VIT67bNc/+rEIDqu6X1tn0gKYn/dF1uyYv+ru7GlB7v7RO7QPSHQ049OeFgxZIaQANgIJOqLIBNAAKOqHKBtAAKOiEKhtAA6CgE6psAA2Agk6osgE0AAo6ocoG0AAo6IQqG0ADoKATqmwADYCCTqiyATQACjqhygbQACjohCobQAOgoBOqbAANgIJOqLIBNAAKOqHKBtAAKOiEKhtAA6CgE6psAA2Agk6osgGbCTTlQ3rYBqTOj/f5mGrt/dI6G4uU/SIeml7fE++l7F92NaD2fmmdLYW6nC38aTy3GxPJrCL+HvZL66RiPyoK17c0Kdt3aR3N/m28BvhXaR0tfjpaA4q/ltaZ5dUkFfszvvSbz7vVPRkCKfs5WjCtP6cyFOL3ea7+ZWI06X/3r98cGoAJfhbVHbDwHskfnPjr1wQt/jbuIAbxHzPZlvFZU/Y3abG6j9aPEEIIIYQQIqPjL0mh8tZyMj7vAAAAAElFTkSuQmCC" />
                </defs>
              </svg>

              Delete Logo
            </span>
          </div>
        </div>
        <!-- input forms -->
        <div class="row">
          <div class="col-md-4">
            <div>Store Name*</div>
            <div>Address</div>
            <div>Certificate Title</div>
            <div>Footer (optional)</div>

            <!-- <div>Description</div>
            <div>Certificate Number</div>
            <div>Item Code/HUID</div> -->
          </div>
          <div class="col-md-8">
            <form [formGroup]="certificateSetupForm">
              <div class="form-group">
                <input formControlName="store_name" class="form-control"
                  [ngStyle]="certificateSetupForm.get('store_name')?.invalid && certificateSetupForm.get('store_name')?.touched || certificateSetupForm.get('store_name')?.errors?.['maxlength']? {'margin-bottom':'0px'}:{'margin-bottom':'4.5%'}"
                  (keydown.enter)="address.focus()" [(ngModel)]="certificate_content.store_name"
                  placeholder="Enter Store Name (Max 30 Characters)" />
                <div class="text-right" *ngIf="
                    certificateSetupForm.get('store_name')?.invalid &&
                    certificateSetupForm.get('store_name')?.touched
                  ">
                  <small class="text-danger"
                    *ngIf="certificateSetupForm.get('store_name')?.errors?.['required'] || certificateSetupForm.get('store_name')?.errors?.['pattern']">
                    Store name is required.
                  </small>
                  <small class="text-danger" *ngIf="certificateSetupForm.get('store_name')?.errors?.['maxlength']">
                    Store name must be at most 30 characters long.
                  </small>
                </div>

                <input class="form-control" 
                #address (keydown.enter)="title.focus()" formControlName="address"
                  [(ngModel)]="certificate_content.address" placeholder="Enter Address (Max 200 Characters)" 
                  [ngStyle]="
                    certificateSetupForm.get('address')?.invalid &&
                    certificateSetupForm.get('address')?.touched
                      ? { 'margin-bottom': '0px' }
                      : { 'margin-bottom': '4.5%' }
                  " />
                <div class="text-right" *ngIf="
                    certificateSetupForm.get('address')?.invalid &&
                    certificateSetupForm.get('address')?.touched
                  ">
                  <small class="text-danger" *ngIf="certificateSetupForm.get('address')?.errors?.['maxlength']">
                    Address must be at most 200 characters long.
                  </small>
                </div>

                <!-- title start -->
                <input
                #title
                (keydown.enter)="footer.focus()"
                formControlName="title"
                class="form-control" 
                [(ngModel)]="certificate_content.title" 
                placeholder="Enter Heading (Max 50 Characters)" 
                [ngStyle]="
                    certificateSetupForm.get('title')?.invalid &&
                    certificateSetupForm.get('title')?.touched
                      ? { 'margin-bottom': '0px' }
                      : { 'margin-bottom': '4.5%' }
                  "/>
                   <div class="text-right" *ngIf="
                   certificateSetupForm.get('title')?.invalid &&
                   certificateSetupForm.get('title')?.touched
                 ">
                 <small class="text-danger" *ngIf="certificateSetupForm.get('title')?.errors?.['maxlength']">
                   title must be at most 50 characters long.
                 </small>
               </div>
                
                
                <!-- title end -->
                
                <input class="form-control" #footer formControlName="footer" [ngStyle]="
                    certificateSetupForm.get('footer')?.invalid &&
                    certificateSetupForm.get('footer')?.touched
                      ? { 'margin-bottom': '0px' }
                      : { 'margin-bottom': '4.5%' }
                  " [(ngModel)]="certificate_content.footer"
                  placeholder="Enter footer description (Max 150 Characters)" />
                <div class="text-right" *ngIf="
                    certificateSetupForm.get('footer')?.invalid &&
                    certificateSetupForm.get('footer')?.touched
                  ">
                  <small class="text-danger" *ngIf="certificateSetupForm.get('footer')?.errors?.['maxlength']">
                    Footer must be at most 150 characters long.
                  </small>
                </div>
        
              </div>
            </form>
          </div>
        </div>
        <div class="row" *ngFor="let raw_data of raw_certificate_content; let index">
          <div class="col-md-4">
            <input class="form-control" [(ngModel)]="raw_data.key" placeholder="Enter label" />
          </div>
          <div class="col-md-8">
            <input class="form-control" [(ngModel)]="raw_data.value" placeholder="Enter value" />
          </div>
        </div>
 
      </div>
    </div>
    <!-- preview -->
    <div class="preview">
      <div class="preview-box">
        <div class="text-center">Front</div>
        <app-basic-template [certificate_content]="certificate_content"
          [raw_certificate_content]="raw_certificate_content"></app-basic-template>
      </div>
    </div>
  </div>
  <div class="button_block">
    <button class="btn" (click)="postCertificateSetup()">Proceed</button>
  </div>
</div>

<ng-template #completed>
  <app-lottie-popup (proceedButtonClickEvent)="afterPopup($event)" [pop_up_data]="certificate_completion_popup"
    [lottie_file_path]="setup_completion_lottie_file_path"></app-lottie-popup>
</ng-template>