import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { PaymentDataSource } from './payment.dataSource';
import { PaymentService } from './payment.service';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from 'src/app/models/message';
import { Router } from '@angular/router';
import { NONE_TYPE } from '@angular/compiler';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopUpManager } from 'src/app/manager/Popup';
import { offlineBankAccounts } from '../models/offlinebankaccount';
import { count } from 'rxjs';
import { HostListener } from '@angular/core';
import { AnalyticService } from '../services/analytics.service';
import { TitleService } from '../services/title.service';
import { ActionService } from 'src/app/services/actions.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit {
  deletenew: any;
  status_of_account = false;
  edit_submit = false;
  disable_options = false;
  popUpManager!: PopUpManager;
  displayedColumns: string[] = [
    'payment_code',
    'name',
    'number',
    'ifsc',
    'branch_name',
    'is_enabled',
    'actions',
  ]; //'type'
  displayedColumnsPos: string[] = ['payment_code', 'is_enabled', 'actions']; //POS Col
  displayedColumnsSettlement: string[] = ['accountname','accountno', 'ifsc', 'actions']; //settlement
  status: boolean = false;
  dataSource!: PaymentDataSource; //datasource of accounts
  dataSourcePOS!: PaymentDataSource; //data source of POS
  dataSourceSettlement!:PaymentDataSource;

  show_add = false;
  edit_id: any;
  show_edit = false;
  currentAccount!: offlineBankAccounts;
  responseMessage!: ResponseMessage;
  popupManager!: PopUpManager;
  allowed!: any;

  constructor(
    private modalService: NgbModal,
    private paymentService: PaymentService,
    private toastr: ToastrService,
    private router: Router,
    private snackBar: MatSnackBar,
    private analysis: AnalyticService,
    private titleService: TitleService,
    private actionService: ActionService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Payment Accounts');
    this.analysis.logEvent('Payment Page Loaded Successfully');
    this.dataSource = new PaymentDataSource(this.paymentService);
    this.dataSource.loadbankAccount(0);
    this.dataSourcePOS = new PaymentDataSource(this.paymentService);
    this.dataSourcePOS.loadbankAccount(1);
    this.dataSourceSettlement=new PaymentDataSource(this.paymentService);
    this.dataSourceSettlement.loadbankAccount(2);
    this.allowed = this.actionService.getActions();
  }

  callMe(content: any) {
    this.show_add = !this.show_add;

    this.modalService.open(content, { centered: true });

    return true;
  }

  disbale_opt() {
    this.disable_options = false;
    return true;
  }

  showPos(content: any) {
    this.modalService.open(content, { centered: true, size: 'sm' });
  }

  openContent(content:any)
  {
    this.modalService.open(content,{centered:true})
  }
  deleteNA(deletecontent: any) {
    this.deletenew = deletecontent;
    return true;
  }

  onKeyDown(event: KeyboardEvent) {
    const key = event.key;
    const isAlphabeticOrSpace = /^[a-zA-Z\s]$/.test(key);
    const isBackspace = key === 'Backspace';
    if (!isAlphabeticOrSpace && !isBackspace) {
      event.preventDefault();
    }
  }

  //toggle button in payment active/disbaled
  toggle(
    id: any,
    ac: any //deactive
  ) {
    var element = <HTMLBodyElement>document.getElementById(id); //btn btn-default
    var onelemnt = <HTMLBodyElement>document.getElementById(id + 50); ///btn btn-success
    element.classList.remove('btn-danger');
    element.classList.add('btn-danger');
    onelemnt.classList.remove('btn-success');

    var count = 1;
    if (count === 1) {
      this.status_of_account = false;
      count += 1;
      this.changeStatus(id, ac);
    }
  }

  //toggle button in payment active/disbaled
  togglenew(
    id: any,
    ac: any //active
  ) {
    var element = <HTMLBodyElement>document.getElementById(id); //btn btn-default
    var onelemnt = <HTMLBodyElement>document.getElementById(id + 50); ///btn btn-success

    element.classList.remove('btn-danger');
    onelemnt.classList.add('btn-success');

    var count = 1;
    if (count === 1) {
      this.status_of_account = true;
      count += 1;
      this.changeStatus(id, ac);
    }
  }

  showEditAccount(id: any) {
    localStorage.setItem('bankAccountId', id);
    this.edit_id = localStorage.getItem('bankAccountId');
    return true;
  }

  editAccount(content: any) {
    this.show_add = !this.show_add;

    this.modalService.open(content, { centered: true });

    this.getEditAccount();
    return true;
  }

  getEditAccount() {
    if (
      localStorage.getItem('bankAccountId') != null &&
      localStorage.getItem('bankAccountId') != undefined
    ) {
      this.edit_id = localStorage.getItem('bankAccountId');
      this.disable_options = true;
      this.edit_submit = true;
    }
    this.paymentService.getAccountById(this.edit_id).subscribe((data) => {
      this.analysis.logEvent('Getting Payment Credential of Selected Bank');
      this.currentAccount = data;
      (<HTMLInputElement>document.getElementById('paymentcode')).value =
        this.currentAccount.payment_code;
      (<HTMLInputElement>document.getElementById('accountname')).value =
        this.currentAccount.name;
      (<HTMLInputElement>document.getElementById('accountno')).value =
        this.currentAccount.number;
      (<HTMLInputElement>document.getElementById('ifsc')).value =
        this.currentAccount.ifsc;
      (<HTMLInputElement>document.getElementById('branch')).value =
        this.currentAccount.branch_name;
    });
  }

  changeStatus(id: any, ac: any) {
    if (this.status_of_account === true) {
      var fd = new FormData();
      fd.append('number', ac);
      fd.append('is_enabled', '1');
      this.paymentService.editAccountbyId(id, fd).subscribe(
        (data) => {
          this.analysis.logEvent(
            'Changing Status to Active of Selected Bank/POS '
          );
          this.responseMessage = data;

          this.toastr.success('', 'Account Activated', {
            positionClass: 'toast-bottom-right',
          });
        },
        (error: any) => {
          this.toastr.info(
            '',
            'Something went wrong, please do try again later.',
            { positionClass: 'toast-bottom-right' }
          );
        }
      );
    } else if (this.status_of_account == false) {
      var fd = new FormData();
      fd.append('number', ac);
      fd.append('is_enabled', '0');
      this.paymentService.editAccountbyId(id, fd).subscribe(
        (data) => {
          this.analysis.logEvent(
            'Changing Status to Disabled of Selected Bank/POS '
          );
          this.responseMessage = data;

          this.toastr.success('', 'Account Disabled', {
            positionClass: 'toast-bottom-right',
          });
        },
        (error: any) => {
          this.toastr.info(
            '',
            'Something went wrong, please do try again later.',
            { positionClass: 'toast-bottom-right' }
          );
        }
      );
    }
  }

  reloadfunc() {
    window.location.reload();
  }
  createPayment() {
    var paymentcode = (<HTMLInputElement>document.getElementById('paymentcode'))
      .value;
    var accountname = (<HTMLInputElement>document.getElementById('accountname'))
      .value;

    var accountno = (<HTMLInputElement>document.getElementById('accountno'))
      .value;
    var ifsc = (<HTMLInputElement>document.getElementById('ifsc')).value;
    var branch = (<HTMLInputElement>document.getElementById('branch')).value;

    var fd = new FormData();

    if (paymentcode == '') {
      this.toastr.info('', 'Invalid payment code', {
        positionClass: 'toast-bottom-right',
      });
      return;
    } else if (accountname == '') {
      this.toastr.info('', 'Invalid account name duration', {
        positionClass: 'toast-bottom-right',
      });
      return;
    } else if (accountno == '') {
      this.toastr.info('', 'Invalid account number', {
        positionClass: 'toast-bottom-right',
      });
      return;
    } else if (ifsc == '') {
      this.toastr.info('', 'Invalid ifsc code', {
        positionClass: 'toast-bottom-right',
      });
      return;
    } else if (branch == '') {
      this.toastr.info('', 'Invalid branch', {
        positionClass: 'toast-bottom-right',
      });
      return;
    }

    fd.append('payment_code', paymentcode);
    fd.append('name', accountname);
    fd.append('number', accountno);
    fd.append('ifsc', ifsc);
    fd.append('branch_name', branch);

    fd.append('type', '0');
    fd.append('is_enabled', '0');

    this.paymentService.addBankAcoount(fd).subscribe(
      (data) => {
        this.analysis.logEvent('New Bank Payment Account Created Successfully');
        this.responseMessage = data;
        this.toastr.success('', 'Bank Account Created Successfully', {
          positionClass: 'toast-bottom-right',
        });

        window.setTimeout(() => {
          location.reload();
        }, 1000);
      },
      (error: any) => {
        this.analysis.logEvent('New Bank Payment Account Creation Failed');
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
        // window.setTimeout(()=>{location.reload()},1000)
      }
    );
  }

  createPOS() {
    var paymentcodepos = (<HTMLInputElement>(
      document.getElementById('paymentcodepos')
    )).value;
    

    var fd = new FormData();

    if (paymentcodepos == '') {
      this.toastr.info('', 'Invalid payment code', {
        positionClass: 'toast-bottom-right',
      });
      return;
    }

    fd.append('payment_code', paymentcodepos);
    fd.append('name', '');
    fd.append('number', paymentcodepos);
    fd.append('ifsc', '');
    fd.append('branch_name', '');

    fd.append('type', '1');
    fd.append('is_enabled', '1');

    this.paymentService.addBankAcoount(fd).subscribe(
      (data) => {
        this.analysis.logEvent('New Bank POS Account Created Successfully');
        this.responseMessage = data;

        this.toastr.success('', 'POS Account Added', {
          positionClass: 'toast-bottom-right',
        });
        window.setTimeout(() => {
          location.reload();
        }, 1000);
      },
      (error: any) => {
        this.analysis.logEvent('New Bank POS Account Creation Failed');
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
        // window.setTimeout(()=>{location.reload()},1000)
      }
    );
  }

  reload() {
    window.location.reload();
  }

  commonFunction() {
    if (this.edit_submit) {
      this.editAccountDetails();
    } else {
      this.createPayment();
    }
  }

  editAccountDetails() {
    var paymentcode = (<HTMLInputElement>document.getElementById('paymentcode'))
      .value;
    var accountname = (<HTMLInputElement>document.getElementById('accountname'))
      .value;

    var accountno = (<HTMLInputElement>document.getElementById('accountno'))
      .value;
    var ifsc = (<HTMLInputElement>document.getElementById('ifsc')).value;
    var branch = (<HTMLInputElement>document.getElementById('branch')).value;

    var fd = new FormData();

    if (paymentcode == '') {
      // this.toastr.info("","Invalid payment code", { positionClass: "toast-bottom-right" });
      return;
    }
    if (accountname == '') {
      this.toastr.info('', 'Invalid account name duration', {
        positionClass: 'toast-bottom-right',
      });
      return;
    } else if (accountno == '') {
      this.toastr.info('', 'Invalid account number', {
        positionClass: 'toast-bottom-right',
      });
      return;
    } else if (ifsc == '') {
      this.toastr.info('', 'Invalid ifsc code', {
        positionClass: 'toast-bottom-right',
      });
      return;
    } else if (branch == '') {
      this.toastr.info('', 'Invalid branch', {
        positionClass: 'toast-bottom-right',
      });
      return;
    }

    fd.append('payment_code', paymentcode);
    fd.append('name', accountname);
    fd.append('number', accountno);
    fd.append('ifsc', ifsc);
    fd.append('branch_name', branch);

    fd.append('type', '0');

    fd.append('is_enabled', '1');
    // fd.append("image",image);
    // this.popUpManager.openSnackBar("Loading","cancel");

    this.paymentService.editAccountbyId(this.edit_id, fd).subscribe(
      (data) => {
        this.analysis.logEvent('New Bank Payment Account Edited Successfully');
        this.responseMessage = data;

        this.toastr.success(
          'Account Edited Successfully',
          this.responseMessage.message,
          {
            positionClass: 'toast-bottom-right',
          }
        );

        window.setTimeout(() => {
          location.reload();
        }, 1000);
      },
      (error: any) => {
        this.analysis.logEvent('New Bank Payment Account Edit Failed');
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }

  deleteRow() {
    // var fd = new FormData();

    // fd.append('is_deleted', 'true');

    this.paymentService.deleteRow(this.edit_id).subscribe(
      (data: any) => {
        this.analysis.logEvent(
          'Payment Bank/POS Account Deletion Successfully '
        );
        this.popupManager = new PopUpManager(this.snackBar);
        this.popupManager.openSnackBar('Loading ...', 'close');

        this.toastr.success('', 'Deleted Successfully', {
          positionClass: 'toast-bottom-right',
        });
        window.setTimeout(() => {
          location.reload();
        }, 1000);
      },
      (error: any) => {
        this.analysis.logEvent('Payment Bank/POS Account Deletion Failed ');
        this.modalService.open(this.deletenew, { centered: true });
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
        // window.setTimeout(()=>{location.reload()},1000)
      }
    );
  }
  actionsCheck(value: any) {
    if (this.allowed == 'admin') {
      return true;
    }
    if (this.allowed.includes(value)) {
      return true;
    } else {
      return false;
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalService.dismissAll();
    }
  }
}
