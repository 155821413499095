import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '../models/customer';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from '../models/message';
import { Form } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class NewCustomerService {
  url = environment.ip + 'shop/users/';
  send_otp_url = environment.ip + 'shop/users/send_otp/';
  otpValidate = environment.ip + 'shop/users/validate_otp/';
  re_send_otp_url = environment.ip + 'shop/users/re_send_otp/';

  pin_to_state = 'https://api.postalpincode.in/pincode/';

  constructor(private http: HttpClient) {}

  postUser(
    fullname: any,
    phoneno: any,
    dob: any,
    email: any,
    gender: any,
    address: any,
    pincode: any,
    state: any,
    referred:any
  ) {
    var fd = new FormData();
    fd.append('first_name', fullname);
    fd.append('phone', phoneno);
    fd.append('dob', dob);

    fd.append('email', email);
    fd.append('gender', gender);

    fd.append('address', address);
    fd.append('pincode', pincode);
    fd.append('state', state);
    fd.append('referred_by',referred);

    return this.http.post<ResponseMessage>(`${environment.ip}shop/users/`, fd);
  }
// this api will be used for oms while create a new custoer if it does'nt exist
  postUserOms(
    fullname: any,
    phoneno: any,
    dob: any,
    email: any,
    gender: any,
    address: any,
    pincode: any,
    state: any,
    referred:any,
    pan:any,
    gstin:any
  )
  {
    var fd = new FormData();
    fd.append('first_name', fullname);
    fd.append('phone', phoneno);
    fd.append('dob', dob);

    fd.append('email', email);
    fd.append('gender', gender);

    fd.append('address', address);
    fd.append('pincode', pincode);
    fd.append('state', state);
    fd.append('pan',pan);
    fd.append('gstin',gstin);
    fd.append('referred_by',referred);
    return this.http.post<ResponseMessage>(`${environment.ip}shop/users/`, fd);

  }

  getUserbyId(id: string) {
    return this.http.get<Customer>(`${environment.ip}shop/users/${id}/`);
  }
  patchUserbyId(
    id: string,
    fullname: any,
    dob: any,
    email: any,
    gender: any,
    address: any,
    pincode: any,
    state: any,
    gstin:any,
    pan:any
  ) {
    var fd = new FormData();
    fd.append('first_name', fullname);
    fd.append('dob', dob);
    fd.append('email', email);
    fd.append('gender', gender);
    fd.append('address', address);
    fd.append('pincode', pincode);
    fd.append('state', state);
    fd.append('gstin',gstin);
    fd.append('pan',pan)

    return this.http.put<ResponseMessage>(
      `${environment.ip}shop/users/${id}/`,
      fd
    );
  }
  sendOtp(mobile: any) {
    var fd = new FormData();
    fd.append('mobile', mobile);
    return this.http.post(this.send_otp_url, fd);
  }
  validateOtp(mobile: any, otp: any) {
    var fd = new FormData();
    fd.append('mobile', mobile);
    fd.append('otp', otp);
    return this.http.post(this.otpValidate, fd);
  }

  resendOtp(fd: FormData) {
    return this.http.post(this.re_send_otp_url, fd);
  }

  getState(pincode: any) {
    return this.http.get(`${environment.ip}shop/pincode/?pincode=${pincode}/`);
  }
  searchUser(search:any)
  {


    return this.http.get(`${environment.ip}shop/users/search_user/?search=${search}`);
  }
}
