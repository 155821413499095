import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NgbModal, } from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';






@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],


})
export class CreateComponent implements OnInit {
  model!: NgbDateStruct;
  formData = [];
  title = 'Forms';
  @ViewChild('f')
  Forms!: NgForm;
  submitted = false;


  constructor(private _location:Location,private modalService:NgbModal,private calendar: NgbCalendar) { }

  ngOnInit(): void {
  }
  isDisabled = (date: NgbDate, current: { month: number; year: number }) => date.month !== current.month;
	isWeekend = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;
  back(){
    this._location.back();
  }
  onSubmit() {
    this.submitted = true;
  

}
}
