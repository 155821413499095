<!-- <div class="body">
  <div class="main" *ngIf="false" >
    <div class="row">
      <div class="col-12 col-lg-6 col-md-6 padding-0">
        <div class="left-half p-5">
          <article>
            <p class="header1 headingColor">Admin Login</p>
            <p class="para1 subheadingColor1">Input login credentials</p>
            <div class="login_flow mt-3">
              <form>
                <div class="block1">
                  <div class="form-row">
                    <div class="form-group col-md-11 col-lg-11 col-12">
                      <label for="inputEmail4" class="button subheadingColor"
                        >Shop Id*</label
                      >
                      <div class="input-group">
                        <input
                          [(ngModel)]="shop_id"
                          type="text"
                          id="shop_id"
                          class="form-control p-3 input_class"
                          maxlength="20"
                          placeholder="Type your shop id here"
                          name="shop"
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-11 col-lg-11 col-12 mt-4">
                      <label for="inputEmail4" class="button subheadingColor"
                        >Mobile*</label
                      >
                      <div class="input-group">
                        <input
                          [(ngModel)]="mobile_number"
                          type="text"
                          id="user_id"
                          class="form-control p-3 input_class"
                          maxlength="20"
                          placeholder="Type your phone number here"
                          (input)="onPhoneChange($event)"
                          name="mobile"
                        />
                      </div>
                    </div>
                  </div>

                  <div *ngIf="show_otp_box" class="form-row mt-4">
                    <div class="form-group col-md-11 col-lg-11 col-12">
                      <label for="inputEmail4" class="button subheadingColor"
                        >OTP*</label
                      >
                      <div class="input-group text-center">
                        <ng-otp-input
                          (onInputChange)="onOtpChange($event)"
                          [config]="{ length: 4 }"
                        ></ng-otp-input>
                      </div>
                    </div>
                  </div>

                  <div class="button otp_btn mt-5">
                    <button
                      disabled
                      (click)="sendOtp()"
                      id="send_otp_btn"
                      type="button"
                      class="btn btn-primary btn-lg cus_btn"
                    >
                      <span class="button button_text"
                        >Send OTP
                        <span
                          *ngIf="show_otp_spinner"
                          class="spinner-border spinner_in_button text-white"
                        ></span
                      ></span>
                    </button>
                  </div>
                </div>

                <div class="block2">
                  <p id="send_otp_label" class="para1 mt-3 otp_sent_label">
                    OTP sent
                    <span class="subheadingColor1 clock">- Valid for </span
                    ><span id="countdown">60</span> sec
                  </p>

                  <div>
                    <p
                      *ngIf="show_resend_otp"
                      (click)="resendOtp()"
                      id="resend"
                      class="para1 ff pl-1 pointer_class"
                    >
                      Resend OTP
                    </p>
                  </div>

                  <div class="button fnl_btn1 mb-5">
                    <button
                      (click)="login()"
                      *ngIf="show_login_button"
                      id="login_btn"
                      type="button"
                      class="btn btn-primary btn-lg mt-2 cus_btn"
                    >
                      <span class="button button_text"
                        >Login
                        <span
                          class="spinner-border spinner_in_button text-white"
                          *ngIf="show_login_spinner"
                        ></span
                      ></span>
                    </button>
                  </div>

                  <div class="mt-5"></div>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>

      <div class="col-12 col-lg-6 col-md-6 padding-0 rightCol">
        <div class="right-half">
          <div class="logo_card">
            <div class="logo text-center">
              <img src="assets/images/mobile.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div
  class=""
  style="
    background: #e5e5e5;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
  "
>
  <div
    style="background: #e5e5e5; width: auto; height: 100vh; overflow-x: hidden"
  >
    <div class="row p-4">
      <span class="spann" style="height: 70px">
        <img
          src="assets/images/jewellers.live.svg"
          width="181px"
          height="34px"
        />
      </span>
    </div>
    <!-- flex box -->
<div class="content_login">
  <div class="image_col" style="">
    <img
      src="assets/images/loginside.svg"
      width="100%"
      height="auto"
      class="image_girl"
      style="margin-top: 1%;"
    />
  </div>
  <div class="login_block">
    <div
      class="card login_block_Card login_b"
      style="width: 100%; height: auto; max-height: auto"
    >
      <ul
        class="nav nav-tabs nav-justified"
        id="myTab"
        role="tablist"
        style="height: 72px"
      >
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
            (click)="showJeweler(); getElementsById(); setupScreen()"
          >
            Jeweler Login
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
            (click)="showCounter()"
          >
            Counter Login
          </button>
        </li>
      </ul>

      <!-- counter login card body -->
      <div class="card-body" id="count_login">
        <div class="ms-4">
          <div class="card-title mt-3 mb-5">Login</div>
          <span class="login-label">Store ID*</span>
          <div class="form-group col-md-11 col-lg-11 col-12 mt-3 mb-4">
            <div class="input-group">
              <input
                [(ngModel)]="counter_store_id"
                class="form-control p-3 input_class"
                maxlength="20"
                placeholder="Type your shop id here"
                name="shop"
                (keydown.enter)="counter_id_input.focus()"
              />
            </div>
          </div>
          <span class="login-label">Counter ID*</span>
          <div class="form-group col-md-11 col-lg-11 col-12 mt-3 mb-4">
            <div class="input-group">
              <input
              #counter_id_input
                [(ngModel)]="counter_id"
                class="form-control p-3 input_class"
                maxlength="20"
                placeholder="Type your shop id here"
                name="shop"
                appUppercaseNoSpecial
              />
            </div>
          </div>
          <div class="login-label mb-3">Enter Pin*</div>

          <ng-otp-input
          #counter_otp_input
            (onInputChange)="onPinChangeCounter($event)"
            [config]="{ length: 4 }"
            allowNumbersOnly
          ></ng-otp-input>
          <button
          type="button"
          (click)="
            counter == 60 || counter == 0
              ? sendCounterOtp(showotp)
              : openContent(showotp)
          "
          class="btn btn-primary cus_btn mt-4"
        >
          Login
          <a style="text-decoration: none"> </a>
        </button>
        </div>
     
      </div>

      <!-- jeweller login here -->
      <div class="card-body" id="jew_login">
        <div class="ms-4 mobile_rem_margin">
          <div class="card-title mt-3 mb-5">Jeweler Login</div>
          <span class="login-label">Store ID*</span>
          <div
            class="form-group fields_mobile col-md-11 col-lg-11 col-12 mt-3 mb-4"
          >
            <div class="input-group">
              <input
                [(ngModel)]="shop_id"
                type="text"
                id="shop_id"
                class="form-control p-3 input_class"
                maxlength="20"
                placeholder="Type your shop id here"
                name="shop"
                (keydown.enter)="jew_mobile_input.focus()"
              />
            </div>
          </div>
          <span class="login-label">Mobile*</span>

          <div
            class="form-group fields_mobile col-md-11 col-lg-11 col-12 mt-4 mb-4"
          >
            <div class="input-group">
              <input
                #jew_mobile_input
                [(ngModel)]="mobile_number"
                type="text"
                id="user_id"
                class="form-control p-3 input_class"
                maxlength="20"
                placeholder="Type your phone number here"
                (input)="onPhoneChange($event)"
                name="mobile"
                (keydown.enter)="sendOtp()"

              />
            </div>
          </div>
          <div class="enter_pin_mobile" *ngIf="show_otp_box">
            <div class="login-label mb-3">Enter Pin*</div>
            <ng-otp-input
              #otpInput
              id="otp_input"
              (keyup.enter)="login()"
              (onInputChange)="onOtpChange($event)"
              [config]="{ length: 4, allowNumbersOnly: true }"
            ></ng-otp-input>
          </div>
          <button
            disabled
            (click)="sendOtp()"
            id="send_otp_btn"
            type="button"
            class="btn btn-primary btn-lg cus_btn mt-2"
          >
            <span class="button button_text"
              >Send OTP
              <span
                *ngIf="show_otp_spinner"
                class="spinner-border spinner_in_button text-white"
              ></span
            ></span>
          </button>

              <div class="mt-3 account_class" style="margin-left: 47%;"  (click)="showRegister()">
                Don't have an account ?
              </div>
            </div>
            <div>
              <div class="block2">
                <div class="ms-4 otp_send_mobile">
                  <p id="send_otp_label" class="para1 mt-3 otp_sent_label">
                    OTP sent
                    <span class="subheadingColor1 clock">- Valid for </span
                    ><span id="countdown">60</span> sec
                  </p>
                  <div>
                    <p
                      *ngIf="show_resend_otp"
                      (click)="resendOtp()"
                      id="resend"
                      class="para1 ff pl-1 pointer_class"
                    >
                      Resend OTP
                    </p>
                  </div>
                </div>

                <button
                  type="button"
                  (click)="login();sendEvent('AP_user_login_click')"
                  *ngIf="show_login_button"
                  class="btn btn-primary next-btn"
                >
                  Login
                  <span
                    class="spinner-border spinner_in_button text-white"
                    *ngIf="show_login_spinner"
                  ></span>
                  <a style="text-decoration: none"> </a>
                </button>
              </div>
            </div>
          </div>

      <!-- jeweller register -->

      <div class="card-body" id="jew_register">
        <div class="ms-4 mobile_rem_margin" style="margin: 7% 0%">
          <div class="card-title mb-5">Create Account</div>
          <div id="mobile_block">
            <span class="login-label">Mobile Number*</span>

            <div
              class="form-group fields_mobile col-md-11 col-lg-11 col-12 mt-3 mb-2"
            >
              <div class="input-group">
                <input
                  [(ngModel)]="mobile_number"
                  type="text"
                  id="user_id"
                  class="form-control p-3 input_class"
                  maxlength="20"
                  placeholder="Type your phone number here"
                  (input)="onPhoneChange($event)"
                  name="mobile"
                />
              </div>
            </div>
          </div>

          <div class="enter_pin_mobile mt-4" *ngIf="show_otp_box">
            <div class="login-label mb-3">Enter OTP*</div>
            <ng-otp-input
              #otpInput
              (keyup.enter)="proceedClick()"
              (onInputChange)="onOtpChange($event)"
              [config]="{ length: 4, allowNumbersOnly: true }"
            ></ng-otp-input>
          </div>
          <!-- shop name and type -->
          <div *ngIf="show_shop_name">
            <!-- store name -->
            <div class="enter_pin_mobile mt-4">
              <div class="login-label">Enter Shop Name*</div>
              <div
                class="form-group fields_mobile col-md-11 col-lg-11 col-12 mt-3 mb-2"
              >
                <input
                  [(ngModel)]="shop_name"
                  type="text"
                  id="shop_name"
                  class="form-control p-3 input_class"
                  maxlength="20"
                  placeholder="Type your shop name here"
                  name="shop_name"
                />
              </div>
            </div>
            <!-- store type -->
            <div class="enter_pin_mobile mt-4">
              <div class="login-label mb-2">Store Type*</div>
              <div
                class="form-group fields_mobile col-md-11 col-lg-11 col-12 mt-3 mb-2"
              >
                <select
                  class="form-select p-3 input_class store_type_select"
                  id="shop_type"
                  name="shop_type"
                  [(ngModel)]="shop_type"
                >
                  <option value="0">Retail</option>
                  <option value="1">Wholesaler/Manufacturer</option>
                  <option value="2">Bullion Dealer</option>
                  <option value="3">Goldsmith</option>
                </select>
              </div>
            </div>
            <!-- Create Account  -->
            <button
              type="button"
              (click)="createShop()"
              class="btn btn-primary create_account_btn"
              [disabled]="!create_shop_button"
            >
              Create Account
              <span
                class="spinner-border spinner_in_button text-white"
                *ngIf="show_register_spinner"
              ></span>
              <a style="text-decoration: none"> </a>
            </button>
          </div>
          <button
          disabled
          (click)="sendOtpRegister()"
          id="send_otp_btn_reg"
          type="button"
          class="btn btn-primary btn-lg cus_btn mt-3"
        >
          <span class="button button_text"
            >Send OTP
            <span
              *ngIf="show_otp_spinner"
              class="spinner-border spinner_in_button text-white"
            ></span
          ></span>
        </button>


        </div>
        <div>
          <div class="block2">
            <div class="ms-4 otp_send_mobile">
              <p
                *ngIf="show_countdown"
                id="send_otp_label_reg"
                class="para1 otp_sent_label mt-2"
              >
                OTP sent
                <span class="subheadingColor1 clock">- Valid for </span
                ><span id="countdown_reg">60</span> sec
              </p>
              <div>
                <p
                  *ngIf="show_resend_otp"
                  (click)="resendOtpRegister()"
                  id="resend"
                  class="para1 ff pl-1 pointer_class"
                >
                  Resend OTP
                </p>
              </div>
            </div>

            <button
              type="button"
              *ngIf="show_proceed_button"
              class="btn btn-primary next-btn"
              style="width: 86.5%;"
              (click)="proceedClick()"
            >
              Proceed
              <span
                class="spinner-border spinner_in_button text-white"
                *ngIf="show_register_spinner"
              ></span>
              <a style="text-decoration: none"> </a>
            </button>
          </div>
        </div>
        <div
        class="mt-2 account_class"
        id="already_account"
        (click)="create_shop_button?alreadyAccount():''"

      >
        Already have an account ? <span class="sign_in">Sign In</span>
  </div>
      </div>
    </div>
  </div>
</div>
     
  </div>
</div>

<ng-template #showotp let-modal>
  <div class="pb-5 p-3">
    <div class="modal-body">
      <div class="text-center mt-3">
        <span class="otp">Enter OTP</span>
        <br />
        <p style="text-align: center" class="mt-4 otp_text">
          Kindly enter OTP that is sent to admin <br />
          mobile number {{ admin_mobile }}.
        </p>

        <p class="greyColor">
          <span>
            <ng-otp-input
              allowNumbersOnly
              (onInputChange)="onOtpChangeCounter($event)"
              [config]="{ length: 4 }"
            ></ng-otp-input>
            <div class="mt-2">
              <div *ngIf="counter > 0; else showThis" class="mt-2">
                <div>Resend after {{ counter }} sec</div>
              </div>
            </div>
            <!-- <div *ngIf="ShowResend == false" class="mt-2">
                <a type="button"> Resend </a>
              </div> -->
            <ng-template #showThis>
              <div class="mt-2">
                <a
                  (click)="
                    setCounter();
                    run = true;
                    run1 = true;
                    timerFun();
                    counterResendOtp(false)
                  "
                  type="button"
                >
                  Resend
                </a>

                <span class="mx-1" type="text"> OR </span>
                <a
                  (click)="
                    setCounter();
                    run = true;
                    run1 = true;
                    timerFun();
                    counterResendOtp(true)
                  "
                  type="button"
                >
                  OTP via Call
                </a>
                <!-- <a (click)="setCounter();run=true;run1=true;timerFun();getOtpNumber(showotp);" type="button"> Resend </a> -->
              </div>
            </ng-template>
          </span>
        </p>

        <button
          (click)="counterLogin(); modal.dismiss('Cross click')"
          type="button"
          class="btn btn-primary mt-3 addbutton1"
        >
          Verify
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #shopinfo let-modal id="shop_info">
  <div class="pb-5 p-3">
    <div class="modal-body">
      <div class="text-center mt-3">
        <span class="otp">Regsitration successful</span>
        <br />
        <p style="text-align: center" class="mt-4 otp_text">
          Hi {{ shop_name }}, Your shop has been Registerd with the id :
          <b>{{ display_shop_id }}</b>.<br />
          Please use this phone number and shop id to login.
        </p>
        <button
          (click)="refreshPage(); modal.dismiss('Cross click')"
          type="button"
          class="btn btn-primary mt-3 addbutton1"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</ng-template>
