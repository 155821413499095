<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:10vh;">

<div class="container-fluid">
  <div class="row">
    <div class="top_nav_list">
      <ul class="top-nav">
        <li
          class="nava"
          (click)="setCurrentItem('item1')"
          [ngClass]="{
            active: currentItem === 'item1'
          }"
        >
        Customer Performance
        <li
          class="nava"
          (click)="setCurrentItem('item2')"
          [ngClass]="{
            active: currentItem === 'item2'
          }"
        >
        App Performance
        </li>
        <li
          class="nava"
          (click)="setCurrentItem('item3')"
          [ngClass]="{
            active: currentItem === 'item3'
          }"
        >
        Funnel Analysis
        </li>
        <li
          class="nava"
          (click)="setCurrentItem('item4')"
          [ngClass]="{
            active: currentItem === 'item4'
          }"
        >
        Lifetime Performance
        </li>
        <li
          class="nava"
          (click)="setCurrentItem('item5')"
          [ngClass]="{
            active: currentItem === 'item5'
          }"
        >
        Digital Gold
        </li>
      </ul>
    </div>
  </div>
  <!-- <h3 class="headi">User Perfomance</h3> -->

  <div class="item-5" [style.display]="currentItem == 'item5' ? 'flex' : 'none'" style="padding-inline: 10px ">
    <div class="balance-details" [style.display]="viewAllBool ? 'none': 'flex'">
      <div class="title">Vault Holdings</div>
      <div class="card" *ngFor="let data of userBalance">
        <div class="header">
          <div class="commodity">{{data.commodity}}</div>
          <div class="amount">{{data.weight | number : "1.3-3"}} <span>gms</span></div>
        </div>
        <div class="d-flex" style="justify-content: space-between;">
          <div class="footer">
            <div class="key-value-pair" *ngFor="let data2 of data.data">
              <div class="key">{{data2.category}}</div>
              <div class="value">{{data2.weight | number : "1.3-3"}} <span>({{data2.order_count}})</span></div>
            </div>
          </div>
          <div class="footer">
            <div class="key-value-pair" *ngFor="let data2 of data.data">
              <ng-container *ngIf="data2.category === 'BUY'">
                <div class="key">Amount</div>
                <div class="value">{{data2.total_amount | number : "1.2-2"}}</div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg_" [style.display]="viewAllBool ? 'none': 'block'">
      <div

      style="display: flex; justify-content: space-between"
    >
      <h3 class="title" style="padding-inline-start: 20px; padding-top: 15px;margin-bottom: 15px;">Overall Stats</h3>
    </div>
    <div class="row mb-4 resposive_forth">
      <div class="col-md-7 col_forth_first">
        <div class="fle">
          <div class="total_installment_paid">
            Total Transaction amount
            <!-- <span class="ms-1">
            <i
              matTooltipClass="tooltip-custom"
              mat-button
              matTooltip="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod "
              matTooltipPosition="above"
            >
              <img src="assets/images/Info.svg" />
            </i>
          </span> -->
            <br />
            <span class="total_installment_paid_value">₹ {{
              OverallStats?.total_digital_coin_order_payments | indianNumeration
            }}</span>
          </div>
          <div class="total_installment_paid me-3">
            Total Orders
            <br />
            <span class="total_installment_paid_value">{{
              OverallStats?.all_digital_coin_order | indianNumeration
            }}</span>
          </div>
        </div>
        <div
          class="mt-4"
          style="display: flex; justify-content: space-between"
        >
          <div class="online_text">
            Online
            <br />
            <span class="online_text_value"
              >₹
              {{
                OverallStats?.online_digital_coin_order_payments | indianNumeration
              }}</span
            >
          </div>

          <div class="online_text border_left" style="width: auto;">
            Cash
            <br />
            <span class="online_text_value"
              >₹
              {{
                OverallStats?.cash_digital_coin_order_payments | indianNumeration
              }}</span
            >
          </div>
          <div class="online_text border_left">
            Card
            <br />
            <span class="online_text_value"
              >₹
              {{
                OverallStats?.card_digital_coin_order_payments | indianNumeration
              }}</span
            >
          </div>
        </div>
        <div
          class="mt-4"
          style="display: flex; justify-content: space-between"
        >
          <div class="online_text">
            UPI
            <br />
            <span class="online_text_value"
              >₹
              {{
                OverallStats?.upi_digital_coin_order_payments | indianNumeration
              }}</span
            >
          </div>
          <div class="online_text border_left me-5" style="width: auto;margin-left: 10px;">
            Net Banking
            <br />
            <span class="online_text_value"
              >₹
              {{
                OverallStats?.netbanking_digital_coin_order_payments
                  | indianNumeration
              }}</span
            >
          </div>
          <div class="online_text border_left">
            Cheque
            <br />
            <span class="online_text_value"
              >₹
              {{
                OverallStats?.cheque_digital_coin_order_payments
                  | indianNumeration
              }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-4 col_forth_second" style="margin-left: 1%;">
        <div class="row" >
          <div class="pt-3 px-3 doughnut_top">
            <div class="all_plans" style="font-family: Rubik;
            font-size: 15px;
            font-weight: 500;
            ">Commodity Distribution</div>
            <div class="average_ticket">
              
            </div>
          </div>

          <div class="col-md-8">
            <canvas id="myChartDoughnutDigi" height="300px !important">
              {{ chartDoughnutD }}
            </canvas>
          </div>
          <div class="average_ticket doughnut_bottom">
            <!-- Average Ticket Size: ₹ {{ life_time_performance.average_ticket_price | indianNumeration}} -->
          </div>
          <div class="col-md-3 remove_doughtnut" style="align-self: center;padding:0">
            <div style="display: flex; align-items: center">
              <div class="vertical_line"></div>
              <div class="plans_state">
                Gold 24k - {{userBalance?.[0]?.data?.[0]?.order_count}} orders
                <br />
                <span class="plans_data">
                  ₹{{
                    OverallStats?.gold_24_total_amount
                      | indianNumeration
                  }}
                </span>
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <div class="vertical_line" style="background: #082d92"></div>
              <div class="plans_state">
                Gold 22k - {{userBalance?.[1]?.data?.[0]?.order_count}} orders
                <br />
                <span class="plans_data">
                  ₹{{
                    OverallStats?.gold_22_total_amount
                      | indianNumeration
                  }}
                </span>
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <div class="vertical_line" style="background: #3268fb"></div>
              <div class="plans_state">
                Silver - {{userBalance?.[2]?.data?.[0]?.order_count}} orders
                <br />
                <span class="plans_data">
                  ₹{{
                    OverallStats?.silver_total_amount
                      | indianNumeration
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="doughtnut_details">
            <div
              style="
                display: flex;
                justify-content: space-between;
                padding: 0%5%0%5%;
              "
            >
              <div style="display: flex; align-items: center">
                <div class="vertical_line"></div>
                <div class="plans_state">
                  {{ life_time_performance.completed_count }} Plans Completed
                  <br />
                  <span class="plans_data">  ₹{{
                    life_time_performance.completed_subscription
                      | indianNumeration
                  }} </span>
                </div>
              </div>
              <div style="display: flex; align-items: center">
                <div class="vertical_line"></div>
                <div class="plans_state">
                  {{ life_time_performance.in_progress_count }} Plans in Progress
                  <br />
                  <span class="plans_data"> ₹{{
                    life_time_performance.in_progress_subscription
                      | indianNumeration
                  }} </span>
                </div>
              </div>
            </div>
            <div
              class="doughnut_details"
              style="
                display: flex;
                justify-content: space-between;
                padding: 2%5%3%5%;
              "
            >
              <div style="display: flex; align-items: center">
                <div class="vertical_line"></div>
                <div class="plans_state">
                  {{ life_time_performance.cancelled_count }} Plan Cancelled
                  <br />
                  <span class="plans_data">   ₹{{
                    life_time_performance.cancelled_subscription
                      | indianNumeration
                  }}</span>
                </div>
              </div>
              <div style="display: flex; align-items: center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="section">
      <div class="title">User Wallet Balance <span (click)="viewAllClicked()">{{viewAllBool ? 'View Less' : 'View All'}}</span></div>
      <div *ngIf="userDataState==='LOADING'; else showData" class="spinner-container">

        <mat-progress-spinner  diameter="50" mode="indeterminate"></mat-progress-spinner>
    </div>
      <ng-template #showData>
        <table mat-table [dataSource]="viewAllBool? digiTable?.results??[] : digiTable?.results!.slice(0,viewAll)">
          <ng-container matColumnDef="Customer Name">
              <th class="custom-mat-th" mat-header-cell *matHeaderCellDef> Customer Name </th>
              <td class="custom-mat-td {{+row.index % 2 ? '' : 'odd'}}"  mat-cell *matCellDef="let row">
                  {{row.first_name}} {{row.last_name}} </td>
          </ng-container>
          <ng-container matColumnDef="Phone No">
              <th class="custom-mat-th" mat-header-cell *matHeaderCellDef> Phone No </th>
              <td class="custom-mat-td {{+row.index % 2 ? '' : 'odd'}}" style="color: #3268FB;" mat-cell *matCellDef="let row">
                  {{row.phone}} </td>
          </ng-container>
          <ng-container matColumnDef="Gold 24k">
              <th class="custom-mat-th" mat-header-cell *matHeaderCellDef> Gold 24k </th>
              <td class="custom-mat-td {{+row.index % 2 ? '' : 'odd'}}" mat-cell *matCellDef="let row">
                  {{row.gold24_vault_holding | number : "1.3-3"}} </td>
          </ng-container>
          <ng-container matColumnDef="Gold 22k">
              <th class="custom-mat-th" mat-header-cell *matHeaderCellDef> Gold 22k </th>
              <td class="custom-mat-td {{+row.index % 2 ? '' : 'odd'}}" mat-cell *matCellDef="let row">
                  {{row.gold22_vault_holding | number : "1.3-3"}} </td>
          </ng-container>
          <ng-container matColumnDef="Silver">
              <th class="custom-mat-th" mat-header-cell *matHeaderCellDef> Silver </th>
              <td class="custom-mat-td {{+row.index % 2 ? '' : 'odd'}}" mat-cell *matCellDef="let row">
                  {{row.silver_vault_holding | number : "1.3-3"}} </td>
          </ng-container>
          <ng-container matColumnDef="Total Investment">
              <th class="custom-mat-th" mat-header-cell *matHeaderCellDef> Total Investment </th>
              <td class="custom-mat-td {{+row.index % 2 ? '' : 'odd'}}" mat-cell *matCellDef="let row">
                  {{row.total_investment}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsDigi"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsDigi;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
              <td class="custom-mat-td" class="mat-cell" colspan="7">No data</td>
          </tr>
      </table>

    </ng-template>
    <mat-paginator *ngIf="viewAllBool" class="pagination" (page)="onPaginatorPageChange($event)" [length]="digiTable?.total_results" [pageSize]="10" showFirstLastButtons>
    </mat-paginator>
  </div>
  </div>

  <div
    class="row customer_performance"
    id="user_performance"
  >
  <div class="user_performance_">
    <div class="first_two_col">
      <div style="display: flex; justify-content: space-between">
        <div>
          <div>
            <img src="assets/images/ava.svg" width="31" height="31" />
          </div>
          <div class="mt-2 current_active">
            Current Active Users

            <span class="ms-1">
              <i
              #tooltip="matTooltip"
              (click)="tooltip.toggle()"
              matTooltipTouch="true"

                matTooltipClass="tooltip-custom"
                mat-button
                matTooltip="Users who have app installed and engaged atleast once in last 90 days"
                matTooltipPosition="above"
              >
                <img src="assets/images/Info.svg" />
              </i>
            </span>
          </div>
          <div *ngIf="!active_user_loading">
            <mat-spinner
            style="margin: 5px 4px"
            mode="indeterminate"
            [diameter]="25"
          ></mat-spinner>
          </div>
          <div *ngIf="active_user_loading"   class="mt-1 current_active_val">

            {{ analytic_data.active_users ? analytic_data.active_users : 0 }}
          </div>
        </div>
        <div class="mt-4 graph_img">
          <!-- <canvas class="" width="269" height="80" #myCanvas id="myChart">
                    {{ chart }}
                  </canvas> -->
          <img src="assets/images/graph.svg" />
        </div>
      </div>
    </div>
    <div class="first_two_col">
      <div style="display: flex; justify-content: space-between">
        <div>
          <div>
            <img src="assets/images/ava.svg" width="31" height="31" />
          </div>
          <div class="mt-2 current_active">
            Current Live Users
            <span class="ms-1">
              <i
              #tooltip1="matTooltip"
              (click)="tooltip1.toggle()"
                matTooltipClass="tooltip-custom"
                mat-button
                matTooltip="Users who are currently using  your app or website in real time"
                matTooltipPosition="above"
              >
                <img src="assets/images/Info.svg" />
              </i>
            </span>
          </div>
          <div *ngIf="!active_user_loading">
            <mat-spinner
            style="margin: 5px 4px"
            mode="indeterminate"
            [diameter]="25"
          ></mat-spinner>
          </div>
          <div *ngIf="active_user_loading" class="mt-1 current_active_val">
            {{ analytic_data.live_users! ? analytic_data.live_users! : 0 }}
          </div>
        </div>
        <div class="mt-4 graph_img">
          <!-- <canvas class="" width="269" height="80" #myCanvas id="myNewChart">
                        {{ chartnew  }}
                      </canvas> -->
          <img src="assets/images/graph.svg" />
        </div>
      </div>
    </div>
  </div>


    <div>
      <div class="graph_div multi_axis mt-4">
        <div class="first_row_gd">
          <div>
            All Activations
          </div>
          <div class="dropdown_graph">
            <select class="form-select fsn" [(ngModel)]="selected_month" (change)="getDaysInMonth(selected_month);multiAxisGraph()">
              <option disabled>Select Month</option>
              <option  value=1>Janaury</option>
              <option value=2>February</option>
              <option value=3>March</option>
              <option value=4>April</option>
              <option value=5>May</option>
              <option value=6>June</option>
              <option value=7>July</option>
              <option value=8>August</option>
              <option value=9>September</option>
              <option value=10>October</option>
              <option value=11>November</option>
              <option value=12>December</option>
            </select>
          </div>
  
        </div>
  
        <canvas id="multi_axis_graph" [height]="100">
          {{ multi_axis_chart }}
        </canvas>
      </div>
    </div>
  </div>
  <div id="app_performance">
    <div class="row">
      <div class="col-md-12 text-center">
        <mat-spinner
          *ngIf="app_performance_loading"
          style="margin: 12px auto"
          mode="indeterminate"
          [diameter]="50"
        ></mat-spinner>
      </div>
    </div>
    <div class="date_adjust_2">
      <div class="headi"></div>
      <div class="mt-1 date_res date_adjust">
        <div class="me-3 date1">
          <label class="date_label">Start Date</label>
          <input
            type="date"
            class="form-control"
            id="app_per_sdate"
            [placeholder]="myPlaceholder"
            [(ngModel)]="app_per_sdate"
            (input)="removeEndDate()"
            [max]="getCurrentDate()"
          />
        </div>

        <div class="me-2 date2">
          <!-- <label class="date_label2">Click to Use Today's date</label> -->
          <label class="date_label">End Date </label>
          <input
            type="date"
            id="app_per_edate"
            class="form-control"
            id="dob"
            [value]="app_per_edate"
            [(ngModel)]="app_per_edate"
            (change)="getAppDates()"
            [min]="app_per_sdate"
            [max]="getCurrentDate()"
          />
        </div>
      </div>
    </div>
    <div class="row ms-4 app_perm">
      <div class="second_two_col">
        <div class="ms-2">
          <div class="mt-1">
            <img src="assets/images/android.svg" width="31" height="31" />
          </div>
          <div class="new_install">
            New Installs
            <span class="ms-1">
              <i
              #tooltip2="matTooltip"
              (click)="tooltip2.toggle()"
                matTooltipClass="tooltip-custom"
                mat-button
                matTooltip="Users who have installed your app"
                matTooltipPosition="above"
              >
                <img src="assets/images/Info.svg" />
              </i>
            </span>
          </div>
          <div *ngIf="!active_user_loading">
            <mat-spinner
            style="margin: 10px 4px"
            mode="indeterminate"
            [diameter]="25"
          ></mat-spinner>
          </div>
          <div  *ngIf="active_user_loading" class="new_install_data">
            {{
              analytic_data.android_downloads!
                ? analytic_data.android_downloads!
                : 0
            }}
          </div>
        </div>
        <div>
          <span><img src="assets/images/Up-arrow.svg" /></span>
          <span class="last_week">from last week</span>
        </div>

        <div>
          <div class="ms-2">
            <div class="new_install">
              Uninstalls
              <span class="ms-1">
                <i
                #tooltip3="matTooltip"
              (click)="tooltip3.toggle()"
                  matTooltipClass="tooltip-custom"
                  mat-button
                  matTooltip="Users who have uninstalled your app"
                  matTooltipPosition="above"
                >
                  <img src="assets/images/Info.svg" />
                </i>
              </span>
            </div>
            <div *ngIf="!active_user_loading">
              <mat-spinner
              style="margin: 10px 4px"
              mode="indeterminate"
              [diameter]="25"
            ></mat-spinner>
            </div>
            <div  *ngIf="active_user_loading" class="new_install_data">
              {{
                analytic_data.android_uninstalls!
                  ? analytic_data.android_uninstalls!
                  : 0
              }}
            </div>
          </div>
          <span><img src="assets/images/Up-arrow.svg" /></span>
          <span class="last_week">from last week</span>
        </div>
      </div>
      <div class="res"></div>
      <div class="second_two_col ms-3">
        <div class="ms-2">
          <div class="mt-1">
            <img src="assets/images/ios.svg" width="31" height="31" />
          </div>
          <div class="new_install">
            New Installs
            <span class="ms-1">
              <i
              #tooltip4="matTooltip"
              (click)="tooltip4.toggle()"
                matTooltipClass="tooltip-custom"
                mat-button
                matTooltip="Users who have installed your app"
                matTooltipPosition="above"
              >
                <img src="assets/images/Info.svg" />
              </i>
            </span>
          </div>
          <div *ngIf="!active_user_loading">
            <mat-spinner
            style="margin: 10px 4px"
            mode="indeterminate"
            [diameter]="25"
          ></mat-spinner>
          </div>
          <div *ngIf="active_user_loading" class="new_install_data">
            {{
              analytic_data.ios_downloads! ? analytic_data.ios_downloads! : 0
            }}
          </div>
        </div>
        <div>
          <span><img src="assets/images/Up-arrow.svg" /></span>
          <span class="last_week">from last week</span>
        </div>

        <div>
          <div class="ms-2">
            <div class="new_install">
              Uninstalls
              <span class="ms-1">
                <i
                #tooltip5="matTooltip"
              (click)="tooltip5.toggle()"
                  matTooltipClass="tooltip-custom"
                  mat-button
                  matTooltip="Users who have uninstalled your app"
                  matTooltipPosition="above"
                >
                  <img src="assets/images/Info.svg" />
                </i>
              </span>
            </div>
            <div *ngIf="!active_user_loading">
              <mat-spinner
              style="margin: 10px 4px"
              mode="indeterminate"
              [diameter]="25"
            ></mat-spinner>
            </div>
            <div *ngIf="active_user_loading" class="new_install_data">
              {{
                analytic_data.ios_uninstalls!
                  ? analytic_data.ios_uninstalls!
                  : 0
              }}
            </div>
          </div>
          <span><img src="assets/images/Up-arrow.svg" /></span>
          <span class="last_week">from last week</span>
        </div>
      </div>
      <div class="res"></div>
      <div class="second_third_col">
        <div class="mt-2" style="display: flex; justify-content: space-between">
          <div class="app_download_source">
            App Download Source
            <span class="ms-1">
              <i
              #tooltip6="matTooltip"
              (click)="tooltip6.toggle()"
                matTooltipClass="tooltip-custom"
                mat-button
                matTooltip="Source of the channel from which the users have installed the app"
                matTooltipPosition="above"
              >
                <img src="assets/images/Info.svg" />
              </i>
            </span>
          </div>
          <div class="get_source">Get Source Links</div>
        </div>
        <canvas #chartCanvas id="chartCanvas" height="145px">
          {{ chart_ }}
        </canvas>
      </div>
    </div>
  </div>
  <div></div>
  <div id="savings_scheme">
    <div class="row">
      <div class="col-md-12 text-center">
        <mat-spinner
          *ngIf="saving_scheme_loading"
          style="margin: 12px auto"
          mode="indeterminate"
          [diameter]="50"
        ></mat-spinner>
      </div>
    </div>
    <div class="mt-2 mb-2 date_adjust_2">
      <h3 class="headi mt-4">Jewellery Plans Funnel
        <span class="ms-1">
          <i
            matTooltipClass="tooltip-custom"
            mat-button
            matTooltip="With a SIP funnel, you can track the progression of pages where your users exit your website or app"
            matTooltipPosition="above"
          >
            <img src="assets/images/Info.svg" />
          </i>
        </span>

      </h3>
      <div class="mt-1 date_adjust">
        <div class="me-3 date1">
          <label class="date_label">Start Date</label>
          <input
            type="date"
            class="form-control"
            id="dob"
            placeholder="--/--/----"
            [(ngModel)]="funnel_sdate"
            [max]="getCurrentDate()"
            (input)="removeEndDateFunnel()"
          />
        </div>

        <div class="me-2 date2">
          <label class="date_label">End Date</label>
          <input
            type="date"
            class="form-control"
            id="dob"
            [max]="getCurrentDate()"
            [min]="funnel_sdate"
            [(ngModel)]="funnel_edate"
            (change)="getFunnelDates()"
          />
        </div>
      </div>
    </div>
    <div class="dropdown_custom_title">
      Sip Subscriptions Funnel <span> </span>
    </div>
    <div class="dropdown_custom">
      <div>
        <div (click)="dropdownHandler('dropdown_child')" class="icon">
          <img src="assets/images/dropdownicon.svg" />
        </div>
        <span class="dropdown_title"> Total Plans </span>
        <br />
        <span class="dropdown_value">
          {{ dropdown_data.total_plans }}
        </span>
      </div>
      <div>
        <span class="dropdown_title"> Total Amount </span>
        <br />
        <span class="dropdown_value">
          ₹ {{ total_amount_of_funnel | indianNumeration }}
        </span>
      </div>
    </div>

    <div class="dropdown_child" id="dropdown_child">
      <img src="assets/images/app_dropdown.svg" />

      <div class="mt-2 app_drop">App</div>

      <span class="app_drop_title">New Customer Subscriptions</span>
      <div class="mt-1" style="display: flex; justify-content: space-between">
        <div class="app_drop_left">
          {{ dropdown_data.app_new_customer }}
        </div>
        <div class="app_drop_right">
          ₹ {{ dropdown_data.app_new_customer_amount | indianNumeration }}
        </div>
      </div>
      <div class="res"></div>
      <span class="app_drop_title">Repeat Customer Subscriptions</span>
      <div class="mt-1" style="display: flex; justify-content: space-between">
        <div class="app_drop_left">
          {{ dropdown_data.app_repeat_customer }}
        </div>
        <div class="app_drop_right">
          ₹ {{ dropdown_data.app_repeat_customer_amount | indianNumeration }}
        </div>
      </div>
      <hr />
      <div>
        <img src="assets/images/dropdown_store.svg" />

        <div class="mt-2 app_drop">Store</div>

        <span class="app_drop_title">New Customer Subscriptions</span>
        <div class="mt-1" style="display: flex; justify-content: space-between">
          <div class="app_drop_left">
            {{ dropdown_data.store_new_customer }}
          </div>
          <div class="app_drop_right">
            ₹ {{ dropdown_data.store_new_customer_amount | indianNumeration }}
          </div>
        </div>
        <div class="res"></div>
        <span class="app_drop_title">Repeat Customer Subscriptions</span>
        <div class="mt-1" style="display: flex; justify-content: space-between">
          <div class="app_drop_left">
            {{ dropdown_data.store_repeat_customer }}
          </div>
          <div class="app_drop_right">
            ₹
            {{ dropdown_data.store_repeat_customer_amount | indianNumeration }}
          </div>
        </div>
      </div>
    </div>

    <div class="row ms-4 waverow">
      <div class="dropdown_modal">
        <div class="fle mar">
          <div class="saving_subs_title">
<!--
            <span class="ms-1">
              <i
                matTooltipClass="tooltip-custom"
                mat-button
                matTooltip="With a SIP funnel, you can track the progression of pages where your users exit your website or app"
                matTooltipPosition="above"
              >
                <img src="assets/images/Info.svg" />
              </i>
            </span> -->
          </div>
          <div style="display: flex; justify-content: space-between">
            <div class="total_plans_amount me-3">
              Total Plan
              <span class="total_plan_value">
                {{ dropdown_data.total_plans | indianNumeration }}</span
              >
            </div>
            <div class="total_plans_amount pb-2">
              Total Amount
              <span mat-button class="total_plan_value"
                >₹ {{ total_amount_of_funnel | indianNumeration }}

                <mat-icon [matMenuTriggerFor]="funnel" class="centerClass mb-1"
                  >keyboard_arrow_down</mat-icon
                >
              </span>
            </div>
          </div>
        </div>
      </div>
      <mat-menu #funnel="matMenu" class="profile">
        <div class="sip_subscribed">
          <div class="title_sip_subs">SIP Subcribed</div>
          <div class="row mt-3">
            <div class="col-md-6">
              <div class="app_title mb-3">App</div>
              <div class="fle details_sub">
                <div class="widthcommon">New Customer Subscriptions</div>
                <div class="widthcommon1">Repeat Customer Subscriptions</div>
              </div>
              <div class="fle">
                <div class="close_space2">
                  <div class="count_cust">
                    {{ dropdown_data.app_new_customer }}
                  </div>
                  <span class="count_rupee">
                    ₹
                    {{
                      dropdown_data.app_new_customer_amount | indianNumeration
                    }}</span
                  >
                </div>
                <div class="close_space">
                  <div class="count_cust">
                    {{ dropdown_data.app_repeat_customer }}
                  </div>
                  <span class="count_rupee">
                    ₹
                    {{
                      dropdown_data.app_repeat_customer_amount
                        | indianNumeration
                    }}</span
                  >
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="vertical-line_subs me-4"></div>
              <div class="app_title mb-3">Store</div>
              <div class="fle details_sub">
                <div class="widthcommon">New Customer Subscriptions</div>
                <div class="widthcommon1">Repeat Customer Subscriptions</div>
              </div>
              <div class="fle">
                <div class="close_space2">
                  <div class="count_cust">
                    {{ dropdown_data.store_new_customer }}
                  </div>
                  <span class="count_rupee">
                    ₹
                    {{
                      dropdown_data.store_new_customer_amount | indianNumeration
                    }}</span
                  >
                </div>
                <div class="close_space">
                  <div class="count_cust">
                    {{ dropdown_data.store_repeat_customer }}
                  </div>
                  <span class="count_rupee">
                    ₹
                    {{
                      dropdown_data.store_repeat_customer_amount
                        | indianNumeration
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-menu>
      <div *ngIf="this.funnel_data" class="flex-row">
        <div class="col-md-3 wavecol">
          <div class="fle wave_graph_top_data">
            <div>Home</div>
            <div>100%</div>
          </div>
          <canvas height="300" id="waveCanvas_1">
            {{ wave_chart_1 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="funnel_data.length>1" class="fle">
              <div  class="Abandonment_left">
                {{ this.funnel_data[0].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.funnel_data[0].abandonment_rate * 100 | number : "1.2-2"
                }}%
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 wavecol">
          <div class="fle wave_graph_top_data">
            <div>Savings</div>
            <div *ngIf="funnel_data.length>1">
              {{
                (funnel_data[1].users / funnel_data[0].users) * 100
                  | number : "1.2-2"
              }}%
            </div>
          </div>
          <canvas height="300" id="waveCanvas_2">
            {{ wave_chart_2 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="funnel_data.length>1" class="fle">
              <div class="Abandonment_left">
                {{ this.funnel_data[1].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.funnel_data[1].abandonment_rate * 100 | number : "1.2-2"
                }}%
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 wavecol">
          <div  class="fle wave_graph_top_data">
            <div>Toc</div>
            <div *ngIf="funnel_data.length>1">
              {{
                (funnel_data[2].users / funnel_data[0].users) * 100
                  | number : "1.2-2"
              }}%
            </div>
          </div>
          <canvas height="300" id="waveCanvas_3">
            {{ wave_chart_3 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="funnel_data.length>1" class="fle">
              <div class="Abandonment_left">
                {{ this.funnel_data[2].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.funnel_data[2].abandonment_rate * 100 | number : "1.2-2"
                }}%
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 wavecol">
          <div  class="fle wave_graph_top_data">
            <div>Success</div>
            <div *ngIf="funnel_data.length>1">
              {{
                (funnel_data[3].users / funnel_data[0].users) * 100
                  | number : "1.2-2"
              }}%
            </div>
          </div>
          <canvas height="300" id="waveCanvas_4">
            {{ wave_chart_4 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="funnel_data.length>1" class="fle">
              <div class="Abandonment_left">
                {{ this.funnel_data[3].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.funnel_data[3].abandonment_rate * 100 | number : "1.2-2"
                }}%
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="mat-elevation mt-3 flex-row">
        <!-- Table -->
        <table *ngIf="dataSource" mat-table [dataSource]="dataSource">
          <!-- Column definitions -->
          <ng-container matColumnDef="step" sticky>
            <th mat-header-cell *matHeaderCellDef>Step</th>
            <td mat-cell *matCellDef="let row">{{ row.step.slice(2) }}</td>
          </ng-container>

          <ng-container matColumnDef="users">
            <th mat-header-cell *matHeaderCellDef>Users(% of step 1)</th>
            <td mat-cell *matCellDef="let row">{{ row.users }}</td>
          </ng-container>

          <ng-container matColumnDef="completion">
            <th mat-header-cell *matHeaderCellDef>Completion Rate</th>
            <td mat-cell *matCellDef="let row">
              {{ row.completion_rate * 100 | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="abandonments">
            <th mat-header-cell *matHeaderCellDef>Abandonments</th>
            <td

              style="color: #3268fb !important; cursor: pointer"
              (click)="listCustomer(download_file, i,0)"
              mat-cell
              *matCellDef="let row; let i = index"
            >
              {{ row.abandonment }}
            </td>
          </ng-container>

          <ng-container matColumnDef="abandonment_rate">
            <th mat-header-cell *matHeaderCellDef>Abandonment rate</th>
            <td mat-cell *matCellDef="let row">
              {{ row.abandonment_rate * 100 | number : "1.2-2" }}
            </td>
          </ng-container>

          <!-- Table rows -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <div *ngIf="false">
    <div class="mt-2 mb-2 date_adjust_2" >
      <h3 class="headi mt-4">Advance Bookings Funnel
        <span class="ms-1">
          <i
            matTooltipClass="tooltip-custom"
            mat-button
            matTooltip="With a Advance Booking funnel, you can track the progression of pages where your users exit your website or app"
            matTooltipPosition="above"
          >
            <img src="assets/images/Info.svg" />
          </i>
        </span>

      </h3>
      <div class="mt-1 date_adjust">
        <div class="me-3 date1">
          <label class="date_label">Start Date</label>
          <input
            type="date"
            class="form-control"
            id="dob"
            placeholder="--/--/----"
            [(ngModel)]="funnel_sdate_booking"
            [max]="getCurrentDate()"
            (input)="removeEndDateFunnelBooking()"
          />
        </div>

        <div class="me-2 date2">
          <label class="date_label">End Date</label>
          <input
            type="date"
            class="form-control"
            id="dob"
            [max]="getCurrentDate()"
            [min]="funnel_sdate_booking"
            [(ngModel)]="funnel_edate_booking"
            (change)="getFunnelDatesBooking()"
          />
        </div>
      </div>
    </div>
    <div class="dropdown_custom_title">
      Advance Bookings Funnel <span> </span>
    </div>
    <div class="dropdown_custom">
      <div>
        <div (click)="dropdownHandler('dropdown_child_booking')" class="icon">
          <img src="assets/images/dropdownicon.svg" />
        </div>
        <span class="dropdown_title"> Total Bookings </span>
        <br />
        <span class="dropdown_value">
          {{ dropdown_data.total_bookings }}
        </span>
      </div>
      <div>
        <span class="dropdown_title"> Total Amount </span>
        <br />
        <span class="dropdown_value">
          ₹ {{ total_amount_booking_funnel | indianNumeration }}
        </span>
      </div>
    </div>
    <div class="dropdown_child" id="dropdown_child_booking">
      <img src="assets/images/app_dropdown.svg" />

      <div class="mt-2 app_drop">App</div>

      <span class="app_drop_title">New Bookings</span>
      <div class="mt-1" style="display: flex; justify-content: space-between">
        <div class="app_drop_left">
          {{ dropdown_data.app_new_booking }}
        </div>
        <div class="app_drop_right">
          ₹ {{ dropdown_data.app_new_booking_amount | indianNumeration }}
        </div>
      </div>
      <div class="res"></div>
      <span class="app_drop_title">Repeat Customer Bookings</span>
      <div class="mt-1" style="display: flex; justify-content: space-between">
        <div class="app_drop_left">
          {{ dropdown_data.app_repeat_booking }}
        </div>
        <div class="app_drop_right">
          ₹ {{ dropdown_data.app_repeat_booking_amount | indianNumeration }}
        </div>
      </div>
      <hr />
      <div>
        <img src="assets/images/dropdown_store.svg" />

        <div class="mt-2 app_drop">Store</div>

        <span class="app_drop_title">New Bookings</span>
        <div class="mt-1" style="display: flex; justify-content: space-between">
          <div class="app_drop_left">
            {{ dropdown_data.store_new_booking }}
          </div>
          <div class="app_drop_right">
            ₹ {{ dropdown_data.store_new_booking_amount | indianNumeration }}
          </div>
        </div>
        <div class="res"></div>
        <span class="app_drop_title">Repeat Customer Bookings</span>
        <div class="mt-1" style="display: flex; justify-content: space-between">
          <div class="app_drop_left">
            {{ dropdown_data.store_repeat_booking }}
          </div>
          <div class="app_drop_right">
            ₹
            {{ dropdown_data.store_repeat_booking_amount | indianNumeration }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2 ms-4 waverow mb-5">
      <div class="dropdown_modal">
        <div class="fle mar">
          <div class="saving_subs_title">
           <!-- Advance Bookings Funnel -->
            <!-- <span class="ms-1">
              <i
                matTooltipClass="tooltip-custom"
                mat-button
                matTooltip="With a SIP funnel, you can track the progression of pages where your users exit your website or app"
                matTooltipPosition="above"
              >
                <img src="assets/images/Info.svg" />
              </i>
            </span> -->
          </div>
          <div style="display: flex; justify-content: space-between">
            <div class="total_plans_amount me-3">
              Total Bookings
              <span class="total_plan_value">
                {{ dropdown_data.total_bookings | indianNumeration }}</span
              >
            </div>
            <div class="total_plans_amount pb-2">
              Total Amount
              <span mat-button class="total_plan_value"
                >₹ {{ total_amount_booking_funnel | indianNumeration }}

                <mat-icon [matMenuTriggerFor]="funnel2" class="centerClass mb-1"
                  >keyboard_arrow_down</mat-icon
                >
              </span>
            </div>
          </div>
          <mat-menu #funnel2="matMenu" class="profile">
            <div class="sip_subscribed">
              <div class="title_sip_subs">Advance Bookings</div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="app_title mb-3">App</div>
                  <div class="fle details_sub">
                    <div class="widthcommon">New Bookings</div>
                    <div class="widthcommon1">Repeat Customer Bookings</div>
                  </div>
                  <div class="fle">
                    <div class="close_space2">
                      <div class="count_cust">
                        {{ dropdown_data.app_new_booking }}
                      </div>
                      <span class="count_rupee">
                        ₹
                        {{
                          dropdown_data.app_new_booking_amount | indianNumeration
                        }}</span
                      >
                    </div>
                    <div class="close_space">
                      <div class="count_cust">
                        {{ dropdown_data.app_repeat_booking }}
                      </div>
                      <span class="count_rupee">
                        ₹
                        {{
                          dropdown_data.app_repeat_booking_amount
                            | indianNumeration
                        }}</span
                      >
                    </div>
                  </div>
                </div>
    
                <div class="col-md-6">
                  <div class="vertical-line_subs me-4"></div>
                  <div class="app_title mb-3">Store</div>
                  <div class="fle details_sub">
                    <div class="widthcommon">New Bookings</div>
                    <div class="widthcommon1">Repeat Customer Bookings</div>
                  </div>
                  <div class="fle">
                    <div class="close_space2">
                      <div class="count_cust">
                        {{ dropdown_data.store_new_booking }}
                      </div>
                      <span class="count_rupee">
                        ₹
                        {{
                          dropdown_data.store_new_booking_amount | indianNumeration
                        }}</span
                      >
                    </div>
                    <div class="close_space">
                      <div class="count_cust">
                        {{ dropdown_data.store_repeat_booking }}
                      </div>
                      <span class="count_rupee">
                        ₹
                        {{
                          dropdown_data.store_repeat_booking_amount
                            | indianNumeration
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>
      <mat-menu #funnel="matMenu" class="profile">
        <div class="sip_subscribed">
          <div class="title_sip_subs">Advance Bookings</div>
          <div class="row mt-3">
            <div class="col-md-6">
              <div class="app_title mb-3">App</div>
              <div class="fle details_sub">
                <div class="widthcommon">New Bookings</div>
                <div class="widthcommon1">Repeat Customer Bookings</div>
              </div>
              <div class="fle">
                <div class="close_space2">
                  <div class="count_cust">
                    {{ dropdown_data.app_new_booking }}
                  </div>
                  <span class="count_rupee">
                    ₹
                    {{
                      dropdown_data.app_new_booking_amount | indianNumeration
                    }}</span
                  >
                </div>
                <div class="close_space">
                  <div class="count_cust">
                    {{ dropdown_data.app_repeat_booking }}
                  </div>
                  <span class="count_rupee">
                    ₹
                    {{
                      dropdown_data.app_repeat_booking_amount
                        | indianNumeration
                    }}</span
                  >
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="vertical-line_subs me-4"></div>
              <div class="app_title mb-3">Store</div>
              <div class="fle details_sub">
                <div class="widthcommon">New Bookings</div>
                <div class="widthcommon1">Repeat Customer Bookings</div>
              </div>
              <div class="fle">
                <div class="close_space2">
                  <div class="count_cust">
                    {{ dropdown_data.store_new_booking }}
                  </div>
                  <span class="count_rupee">
                    ₹
                    {{
                      dropdown_data.store_new_booking_amount | indianNumeration
                    }}</span
                  >
                </div>
                <div class="close_space">
                  <div class="count_cust">
                    {{ dropdown_data.store_repeat_booking }}
                  </div>
                  <span class="count_rupee">
                    ₹
                    {{
                      dropdown_data.store_repeat_booking_amount
                        | indianNumeration
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-menu>
      <div *ngIf="funnel_data" class="flex-row">
        <div class="col-md-4 wavecol">
          <div class="fle wave_graph_top_data">
            <div>Home</div>
            <div>100%</div>
          </div>
          <canvas height="300" id="waveCanvas_7">
            {{ wave_chart_7 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="dataSourceBooking && dataSourceBooking.length>1" class="fle">
              <div  class="Abandonment_left">
                {{ this.dataSourceBooking[0].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.dataSourceBooking[0].abandonment_rate * 100 | number : "1.2-2"
                }}%
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 wavecol">
          <div class="fle wave_graph_top_data">
            <div>Bookings</div>
            <div *ngIf="dataSourceBooking && dataSourceBooking.length>1">
              {{
                (dataSourceBooking[1].users / dataSourceBooking[0].users) * 100
                  | number : "1.2-2"
              }}%
            </div>
          </div>
          <canvas height="300" id="waveCanvas_8">
            {{ wave_chart_2 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="dataSourceBooking && dataSourceBooking.length>1" class="fle">
              <div class="Abandonment_left">
                {{ this.dataSourceBooking[1].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.dataSourceBooking[1].abandonment_rate * 100 | number : "1.2-2"
                }}%
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 wavecol">
          <div  class="fle wave_graph_top_data">
            <div>Success</div>
            <div *ngIf="dataSourceBooking && dataSourceBooking.length>1">
              {{
                (dataSourceBooking[2].users / dataSourceBooking[0].users) * 100
                  | number : "1.2-2"
              }}%
            </div>
          </div>
          <canvas height="300" id="waveCanvas_9">
            {{ wave_chart_3 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="dataSourceBooking && dataSourceBooking.length>1" class="fle">
              <div class="Abandonment_left">
                {{ this.dataSourceBooking[2].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.dataSourceBooking[2].abandonment_rate * 100 | number : "1.2-2"
                }}%
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-3 wavecol">
          <div  class="fle wave_graph_top_data">
            <div>Success</div>
            <div *ngIf="funnel_data.length>1">
              {{
                (funnel_data[3].users / funnel_data[0].users) * 100
                  | number : "1.2-2"
              }}%
            </div>
          </div>
          <canvas height="300" id="waveCanvas_4">
            {{ wave_chart_4 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="funnel_data.length>1" class="fle">
              <div class="Abandonment_left">
                {{ this.funnel_data[3].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.funnel_data[3].abandonment_rate * 100 | number : "1.2-2"
                }}%
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="mat-elevation mt-3 flex-row">
        <!-- Table -->
        <table *ngIf="dataSource" mat-table [dataSource]="dataSourceBooking">
          <!-- Column definitions -->
          <ng-container matColumnDef="step" sticky>
            <th mat-header-cell *matHeaderCellDef>Step</th>
            <td mat-cell *matCellDef="let row">{{ row.step.slice(2) }}</td>
          </ng-container>

          <ng-container matColumnDef="users">
            <th mat-header-cell *matHeaderCellDef>Users(% of step 1)</th>
            <td mat-cell *matCellDef="let row">{{ row.users }}</td>
          </ng-container>

          <ng-container matColumnDef="completion">
            <th mat-header-cell *matHeaderCellDef>Completion Rate</th>
            <td mat-cell *matCellDef="let row">
              {{ row.completion_rate * 100 | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="abandonments">
            <th mat-header-cell *matHeaderCellDef>Abandonments</th>
            <td

              style="color: #3268fb !important; cursor: pointer"
              (click)="listCustomer(download_file, i,1)"
              mat-cell
              *matCellDef="let row; let i = index"
            >
              {{ row.abandonment }}
            </td>
          </ng-container>

          <ng-container matColumnDef="abandonment_rate">
            <th mat-header-cell *matHeaderCellDef>Abandonment rate</th>
            <td mat-cell *matCellDef="let row">
              {{ row.abandonment_rate * 100 | number : "1.2-2" }}
            </td>
          </ng-container>

          <!-- Table rows -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
    <!-- digital gold funnel -->
    <div class="mt-2 mb-2 date_adjust_2">
      <h3 class="headi mt-4">Digital Gold Funnel
        <span class="ms-1">
          <i
            matTooltipClass="tooltip-custom"
            mat-button
            matTooltip="With a Advance Booking funnel, you can track the progression of pages where your users exit your website or app"
            matTooltipPosition="above"
          >
            <img src="assets/images/Info.svg" />
          </i>
        </span>

      </h3>
      <div class="mt-1 date_adjust">
        <div class="me-3 date1">
          <label class="date_label">Start Date</label>
          <input
            type="date"
            class="form-control"
            id="dob"
            placeholder="--/--/----"
            [(ngModel)]="funnel_sdate_booking"
            [max]="getCurrentDate()"
            (input)="removeEndDateFunnelBooking()"
          />
        </div>

        <div class="me-2 date2">
          <label class="date_label">End Date</label>
          <input
            type="date"
            class="form-control"
            id="dob"
            [max]="getCurrentDate()"
            [min]="funnel_sdate_booking"
            [(ngModel)]="funnel_edate_booking"
            (change)="getFunnelDatesBooking()"
          />
        </div>
      </div>
      </div>
    <div class="row mt-2 ms-4 waverow mb-5">
      <div class="dropdown_modal">
        <div class="fle mar">
          <div class="saving_subs_title">
           <!-- Advance Bookings Funnel -->
            <!-- <span class="ms-1">
              <i
                matTooltipClass="tooltip-custom"
                mat-button
                matTooltip="With a SIP funnel, you can track the progression of pages where your users exit your website or app"
                matTooltipPosition="above"
              >
                <img src="assets/images/Info.svg" />
              </i>
            </span> -->
          </div>
          <div style="display: flex; justify-content: space-between">
            <div class="total_plans_amount me-3">
              Total Bookings
              <span class="total_plan_value">
                {{ dropdown_data.total_bookings | indianNumeration }}</span
              >
            </div>
            <div class="total_plans_amount pb-2">
              Total Amount
              <span mat-button class="total_plan_value"
                >₹ {{ total_amount_booking_funnel | indianNumeration }}

                <mat-icon [matMenuTriggerFor]="funnel2" class="centerClass mb-1"
                  >keyboard_arrow_down</mat-icon
                >
              </span>
            </div>
          </div>
          <mat-menu #funnel2="matMenu" class="profile">
            <div class="sip_subscribed">
              <div class="title_sip_subs">Digital Gold</div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="app_title mb-3">App</div>
                  <div class="fle details_sub">
                    <div class="widthcommon">New Bookings</div>
                    <div class="widthcommon1">Repeat Customer Bookings</div>
                  </div>
                  <div class="fle">
                    <div class="close_space2">
                      <div class="count_cust">
                        {{ dropdown_data.app_new_booking }}
                      </div>
                      <span class="count_rupee">
                        ₹
                        {{
                          dropdown_data.app_new_booking_amount | indianNumeration
                        }}</span
                      >
                    </div>
                    <div class="close_space">
                      <div class="count_cust">
                        {{ dropdown_data.app_repeat_booking }}
                      </div>
                      <span class="count_rupee">
                        ₹
                        {{
                          dropdown_data.app_repeat_booking_amount
                            | indianNumeration
                        }}</span
                      >
                    </div>
                  </div>
                </div>
    
                <div class="col-md-6">
                  <div class="vertical-line_subs me-4"></div>
                  <div class="app_title mb-3">Store</div>
                  <div class="fle details_sub">
                    <div class="widthcommon">New Bookings</div>
                    <div class="widthcommon1">Repeat Customer Bookings</div>
                  </div>
                  <div class="fle">
                    <div class="close_space2">
                      <div class="count_cust">
                        {{ dropdown_data.store_new_booking }}
                      </div>
                      <span class="count_rupee">
                        ₹
                        {{
                          dropdown_data.store_new_booking_amount | indianNumeration
                        }}</span
                      >
                    </div>
                    <div class="close_space">
                      <div class="count_cust">
                        {{ dropdown_data.store_repeat_booking }}
                      </div>
                      <span class="count_rupee">
                        ₹
                        {{
                          dropdown_data.store_repeat_booking_amount
                            | indianNumeration
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>
      <mat-menu #funnel="matMenu" class="profile">
        <div class="sip_subscribed">
          <div class="title_sip_subs">Advance Bookings</div>
          <div class="row mt-3">
            <div class="col-md-6">
              <div class="app_title mb-3">App</div>
              <div class="fle details_sub">
                <div class="widthcommon">New Bookings</div>
                <div class="widthcommon1">Repeat Customer Bookings</div>
              </div>
              <div class="fle">
                <div class="close_space2">
                  <div class="count_cust">
                    {{ dropdown_data.app_new_booking }}
                  </div>
                  <span class="count_rupee">
                    ₹
                    {{
                      dropdown_data.app_new_booking_amount | indianNumeration
                    }}</span
                  >
                </div>
                <div class="close_space">
                  <div class="count_cust">
                    {{ dropdown_data.app_repeat_booking }}
                  </div>
                  <span class="count_rupee">
                    ₹
                    {{
                      dropdown_data.app_repeat_booking_amount
                        | indianNumeration
                    }}</span
                  >
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="vertical-line_subs me-4"></div>
              <div class="app_title mb-3">Store</div>
              <div class="fle details_sub">
                <div class="widthcommon">New Bookings</div>
                <div class="widthcommon1">Repeat Customer Bookings</div>
              </div>
              <div class="fle">
                <div class="close_space2">
                  <div class="count_cust">
                    {{ dropdown_data.store_new_booking }}
                  </div>
                  <span class="count_rupee">
                    ₹
                    {{
                      dropdown_data.store_new_booking_amount | indianNumeration
                    }}</span
                  >
                </div>
                <div class="close_space">
                  <div class="count_cust">
                    {{ dropdown_data.store_repeat_booking }}
                  </div>
                  <span class="count_rupee">
                    ₹
                    {{
                      dropdown_data.store_repeat_booking_amount
                        | indianNumeration
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-menu>
      <div *ngIf="funnel_data" class="flex-row">
        <div class="col-md-3 wavecol">
          <div class="fle wave_graph_top_data">
            <div>Home</div>
            <div>100%</div>
          </div>
          <canvas height="300" id="waveCanvas_10">
            {{ wave_chart_7 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="dataSourceBooking && dataSourceBooking.length>1" class="fle">
              <div  class="Abandonment_left">
                {{ this.dataSourceBooking[0].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.dataSourceBooking[0].abandonment_rate * 100 | indianNumeration
                }}%
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 wavecol">
          <div class="fle wave_graph_top_data">
            <div>Bookings</div>
            <div *ngIf="dataSourceBooking && dataSourceBooking.length>1">
              {{
                (dataSourceBooking[1].users / dataSourceBooking[0].users) * 100
                  | indianNumeration
              }}%
            </div>
          </div>
          <canvas height="300" id="waveCanvas_11">
            {{ wave_chart_2 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="dataSourceBooking && dataSourceBooking.length>1" class="fle">
              <div class="Abandonment_left">
                {{ this.dataSourceBooking[1].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.dataSourceBooking[1].abandonment_rate * 100 | indianNumeration
                }}%
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 wavecol">
          <div  class="fle wave_graph_top_data">
            <div>Success</div>
            <div *ngIf="dataSourceBooking && dataSourceBooking.length>1">
              {{
                (dataSourceBooking[2].users / dataSourceBooking[0].users) * 100
                  | number : "1.2-2"
              }}%
            </div>
          </div>
          <canvas height="300" id="waveCanvas_12">
            {{ wave_chart_3 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="dataSourceBooking && dataSourceBooking.length>1" class="fle">
              <div class="Abandonment_left">
                {{ this.dataSourceBooking[2].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.dataSourceBooking[2].abandonment_rate * 100 | number : "1.2-2"
                }}%
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 wavecol">
          <div  class="fle wave_graph_top_data">
            <div>Success</div>
            <div *ngIf="dataSourceBooking && dataSourceBooking.length>1">
              {{
                (dataSourceBooking[2].users / dataSourceBooking[0].users) * 100
                  | indianNumeration
              }}%
            </div>
          </div>
          <canvas height="300" id="waveCanvas_12">
            {{ wave_chart_3 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="dataSourceBooking && dataSourceBooking.length>1" class="fle">
              <div class="Abandonment_left">
                {{ this.dataSourceBooking[2].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.dataSourceBooking[2].abandonment_rate * 100 | indianNumeration
                }}%
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-3 wavecol">
          <div  class="fle wave_graph_top_data">
            <div>Success</div>
            <div *ngIf="funnel_data.length>1">
              {{
                (funnel_data[3].users / funnel_data[0].users) * 100
                  | number : "1.2-2"
              }}%
            </div>
          </div>
          <canvas height="300" id="waveCanvas_4">
            {{ wave_chart_4 }}
          </canvas>
          <div class="pt-4 pb-2 mx-2 responsive_graph">
            <div class="Abandonment rate">Abandonment rate</div>
            <div *ngIf="funnel_data.length>1" class="fle">
              <div class="Abandonment_left">
                {{ this.funnel_data[3].abandonment }}
              </div>
              <div class="Abandonment_right">
                {{
                  this.funnel_data[3].abandonment_rate * 100 | number : "1.2-2"
                }}%
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="mat-elevation mt-3 flex-row">
        <!-- Table -->
        <table *ngIf="dataSource" mat-table [dataSource]="dataSourceBooking">
          <!-- Column definitions -->
          <ng-container matColumnDef="step" sticky>
            <th mat-header-cell *matHeaderCellDef>Step</th>
            <td mat-cell *matCellDef="let row">{{ row.step.slice(2) }}</td>
          </ng-container>

          <ng-container matColumnDef="users">
            <th mat-header-cell *matHeaderCellDef>Users(% of step 1)</th>
            <td mat-cell *matCellDef="let row">{{ row.users }}</td>
          </ng-container>

          <ng-container matColumnDef="completion">
            <th mat-header-cell *matHeaderCellDef>Completion Rate</th>
            <td mat-cell *matCellDef="let row">
              {{ row.completion_rate * 100 | indianNumeration }}
            </td>
          </ng-container>

          <ng-container matColumnDef="abandonments">
            <th mat-header-cell *matHeaderCellDef>Abandonments</th>
            <td

              style="color: #3268fb !important; cursor: pointer"
              (click)="listCustomer(download_file, i,1)"
              mat-cell
              *matCellDef="let row; let i = index"
            >
              {{ row.abandonment }}
            </td>
          </ng-container>

          <ng-container matColumnDef="abandonment_rate">
            <th mat-header-cell *matHeaderCellDef>Abandonment rate</th>
            <td mat-cell *matCellDef="let row">
              {{ row.abandonment_rate * 100 | indianNumeration }}
            </td>
          </ng-container>

          <!-- Table rows -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    
  </div>
  <div id="savings_scheme_life_time">
    <div class="dropdown_custom">
      <div>
        <span class="dropdown_title"> Total Installments </span>
        <br />
        <span class="dropdown_value">
          {{ life_time_performance.all_installments }}
        </span>
      </div>
      <div>
        <span class="dropdown_title"> Total Amount Collected </span>
        <br />
        <span class="dropdown_value">
          ₹ {{ life_time_performance.all_installment_amount  | indianNumeration}}
        </span>
      </div>
    </div>
    <div class="saving_scheme_child">
      <div
        class="px-3 pt-2 pb-2"
        style="display: flex; justify-content: space-between"
      >
        <div class="payment_mode_left">Online</div>
        <div class="payment_mode_right">
          ₹ {{ life_time_performance.in_online_payment  | indianNumeration}}
        </div>
      </div>

      <div
        class="px-3 pt-2 pb-2"
        style="
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #f4f4f4;
        "
      >
        <div class="payment_mode_left">Cash</div>
        <div class="payment_mode_right">
          ₹ {{ life_time_performance.store_amount_total_cash | indianNumeration }}
        </div>
      </div>

      <div
        class="px-3 pt-2 pb-2"
        style="
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #f4f4f4;
        "
      >
        <div class="payment_mode_left">Card</div>
        <div class="payment_mode_right">
          ₹ {{ life_time_performance.store_amount_total_card  | indianNumeration }}
        </div>
      </div>

      <div
        class="px-3 pt-2 pb-2"
        style="
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #f4f4f4;
        "
      >
        <div class="payment_mode_left">UPI</div>
        <div class="payment_mode_right">
          ₹ {{ life_time_performance.store_amount_total_upi  | indianNumeration}}
        </div>
      </div>

      <div
        class="px-3 pt-2 pb-2"
        style="
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #f4f4f4;
        "
      >
        <div class="payment_mode_left">Net Banking</div>
        <div class="payment_mode_right">
          ₹ {{ life_time_performance.store_amount_total_net_banking  | indianNumeration}}
        </div>
      </div>

      <div
        class="px-3 pt-2 pb-2"
        style="display: flex; justify-content: space-between"
      >
        <div class="payment_mode_left">Cheque</div>
        <div class="payment_mode_right">
          ₹ {{ life_time_performance.store_amount_total_net_cheque  | indianNumeration}}
        </div>
      </div>
    </div>

    <div class="bg_">
      <div

      style="display: flex; justify-content: space-between"
    >
      <h3 class="headi mt-4">Jewellery Plans Performance</h3>
    </div>
    <div class="row mb-4 resposive_forth">
      <div class="col-md-8 col_forth_first">
        <div class="fle mx-3">
          <div class="total_installment_paid">
            Total No of Installments Paid
            <!-- <span class="ms-1">
            <i
              matTooltipClass="tooltip-custom"
              mat-button
              matTooltip="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod "
              matTooltipPosition="above"
            >
              <img src="assets/images/Info.svg" />
            </i>
          </span> -->
            <br />
            <span class="total_installment_paid_value">{{
              life_time_performance.all_installments
            }}</span>
          </div>
          <div class="total_installment_paid">
            Total Amount collected
            <br />
            <span class="total_installment_paid_value">{{
              life_time_performance.all_installment_amount | indianNumeration
            }}</span>
          </div>
        </div>
        <div
          class="mx-3 mt-4"
          style="display: flex; justify-content: space-between"
        >
          <div class="online_text">
            Online
            <br />
            <span class="online_text_value"
              >₹
              {{
                life_time_performance.in_online_payment | indianNumeration
              }}</span
            >
          </div>

          <div class="online_text border_left">
            Cash
            <br />
            <span class="online_text_value"
              >₹
              {{
                life_time_performance.store_amount_total_cash | indianNumeration
              }}</span
            >
          </div>
          <div class="online_text border_left">
            Card
            <br />
            <span class="online_text_value"
              >₹
              {{
                life_time_performance.store_amount_total_card | indianNumeration
              }}</span
            >
          </div>
        </div>
        <div
          class="mx-3 mt-4"
          style="display: flex; justify-content: space-between"
        >
          <div class="online_text">
            UPI
            <br />
            <span class="online_text_value"
              >₹
              {{
                life_time_performance.store_amount_total_upi | indianNumeration
              }}</span
            >
          </div>
          <div class="online_text border_left">
            Net Banking
            <br />
            <span class="online_text_value"
              >₹
              {{
                life_time_performance.store_amount_total_net_banking
                  | indianNumeration
              }}</span
            >
          </div>
          <div class="online_text border_left">
            Cheque
            <br />
            <span class="online_text_value"
              >₹
              {{
                life_time_performance.store_amount_total_net_cheque
                  | indianNumeration
              }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-4 col_forth_second">
        <div class="row" >
          <div class="pt-3 px-3 doughnut_top">
            <div class="all_plans">All Plans</div>
            <div class="average_ticket">
             Total Plans:  {{life_time_performance.total_count | indianNumeration}}
            </div>
          </div>
          <div class="px-3 pb-3 pt-2 doughnut_top">
            <div class="all_plans">Plan Value: {{life_time_performance.total_subscription | indianNumeration}}</div>
            <div class="average_ticket">
              Average Plan Size: ₹ {{life_time_performance.average_ticket_price | indianNumeration}}
            </div>
          </div>

          <div class="col-md-7">
            <canvas    id="myChartDoughnut" height="200px">
              {{ chartDoughnut }}
            </canvas>
          </div>
          <div class="average_ticket doughnut_bottom">
            Average Ticket Size: ₹ {{ life_time_performance.average_ticket_price | indianNumeration}}
          </div>
          <div class="col-md-5 remove_doughtnut" style="align-self: center">
        
            <div style="display: flex; align-items: center">
              <div class="vertical_line" style="background: #668DF6B2"></div>
              <div class="plans_state">
                {{ life_time_performance.in_progress_count }} Plans in Progress
                <br />
                <span class="plans_data">
                  ₹{{
                    life_time_performance.in_progress_subscription
                      | indianNumeration
                  }}
                </span>
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <div class="vertical_line" style="background: #668DF6"></div>
              <div class="plans_state">
                {{ life_time_performance.completed_count }} Plans Completed
                <br />
                <span class="plans_data">
                  ₹{{
                    life_time_performance.completed_subscription
                      | indianNumeration
                  }}
                </span>
              </div>
            </div>

            <div style="display: flex; align-items: center">
              <div class="vertical_line" style="background: #3268FB"></div>
              <div class="plans_state">
                {{ life_time_performance.billed_count }} Plans Billed
                <br />
                <span class="plans_data">
                  ₹{{
                    life_time_performance.billed_subscription
                      | indianNumeration
                  }}
                </span>
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <div class="vertical_line" style="background: #082D92"></div>
              <div class="plans_state">
                {{ life_time_performance.cancelled_count }} Plan Cancelled
                <br />
                <span class="plans_data">
                  ₹{{
                    life_time_performance.cancelled_subscription
                      | indianNumeration
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="doughtnut_details">
            <div
              style="
                display: flex;
                justify-content: space-between;
                padding: 0%5%0%5%;
              "
            >
              <div style="display: flex; align-items: center">
                <div class="vertical_line"></div>
                <div class="plans_state">
                  {{ life_time_performance.completed_count }} Plans Completed
                  <br />
                  <span class="plans_data">  ₹{{
                    life_time_performance.completed_subscription
                      | indianNumeration
                  }} </span>
                </div>
              </div>
              <div style="display: flex; align-items: center">
                <div class="vertical_line"></div>
                <div class="plans_state">
                  {{ life_time_performance.in_progress_count }} Plans in Progress
                  <br />
                  <span class="plans_data"> ₹{{
                    life_time_performance.in_progress_subscription
                      | indianNumeration
                  }} </span>
                </div>
              </div>
            </div>
            <div
              class="doughnut_details"
              style="
                display: flex;
                justify-content: space-between;
                padding: 2%5%3%5%;
              "
            >
              <div style="display: flex; align-items: center">
                <div class="vertical_line"></div>
                <div class="plans_state">
                  {{ life_time_performance.cancelled_count }} Plan Cancelled
                  <br />
                  <span class="plans_data">   ₹{{
                    life_time_performance.cancelled_subscription
                      | indianNumeration
                  }}</span>
                </div>
              </div>
              <div style="display: flex; align-items: center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>


    <div class="bg_ mt-4">
      <div
      class="mt-2"
      style="display: flex; justify-content: space-between"
    >
      <h3 class="headi mt-3">Advance Booking Performance</h3>
    </div>
    <div class="row mb-4 resposive_forth">
      <div class="col-md-8 col_forth_first">
        <div class="fle mx-3">
          <div class="total_installment_paid">
            Total No of Bookings Payment
            <!-- <span class="ms-1">
            <i
              matTooltipClass="tooltip-custom"
              mat-button
              matTooltip="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod "
              matTooltipPosition="above"
            >
              <img src="assets/images/Info.svg" />
            </i>
          </span> -->
            <br />
            <span class="total_installment_paid_value">{{
              life_time_performance.all_booking
            }}</span>
          </div>
          <div class="total_installment_paid">
            Total Amount collected
            <br />
            <span class="total_installment_paid_value">{{
              life_time_performance.all_booking_amount | indianNumeration
            }}</span>
          </div>
        </div>
        <div
          class="mx-3 mt-4"
          style="display: flex; justify-content: space-between"
        >
          <div class="online_text">
            Online
            <br />
            <span class="online_text_value"
              >₹
              {{
                life_time_performance.in_online_booking_payment | indianNumeration
              }}</span
            >
          </div>
  
          <div class="online_text border_left">
            Cash
            <br />
            <span class="online_text_value"
              >₹
              {{
                life_time_performance.store_booking_total_cash | indianNumeration
              }}</span
            >
          </div>
          <div class="online_text border_left">
            Card
            <br />
            <span class="online_text_value"
              >₹
              {{
                life_time_performance.store_booking_total_card | indianNumeration
              }}</span
            >
          </div>
        </div>
        <div
          class="mx-3 mt-4"
          style="display: flex; justify-content: space-between"
        >
          <div class="online_text">
            UPI
            <br />
            <span class="online_text_value"
              >₹
              {{
                life_time_performance.store_booking_total_upi | indianNumeration
              }}</span
            >
          </div>
          <div class="online_text border_left">
            Net Banking
            <br />
            <span class="online_text_value"
              >₹
              {{
                life_time_performance.store_booking_total_net_banking
                  | indianNumeration
              }}</span
            >
          </div>
          <div class="online_text border_left">
            Cheque
            <br />
            <span class="online_text_value"
              >₹
              {{
                life_time_performance.store_booking_total_cheque
                  | indianNumeration
              }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-4 col_forth_second temp">
        <div class="row" >
          <div class="pt-3 px-3 doughnut_top">
            <div class="all_plans">All Bookings</div>
            <div class="average_ticket">
              Average Ticket Size: ₹{{
                life_time_performance.average_booking_ticket_price | indianNumeration
              }}
            </div>
          </div>
  
          <div class="col-md-7">
            <canvas    id="myChartDoughnutBookings" height="200px">
              {{ chartDoughnutBookings }}
            </canvas>
          </div>
          <div class="average_ticket doughnut_bottom">
            Average Ticket Size: ₹ {{ life_time_performance.average_booking_ticket_price | indianNumeration}}
          </div>
          <div class="col-md-5 remove_doughtnut" style="align-self: center">
            <div style="display: flex; align-items: center">
              <div class="vertical_line"></div>
              <div class="plans_state">
                {{ life_time_performance.completed_booking_count }}  Completed Bookings
                <br />
                <span class="plans_data">
                  ₹{{
                    life_time_performance.completed_booking_amount
                      | indianNumeration
                  }}
                </span>
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <div class="vertical_line" style="background: #082d92"></div>
              <div class="plans_state">
                {{ life_time_performance.in_progress_booking_count }}  in Progress Bookings
                <br />
                <span class="plans_data">
                  ₹{{
                    life_time_performance.in_progess_booking_amount
                      | indianNumeration
                  }}
                </span>
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <div class="vertical_line" style="background: #3268fb"></div>
              <div class="plans_state">
                {{ life_time_performance.cancelled_booking_count }} Cancelled Bookings
                <br />
                <span class="plans_data">
                  ₹{{
                    life_time_performance.cancelled_booking_amount
                      | indianNumeration
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="doughtnut_details">
            <div
              style="
                display: flex;
                justify-content: space-between;
                padding: 0%5%0%5%;
              "
            >
              <div style="display: flex; align-items: center">
                <div class="vertical_line"></div>
                <div class="plans_state">
                  {{ life_time_performance.completed_count }} Plans Completed
                  <br />
                  <span class="plans_data">  ₹{{
                    life_time_performance.completed_subscription
                      | indianNumeration
                  }} </span>
                </div>
              </div>
              <div style="display: flex; align-items: center">
                <div class="vertical_line"></div>
                <div class="plans_state">
                  {{ life_time_performance.in_progress_count }} Plans in Progress
                  <br />
                  <span class="plans_data"> ₹{{
                    life_time_performance.in_progress_subscription
                      | indianNumeration
                  }} </span>
                </div>
              </div>
            </div>
            <div
              class="doughnut_details"
              style="
                display: flex;
                justify-content: space-between;
                padding: 2%5%3%5%;
              "
            >
              <div style="display: flex; align-items: center">
                <div class="vertical_line"></div>
                <div class="plans_state">
                  {{ life_time_performance.cancelled_count }} Plan Cancelled
                  <br />
                  <span class="plans_data">   ₹{{
                    life_time_performance.cancelled_subscription
                      | indianNumeration
                  }}</span>
                </div>
              </div>
              <div style="display: flex; align-items: center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <!-- Advance Booking Performance -->

  </div>
<!-- </ng-container> -->
</div>
</div>
<ng-template  #download_file let-modal >

  <div class="emailModal">

    <div class="modal-header p-3" style="border: 0">
      <div class="modal-title">Customer Details</div>
      <!-- <div>
      <button mat-button >
        Click me

      </button>
    </div> -->
      <button
        type="button"
        style="outline: none"
        class="close btn"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body customer_detail">
      <table mat-table mat-table [dataSource]="users">
        <!-- <thead>
          <tr >
            <th class="thead_customer_list" >Sr No</th>
            <th class="thead_customer_list">Phone Number</th>
          </tr>

        </thead> -->
        <ng-container matColumnDef="serialNumber">
          <th mat-header-cell *matHeaderCellDef>Sr No</th>
          <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="column1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Phone Number
          </th>
          <td mat-cell *matCellDef="let users">{{ users }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsNew"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsNew"></tr>
        <!-- <tbody>

          <tr  *ngFor="let i of users;let j = index">
            <td class="table_left_data">{{ j+1 }}</td>
            <td class="table_right_data">
              {{i}}
            </td>

          </tr>
        </tbody> -->
      </table>
      <div class="row mt-3">
        <div class="col-md-12 text-center">
          <mat-spinner
            *ngIf="customer_details_loading"
            style="margin: 12px auto"
            mode="indeterminate"
            [diameter]="50"
          ></mat-spinner>
        </div>
      </div>
    </div>
    <div class="downlaod_for_mobile m-2 ">
      <button class="download_button" (click)="downloadFile(users)">Download</button>
    </div>
  </div>

</ng-template>
