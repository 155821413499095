import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BuyTableData } from '../bookings/bookings.component';
import { PrimaryBtnComponent } from '../common/primary-btn/primary-btn.component';

@Component({
  selector: 'app-payment-status-popup',
  standalone: true,
  imports: [PrimaryBtnComponent],
  templateUrl: './payment-status-popup.component.html',
  styleUrls: ['./payment-status-popup.component.css']
})
export class PaymentStatusPopupComponent {

  constructor(
    public dialogRef: MatDialogRef<PaymentStatusPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { details: BuyTableData & { weight: string } }
  ) { }
}
