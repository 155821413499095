<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:10vh;">

<div *ngIf="!data_present" class="no_rate_cut">

    <div><img src="assets/images/Savings.png" class="img-responsive" /></div>

    <div class="no_rate_cut_title">You don't have any Advance Booking Setup yet</div>
    <div> <button class="setup_button" (click)="handleSetup()">+ Setup New</button></div>

</div>
<div *ngIf="data_present" class="ms-4 mt-4">
  <h3 (click)="back()" class="back_title">
    <mat-icon class="right_back">arrow_back_ios</mat-icon>
    Setup Advance booking
  </h3>
</div>
<div *ngIf="data_present" class="weight_block ms-4">
    <div  class="weight_title">Weight</div>
    <div  *ngFor="let data of rate_cut.options;let i=index">
    <div class="input_block">
        <label class="rate_label">From (In Gms)*</label>

        <label class="rate_label"> To (In Gms)*</label>
        <!-- <input class="form-control"> -->
        <label class="rate_label">Advance % *</label>
        <!-- <input class="form-control"> -->
        <label class="rate_label">Redeem Duration*</label>
        <!-- <input class="form-control"> -->
    </div>
    <div class="input_block">
        <!-- <label class="rate_label">From</label> -->
        <input class="form-control fontstyle"
           [(ngModel)]="data.weight_from" [disabled]="data.blocked" type="text"  appTwoDigitDecimaNumber>
        <!-- <label class="rate_label"> To</label> -->
        <input class="form-control"
       [(ngModel)]="data.weight_to" (input)="addZeroPointOne(i)" #focus  type="text"  appTwoDigitDecimaNumber>
        <!-- <label class="rate_label">Advance %</label> -->

        <select
        [(ngModel)]="data.advance_percentage"
        class="form-select"

      >
        <option value="" disabled selected>Select Advance%</option>
        <option value="30">30%</option>
        <option value="35">35%</option>
        <option value="40">40%</option>
        <option value="45">45%</option>
        <option value="50">50%</option>
        <option value="55">55%</option>
        <option value="60">60%</option>
        <option value="65">65%</option>
        <option value="70">70%</option>
        <option value="75">75%</option>
        <option value="80">80%</option>
        <option value="85">85%</option>
        <option value="90">90%</option>
        <option value="95">95%</option>
        <option value="100">100%</option>

      </select>
        <!-- <label class="rate_label">Redeem Duration</label> -->

          <select
          [(ngModel)]="data.redeem_duration"
          class="form-select"

        >
          <option value="">Select Months</option>
          <option value="2">2 Months</option>
          <option value="3">3 Months</option>
          <option value="4">4 Months</option>
          <option value="5">5 Months</option>
          <option value="6">6 Months</option>
          <option value="7">7 Months</option>
          <option value="8">8 Months</option>
          <option value="9">9 Months</option>
          <option value="10">10 Months</option>
          <option value="11">11 Months</option>
          <option value="12">12 Months</option>
  
        </select>





    </div>


</div>
<div class="add_remove">
  <div (click)="addOneMoreRecord()" class="add_shift">+ Add (Shift+Enter) </div>
  <div  (click)="removeLastRow()" class="remove_shift">- Remove (Shift+DEL) </div>
</div>

    <div class="button_block">
        <div class="button_block_child">
            <button  mat-raised-button
            color="primary" [disabled]="!dataChanged()" (click)="validatingData()?warningModal(continue):''" class="setup_button">Save</button>
            <!-- <button class="setup_button2">Cancel</button> -->
        </div>

    </div>
</div>
</div>
<ng-template #continue let-modal>
  <div class="myModal">
    <div class="modal-content p-2" style="width: 100%">
      <div class="modal-header" style="border: 0; height: 0">
        <h1 class="modal-title fbold"></h1>
        <button
          type="button"
          class="close closeButton btn"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <img
            src="assets/images/errorsign.svg"
            class="img-responsive img-fluid"
          />

          <h1 class="fbold mt-2">Warning!</h1>

          <p
            class="warning_text"

          >
          Are you sure you want to save the changes?
          </p>
          <div  class="warning_block">
            <div class="warning_block_note ms-3 mb-2">Note:</div>
            <ul>
              <li class="mb-1">
                All previously made bookings will remain unchanged and will not affect any bookings that have already been made.
              </li>
              <li>
                The new booking terms will be applied to all the future bookings.
              </li>
            </ul>
          </div>
          <button
            (click)="postRateCut();modal.dismiss('Cross click')"
            type="button"
            class="btn pause_btn mt-3"
          >
          Apply Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
