import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { RateFinal, RateTable } from '../models/rate';
import { Rest } from '../models/rest_model';
import { ResponseMessage } from '../models/message';

@Injectable({
  providedIn: 'root'
})
export class RateServiceService {
  get_rates_url = environment.ip+"shop/rates/";
  daily_rates_url = environment.ip+"shop/daily-rates/";
  latest_rates_url = environment.ip+"shop/latest_rates/";

  post_material_name=environment.ip+"shop/materialNames/add_material_name/"

  get_material_name=environment.ip+"shop/materialNames/get_names/"

  // get default purites
  get_default_purites=environment.ip+"shop/customOrders/get_purity/"
  // create purities
  add_purities=environment.ip+"shop/customOrders/add_purity/"

  constructor(private http:HttpClient) { }

  getRates(){
    return this.http.get<RateFinal>(this.get_rates_url);
  }
  getLatestRates(){
    return this.http.get<RateFinal>(this.latest_rates_url);
  }
  getRateLogs(page:number,from_date:string,to_date:string){
    return this.http.get<Rest<RateTable>>(this.daily_rates_url,{params:{"from_date":from_date,"to_date":to_date,"page":page}});

  }
  updateRates(gold22:number,gold18:number,silver:number,platinum:number,gold24:number,gold_others:number,silver999:number){
    var data={
      gold22:gold22,
      gold18:gold18,
      silver:silver,
      platinum:platinum,

      gold24:gold24,
      gold_others:gold_others,
      silver_999:silver999

    };

    const headers = { 'content-type': 'application/json'}

    return this.http.post<ResponseMessage>(this.daily_rates_url+"add_rates/",data,{headers:headers});
  }
  saveReport(fd:FormData){

    return this.http.post<ResponseMessage>(this.daily_rates_url+"add_rates/",fd);
  }

  postMaterialname(gold22:any,gold18:any,silver:any,platinum:any,gold24:any, goldother:any, silver999:any)
  {
    var fd = new FormData();
    fd.append("gold22",gold22);
    fd.append("gold18",gold18);
    fd.append("silver",silver);
    fd.append("platinum",platinum);
    fd.append("gold24",gold24);
    fd.append("gold_others",goldother);
    fd.append("silver_999",silver999);


    return this.http.post<ResponseMessage>(this.post_material_name,fd);
  }

  getMaterialname()
  {
    return this.http.get<ResponseMessage>(this.get_material_name);
  }
  // this api is for add purity
  getDefaultRates()
  {
    return this.http.get(this.get_default_purites);
  }

  // this api is to create purity
  postDefaultRates(data:any)
  {
    return this.http.post(this.add_purities,data);
  }
}
