import { Component, Input, OnInit } from '@angular/core';
import { InstallmentPrint } from '../models/installments';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css']
})
export class PrinterComponent implements OnInit{
  @Input() content!:InstallmentPrint;
  isVisible: boolean = false;

  ngOnInit(): void {
    this.print();
  }
  setContent(value: any) {
    this.content = value;
  }
  toggleVisibility() {
    this.isVisible = !this.isVisible;
    this.print();
    // setTimeout(() => {
    //   this.isVisible = !this.isVisible;
    // }, 5000);
  }
  print(): void {
    console.log(this.content)
    let printContents = document.getElementById('print-content')!.innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    // window.location.reload();
  }
}
