import {  DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Rest } from "src/app/models/rest_model";
import {  BookingData } from "src/app/models/booking";
import { SettlementServiceService } from "../reports/settlement-service.service";
import { RateCutService } from "src/app/rate_cut_feature/ratecut/rate-cut.service";
import { CancelData } from "src/app/models/cancelOrder";
import { CancelPlanService } from "src/app/cancel-plan/cancel-plan.service";



export class CancelOrderDataSource extends DataSource<any> {

  private cancelData=new BehaviorSubject<CancelData[]>([]);
  private loadingSettlements = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSettlements.asObservable();
  private countSettlements=new BehaviorSubject<number>(0);
  public counter$=this.countSettlements.asObservable();




  constructor(private cancelService:CancelPlanService) {
    super();
  }
  connect(): Observable<CancelData[]> {
    return this.cancelData.asObservable();

  }
  disconnect() { 
    this.cancelData.complete();
    this.loadingSettlements.complete();
  }
  loadData(page:number=0,from_date: any,to_date: any,search:any,filter:any){
      page=page+1;
      this.loadingSettlements.next(true);
      this.cancelService.getCancel(page=page,from_date=from_date,to_date=to_date,search=search,filter=filter).pipe(
        catchError(()=> of([])),
        finalize(()=>{
          this.loadingSettlements.next(false);
        })

      ).subscribe((settlementLogs: any)=>this.renderOrders(settlementLogs))

  }

  renderOrders(settlementLogs:any){

    this.countSettlements.next(settlementLogs.count);
    this.cancelData.next(settlementLogs.results);
    console.log(settlementLogs)

  }





}