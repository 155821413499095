import { Component, ElementRef, HostListener, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { CertificateData } from '../models/Certificate-content';
import { CertificateServiceService } from '../certificate-service.service';
import html2canvas from 'html2canvas';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopUpData } from 'src/app/templates/warning-popup/warning-popup/warning-popup.component';
import { AnimationOptions } from 'ngx-lottie';
import { Router } from '@angular/router';
import { TitleService } from 'src/app/services/title.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { maxNonSpaceLength } from 'src/app/utils/validators/certificate-validator';
import { ToastrService } from 'ngx-toastr';
import { UrlToImageService } from 'src/app/services/url-to-image.service';


@Component({
  selector: 'app-new-certificate',
  templateUrl: './new-certificate.component.html',
  styleUrls: [
    './new-certificate.component.css',
    '../create-certificate/create-certificate.component.css',
    '../certificate-templates/basic-template/basic-template.component.css']
})
export class NewCertificateComponent {
  @ViewChildren('keyInput') keyInputs!: QueryList<ElementRef>;
  @ViewChildren('valueInput') valueInputs!: QueryList<ElementRef>;
  @ViewChild('completed', { read: TemplateRef }) completed_template!: TemplateRef<any>;
  @ViewChild('screenElement', { static: true }) screenElement!: ElementRef;

  
  certificate_completion_popup: PopUpData = new PopUpData("Certificate Created Successfully", "", "", "Show Certificates")
  setup_completion_lottie_file_path: AnimationOptions = { path: '/assets/lottie/completed_animation.json' };

  is_setup_exist: Boolean = false;
  certificateForm = new FormGroup({
    item_code_or_huid: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), Validators.maxLength(20)]),
    description: new FormControl('', [Validators.required, Validators.maxLength(200)])
  });


  raw_certificate_content: { key: string; value: string }[] = [];

  certificate_content: CertificateData = new CertificateData({});

  @ViewChild('certificate') certificateElement!: ElementRef;
  imagesLoaded = 0;
  totalImages = 0;

  constructor(
    private urlToImage:UrlToImageService,
    private titleService: TitleService, private toastr: ToastrService, private modalService: NgbModal, private router: Router, private elementRef: ElementRef, private CertificateService: CertificateServiceService) {
   
  }

  ngOnInit(): void {
    this.titleService.setTitle('New Certificate')
    this.certificateForm.get('item_code_or_huid')?.valueChanges.subscribe(value => {
      console.log('Item Code/HUID changed: ', value);
      console.log(this.certificateForm.get('item_code_or_huid'))
    });

    this.certificateForm.get('description')?.valueChanges.subscribe(value => {
      console.log('Description changed: ', value);
    });
    this.getSetup();
  }

  afterPopup(value: boolean) { this.router.navigate(["dashboard/list-certificate/"]); this.modalService.dismissAll() }

  getSetup() {
    this.CertificateService.getSetup().subscribe((data: CertificateData) => {
      this.is_setup_exist = true;
      console.log(data)
      this.certificate_content = new CertificateData(data);
      console.log(this.certificateElement)
    },(error:any)=>
    {
        this.is_setup_exist = false;
        this.router.navigate(['/dashboard/create_certificate'])
        this.toastr.info("", "Please Create Setup Frist", { positionClass: 'toast-bottom-right', })
    })
  }
      

  addSection() {
    if (this.raw_certificate_content.length > 3) { this.certificate_content.is_overflow = true; }
    this.raw_certificate_content.push({ key: '', value: '' });
  }
  removeSection() {
    if (this.raw_certificate_content.length < 6) { this.certificate_content.is_overflow = false; }
    this.raw_certificate_content.pop();
  }


  focusNextKey(index: number) {
    const keyInputsArray = this.keyInputs.toArray();
    if (keyInputsArray[index + 1]) {
      keyInputsArray[index + 1].nativeElement.focus();
    }
  }

  focusNextValue(index: number) {
    const valueInputsArray = this.valueInputs.toArray();
    if (valueInputsArray[index + 1]) {
      valueInputsArray[index + 1].nativeElement.focus();
    }
  }


  onFileSelected(event: Event, type: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => { this.certificate_content[type] = reader.result; };
        reader.readAsDataURL(file);
      } else { alert('Please upload a valid image file.'); }
    }
  }



  

  captureComponent(event: any) {
    this.certificate_content.is_saved = true;
    if (this.certificateForm.valid) {
   

        this.certificate_content.raw_certificate_content = this.raw_certificate_content;
        const formData = this.createCertificateForm() 

       if (this.certificate_content.item_image) {
        const mimeType = this.certificate_content.item_image?.split(',')[0].split(':')[1].split(';')[0];
        const certificateStoreImageBlob = this.base64ToBlob(this.certificate_content.item_image, mimeType);
        formData.append('image', certificateStoreImageBlob, String(this.certificate_content.certificate_number) + "-" + 'certificate.jpeg');
       }
       


        this.CertificateService.createCertificate(formData).subscribe((data: any) => {
          this.modalService.open(this.completed_template, { centered: true })
          
        },
      (error:any)=>{
        this.certificate_content.is_saved = false;
        this.toastr.error('', 'Error Occured', { positionClass: 'toast-bottom-right', })
      })

    }
    else {
      this.certificate_content.is_saved = false;
      this.toastr.info('', 'Please Enter Valid input', { positionClass: 'toast-bottom-right', })
    }

    
  }
  base64ToBlob(base64: string, mime: string): Blob {
    const byteChars = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
  }
  createCertificateForm(): FormData {
    const formData = new FormData();

    const certificateContentJson = this.raw_certificate_content.reduce((obj: any, item: any) => {
      obj[item.key] = item.value;
      return obj;
    }, {});

    console.log(certificateContentJson)
    formData.append('item_code', this.certificate_content.item_code_or_huid);
    formData.append('description', this.certificate_content.description);
    formData.append('title',this.certificate_content.title)

    formData.append('raw_data', JSON.stringify(certificateContentJson));
    return formData
  }

  @HostListener('window:keydown.shift.enter', ['$event'])
  onShiftEnterPress(event: KeyboardEvent) {
    this.addSection();
    this.focusNextKey(this.raw_certificate_content.length - 1)

  }

  @HostListener('window:keydown.shift.delete', ['$event'])
  onShiftDelete(event: KeyboardEvent) {
    this.removeSection()
  }

  @HostListener('window:keydown.control.s', ['$event'])
  onCtrlSave(event: KeyboardEvent) {
    event.preventDefault();
    this.captureComponent(event)
  }

}
