
<div class="myModal">
    <div class="modal-content" style="width: 100%">
      <div class="modal-header" style="border: 0; height: 0">
        <h1 class="modal-title fbold"></h1>
        <button
        (click)="proceedButtonClicked(false)"
          type="button"
          class="close closeButton btn"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
            <ng-lottie 
            [options]="lottie_file_path"
            [styles]="{margin:'-10% 0%'}"
            >
            </ng-lottie>

          <h1 class="fbold">{{pop_up_data.title}}</h1>

          <p
            class="greyColor"
          >
          {{pop_up_data.message}}
          <br>
            {{pop_up_data.description}}
          </p>

          <button
            type="button"
            class="btn btn-outline-primary pause_btn mt-3"
            (click)="proceedButtonClicked(true)"
          >
            {{pop_up_data.button_text}}
          </button>
        </div>
      </div>
    </div>
  </div>