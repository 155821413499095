<div class="overall-container" style="height:90vh; width:95%; overflow-y:scroll;">
    <div style="margin-right: 20px;">
        <div class="create-container">
            <div class="container">
              <div class="row">           
                <div class="col-sm-4">
                  <label for="rate">Rate/gm</label>
                  <input type="text" class="form-control" [(ngModel)]="rate" />
                </div>
                <div class="col-sm-4">
                  <label for="rate">Metal & Purity</label>
                  <div class="form-control" style="display: flex; flex-direction: row; font-family: 'Rubik'; font-weight: 400; color: #606060; font-size: 14px;" (click)="openModal('metalPurity')">
                    <span>
                      {{ this.selected_metal == 0 ? 'Gold' + '' : this.selected_metal == 2 ? 'Silver'+'' : 'Platinum'+'' }} 
                    </span>
                      <span *ngFor="let purity of metal_purity[activePurity]" >
                        {{ selected_purity == purity.id ? purity.name : '' }}
                      </span>
                  </div>
                </div>       
            </div>
            </div>
            <div class="row-2 form-control" style="width: fit-content; position: relative;">
              <div *ngIf="!imageUrl && !product_link" (click)="openModal('imageUpload')">
                <mat-icon *ngIf="!imageUrl && !product_link" style="color: #4070F4;">add_a_photo</mat-icon>
                <label *ngIf="!imageUrl && !product_link" style="margin-left: 5px;">
                  Upload Image
                </label>
              </div>
              <img *ngIf="imageUrl" [src]="imageUrl" alt="Selected Image" style="height: 100px; width: 100px; border-radius: 4px;">
              <span>
                {{ product_link ? product_link : '' }}
              </span>
              <button *ngIf="imageUrl || product_link" (click)="removeImage()" style="position: absolute; top: 0; right: 0; border: none;">
                <mat-icon style="color: red;">close</mat-icon>
              </button>
            </div> 
            <div class="estimation-box">
              <table>
                <thead>
                  <tr>
                    <th style="border-radius: 10px 0px 0px 0px;" scope="col">Gross Weight (g)</th>
                    <th scope="col">Nett Weight (g)*</th>
                    <th scope="col">Wastage</th>
                    <th scope="col">MC</th>
                    <th scope="col">Stone (₹)</th>
                    <th scope="col">GST %</th>
                    <th style="border-radius: 0px 10px 0px 0px;" scope="col">Total (₹)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><input #grossWeightInput id="gross-wt-id" type="text" class="form-control" style="border: none;"
                        [(ngModel)]="gross_wt" [autofocus]="true" (change)="updateGrossWeight(gross_wt)" /></td>
                    <td><input #nettWeightInput type="text" class="form-control" style="border: none;" [(ngModel)]="nett_wt"
                        (change)="updateNetWeight(nett_wt)" (keydown.tab)="openModal('wastagePopup')"/></td>
                    <td><input #wastageInput type="text" class="form-control" style="border: none;" [(ngModel)]="wastage" (change)="calculateWastage()"
                      (click)="openModal('wastagePopup')" focus="openModal('wastagePopup')"/>
                    </td>
                    <td><input #makingChargeInput type="text" class="form-control" style="border: none;" [(ngModel)]="making_charge" (change)="calculateMakingCharge()"
                        (click)="openModal('makingChargePopup')"/>
                    </td>
                    <td><input #stoneInput type="text" class="form-control" style="border: none;" [(ngModel)]="stone" (change)="addStoneCharge(stoneCount)"
                      (click)="openModal('addStoneChargePopup')" (click)="stoneData.length == 0 ? addRow() : null" /></td>
                    <td><input type="text" class="form-control" style="border: none;" [(ngModel)]="gst"
                        (change)="calculateTotal(gst)" /></td>
                    <td><input type="text" class="form-control" style="border: none;" [(ngModel)]="total" /></td>
                  </tr>
                </tbody>
              </table>
    
              <div>
                <button type="button" class="clear-all-btn" (click)="clearAll()">Clear All</button>
              </div>
            </div>
    
            <div class="save-est-cont" *ngIf="!is_share_visible">
              <button type="button" [ngClass]="total != 0 && total ? 'save-est-btn' : 'save-est-btn-disabled'"
                [disabled]="!total && total ==0" (click)="editEstimate()">Save
                Estimate</button>
            </div>
            <div class="save-est-cont" *ngIf="is_share_visible">
              <div style="position: relative;">
                <input
                  matInput
                  class="form-control"
                  id="3"
                  style="padding-right: 0px; width: 300px;"
                  [(ngModel)]="share_url"
                  [disabled]="true"
                />
                <button style="position: absolute; right: 2px; top: 2px; border: none;" (click)="copyShareUrl(share_url)">
                  <mat-icon style="color: #4070F4;">
                    file_copy
                  </mat-icon>
                </button>
              </div>
              <button type="button" class="add-making-btn" style="margin-left: 20px;" (click)="clearAll()">Create New</button>
            </div>
            <ng-template #wastagePopup let-modal>
              <div class="modal-header" (keydown.enter)="calculateWastage()">
                <h5 class="modal-title" id="wastagePopupLabel">Wastage %</h5>
                <button type="button" style="outline: none;" class="close btn" aria-label="Close" (click)="closeModal()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="wastage-options">
                    <mat-button-toggle-group [(value)]="selected_wastage_type">
                      <mat-button-toggle *ngFor="let wastage_type of wastage_types" [value]="wastage_type.value" (click)="chooseWastage(wastage_type.value)">
                        {{ wastage_type.name }}
                      </mat-button-toggle>
                    </mat-button-toggle-group>
                    <br><br>
                </div>
                <input #wastageChargeTypeInput [autofocus]="true" class="wastage-input" [(ngModel)]="wastage" (keydown.enter)="calculateWastage()"/>
              </div>
              <div class="modal-footer" style="justify-content: center;">
                <button type="button" class="add-wastage-btn" (click)="calculateWastage()">Save</button>
              </div>
            </ng-template>
    
            <ng-template #makingChargePopup let-modal>
              <div class="modal-header" (keydown.enter)="calculateMakingCharge()">
                <h5 class="modal-title" id="makingChargePopupLabel">Making Charge</h5>
                <button type="button" style="outline: none;" class="close btn" aria-label="Close" (click)="closeModal()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="making-charge-options" style="margin-bottom: 10px;">
                    <mat-button-toggle-group [(value)]="selected_making_charge">
                      <mat-button-toggle *ngFor="let making_type of making_charge_types" [value]="making_type.value" (click)="chooseMakingCharge(making_type.value)">
                        {{ making_type.name }}
                      </mat-button-toggle>
                    </mat-button-toggle-group>
                    <br><br>
                  <!-- <label for="wastage-choice">{{ selectedMakingChargeType }}</label> -->
                </div>
                <input type="number" [autofocus]="true" class="making-input" id="making-charge-input-id" [(ngModel)]="making_charge" (keydown.enter)="calculateMakingCharge()"/>
              </div>
              <div class="modal-footer" style="justify-content: center;">
                <button type="button" class="add-making-btn" (click)="calculateMakingCharge()">Save</button>
              </div>
            </ng-template>
    
    
            <ng-template #addStoneChargePopup let-modal>
              <div class="modal-header">
                <h5 class="modal-title" id="addStoneChargeLabel">Stone Cost</h5>
                <button type="button" style="outline: none;" class="close btn" aria-label="Close" (click)="closeModal()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" (keydown.shift.enter)="addRow()" (keydown.cntrl.s)="saveStoneCharges(stoneCount)">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Stone Name</th>
                      <th scope="col">Pcs</th>
                      <th scope="col">Gms</th>
                      <th scope="col">Carat</th>
                      <th scope="col">Rate</th>
                      <th scope="col">Amount</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let stone of stoneData; let i = index">
                    <tr>
                      <td><input #stoneNameInput type="text" style="border: none;" class="stone-form-control"
                          [(ngModel)]="stone.name" /></td>
                      <td><input style="border: none;" class="stone-form-control"
                          [(ngModel)]="stone.quantity" />
                      </td>
                      <td><input style="border: none;" class="stone-form-control" [(ngModel)]="stone.weight" />
                      </td>
                      <td><input style="border: none;" class="stone-form-control" [(ngModel)]="stone.carat" />
                      </td>
                      <td>
                        <div class="input-group">
                          <input [autofocus]="true" style="border: none;" class="stone-form-control" [(ngModel)]="stone.rate" (keydown.enter)="addStoneCharge(stoneCount)"/>
                          <select class="category-box" [(ngModel)]="stone.charge_type" (keydown.enter)="addStoneCharge(stoneCount)" style="padding: 0px;">
                            <option *ngFor="let stone_charge of stone_charge_types" [value]="stone_charge.value">
                              {{ stone_charge.name }}
                            </option>
                          </select>
                        </div>
                      </td>
                      <td><input type="text" style="border: none;" class="stone-form-control" (focus)="addStoneCharge(stoneCount)" [(ngModel)]="stone.cost" />
                      </td>
                      <td>
                        <mat-icon style="color: #4070F4;" type="button" class="delete-stone-row" (click)="removeRow(i)">delete</mat-icon>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <button type="button" class="add-stone-row" (click)="addRow()">+ ADD ITEM</button>
                  <button type="button" class="add-stone-row-shortcut" (click)="addRow()">(Shift + Enter)</button>
                </div>
              </div>
              <div class="modal-footer" style="justify-content: center;">
                <button type="button" class="add-stone-btn" (click)="saveStoneCharges(stoneCount)" >Save</button>
              </div>
              <!-- </div>
              </div>
            </div> -->
            </ng-template>
    
            <ng-template #metalPurity let-modal>
              <div class="metal_purity_modal">
                <div class="d-flex justify-content-between">
                  <div class="modal-body">
                    <div class="grid-container" style="align-items: start; justify-items: start;">
                      <div class="metals">
                        <mat-list>
                          <mat-list-item *ngFor="let mp of metals; let i = index" (click)="getSelectedMetal(i)">
                            {{ mp == 0 ? 'Gold' : mp == 2 ? 'Silver' : 'Platinum' }}
                          </mat-list-item>
                        </mat-list>
                      </div>
                      <div class="divider"></div>
                      <div class="purities" style="margin-left: 20px;">
                        <mat-radio-group aria-label="Select an option">
                          <mat-radio-button style="align-items: start; display: flex; flex-direction: column; margin-top: 20px;" 
                                            *ngFor="let p of metal_purity[activePurity]" 
                                            [value]="selected_purity?.toString()" 
                                            (change)="getSelectedPurity(p['id'])">
                              {{ p['name'] }}
                          </mat-radio-button>
                      </mat-radio-group>                  
                      </div>
                    </div>                       
                    <button type="button" class="save-est-btn" style="width: 95%; height:50px; margin-top: 20px; margin-bottom: 5px;" (click)="saveSelectedPurities()">Save</button>
                  </div>
                </div>
              </div>
            </ng-template>
    
            <ng-template #imageUpload let-modal style="margin-right:  5px;">
                  <!-- Modal Header -->
                  <div class="modal-header">
                    <h4 class="modal-title">Choose Image or Paste Link</h4>
                    <button type="button" class="close" (click)="modalService.dismissAll()">×</button>
                  </div>
              
                  <!-- Modal Body -->
                  <div class="modal-body">
                      <div class="form-group">
                        <label for="imageUpload">Upload Image:</label>
                        <input #fileInput type="file" (change)="onFileSelected($event)">
                      </div>
                      <div class="form-group">
                        <label for="imageUrl">Or Paste Image URL:</label>
                        <input type="text" class="form-control" id="imageUrl" [(ngModel)]="product_link" (change)="saveImageUrl(product_link)" placeholder="Enter URL">
                      </div>
                  </div>
              
                  <!-- Modal Footer -->
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)='modalService.dismissAll()'>Close</button>
                    <button type="button" class="btn btn-primary" (click)='onFileSelected($event)'>Save</button>
                  </div>
            </ng-template>
    
          </div>
    </div>
    </div>