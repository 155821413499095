import { Component,ViewChild } from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { TitleService } from 'src/app/services/title.service';
import { ViewVendorDataSource } from './view-vendor.dataSource';
import { ViewVendorService } from '../view-vendor.service';
import { PopUpManager } from 'src/app/manager/Popup';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from 'src/app/models/message';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Vendor } from 'src/app/models/vendors';

@Component({
  selector: 'app-view-vendor',
  templateUrl: './view-vendor.component.html',
  styleUrls: ['./view-vendor.component.css']
})
export class ViewVendorComponent {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private titleService:TitleService,private vendorService:ViewVendorService,private snackBar: MatSnackBar,private toastr:ToastrService,private modalService:NgbModal ){}

  dataSource!:ViewVendorDataSource;
  search = "";
  popupManager!: PopUpManager;
  responseMessage!:ResponseMessage;

  vendorData!:Vendor;
  vendord_id="";

  displayedColumns: string[] = [
    // 'checkbox',
    'name',
    'phone',
    'email',
    'code_name',
    'action'
  ];

  ngOnInit(): void {
    this.titleService.setTitle('Vendors/Goldsmiths');
    this.dataSource = new ViewVendorDataSource(this.vendorService);
    this.dataSource.loadData(0, this.search);
    this.popupManager = new PopUpManager(this.snackBar);
    const paginationState = localStorage.getItem('vendorPaginationState');
    if (paginationState) {
      const { pageIndex, pageSize } = JSON.parse(paginationState);
      this.paginator.pageIndex = pageIndex;
      this.paginator.pageSize = pageSize;
      console.log(this.paginator.pageIndex);
      console.log(this.paginator.pageSize);
    }

  }

  handlePage(event: any) {
    // Store pagination state in local storage whenever the page changes
    localStorage.setItem('vendorPaginationState', JSON.stringify({
      pageIndex: event.pageIndex,
      pageSize: event.pageSize
    }));
    console.log(event.pageIndex);
    // Your pagination logic here
  }

  searchOption() {
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)/;
    if (regex.test(this.search)) {
      this.search = this.search.replace(/\s+/g, '');
    }
  
    this.resetAndDisplayTable();
  }
  clearInput() {
  
    this.search = '';
  }

  openVendor(content:any){
    this.modalService.open(content, { centered: true,size:'lg' , modalDialogClass: 'vendor_modal', });
    setTimeout(() => {
      (<HTMLInputElement>document.getElementById('mobile_number')).focus();
    }, 100);
  }

  ngAfterViewInit(): void {
    this.dataSource.counter$.subscribe((count) => {
      this.paginator.length = count;
    });
    this.paginator.page.subscribe(() => {

      this.dataSource.loadData(
        this.paginator.pageIndex,
        this.search
      );
    });

  }

  resetAndDisplayTable() {
    this.popupManager.openSnackBar('Loading', 'cancel');
    this.paginator.pageIndex = 0;
    this.dataSource.loadData(0, this.search);
 }
 focus(id: string) {
  console.log(id);
  setTimeout(() => {
    const input = <HTMLInputElement>document.getElementById(id);
    input.focus();
  }, 100);

}

getVendorInfo(id:any,content:any){
  this.vendorService.getVendor(id).subscribe((data)=>{
    console.log(data);
    this.vendorData = data;
    this.modalService.open(content, { centered: true,size:'lg' , modalDialogClass: 'vendor_modal', });
    this.vendord_id = id;
    setTimeout(() => {
      (<HTMLInputElement>document.getElementById('full_name')).focus();
    }, 100);
  })
}

deleteVendorInfo(){
  this.vendorService.removeVendor(this.vendorData.id).subscribe((data)=>{
    // console.log(data);
    // this.vendorData = data;
    // this.modalService.open(content);
    // this.vendord_id = id;
    this.toastr.success('', "Successfully removed vendoer", {
      positionClass: 'toast-bottom-right',
    });
    this.resetAndDisplayTable();

  })
}

  createVendor(){
    console.log("add vendor");
    var code_name = (<HTMLInputElement>document.getElementById('code_name')).value;
    var full_name = (<HTMLInputElement>document.getElementById('full_name')).value;
    var mobile_no = (<HTMLInputElement>document.getElementById('mobile_number')).value;
    var email = (<HTMLInputElement>document.getElementById('vendor_email')).value;
    if(code_name==undefined || code_name=="" || code_name.trim().length === 0){
      this.toastr.info('', "Please Provide Valid Code Name", {
        positionClass: 'toast-bottom-right',
      });
    }else if(full_name==undefined || full_name=="" || full_name.trim().length === 0){
      this.toastr.info('', "Please Provide Valid Name", {
        positionClass: 'toast-bottom-right',
      });
    }else if(mobile_no==undefined || mobile_no=="" || mobile_no.trim().length === 0 || mobile_no.length!=10){
      console.log(mobile_no)
      this.toastr.info('', "Please Provide Valid Mobile Number", {
        positionClass: 'toast-bottom-right',
      });
    }else{
      var fd = new FormData();
      fd.append("code_name",code_name);
      fd.append("name",full_name);
      fd.append("mobile",mobile_no);
      fd.append("email",email);
      this.vendorService.postVendor(fd).subscribe(
        (data) => {
          this.responseMessage = data;
          this.toastr.success('', this.responseMessage.message, {
            positionClass: 'toast-bottom-right',
          });
          this.modalService.dismissAll();
          this.resetAndDisplayTable()
        },
        (error: any) => {
          this.toastr.info(
            '',
            error.error.message,
            { positionClass: 'toast-bottom-right' }
          );
        }
      );

    }


  }


  editVendor(){
    console.log("add vendor");
    var code_name = (<HTMLInputElement>document.getElementById('code_name')).value;
    var full_name = (<HTMLInputElement>document.getElementById('full_name')).value;
    var mobile_no = (<HTMLInputElement>document.getElementById('mobile_number')).value;
    var email = (<HTMLInputElement>document.getElementById('vendor_email')).value;
    if(code_name==undefined || code_name=="" || code_name.trim().length === 0){
      this.toastr.info('', "Please Provide Valid Code Name", {
        positionClass: 'toast-bottom-right',
      });
    }else if(full_name==undefined || full_name=="" || full_name.trim().length === 0){
      this.toastr.info('', "Please Provide Valid Name", {
        positionClass: 'toast-bottom-right',
      });
    }else if(mobile_no==undefined || mobile_no=="" || mobile_no.trim().length === 0 || mobile_no.length!=10){
      console.log(mobile_no)
      this.toastr.info('', "Please Provide Valid Mobile Number", {
        positionClass: 'toast-bottom-right',
      });
    }else{
      var fd = new FormData();
      fd.append("code_name",code_name);
      fd.append("name",full_name);
      fd.append("mobile",mobile_no);
      fd.append("email",email);
      this.vendorService.editVendor(fd,this.vendord_id).subscribe(
        (data) => {
          this.responseMessage = data;
          this.toastr.success('', this.responseMessage.message, {
            positionClass: 'toast-bottom-right',
          });
          this.modalService.dismissAll();
          this.resetAndDisplayTable()
        },
        (error: any) => {
          this.toastr.info(
            '',
            error.error.message,
            { positionClass: 'toast-bottom-right' }
          );
        }
      );

    }


  }

  preventEvent(event:any)
  {
    event.preventDefault();
  }

  openDeleteOption(element:Vendor,content:any){
    this.vendorData=element;

    this.modalService.open(content);

    

  }




}
