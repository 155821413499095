<div class="parent mt-4">
    <div class="header">
        Plan Details
    </div>
    <div class="content">
        <div class="details">
            <div class="title">Enter Plan ID</div>
            <div class="inp">
                <input #inputField class="form-control" type="text" style="color: #262626;"
                    placeholder="Enter Plan ID" >
                <primary-btn  [styles]="{'width':'150px'}">Verify</primary-btn>
            </div>
        </div>
    </div>
</div>
