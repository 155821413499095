import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appUppercaseNoSpecial]'
})
export class UppercaseNoSpecialDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue: string = event.target.value;

    // Remove special characters and spaces using regex
    const newValue: string = initialValue.replace(/[^A-Za-z0-9]/g, '').toUpperCase();

    // Update the input value with the modified string
    this.el.nativeElement.value = newValue;

    // Dispatch a new input event to reflect the changes
    this.el.nativeElement.dispatchEvent(new Event('input'));
  }

}
