import {  Component,  ElementRef,  HostListener,  ViewChild,  ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, fromEvent, switchMap } from 'rxjs';
import { Design, DesingModel } from 'src/app/models/design';
import { SearchUser } from 'src/app/models/search-user';
import { DesignService } from 'src/app/oms/view-order/design.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { ResponseMessage } from 'src/app/models/message';
@Component({
  selector: 'app-repair-list-mobile',
  templateUrl: './repair-list-mobile.component.html',
  styleUrls: ['./repair-list-mobile.component.css']
})
export class RepairListMobileComponent {

  constructor(private modalSerive:NgbModal,
    private ExposeFunction:MessagingService,
    private router:Router,
    private OrderService:DesignService,
    private toastr:ToastrService,
    private route: ActivatedRoute,
    
    ){
      this.inputSubject
      .pipe(
        debounceTime(800), // Adjust the time as needed (in milliseconds)
      )
      .subscribe(response => {
        // Handle the API response here
        this.searchOrder=response;
        this.getOrders();
      });
    }
    uploaded_designs:Design[]=[];
    designModel:any=DesingModel;
    searchUsers:SearchUser[]=[];
    showBackToTopButton = false;
    scrollPosition:any;
    @ViewChild('page', { static: true }) _div?: ElementRef;

  order_count:number=0;
  private inputSubject = new Subject<string>();
  dropdownOptions = [
    { value: 0, label: 'Unassigned', color: '#F03738',background:"rgba(240, 55, 56, 0.12)" },
    { value: 1, label: 'Assigned', color: '#3268FB' ,background:"rgba(50, 104, 251, 0.15)"},
    { value: 2, label: 'Ready', color: '#E9B640' , background:"rgba(255, 197, 62, 0.12)"},
    { value: 3, label: 'Delivered', color: '#288127' , background:"rgba(40, 129, 39, 0.12)"},
    { value: 4, label: 'Cancelled', color: '#082D92',  background:"rgba(40, 129, 39, 0.12)"},
  ];
  custom_item_id!:any;
  files: File[] = [];
  orders:any=[]
  mergerd_orders:any=[];
  deleteResponse: any=ResponseMessage;


  search:string="";
  searchOrder:string="";
  canLoadMoreOrders=false;
  page_index=1;
  filter_page_index=1;
  filter_status_toggle:boolean=true;

  vendor_list:{ name: string, id: number, state: boolean }[] = [];
  filter_list:{ name: string, value: string, state: boolean }[] = [
    {name:"Unassigned",value:"0",state:true},
    {name:"Assigned",value:"1",state:true},
    {name:"Ready",value:"2",state:true},
    {name:"Completed",value:"3",state:false},
    {name:"Cancelled",value:"4",state:false},
  ];
  is_all_vendor:boolean=false;
  is_all_filter:boolean=false;

  filter_values:any=["0","1","2"];
  vendors_values:any=[];

  selected_design?:Design;

  @ViewChildren('checkBoxRefVendors') checkboxesVendors!: any;

  selectImage(Design:Design)
  {
    this.selected_design=Design
  }
  generateRandomNegative(): number {
    return -1 * Math.random();
}
  onSelect2(event: any,type:any) {
    var selectedFile:any="";
    if(type==0)
    {
      selectedFile= event.target.files[0];
    }
    if (type==1)
    {
      selectedFile=event.addedFiles[0];
    }
    const counter=0;
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        // Assign the result of FileReader to selectedImage
        const selectedImage = reader.result as string;
        
        // Create a dummy design object with the selected image and other properties
        const dummyDesign = { id: String(this.generateRandomNegative()), image: selectedImage, size: selectedFile.size, name: selectedFile.name };
        
        // Add the dummy design to the uploaded_designs array
        this.uploaded_designs.push(dummyDesign);
        this.selected_design= dummyDesign;

      };
      // Read the selected file as a data URL
      reader.readAsDataURL(selectedFile);
      this.files.push(selectedFile);
    }
    
  }
  
  searchVendor(event:any){
    console.log("wokg")
    var search=event.value;
    var custom_id=this.custom_item_id;
    if (event=='')
    {
      console.log(this.custom_item_id)
      this.OrderService.getRepairVendors(custom_id,'').subscribe((data)=>{
        this.searchUsers = data.response;
        console.log(this.searchUsers);
      })
    }
    else{
      if(search.length >=2){
        this.OrderService.getRepairVendors(this.custom_item_id,search).subscribe((data)=>{
          this.searchUsers = data.response;
        })
      }
    }
    console.log(this.searchUsers);

    this.custom_item_id=custom_id;

  }
  @HostListener('document:wheel', ['$event.target'])
  @HostListener('window:touchmove', ['$event'])
  onWindowScroll() {
    if(this.scrollPosition>300)
    {
      this.showBackToTopButton = true;
    }
    else{
      this.showBackToTopButton = false;
    }
  }
  backToTop()
  {
    this.scrollPosition=0;
    (this._div?.nativeElement as HTMLElement).scrollTop = this.scrollPosition;
    this.showBackToTopButton = false;
  }
  getDesign(){
    this.OrderService.getRepairDesigns(this.custom_item_id).subscribe((data)=>{
      console.log(data);
      this.designModel=data;
      this.uploaded_designs = this.designModel.designs;
      this.selected_design=this.uploaded_designs[0];
      


     (<HTMLInputElement>document.getElementById('size')).value=this.designModel.size;
    (<HTMLInputElement>document.getElementById('pieces')).value=this.designModel.pieces;
   (<HTMLInputElement>document.getElementById('notes')).value=this.designModel.order_notes;
   (<HTMLInputElement>document.getElementById('design_weight')).value=this.designModel.weight;
   (<HTMLInputElement>document.getElementById('purity')).value=this.designModel.metal_purity;
   (<HTMLInputElement>document.getElementById('design_order_id')).value=this.designModel.order_id;

    })
  }
  removeDesign(id:any){
    console.log(id);
    if (Number(id.id)<0)
    {
      
      this.uploaded_designs.splice(this.uploaded_designs.indexOf(id), 1);
      
      if(this.uploaded_designs.length==0)
      {
        this.selected_design=undefined;
        this.files=[];
      }
      return

    }
    var fd = new FormData();
    fd.append("id",id);
    fd.append("item_id",this.custom_item_id);
    this.OrderService.deleteRepairDesigns(fd).subscribe((data)=>{
      console.log(data);
      this.getDesign();
      this.toastr.success('','Item Removed Successfully', {positionClass: 'toast-bottom-right',});
    })

  }
  createVendorFunction(){
    var code_name = (<HTMLInputElement>document.getElementById('code_name')).value;
    var full_name = (<HTMLInputElement>document.getElementById('full_name')).value;
    var mobile_no = (<HTMLInputElement>document.getElementById('mobile_number')).value;
    var email = (<HTMLInputElement>document.getElementById('vendor_email')).value;
    if(code_name==undefined || code_name=="" || code_name.trim().length === 0){
      this.toastr.info('', "Please Provide Valid Code Name", {
        positionClass: 'toast-bottom-right',
      });
    }else if(full_name==undefined || full_name=="" || full_name.trim().length === 0){
      this.toastr.info('', "Please Provide Valid Name", {
        positionClass: 'toast-bottom-right',
      });
    }else if(mobile_no==undefined || mobile_no=="" || mobile_no.trim().length === 0 || mobile_no.length!=10){
      console.log(mobile_no)
      this.toastr.info('', "Please Provide Valid Mobile Number", {
        positionClass: 'toast-bottom-right',
      });
    }else{
      var fd = new FormData();
      fd.append("item_id",this.custom_item_id)
      fd.append("code_name",code_name);
      fd.append("name",full_name);
      fd.append("mobile",mobile_no);
      fd.append("email",email);
      this.OrderService.postRepairVendor(fd).subscribe(
        (data:any) => {
          this.toastr.success('', data.message, {
            positionClass: 'toast-bottom-right',
          });
          this.getOrders();
        },
        (error: any) => {
          this.toastr.info(
            '',
            error.error.message,
            { positionClass: 'toast-bottom-right' }
          );
        }
      );

    }


  }

  clearSeachInput()
  {
    this.searchOrder="";
  }
  addDesignModal(content:any,row:any)
  {
    this.custom_item_id = row.id;
    this.getDesign();
    this.modalSerive.open(content,
      {
        size: 'fullscreen', // Use 'fullscreen' to make it full page
        centered: true,     // Center the modal vertically and horizontally
      });
  }
  openDesignModal(content:any)
  {
    this.modalSerive.open(
      content,{
        size:'fullscreen',
        centered:true
      }
    )
  }
  handleStatus(value:any,row:any)
  {
    if (value.value==3)
    {
      // when order is in deliverd state
    }
    row.status=value.value;
    var fd = new FormData();
    fd.append("id",row.id);
    fd.append("status",row.status);
    console.log("status",row.status);
    this.OrderService.changeStatusOfRepair(fd).subscribe((data)=>
    {
      console.log(data);
      this.getOrders()
    },(error:any)=>
    {
      console.log(error)
    })

  }
  ngOnInit()
  {
    if (this.route.snapshot.paramMap.get('is_unassigned')) {
      this.filter_values = ["0"];
      this.filter_list.forEach((filter) => {
        if (filter.value === "0") {
          filter.state = true;
        } else {
          filter.state = false;
        }
      });
    }
    this.getVendors();
  }

  ngAfterViewInit() {
    this.getOrders();
    
      fromEvent(this._div?.nativeElement, 'scroll').subscribe((e: any) =>  {
          this.scrollPosition = e?.target['scrollTop'];
             });
   
  }
  sendMessageToFlutter()
  {
    this.ExposeFunction.exposeFunction('getContact')

  }

  switchToOrderDetailPage(order_id:any)
  {
    this.router.navigate(['/dashboard/view_repair_details_mobile/',{id:order_id}])
  }
  getImageSize(size:number){
    return size/(1024*1024);
  }

  postDesign(){
    this.toastr.info('', "Please wait until the data is saved", {positionClass: 'toast-bottom-right',});
    var size=(<HTMLInputElement>document.getElementById('size')).value;
    var piece=(<HTMLInputElement>document.getElementById('pieces')).value;
    var notes=(<HTMLInputElement>document.getElementById('notes')).value;

    for(let i=0;i<this.files.length;i++){

      console.log(this.files[i]);
        if(this.getImageSize(this.files[i].size) > 5){
          this.toastr.info('', this.files[i].name+" is not uploaded due to size greater than 5 mb.", {
            positionClass: 'toast-bottom-right',
          });
        }else if(this.uploaded_designs.length != 5){

        console.log("comes in ");
        var fd=new FormData();
        fd.append("id",this.custom_item_id);
        fd.append("size",size);
        fd.append("piece",piece);
        fd.append("note",notes);
        fd.append("image",this.files[i]);

        this.OrderService.postRepairDesign(fd).subscribe(
          percent => {

          },
          error => {
            // Handle any errors that occur during the upload
            console.error('Upload Error:', error);
          },
          () => {
            this.toastr.success('', "Data Saved successfully", {
              positionClass: 'toast-bottom-right',
            }

            )
            ;
            this.getOrders();
          }
        );

        }else{
          this.toastr.info('', "Only 5 images can be uploaded.", {
            positionClass: 'toast-bottom-right',
          });
        }
    }
    if (this.files.length==0)
    {
      var fd=new FormData();
      fd.append("id",this.custom_item_id);
      fd.append("size",size);
      fd.append("piece",piece);
      fd.append("note",notes);
      this.OrderService.postRepairDesign(fd).subscribe(
        percent => {

        },
        error => {
          // Handle any errors that occur during the upload
          console.error('Upload Error:', error);
        },
        () => {
          this.toastr.success('', "Data Saved successfully", {
            positionClass: 'toast-bottom-right',
          })
          this.getOrders();

          // this.getDesign();
          // this.modalService.dismissAll();

        }
      );

    }
    this.files = [];
  }

  addVendorModal(content:any,id:any)
  {
    this.custom_item_id=id;
    this.modalSerive.open(content,
      {
        size:'fullscreen',
        centered:true,
        windowClass: 'add_vendor_custom_class'
      })

  }
  menuItemClicked(event:any,type:any,item_id:any,user_id:any) {
    console.log("this is clicked");
    event.stopPropagation();

    if(type=="search"){
    event.stopPropagation();


    }else if(type=="assign"){
      this.assignVendor(item_id,user_id);

    }else if(type=="remove"){
      this.removeVendor(item_id,user_id);
    }
  }
 
  assignVendor(custom_id:any,vendor_id:any){
    console.log("called assing");
    this.custom_item_id = custom_id;
    var fd = new FormData();
    fd.append("item_id",this.custom_item_id);
    fd.append("vendor_id",vendor_id);
    this.OrderService.assignRepairVendor(fd).subscribe(
      (data:any) => {
        this.toastr.success('', data.message, {
          positionClass: 'toast-bottom-right',
        });
        // this.modalService.dismissAll();
        // this.resetAndDisplayTable()
        this.searchUsers.map((item)=>
        {
          if(item.id==vendor_id)
          {
            item.selected=true;
          }
        })
        this.modalSerive.dismissAll();
        this.getOrders();
      },
      (error: any) => {

        this.toastr.info(
          '',
          error.error.message,
          { positionClass: 'toast-bottom-right' }
        );
      }
    )



  }
  removeVendor(custom_id:any,vendor_id:any){
    console.log("called remove");
    this.custom_item_id = custom_id;
    var fd = new FormData();
    fd.append("item_id",this.custom_item_id);
    fd.append("vendor_id",vendor_id);
    this.OrderService.removeRepairVendor(fd).subscribe(
      (data) => {
          
        // this.responseMessage = data;
        this.toastr.success('', data.message, {
          positionClass: 'toast-bottom-right',
        });
        // this.modalService.dismissAll();
        // this.ngOnInit();
       this.searchUsers.map((item)=>
        {if(item.id==vendor_id)
          {
            item.selected=false;
          }
          })
          this.getOrders();
      },
      (error: any) => {
   
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    )


    
  }
  createVendorModal(content:any)
  {
    this.modalSerive.open(content,
      {
        size:'fullscreen',
        centered:true,
        windowClass: 'add_vendor_custom_class'
      })
  }
  addingImage(event:any) {
    this.files.push(...event.addedFiles);
  }
  removeLocalFile(event:any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  getOrders()
  {
    this.canLoadMoreOrders = false; // Prevent further loading

    this.OrderService.getRepairOrdersMobile(this.page_index,this.searchOrder,this.filter_values,this.vendors_values).subscribe((data:any)=>
    {
      console.log(data)
      if(this.page_index==1)
      {
        this.orders=data.results;
      }
      else{
        this.orders=this.orders.concat(data.results);
      }
      this.order_count=data.count;
      this.vendor_list=this.vendor_list.map((vendor:any) => ({ ...vendor, state: false }));
      this.canLoadMoreOrders = true; // Prevent further loading

    })
  }
  filterApply()
  {
    this.canLoadMoreOrders=false;
    this.filter_values.splice(0, this.filter_values.length, ...[...new Set(this.filter_values)]);

    this.OrderService.getCustomOrdersMobile(this.filter_page_index,this.search,this.filter_values,this.vendors_values).subscribe((data:any)=>
    {
      if(this.filter_page_index==1)
      {
        this.orders=data.results;
      }
      else{
        this.orders=this.orders.concat(data.results);
      }
      this.order_count=data.count;
      this.canLoadMoreOrders=true;
    })
    
  }
  toggleFilter()
  {
    this.filter_status_toggle=!this.filter_status_toggle
  }
  getVendors()
  {
    this.OrderService.getVendorsForFilter().subscribe((data:any)=>
    {
      this.vendor_list=data.vendors;
      this.vendor_list = this.vendor_list.map((vendor) => ({ ...vendor, state: false }));
    })
  }
  storeFilterValues(event:any,filter:any)
  { 
    if (event.target.checked)
    { 
      filter.state=true;
      this.filter_values.push(filter.value);}
    else 
    {
      filter.state=false;
      this.filter_values.splice(this.filter_values.indexOf(filter.value),1);}
  }
  storeVendorFilterValues(event:any,vendor:any)
  {
    if (event.target.checked)
    { 
      vendor.state=true;
      this.vendors_values.push(vendor.value);}
    else 
    {
      vendor.state=false;
      this.vendors_values.splice(this.vendors_values.indexOf(vendor.value),1);}
  }

  checkVendorsAllFilters(event:any)
  {
    if (event.target.checked)
      {
        this.is_all_vendor=true;
        this.vendor_list.forEach((vendor:any)=>
        {vendor.state=true,this.vendors_values.push(vendor.value)});
      }
      else{
        this.is_all_vendor=false;
       this.vendor_list.forEach((vendor:any)=>{vendor.state=false,
        this.vendors_values.pop(vendor.value)})
      }
  }
  checkAllFilters(event:any)
    {
      if (event.target.checked)
      {
        this.is_all_filter=true;
        this.filter_list.forEach((filter:any)=>
        {filter.state=true;this.filter_values.push(filter.value)});
      }
      else{
        this.is_all_filter=false;
        this.filter_list.forEach((filter:any)=>
        {filter.state=false;this.filter_values.pop(filter.value)});
      }
     
    }
    clearAllFilter()
    {
      this.filter_list.forEach((filter:any)=>
      {
        filter.state=false;
      })
      this.vendor_list.forEach((vendor:any)=>
      {
        vendor.state=false;
      })
      this.filter_values=[];
      this.vendors_values=[];
      this.is_all_filter=false;
      this.is_all_vendor=false;
    }
       loadNextOrders()
      {
        if(this.filter_list.length!=0 && this.canLoadMoreOrders)
        {
          this.filter_page_index+=1;
          return this.filterApply();
        }
        if (this.canLoadMoreOrders)
        {
          this.page_index+=1;
           this.getOrders();
        }
        
      }
       
    
    // @HostListener('window:scroll', ['$event'])
    // @HostListener('window:touchmove', ['$event'])
    // onWindowScroll(event:any) {
    //   console.log(event);
    //   console.log("working")
    //   console.log(window)
    //   if (window.scrollY > 100) { // Show button when scrolling down
    //     this.showBackToTopButton = true;
    //   } else { // Hide button when scrolling up
    //     this.showBackToTopButton = false;
    //   }
    // }


}
