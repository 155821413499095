import { Location, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from 'src/app/models/message';
import { SettlementServiceService } from '../reports/settlement-service.service';
import { OrderDataSource } from './all-reports-dataSource';
import * as moment from 'moment';
import { PopUpManager } from 'src/app/manager/Popup';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateAdapter } from '@angular/material/core';
import { MatDateFormats } from '@angular/material/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatSelect } from '@angular/material/select';
import { TitleService } from 'src/app/services/title.service';
import { ActionService } from 'src/app/services/actions.service';
import { BookingDataSource } from 'src/app/rate_cut_feature/bookings/bookings_dataSource';
import { RateCutService } from 'src/app/rate_cut_feature/ratecut/rate-cut.service';
import { CancelOrderDataSource } from './cancel-order-dataSource';
import { CancelPlanService } from 'src/app/cancel-plan/cancel-plan.service';
import { AdvanceOrderDataSource } from './advance-booking-dataSource';
import { CustomOrderDataSource } from './custom-order-dataSource';
import { CreateOrderService } from 'src/app/oms/create-order/create-order.service';
import { DigitalCoinDataSource } from './digital-coin-dataSource';
import { DigitalCoinService } from 'src/app/digital-coin/services/digital-coin.service';
import { RepairOrderDataSource } from './repair-order-dataSource';

export interface ReportCategories {
  SAVINGSCHEME: boolean;
  ADVANCEBOOKING: boolean;
  CANCELLATIONS: boolean;
}

@Component({
  selector: 'app-all-reports',
  templateUrl: './all-reports.component.html',
  styleUrls: ['./all-reports.component.css'],
})
export class AllReportsComponent implements OnInit {
  // displayedColumns: string[] = ['plan_id','installments','plan_name','customer_name','phone','payment_date','amount','rate','weight','payment_mode','payment_id','commission','gst','settlement_amount','settlement_id','settlement_date'];

  current_category!: ReportCategories;
  dropdownSettings: IDropdownSettings = {};
  savingsDisplayedColumns: string[] = [
    'payment_date',
    'plan_id',
    'customer_name',
    'phone',
    'installments',
    'amount',
    'payment_mode',
    'payment_id',
    'settlement_id',
    'settlement_date',

    // 'rate',
    // 'weight',
    // 'plan_name',
  ];
  bookingDisplayedColumns: string[] = [
    'date',
    'booking_id',
    'customer_name',
    'phone',
    'weight',
    'advance_paid',
    'payment_mode',
    'payment_id',
    'settlement_id',
    'settlement_date',
  ];

  cancelDisplayedColumns: string[] = [
    'date',
    'transaction_id',
    'transaction_type',
    'customer_name',
    'phone',
    'cancellation_id',
    'refund_amount',
    'refund_mode',
    'refund_id',
    'refund_ac',
    'refund_date',
  ];

  customOrderDisplayedColumns:string[]= [
    'date',
    'order_id',
    'customer_name',
    'phone',
    'old_gold_wt',
    'amount',
    'payment_mode',
    'payment_id',
    'settlement_id',
    'settlement_date'
  ]
  repairOrderDisplayedColumns:string[]= [
    'date',
    'repair_id',
    'customer_name',
    'phone',
    'weight',
    'amount',
    'payment_mode',
    'payment_id',
    'settlement_id',
    'settlement_date'
  ]

  digitalCoinDisplayedColumns:string[]= [
    'date',
    'booking_id',
    'customer_name',
    'phone',
    'weight',
    'amount',
    'payment_mode',
    'payment_id',
    'settlement_id',
    'settlement_date'
  ]

  @ViewChild('savings_paginator') savings_paginator!: MatPaginator;
  @ViewChild('bookings_paginator') bookings_paginator!: MatPaginator;
  @ViewChild('cancel_paginator') cancel_paginator!: MatPaginator;
  @ViewChild('custom_order_paginator') custom_order_paginator!:MatPaginator;
  @ViewChild('repair_order_paginator') repair_order_paginator!:MatPaginator;
  @ViewChild('digital_coin_paginator') digital_coin_paginator!:MatPaginator;

  savingsDataSource!: OrderDataSource;
  bookingDataSource!: AdvanceOrderDataSource;
  customOrderDataSource!:CustomOrderDataSource;
  repairOrderDataSource!:RepairOrderDataSource;
  digitalCoinDataSource!: DigitalCoinDataSource;
  cancelDataSource!: CancelOrderDataSource;
  responseMessage!: ResponseMessage;
  @ViewChild('singleSelect') singleSelect!: MatSelect;
  popupManager!: PopUpManager;
  current_date = new Date();
  from_date: string = '';
  to_date: string = '';
  ordering: string = 'id';
  search = '';
  sort!: MatSort;
  email = '';
  filterValue = '';
  download_from='';
  download_to='';

  advance_booking_datasource!:any;
  allowed!: any;

  advance_booking_table = false;
  saving_table = true;

  report_cat: any[] = [
    { name: 'Jewellery Plans', state: true },
    { name: 'Advance Bookings', state: false },
    {name :'New Orders',state:false},
    {name :'Digital Coin',state:false},
    {name :'Repairs',state : false},
    { name: 'Cancellations', state: false },
  ];

  selected_table: any;

  disable_other_filters!: boolean;

  f_date: string = '';
  t_date: string = '';

  disable_online_option!: boolean;
  count = 0;
  current_date_validation!: string;
  fromDate:any=""
  toDate:any=""

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private settlementService: SettlementServiceService,
    private router: Router,
    private _location: Location,
    private toastr: ToastrService,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    private titleService: TitleService,
    private actionService: ActionService,
    private bookingService: RateCutService,
    private omsService : CreateOrderService,
    private digiService: DigitalCoinService,
    private cancelService: CancelPlanService,
    private cd: ChangeDetectorRef
  ) {
    this.dateAdapter.setLocale('en-IN');
  }

  ngOnInit(): void {
    this.selected_table = this.report_cat[0]; //saving scheme will be first table
    this.titleService.setTitle('Reports');
    this.allowed = this.actionService.getActions();

    //savings Data Source
    this.savingsDataSource = new OrderDataSource(this.settlementService);
    this.savingsDataSource.loadData(
      0,
      this.from_date,
      this.to_date,
      this.search,
      this.filterValue
    );

    // booking data source

    this.bookingDataSource = new AdvanceOrderDataSource(this.bookingService);
    this.bookingDataSource.loadData(
      0,
      this.from_date,
      this.to_date,
      this.search,
      this.filterValue
    );

      console.log(this.bookingDataSource)

    this.customOrderDataSource = new CustomOrderDataSource(this.omsService);
    this.customOrderDataSource.loadData(
      0,
      this.from_date,
      this.to_date,
      this.search,
      this.filterValue
    )
      // Repair Data Source
      this.repairOrderDataSource = new RepairOrderDataSource(this.omsService);
      this.repairOrderDataSource.loadData(
        0,
        this.from_date,
        this.to_date,
        this.search,
        this.filterValue
      )

    // Digital Coin Data Source
    this.digitalCoinDataSource = new DigitalCoinDataSource(this.digiService);
    this.digitalCoinDataSource.loadData(
      0,
      this.from_date,
      this.to_date,
      this.search,
      this.filterValue
    );
    //cancel data Source
    this.cancelDataSource = new CancelOrderDataSource(this.cancelService);
    this.cancelDataSource.loadData(
      0,
      this.from_date,
      this.to_date,
      this.search,
      this.filterValue
    );
    this.popupManager = new PopUpManager(this.snackBar);
    this.current_date_validation=this.getCurrentDate()
  }
  back() {
    this._location.back();
  }
  transformString(inputString: string): string {
    // Regular expressions for matching and replacing the patterns
    const regexNetBanking = /Net Banking - (.*)/;
    const regexStoreUpi = /Store Upi - (.*)/;
    const regexDebitCard = /Debit Card - (.*)/;

    // Perform replacements using capturing groups
    if (regexNetBanking.test(inputString)) {
      return inputString.replace(regexNetBanking, 'RTGS/NEFT - $1');
    } else if (regexStoreUpi.test(inputString)) {
      return inputString.replace(regexStoreUpi, 'UPI/IMPS - $1');
    } else if (regexDebitCard.test(inputString)) {
      return inputString.replace(regexDebitCard, 'Card - $1');
    } else {
      return inputString; // Return the original string if it doesn't match any of the specified cases
    }
  }


  getCurrentDate(): string {
    const today = new Date();
    let day: string = String(today.getDate());
    let month: string = String(today.getMonth() + 1);
    const year: string = String(today.getFullYear());

    if (day.length < 2) {
      day = '0' + day;
    }

    if (month.length < 2) {
      month = '0' + month;
    }
    return `${year}-${month}-${day}`;
  }


  clearInput() {
    this.search = '';
  }
  handleTable(table: any) {
    this.fromDate = '';
    this.toDate='';
    if (this.selected_table == table) {
      return;
    }
    for (let i = 0; i < this.report_cat.length; i++) {
      console.log('working');
      if (this.report_cat[i] == this.selected_table) {
        this.report_cat[i].state = false;
        console.log('working');
      }
    }

    table.state = true;
    this.selected_table = table;
    this.filterValue = "";
    this.singleSelect.close();
    this.disable_online_option=false
    this.disable_other_filters=false
    this.singleSelect.value=""
    this.resetAndDisplayTable();

    this.ngAfterViewInit();
    console.log(this.cancel_paginator.length);
  }
  searchOption() {
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)/;
    if (regex.test(this.search)) {
      this.search = this.search.replace(/\s+/g, '');
    }

    this.resetAndDisplayTable();
  }

  onlineActive(value: any) {
    this.disable_other_filters = value;
  }

  onlineDisable(value: any) {
    if (value == true) {
      this.count += 1;
      this.disable_online_option = value;
    } else if (!value && this.disable_online_option == true) {
      if (this.count == 1) {
        this.disable_online_option = false;
        this.disable_other_filters = false;
      }

      this.count -= 1;
    }
  }

  getFromDate(event: any): void {
    this.from_date = moment(event).format('DD-MM-YYYY');
    this.resetAndDisplayTable();
  }

  getToDate(event: any) {
    this.popupManager.openSnackBar('Loading ...', 'close');
    this.to_date = moment(event).format('DD-MM-YYYY');
    setTimeout(() => {
      (<HTMLInputElement>document.getElementById('fdate'))!.value =
        this.from_date;
      (<HTMLInputElement>document.getElementById('tdate'))!.value =
        this.to_date;
    }, 1000);

    this.resetAndDisplayTable();
  }

  ngAfterViewInit(): void {
    console.log(this.report_cat[0].state);
    if (this.report_cat[0].state) {
      console.log('value', this.report_cat[0]);
      this.savingsDataSource.counter$.subscribe((count) => {
        this.savings_paginator.length = count;
      });

      this.savings_paginator.page.subscribe(() => {
        this.savingsDataSource.loadData(
          this.savings_paginator.pageIndex,
          this.from_date,
          this.to_date,
          this.search,
          this.filterValue
        );
        this.popupManager.openSnackBar('Loading ...', 'close');
      });
    }
    if (this.report_cat[1].state) {
      console.log(this.filterValue);
      this.bookingDataSource.counter$.subscribe((count) => {
        this.bookings_paginator.length = count;
        console.log(this.bookings_paginator.length);
        console.log(this.bookingDataSource);
      });
      this.bookings_paginator.page.subscribe(() => {
        this.bookingDataSource.loadData(
          this.bookings_paginator.pageIndex,
          this.from_date,
          this.to_date,
          this.search,
          this.filterValue
        );
        this.popupManager.openSnackBar('Loading ...', 'close');
      });
    }
    if (this.report_cat[2].state) {
      this.customOrderDataSource.counter$.subscribe((count) => {
        this.custom_order_paginator.length = count;
        console.log('length', this.cancel_paginator.length);
      });
      this.custom_order_paginator.page.subscribe(() => {
        this.customOrderDataSource.loadData(
          this.custom_order_paginator.pageIndex,
          this.from_date,
          this.to_date,
          this.search,
          this.filterValue
        );
        this.popupManager.openSnackBar('Loading ...', 'close');
      });
    }
    if (this.report_cat[3].state) {
      this.digitalCoinDataSource.counter$.subscribe((count) => {
        this.digital_coin_paginator.length = count;
      });
      this.digital_coin_paginator.page.subscribe(() => {
        this.digitalCoinDataSource.loadData(
          this.digital_coin_paginator.pageIndex,
          this.from_date,
          this.to_date,
          this.search,
          this.filterValue
        );
        this.popupManager.openSnackBar('Loading ...', 'close');
      });
    }

    if (this.report_cat[5].state) {
      this.cancelDataSource.counter$.subscribe((count) => {
        this.cancel_paginator.length = count;
        console.log('length', this.cancel_paginator.length);
      });
      this.cancel_paginator.page.subscribe(() => {
        this.cancelDataSource.loadData(
          this.cancel_paginator.pageIndex,
          this.from_date,
          this.to_date,
          this.search,
          this.filterValue
        );
        this.popupManager.openSnackBar('Loading ...', 'close');
      });
    }
    if (this.report_cat[4].state) {
      this.repairOrderDataSource.counter$.subscribe((count) => {
        this.repair_order_paginator.length = count;
      });
      this.repair_order_paginator.page.subscribe(() => {
        this.repairOrderDataSource.loadData(
          this.repair_order_paginator.pageIndex,
          this.from_date,
          this.to_date,
          this.search,
          this.filterValue
        );
        this.popupManager.openSnackBar('Loading ...', 'close');
      });
    }
  }

  sortbyId() {
    if (this.sort.direction == 'asc') {
      this.ordering = 'id';
      this.resetAndDisplayTable();
    }
    if (this.sort.direction == 'desc') {
      this.ordering = '-id';
      this.resetAndDisplayTable();
    }
  }

  resetAndDisplayTable() {
    if (this.report_cat[0].state) {
      this.savings_paginator.pageIndex = 0;

      this.savingsDataSource.loadData(
        0,
        this.from_date,
        this.to_date,
        this.search,
        this.filterValue
      );
    }

    if (this.report_cat[1].state) {
      this.bookings_paginator.pageIndex = 0;
      this.bookingDataSource.loadData(
        0,
        this.from_date,
        this.to_date,
        this.search,
        this.filterValue
      );
    }

    if (this.report_cat[2].state) {
      this.custom_order_paginator.pageIndex = 0;
      this.customOrderDataSource.loadData(
        0,
        this.from_date,
        this.to_date,
        this.search,
        this.filterValue
      );
    }
   

    if (this.report_cat[3].state) {
      this.digital_coin_paginator.pageIndex = 0;
      this.digitalCoinDataSource.loadData(
        0,
        this.from_date,
        this.to_date,
        this.search,
        this.filterValue
      );
    }

    if (this.report_cat[4].state) {
      this.cancel_paginator.pageIndex = 0;
      this.cancelDataSource.loadData(
        0,
        this.from_date,
        this.to_date,
        this.search,
        this.filterValue
      );
    }
    if (this.report_cat[5].state) {
      this.repair_order_paginator.pageIndex = 0;
      this.repairOrderDataSource.loadData(
        0,
        this.from_date,
        this.to_date,
        this.search,
        this.filterValue
      );
    }
  }

  searchData() {
    this.search = (<HTMLInputElement>document.getElementById('search')).value;
    this.popupManager.openSnackBar('Loading ...', 'close');
    this.resetAndDisplayTable();
  }


  generateCsvData(data: any[], headers: string[]): string {
    let csvData = '';

    // Add headers to the CSV data
    csvData += headers.join(',') + '\n';

    // Iterate over the data and add it to the CSV string
    data.forEach((row: any) => {
        headers.forEach((header: string, index: number) => {
            // Ensure that the value is properly escaped
            let value = row[header];
            if (typeof value === 'string') {
                value = `"${value.replace(/"/g, '""')}"`;
            }
            csvData += (index > 0 ? ',' : '') + value;
        });
        csvData += '\n';
    });

    return csvData;
}


  
  // Download the CSV data
  downloadCsvData(data:any,file_name:any) {
    // Get the three months of data
   
    const headers = Object.keys(data[0]);

    // Generate the CSV data
    const csvData = this.generateCsvData(data,headers);
  
    // Create a Blob object from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });
  
    // Create a URL object from the Blob object
    const url = URL.createObjectURL(blob);
  
    // Create a new anchor element and set its href attribute to the URL object
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = file_name;
  
    // Click the anchor element to start the download
    anchorElement.click();
  }


  downloadReport() {

    if (this.f_date == '') {
      this.toastr.info('', 'Please do select from date');
      return;
    } else if (this.t_date == '') {
      this.toastr.info('', 'Please do select to date');
      return;
    } 


    const fromDate = moment(this.download_from);
    const toDate = moment(this.download_to);
    const differenceInDays = toDate.diff(fromDate, 'days');

    if(differenceInDays>90){
      this.toastr.info(
        '',
        'You can download only 90 days of data.please do select from and to date within 90 days.',
        { positionClass: 'toast-bottom-right' }
      );
      return;
    }

    this.modalService.dismissAll();
    this.toastr.info(
      '',
      'Downloading...',
      { positionClass: 'toast-bottom-right' }
    );

    if (this.report_cat[0].state)
    {
      this.settlementService
      .downloadAllOrders(this.f_date, this.t_date, this.email)
      .subscribe(
        (data:any) => {
          var final_data=data['data'];

          this.responseMessage = data;

          if(final_data.length==0){
            this.toastr.info(
              '',
              'No data found in the selected duration.',
              { positionClass: 'toast-bottom-right' }
            );
          }else{
            this.downloadCsvData(data['data'],"orders.csv");
            this.toastr.success('', this.responseMessage.message, {
              positionClass: 'toast-bottom-right',
            });
          }
       
        },
        (error: any) => {
          this.toastr.info(
            '',
            'Something went wrong, please do try again later.',
            { positionClass: 'toast-bottom-right' }
          );
        }
      );
    }
    if (this.report_cat[1].state)
    {
      this.settlementService
      .downloadAllAdvanceOrders(this.f_date, this.t_date, this.email)
      .subscribe(
        (data:any) => {

          var final_data=data['data'];

          this.responseMessage = data;

          if(final_data.length==0){
            this.toastr.info(
              '',
              'No data found in the selected duration.',
              { positionClass: 'toast-bottom-right' }
            );
          }else{
            this.downloadCsvData(data['data'],"bookings.csv");
            this.toastr.success('', this.responseMessage.message, {
              positionClass: 'toast-bottom-right',
            });
          }
          
        },
        (error: any) => {
          this.toastr.info(
            '',
            'Something went wrong, please do try again later.',
            { positionClass: 'toast-bottom-right' }
          );
        }
      );
    }
    if (this.report_cat[2].state)
    {
      this.settlementService
      .downloadAllOMSOrders(this.f_date, this.t_date, this.email)
      .subscribe(
        (data:any) => {
          var final_data=data['data'];

          this.responseMessage = data;

          if(final_data.length==0){
            this.toastr.info(
              '',
              'No data found in the selected duration.',
              { positionClass: 'toast-bottom-right' }
            );
          }else{
            this.downloadCsvData(data['data'],"oms.csv");
            this.toastr.success('', this.responseMessage.message, {
              positionClass: 'toast-bottom-right',
            });
          }
         
        },
        (error: any) => {
          this.toastr.info(
            '',
            'Something went wrong, please do try again later.',
            { positionClass: 'toast-bottom-right' }
          );
        }
      );
    }
    if (this.report_cat[3].state) {
      this.settlementService.downloadDigitalCoinReport(this.f_date, this.t_date, this.email).subscribe((data:any) => {
        var final_data=data['data'];
        this.responseMessage = data;
        if(final_data.length==0){
          this.toastr.info('','No data found in the selected duration.',
            { positionClass: 'toast-bottom-right' 
          });
        } else {
          this.downloadCsvData(data['data'],"digital-coin-report.csv");
          this.toastr.success('', this.responseMessage.message, {
            positionClass: 'toast-bottom-right',
          });
        }
      }, (error: any) => {
        this.toastr.info('','Something went wrong, please do try again later.',{ 
          positionClass: 'toast-bottom-right' 
        });
      });
    }

    if (this.report_cat[4].state)
    {
      this.settlementService
      .downloadAllRepairOrders(this.f_date, this.t_date, this.email)
      .subscribe(
        (data:any) => {
          var final_data=data['data'];

          this.responseMessage = data;

          if(final_data.length==0){
            this.toastr.info(
              '',
              'No data found in the selected duration.',
              { positionClass: 'toast-bottom-right' }
            );
          }else{
            this.downloadCsvData(data['data'],"repair.csv");
            this.toastr.success('', this.responseMessage.message, {
              positionClass: 'toast-bottom-right',
            });
          }
         
        },
        (error: any) => {
          this.toastr.info(
            '',
            'Something went wrong, please do try again later.',
            { positionClass: 'toast-bottom-right' }
          );
        }
      );
    }
    if (this.report_cat[5].state)
    {
      this.settlementService
      .downloadAllCancelOrder(this.f_date, this.t_date, this.email)
      .subscribe(
        (data:any) => {
          var final_data=data['data'];

          this.responseMessage = data;

          if(final_data.length==0){
            this.toastr.info(
              '',
              'No data found in the selected duration.',
              { positionClass: 'toast-bottom-right' }
            );
          }else{
            this.downloadCsvData(data['data'],"cancel.csv");
            this.toastr.success('', this.responseMessage.message, {
              positionClass: 'toast-bottom-right',
            });
          }
          
        },
        (error: any) => {
          this.toastr.info(
            '',
            'Something went wrong, please do try again later.',
            { positionClass: 'toast-bottom-right' }
          );
        }
      );
    }
  }

  downloadfDate(event: any) {
    this.f_date = moment(event).format('DD-MM-YYYY');
    this.download_from = moment(event).format('YYYY-MM-DD');

  }
  downloadtDate(event: any) {
    this.t_date = moment(event).format('DD-MM-YYYY');
    this.download_to = moment(event).format('YYYY-MM-DD');

  }

  download(content: any) {
    this.modalService.open(content);
  }

  store(event: any) {
    let filterValue = String(event.value);
    this.singleSelect.close();
    this.filterValue = filterValue;
    this.resetAndDisplayTable();
    console.log(filterValue);
  }
  actionsCheck(value: any) {
    if (this.allowed == 'admin') {
      return true;
    }
    if (this.allowed.includes(value)) {
      return true;
    } else {
      return false;
    }
  }
}
