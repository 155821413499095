import { Component } from '@angular/core';

@Component({
  selector: 'app-add-emi',
  templateUrl: './add-emi.component.html',
  styleUrls: ['./add-emi.component.css']
})
export class AddEmiComponent {

}
