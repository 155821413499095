export class JewelleryCategoryModel {
    id!: number;
    name!: string;
    image!: string;
    description!: string;
    search_tags!: string;
    share_link!: string;
}

export class JewelleryCategoryApiResponse {
    count!: number;
    next!: string | null;
    previous!: string | null;
    results!: JewelleryCategoryModel[];
}