<div>
    <div class="nw">
      <div class="left-buttons">
        <div class="input-group mt-1">
          <mat-icon
            class="searchIcon cursor"
            id="23"
            [ngStyle]="{ color: '#9A9AB0' }"
            >search</mat-icon
          >

          <input
            [(ngModel)]="search"
            type="text"
            class="form-control search_box roundSquare text-indent cursor"
            id="23"
            style="border: 0; height: 48px"
            placeholder="Search Booking ID, Customer Name"
            #txtInput
          />
          <mat-icon
          *ngIf="search"
          (click)="search=''; resetAndDisplayTable()"
            class="crossIcon cursor"
            [ngStyle]="{ color: '#9A9AB0' }"
            id="23"
            >cancel</mat-icon>

          <button
          (click)="resetAndDisplayTable()"
            mat-raised-button
            id="23"
            class="searchButton cursor"
            style="
              background: #4070f4;
              color: #ffffff;
              height: 48px;
              box-shadow: none;
            "
          >
            Search
          </button>
        </div>
       

      </div>

      <div class="right-buttons">
        <div>
          <a>
            <button
              mat-raised-button
              color="primary"
              class="mt-2 add_booking_button"
                [routerLink]="['/dashboard/new_certificate']"
              id="4"
            >
              + Create New
            </button>
          </a>
        </div>

        <div
          class="rcorners1 cursor"
          style="cursor: pointer; height: 45px"
          id="24"
          [routerLink]="['/dashboard/create_certificate']"

        >
          <img
            src="assets/images/setup.png"
            style="width: 45px; height: 45px; margin-top: 8px"
          />
        </div>
      </div>
    </div>
    <div class="flex_center mt-4 mx-3">
      
    <div class="mat-elevation table-responsive">
      <table mat-table [dataSource]="dataSource" class="mat-table-container">
        <!-- Column 1 -->
        <ng-container matColumnDef="certificate_number">
          <th mat-header-cell *matHeaderCellDef>Certificate Number</th>
          <td mat-cell *matCellDef="let element">{{ element.certificate_code }}</td>
        </ng-container>
  
        <!-- Column 2 -->
        <ng-container matColumnDef="item_code">
          <th mat-header-cell *matHeaderCellDef>Item Code</th>
          <td mat-cell *matCellDef="let element">{{ element.item_code }}</td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element" class="hover-text-overflow">
            <div class="tooltip-content">{{ element.description }}</div>
            <span class="tooltip-text" [matTooltip]="element.description">{{ element.description }}</span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef>Image</th>
          <td mat-cell *matCellDef="let element"
           
          class="hover-text-overflow">
            <div  (click)="openImage(element)" class="view">View</div>
          </td>

        </ng-container>
       
    
  
        <!-- ... Repeat the pattern for the remaining columns ... -->
  
    
  
        <!-- Header row -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  
        <!-- Data row -->
        <tr
          mat-row
          [ngStyle]="{ 'background-color': row.status == 4 ? '#f0f0f0' : '' }"
          *matRowDef="let row; columns: displayedColumns"
          [class.selected]="row.selected"
        ></tr>
      </table>
  
      <mat-paginator
      class="page_"
        style="background-color: #f5f6f8"
        [pageSizeOptions]="[popUpManager.page_size_number]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
  </div>