<div class="container" >
 <br>
  <button class="primary-btn">Save</button>
  <!-- End: Button -->
  <br>
  <br>
  <!-- Start: Inactive button -->
  <button class="secondary-btn" mat-button mat-raised-button>Hey there</button>
  <!-- End: Inactive button -->

<br><br>
  <!-- custom checkbox -->
  <label class="checkbox-container">
    <div style="height:25px;display:flex;align-items:center">
      Select All
    </div>
    <input type="checkbox" checked="checked">
    <span class="checkmark"></span>
  </label>
  <!-- End custom checkbox -->

<br>
<table class="table table-bordered">
  <thead>
    <tr>
      <th scope="col">Gross Weight (g)</th>
      <th scope="col">Nett Weight (g)</th>
      <th scope="col">Wastage</th>
      <th scope="col">MC</th>
      <th scope="col">Stone (₹)</th>
      <th scope="col">GST %</th>
      <th scope="col">Total (₹)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1.00</td>
      <td>0.90</td>
      <td>10%</td>
      <td>0.00</td>
      <td>0.00</td>
      <td>3%</td>
      <td>0.00</td>
    </tr>
  </tbody>
</table>
<br>

  <!-- Animation Test -->
  bullshit bullshit
  <h1>Animation Test</h1><br>
  <button (click)="toggleAppearance()">Toggle element</button>
  bullshit
  <div style="background-color:red; height:300px; width:300px"   [@tadaOnEnter] [@fadeOutOnLeave]></div>
  bullshit bullshit
  <!-- End Animation Test -->
  <br>
  <button mat-fab>
    <mat-icon>add</mat-icon>
  </button>
  <br>
    <p class="heading1">Heading 1 </p>

    <p class="heading2">Heading 2</p>
  <br>
  <p class="table-heading">Table Heading</p>
  <p class="text-field-hint">Text field hint</p>
  <p class="text-field-input">Text field label</p>
  <br>
  <!-- Start: Bottom Modal -->
  <h1 >Bottom Modal</h1>
  <div class="bottom-modal" style="position:sticky;bottom:100px;" *ngIf="is_appear" [@slideInUpOnEnter] [@slideOutDownOnLeave]>
    <div class="bottom-content">
      <div style="display:flex;flex-grow:5;align-items:center;">
        <!-- custom checkbox -->
        <label class="checkbox-container">
          <div style="height:25px;display:flex;align-items:center;color: #041649;font-weight:400;font-size:16px;
          ">
            Select All
          </div>
          <input type="checkbox" checked="checked">
          <span class="checkmark"></span>
        </label>
        <!-- End custom checkbox -->
      </div>
      <div class="bold" style="display:flex;flex-grow:3;font-size:16px;color: #262626;">
        <b>04/30 selected</b>
      </div>
      <div style="display:flex;flex-grow:3;">
        <select class="category-drop-down-box" placeholder="Select category">
          <option id="selcted-category" value="1">
            Bangle
          </option>
          <option id="selcted-category" value="category">
            Anklet
          </option>
          <option id="selcted-category" value="category">
            Necklace
          </option>

        </select>
      </div>
      <div style="display:flex;flex-grow:3;">
        <select class="category-box" placeholder="Metal Purity">
          <option id="selcted-category" value="1">
            999 Gold
          </option>
          <option id="selcted-category" value="category">
            Anklet
          </option>
          <option id="selcted-category" value="category">
            Necklace
          </option>

        </select>
      </div>
      <div style="display:flex;flex-grow:3">
        <ng-template #mergeTip><b>Merge</b></ng-template>
        <a href="" class="img-btn">
          <img src="assets/images/merge-icon.png" style="object-fit:contain" [ngbTooltip]="mergeTip" height="30px" width="30px" alt="merge button">
        </a>
      </div>

      <div style="display:flex;flex-grow:3" >
        <ng-template #deleteTip><b>Delete</b></ng-template>
        <a href="" class="img-btn"  >
          <img src="assets/images/delete-icon.png" style="object-fit:contain" [ngbTooltip]="deleteTip" height="20px" width="20px" alt="delete button">
        </a>
      </div>

      <div style="display:flex; flex-grow:3;">
        <button class="primary-btn" style="width:150px; height:50px">Save</button>
      </div>

      <div style="display:flex;flex-grow:3;">
        <button  class="secondary-btn" style="width:150px; height:50px" >Cancel</button>

      </div>
    </div>

  </div>

  <!-- End: Bottom  modal -->

</div>
