import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { JewelleryApiResponse } from '../models/jewelleries-model';
import { CatalogueService } from './catalogue-service.service';
import { JewelleryCategoryApiResponse } from '../models/jewellery-category-model';
import { ActionService } from '../services/actions.service';
import { AnalyticService } from '../services/analytics.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { fadeOutOnLeaveAnimation, slideInLeftOnEnterAnimation, slideInRightOnEnterAnimation, slideInUpAnimation, slideInUpOnEnterAnimation, slideOutDownOnLeaveAnimation, slideOutLeftAnimation, slideOutLeftOnLeaveAnimation, slideOutRightOnLeaveAnimation, zoomOutOnLeaveAnimation } from 'angular-animations';

import { MatDialog } from '@angular/material/dialog';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AppComponent } from '../app.component';

import { JewelleryModel } from '../models/jewelleries-model';
import { JewelleryCategoryModel } from '../models/jewellery-category-model';
import { JewelleryMetalPurityModel } from '../models/jewellery-metal-purity-model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PopUpManager } from '../manager/Popup';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangeDetectionStrategy } from '@angular/core';
import { Input } from '@angular/core';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { ShowAllImagesDialogComponent } from '../show-all-images-dialog/show-all-images-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { error } from 'jquery';
import { EnquiryService } from '../enquiry/enquiry-service.service';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';

interface FileItem { // review: use same as in response
  id?: number;
  selected?: boolean;
  imageUrl: Array<string>;
  name: string;
  category?: string;
  metal?: string;
  purity?: Array<string>;
  status?: number;
}
interface Business { // review: talk to harsh
  value: string;
  viewValue: string;
}

class JewelleryUIModel extends JewelleryModel {
  // why: Same as jewellery Model, additional elements added for ui support

  is_checked = false;  // will help in deciding if checkbox has to be checked while shifting from grid view to other view and vice versa
}

class JewelleryCategoryUIModel extends JewelleryCategoryModel {
  is_checked = false;
}

// review: wherever boolean value is there, use is_what_is_done
@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.css',
   "../templates/templates.component.css"
  ],
  animations: [

    slideInUpOnEnterAnimation({ duration: 400 }),
    slideOutDownOnLeaveAnimation({ duration: 400 }),
    slideOutLeftOnLeaveAnimation({ duration: 400 }),
    slideOutRightOnLeaveAnimation({ duration: 400 }),
    slideInRightOnEnterAnimation({ duration: 400 }),
    slideInLeftOnEnterAnimation({ duration: 400 }),

  ],
  // changeDetection: ChangeDetectionStrategy.OnPush

})

export class CatalogueComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/lottie/circular_loading.json'
  };
  work_in_progess : AnimationOptions={
    path:'/assets/lottie/work_in_progress.json'
  }
  @ViewChild('upload_modal') upload_modal!: UploadDialogComponent;
  selected_purities: number[] = [];// review: save list of ids

  metals: any[] = [];
  selected_metal!: number;
  metal_purity: any[] = [];
  activeLink = 0;

  selected_category!: number;


  jewellery_response: JewelleryApiResponse | undefined; // review: snake case, jewellery_response
  search_jewellery_response: JewelleryApiResponse | undefined; // review: snake case, jewellery_response
  filter_jewellery_response: JewelleryApiResponse | undefined; // review: snake case, jewellery_response


  category_response: JewelleryCategoryApiResponse | undefined;// review same as above, class names are perfect dont change

  selected_files: Set<number> = new Set(); // all the files ids  that are selected
  upload_progress: number = 0;
  upload_interval: any;

  jewellery_model: JewelleryUIModel[] = new Array<JewelleryUIModel>();
  jewellery_category_model: JewelleryCategoryUIModel[] = new Array<JewelleryCategoryUIModel>();
  jewellery_metal_purity_model: JewelleryMetalPurityModel[] = new Array<JewelleryMetalPurityModel>();

  is_all_items_selected: boolean = false;// ui component, used by bottom modal for selecting all items

  search_jewellery_model: JewelleryUIModel[] = new Array<JewelleryUIModel>();
  filter_jewellery_model: JewelleryUIModel[] = new Array<JewelleryUIModel>();

  filterApplied: boolean = false;
  search_jewellery_paginator = false;
  filter_jewellery_paginator = false;
  jewellery_paginator = true;

  current_pagination_page = 1;
  bigImageUrl: string = '';

  ngAfterViewInit(): void {
    // Check if the template reference is available
    if (this.upload_modal) {
      // Do something with the template, e.g., display it
      console.log("you got this");
    }

    this.allowed = this.actionService.getActions();
    const jewellery_category_res = this.catalogueService.getJewelleryCategoryData();
    jewellery_category_res.subscribe((data: any) => {
      this.category_response = data;
      this.categories = [];
      for (let i = 0; i < data.count; i++) {
        // this.categories.push(data.results[i].name);
        this.jewellery_category_model.push(data.results[i]);
      }
      console.log(data.count);
      console.log(this.jewellery_category_model);
      console.log(this.jewellery_category_model.length);
    }, error => {
      console.log(error);
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });

    const jewelleryPurityRes = this.catalogueService.getJewelleryMetalPurityData();
    jewelleryPurityRes.subscribe((data: any) => {
      console.log(data.results);
      this.metals = [];
      for (let i = 0; i < data.count; i++) {
        // this.metals.push(data.results[i].name);
        this.jewellery_metal_purity_model.push(data.results[i]);
        for (let i = 0; i < data.results.length; i++) {
          // add metals only if it is not same as previous
          if (this.metals.indexOf(data.results[i].metal) == -1) {
            this.metals.push(data.results[i].metal);
          }
        }
        for (let i = 0; i < this.metals.length; i++) {
          this.metal_purity[i] = [];
          for (let j = 0; j < data.count; j++) {
            if (this.metals[i] == data.results[j].metal) {
              this.metal_purity[i].push({
                id: data.results[j].id,
                name: data.results[j].name,
              });
            }
          }
        }
      }
      console.log(this.metals);
      console.log(this.metal_purity);
    }, error => {
      console.log(error);
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
    // this.dataSource = new CatalogueDataSource(this.catalogueService);
    // this.dataSource.loadData(0);
    // this.popUpManager = new PopUpManager(this.snackBar);
    this.getJewelleryData(1);
  }

  // Function to open the template (you can call this from a button click or any event)
  openTemplate(): void {
    // Logic to open the template
    if (this.upload_modal) {
      const dialogRef = this.dialog.open(UploadDialogComponent, {
        hasBackdrop: true,
        width: '100%',
        data: {
          template: this.upload_modal
        }
      });
    }
  }


  onlineStore: boolean = true;
  storeName!: any;
  businessName: string = '';
  storeAddress: string = '';
  storeID: string = '';
  aadhaarNumber: string = '';
  areStoreDetailsInputsValid: boolean = false;
  isAadhaarNumberValid: boolean = false;
  is_bottom_modal_visible: boolean = false;
  is_merge_modal_visible: boolean = false;

  category_purity_modal_ref!: NgbModalRef;

  upload_Stage_1: boolean = true;
  upload_Stage_2: boolean = false;
  upload_Stage_3: boolean = false;

  selected_tab: string = 'category';
  is_metal_selected = false;

  icon_color = 'black'; // Initial color of the icon
  icon_color_list = 'black';
  files: File[] = [];
  preview_image_urls: string[] = [];

  business: Business[] = [
    { value: '1', viewValue: 'Wholesale' },
    { value: '2', viewValue: 'Retail' },
    { value: '3', viewValue: 'Manufacturing' },
  ];
  auto_refresh_count=0;
  show_loading_progress: boolean = false;
  progress_count_down: number = 10;

  constructor(
    private router: Router,
    public http: HttpClient,
    private catalogueService: CatalogueService,
    private enquiryService: EnquiryService,
    private actionService: ActionService,
    private analysis: AnalyticService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) { }

  openUploadDialog(id: number): void {
    this.openSpinnerDialog();
    var whatsapp_number = localStorage.getItem('whatsapp_number');

      if (whatsapp_number != null) {
        this.dialog.closeAll();
        const modalRef = this.modalService.open(UploadDialogComponent, { centered: true, size: 'xl', backdrop: 'static', keyboard: false});
        modalRef.componentInstance.id = id;
    
        modalRef.dismissed.subscribe((data: any) => {
          console.log('Dialog closed with data:', data);
          this.getJewelleryData(1);
        });    
      } else {
        this.dialog.closeAll();
        this.router.navigate(['/enquiry']);
        this.toastr.info(
          '',
          'Please update your whatsapp number to upload jewellery.',
          { positionClass: 'toast-bottom-right' }
        );
      }
  }
  openSpinnerDialog(): void {
    const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('spinner Dialog closed:', result); // Handle dialog close event
    });
  }

  openEditPage(jewelleryId: number) {
    this.router.navigate(['/catalogue/edit_catalogue'], { queryParams: { "id": jewelleryId} });
  }

  openDeleteDialog(id: number, filesAssets: any[]): void {

    const modalRef = this.modalService.open(DeleteDialogComponent, { centered: true, size: 'l' });
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.filesAssets = filesAssets;

    modalRef.dismissed.subscribe((data: any) => {
      console.log('Deletion dialog closed:', data);
      this.getJewelleryData(1);
    });


  }
  openShowImagesDialog(id: number, filesAssets: any[]): void {

    const modalRef = this.modalService.open(ShowAllImagesDialogComponent, { centered: true, size: 'l' });
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.filesAssets = filesAssets;

    // modalRef.dismissed.subscribe((data: any) => {
    //   console.log('Deletion dialog closed:', data);
    //   this.getJewelleryData(1);
    // });


  }
  checkInputValues(value: any) {
    console.log(value.target.value)
    this.areStoreDetailsInputsValid = this.storeName !== "" && this.storeID !== "" && this.businessName !== "" && this.storeAddress !== "";
    this.isAadhaarNumberValid = this.aadhaarNumber !== ""
  }


  onSelect(event: any) {// function name should tell what it does
    console.log(event);
    this.files.push(...event.addedFiles);


    // if (files && files.length > 0) {
    //   this.previewImages = [];

    //   for (let i = 0; i < files.length; i++) {
    //     const file = files[i];
    //     if (file.type.startsWith('image/')) {
    //       this.previewImages.push(URL.createObjectURL(file));
    //     }
    //   }
    // }
  }

  onRemove(event: any) { //review: function should tell what it does eg: removeLocalFile
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  @Input()
  has_images = false;

  categories = ["Bangles", "Necklace", "Rings"];
  selected_view: string = 'grid'; // Initial view (grid or list) , best is number no confusion, second best text small or caps fixed
  search = '';
  search_field_disable!: boolean;
  allowed!: any;// review: is_allowed
  enteredDesignName!: string;

  openCategory(content: any, id: number) {
    if (!this.is_bottom_modal_visible  && this.selected_files.size > 1) {
      this.selected_metal = 0;
      this.selected_category = 0;
      this.selected_purities = [];
    }
    console.log(id);
    if (id != 0) {
      console.log("id is not 0");
      this.selected_files.add(id);
    }
    // console.log(this.selected_files);
    console.log(content);
    this.category_purity_modal_ref = this.modalService.open(content, { centered: false, size: <any>'sm' });
  }

  openUpload(content: any) {

    this.modalService.open(content, { centered: true, size: <any>'xl' });

  }

  closeCategory() {
    this.modalService.dismissAll();
  }


  ngOnInit(): void {
    this.openSpinnerDialog();
    this.allowed = this.actionService.getActions();
  }


  actionsCheck(value: any) {
    if (this.allowed == 'admin') {
      return true;
    }
    if (this.allowed.includes(value)) {
      return true;
    } else {
      return false;
    }
  }

  //review: remove if nor required
  searchOption(pageIndex: number) {
    this.openSpinnerDialog();
    this.search_jewellery_model = []
    console.log(this.search)

    if (this.search.trim() === '') {
      this.dialog.closeAll();
      return;
    }

    // Call the API service and update the searchResults array
    const jewelleryRes = this.catalogueService.searchJewelleryItem(this.search, pageIndex);
    jewelleryRes.subscribe((data: any) => {
      console.log(data)
      if (data.count > 0) {
        this.has_images = true;
        this.search_jewellery_response = data;
        this.search_jewellery_model = data.results;
        this.dialog.closeAll();
      } else {
        this.dialog.closeAll();
        this.toastr.info(
          '',
          'No results found',
          { positionClass: 'toast-bottom-right' }
        );
      }
      console.log(this.search_jewellery_model)

    }, error => {
      console.log(error);
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
    this.search_jewellery_paginator = true;
    this.jewellery_paginator = false;
  }


  clearInput() {
    this.analysis.logEvent('User Clear Input Search field');
    this.search = '';
    this.search_jewellery_paginator = false;
    this.jewellery_paginator = true
    this.search_jewellery_model = [];
    this.dialog.closeAll();
    this.getJewelleryData(1);
  }

  actionAllowed() {
    if (this.actionsCheck(23)) {
      this.search_field_disable = false;
    } else {
      this.search_field_disable = true;
    }
  }

  switchView(view: string) {
    // if(view=="grid"){
    //   this.icon_color = this.icon_color === 'black' ? 'blue' : 'black'; // Change the color on each click
    //   this.icon_color_list='black';

    // }else{
    //   this.icon_color_list = this.icon_color_list === 'black' ? 'blue' : 'black';
    //   this.icon_color='black';
    // }
    this.selected_view = view;


  }

  addMore() {
    alert('add more');
  }
  selectedFile: File | null = null;
  chunkSize = 128 * 128; // 128kb chunks (adjust as needed)

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }
  async copyFile() {
    if (!this.selectedFile) {
      console.log('No file selected.');
      return;
    }

    let offset = 0;
    const totalSize = this.selectedFile.size;

    while (offset < totalSize) {
      const chunk = this.selectedFile.slice(offset, offset + this.chunkSize);
      // await this.catalogueService.uploadJewelleryAssets(chunk);
      offset += this.chunkSize;
    }

    console.log('File copy completed.');
  }



  toggleAllSelection() {
    this.is_all_items_selected = !this.is_all_items_selected;

    for (let i of this.jewellery_model)
      i.is_checked = this.is_all_items_selected;
    if (this.is_all_items_selected) {
      for (let file of this.jewellery_model)
        this.selected_files.add(file.id);
    }
    else
      this.selected_files.clear()

    if (this.selected_files.size > 0) this.is_bottom_modal_visible = true;
    else this.is_bottom_modal_visible = false;
  }

  toggleSelected(file: JewelleryUIModel) {
    // When: this function gets called when the checkbox is selected on the jewellery grid
    // const selectCheckbox = document.getElementById("selectCheckbox" + i) as HTMLInputElement;
    // console.log(selectCheckbox.checked);
    file.is_checked = !file.is_checked;
    if (file.is_checked) this.selected_files.add(file.id);
    else {
      this.selected_files.delete(file.id)
    }
    if (this.selected_files.size > 0) this.is_bottom_modal_visible = true;
    else this.is_bottom_modal_visible = false;
  }

  biggerImage(content: any, image: string) {
    console.log(image);
    this.bigImageUrl = image;
    this.modalService.open(content, { centered: true, size: <any>'m' });
  }


  saveChanges(id: number) {
    this.openSpinnerDialog();
    console.log('save changes' + id);
    console.log(this.enteredDesignName);
    console.log(this.selected_category);
    console.log(this.selected_purities);
    console.log(this.selected_metal);
    this.catalogueService.updateJewelleryItem(
      id,
      this.enteredDesignName,
      this.selected_category,
      this.selected_metal,
      this.selected_purities,
    ).subscribe((data: any) => {
      console.log(data);
      this.getJewelleryData(this.current_pagination_page);
      this.dialog.closeAll();
      this.cancelBottomModal();
    }, error => {
      this.dialog.closeAll();
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
  }

  saveSelected() {
    console.log(this.selected_files);
    for (let file of this.selected_files) {
      this.saveChanges(file);
    }
  }

  deleteMultiple() {
    this.openSpinnerDialog();
    console.log(this.selected_files);
    this.catalogueService.deleteMultiple(this.selected_files).subscribe((data: any) => {
      console.log(data);
      if (data['success'] == 'deleted') {
        this.getJewelleryData(1);
      }
    }, error => {
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
    this.cancelBottomModal();
  }

  cancelBottomModal() {
    console.log("cancel bottom modal");
    this.selected_files.clear();
    for (let i of this.jewellery_model)
      i.is_checked = false;
    this.is_bottom_modal_visible = false;
    this.is_all_items_selected = false;
  }

  getDesignName(event: any) {
    console.log(event.target.value);
    this.enteredDesignName = event.target.value;
  }

  getSelectedPurity(id: number) {
    console.log(id);
    if (this.selected_purities.includes(id)) {
      this.selected_purities.splice(this.selected_purities.indexOf(id), 1);
    } else {
      this.selected_purities.push(id);
    }
    console.log(this.selected_purities);
  }
  // Function to check if a purity is selected (you should implement this)
  isPuritySelected(purityId: number): boolean {
    console.log(event);
    if (this.selected_purities.includes(purityId)) {
      this.selected_purities.splice(this.selected_purities.indexOf(purityId), 1);
      return true;
    } else {
      this.selected_purities.push(purityId);
      return false;
    }
  }
  getSelectedMetal(id: any) {
    this.activeLink = id;
    console.log(this.activeLink);
    this.selected_metal = this.metals[this.activeLink];
    this.selected_purities = [];
  }

  chooseMetal(metal: any) {
    console.log(metal);
    this.activeLink = metal.id;
    this.is_metal_selected = true;
    this.selected_metal = metal;
  }

  saveSelectedPurities() {
    this.openSpinnerDialog();
    console.log(this.selected_metal);
    console.log(this.selected_purities);
    console.log(this.selected_files);
    for (let id of this.selected_files) {
      this.savePurities(id);
    }
  }
  savePurities(id: number) {
    if (this.is_bottom_modal_visible) {
      if(this.is_merge_modal_visible) {
        this.dialog.closeAll();
      } else {
        this.modalService.dismissAll();
        this.dialog.closeAll();
      }
    } else {
      this.catalogueService.updateJewelleryItem(id,
        this.enteredDesignName,
        this.selected_category,
        this.selected_metal,
        this.selected_purities,
        ).subscribe((data: any) => {
          console.log(data);
          this.modalService.dismissAll();
          this.cancelBottomModal();
          this.selected_files.clear();
          this.getJewelleryData(1);
        }, error => {
          this.dialog.closeAll();
          this.toastr.info(
            '',
            'Something went wrong, please do try again later.',
            { positionClass: 'toast-bottom-right' }
          );
        });
    }
  }

  deleteSelectedJewellery(jewelleryId: number) {
    this.openSpinnerDialog();
    console.log(jewelleryId);
    this.catalogueService.deleteJewellery(jewelleryId).subscribe((data: any) => {
      console.log(data);
      if (data['success'] == 'deleted') {
        this.getJewelleryData(1);
      }
    }, error => {
      this.dialog.closeAll();
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
  }

  openingCategory(id: number) {
    console.log('file id'+id);
    if(this.is_bottom_modal_visible) {

    } else {
      this.selected_metal = 0;
      this.selected_files.add(id); 
    }
  }

  toggleCategory(category: any) {
    category.is_checked = !category.is_checked;
    if (category.is_checked) {
      category.is_checked = false;
      this.selected_category = category.id;
    }
    console.log(this.selected_files);
    console.log(this.selected_category);
    this.jewellery_model.forEach((item) => {
      if (this.selected_files.has(item.id)) {
        item.category = category.id;
        console.log('category' + item.category);
        console.log('jewellery' + item.id);
      }
    });
    if(this.is_merge_modal_visible) {
      this.category_purity_modal_ref.close();
    } else {
      this.modalService.dismissAll();
    }
  }

  toggleFilterCategory(category: any) {
    category.is_checked = !category.is_checked;
    if (category.is_checked) {
      category.is_checked = false;
      this.selected_category = category.id;
    }
    console.log(this.selected_category);
  }

  applyFilters(category: any, metals: number, purities: number[], pageIndex: number) {
    // console.log(this.selected_category + " " + this.selected_metal + " " + this.selected_purities);
    // this.catalogueService.applyFilterOnJewelleryItems(category, metals, purities);
    // this.modalService
    this.filter_jewellery_model = [];

    const filterRes = this.catalogueService.applyFilterOnJewelleryItems(category, metals, purities, 1);
    filterRes.subscribe((data: any) => {
      console.log(data)
      if (data.count > 0) {
        this.has_images = true;
        this.filter_jewellery_response = data;
        this.filter_jewellery_model = data.results;
        this.dialog.closeAll();
      }
      console.log(this.filter_jewellery_model.length)
      this.closeCategory()
    });
    this.filterApplied = true;
    this.filter_jewellery_paginator = true;
    this.jewellery_paginator = false;
  }
  clearFilters() {
    this.filter_jewellery_model = [];
    this.filter_jewellery_response?.results?.length == 0
    console.log('all filter cleared')
    this.filterApplied = false;
    this.filter_jewellery_paginator = false;
    this.jewellery_paginator = true;
  }
  switchTab(tab: string) {
    this.selected_tab = tab;
  }

  mergeSelected(modal: any) {
    if(this.selected_files.size > 1) {
      this.is_merge_modal_visible = true;
      this.category_purity_modal_ref = this.modalService.open(modal, { centered: true, size: <any>'sm' });
    } else {
      this.toastr.info(
        '',
        'Please select atleast two jewellery items to merge.',
        { positionClass: 'toast-bottom-right' }
      );
    }
  }

  clearSelectedMetals() {
    this.is_metal_selected = false;
  }

  setPagination($event: PageEvent) {
    this.openSpinnerDialog();
    this.current_pagination_page = $event.pageIndex + 1;
    if ($event.pageIndex > $event.previousPageIndex!) {
      this.getJewelleryData($event.pageIndex + 1);
    } else {
      this.getJewelleryData($event.previousPageIndex!);
    }
  }
  setSearchResultPagination($event: PageEvent) {
    if ($event.pageIndex > $event.previousPageIndex!) {
      const jewelleryRes = this.catalogueService.searchJewelleryItem(this.search, $event.pageIndex + 1);
      jewelleryRes.subscribe((data: any) => {
        console.log(data)
        if (data.count > 0) {
          this.has_images = true;
          this.search_jewellery_response = data;
          this.search_jewellery_model = data.results;
          this.dialog.closeAll();
        }
        console.log(this.search_jewellery_model.length)

      });
    } else {
      this.catalogueService.searchJewelleryItem(this.search, $event.previousPageIndex!)
        .subscribe((data: any) => {
          console.log(data)
          if (data.count > 0) {
            this.has_images = true;
            this.search_jewellery_response = data;
            this.search_jewellery_model = data.results;
            this.dialog.closeAll();
          }
          console.log(this.search_jewellery_model.length)
        });
    }
  }
  setFilterResultPagination($event: PageEvent) {
    if ($event.pageIndex > $event.previousPageIndex!) {
      const jewelleryRes = this.catalogueService.applyFilterOnJewelleryItems(this.selected_category, this.activeLink, this.selected_purities, $event.pageIndex + 1);
      jewelleryRes.subscribe((data: any) => {
        console.log(data)
        if (data.count > 0) {
          this.has_images = true;
          this.search_jewellery_response = data;
          this.search_jewellery_model = data.results;
          this.dialog.closeAll();
        }
        console.log(this.search_jewellery_model.length)

      });
    } else {
      this.catalogueService.applyFilterOnJewelleryItems(this.selected_category, this.activeLink, this.selected_purities, $event.previousPageIndex!)
        .subscribe((data: any) => {
          console.log(data)
          if (data.count > 0) {
            this.has_images = true;
            this.search_jewellery_response = data;
            this.search_jewellery_model = data.results;
            this.dialog.closeAll();
          }
          // console.log(this.filter_jewellery_model.length)
        });
    }
  }

  setAlreadyCheckedItemInJewelleryModel() {
    //input: new array of jewellery items
    //do what: checks if any of the item was previously selected, if selected, marks is_checked as true
    //output: internally changes the jewellery_items data
    if (this.selected_files.size == 0) return;
    for (let jewellery_item of this.jewellery_model) {
      console.log("selected items", this.selected_files);
      console.log(jewellery_item);
      if (this.selected_files.has(jewellery_item.id)) {
        jewellery_item.is_checked = true;
        console.log("item found");
      }
    }

  }
  getJewelleryData(pageIndex: number) {
    const jewelleryRes = this.catalogueService.getJewelleryData(pageIndex);
    jewelleryRes.subscribe((data: any) => {
      console.log(data);
      if (data.count > 0) {
        this.has_images = true;
        this.jewellery_response = data;
        this.jewellery_model = data.results;
        this.dialog.closeAll();
        this.refreshJewellery(pageIndex);
      } else {
        console.log("no images");
        this.has_images = false;
        this.dialog.closeAll();
      }
      this.is_all_items_selected = false;
      this.setAlreadyCheckedItemInJewelleryModel();
    }, error => {
      console.log(error);
      this.dialog.closeAll();
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
  }

  refreshJewellery(pageIndex: number) {
    if(this.jewellery_model.some(item => item.status == 2)) {
      this.show_loading_progress = true;
      console.log('refresh' + this.auto_refresh_count);
      if(this.auto_refresh_count <= 2) {
        if(this.progress_count_down != 0) {
          setTimeout(() => {
            this.refreshJewellery(pageIndex);
          }, 1000);
          this.progress_count_down = this.progress_count_down - 1;
        } else {
          this.auto_refresh_count++;
          this.show_loading_progress = false;
          this.progress_count_down = 10;
          this.getJewelleryData(pageIndex);
        }
      } else {
        this.progress_count_down = 10;
        this.show_loading_progress = false;
        // this.toastr.info(
        //   '',
        //   'Failed to refresh images, will try again later',
        //   { positionClass: 'toast-bottom-right' }
        // );
      }
    } else {
      this.auto_refresh_count = 0;
      this.progress_count_down = 10;
      this.show_loading_progress = false;
    }
  }

  applyMergeChanges() {
    this.openSpinnerDialog();
    console.log(this.selected_files);
    this.catalogueService.mergeJewelleryItems(this.selected_files, this.selected_category, this.selected_metal, this.selected_purities).subscribe((data: any) => {
      console.log(data);
      this.getJewelleryData(1);
      this.modalService.dismissAll();
      this.cancelBottomModal();
    }, error => {
      this.dialog.closeAll();
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
  }
}
