import { event } from 'jquery';
import { PaymentOptions } from './installments';
import { getMaterialTypeFromString } from '../manager/material';
import { formatDate } from '@angular/common';
import { RateServiceService } from '../rate-page/rate-service.service';
import { RateFinal } from './rate';
import { HttpClient } from '@angular/common/http';
import { User } from './user_model';

// first table
export class orderDetail {

  id!: string;
  sr_no!: string;
  description!: string;
  size!: string;
  purity!: string | number;
  item_rate!:string;
  temp_purity!:string ;
  weight: number | string;
  amount: number | string;
  estimation!: EstimationModel;
  block_amount!:boolean;
  status !:Number;
  is_detailed :boolean= false;
  // old_gold:oldGoldDetail[]=[]
  constructor(
    id: string,
    sr_no: string,
    description: string,
    size: string,
    purity: string,
    temp_purity:string,
    weight: number | string,
    amount: number | string,
    estimation: EstimationModel
    // old_gold:oldGoldDetail[]=[],
  ) {
    this.id = id;
    this.sr_no = sr_no;
    this.description = description;
    this.size = size;
    this.purity = purity;
    this.temp_purity = temp_purity
    this.weight = weight;
    this.amount = amount;
    this.estimation = estimation;
    // this.old_gold = old_gold;
  }
  public  ShouldAdd() :boolean
  {
    return (
      this.description !== '' && // Description is not empty
      this.weight !== '' // net_weight is not an empty string
    );
  }

}

export class DetailForCancellation{
  id!:string;
  order_id!:string;
  customer_name!:string;
  phone!:string;
  address!:string;
  pan!:string;
  gstin!:string;
  advance_paid!:string;

  constructor(id:string,order_id:string,customer_name:string,phone:string,address:string,pan:string,gstin:string,advance_paid:string){
    this.id=id;
    this.order_id=order_id;
    this.customer_name=customer_name;
    this.phone=phone;
    this.address=address;
    this.pan=pan;
    this.gstin=gstin;
    this.advance_paid=advance_paid;
  }
}

export class OrderReportData
{
    id!:String;
    order_id!:String;
    customer_name!:String;
    phone!:String;
    date!:String;
    old_gold_wt!:String;
    amount!:String;
    payment_mode!:String;
    payment_id!:String;
    settlement_id!:String;
    settlement_date!:String;
    count!:String;
}

export class RepairReportData
{
    id!:String;
    repair_id!:String;
    customer_name!:String;
    phone!:String;
    date!:String;
    weight!:String;
    amount!:String;
    payment_mode!:String;
    payment_id!:String;
    settlement_id!:String;
    settlement_date!:String;
    count!:String;
}

// old gold details rows

export class oldGoldDetail {
  id!:string;
  description: string;
  pc: string ;
  weight:  string;
  net_weight: string;
  less: string;
  pure: string;
  rate: string;
  amount: string;
  round_off: string;
  total: string;
  temp_weight :string;
  is_saved:boolean=false;


  constructor(
    id:string,
    description: string,
    pc: string,
    weight: string,
    less: string ,
    pure: string ,
    rate: string ,
    amount: string ,
    round_off: string ,
    total: string ,
    net_weight: string,
    temp_weight :string,
    // test
  ) {
    this.id= id;
    this.description = description;
    this.pc = pc;
    this.weight = weight;
    this.less=less;
    this.pure = pure;
    this.rate = rate;
    this.amount = amount;
    this.round_off = round_off;
    this.total = total;
    this.net_weight = net_weight;
    this.temp_weight = temp_weight;
  }
  atomicUpdate() {
    this.calculateWeightAcLess();
    this.calculateWeightAcPure();
    this.amountAcRate();
    this.roundOff();
  }

  private roundValueTo3DecimalPlaces(value: string) {
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      return numericValue.toFixed(3);
    }
    return value;
  }
  roundNumericValuesTo3DecimalPlaces() {
    this.weight = this.roundValueTo3DecimalPlaces(this.weight);
    this.net_weight = this.roundValueTo3DecimalPlaces(this.net_weight);
    this.less = this.roundValueTo3DecimalPlaces(this.less);
    // this.rate = this.roundValueTo3DecimalPlaces(this.rate);
    this.temp_weight = this.roundValueTo3DecimalPlaces(this.temp_weight);
  }

  formatToThreeDecimals(value: string | number): string {
    if (typeof value === 'number') {
      return value.toFixed(3);
    } else if (typeof value === 'string') {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
        return numericValue.toFixed(3);
      }
    }
    return ''; // Return an empty string if the value is not a valid number or string
  }

   formatDecimalInput(inputValue: string): string {
    // Remove any non-numeric characters and leading zeros
    const newValue = inputValue.replace(/[^0-9.]/g, '').replace(/^0+(?=\d)/, '');

    // If the input is empty, return an empty string
    if (newValue === '') {
      return '';
    }

    // Format the value with three decimal places
    const formattedValue = parseFloat(newValue).toFixed(3);

    return formattedValue;
  }
  

  public shouldAdd():boolean{
    console.log(this)
    return (
      this.description !== '' && // Description is not empty
      // Description is not an empty string
      this.pc !== '' && // pc is not an empty string
      this.weight !== '' && // weight is not an empty string
      this.net_weight !== '' && // net_weight is not an empty string
      this.pure !== '' && // pure is not an empty string
      this.rate !== '' && // rate is not an empty string
      this.amount !== '' && // amount is not an empty string
      this.round_off !== '' && // round_off is not an empty string
      this.total !== '' // total is not an empty string

    );
  }


  public calculateWeightAcLess(): void {
    // Calculate the 'weight' property based on 'less'
    // this.weight = (Number(this.weight) - Number(this.less?this.less:0));
    // this.weight = (this.formatDecimalInput(String(this.weight)))
    this.weight = this.weight
    this.calculateNetWeight()
  }
  public calculateNetWeight():void{
    if(this.less=='')
    {
      this.less='0'
    }
    this.net_weight = String(Number(this.weight) - Number(this.less?this.less:'0'));
    this.net_weight = parseFloat(this.net_weight).toFixed(3)
    this.temp_weight = this.net_weight
  }
  public calculateWeightAcPure(): void {
    // Calculate the weight based on the initial weight and pure value
    // this.weight = (Number(this.weight) * Number(this.pure)) / 100;
    console.log(this.net_weight)
    console.log(this.pure)
    this.temp_weight = String((Number(this.net_weight) * Number(this.pure)) / 100);
  }
  public amountAcRate(): void {
    this.amount = String(Number(this.temp_weight) * Number(this.rate));
    this.amount= String(Math.round(Number(this.amount)))
    }
  public roundOff(): void {
    this.total = String(Math.ceil(Number(this.amount) + Number(this.round_off)));
  }


  // public amountAcRoundOFF(): void {
  //   this.round_off = Math.round(this.amount / 10) * 10;
  //   this.total = this.round_off;
  // }
  public clearAll(event: any): void {
    event.preventDefault();
    // Reset all properties to their initial/default values
    // You can assign default values or use 0 for numbers and empty string for strings, or customize as needed
    this.description = 'Old Gold';
    this.pc = '';
    this.weight = '';
    this.net_weight ='';
    this.less = '';
    this.pure = '';
    this.rate = '';
    this.amount = '';
    this.round_off = '';
    this.total = '';
    this.temp_weight ='';
  }



  public isValidLessWeight() :boolean{
    return (Number(this.less)<Number(this.weight))
  }
  public isValidData(): boolean{
   var valid= (
      this.description !== '' && // Description is not empty
      (this.pc !== '') && // pc is not zero
      ( this.weight!=='') && // weight is not zero
      ( this.net_weight!=='') && // wastage is not zero
      (this.pure!=='') && // pure is not zero
      ( this.rate!=='') && // rate is not zero
      ( this.amount!=='') && // amount is not zero
      ( this.round_off!=='') && // round_off is not zero
      (this.total!=='')
   // total is not zero
    );
    if(valid){this.is_saved=true;}
    return valid
  }


}

export class paymentDetail {
  id!: string;
  amount!: string;
  payment_method!: string;
  date!: string;
  bank!: string;
  payment_detail!: string;
}

// from webstore

export class StoneEstimation {
  id: number;
  name: string;
  quantity: number | null;
  weight: number | null;
  carat: number | null;
  rate: string;
  cost: number | null;
  charge_type: number;

  constructor(
    id: number,
    name: string,
    quantity: number | null,
    weight: number | null,
    carat: number | null,
    rate: string,
    cost: number | null,
    charge_type: number
  ) {
    this.id = id;
    this.name = name;
    this.quantity = quantity;
    this.weight = weight;
    this.carat = carat;
    this.rate = rate;
    this.cost = cost;
    this.charge_type = charge_type;
  }
}

// making Charge
export class MakingChargeType {
  static GROSS = new MakingChargeType('(G.wt/gm)', 0, false);
  static NET = new MakingChargeType('(N.wt/gm)', 1, true);
  static PC = new MakingChargeType('(/pc)', 2, false);

  name: string;
  value: number;
  active: boolean = false;

  private constructor(name: string, value: number, active: boolean) {
    this.name = name;
    this.value = value;
    this.active = active;
  }
  setAsActive() {
    MakingChargeType.GROSS.active = false;
    MakingChargeType.NET.active = false;
    MakingChargeType.PC.active = false;
    this.active = true;
  }
  getActiveType(): any {
    if (MakingChargeType.GROSS.active) {
      return MakingChargeType.GROSS.value;
    } else if (MakingChargeType.NET.active) {
      return MakingChargeType.NET.value;
    } else if (MakingChargeType.PC.active) {
      return MakingChargeType.PC.value;
    }
    return null; // None is active
  }
  changeType(){
    if(MakingChargeType.GROSS.active)
    {
      MakingChargeType.GROSS.active=false
      MakingChargeType.NET.active=true
      MakingChargeType.PC.active=false
    }
    else if(MakingChargeType.NET.active)
      {
        MakingChargeType.GROSS.active=false
        MakingChargeType.NET.active=false
        MakingChargeType.PC.active=true
    }
    else {
      MakingChargeType.GROSS.active=true
      MakingChargeType.NET.active=false
      MakingChargeType.PC.active=false
    }
  }
}
// wastage type
export class Wastage {
  static PERCENTAGE = new Wastage('%', 1, true);
  static GRAM = new Wastage('(gms)', 0, false);

  name: string;
  value: number;
  active: boolean = false;

  private constructor(name: string, value: number, active: boolean) {
    this.name = name;
    this.value = value;
    this.active = active;
  }
  setAsActive() {
    Wastage.PERCENTAGE.active = false;
    Wastage.GRAM.active = false;
    this.active = true;
  }
  getActiveType(): any {
    if (Wastage.PERCENTAGE.active) {
      return Wastage.PERCENTAGE.value;
    } else if (Wastage.GRAM.active) {
      return Wastage.GRAM.value;
    }
    return null; // None is active
  }
  changeType():void{
    if(Wastage.PERCENTAGE.active)
    {
      Wastage.GRAM.active=true
      Wastage.PERCENTAGE.active=false
    }
    else{
      Wastage.GRAM.active=false
      Wastage.PERCENTAGE.active=true
    }
  }

}

// actual estimation

export class EstimationModel {
  // this will have everything that estimation required
  date!: string;
  gross_weight: number | string;
  net_weight: number | string;
  wastage: number;
  rate_per_gram: number | string;
  making_charge: number;
  stone_estimation: StoneEstimation[];
  gst: number;
  total_amount: number ;

  stone_amount: number;
  //others
  metal: string;
  wastage_type: number;
  making_charge_type: number; // Assuming selectedMakingChargeType is a string

  constructor(data: any) {
    this.date = new Date().toISOString().split('T')[0];
    this.rate_per_gram = data.rate_per_gram || 0 || '';
    this.gross_weight = data.gross_wt || '';
    this.metal = data.selected_metal || '';
    // this.purity = data.selected_purities[0] || ''; need to ask
    this.net_weight = data.nett_wt || '';
    this.wastage = data.wastage || '';
    this.wastage_type = data.wastage_type || Wastage.PERCENTAGE.value;
    this.making_charge = data.making_charge || '';
    this.making_charge_type =
      data.making_charge_type || MakingChargeType.NET.value;
    this.stone_estimation = data.stone_estimation || [
      new StoneEstimation(0, '', null, null, null, '', null, 1),
    ];
    this.total_amount = data.total_amount || '';
    this.gst = data.gst || 3;
    this.stone_amount = data.stone_amount || '';
  }
  get stoneEstimationTotalCost(): number {
    return this.stone_estimation.reduce((totalCost, stone) => {
      if (stone && stone.cost) {
        return Number(totalCost) + Number(stone.cost);
      }
      return Number(totalCost);
    }, 0);
  }
}

export class Order {
  id!: number;
  commodity!: number;
  order_no!: string;
  booking!: number;
  order_date!: string;
  customer_name!: string; //this will help to find the customer
  order_taken_by!: string;
  rate!: string;
  rate_!:string;
  rate_category!: number;
  old_golds: oldGoldDetail[] = [];
  custom_order_items: orderDetail[] = [];
  payment_data!: PaymentOptions[];
  advance_amount!: number;
  user:Customer= new Customer('','','','','','','','','','','');
    invoice_url:string='';
  order_note!:string;

  constructor(data: any) {
    this.id = data.id || '';
    this.commodity = data.commodity || 0;
    this.order_no = data.order_no || '';
    this.booking = data.booking || 0;
    this.order_date =
      data.order_date || formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.customer_name = data.customer_name || 0;
    this.order_taken_by = data.order_taken_by || '';
    this.rate = data.rate || '';
    this.rate_category = data.rate_category || getMaterialTypeFromString('22k');
    this.old_golds = data.old_gold || [
      new oldGoldDetail('0','', '', '', '', '', '', '', '', '', '',''),
    ];
    this.custom_order_items = data.custom_order_items || [
      new orderDetail('', '', '', '', '','', '', '', new EstimationModel({})),
    ];
  }
}

export class CustomOrderItem {
  id!: string;
  description!: string;
  size!: string;
  purity!: string;
  weight!: number;
  amount!: number;
  est!: EstimationModel;
}

// rate fix and rate unfix

export class BookingType {
  static RATEFIX = new BookingType('Rate Fix', 0, true);
  static RATEUNFIX = new BookingType('Rate Unfix', 1, false);

  name: string;
  value: number;
  active: boolean = false;

  private constructor(name: string, value: number, active: boolean) {
    this.name = name;
    this.value = value;
    this.active = active;
  }
  setAsActive() {
    BookingType.RATEFIX.active = false;
    BookingType.RATEUNFIX.active = false;
    this.active = true;
  }
  // getActiveType(): MakingChargeType | null {
  //   if (BookingType.RATEFIX.active) {
  //     return BookingType.RATEFIX;
  //   } else if (BookingType.RATEUNFIX.active) {
  //     return BookingType.RATEUNFIX;
  //   }
  //   return null; // None is active
  // }
}

export class Customer {
  id!:string;
  first_name!: string;
  phone!: string;
  dob!: string;
  email!: string;
  gender!: string;
  address!: string;
  pincode!: string;
  state!: string;
  pan!: string;
  gstin!: string;

  constructor(
    id:string,
    first_name: string,
    phone: string,
    dob: string,
    email: string,
    gender: string,
    address: string,
    pincode: string,
    state: string,
    pan: string,
    gstin: string
  ) {
    (this.id=id),
    (this.first_name = first_name),
      (this.phone = phone),
      (this.dob = dob),
      (this.email = email),
      (this.gender = gender),
      (this.address = address),
      (this.pincode = pincode),
      (this.state = state),
      (this.pan = pan),
      (this.gstin = gstin);
  }
}
export class GoldCommodies {
  static GOLD22k = new GoldCommodies('22k', 1,'');
  static GOLD24k = new GoldCommodies('24k', 4,'');
  static GOLD18k = new GoldCommodies('18k', 0,'');
  static GOLDOTHER = new GoldCommodies('Other', 5,'');

  name: string;
  value: number;
  rate:string;
  private constructor(name: string, value: number,rate:string) {
    this.name = name;
    this.value = value;
    this.rate = rate;
  }
}
// silver commdities
export class SilverCommodies {
  static SILVER = new SilverCommodies('Sil', 2,'');
  static SILVER999 = new SilverCommodies('999', 6,'');

  name: string;
  value: number;
  rate:string;
  private constructor(name: string, value: number,rate:string) {
    this.name = name;
    this.value = value;
    this.rate = rate;
  }
}
// platinum
export class PlatinumCommodies {
  static PLATINUM = new PlatinumCommodies('Plat', 3,'');
  name: string;
  value: number;
  rate:string;
  private constructor(name: string, value: number,rate:string) {
    this.name = name;
    this.value = value;
    this.rate = rate;
  }
}

// selected commodity
export class Commodity {
  static GOLD = new Commodity(
    'Gold',
    [
      GoldCommodies.GOLD22k,
      GoldCommodies.GOLD18k,
      GoldCommodies.GOLD24k,
      GoldCommodies.GOLDOTHER,
    ],
    true,
    ['Gold 22k', 'Gold 18k','Gold 24k', 'Gold Other'],0
  );
  static SILVER = new Commodity(
    'Silver',
    [SilverCommodies.SILVER, SilverCommodies.SILVER999],
    false,
    ['Silver', 'Silver 999'],1
  );
  static PLATINUM = new Commodity(
    'Platinum',
    [PlatinumCommodies.PLATINUM],
    false,
    ['Platinum'],2
  );

  name: string;
  types_name: string[] = [];
  types: GoldCommodies[];
  active: boolean = false;
  value : number;

  private constructor(
    name: string,
    types: GoldCommodies[],
    active: boolean,
    types_names: string[],
    value:number
  ) {
    this.name = name;
    this.types = types;
    this.active = active;
    this.types_name = types_names;
    this.value =value
  }
}

export class PaymentSummary {
  amount!: number;
  gst!: number;
  total_amount_gst!: number;
  old_gold_weight!: number;
  old_gold_total!: number;
  advance!: number;
  balance!:number;
  constructor(data: any) {
    this.amount = data.amount || 0;
    this.gst = data.gst || 0;
    this.total_amount_gst = data.total_amount_gst || 0;
    this.old_gold_total = data.old_gold_total || 0;
    this.advance = data.advance || 0;
    this.balance=data.balance || 0;
  }
}

// display options

export class DisplayOptions {
  can_edit: boolean = false;
  view_est: boolean = false;
  view_order: boolean = false;
  payment_edit:boolean =false;

  constructor(can_edit: boolean, view_est: boolean, view_order: boolean,payment_edit:boolean) {
    this.can_edit = can_edit;
    this.view_est = view_est;
    this.view_order = view_order;
    this.payment_edit= payment_edit;
  }
}
