import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {MatPaginator} from '@angular/material/paginator';
import { PopUpManager } from 'src/app/manager/Popup';
import { UserDataSource } from '../notify/select-users/select-dataSource';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { AnalyticService } from '../services/analytics.service';
// import { __values } from 'tslib';




@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  displayedColumns: string[] = ['customer id','Customer Name','Phone Number','Date Of Birth','Email ID','Gender','Joined On','Address','Pin Code','State'];
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  dataSource!:UserDataSource;
  popupManager!:PopUpManager;
  sort!: MatSort;
  listData:any;
  constructor(
    private analysis:AnalyticService,private router:Router,private _location:Location,private modalService:NgbModal,private snackBar:MatSnackBar ) { 
    this.listData = [];
  }
  // removeItem(element:any){
  //   this.listData.forEach((value:any,index:any)=>{
  //     if(value == element)
  //     this.listData.splice(index,1)   
  //   });
  // }

  ngOnInit(): void {
    this.router.navigate(['/dashboard/customers'])
    this.popupManager = new PopUpManager(this.snackBar);

  }
  back(){
    this._location.back();
  }
  open_sent(content:any){
    this.modalService.open(content);
 }
 selectUser(){
  this.router.navigate(['/dashboard/create'])
  
}

}
  


