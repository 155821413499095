export const environment = {
  production: true,
  ip:"https://server.jewellers.live/",
  uploaderIp:"https://uploader.jewellers.live/",
  google_analytics_measurement: "G-L8CJDDWC0F",
  is_analytics_enabled: true,
  version: '0.0.15',
  firebase: {
    apiKey: "AIzaSyAmpC_YypvSFUMjHjg8sJbznTbr3sRyJdY",
    authDomain: "ounceapp-56fb0.firebaseapp.com",
    databaseURL: "https://ounceapp-56fb0.firebaseio.com",
    projectId: "ounceapp-56fb0",
    storageBucket: "ounceapp-56fb0.appspot.com",
    messagingSenderId: "883625753856",
    appId: "1:883625753856:web:08ae40f90f2efb270f07e5",
    measurementId: "G-FXNVDLH1MW",
    vapidKey:"BNUE9IaV20gNPeTLsnhompbT7rVA_exbM09f0ozbCIX-APKgOqxcExL98rRKANoDd3Y_ubXBWUALmKOr0ebsGNU"
  }
};
