import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from 'src/environments/environment';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
 
  currentMessage = new BehaviorSubject(null);
  update_token=true;
  token_url = environment.ip+"app/notification/";



  constructor(private router:Router,private toastr:ToastrService,private angularFirebaseMessaging:AngularFireMessaging,private http:HttpClient) {

  }


  requestPerm(){
    this.angularFirebaseMessaging.requestToken.subscribe((token)=>{
        var fd = new FormData();
        fd.append("token",token!);
        fd.append("platform","0");

        return this.http.post(this.token_url,fd).subscribe(data=>{

        });


    },
    (err)=>
    {
        console.error("No Permission "+ err);
    })
}

receiveMessage() {
  this.angularFirebaseMessaging.messages.subscribe(
    (payload:any) => {

      this.toastr.success(payload['notification']['title'],payload['notification']['body']);



      this.currentMessage.next(payload);
    }
  )
}

exposeFunction(str:String) {
 (window as any).flutter_inappwebview.callHandler('ContactChannel',str)
}


}
