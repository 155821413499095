import { Component, OnInit,HostListener, inject, Inject  } from '@angular/core';
import { AuthServiceService } from './services/auth-service.service';
import { MessagingService } from './services/messaging.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { GoogleAnalyticsService } from '@hakimio/ngx-google-analytics';
import { AnalyticService } from './services/analytics.service';

import { ActivatedRoute } from '@angular/router';

MessagingService
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  show_floating=false;
  message!:any;
  display_name:any;
  screenWidth!: number;

  isOnline!: boolean;

  constructor(private analyticsService: AnalyticService,
    public router:Router, public authService:AuthServiceService,private messagingService:MessagingService,
    private route: ActivatedRoute
    ) {this.screenWidth = window.innerWidth;}



  ngOnInit(): void {
    this.isOnline = navigator.onLine;

    // Listen for online/offline events
    window.addEventListener('online', () => {
      this.isOnline = true;
    });

    window.addEventListener('offline', () => {
      this.isOnline = false;
    });
    this.analyticsService.loadGoogleAnalytics();
    this.messagingService.requestPerm();
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
    this.getTitleName()
    // if (this.screenWidth<800 && this.authService.isLoggedIn())
    // {
    //   console.log(this.router.url)
    //   if(this.router.url==="/" )
    //   {
    //   this.router.navigate(['/dashboard/mobile_rate']);
    //   }
    // }
    
    
   
    
   
  }

  
  is_mobile()
  {
    if(window.innerWidth < 768)
    {
      return true
    }
    return false
  }
  title = 'jewellers_admin';

  getTitleName()
  {

    this.display_name=localStorage.getItem("title")!;
  }



  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.disableZoomOnMobile();
  }

  disableZoomOnMobile() {
    if (window.innerWidth < 768) {
      document.documentElement.classList.add('mobile-view');
    } else {
      document.documentElement.classList.remove('mobile-view');
    }
  }

}
