import { Component, Input, Output ,EventEmitter } from '@angular/core';

export class PopUpData{
  title:string="";
  message:string="";
  description:string="";
  button_text:string="";
  constructor(title:string,message:string,description:string,button_text:string){
    this.title=title;
    this.message=message;
    this.description=description;
    this.button_text=button_text;
  }
}

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.css']
})


export class WarningPopupComponent {
  @Input() warning_data!:PopUpData;
  @Output() proceedButtonClickEvent = new EventEmitter<boolean>();
  constructor() { }

  proceedButtonClicked(value:boolean){this.proceedButtonClickEvent.emit(value);}
}
