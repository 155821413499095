export class RateCutOptions{
    options!:RateCut[]
    constructor(options:RateCut[])
    {
        this.options=options
    }
}

export class RateCut
{
    id!:number;
    weight_from:number | null = null;
    weight_to:number | null = null;
    advance_percentage!:string;
    blocked!:boolean;
    redeem_duration!:string;
    constructor(id:number,weight_from:number| null = null,weight_to:number| null = null,advance_percentage:string,redeem_duration:string,blocked:boolean)
    {
        this.id=id;
        this.weight_from=weight_from
        this.weight_to=weight_to
        this.advance_percentage=advance_percentage
        this.redeem_duration=redeem_duration
        this.blocked=blocked
    }
}

export interface BookingValidation{
    id:string;
    advance_percentage:string;
    redeem_duration:string;
    weight_from:string;
    weight_to:string;
  }
