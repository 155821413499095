import { JsonPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { BankAccountsList } from 'src/app/models/accounts';
import { AddInstallmentForDailyPayRequest, InstallmentData, InstallmentDataFromID, PaymentOptions } from 'src/app/models/installments';
import { ResponseMessage } from 'src/app/models/message';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class InstallmentServiceService {
  plan_url = environment.ip+"shop/savings-plan/";
  
  
  list_payment_url = environment.ip+"shop/offlineBankAccounts/0/get_accounts/";

  constructor(private http:HttpClient) { }

  addInstallment(installement_data:InstallmentData,payment_option:PaymentOptions[],subscription_id:any){

    var data={
      installment_data:installement_data,
      payment_option:payment_option,
      subscription_id:subscription_id
    };

    const headers = { 'content-type': 'application/json'}  

    return this.http.post<ResponseMessage>(this.plan_url+"add_installment/",data,{headers:headers});
  }

  addInstallmentForDailyPay(request_data:AddInstallmentForDailyPayRequest){
    const headers = { 'content-type': 'application/json'}  
    return this.http.post<ResponseMessage>(this.plan_url+"add_installment/",request_data,{headers:headers});
  }

  isInstallmentPaid(subscription_code:string,installment_date:string)
  {
    return this.http.get(`${this.plan_url}isInstallmentPaid/?subscription_code=${subscription_code}&installment_date=${installment_date}`)
  }

  createInstallment(phone:any,amount:any,planid:any,payment_option:PaymentOptions[]){

    var data={
      phone:phone,
      amount:amount,
      planid:planid,
      payment_option:payment_option,
    };

    const headers = { 'content-type': 'application/json'}  

    return this.http.post<ResponseMessage>(this.plan_url+"create_installment/",data,{headers:headers});
  }
  getPayments(){
    return this.http.get<BankAccountsList>(this.list_payment_url);
  }
  getInstallmentData(id:any)
  {
    return this.http.get<InstallmentDataFromID>(`${this.plan_url}get_installment_data/?subscription_code=${id}`)
  }

  

  editInstallment(subCode:any,orderID:any)
  {
    return this.http.get(`${this.plan_url}get_payment_data/?subscription_code=${subCode}&order_id=${orderID}`)
  }


  editEmi(installement_data:InstallmentData,payment_option:PaymentOptions[],subscription_code:any,order_id:any){

    var data={
      installment_data:installement_data,
      payment_option:payment_option,
      subscription_code:subscription_code,
      order_id:order_id,
    };

    const headers = { 'content-type': 'application/json'}

    return this.http.post<ResponseMessage>(this.plan_url+"edit_installment/",data,{headers:headers});
  }



}
