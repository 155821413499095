import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { PopUpManager } from 'src/app/manager/Popup';
import { BookingDataSource } from 'src/app/rate_cut_feature/bookings/bookings_dataSource';
import { RateCutService } from 'src/app/rate_cut_feature/ratecut/rate-cut.service';
import { PendingInstallmentDataSource } from './customer_ledger_dataSource';
import { PendingInstallmentService } from './pending-installment.service';
import { TitleService } from 'src/app/services/title.service';
import { Emi, EMI_DATA } from 'src/app/models/ledger';
import { LedgerServiceService } from '../customer-ledger/ledger-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-pending-installment',
  templateUrl: './pending-installment.component.html',
  styleUrls: ['./pending-installment.component.css','../../rate_cut_feature/view-booking/view-booking.component.css',]
})
export class PendingInstallmentComponent {
  dataSource!: PendingInstallmentDataSource;
  fdate: string = '';
  tdate: string = '';
  email = '';
  ownload_from_date='';
  download_to_date = '';
  download_from_date='';

  search = '';
  status = '';
  ordering: string = 'id';
  current_date_validation!:string;
  emi_Data!: EMI_DATA;
  plan_dataSource!: Array<Emi>;


  displayedColumns: string[] = [
    'plan_id',
    'customer_name',
    'phone',
    'plan_type',
    'payment_status'
  ];
  displayedColumnsEmis: string[] = [
    // 'plan_id',
    // 'plan_name',
    'installments',
    'payment_date',
    'amount',
    'rate',
    'weight',
    'payment_mode',
    'payment_id',
    'settlement_id',
    'settlement_date',
  ];

  popupManager!: PopUpManager;
  @ViewChild(MatPaginator) paginator!: MatPaginator;


  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private pendingInstallmentsService : PendingInstallmentService,
    private titleService: TitleService,
    private ledgerService: LedgerServiceService,
    private snackBar: MatSnackBar,


  ){
    
  }
  getEmis(id: string, content: any,code:any) {
    this.ledgerService.getEmiData(id).subscribe(
      (data: any) => {
        console.log(data);
        var test = [];
        var arr = new Array(data.emi.length);
        var temp = data.emi[0].installments;

        this.emi_Data = data.subscription;
        console.log(data)
       

        for (var i = 1; i < data.emi.length; i++) {
        
          if (data.emi[i].installments == temp) {
          console.log(data.emi[i],temp);
            arr[i] = 1;
          } else {
            temp = data.emi[i].installments;
            arr[i] = 0;
          }
        }

        for (var j = 0; j < data.emi.length; j++) {
          if (arr[j] == 1) {
            test.push({
              plan_id: '',
              code: '',
              installments: '',
              plan_name: '',
              plan_date: '',
              amount: data.emi[j].amount,
              is_online: data.emi[j].is_online,
              rate: data.emi[j].rate,
              weight: data.emi[j].weight,
              payment_mode: data.emi[j].payment_mode,
              payment_id: data.emi[j].payment_id,
              settlement_date: data.emi[j].settlement_date,
              settlement_id: data.emi[j].settlement_id,
            });
          } else {
            test.push(data.emi[j]);
          }
        }

        this.plan_dataSource = test;

        this.modalService.open(content, { centered: true, size: <any>'xl' });


      },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }

  ngOnInit():void{
    this.titleService.setTitle('Pending Installments');
    this.popupManager = new PopUpManager(this.snackBar);

    this.dataSource = new PendingInstallmentDataSource(this.pendingInstallmentsService);
    this.dataSource.loadData(0, this.search, this.ordering, this.search);
  }
  searchOption() {
    const regex = /^(?=.*[a-zA-Z])(?=.*\d)/;
    if (regex.test(this.search)) {
      this.search = this.search.replace(/\s+/g, '');
    }

    this.resetAndDisplayTable();
  }
  ngAfterViewInit(): void {
    this.dataSource.counter$.subscribe((count) => {
      this.paginator.length = count;
    });
    this.paginator.page.subscribe(() => {
      this.popupManager.openSnackBar('Loading', 'cancel');
      this.dataSource.loadData(
        this.paginator.pageIndex,
        this.search,
        this.ordering,
        this.status
      );
    });


  }
  resetAndDisplayTable() {
    this.popupManager.openSnackBar('Loading', 'cancel');
    this.paginator.pageIndex = 0;
    this.dataSource.loadData(0, this.search, this.ordering, this.status);
  }
  getColor(status: any) {
    if (status == 'In Progress' || status == "IP") {
      return 'blue';
    } else if (status == 'Completed' ||  status == "CP")  {
      return 'green';
    }

    else if (status=='Billed')
    {
      return 'orange'
    }
    else if (status=='Paused' || status=='PS'){
      return 'purple'
    }
    else {
      return 'red';
    }

  }
  clearInput() {this.search = '';}
  fetchStatus(value: any) {this.status = value;this.resetAndDisplayTable();}
  openModel2(content:any)
  {this.modalService.open(content)}
  BookingDetail(content: any, data: any) {
    this.modalService.open(content);  
    console.log(data)
  }
  downloadtDate(event: any) {
    this.tdate = moment(event).format('DD-MM-YYYY');
    this.download_to_date = moment(event).format('YYYY-MM-DD');
  }
  file_download(payment_status="all") {
    var fd = new FormData();

   
    this.modalService.dismissAll();

    fd.append('payment_status', payment_status);
 
    this.toastr.info(
      '',
      'Downloading...',
      { positionClass: 'toast-bottom-right' }
    );

    this.pendingInstallmentsService.downloadPendingPlans(fd).subscribe(
      (data:any) => {

        var final_data=data['data'];
        if(final_data.length==0){
          this.toastr.info(
            '',
            'No data found in the selected duration.',
            { positionClass: 'toast-bottom-right' }
          );
        }else{
          this.downloadCsvData(final_data);
          this.toastr.success('', data.message, {
            positionClass: 'toast-bottom-right',
          });
        }
      
      },
      (error: any) => {

        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }
  downloadCsvData(data:any) {
    // Get the three months of data
   
  
    // Generate the CSV data
    const headers = Object.keys(data[0]);

    // Generate the CSV data
    const csvData = this.generateCsvData(data,headers);
  
    // Create a Blob object from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });
  
    // Create a URL object from the Blob object
    const url = URL.createObjectURL(blob);
  
    // Create a new anchor element and set its href attribute to the URL object
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = 'orders.csv';
  
    // Click the anchor element to start the download
    anchorElement.click();
  }
  generateCsvData(data: any[], headers: string[]): string {
    let csvData = '';

    // Add headers to the CSV data
    csvData += headers.join(',') + '\n';

    // Iterate over the data and add it to the CSV string
    data.forEach((row: any) => {
        headers.forEach((header: string, index: number) => {
            // Ensure that the value is properly escaped
            let value = row[header];
            if (typeof value === 'string') {
                value = `"${value.replace(/"/g, '""')}"`;
            }
            csvData += (index > 0 ? ',' : '') + value;
        });
        csvData += '\n';
    });

    return csvData;
}
  downloadfDate(event: any) {
    this.fdate = moment(event).format('DD-MM-YYYY');
    this.download_from_date = moment(event).format('YYYY-MM-DD');

  }
}
