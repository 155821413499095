import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlToImageService {

  constructor(private http: HttpClient) { }

  getImageAsBase64(imageUrl: string): Promise<string> {
    return this.http.get(imageUrl, { responseType: 'blob' }).toPromise()
      .then((blob:any) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = (error) => {
            console.error('FileReader error:', error);
            reject(error);
          };
          reader.readAsDataURL(blob);
        });
      })
      .catch(error => {
        console.error('HTTP error:', error);
        throw error;
      });
  }
}
