import { Component, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CancelPlanService } from './cancel-plan.service';
import { formatDate } from '@angular/common';
import { BankAccountsList } from '../models/accounts';
import { InstallmentServiceService } from '../create_launch_plans/add-installments/installment-service.service';
import { Toast, ToastrService } from 'ngx-toastr';
import { error } from 'jquery';
import { CancelPlan } from '../models/cancelplan';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { content } from 'html2canvas/dist/types/css/property-descriptors/content';
import { RateCutService } from '../rate_cut_feature/ratecut/rate-cut.service';

@Component({
  selector: 'app-cancel-plan',
  templateUrl: './cancel-plan.component.html',
  styleUrls: ['./cancel-plan.component.css'],
})
export class CancelPlanComponent {
  @ViewChild('focus_deductions')focus_deductions!: ElementRef ;
  vr_no: any;
  planID: any;
  name: any;
  phone: any;
  plan_amount: any;
  installment_paid: any;
  weight_per_installment: any;

  amount_collected: any;
  deductions:any=0;
  final_amount: any;

  cancel_date: any;
  payment_date: any;
  bank_: any;
  p_mode: any='7';
  comments: any;

  edit_enable = false;

  data_of_cancelled_plan!: CancelPlan;

  account_close_voucher = false;
  show_cancel = true;
  editCancel: any;

  print_plan: any;
  is_booking_cancel:boolean=false;

  account_list: BankAccountsList = new BankAccountsList();
  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private cancelService: CancelPlanService,
    private installmentService: InstallmentServiceService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private router: Router,
    private ratecutservice:RateCutService,
  ) {}

  printDiv(): void {
    const remove_button=document.getElementById('two_buttons');
    remove_button!.style.display="none";
    const printableDiv = document.getElementById('printable-content')!;
    const scale = 2;
    const canvasWidth = printableDiv.offsetWidth * scale;
const canvasHeight = printableDiv.offsetHeight * scale;

    html2canvas(printableDiv,{scale:scale}).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg',1.0);
      const pdf = new jsPDF('l', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
// new


// Calculate the aspect ratio to maintain the content's proportions
const aspectRatio = canvasWidth / canvasHeight;
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / aspectRatio;

      if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * aspectRatio;
      }
      const xPos = (pdfWidth - imgWidth) / 2;
      const yPos = (pdfHeight - imgHeight) / 2;
      pdf.addImage(imgData, 'JPEG',xPos, yPos, imgWidth, imgHeight);
      // pdf.save('print.pdf');
      pdf.autoPrint();
      window.open(pdf.output('bloburl'), '_blank');
    });

    remove_button!.style.display="flex";
  }
  ngOnInit(): void {

    if (this.route.snapshot.queryParamMap.get('planN') != null) {
      this.planID = this.route.snapshot.queryParamMap.get('planN');
      this.vr_no = this.route.snapshot.queryParamMap.get('vr_no');
      this.name = this.route.snapshot.queryParamMap.get('name');
      this.phone = this.route.snapshot.queryParamMap.get('phone');
      this.plan_amount = this.route.snapshot.queryParamMap.get('amount');
      this.installment_paid = this.route.snapshot.queryParamMap.get('paid');
      this.weight_per_installment =
        this.route.snapshot.queryParamMap.get('weight');
      const stringAfterSlash = this.weight_per_installment.substr(
        this.weight_per_installment.lastIndexOf('/') + 1
      );
      this.weight_per_installment = stringAfterSlash;

      this.cancel_date = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
      this.payment_date = this.cancel_date;
      this.getPaymentAccounts();

      this.cancelService.cancelData(this.planID).subscribe((data: any) => {
        this.amount_collected = data.total_paid;
        this.final_amount = this.amount_collected;
      });
    }
    if(this.route.snapshot.queryParamMap.get('booking')=='true')
    {
      this.is_booking_cancel=true
      this.planID=this.route.snapshot.queryParamMap.get('planNo');
      this.name = this.route.snapshot.queryParamMap.get('name');
      this.phone = this.route.snapshot.queryParamMap.get('phone');
      this.plan_amount = this.route.snapshot.queryParamMap.get('amount');
      this.weight_per_installment =this.route.snapshot.queryParamMap.get('weight');
      this.installment_paid = this.route.snapshot.queryParamMap.get('paid');

      this.amount_collected=this.plan_amount
      this.amountPayable()
    }
    this.editCancel = this.route.snapshot.paramMap.get('planN');
    if (this.editCancel) {
      this.edit_enable = true;
      this.planID = this.editCancel;
      this.getCancelledData();
    }
    
    this.cancel_date = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.payment_date = this.cancel_date;
    this.getPaymentAccounts();

    this.print_plan = this.route.snapshot.paramMap.get('print_planN');
    if (this.print_plan) {
      this.edit_enable = true;
      this.planID = this.print_plan;

      this.getCancelledData();
      this.account_close_voucher = true;
      this.show_cancel = false;

      setTimeout(() => {
        this.printDiv();
      }, 1000);
    }


    setTimeout(() => {
      this.focus_deductions.nativeElement.focus();
    }, 10);

  }



  openModel(content: any) {
    this.router.navigate(['/dashboard/customer_ledger']);
    this.modalService.open(content, { centered: true });
  }

  amountPayable() {
    this.final_amount = (this.amount_collected) -(this.deductions);
    if (Number.isNaN(this.final_amount) || this.final_amount<0)
    {
      this.toastr.info('', 'Please Enter Valid Deductions', {
        positionClass: 'toast-bottom-right',
      });
    }
  }

  getPaymentAccounts() {
    this.installmentService.getPayments().subscribe((data: any) => {
      this.account_list = data;
      console.log(this.account_list)
    });
  }

  openWarning(content: any) {
    console.log(this.deductions, typeof this.deductions);
    if (typeof(this.deductions)=='undefined') {
      this.deductions = 0;
    }

    if (
      this.deductions >= 0 &&
      (this.bank_ || this.p_mode == 7 || this.p_mode == 4 || this.p_mode== 9) && this.p_mode && this.final_amount > 0
    ) {
      this.modalService.open(content, { centered: true });
    } else {
      this.toastr.info('', 'Please Enter Valid Input', {
        positionClass: 'toast-bottom-right',
      });
    }
  }
  cancelPlan() {

    console.log(this.deductions, typeof this.deductions);
    if (this.deductions == 0) {
      this.deductions = 0;
    }

    this.cancelService
      .cancelPlan(
        this.planID,
        this.deductions,
        this.final_amount,
        this.cancel_date,
        this.p_mode,
        this.bank_,
        this.payment_date,
        this.comments
      )
      .subscribe(
        (data: any) => {
          this.account_close_voucher = true;
          this.show_cancel = false;
          this.getCancelledData();
          this.toastr.success('', data.message, {
            positionClass: 'toast-bottom-right',
          });

        },
        (error: any) => {
          this.toastr.info('', error.error.message, {
            positionClass: 'toast-bottom-right',
          });
        }
      );
  }
  cancelBooking(content:any)
  {
    if (this.deductions == 0) {
      this.deductions = 0;
    }
    this.ratecutservice
      .cancelBooking(
        this.planID,
        this.deductions,
        this.final_amount,
        this.cancel_date,
        this.p_mode,
        this.bank_,
        this.payment_date,
        this.comments
      )
      .subscribe(
        (data: any) => {


          this.toastr.success('', data.message, {
            positionClass: 'toast-bottom-right',
          });
          this.router.navigate(['/dashboard/view_bookings']);
          this.modalService.open(content, { centered: true });
        },
        (error: any) => {
          this.toastr.info('', error.error.message, {
            positionClass: 'toast-bottom-right',
          });
        }
      );
  }

  redirectToVoucher() {
    if (this.deductions >= 0 && this.p_mode && this.bank_) {
      this.account_close_voucher = true;
      this.show_cancel = false;
    } else {
      this.toastr.info('', 'Please Type Valid Input', {
        positionClass: 'toast-bottom-right',
      });
    }
  }

  revertToCancel() {
    this.editCancel = true;
    this.account_close_voucher = false;
    this.show_cancel = true;
  }

  getCancelledData() {
    this.cancelService.getCancelledData(this.planID).subscribe((data: any) => {
      this.data_of_cancelled_plan = data;
      this.vr_no = this.data_of_cancelled_plan.vr_no;
      this.name = this.data_of_cancelled_plan.name;
      this.phone = this.data_of_cancelled_plan.phone;
      this.plan_amount = this.data_of_cancelled_plan.plan_amount; //
      this.installment_paid = this.data_of_cancelled_plan.installments; //
      this.weight_per_installment = this.data_of_cancelled_plan.weight; //

      this.cancel_date = formatDate(
        this.data_of_cancelled_plan.cancel_date,
        'yyyy-MM-dd',
        'en-US'
      );

      this.payment_date = formatDate(
        this.data_of_cancelled_plan.payment_date,
        'yyyy-MM-dd',
        'en-US'
      );

      this.p_mode = this.data_of_cancelled_plan.payment_mode;
      this.bank_ = this.data_of_cancelled_plan.offline_bank_account;


      this.amount_collected = this.data_of_cancelled_plan.amount_collected;
      this.deductions = this.data_of_cancelled_plan.deduction;
      this.final_amount = this.data_of_cancelled_plan.amount_payable;
      this.comments = this.data_of_cancelled_plan.comments;

      // this.getPaymentAccounts();
    });
  }

  defaultBank(value:any)
  {
    if(value.value=="7" || value.value=="9")
    {
      this.bank_=""
      return
    }
    this.bank_=this.account_list.bank_accounts[0].id
  }

  editCancelPlan() {
    this.cancelService
      .editCancelPlan(
        this.planID,
        this.p_mode,
        this.bank_,
        this.payment_date,
        this.comments
      )
      .subscribe(
        (data: any) => {
          this.account_close_voucher = true;
          this.show_cancel = false;
          this.toastr.success('', data.message, {
            positionClass: 'toast-bottom-right',
          });
        },
        (error: any) => {
          this.toastr.info('', error.error.message, {
            positionClass: 'toast-bottom-right',
          });
        }
      );
  }
}
