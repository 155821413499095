import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddInstallmentsComponent } from 'src/app/create_launch_plans/add-installments/add-installments.component';
import { InstallmentServiceService } from 'src/app/create_launch_plans/add-installments/installment-service.service';
import { InstallmentDataFromID } from 'src/app/models/installments';
import { PopUpData } from 'src/app/templates/warning-popup/warning-popup/warning-popup.component';
import { AnimationOptions } from 'ngx-lottie';
import { ToastrService } from 'ngx-toastr';
import { PageAccessService } from 'src/app/services/pageaccess.service';


@Component({
  selector: 'app-add-installment',
  templateUrl: './add-installment.component.html',
  styleUrls: ['./add-installment.component.css']
})
export class AddInstallmentComponent {
  @ViewChild('already_paid', { read: TemplateRef })
  already_paid_template!:TemplateRef<any>;

  @ViewChild('cancelled', { read: TemplateRef })
  cancelled_template!:TemplateRef<any>;

  @ViewChild('completed', { read: TemplateRef })
  completed_template!:TemplateRef<any>;

  @ViewChild('paused', { read: TemplateRef })
  paused_template!:TemplateRef<any>;


  allowed_actions: { [key: string]: boolean } = {};


  show_add_installment: boolean = true;
  plan_detail:InstallmentDataFromID = new InstallmentDataFromID({});
  plan_id:string = "";
  is_valid_plan:boolean = false;
  
  completed_plan_lottie_file_path : AnimationOptions = {path:'/assets/lottie/completed_animation.json'};
  cancelled_plan_lottie_file_path : AnimationOptions = {path:'/assets/lottie/cancelled_animation.json'};
  paused_plan_lottie_file_path : AnimationOptions = {path:'/assets/lottie/paused_animation.json'};


  completed_plan_data:PopUpData = new PopUpData("Completed Plan","This plan has been completed.","You cannot make any installments for this plan.","Close");
  cancelled_plan_data:PopUpData = new PopUpData("Cancelled Plan","This plan has been cancelled.","You cannot make any installments for this plan.","Close");
  paused_plan_data:PopUpData = new PopUpData("Paused Plan","This plan has been paused.","Please Contact Admin","Close");
  warning_data:PopUpData = new PopUpData("Warning","The installment for this date has already been received.","Are you sure you want to make another installment?","Proceed");
  

  current_date_validation=this.getCurrentDate();

  constructor(
    private installmentService:InstallmentServiceService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private authservice :PageAccessService,

  ){}
togglePayment ()
{this.show_add_installment = !this.show_add_installment;}

afterSuccess(value:boolean){
  this.modalService.dismissAll();
  this.togglePayment();
  this.plan_id = "";
  this.is_valid_plan = false;
}

proceedAfterWarning(value:boolean){
  if(value && this.allowed_actions['AdditionalInstallment']){this.is_valid_plan = true;}
  else{this.is_valid_plan = false;}
  this.modalService.dismissAll();
}

getPlanUsingCode(code:string){
  this.installmentService.getInstallmentData(code).subscribe((plan_data:InstallmentDataFromID)=>{
    plan_data.code=code;
    this.plan_detail = new InstallmentDataFromID(plan_data);
    if(this.isAlreadyPaid()){this.modalService.open(this.already_paid_template,{centered:true})}
    else{this.is_valid_plan = true;}
    
  },(error:any)=>{this.toastr.info('', 'Please fill Valid plan id ', {positionClass: 'toast-bottom-right',});;console.log(error.error);this.handlePopUp(error.error.status);this.is_valid_plan = false;})
}

handlePopUp(value:string){
  if (value=='CL'){this.modalService.open(this.cancelled_template,{centered:true})}
  if(value=='CP'){this.modalService.open(this.completed_template,{centered:true})}
  if(value=='CD'){this.modalService.open(this.completed_template,{centered:true})}
  if(value=='PS'){this.modalService.open(this.paused_template,{centered:true})}
}

ngOnInit(): void {
  this.allowed_actions=this.authservice.allowedActions;

}

isAlreadyPaid(){return this.plan_detail.last_paid == this.current_date_validation;}


getCurrentDate(): string {
  const today = new Date();
  let day: string = String(today.getDate());
  let month: string = String(today.getMonth() + 1);
  const year: string = String(today.getFullYear());
  if (day.length < 2) {day = '0' + day;}
  if (month.length < 2) {month = '0' + month;}
  return `${year}-${month}-${day}`;
  }
}

