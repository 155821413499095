import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indianNumeration'
})
export class IndianNumerationPipe implements PipeTransform {
  transform(value: number | string): string|Number {
    const parsedValue = Number(value);
    if (!isNaN(parsedValue)) {
      return new Intl.NumberFormat('en-IN').format(parsedValue);
    }
    return '';
  }
}
