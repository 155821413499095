import { Component, ViewChild } from '@angular/core';
import { ViewOrderDataSource } from '../view-order/view-order.dataSource';
import { DesignService } from '../view-order/design.service';
import { MatPaginator } from '@angular/material/paginator';
import { PopUpManager } from 'src/app/manager/Popup';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delivery-table',
  templateUrl: './delivery-table.component.html',
  styleUrls: ['./delivery-table.component.css','../view-order/view-order.component.css']
})
export class DeliveryTableComponent {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'delivery_date',
    'order_no',
    'customer',
    'item',
    'weight',
    'invoice_no'];

  deliveryDataSource!:ViewOrderDataSource;
  selected_vendors:any=[];
  search = "";
  popUpManager!: PopUpManager;
  page=0;


  constructor(private desingService:DesignService,
    private snackBar: MatSnackBar,
    private router:Router,

  ) { }

  ngOnInit():void{
    this.getDeliveryData();
  }
  getDeliveryData(){
    this.deliveryDataSource = new ViewOrderDataSource(this.desingService);
    this.deliveryDataSource.loadData(this.page,this.search,['3'],this.selected_vendors);
    this.popUpManager = new PopUpManager(this.snackBar);}

  ngAfterViewInit(): void {
    this.deliveryDataSource.counter$.subscribe((count) => {this.paginator.length = count;});
    this.paginator.page.subscribe(() => {
      this.deliveryDataSource.loadData(
        this.paginator.pageIndex,
        this.search,
        ['3'],
        this.selected_vendors); });}

  redirectToOrder(id:number)
  {this.router.navigate(['/dashboard/order/',{
    // order id to get the data
    id:id}])}
}
