import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byteToMb'
})
export class ByteToMbPipe implements PipeTransform {

  transform(value: any): string {
    if (value === null || isNaN(value)) {
      return '0 MB';
    }
  
    const bytes = parseFloat(value);
    if (bytes < 1024 * 1024) {
      // If less than 1 MB, show in kilobytes
      const kilobytes = bytes / 1024;
      return kilobytes.toFixed(2) + ' KB';
    }
  
    // Otherwise, show in megabytes
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2) + ' MB';
  }

}
