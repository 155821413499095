import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appNextInput]',
    standalone: true
})
export class NextInputDirective {
    constructor(private el: ElementRef) { }

    @HostListener('keydown.enter', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        event.preventDefault();

        this.focusOnNextElementIfFilled();
    }

    @HostListener('selectionChange', ['$event'])
    onSelectionChange(event: Event): void {
        this.focusOnNextElementIfFilled();
    }

    private focusOnNextElementIfFilled(): void {
        const currentInput = this.el.nativeElement;
        if (currentInput.tagName === ('INPUT'||'SELECT') && currentInput.value.trim() !== '') {
            const focusableElements = this.getFocusableElements();
            const currentIndex = focusableElements.indexOf(currentInput);

            if (currentIndex !== -1 && currentIndex < focusableElements.length - 1) {
                const nextElement = focusableElements[currentIndex + 1] as HTMLElement;
                nextElement.focus();
            }
        } 
        // else if(currentInput.tagName === "MAT-SELECT") {
        //     const focusableElements = this.getFocusableElements();
        //     const currentIndex = focusableElements.indexOf(currentInput);
        //     if (currentIndex !== -1 && currentIndex < focusableElements.length - 1) {
        //         const nextElement = focusableElements[currentIndex + 1] as HTMLElement;
        //         nextElement.focus();
        //     }
        // }
    }

    private getFocusableElements(): HTMLElement[] {
        const focusableElements = Array.from(document.querySelectorAll('mat-select, input,select'));
        console.log(focusableElements)
        return focusableElements.filter((element) => !element.hasAttribute('disabled')) as HTMLElement[];
    }
}