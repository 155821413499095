import {  DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {  BookingData } from "src/app/models/booking";
import { CreateOrderService } from "src/app/oms/create-order/create-order.service";
import { OrderDataSource } from "./all-reports-dataSource";
import { OrderReportData } from "src/app/models/orderDetail";



export class CustomOrderDataSource extends DataSource<any> {

  private customOrderData=new BehaviorSubject<OrderReportData[]>([]);
  private loadingSettlements = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSettlements.asObservable();
  private countSettlements=new BehaviorSubject<number>(0);
  public counter$=this.countSettlements.asObservable();




  constructor(private omsService:CreateOrderService) {
    super();
  }
  connect(): Observable<OrderReportData[]> {
    return this.customOrderData.asObservable();

  }
  disconnect() {
    this.customOrderData.complete();
    this.loadingSettlements.complete();
  }
  loadData(page:number=0,from_date: any,to_date: any,search:any,filter:any){


      page=page+1;
      this.loadingSettlements.next(true);
      this.omsService.getOmsReport(page=page,from_date=from_date,to_date=to_date,search=search,filter=filter).pipe(
        catchError(()=> of([])),
        finalize(()=>{
          this.loadingSettlements.next(false);
        })

      ).subscribe((settlementLogs: any)=>this.renderOrders(settlementLogs))

  }

  renderOrders(settlementLogs:any){

    this.countSettlements.next(settlementLogs.count);
    this.customOrderData.next(settlementLogs.results);
    console.log(settlementLogs.results)

  }





}