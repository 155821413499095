export class CertificateData {

  // setup info
    // header: string = '';
    id!:number;
    store_name: string = '';
    address: string = '';
    
    store_image: any = null;
    shop_image_url : any =null;

    sub_brand_image:any = null;
    sub_brand_image_url:any=null;

    footer: string = '';
    // title: string = '';
    is_saved: boolean = false;

    title:string= '';

    certificate_number: string = '';
    item_code_or_huid: string = '';
    description: string = '';
    item_image: any = null;
    is_overflow:boolean=false;
    [key: string]: any; // Index signature for dynamic properties
    
    raw_certificate_content: { key: string; value: string }[] = [];


    constructor(data:any) {
        this.id = data.id;
        this.store_name = data.store_name;
        this.address = data.address;
        this.certificate_number = data.certificate_number;
        this.item_code_or_huid = data.item_code_or_huid;
        this.description = data.description;
        this.title = data.title? data.title:"Certificate of Authenticity";
        this.item_image = data.item_image;
        this.store_image = data.store_image;
        this.sub_brand_image = data.sub_brand_image;
        this.footer=data.footer;
        this.shop_image_url=data.shop_image_url;
        this.sub_brand_image_url=data.sub_brand_image_url;
        this.is_saved = false;
    
    }

    updateUrl(){
      this.shop_image_url+="?not-from-cache-please";
      this.sub_brand_image_url+="?not-from-cache-please";
    }
    deleteShopImage()
    {
      this.store_image=null;
      this.shop_image_url="";
    }
    deleteSubBrandImage()
    {
      this.sub_brand_image=null;
      this.sub_brand_image_url="";
    }


    convertToFormData(): FormData {
      const formData = new FormData();
  
      if (this.store_image) {
        const mimeType = this.store_image.split(',')[0].split(':')[1].split(';')[0];
        const certificateStoreImageBlob = this.base64ToBlob(this.store_image, mimeType);
        formData.append('shop_image', certificateStoreImageBlob, 'store_image.png');
      }
      if(this.sub_brand_image){
        const mimeType = this.sub_brand_image.split(',')[0].split(':')[1].split(';')[0];
        const certificateSubBrandImageBlob = this.base64ToBlob(this.sub_brand_image, mimeType);
        formData.append('sub_brand_image', certificateSubBrandImageBlob, 'sub_brand_image.png');
      }
  
      formData.append('store_name', this.store_name);
      formData.append('address', this.address);
      formData.append('title',this.title);
      formData.append('footer', this.footer);
      
      return formData;
    }
  
    base64ToBlob(base64: string, mime: string): Blob {
      const byteChars = atob(base64.split(',')[1]);
      const byteNumbers = new Array(byteChars.length);
      for (let i = 0; i < byteChars.length; i++) {
        byteNumbers[i] = byteChars.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mime });
    }

 
  }

export class RawCertificateData{
    [key: string]: any; // Index signature for dynamic properties
    private constructor() {}
  
    static create(init?: Partial<CertificateData>): CertificateData {
      const instance = new CertificateData({});
      if (init) {Object.assign(instance, init);}
      return instance;
    }
    getKey(): string {return Object.name;}


}

export class ListCertificate{
  id!:number;
  certificate_code!:number| string;
  description!:string;
  image!:string;
  raw_data!:string;
  image_url!:string;
}