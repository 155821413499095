import {  DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Ledger } from "src/app/models/ledger";
import { Rest } from "../../models/rest_model";
import { LedgerServiceService } from "./ledger-service.service";


export class LedgerDataSource extends DataSource<any> {

  private ledgerData=new BehaviorSubject<Ledger[]>([]);
  private loadingledger = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingledger.asObservable();
  private countledgers=new BehaviorSubject<number>(0);
  public counter$=this.countledgers.asObservable();



  constructor(private ledgerService:LedgerServiceService) {
    super();
  }
  connect(): Observable<Ledger[]> {
    return this.ledgerData.asObservable();

  }
  disconnect() {
    this.ledgerData.complete();
    this.loadingledger.complete();
  }
  loadData(page:number=0,search: any,ordering:any,status:any){


      page=page+1;
      this.loadingledger.next(true);
      this.ledgerService.getLedgers(page=page,search=search,ordering=ordering,status=status).pipe(
        catchError(()=> of([])),
        finalize(()=>{
          this.loadingledger.next(false);
        })

      ).subscribe(ledgerLogs=>this.renderOrders(ledgerLogs))

  }

  renderOrders(ledgerLogs:any){
    this.countledgers.next(ledgerLogs.count);
    this.ledgerData.next(ledgerLogs.results);


  }




}