import {  DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Customer } from "../models/customer";

import { CustomerdetailsService } from "./customerdetails.service";


export class CustomerDataSource extends DataSource<any> {

    
  private customerData=new BehaviorSubject<Customer[]>([]);
  private loadingcustomerData = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingcustomerData.asObservable();    
  private countcustomerData=new BehaviorSubject<number>(0);
  public counter$=this.countcustomerData.asObservable();


    constructor(private customerdetailService:CustomerdetailsService) {
        super();
      }

      connect(): Observable<Customer[]> {
        return this.customerData.asObservable();
    
      }
      disconnect() { 
        this.customerData.complete();
        this.loadingcustomerData.complete();
      }

      loadData(page:number=0,search: any,ordering:any,status:any){


        page=page+1;
        this.loadingcustomerData.next(true);
        this.customerdetailService.getUsers(page=page,search=search,ordering=ordering,status=status).pipe(
          catchError(()=> of([])),
          finalize(()=>{
            this.loadingcustomerData.next(false);
          })
          
        ).subscribe(customerLogs=>this.renderCustomerDetails(customerLogs))
      
    }
    renderCustomerDetails(customerLogs:any){
        this.countcustomerData.next(customerLogs.count);
        this.customerData.next(customerLogs.results);
    
      }

 
}