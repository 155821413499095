import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router, Routes } from '@angular/router';
import { Estimation, Purity } from 'src/app/models/estimation-model';
import { EstimationService } from '../estimation-service';
import { Location } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { parse } from 'papaparse';
import { RateServiceService } from 'src/app/rate-page/rate-service.service';
import { RateFinal } from 'src/app/models/rate';

@Component({
  selector: 'ngbd-modal-basic',
  templateUrl: './view_estimation.component.html',
  styleUrls: ['./view_estimation.component.css'],
})
export class ViewEstimationComponent implements OnInit {
  estimation_id: any;
  estimation: Estimation = new Estimation();

  rate_time!: any;
  rates!: RateFinal;

  wastage_types = [
    {
      name: "in %",
      value: 1,
    },
    {
      name: "in gms",
      value: 0,
    }
  ];

  making_charge_types = [{
    name: "G.wt /gm",
    value: 0,
  }, {
    name: "N.wt /gm",
    value: 1,
  }, {
    name: "/pc",
    value: 2,
  }];

  stone_charge_types = [{
    name: "/Ct",
    value: 0,
  }, {
    name: "/Gm",
    value: 1,
  }, {
    name: "/pc",
    value: 2,
  }];

  constructor(
    public http: HttpClient,
    public modalService: NgbModal,
    private router: Router,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private _location: Location,
    private rateService: RateServiceService,
    private estimationService: EstimationService) {

  }

  ngOnInit(): void {
    // this.estimation_id = history.state.id;
    // console.log(this.estimation_id); // log the received data
    // this.getEstimationDetails(this.estimation_id);      
    this.route.params.subscribe(params => {
      this.estimation_id = params['estimation_id'];
      console.log(this.estimation_id); // log the received data
      this.getEstimationDetails(this.estimation_id);
    });
  }

  back() {
    this._location.back();
  }

  getEstimationDetails(no: any) {
    this.estimationService.getEstimation(no).subscribe((res: any) => {
      console.log(res);
      this.estimation = res;
      this.estimation.date = moment(this.estimation.date).format('DD-MM-YYYY');
      // if (this.estimation.live_rate == true) {
      //   console.log("live rate");
      //   this.getRate();
      // } else {
      //   console.log("not live rate");
      // }
    }, error => {
      console.log(error);
      this.toastr.error('Error', 'Error while fetching Estimation Details');
    });
  }

  getRate() {
    this.rateService.getLatestRates().subscribe(
      (data: any) => {
        try {
          this.rates = data;
          console.log(this.rates);
          this.rates.rates.forEach((element: any) => {
            var estimationMetal = this.estimation.metal == 0 ? 'Gold' : this.estimation.metal == 2 ? 'Silver' : 'Platinum';
            // console.log(element.material + estimationMetal + " " + this.estimation.purity?.name);
            if (element.material == estimationMetal + " " + this.estimation.purity?.name) {
              console.log(element.rate);
              this.estimation.rate_per_gram = element.rate;
              console.log(this.estimation.rate_per_gram);
              this.updateTotalAmount();
            }
          }
          );
        } catch (e) {
          console.log(e);
        }
      },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }

  updateTotalAmount() {
    var total_amount = 0;
    var value1 = 0;
    var value2 = 0;
    var value3 = 0;
    if (this.estimation.wastage_type == 1) {
      var gms = parseFloat(this.estimation.net_weight!) + (parseFloat(this.estimation.net_weight!) * (parseFloat(this.estimation.wastage!) / 100));
      value1 = gms * parseFloat(this.estimation.rate_per_gram!);
    } else if (this.estimation.wastage_type == 1) {
      value1 = parseFloat(this.estimation.gross_weight!) * parseFloat(this.estimation.rate_per_gram!);
    }
    if (this.estimation.making_charge_type == 0) {
      value2 = parseFloat(this.estimation.making_charge!) * parseFloat(this.estimation.gross_weight!);
    } else if (this.estimation.making_charge_type == 1) {
      value2 = parseFloat(this.estimation.making_charge!) * parseFloat(this.estimation.net_weight!);
    }
    value3 = this.calculateStoneCharge(this.estimation);
    var without_gst = value1 + value2 + value3;
    var gst = without_gst * (parseFloat(this.estimation.gst!) / 100);
    total_amount = without_gst + gst;
    this.estimation.total_amount = parseFloat(total_amount.toFixed(2)).toString();
  }

  makingChargeType(estimation: Estimation) {
    var name;
    for (let i = 0; i < this.making_charge_types.length; i++) {
      if (this.making_charge_types[i].value == estimation.making_charge_type) {
        name = this.making_charge_types[i].name;
      }
    }
    return name;
  }

  getMakingCharge(estimation: Estimation) {
    var making_charge = 0;
    if (estimation.making_charge != null && estimation.making_charge != undefined && estimation.gross_weight != null && estimation.gross_weight != undefined && estimation.net_weight != null && estimation.net_weight != undefined) {
      if (estimation.making_charge_type == 0) {
        making_charge = parseFloat(estimation.making_charge) * parseFloat(estimation.gross_weight);
      } else if (estimation.making_charge_type == 1) {
        making_charge = parseFloat(estimation.making_charge) * parseFloat(estimation.net_weight);
      }
      return making_charge;
    } else {
      return 0;
    }
  }

  wastageType(type: any) {
    var name;
    for (let i = 0; i < this.wastage_types.length; i++) {
      if (this.wastage_types[i].value == type) {
        name = this.wastage_types[i].name;
      }
    }
    return name;
  }

  calculateStoneCharge(estimation: Estimation) {
    var stones = estimation.stone_estimation;
    if (stones != null && stones?.length > 0) {
      var total = 0;
      for (let i = 0; i < stones!.length; i++) {
        total = total + parseFloat(stones![i].cost.toString());
      }
      return total;
    } else {
      return 0;
    }
  }
}