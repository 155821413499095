import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EstimationApiResponse } from '../models/estimation-model';
import { CatalogueService } from '../catalogue/catalogue-service.service';
import { JewelleryMetalPurityModel } from '../models/jewellery-metal-purity-model';
import * as moment from 'moment';
import { RateServiceService } from '../rate-page/rate-service.service';
import { RateFinal } from '../models/rate';
import { ToastrService } from 'ngx-toastr';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router, Routes } from '@angular/router';
import { AnalyticService } from '../services/analytics.service';
import { EstimationService } from '../estimation/estimation-service';

@Component({
  selector: 'app-mobile-estimation',
  templateUrl: './mobile-estimation.component.html',
  styleUrls: ['./mobile-estimation.component.css']
})
export class MobileEstimationComponent {
  @ViewChild('addStoneChargePopup', { read: TemplateRef }) addStoneChargePopup!: TemplateRef<any>;
  @ViewChild('wastagePopup', { read: TemplateRef }) wastagePopup!: TemplateRef<any>;
  @ViewChild('makingChargePopup', { read: TemplateRef }) makingChargePopup!: TemplateRef<any>;
  @ViewChild('metalPurity', { read: TemplateRef }) metalPurity!: TemplateRef<any>;
  @ViewChild('imageUpload', { read: TemplateRef }) imageUpload!: TemplateRef<any>;
  @ViewChild('attachImageLink', { read: TemplateRef }) attachImageLink!: TemplateRef<any>;
  @ViewChild('successModal', { read: TemplateRef }) successModal!: TemplateRef<any>;

  @ViewChild('grossWeightInput') grossWeightInput!: ElementRef;
  @ViewChild('nettWeightInput') nettWeightInput!: ElementRef;
  @ViewChild('wastageInput') wastageInput!: ElementRef;
  @ViewChild('wastageChargeTypeInput') wastageChargeTypeInput!: ElementRef;
  @ViewChild('makingChargeInput') makingChargeInput!: ElementRef;
  @ViewChild('makingChargeTypeInput') makingChargeTypeInput!: ElementRef;
  @ViewChild('stoneInput') stoneInput!: ElementRef;
  @ViewChild('stoneNameInput') stoneNameInput!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;

  gross_wt: any;
  nett_wt: any;
  wastage: any;
  making_charge: any;
  stone: any;
  gst: any;
  total: any;
  rate: any;
  estimation_number: any;
  estimation_date: any;
  product_link!: String;
  product_asset: any;
  jewellery_id: any;
  
  stone_name: any;
  stone_quantity: any;
  stone_weight: any;
  stone_carat: any;
  stone_charge_type: any;
  stone_rate: any;
  stone_amount: any;

  stoneData: any[] = [];
  stoneCount: number = 0;

  metals: any[] = [];
  selected_metal: number = 0;
  metal_purity: any[] = [];
  activePurity = 0;
  selected_purity: any = null;
  is_metal_selected = false;

  is_share_visible = false;
  share_url = '';

  stone_charge_types = [{
    name: "/Ct",
    value: 0,
  }, {
    name: "/Gm",
    value: 1,
  }, {
    name: "/pc",
    value: 2,
  }];
  selectedStoneChargeType: number = this.stone_charge_types[0].value;

  making_charge_types = [{
    name: "G.wt /gm",
    value: 0,
  }, {
    name: "N.wt /gm",
    value: 1,
  }, {
    name: "/pc",
    value: 2,
  }];
  selectedMakingChargeType: number = this.making_charge_types[1].value;

  wastage_types = [
    {
      name: "in %",
      value: 1,
    },
    {
      name: "in gms",
      value: 0,
    }
  ];
  selectedWastageType: number = this.wastage_types[0].value;

  value1: any;
  value2: any;
  value3: any;

  gold: string = '';
  display_rate!: any;
  show_rate = false;
  rate_time!: any;
  rates!: RateFinal;

  estimationList: EstimationApiResponse | undefined;
  selectedTabIndex: any;
  selectedImageTabIndex: any;
  jewellery_metal_purity_model: JewelleryMetalPurityModel[] = new Array<JewelleryMetalPurityModel>();
  imageUrl: any = null;
  fileToUpload!: File;

  constructor(
    private router: Router,
    public http: HttpClient,
    public modalService: NgbModal,
    public bottomSheet: MatBottomSheet,
    private catalogueService: CatalogueService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private rateService: RateServiceService,
    private estimationService: EstimationService, 
    private analyticService: AnalyticService,
    private route: ActivatedRoute) {
    this.gross_wt;
    this.nett_wt;
    this.wastage;
    this.making_charge;
    this.stone;
    this.gst = 3;
    this.total;
    this.value1 = 0;
    this.value2 = 0;
    this.value3 = 0;
    this.selectedWastageType = 1;
    this.selectedMakingChargeType = 1;
    this.selectedStoneChargeType = 0;
  }
  ngAfterViewInit(): void {
    this.grossWeightInput.nativeElement.focus();
  }
  openModal(content: any) {
    console.log(content);
    if (content === 'addStoneChargePopup') {
      console.log('addStoneChargePopup');
      setTimeout(() => {
        if (this.stoneInput) {
          this.stoneInput.nativeElement.focus();
        }
      }, 200);
      this.bottomSheet.open(this.addStoneChargePopup);
    }
    else if (content === 'wastagePopup') {
      setTimeout(() => {
        if (this.wastageInput) {
          this.wastageInput.nativeElement.focus();
        }
      }, 200);
      this.bottomSheet.open(this.wastagePopup);
    } else if (content === 'makingChargePopup') {
      setTimeout(() => {
        if (this.makingChargeInput) {
          this.makingChargeInput.nativeElement.focus();
        }
      }, 200);
      this.bottomSheet.open(this.makingChargePopup);
    } else if (content === 'metalPurity') {
      this.getSelectedMetal(this.activePurity);
      this.bottomSheet.open(this.metalPurity);
    } else if (content === 'imageUpload') {
      this.bottomSheet.open(this.imageUpload);
    } else if (content === 'attachImageLink') {
      this.bottomSheet.open(this.attachImageLink);
    } else if (content === 'successModal') {
      this.bottomSheet.open(this.successModal, { disableClose: true });
    }
  }

  closeModal(): void {
    console.log(this.modalService.activeInstances);
    this.bottomSheet.dismiss();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const id = params['jewellery_id'];
      const link = params['link'];
      console.log(id);
      console.log(link);
      this.product_link = link;
      this.jewellery_id = id;
    });
    const jewelleryPurityRes = this.catalogueService.getJewelleryMetalPurityData();
    jewelleryPurityRes.subscribe((data: any) => {
      console.log(data.results);
      this.metals = [];
      for (let i = 0; i < data.count; i++) {
        // this.metals.push(data.results[i].name);
        this.jewellery_metal_purity_model.push(data.results[i]);
        for (let i = 0; i < data.results.length; i++) {
          // add metals only if it is not same as previous
          if (this.metals.indexOf(data.results[i].metal) == -1) {
            this.metals.push(data.results[i].metal);
          }
        }
        for (let i = 0; i < this.metals.length; i++) {
          this.metal_purity[i] = [];
          for (let j = 0; j < data.count; j++) {
            if (this.metals[i] == data.results[j].metal) {
              this.metal_purity[i].push({
                id: data.results[j].id,
                name: data.results[j].name,
                available_purity_type: data.results[j].available_purity_type,
              });
            }
          }
        }
      }
      console.log(this.metals);
      console.log(this.metal_purity);
    }, error => {
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
    // this.estimationService.getEstimationNum().subscribe((data: any) => {
    //   console.log(data);
    //   this.estimation_number = data['est_no'];
    // });
    var today = new Date();
    this.estimation_date = moment(today).format('YYYY-MM-DD');
    this.getRate();
    this.selectedWastageType = 1;
    this.selectedMakingChargeType = 1;
    this.selectedStoneChargeType = 0;
  }

  getRate() {
    this.rateService.getLatestRates().subscribe(
      (data: any) => {
        this.rate_time = data.last_updated;
        this.rates = data;
        console.log('rates' + this.rates);
        try {
          if (this.rates.rates.length != 0) {
            this.display_rate = this.rates.rates;
            this.show_rate = true;
            console.log(this.display_rate);
            this.rate = parseFloat(this.display_rate[0].rate == "---" ? 0 : this.display_rate[0].rate).toFixed(2);
          }
          this.selected_purity = this.metal_purity[this.activePurity][0].id;
          this.saveSelectedPurities();
        } catch (e) { }
      },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }

  getSelectedPurity(event: any) {
    console.log(event);
    this.selected_purity = event;
    this.saveSelectedPurities();
  }

  getSelectedMetal(event: any) {
    this.activePurity = event;
    console.log(this.activePurity);
    this.selected_metal = this.metals[this.activePurity];
    this.selected_purity = this.metal_purity[this.activePurity][0].id;
  }

  saveSelectedPurities() {
    console.log(this.selected_metal);
    console.log(this.selected_purity);
    console.log(this.metal_purity[this.activePurity]);
    var type = '';
    this.metal_purity[this.activePurity].forEach((element: any) => {
      // console.log('inside metal_purity');
      // console.log('available' + element.available_purity_type);
      // console.log('selected purities' + this.selected_purity);
      // console.log('id' + element.id)
      if (element.id == this.selected_purity) {
        type = element.available_purity_type;
      }
    });
    console.log('type' + type);
    this.display_rate.forEach((element: any) => {
      if (element.type == type) {
        this.rate = parseFloat(element.rate == "---" ? 0 : element.rate).toFixed(2);
      }
    });
    this.modalService.dismissAll();
  }

  onTabChanged(event: any) {
    console.log(event);
    this.selectedTabIndex = event.index;
    if (this.selectedTabIndex == 1) {
      this.openSpinnerDialog();
      this.estimationService.getEstimationsList().subscribe((data: any) => {
        console.log(data);
        this.estimationList = data;
        this.dialog.closeAll();
      }, (error: any) => {
        this.dialog.closeAll();
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      });
    } else {
      // this.estimationService.getEstimationNum().subscribe((data: any) => {
      //   console.log(data);
      //   this.estimation_number = data['est_no'];
      // });
      var today = new Date();
      console.log(today);
      this.estimation_date = moment(today).format('YYYY-MM-DD');
      this.getRate();
      this.selectedWastageType = 1;
      this.selectedMakingChargeType = 1;
      this.selectedStoneChargeType = 0;
      this.grossWeightInput.nativeElement.focus();
    }
  }

  onImageTabChanged(event: any) {
    this.selectedImageTabIndex = event.index;
  }

  chooseWastage(type: number) {
    console.log(type);
    this.selectedWastageType = type;
  }

  calculateWastage() {
    console.log(this.selectedWastageType);
    if (this.wastage == undefined || this.wastage == null || this.wastage == "") {
      this.wastage = 0;
      if (this.nett_wt == undefined || this.nett_wt == null || this.nett_wt == "") {
        this.value1 = 0;
        this.bottomSheet.dismiss();
      } else {
        this.value1 = this.nett_wt * this.rate;
        this.wastageInput.nativeElement.focus();
        this.openModal(this.wastagePopup);
        // this.wastageChargeTypeInput.nativeElement.focus();
      }
      console.log("wastage" + this.wastage);
    } else {
      if (this.selectedWastageType == 1) {
        console.log("nett" + this.nett_wt);
        var gms = parseFloat(this.nett_wt) + (this.nett_wt * (this.wastage / 100));
        console.log("gm" + gms);
        this.value1 = gms * this.rate;
        this.bottomSheet.dismiss();
      } else {
        var gms = parseFloat(this.nett_wt) + parseFloat(this.wastage);
        this.value1 = gms * this.rate;
        this.bottomSheet.dismiss();
      }
    }
    console.log("value1" + this.value1);
    this.calculateTotal(this.gst);
  }

  calculateMakingCharge() {
    if (this.making_charge == undefined || this.making_charge == null || this.making_charge == "") {
      this.making_charge = 0;
      this.value2 = this.nett_wt * this.rate;
    } else {
      if (this.selectedMakingChargeType == 0) {
        if (this.gross_wt == 0) {
          alert("Please enter gross weight");
          return;
        } else {
          this.value2 = parseFloat(this.gross_wt) * parseFloat(this.making_charge);
        }
      } else if (this.selectedMakingChargeType == 1) {
        this.value2 = parseFloat(this.nett_wt) * parseFloat(this.making_charge);
      } else {
        this.value2 = parseFloat(this.making_charge);
      }
    }
    this.calculateTotal(this.gst);
    this.bottomSheet.dismiss();
  }

  chooseMakingCharge(type: number) {
    console.log(type);
    this.selectedMakingChargeType = type;
  }

  getFromDate(event: any): void {
    console.log(event);
    this.estimation_date = moment(event).format('YYYY-MM-DD');
  }

  addRow(): void {
    if (this.stoneData.length != 0) {
      this.stoneCount++;
    }
    this.stoneData.push({
      name: '',
      quantity: 0.0,
      weight: 0.0,
      carat: 0,
      rate: 0.0,
      cost: 0.0,
      charge_type: 0
    });
  }

  removeRow(index: number): void {
    this.stoneData.splice(index, 1);
  }

  addStoneCharge(stoneNum: number) {
    this.stone = 0;

    console.log(this.stoneData);
    console.log(stoneNum);
    this.stoneData.forEach((element: any) => {
      if (element.rate == undefined || element.rate == null || element.rate == "") {
        element.rate = 0;
        element.cost = 0;
        element.carat = 0;
        element.weight = 0;
        this.stone = 0;
        this.value3 = 0;
        element.name = "";
        if (this.stoneData.length > 1) {
          this.stoneData.splice(this.stoneData.indexOf(element), 1);
        }
      } else {
        if (element.charge_type == "/Ct" || element.charge_type == 0) {
          element.charge_type = 0;
          console.log(element.carat)
          element.cost = (element.carat * element.rate).toFixed(2);
          console.log('carat' + element.carat);
          console.log('cost' + element.cost);
        } else if (element.charge_type == "/Gm" || element.charge_type == 1) {
          element.charge_type = 1;
          element.cost = (element.weight * element.rate).toFixed(2);
        } else if(element.charge_type == "/pc" || element.charge_type == 2) {
          element.charge_type = 2;
          element.cost = (element.rate * element.quantity).toFixed(2);
        }
        console.log('cost' + element.cost)
        console.log(this.stone);
        this.stone = parseFloat(this.stone ?? 0) + parseFloat(element.cost);
        console.log(this.stone);
      }
    });
    this.value3 = parseFloat(this.stone);
    this.total = this.value1 + this.value3;
    this.calculateTotal(this.gst);
  }

  saveStoneCharges(stoneNum: number) {
    this.stone = 0;

    console.log(this.stoneData);
    console.log(stoneNum);
    this.stoneData.forEach((element: any) => {
      if (element.rate == undefined || element.rate == null || element.rate == "") {
        element.rate = 0;
        element.cost = 0;
        element.carat = 0;
        element.weight = 0;
        this.stone = 0;
        this.value3 = 0;
        element.name = "";
        this.stoneData.splice(this.stoneData.indexOf(element), 1);
      } else {
        if (element.charge_type == "/Ct" || element.charge_type == 0) {
          element.charge_type = 0;
          console.log(element.carat)
          element.cost = (element.carat * element.rate).toFixed(2);
          console.log('carat' + element.carat);
          console.log('cost' + element.cost);
        } else if (element.charge_type == "/Gm" || element.charge_type == 1) {
          element.charge_type = 1;
          element.cost = (element.weight * element.rate).toFixed(2);
        } else if(element.charge_type == "/pc" || element.charge_type == 2) {
          element.charge_type = 2;
          element.cost = (element.rate * element.quantity).toFixed(2);
        }
        console.log(element.cost)
        console.log(this.stone);
        this.stone = parseFloat(this.stone ?? 0) + parseFloat(element.cost);
        console.log(element.cost);
      }
    });
    this.value3 = parseFloat(this.stone);
    this.total = this.value1 + this.value3;
    this.calculateTotal(this.gst);
    this.bottomSheet.dismiss();
  }

  calculateTotal(gst_input: any) {
    console.log(this.gross_wt + this.nett_wt + this.wastage + this.making_charge + this.stone_amount + gst_input);
    this.total = (this.value1 + this.value2 + this.value3);
    console.log('value1 ' + this.value1 + 'value2 ' + this.value2 + 'value3 ' + this.value3);
    if (this.gst != 0) {
      this.total = (this.total + (this.total * (gst_input / 100))).toFixed(2);
    } else {
      this.total = this.total.toFixed(2);
    }
    console.log(this.total);
  }

  updateGrossWeight(gross_input: any) {
    if(this.rate != 0) {
      console.log(gross_input);
    this.gross_wt = gross_input;
    this.grossWeightInput.nativeElement.blur();
    this.calculateWastage();
    }
  }

  updateNetWeight(net_input: any) {
    console.log(net_input);
    this.nett_wt = net_input;
    this.nettWeightInput.nativeElement.blur();
    this.wastageInput.nativeElement.focus();
    this.calculateWastage();
  }

  clearAll() {
    this.gross_wt = null;
    this.nett_wt = null;
    this.wastage = null;
    this.making_charge = null;
    this.stone_weight = null;
    this.stone_carat = null;
    this.stone_rate = null;
    this.stone_amount = null;
    this.stone = null;
    this.value1 = 0;
    this.value2 = 0;
    this.value3 = 0;
    this.total = null;
    this.gst = 3;
    this.stoneData = [];
    this.selected_purity = null;
    this.selected_metal = 0;
    this.activePurity = 0;
    this.selectedTabIndex = 0;
    this.share_url = '';
    this.is_share_visible = false;
    this.imageUrl = null;
    this.product_link = '';
    this.grossWeightInput.nativeElement.focus();
    this.bottomSheet.dismiss();
  }

  openSpinnerDialog(): void {
    const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed:', result); // Handle dialog close event
    });
  }


  createEstimate() {
    this.openSpinnerDialog();
    if (this.nett_wt == 0 || this.nett_wt == undefined || this.nett_wt == '') {
      alert("Please enter net weight");
      this.dialog.closeAll();
      return;
    } else {
      var data = {
        "estimation_no": this.estimation_number,
        "date": this.estimation_date,
        "rate_per_gram": this.rate,
        "gross_weight": this.gross_wt,
        "metal": this.selected_metal,
        "purity": this.selected_purity,
        "net_weight": this.nett_wt,
        "wastage": this.wastage,
        "wastage_type": this.selectedWastageType,
        "making_charge": this.making_charge,
        "making_charge_type": this.selectedMakingChargeType,
        "stone_estimation": this.stoneData,
        "total_amount": this.total,
        "gst": this.gst,
      }
      var formData = new FormData();

      if (this.fileToUpload != undefined && this.fileToUpload != null) {
        formData.append('asset_file', this.fileToUpload, this.fileToUpload.name);
      }
      formData.append('data', JSON.stringify(data));

      console.log(data);
      this.estimationService.createNewEstimation(formData).subscribe((res: any) => {
        console.log(res);
        if (res['detail'] != 0 || res['detail'] != null || res['detail'] != undefined) {
          this.modalService.dismissAll();
          this.dialog.closeAll();

          // this.ngOnInit();
          // this.ngAfterViewInit();
          this.is_share_visible = true;
          this.share_url = res['detail'];
          this.toastr.success(
            '',
            'Estimate created successfully',
            { positionClass: 'toast-bottom-right' }
          );
          this.analyticService.logEvent("create_estimation", {
            "medium": "web"
          });
          this.openModal('successModal');
        } else {
          this.toastr.error(
            '',
            'Something went wrong, please do try again later.',
            { positionClass: 'toast-bottom-right' }
          );
        }
      }, (error: any) => {
        this.dialog.closeAll();
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      });
    }
  }

  copyShareUrl(url: String) {
    console.log(url);
    navigator.clipboard.writeText(url.toString());
    this.toastr.success(
      '',
      'Copied to clipboard',
      { positionClass: 'toast-bottom-right' }
    );
  }

  openCamera() {
    const fileInput = document.querySelector('input[type="file"][capture="camera"]') as HTMLInputElement;
    fileInput.click();
  }

  onFileSelected(event: Event) {
    console.log(event);
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (files != null) {
      this.fileToUpload = files![0];
      if (files && files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (event) => {
          this.imageUrl = reader.result;
          this.bottomSheet.dismiss();
        }
      }
    } else {
      console.log(this.product_link);
      this.bottomSheet.dismiss();
      this.modalService.dismissAll();
    }
  }

  saveImageUrl(link: String) {
    console.log(link);
    this.product_link = link;
    try {
      const url = new URL(this.product_link.toString());
      const paths = url.pathname.split('/');
      this.jewellery_id = paths[paths.length - 1]; // get the last path segment
    } catch (error) {
      console.error('Error extracting jewellery_id:', error);
    }
    this.modalService.dismissAll();
  }

  removeImage() {
    // Clear the image URL
    this.imageUrl = null;
    this.product_link = '';
    this.jewellery_id = null;
  }

  viewImage() {
    console.log('viewImage' + this.product_link);
    this.router.navigate(['/catalogue/edit_catalogue'], { queryParams: { id:  this.jewellery_id} });
  }

  getEstimations() {
    this.estimationService.getEstimationsList().subscribe((res: any) => {
      console.log(res);
      this.estimationList = res;
      console.log(res.length);
      this.dialog.closeAll();
    }, (error: any) => {
      this.dialog.closeAll();
      this.toastr.info(
        '',
        'Something went wrong, please do try again later.',
        { positionClass: 'toast-bottom-right' }
      );
    });
  }

  deleteEstimate(no: any) {
    this.openSpinnerDialog();
    console.log(no);
    if (confirm("Are you sure to delete this estimate?") == true) {
      this.estimationService.deleteEstimation(no).subscribe((res: any) => {
        console.log(res);
        this.getEstimations();
        this.modalService.dismissAll();
        this.analyticService.logEvent("delete_estimation");
      }, (error: any) => {
        this.dialog.closeAll();
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      });
    } else {
      this.dialog.closeAll();
    }
  }

  viewEstimate(no: any) {
    console.log(no);
    const extras: NavigationExtras = {
      state: {
        id: no
      }
    };
    this.router.navigate(['view_estimation',{estimation_id:no}], extras);
  }

  editEstimate(no: any) {
    console.log(no);
    const extras: NavigationExtras = {
      state: {
        id: no
      }
    };
    this.router.navigate(['edit_estimation',{estimation_id:no}], extras);
  }

  back() {
    this.router.navigate(['']);
  }
}
