import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QueryService } from './query.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-access-denied-modal',
  templateUrl: './access-denied-modal.component.html',
  styleUrls: ['./access-denied-modal.component.css']
})
export class AccessDeniedModalComponent {

  constructor(private modalService: NgbModal,
    private queryService:QueryService,
    private toastr:ToastrService) { }

closeModal(){
  this.modalService.dismissAll();
}

sendQuery()
{
  this.queryService.sendQuery().subscribe((data:any)=>{
    console.log(data);
    this.toastr.success("","Message has been sent to our team. Our team will connect with you shortly",
    { positionClass: 'toast-bottom-right' })
  });
}

}
