import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CertificateData, ListCertificate } from './models/Certificate-content';
import { Rest } from '../models/rest_model';
import { take } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CertificateServiceService {
  setup_url = environment.ip+"shop/certificate-setup/";
  certificate_url =environment.ip + "shop/certificate/";

  constructor(private http:HttpClient) { }

  postSetup(CertificateSetup:CertificateData){return this.http.post(this.setup_url,CertificateSetup.convertToFormData());}
  
  updateSetupCertificate(CertificateSetup:CertificateData,obj_id:number){return this.http.put<CertificateData>(this.setup_url+obj_id+'/',CertificateSetup.convertToFormData())}

  getSetup(){return this.http.get<CertificateData>(this.setup_url)}

  createCertificate(formData:FormData){return this.http.post(this.certificate_url,formData).pipe(take(1))}

  list(page: number, search: string){
    return this.http.get<Rest<ListCertificate>>(this.certificate_url,
      {
        params: { search: search,
           page: page 
          },
      }
    )

  }

  createCertificatePdf(formData:FormData){
    return this.http.post(this.certificate_url+'createCertificate/',formData)
  }


}
