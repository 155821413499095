export class AnalyticsData{

    active_users!:string;
    live_users!:string;
    android_downloads!:string;
    ios_downloads!:string;
    android_uninstalls!:string;
    ios_uninstalls!:string;
    // branch_data!:BranchData[];
    // funnel_data!:FunnelData[];
    // life_time_performance_data!:LifeTimePerformance;
    // funnel_drop_down!:FunnelDropDown;
}

export class BranchData
{
    key!:string;
    value!:string;
}
export class FunnelData
{
    step!:string;
    users:number=0;
    completion_rate!:number;
    abandonment!:string;
    abandonment_rate!:number;

}
export class FunnelDropDown
{
    app_new_customer!:String;
    app_new_customer_amount!:number;
    app_repeat_customer!:string;
    app_repeat_customer_amount!:number;


    store_new_customer!:String;
    store_new_customer_amount!:number;
    store_repeat_customer!:string;
    store_repeat_customer_amount!:number;
    total_plans!:string;

    //for advance booking
    app_new_booking!:String;
    app_new_booking_amount!:number;
    app_repeat_booking!:string;
    app_repeat_booking_amount!:number;

    store_new_booking!:String;
    store_new_booking_amount!:number;
    store_repeat_booking!:string;
    store_repeat_booking_amount!:number;
    total_bookings!:string;

}
export class FunnelDropDownBooking
{
    app_new_booking!:String;
    app_new_booking_amount!:number;
    app_repeat_booking!:string;
    app_repeat_booking_amount!:number;

    store_new_booking!:String;
    store_new_booking_amount!:number;
    store_repeat_booking!:string;
    store_repeat_booking_amount!:number;
    total_bookings!:string;
}

export class LifeTimePerformance
{

    all_installment_amount!: string;
    all_installments!: string;
    average_ticket_price!: string;
    cancelled_count!: string;
    cancelled_subscription!: string;
    billed_count!:string;
    billed_subscription!:string;
    completed_count!: string;
    completed_subscription!: string;
    in_online_payment!: string;
    in_progress_count!: string;
    in_progress_subscription!: string;
    store_amount_total!: string;

    store_amount_total_card!: string;
    store_amount_total_cash!: string;
    store_amount_total_net_banking!: string;
    store_amount_total_net_cheque!: string;
    store_amount_total_upi!: string;

    total_count!: string;
    total_subscription!: string;

    outstanding_subscription!:string;


    // booking data

    all_booking!: string;
    all_booking_amount!: string;
    in_online_booking_payment!: string;
    store_booking_total_cash!: string;
    store_booking_total_card!: string;
    store_booking_total_upi!: string;
    store_booking_total_net_banking!: string;
    store_booking_total_cheque!: string;

    in_progress_booking_count!:string;
    in_progess_booking_amount!:string;

    completed_booking_count!:string;
    completed_booking_amount!:string;

    
    cancelled_booking_count!:string;
    cancelled_booking_amount!:string;

    total_booking_count!:string;
    total_booking_amount!:string;

    average_booking_ticket_price!:string;


}