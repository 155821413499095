import {  DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Rest } from "../../models/rest_model";
import { CustomItem } from "src/app/models/customItem";
import { repairOrderItem } from "src/app/models/repairOrder";
import { CertificateServiceService } from "../certificate-service.service";
import { ListCertificate } from "../models/Certificate-content";

export class CertificateDataSource extends DataSource<any> {

  private customData=new BehaviorSubject<ListCertificate[]>([]);
  private loadingcustomdata = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingcustomdata.asObservable();
  private countledgers=new BehaviorSubject<number>(0);
  public counter$=this.countledgers.asObservable();



  constructor(private certificateService:CertificateServiceService) {
    super();
  }
  connect(): Observable<ListCertificate[]> {
    return this.customData.asObservable();

  }
  disconnect() {
    this.customData.complete();
    this.loadingcustomdata.complete();
  }
  loadData(page:number=0,search: any){


      page=page+1;
      this.loadingcustomdata.next(true);
      this.certificateService.list(page=page,search=search).pipe(
        catchError(()=> of([])),
        finalize(()=>{
          this.loadingcustomdata.next(false);
        })

      ).subscribe(ledgerLogs=>this.renderOrders(ledgerLogs))

  }

  renderOrders(ledgerLogs:any){
    this.countledgers.next(ledgerLogs.count);
    this.customData.next(ledgerLogs.results);
    console.log(ledgerLogs.results)

  }




}