<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:50vh;">

<div class="ms-5 mt-4">
  <h3 class="sub_title">Store Details</h3>
  <div
    class="row p-2"
    style="background-color: white; width: 99%; border-radius: 10px"
  >
    <div class="row mt-2" style="margin: 0; padding: 0">
      <div class="col-md-1">
        <img src="assets/images/jewelerprofile.svg" />
      </div>
      <div class="col-md-8">
        <div class="mt-2 ms-2">
          <h3 class="jew_name">Maya Ramachandran</h3>
        </div>
        <div class="jew_info ms-2" style="margin-top: -1%">
          <span
            >M4842892
            <span class="ms-5"> Date Joined : 10th January 2022</span></span
          >
        </div>
      </div>

      <div class="col-md-3">
        <span>
          <img src="assets/images/qr.svg" />
          <span class="side_buttons ms-2">Download QR</span>
        </span>

        <span class="ms-4">

          <img class="ms-3" src="assets/images/edit.svg" />
          <span class="ms-2 side_buttons">Edit</span>
        </span>

      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-3">
        <img src="assets/images/Phone.svg" />
        <span class="ms-2 jew_info"> 91-1234567890 </span>
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-1"><img src="assets/images/Address.svg" /></div>
          <div class="col-md-10 ms-2">
            <span class="jew_info_small">
              Next to Rashtra Sadan, Barbatia Gaav, Phulaniati, Dist-Tinsukia,
              Assam
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <img src="assets/images/Ratio.svg" />
        <span class="ms-2 jew_info"> AA84948403JEO90Z </span>
      </div>
    </div>
  </div>
  <!-- 2nd row -->
  <div class="mt-3">
    <h3 class="sub_title">Social Account</h3>
    <div
      class="row p-3"
      style="background-color: white; width: 99%; border-radius: 10px"
    >
      <div class="row mt-2">
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-2">
              <img src="assets/images/Instagram.svg" />
            </div>
            <div class="col-md-10">
              <div class="mt-2 ms-1">
                <span class="social_title">Instagram</span>
              </div>
              <div class="row">
                <div class="col-md-1">
                  <img src="assets/images/Link.svg" width="23" />
                </div>
                <div class="col-md-10">
                  <span class="social_sub_t">
                    instagram.com/usernameijrorjirp...</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 ms-4">
          <div class="row">
            <div class="col-md-2">
              <img src="assets/images/pinterest.svg" class="mt-1" />
            </div>
            <div class="col-md-10">
              <div class="mt-2 ms-1">
                <span class="social_title">Instagram</span>
              </div>
              <div class="row">
                <div class="col-md-1">
                  <img src="assets/images/Link.svg" width="23" />
                </div>
                <div class="col-md-10">
                  <span class="social_sub_t">
                    instagram.com/usernameijrorjirp...</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3 mb-2">
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-2">
              <img src="assets/images/Facebook.svg" />
            </div>
            <div class="col-md-10">
              <div class="mt-2 ms-1">
                <span class="social_title">Instagram</span>
              </div>
              <div class="row">
                <div class="col-md-1">
                  <img src="assets/images/Link.svg" width="23" />
                </div>
                <div class="col-md-10">
                  <span class="social_sub_t">
                    instagram.com/usernameijrorjirp...</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 3rd row -->
  <div class="mt-3">
    <h3 class="sub_title">Settlement Account</h3>
    <div
      class="row p-2"
      style="background-color: white; width: 99%; border-radius: 10px"
    >
      <div class="row mt-2" style="margin: 0; padding: 0">
        <div class="col-md-2">
          <h4 class="bank">Bank Account</h4>
        </div>
        <div class="col-md-9"></div>
        <div class="col-md-1">
          <div class="">
            <img class="" src="assets/images/edit.svg" />

            <span class="ms-2 side_buttons">Edit</span>
          </div>
        </div>
      </div>
      <div class="row mt-1 mb-3">
        <div class="col-md-3 bank_info">
          <span class="bank"> Account Name </span>
          <br />
          AA84948403JEO90Z
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-3 bank_info">
          <span class="bank"> Account Name </span>
          <br />
          AA84948403JEO90Z
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-3 bank_info">
          <span class="bank"> Account Name </span>
          <br />
          AA84948403JEO90Z
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div>
    <h3 class="sub_title mb-3">Edit Details</h3>

    <h3 class="sub_title">Store Details</h3>
    <div
      class="row p-3"
      style="background-color: white; width: 99%; border-radius: 10px"
    >
      <div class="row">
        <div class="col-md-1">
          <img
            src="assets/images/jewelerprofile.svg"
            style="border-radius: 55px"
          />
        </div>
        <div class="col-md-10 ms-3">
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary"
            style="
              margin-top: 2%;
              background: #4070f4;
              width: 174px;
              height: 42px;
              border-radius: 8px;
            "
          >
            Change Picture
          </button>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-5">
          <div class="label_jel">Enter Phone Number</div>
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="Customer Email ID"
          />
        </div>

        <div class="col-md-5">
          <div class="label_jel">Enter Email</div>
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="Customer Email ID"
          />
        </div>
      </div>

      <div class="row mt-4 mb-2">
        <div class="col-md-10">
          <div class="label_jel">Enter Address</div>
          <input
            type="email"
            class="form-control address"
            id="email"
            placeholder="Customer Email ID"
          />
        </div>
      </div>
    </div>
  </div>
  <div>
    <h3 class="sub_title mt-4">Social Account Details</h3>
    <div
      class="row p-3"
      style="background-color: white; width: 99%; border-radius: 10px"
    >
      <div class="col-md-10">
        <div class="label_jel">Instagram</div>
        <input
          type="email"
          class="form-control social_account_field"
          id="email"
          placeholder="Customer Email ID"
        />
      </div>
      <div class="col-md-10 mt-3">
        <div class="label_jel">Youtube</div>
        <input
          type="email"
          class="form-control social_account_field"
          id="email"
          placeholder="Customer Email ID"
        />
      </div>
      <div class="col-md-10 mt-3">
        <div class="label_jel">Facebook</div>
        <input
          type="email"
          class="form-control social_account_field"
          id="email"
          placeholder="Customer Email ID"
        />
      </div>
      <div class="col-md-10 mt-3">
        <div class="label_jel">Pinterest</div>
        <input
          type="email"
          class="form-control social_account_field"
          id="email"
          placeholder="Customer Email ID"
        />
      </div>
    </div>
    <button type="button" (click)="modal(add_counter)" class="btn btn-primary next-btn">
      Save
      <a style="text-decoration: none"> </a>
    </button>
  </div>
</div>

<div class="mb-5 mt-2" style="width: 120%;">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="Jeweler Name">
      <th mat-header-cell *matHeaderCellDef>Jeweler Name</th>
      <td mat-cell *matCellDef="let element">
        <div class="row" >
          <div class="col-md-3" style="width: 20%;">
            <img
          src="assets/images/jewelerprofile.svg"
          width="40"
          style="border-radius: 55px"
        />
          </div>
          <div class="col-md-9">
            <span style="font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;

            
            color: #3268FB;">M4842892</span>
            <br>
            <span style="
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;

            
            color: #636363;">Maya Ramachandran</span>

          </div>
        </div>

      </td>
    </ng-container>
    <ng-container matColumnDef="Address">
      <th mat-header-cell *matHeaderCellDef style="width:180px">Address</th>
      <td mat-cell *matCellDef="let element"style="white-space: pre-wrap; width:120px">
        Phulaniati, Dist-Tinsukia, Assam ahskudkhaskd sdhadh
      </td>
    </ng-container>
    <ng-container matColumnDef="Date Joined">
      <th mat-header-cell *matHeaderCellDef>Date Joined</th>
      <td mat-cell *matCellDef="let element">13/12/21</td>
    </ng-container>
    <ng-container matColumnDef="Commission">
      <th mat-header-cell *matHeaderCellDef>Commission</th>
      <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
    </ng-container>
    <ng-container matColumnDef="Bank A/C">
      <th mat-header-cell *matHeaderCellDef>Bank A/C</th>
      <td mat-cell *matCellDef="let element">0000000000000</td>
    </ng-container>
    <ng-container matColumnDef="URL">
      <th mat-header-cell *matHeaderCellDef>URL</th>
      <td mat-cell *matCellDef="let element"><a href="">maitreyiagarwal@telecom.org</a></td>
    </ng-container>
    <ng-container matColumnDef="Payment Gateway">
      <th mat-header-cell *matHeaderCellDef>Payment Gateway</th>
      <td mat-cell *matCellDef="let element"><select class="dropdown" [(ngModel)]="selectedValue">
        <option *ngFor="let option of options" [value]="option.value">{{option.label}}</option>
      </select></td>
    </ng-container>
    <ng-container matColumnDef="JSP Subscriptions">
      <th mat-header-cell *matHeaderCellDef>JSP Subscriptions</th>
      <td mat-cell *matCellDef="let element">100</td>
    </ng-container>

    <ng-container matColumnDef="Dashboard">
      <th mat-header-cell *matHeaderCellDef>Dashboard</th>
      <td mat-cell *matCellDef="let element">
        <img src="assets/images/dashboard_icon.svg" />
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

</div>
<div>
  <div class="row">
  <div class="col-md-5 ms-5">
    <div class="input-group" style="margin-left: 15px; height: 48px">
      <mat-icon class="searchIcon" [ngStyle]="{ color: '#9A9AB0' }"
        >search</mat-icon
      >

      <input

        type="text"
        class="form-control search_box roundSquare text-indent"
        id="plan_name"
        style="border: 0; height: 48px"
        placeholder="Search Plan ID, Customer Name, Plan type"

        #txtInput
      />
      <mat-icon

        class="crossIcon"

        [ngStyle]="{ color: '#9A9AB0' }"
        >cancel</mat-icon
      >

      <button

        mat-raised-button
        class="searchButton"
        style="
          background: #4070f4;
          color: #ffffff;
          height: 48px;
          box-shadow: none;
        "
      >
        Search
      </button>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-5 ms-5">
    <div class="mt-4 mb-3" style="font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;


    color: #636363;">
     Role*
    </div>
    <select class="dropdown" style="width:82%;height: 52px;" [(ngModel)]="selectedValue">
      <option *ngFor="let option of options" [value]="option.value">{{option.label}}</option>
    </select>

  </div>

</div>
<div class="row mt-3">
  <div class="col-md-5 ms-5">
    <div class="mt-4 mb-3" style="font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  
  
    color: #636363;">
     Permissions
    </div>
  </div>
  <div class="col-md-12 ms-5">
    <div class="row mb-5" style="background-color: white; width: 92%;border-radius: 8px;">

      <div class="col-md-4" style="padding: 0;border-right:1px solid #EAEAEA">

        <table >

          <thead>
            <tr style="background: #F6F8FE">
              <th style="height: 47px;padding-left: 35px;width: 60%;">Page</th>
              <th style="height: 47px;"></th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of items" >
              <td  style="height: 37px;padding-left: 35px;border: none;">{{ item.value }}</td>
              <td style="height: 37px;border: none; ">
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="item.selected">
                  <span class="slider round" (click)="page_to_actions(item)"></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-7" style="padding: 0;">
        <table>

          <thead style="background: #F6F8FE">
            <tr >
              <th style="height: 47px;padding-left: 35px">Actions</th>
              <th style="height: 47px;" ></th>




            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of selected_actions">
              <td style="height: 37px;padding-left: 35px;width: 30%;border: none;">{{ item }}</td>
              <td style="height: 37px;border: none;">
                <label class="switch">
                  <input type="checkbox" >
                  <span class="slider round"></span>
                </label>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
</div>
</div>

<ng-template #add_counter let-modal>
  <div class="myModal_new">
    <div class="modal-content" style="width: 100%; height: 550px">
      <div class="modal-header" style="background: #F2F5FF;
      border-radius: 20px 20px 0px 0px;">
        <span class="modal-title"> Add Counter Account</span>
        <button
          type="button"
          class="close closeButton btn"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ms-3 mt-3">

        <span style="font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;


        color: #262626;">
  Store ID: 1234567890
        </span>

       <div class="mt-4" style="font-family: 'Rubik';
       font-style: normal;
       font-weight: 400;
       font-size: 16px;
       color: #636363;">
        Counter ID*
       </div>
       <input
          type="number"
          class="form-control social_account_field mt-3"
          id="email"
          placeholder="Customer Email ID"

        />
        <div class="mt-4 mb-3" style="font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;


        color: #636363;">
         Set Pin*
        </div>

        <ng-otp-input

              [config]="{ length: 4 }"
            ></ng-otp-input>

            <div class="mt-4 mb-3" style="font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;


            color: #636363;">
             Role*
            </div>

            <select class="dropdown" style="width:93%;height: 52px;" [(ngModel)]="selectedValue">
              <option *ngFor="let option of options" [value]="option.value">{{option.label}}</option>
            </select>



            <div class="row mt-3">
              <div class="col-md-2"></div>
              <div class="col-md-4">
                <button
                (click)="modal.dismiss('Cross click')"
                type="button"
                class="btn btn-primary mt-2 cancelbutton"
              >
                Cancel
              </button>
              </div>

              <div class="col-md-4">
                <button
                (click)="modal.dismiss('Cross click')"
                type="button"
                class="btn btn-primary mt-2 addbutton1"
              >
                Add
              </button>
              </div>
            </div>



      </div>
    </div>
  </div>
</ng-template>


