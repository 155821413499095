import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseMessage } from 'src/app/models/message';
import { Rest } from 'src/app/models/rest_model';
import { Settlement, SettlementOrder } from 'src/app/models/settlement';
import { User } from 'src/app/models/user_model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiServiceService {
  customer_url = environment.ip + 'shop/my-customers/';
  user_url = environment.ip + 'shop/users/0/notify_user_message/';

  constructor(private http: HttpClient) {}

  getAllUsers(page: number, search: string) {
    return this.http.get<Rest<User>>(this.customer_url, {
      params: { page: page, search: search },
    });
  }

  notifyUsers(
    title: string,
    description: string,
    users: any[],
    deep_link: string,
    deep_link_url: string,
    all_selected: boolean
  ) {
    var fd = new FormData();

    fd.append('title', title);
    fd.append('message', description);
    fd.append('users', users.toString());
    fd.append('deep_link', '4');
    fd.append('deep_link_url', deep_link_url);
    fd.append('all_selected', all_selected.toString());


    return this.http.post<ResponseMessage>(this.user_url, fd);
  }
}
