import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { RateFinal, RateTable } from '../models/rate';
import { Rest } from '../models/rest_model';
import { ResponseMessage } from '../models/message';

@Injectable({
  providedIn: 'root'
})
export class TemplateServiceService {
  get_rates_url = environment.ip+"shop/rates/";
  daily_rates_url = environment.ip+"shop/daily-rates/";
  latest_rates_url = environment.ip+"shop/latest_rates/";

  constructor(private http:HttpClient) { }

  getRates(){
    return this.http.get<RateFinal>(this.get_rates_url);
  }
  getLatestRates(){
    return this.http.get<RateFinal>(this.latest_rates_url);
  }
  getRateLogs(page:number,from_date:string,to_date:string){
    return this.http.get<Rest<RateTable>>(this.daily_rates_url,{params:{"from_date":from_date,"to_date":to_date,"page":page}});

  }
  updateRates(fd:FormData){
    return this.http.post<ResponseMessage>(this.daily_rates_url+"0/add_rates/",fd);
  }
  saveReport(fd:FormData){
    return this.http.post<ResponseMessage>(this.daily_rates_url+"0/add_rates/",fd);
  }
}
