import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CatalogueService } from '../catalogue/catalogue-service.service';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AnalyticService } from '../services/analytics.service';
import { HttpEventType } from '@angular/common/http';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.css'],
})
export class UploadDialogComponent implements OnInit {
  @Input() id!: number;

  selected_files: File[] = [];
  rejected_files: File[] = [];
  uploaded_files: File[] = [];
  uploaded_file_base64: any[] = [];
  uploadProgress: number = 0;
  overallProgress: number = 0;
  totalUploadedFiles: number = 0;
  batchProgresses: number[] = [];
  totalChunks=0;
  uploadedChunks = 0;
  currentFileIndex = 0;

  upload_Stage_1: boolean = true;
  upload_Stage_2: boolean = false;
  upload_Stage_3: boolean = false;
  upload_success: boolean = false;

  enteredDesignName: any;
  selected_category: any;
  selected_metal: any;
  selected_purities: [] = [];

  passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    public modalService: NgbModal,
    private catalogueService: CatalogueService,
    public activeModal: NgbActiveModal,
    public toastr: ToastrService,
    private analyticService: AnalyticService
  ) {}

  ngOnInit() {
    console.log('id:', this.id);
  }

  startUpload() {
    this.uploadProgress = 0;
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  upload_stages(value: any) {
    if (value == 1) {
      this.upload_Stage_1 = true;
      this.upload_Stage_2 = false;
      this.upload_Stage_3 = false;
      this.startUpload();
    } else if (value == 2) {
      this.uploadProgress = 0;
      this.upload_Stage_1 = false;
      this.upload_Stage_2 = true;
      this.upload_Stage_3 = false;
    } else if (value == 3) {
      this.upload_Stage_1 = false;
      this.upload_Stage_2 = false;
      this.upload_Stage_3 = true;
    } else if (value == 4) {
      this.upload_Stage_1 = false;
      this.upload_Stage_2 = false;
      this.upload_Stage_3 = false;
      this.upload_success = true;
    }
  }
  openSpinnerDialog(): void {
    const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
    });
  }

  onSelect(event: any) {
    if (event.addedFiles.length <= 50) {
      this.uploaded_files.push(...event.addedFiles);
      this.selected_files.push(...event.addedFiles);
  
      const totalFiles = event.addedFiles.length;
      const incrementPerFile = 100 / totalFiles;
      const maxConcurrentUploads = 4;
  
      const processFilesFromSelectedByIndex = (index: number) => {
        console.log(`Processing files from index ${index}`);
        if (index < totalFiles) {
          const endIndex = Math.min(index + maxConcurrentUploads, totalFiles);
          console.log(`Processing files from index ${index} to ${endIndex - 1}`);
          const uploadPromises: Promise<void>[] = [];
  
          for (let i = index; i < endIndex; i++) {
            const file = event.addedFiles[i];
          
            // Add a null check to ensure file is defined
            if (file) {
              const isVideo = file.type.startsWith('video/');
          
              if (
                (isVideo && file.size > 50 * 1024 * 1024) ||
                (!isVideo && file.size > 5 * 1024 * 1024)
              ) {
                console.log(`File ${file.name} is too large, rejecting.`);
                this.rejected_files.push(file);
                this.uploaded_files.splice(
                  this.uploaded_files.indexOf(file),
                  1
                );
                event.addedFiles.splice(i, 1);
                i--; // Decrement index to process the next file
                uploadPromises.push(Promise.resolve());
              } else {
                console.log(`File ${file.name} is being uploaded.`);
                this.currentFileIndex = i; // Update currentFileIndex to the correct value
          
                this.upload_stages(2);
          
                const uploadPromise = new Promise<void>((resolve, reject) => {
                  this.sendFile(file, i, incrementPerFile, () => {
                    resolve();
                  });
                });
          
                uploadPromises.push(uploadPromise);
              }
            }
          }          
  
          Promise.all(uploadPromises).then(() => {
            processFilesFromSelectedByIndex(endIndex);
          });
        } else {
          this.upload_stages(3);
          this.analyticService.logEvent('jewellery_uploaded', {
            medium: 'web',
            type: 'images',
            jewellery_item_count: this.selected_files.length,
          });
        }
      };
  
      processFilesFromSelectedByIndex(0);
    } else {
      this.modalService.dismissAll();
      alert('You can only upload a maximum of 50 images at a time');
    }
  }

  sendFile(file: File, fileIndex: number, incrementPerFile: number, callback: () => void) {
    // Convert the file to base64 text
    const reader = new FileReader();
    reader.readAsDataURL(file);
    var is_video = file.type.split('/')[0] == 'video' ? 0 : 1;

    reader.onload = () => {
      const base64Data = reader.result?.toString().split(',')[1]; // Remove `data:image/png;base64,`
      const chunkSize = 340 * 1024; // 340KB (adjust as needed)
      const chunks: any = []; // Push the chunks into an array
      for (let i = 0; i < base64Data!.length; i += chunkSize) {
        chunks.push(base64Data!.slice(i, i + chunkSize)); // Append string end character, in case it gets sliced in the middle of a string
      }
      this.totalChunks += chunks.length;
      console.log('total' + this.totalChunks);
      if (this.id == 0) {
        const emptyImageRes = this.catalogueService.createEmptyJewelleryItem(
          file.name,
          is_video,
          chunks.length
        ); // Send the first file info to create the item in the database

        emptyImageRes.subscribe(
          (data: any) => {
            this.uploaded_file_base64.push({
              jewellery_id: data['id'], // Store the jewellery id
              chunks_count: chunks.length, // Store the number of chunks
            });

            const jewelryId = data['id'];

            const sendNextChunk = (chunkIndex: number) => {
              if (chunkIndex < chunks.length) {
                const chunk = chunks[chunkIndex]; // Get the chunk from the array of chunks
                // Send the chunk to the server
                const res = this.catalogueService.sendChunkInfo(
                  jewelryId, // The jewellery id
                  chunk, // The actual chunk
                  chunkIndex, // The index of the chunk in the chunks array
                  chunks.length, // The total number of chunks
                  ''
                );

                res.subscribe(
                  (data: any) => {
                    if (data['success'] == 'created') {
                      this.uploadedChunks++;
                      chunkIndex++; // Increment the index of the chunk to send the next chunk
          
                      console.log(`Uploaded Chunks: ${this.uploadedChunks}/${this.totalChunks}`);
                      // Calculate batch progress and update the overall progress
                      const batchProgress = Math.floor((this.uploadedChunks / this.totalChunks) * 100);
                      // Update the progress for the current file
                      this.uploadProgress = batchProgress;
                      sendNextChunk(chunkIndex); // Send the next chunk
                    }
                  },
                  (error) => {
                    // Handle error if chunk upload fails
                    this.dialog.closeAll();
                    this.modalService.dismissAll();
                    this.toastr.info(
                      '',
                      'Something went wrong, please do try again later.',
                      { positionClass: 'toast-bottom-right' }
                    );
                    this.analyticService.logEvent('jewellery_upload_failed', {
                      medium: 'web',
                      type: 'images',
                    });
                  }
                );
              } else {
                // All chunks have been sent, call the callback
                callback();
              }
            };

            // Start sending the chunks
            sendNextChunk(0);
          },
          (error) => {
            this.dialog.closeAll();
            this.modalService.dismissAll();
            this.toastr.info(
              '',
              'Something went wrong, please do try again later.',
              { positionClass: 'toast-bottom-right' }
            );
            this.analyticService.logEvent('jewellery_upload_failed', {
              medium: 'web',
              type: 'images',
            });
          }
        );
      } else {
        const emptyImageRes = this.catalogueService.createAdditonalJewelleryItem(
          file.name,
          is_video,
          chunks.length,
          this.id
        ); // Send the first file info to create the item in the database

        emptyImageRes.subscribe(
          (data: any) => {
            console.log('response', data['uuid']);
            this.uploaded_file_base64.push({
              uuid: data['uuid'], // Store the uuid
              jewellery_id: data['id'], // Store the jewellery id
              chunks_count: chunks.length, // Store the number of chunks
            });

            const uuid = data['uuid'];
            const jewelryId = data['id'];
            let uploadedChunkCount = 0; // Track the number of uploaded chunks

            const sendNextChunk = (chunkIndex: number) => {
              if (chunkIndex < chunks.length) {
                const chunk = chunks[chunkIndex]; // Get the chunk from the array of chunks
                // Send the chunk to the server
                const res = this.catalogueService.sendChunkInfo(
                  jewelryId, // The jewellery id
                  chunk, // The actual chunk
                  chunkIndex, // The index of the chunk in the chunks array
                  chunks.length, // The total number of chunks
                  uuid
                );

                res.subscribe(
                  (data: any) => {
                    if (data['success'] == 'created') {
                      this.uploadedChunks++;
                      chunkIndex++; // Increment the index of the chunk to send the next chunk
          
                      console.log(`Uploaded Chunks: ${this.uploadedChunks}/${this.totalChunks}`);
          
                      // Calculate batch progress and update the overall progress
                      const batchProgress = Math.floor((this.uploadedChunks / this.totalChunks) * 100);
                      // Update the progress for the current file
                      this.uploadProgress = batchProgress;
                      sendNextChunk(chunkIndex); // Send the next chunk
                    }
                  },
                  (error) => {
                    // Handle error if chunk upload fails
                    this.dialog.closeAll();
                    this.modalService.dismissAll();
                    this.toastr.info(
                      '',
                      'Something went wrong, please do try again later.',
                      { positionClass: 'toast-bottom-right' }
                    );
                    this.analyticService.logEvent('jewellery_upload_failed', {
                      medium: 'web',
                      type: 'images',
                    });
                  }
                );
              } else {
                // All chunks have been sent, call the callback
                callback();
              }
            };

            // Start sending the chunks
            sendNextChunk(0);
          },
          (error) => {
            this.dialog.closeAll();
            this.modalService.dismissAll();
            this.toastr.info(
              '',
              'Something went wrong, please do try again later.',
              { positionClass: 'toast-bottom-right' }
            );
            this.analyticService.logEvent('jewellery_upload_failed', {
              medium: 'web',
              type: 'images',
            });
          }
        );
      }
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  onRemove(event: any) {
    this.selected_files.splice(
      this.selected_files.indexOf(event),
      1
    );
  }
}