<div class="certificate" >
  <div class="certificate_body">
    <div class="header">
      
      <div class="col-md-2 shop_image">
        <img
        id="store_image"

        style="max-width: 60px; max-height: 60px;"
          [src]="certificate_content.store_image ?? certificate_content.shop_image_url"

          alt="images"
          />
      </div>
      <div class="col-md-8 header_text">
        <div class="store_name">{{ certificate_content.store_name }}</div>
        <div class="address">{{ certificate_content.address }}</div>
      </div>
      <div class="col-md-2 shop_image">
        <img
        style="max-width: 60px; max-height: 60px;"
          [src]="certificate_content.sub_brand_image ?? certificate_content.sub_brand_image_url"
          />
      </div>
    </div>
    <hr style="margin-bottom:5px;margin-top:0px">
    <!-- header copy -->
    <div class="header">
      
      <div class="col-md-2 shop_image">
       
      </div>
      <div class="col-md-8 header_text">
        <div class="certificate_title">{{certificate_content.title}}</div>
      </div>
      <div class="col-md-2 shop_image">
      </div>
    </div>
    
    <div class="content">
      <!-- <div class="certificate_title text-center">
        {{ certificate_content.header }}
      </div> -->
      <div class="spliter">
        <div class="col-md-8">
          <div
            *ngIf="
              certificate_content.description ||
              certificate_content.item_code_or_huid
            "
          >
          <p>
            <strong>Item Code/Huid:</strong>
            {{ certificate_content.item_code_or_huid }}
          </p>
            <p>
              <strong>Description:</strong> {{ certificate_content.description }}
            </p>
        
          
          </div>
          <div class="raw_data_loop">
            <div
            class=""
            *ngFor="let raw_data of raw_certificate_content; let index"
          >
            <p>
              <strong>{{ raw_data.key }}:</strong> 
              {{ raw_data.value }}
            </p>
          </div>
          </div>
         
        </div>
        <div class="col-md-1 blank"></div>
        <div class="col-md-3 image_box">
          <div *ngIf="certificate_content.item_image" >
            <div class="gradient-border">
              <img
                [src]="certificate_content.item_image"
                alt="File Preview"
                style="min-height: 50px; min-width: 75px; max-width: 80px; max-height: 75px;"
              />
            </div>
            <div class="certificate_no">
              Crt.no - {{certificate_content.certificate_number}}
          </div>
          </div>
         
         
        </div>
      </div>
    </div>
   
  
    <div class="footer">
      <!-- <p><strong>{{certificate_content.store_name}}</strong></p> -->
      <!-- <p>{{certificate_content.address}}</p> -->
      <p class="disclaim">{{ certificate_content.footer }}</p>
    </div>
  </div>

</div>
