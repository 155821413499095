import { style } from '@angular/animations';
import { HtmlParser, NONE_TYPE } from '@angular/compiler';

import Chart from 'chart.js/auto';
import { NgChartsModule } from 'ng2-charts/public_api';

import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import { DashboardnewService } from './dashboardnew.service';
import { CounterData, CounterDataList, Dashboard } from '../models/dashboard';
import { ResponseMessage } from '../models/message';
import { Doughnut, GraphData, ListGraphData } from '../models/graphdata';
import { formatDate } from '@angular/common';
import { HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticService } from '../services/analytics.service';
import { ToastrService } from 'ngx-toastr';
import { ToastContainerDirective } from 'ngx-toastr';
import { TitleService } from '../services/title.service';
import { PageAccessService } from '../services/pageaccess.service';
import { injectClarity } from '../services/scriptInjection.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { IndianNumerationPipe } from '../indian-numeration.pipe';
import { DeviceDetectorService } from 'ngx-device-detector';
import { error } from 'console';

export interface DropDownData {
  payment_mode: string;
  jewellery_plan: number;
  advance_booking: number;
  oms:number,
  digital_gold: number,
  total: number;
}


@Component({
  selector: 'app-dashboardnew',
  templateUrl: './dashboardnew.component.html',
  styleUrls: ['./dashboardnew.component.css'],
})
export class DashboardnewComponent implements OnInit {
  graph_previous_date = '';
  graph_present_date = '';
  public chart: any;
  public chartDoughnut: any;
  public chartDoughnut_adv: any;
    multi_axis_chart: any;
  responseMessage: any = ResponseMessage;
  dashdata: Dashboard = new Dashboard();
  doughnut: Doughnut = new Doughnut();
  all_installment!: ListGraphData;
  gradient: any;
  date: any = [];
  allowed!: any;
  selected_month = new Date().getMonth() + 1;
  allowed_actions!: { [key: string]: boolean };
  static multi_axis_chart: any;
  installment: any = [];
  all_adv: any = [];
  isFlipped: boolean = false;
  dropdown_data: DropDownData[] = [];

  counter_data_list=new CounterDataList();

  isCardEnabled: boolean = false;
  displayedColumns: string[] = [
    'payment_mode',
    'jewellery_plans',
    'advance_bookings',
    'Digital Gold',
    'total',
  ];

  generateRandomList(length: number, maxValue: number): number[] {
    return Array.from({ length }, () =>
      Math.floor(Math.random() * (maxValue + 1))
    );
  }

  list1: number[] = this.generateRandomList(31, 50);
  list2: number[] = this.generateRandomList(31, 50);

  monthsWithDays: { month: string; days: number }[] = [
    { month: 'January', days: 31 },
    { month: 'February', days: 28 }, // Leap year has 29 days
    { month: 'March', days: 31 },
    { month: 'April', days: 30 },
    { month: 'May', days: 31 },
    { month: 'June', days: 30 },
    { month: 'July', days: 31 },
    { month: 'August', days: 31 },
    { month: 'September', days: 30 },
    { month: 'October', days: 31 },
    { month: 'November', days: 30 },
    { month: 'December', days: 31 },
  ];
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer:
    | ToastContainerDirective
    | undefined;
  toastrService: any;
  isMobile!: boolean;
  isDesktop!: boolean;
  digiStatus: "NOT_ELIGIBLE" | "NOT_REGISTERED" | "REGISTERED" | undefined = undefined
  //trial
  change_line = 0;
  input_!: any;
  days_in_month!: number[];

  constructor(
    private dashboardservice: DashboardnewService,
    private router: Router,
    private analysis: AnalyticService,
    private toastr: ToastrService,
    private titleService: TitleService,
    private elem: ElementRef,
    private authservice: PageAccessService,
    private deviceService: DeviceDetectorService,
    private elRef: ElementRef, private renderer: Renderer2
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.isDesktop = this.deviceService.isDesktop();
  }
  handleRouteEvents() {
    gtag('event', 'page_view', {
      page_title: "Dashboardview",
      page_path: "nothing",
    })
}
handleAfterClick(): void {
  // Your function to execute when ::after pseudo-element is clicked
  console.log('::after pseudo-element clicked!');
}

  ngOnInit(): void {
    const element = this.elRef.nativeElement.querySelector('.counter_wise_box');
    this.renderer.listen(element, 'click', (event:any) => {
      if (event.target && (event.target as HTMLElement).classList.contains('.counter_wise_box::after')) {
        this.handleAfterClick();
      }
    });
    this.digiStatus = (localStorage.getItem("digiStatus") ?? undefined) as typeof this.digiStatus;
    if(this.digiStatus == "REGISTERED") {
      this.isCardEnabled = true;
    } else {
      this.isCardEnabled = false;
    }
    console.log(this.router.url) 
    this.handleRouteEvents();
    this.analysis.logPageView(this.router.url)
    if (this.isMobile) {
      this.router.navigate(['dashboard/mobile_rate/']);
    }
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    this.titleService.setTitle('Dashboard');
    this.analysis.logEvent('2feb');
    this.dateHandler(); //for Getting 7 days before date
    this.getData(); //graph and doughnut handler
    //same
    this.setData(); //for setting data in graph and doughnut
    setTimeout(() => {
      this.showData();
      this.getGraphData(currentMonth);
    }, 1000);
    this.allowed_actions = this.authservice.allowedActions;
  }
  getDaysInMonth(month: number): number[] | null {
    if (month >= 1 && month <= 12) {
      const daysInMonth = Array.from(
        { length: this.monthsWithDays[month - 1].days },
        (_, index) => index + 1
      );
      this.days_in_month = daysInMonth;
      this.getGraphData(month);
      return daysInMonth;
    } else {
      console.error(
        'Invalid month number. Please provide a value between 1 and 12.'
      );
      return null;
    }
  }

  ngAfterViewInit(): void {
    let router_cursor =
      this.elem.nativeElement.querySelectorAll('.router_cursor');
    if (router_cursor) {
      router_cursor.forEach(
        (ele: { style: any; id(id: any): unknown; nativeElement: any }) => {
          if (this.authservice.check2(ele.id)) {
          } else {
            ele.style.cursor = 'not-allowed';
          }
        }
      );
    }
  }

  lineChange() {
    this.change_line = this.input_.length * 2;
  }

  multiAxisGraph() {
    if (this.multi_axis_chart) {
      this.multi_axis_chart.clear();
      this.multi_axis_chart.destroy();
    }

    const canvas = document.getElementById(
      'multi_axis_graph'
    ) as HTMLCanvasElement | null;

    const ctx = canvas?.getContext('2d');

    const labels = this.days_in_month
      ? this.days_in_month
      : Array.from(
          {
            length: new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
            ).getDate(),
          },
          (_, index) => index + 1
        );

    // const ctxMultiAxis=<HTMLCanvasElement>(
    //   document.getElementById('multi_axis_graph')
    // );
    // document.getElementById('chartjsLegend')!.innerHTML = ctx!.generateLegend();
    const plugin = {
      beforeInit(chart: any) {
        // Get a reference to the original fit function
        const originalFit = chart.legend.fit;

        // Override the fit function
        chart.legend.fit = function fit() {
          // Call the original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)();
          // Change the height as suggested in other answers
          this.height += 15;
        };
      },
    };

    if (ctx) {
      this.multi_axis_chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,

          datasets: [
            {
              label: 'SIP',
              data: this.installment,

              backgroundColor: 'rgba(0,122,255, 0.2)',
              borderColor: 'rgba(0,122,255, 1)',
              borderWidth: 1,
              fill: false, // Ensure the line is not filled
              pointRadius: 0,
              pointBackgroundColor: '#BBDBFF',
            },
            {
              label: 'Advance Booking',
              data: this.all_adv,

              backgroundColor: 'rgba(39,174,96, 0.2)',
              borderColor: 'rgba(39,174,96, 1)',
              borderWidth: 1,
              fill: false, // Ensure the line is not filled
              pointRadius: 0,
              pointBackgroundColor: '#A6DDBE',
            },
          ],
        },
        options: {
          scales: {
            y: {
              grid: {
                display: false,
                color: '#E5E5E5', // Set this to false to remove the x-axis grid lines
              },
              beginAtZero: true,
              ticks: {
                color: '#9C9C9C',
              },
            },
            x: {
              grid: { color: '#E5E5E5' },
              ticks: {
                color: '#9C9C9C',
              },
            },
          },
          elements: {
            line: {
              tension: 0.3,
            },
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              align: 'end',
              labels: {
                usePointStyle: true,
                padding: 20,
              },
            },
          },
        },
      });
    }
  }

  showData() {
    this.analysis.logEvent('Chart & Doughnut Loaded');
    const canvas = document.getElementById(
      'myChart'
    ) as HTMLCanvasElement | null;
    const ctx = canvas?.getContext('2d');

    if (ctx) {
      this.gradient = ctx?.createLinearGradient(0, 0, 0, 400);
      this.gradient.addColorStop(1, 'rgba(255, 255,255, 0)');
      this.gradient.addColorStop(0, 'rgba(0, 122, 255, 0.33)');
    }

    const ctxDoughnut = <HTMLCanvasElement>(
      document.getElementById('myChartDoughnut')
    );

    var extra_data = Math.max(...this.installment);
    for (var i = 0; i <= 4; i++) {
      this.installment.push(extra_data++);
    }

    this.chart = ctx
      ? new Chart(ctx, {
          type: 'line',
          data: {
            labels: this.date,
            //dates
            datasets: [
              {
                label: 'No. of Installments',
                data: this.installment,
                borderWidth: 1,
                backgroundColor: this.gradient,
                fill: true,
                borderColor: '#007AFF',
                pointBackgroundColor: '#007AFF',
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  precision: 0,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
            elements: {
              line: {
                tension: 0.3,
              },
            },
          },
        })
      : null;
    const indianNumerationPipe = new IndianNumerationPipe();
    this.chartDoughnut = new Chart(ctxDoughnut, {
      type: 'doughnut',

      data: {
        datasets: [
          {
            label: '',
            data: [
              this.doughnut.completed_count,
              this.doughnut.in_progress_count,
              this.doughnut.cancelled_count,
            ],
            backgroundColor: ['#FFB21D', '#329963', '#E10000'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        cutout: 90,

        // aspectRatio:1,
        responsive: true,
        maintainAspectRatio: false,
      },

      plugins: [
        {
          id: 'customPluginName',
          beforeDraw: (chart, args, opts) => {
            var width = this.chartDoughnut.width,
              height = this.chartDoughnut.height,
              ctx = this.chartDoughnut.ctx;
            ctx.restore();

            var fontSize = (height / 140).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            ctx.textBaseline = 'middle';

            var text =
              '₹' +
              indianNumerationPipe.transform(this.doughnut.total_subscription);
            var newtext = `Total ${this.doughnut.total_count} Plans`,
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;
            ctx.fillText(text, textX, textY);

            var fontSize = (height / 300).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            var textx = Math.round(
              (width - ctx.measureText(newtext).width) / 2
            );
            var texty = height / 1.7;

            ctx.fillText(newtext, textx, texty);
            ctx.save();
          },
        },
      ],
    });

    const ctxDoughtnut_new = <HTMLCanvasElement>(
      document.getElementById('advDoughtnut')
    );

    this.chartDoughnut_adv = new Chart(ctxDoughtnut_new, {
      type: 'doughnut',

      data: {
        datasets: [
          {
            label: '',
            data: [
              this.doughnut.completed_advance_count,
              this.doughnut.in_progress_advance_count,
              this.doughnut.cancelled_advance_count,
            ],
            backgroundColor: ['#FFB21D', '#329963', '#E10000'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        cutout: 90,

        // aspectRatio:1,
        responsive: true,
        maintainAspectRatio: false,
      },

      plugins: [
        {
          id: 'customPluginName',
          beforeDraw: (chart, args, opts) => {
            var width = this.chartDoughnut_adv.width,
              height = this.chartDoughnut_adv.height,
              ctx = this.chartDoughnut_adv.ctx;
            ctx.restore();

            var fontSize = (height / 140).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            ctx.textBaseline = 'middle';

            var text =
              '₹' + indianNumerationPipe.transform(this.doughnut.total_booking);
            var newtext = `Total ${this.doughnut.total_booking_count} Plans`,
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;
            ctx.fillText(text, textX, textY);

            var fontSize = (height / 300).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            var textx = Math.round(
              (width - ctx.measureText(newtext).width) / 2
            );
            var texty = height / 1.7;

            ctx.fillText(newtext, textx, texty);
            ctx.save();
          },
        },
      ],
    });
  }

  getData() {
    
    this.dashboardservice.getData().subscribe((data) => {
      this.analysis.logEvent('Graph Data Loaded');
      this.dashdata = data;
      console.log(data);
      this.dropdown_data.push({
        payment_mode: 'Online',
        jewellery_plan: this.dashdata.online_jewellery_plan_payments,
        advance_booking: this.dashdata.online_advance_booking_payments,
        oms:this.dashdata.oms_online,
        digital_gold: this.dashdata.online_digital_coin_order_payments,
        total:
          Number(this.dashdata.online_jewellery_plan_payments) +
          Number(this.dashdata.online_advance_booking_payments)+
          Number(this.dashdata.oms_online)+
          Number(this.dashdata.online_digital_coin_order_payments),
      });
      this.dropdown_data.push({
        payment_mode: 'Cash',
        jewellery_plan: this.dashdata.cash_jewellery_plan_payments,
        advance_booking: this.dashdata.cash_advance_booking_payments,
        oms:this.dashdata.oms_cash,
        digital_gold: this.dashdata.cash_digital_coin_order_payments,
        total:
          Number(this.dashdata.cash_advance_booking_payments) +
          Number(this.dashdata.cash_jewellery_plan_payments)
          +Number(this.dashdata.oms_cash)+
          Number(this.dashdata.cash_digital_coin_order_payments),
      });
      this.dropdown_data.push({
        payment_mode: 'Card',
        jewellery_plan: this.dashdata.card_jewellery_plan_payments,
        advance_booking: this.dashdata.card_advance_booking_payments,
        oms:this.dashdata.oms_card,
        digital_gold: this.dashdata.card_digital_coin_order_payments,
        total:
          Number(this.dashdata.card_advance_booking_payments) +
          Number(this.dashdata.card_jewellery_plan_payments)
          +Number(this.dashdata.oms_card)+
          Number(this.dashdata.card_digital_coin_order_payments),
      });
      this.dropdown_data.push({
        payment_mode: 'UPI/IMPS',
        jewellery_plan: this.dashdata.upi_jewellery_plan_payments,
        advance_booking: this.dashdata.upi_advance_booking_payments,
        oms:this.dashdata.oms_upi,
        digital_gold: this.dashdata.upi_digital_coin_order_payments,
        total:
          Number(this.dashdata.upi_jewellery_plan_payments) +
          Number(this.dashdata.upi_advance_booking_payments)
          +Number(this.dashdata.oms_upi)+
          Number(this.dashdata.upi_digital_coin_order_payments),
      });
      this.dropdown_data.push({
        payment_mode: 'RTGS/NEFT',
        jewellery_plan: this.dashdata.netbanking_jewellery_plan_payments,
        advance_booking: this.dashdata.netbanking_advance_booking_payments,
        oms:this.dashdata.oms_rtgs,
        digital_gold: this.dashdata.netbanking_digital_coin_order_payments,
        total:
          Number(this.dashdata.netbanking_jewellery_plan_payments) +
          Number(this.dashdata.netbanking_advance_booking_payments)
          +Number(this.dashdata.oms_rtgs)+
          Number(this.dashdata.netbanking_digital_coin_order_payments),
      });
      this.dropdown_data.push({
        payment_mode: 'Cheque',
        jewellery_plan: this.dashdata.cheque_jewellery_plan_payments,
        advance_booking: this.dashdata.cheque_advance_booking_payments,
        oms:this.dashdata.oms_cheque,
        digital_gold: this.dashdata.cheque_digital_coin_order_payments,
        total:
          Number(this.dashdata.cheque_advance_booking_payments) +
          Number(this.dashdata.cheque_jewellery_plan_payments)
          +Number(this.dashdata.oms_cheque)+
          Number(this.dashdata.cheque_digital_coin_order_payments),

      });
      this.dropdown_data.push({
        payment_mode: 'Cancellations(Cash)',
        jewellery_plan: this.dashdata.cash_cancelled_jewellery_plans,
        advance_booking: this.dashdata.cash_cancelled_advance_booking,
        oms:this.dashdata.cash_cancelled_custom_order,
        digital_gold: this.dashdata.cash_cancelled_digital_coin_order,
        total:
          Number(this.dashdata.cash_cancelled_jewellery_plans) +
          Number(this.dashdata.cash_cancelled_advance_booking)
          +Number(this.dashdata.cash_cancelled_custom_order)+
          Number(this.dashdata.cash_cancelled_digital_coin_order),
      });
      this.dropdown_data.push({
        payment_mode: 'Cancellations(A/C)',
        jewellery_plan: this.dashdata.ac_cancelled_jewellery_plans,
        advance_booking: this.dashdata.ac_cancelled_advance_booking,
        oms:this.dashdata.ac_cancelled_custom_order,
        digital_gold: this.dashdata.ac_cancelled_digital_coin_order,
        total:
          Number(this.dashdata.ac_cancelled_jewellery_plans) +
          Number(this.dashdata.ac_cancelled_advance_booking)+
          Number(this.dashdata.ac_cancelled_custom_order)+
          Number(this.dashdata.ac_cancelled_digital_coin_order),
      });
      console.log(this.dropdown_data);
    });
    this.dashboardservice.getCounterData().subscribe((data:CounterDataList) => {
            this.counter_data_list.counter_data=data.counter_data;
            console.log(this.counter_data_list)
    },(error:any)=>
    {
      const remove_button=document.getElementById('counter_view');
      remove_button?.remove();
      console.log("error",error.error.message)
      // console.log(error)
      // this.counter_data_list=new CounterDataList();
    });
  
  }

  getPreviousCounter(){
    this.counter_data_list.index-=1;
  }
  getNextCounter(){
    console.log(this.counter_data_list)
    this.counter_data_list.index+=1;
  }

  getGraphData(month: any) {
    this.dashboardservice.getGraph(month).subscribe((data) => {
      this.all_installment = data;
      var days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
      console.log(this.all_installment);
      this.installment = [];
      this.all_adv = [];
      for (var i = 0; i < this.all_installment.activation_list.length; i++) {
        let newDate = new Date(this.all_installment.activation_list[i].date);

        if (this.all_installment.activation_list[i].type == 'sip') {
          this.installment.push(this.all_installment.activation_list[i].count);
        } else {
          this.all_adv.push(this.all_installment.activation_list[i].count);
        }
      }
      setTimeout(() => {
        this.multiAxisGraph();
      }, 100);
    });
  }

  setData() {
    this.dashboardservice.getDoughnut().subscribe((data) => {
      this.analysis.logEvent('Doughnut Data');
      this.doughnut = data;
      console.log(data);
    });
  }

  dateHandler() {
    const sevenDaysAgo: Date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    var today = new Date();
    this.graph_previous_date = formatDate(sevenDaysAgo, 'dd/MM/yyyy', 'en-US');
    this.graph_present_date = formatDate(today, 'dd/MM/yyyy', 'en-US');
  }

  navigateToInstallment() {
    this.analysis.logEvent('Navigate To Add Installments');
    this.router.navigate(['/dashboard/add_installments/']);
  }

  navigateToAdd() {
    this.analysis.logEvent('Navigate To Create Saving Customer');
    this.router.navigate(['/dashboard/start_jewellery_plan/']);
  }

  navigateToBooking() {
    this.router.navigate(['/dashboard/ratecut_bookings/']);
  }
  navigateToOrder() {
    this.router.navigate(['/dashboard/order/']);
  }

  navigateToBuy() {
    this.router.navigate(['/digital-coin/details/']);
  }

  navigateToDelivery() {
    this.router.navigate(['/digital-coin/delivery-details/']);
  }
  viewReport() {
    this.analysis.logEvent('Navigate to All Reports');
    this.router.navigate(['/dashboard/all_reports/']);
  }

  CTI(s: any) {
    return Number(s);
  }

  toggleFlip(): void {
    this.isFlipped = !this.isFlipped;
  }

  @HostListener('window:keydown.control.e', ['$event'])
  handleDeleteKeyboard(event: KeyboardEvent) {
    if (event.ctrlKey && event.key == 'e') {
      event.preventDefault();
      this.analysis.logEvent('Navigate To Create Saving Customer');
      this.router.navigate(['/dashboard/saving_customer/']);
    }
  }

  @HostListener('document:keydown.control.i', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key == 'i') {
      event.preventDefault();
      this.analysis.logEvent('Navigate To Add Installments');
      this.router.navigate(['/dashboard/add_installments/']);
    }
  }
  @HostListener('document:keydown.control.o', ['$event'])
  handleOrderKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key == 'o') {
      event.preventDefault();
      this.analysis.logEvent('Navigate To Add Installments');
      this.router.navigate(['/dashboard/order/']);
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleBookingKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key == 'b') {
      event.preventDefault();
      this.analysis.logEvent('Navigate To Add Installments');
      this.router.navigate(['/dashboard/ratecut_bookings/']);
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleDeliveryKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key == 'y') {
      event.preventDefault();
      this.analysis.logEvent('Navigate To Digital Delivery');
      this.router.navigate(['/digital-coin/delivery-details']);
    }
    if (event.ctrlKey && event.key == 'd') {
      event.preventDefault();
      this.analysis.logEvent('Navigate To Digital Buy');
      this.router.navigate(['/digital-coin/details']);
    }
  }
}
