<div class="parent">
    <div class="header">Enter OTP</div>
    <form [formGroup]="otpForm" *ngIf="showInput">
        <div class="otp-container" formArrayName="otp">
            <div class="otp-col" *ngFor="let optBox of (otpInfo.controls); let i = index" [formGroupName]="i">
                <input class="remove-input-arrows rid" formControlName="digit" [id]="'digit' + i" type="number"
                    autocomplete="off" digitOnly onKeyPress="if(this.value.length==1) return false;"
                    (keydown)="focusPrevInput(i, $event)" (keyup)="focusNextInput(i, $event)">
            </div>
        </div>
    </form>
    <div class="txt">
        <span style="color: #F03738;" *ngIf="data.wrongOtp | async">Wrong OTP</span>
        <span *ngIf="defaultOtpTimeout - timerVal >=1; else onlyResend" style="margin-left: 12px;">
            Resend after {{defaultOtpTimeout - timerVal}} sec</span>
        <ng-template #onlyResend>
            <span (click)="resendOtp()" style="color: #3268FB;cursor: pointer;margin-left: 12px;">Resend</span>
        </ng-template>
    </div>
    <primary-btn (click)="verifyClicked()" [disabled]="otpForm.invalid"
        [styles]="{'margin':'auto', 'width':'75%', 'fontSize':'20px', 'fontWeight':'500'}">Verify</primary-btn>
</div>