import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Vendor } from '../models/vendors';
import { Rest } from '../models/rest_model';
import { ResponseMessage } from '../models/message';

@Injectable({
  providedIn: 'root'
})
export class ViewVendorService {
  view_vendor_url = environment.ip+"shop/vendors/";

  constructor(private http:HttpClient) { }


  getVendors(page:number,search:string){
    return this.http.get<Rest<Vendor>>(this.view_vendor_url,{params:{"search":search,"page":page}});
  }
  getVendor(id:string){
    return this.http.get<Vendor>(this.view_vendor_url+id+"/");
  }

  postVendor(fd:FormData){
    return this.http.post<ResponseMessage>(this.view_vendor_url+"add_vendor/",fd);
  }
  editVendor(fd:FormData,id:any){
    return this.http.post<ResponseMessage>(this.view_vendor_url+id+"/edit_vendor/",fd);
  }
  removeVendor(id:any){
    return this.http.post<ResponseMessage>(this.view_vendor_url+id+"/delete_vendor/",{});
  }



}
