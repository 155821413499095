<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:10vh;">

    
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 text-center">
          <mat-spinner
            *ngIf="dataSource.loading$ | async"
            style="margin: 0 auto"
            mode="indeterminate"
            [diameter]="50"
          ></mat-spinner>
        </div>
      </div>
      <div class="row mt-4">
        <!-- <mat-form-field >
              <mat-label>Outline form field</mat-label>
              <input matInput placeholder="Placeholder">
              <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
              <mat-hint>Hint</mat-hint>
            </mat-form-field> -->
        <div>
          <div class="nw">
            <div class="left-buttons">
              <div class="input-group mt-1">
                <mat-icon
                  class="searchIcon cursor"
                  id="23"
                  [ngStyle]="{ color: '#9A9AB0' }"
                  >search</mat-icon
                >
    
                <input
                  [(ngModel)]="search"
                  type="text"
                  class="form-control search_box roundSquare text-indent cursor"
                  id="23"
                  style="border: 0; height: 48px"
                  placeholder="Search Booking ID, Customer Name"
                  (keyup.enter)="searchOption(); txtInput.blur()"
                  #txtInput
                />
                <mat-icon
                  *ngIf="search"
                  (click)="clearInput(); searchOption()"
                  class="crossIcon cursor"
                  [ngStyle]="{ color: '#9A9AB0' }"
                  id="23"
                  >cancel</mat-icon
                >
    
                <button
                (click)="searchOption()"
                  mat-raised-button
                  id="23"
                  class="searchButton cursor"
                  style="
                    background: #4070f4;
                    color: #ffffff;
                    height: 48px;
                    box-shadow: none;
                  "
                >
                  Search
                </button>
              </div>
              <div>
                <div
                  ngbDropdown
                  #myDropdown="ngbDropdown"
                  style="cursor: pointer"
                  class="dropdownledger"
                  placement="bottom-right"
                >
                  <i id="dropdownBasic1" ngbDropdownToggle>
                    <img
                      src="assets/images/drop.png"
                      style="height: 50px; margin-top: 5px; margin-left: 13px"
                    />
                  </i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button
                    (click)="
                    fetchStatus($any($event.target).value); myDropdown.close()
                  "
                  value=""
                    ngbDropdownItem value="">All</button>
                    <button
                      ngbDropdownItem
                      (click)="
                        fetchStatus($any($event.target).value); myDropdown.close()
                      "
                      value="paid"
                    >
                      Paid
                    </button>
                 
                    <button
                      ngbDropdownItem
                      (click)="
                        fetchStatus($any($event.target).value); myDropdown.close()
                      "
                      value="pending"
                    >
                      Pending
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-group" style="margin-top: 10px;">
        
    
    
    
              <div>
                <img
    
                  (click)="openModel2(download_file)"
                  src="assets/images/ButtonDownload.svg"
                  style="width: 55px; height: 45px; margin-top: 8px;margin-left: 10px;"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 mt-2"></div>
    
        <div class="col-md-1 mt-4"></div>
      </div>
    </div>
    
    <div class="col-md-12 mt-2 tab">
      <div class="row tablePadding">
        <div class="col-md-12 text-center">
          <div class="mat-elevation table-responsive">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              class="booking-table"
            >
              <!-- Position Column -->
              <ng-container matColumnDef="plan_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Plan ID
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="color: var(--colors-fill-fill, #3268fb);cursor:pointer"
                  (click)="getEmis(element.id, emi_modal, element.code)" 
                  >
                  {{ element.plan_id }}
                </td>
              </ng-container>
    
              <ng-container matColumnDef="customer_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Customer Name
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.customer_name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone No</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.phone }}
                </td>
              </ng-container>
              <ng-container matColumnDef="plan_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.plan_type == "WB" ? "Weight Based" : "Amount Based" }}
                </td>
              </ng-container>
    
              <ng-container matColumnDef="payment_status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Payment Status
                </th>
                <td mat-cell *matCellDef="let element">
                    <div
                    [ngStyle]="element.payment_status? {'color':'#288127'}:{'color':'#F03738'}"
                    >
                        {{ element.payment_status ? "Paid" : "Pending"}}

                    </div>  
                </td>
              </ng-container>
    
            
    
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
    
            <mat-paginator
              style="background-color: #f5f6f8"
              [pageSizeOptions]="[popupManager.page_size_number]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
    
    
        </div>
      </div>
    </div>
    </div>
    <ng-template #download_file let-modal>
        <div class="emailModal">
          <div class="modal-header p-3" style="border: 0">
            <h1
              class="modal-title"
              style="
                font-family: 'Rubik';
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                margin-left: 125px;
                margin-top: 15px;
                font-weight: 500;
                color: #262626;
              "
            >
              Download Data
            </h1>
            <button
              type="button"
              style="outline: none"
              class="close btn"
              aria-label="Close"
              (click)="modal.dismiss('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h3
              style="
                font-family: 'rubil';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #000000;
              "
            >
              Choose Filter
            </h3>
            <div class="dropdown-container">
                <select #download_dropdown class="dropdown_">
                    <option value="all">ALL</option>
                    <option value="paid">Paid</option>
                    <option value="pending">Pending</option>
                </select>
            </div>
      
            <div class="row ms-1 me-1 form-group">
            
      
              <div class="btn-bottom">
                <button
                (click)="file_download(download_dropdown.value)"
                mat-raised-button
                color="primary"
                class="shad"
              >
                Download
              </button>
              </div>
      
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #emi_modal let-modal>
        <div class="myModal emi">
          <div class="modal-content p-2 px-3">
            <div class="modal-header">
              <h1 class="modal-title emi-modal-title">Installment Details</h1>
              <button
                type="button"
                style="outline: none"
                class="close btn"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div
                  class="col-md-12 card"
                  style="height: 101px; width: 98.5%; margin-left: 10px"
                >
                  <div class="row mt-2">
                    <div
                      class="col-md-1 mt-3"
                      style="
                        width: 12.499999995%;
      
                        height: 50px;
                      "
                    >
                      <h4 class="emiCardTitle text-center">
                        Plan ID
                        <span>
                          <span>
                            <h3 class="mt-2 emiCardValue">{{ emi_Data.code }}</h3>
                          </span>
                        </span>
                      </h4>
                    </div>
                    <div
                      class="col-md-1 mt-3"
                      style="
                        width: 12.499999995%;
                        border-left: 1px solid #DFDFDF;
                        height: 50px;
                      "
                    >
                      <h4 class="emiCardTitle text-center">
                        Type
                        <span>
                          <span>
                            <h3 class="mt-2 emiCardValue">{{ emi_Data.type }}</h3>
                          </span>
                        </span>
                      </h4>
                    </div>
                    <div
                      class="col-md-1 mt-3"
                      style="
                        width: 12.499999995%;
                        border-left: 1px solid #DFDFDF;
                        height: 50px;
                      "
                    >
                      <h4 class="emiCardTitle text-center">
                        Amount
                        <span>
                          <span>
                            <h3 class="mt-2 emiCardValue">{{ emi_Data.amount }}</h3>
                          </span>
                        </span>
                      </h4>
                    </div>
                    <div
                      class="col-md-1 mt-3"
                      style="
                        width: 12.499999995%;
                        border-left: 1px solid #DFDFDF;
                        height: 50px;
                      "
                    >
                      <h4 class="emiCardTitle text-center">
                        Duration
                        <span>
                          <span>
                            <h3 class="mt-2 emiCardValue">{{ emi_Data.duration }}</h3>
                          </span>
                        </span>
                      </h4>
                    </div>
                    <div
                      class="col-md-1 mt-3"
                      style="
                        width: 12.499999995%;
                        border-left: 1px solid #DFDFDF;
                        height: 50px;
                      "
                    >
                      <h4 class="emiCardTitle text-center">
                        Start Date
                        <span>
                          <span>
                            <h3 class="mt-2 emiCardValue">
                              {{ emi_Data.start_date }}
                            </h3>
                          </span>
                        </span>
                      </h4>
                    </div>
                    <div
                      class="col-md-1 mt-3"
                      style="
                        width: 12.499999995%;
                        border-left: 1px solid #DFDFDF;
                        height: 50px;
                      "
                    >
                      <h4 class="emiCardTitle text-center">
                        Installments
                        <span>
                          <span>
                            <h3 class="mt-2 emiCardValue">
                              {{ emi_Data.installments }}
                            </h3>
                          </span>
                        </span>
                      </h4>
                    </div>
                    <div
                      class="col-md-1 mt-3"
                      style="
                        width: 12.499999995%;
                        border-left: 1px solid #DFDFDF;
                        height: 50px;
                      "
                    >
                      <h4 *ngIf="emi_Data.type != 'Daily Amount Based'" class="emiCardTitle text-center">
                        Total Weight
                        <span>
                          <span>
                            <h3 class="mt-2 emiCardValue">
                              {{ emi_Data.total_weight}}
                            </h3>
                          </span>
                        </span>
                      </h4>
                      <h4 *ngIf="emi_Data.type =='Daily Amount Based'" class="emiCardTitle text-center">
                        Due Installments
                        <span>
                          <span>
                            <h3 class="mt-2 emiCardValue">
                              {{ emi_Data.due_installments}}
                            </h3>
                          </span>
                        </span>
                      
                      </h4>
    
                    </div>
                    <div
                      class="col-md-1 mt-3"
                      style="
                        width: 12.499999995%;
                        border-left: 1px solid #DFDFDF;
                        height: 50px;
                      "
                    >
                      <h4 class="emiCardTitle text-center">
                        Plan Status
                        <span>
                          <span>
                            <h3 [ngStyle]="{ color: getColor(emi_Data.status) }" class="mt-2 emiCardValue">
                              {{
                                emi_Data.status == "IP" ? "Active" :
                                emi_Data.status == "CP" ? "Completed" :
                                emi_Data.status == "CL" ? "Cancelled" :
                                emi_Data.status == "CD" ? "Closed" :
                                emi_Data.status == "PS" ? "Paused" :
                                emi_Data.status == "IG" ? "IN Grace":
                                "Deleted"
                              }}
                            </h3>
                          </span>
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row tablePadding mt-2">
                <div class="col-md-12 text-center">
                  <div class="mat-elevation table-responsive">
                    <table
                      mat-table
                      [dataSource]="plan_dataSource"
                      matSort
                      class="my-table"
                      style="table-layout: auto; width: 100%"
                    >
                      <!-- Position Column -->
                      <ng-container matColumnDef="plan_id" class="">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Plan Id
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.code }}
                        </td>
                      </ng-container>
      
                      <ng-container matColumnDef="plan_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Plan name
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.plan_name }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="installments">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Installment
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.installments }}
                        </td>
                      </ng-container>
      
                      <ng-container matColumnDef="payment_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Installment Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.plan_date }}
                        </td>
                      </ng-container>
      
                      <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Amount
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.amount }}
                        </td>
                      </ng-container>
      
                      <ng-container matColumnDef="rate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Rate/Gm
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.rate }}
                        </td>
                      </ng-container>
      
                      <ng-container matColumnDef="weight">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Weight
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.weight }}
                        </td>
                      </ng-container>
      
                      <ng-container matColumnDef="payment_mode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Payment mode
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.payment_mode }}
                        </td>
                      </ng-container>
      
                      <ng-container matColumnDef="payment_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Payment Id
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.payment_id }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="settlement_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Transfer Id
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.settlement_id }}
                        </td>
                      </ng-container>
      
                      <ng-container matColumnDef="settlement_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Settlement Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.settlement_date }}
                        </td>
                      </ng-container>
      
             
      
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsEmis"></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumnsEmis"
                      ></tr>
                    </table>
                  </div>
                </div>
           
              </div>
            </div>
          </div>
        </div>
      </ng-template>