import { Router } from "@angular/router";
import { Inject } from '@angular/core';
import Swal from 'sweetalert2';
import {MatSnackBar} from '@angular/material/snack-bar';


export class PopUpManager {
    page_size_number=10;  //changes to 10  .... previous value =20
    custom_size_number=10;
    constructor(public snackBar: MatSnackBar) {}


    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
        }


}