export function injectClarity(clarity_id?:string|null)
{

  let clarity_script=document.getElementById("clarity_script");
  if(clarity_script==null)
  {
    // console.log("injecting clarity");
    let node = document.createElement('script');
    node.type = 'text/javascript';
    node.innerHTML=` (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${clarity_id}");`
  node.setAttribute("id","clarity_script");
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
