<div class="parent mt-4">
  <div class="header">
      <!-- <mat-icon (click)="onclickBack()">arrow_back_ios</mat-icon> -->
      Details
  </div>
  <div class="content">
      <div class="details" *ngIf="!edit_booking">
          <div class="title">Mobile Number</div>
          <div class="inp" *ngIf="!mobileVerified; else mobileVerifiedTemplate">
              <input #inputField class="{{mobileNumber.dirty && mobileNumber.invalid ? 'error' : ''}}" type="text" maxlength="10" style="color: #262626;"
                  [formControl]="mobileNumber" placeholder="Enter Mobile Number" (keyup.enter)="verifyOtp()" >
              <primary-btn (click)="verifyOtp()" [styles]="{'width':'150px'}">Verify</primary-btn>
          </div>
          <ng-template #mobileVerifiedTemplate>
              <div class="mobile-verified">
                  <div class="number">91-{{mobileNumber.value}} <span>{{userDetails?.first_name}}</span></div>
                  <div><mat-icon
                          (click)="goBackToFirstScreen()">close</mat-icon>
                  </div>
              </div>
              <div class="verified-batch"><img src="assets/images/Ok.svg" alt=""> Verified</div>
              <div class="customer-details">
                  <div class="detail-col">Name <span>{{userDetails?.first_name}}</span></div>
                  <div class="detail-col">Contact No <span>91-{{mobileNumber.value}}</span></div>
                  <div class="detail-col">Address <span>{{userDetails?.address}}</span></div>
                  <div class="detail-col" style="text-align: left;">Email<span>{{userDetails?.email ? userDetails?.email : '-'}}</span></div>
                  <div class="detail-col" style="text-align: center;">Pincode <span>{{userDetails?.pincode ? userDetails?.pincode : '-'}}</span></div>
                  <primary-btn (click)="openCustomerDetails()" [styles]="{'height':'35px'}">Edit
                      (Ctrl+E)</primary-btn>
              </div>
          </ng-template>
      </div>
      <div class="details" *ngIf="mobileVerified">
        <div class="booking_second_screen">
          <div class="first_row_booking">
            <label class="label_booking">Weight (In gms)</label>
            <label class="label_booking ms-5">Rate/gm</label>
          </div>
      
          <div class="first_row_booking">
            <input
            type="text"
              class="form-control booking_input"
              [disabled]="edit_booking"
              [(ngModel)]="booking_weight"
              (input)="isAboveRange()"
              placeholder="Enter Weight"
              #focus_weight
              id="booking_weight"
              (keydown.enter)="focus('booking_rate')"
              appTwoDigitDecimaNumber>
            <input
            type="text"
            id="booking_rate"
              class="form-control booking_input ms-5"
              [(ngModel)]="booking_rate"
              (input)="edit_booking?'':suggestionRate()"
              placeholder="Enter Rate"
              [disabled]="!allowed_actions['ChangeRateBooking']"
              [style]="allowed_actions['ChangeRateBooking']?{'cursor':'pointer'}:{'cursor':'not-allowed'}"
              (keydown.enter)="focus('booking_advance')"
              appTwoDigitDecimaNumber>
          </div>
          <div *ngIf="can_collect && !edit_booking && !above_range_text" class="suggestion_box mt-4">
            You can collect the Advance amount
            <span class="suggestion_amount"> ₹{{ can_collect  }}</span> as per your
            booking terms of
            <span class="suggestion_amount"> {{ advance_percentage }}%</span>.
          </div>
          <div *ngIf="above_range_text" class="suggestion_box mt-4">
            {{above_range_text}}
          </div>
          <div class="mt-4">
            <label class="label_booking">Advance Payable (In ₹) </label>
            <input
            id="booking_advance"
              type="number"
              class="form-control booking_input rid"
              [(ngModel)]="booking_advance"
              placeholder="Enter Advance Payable"
              (keydown.enter)="focus('proceed_btn')"
            />
          </div>
          <div class="flexe mt-4"
          *ngIf="!edit_booking">
            <button
            id="proceed_btn"
              class="book_now"
              [disabled]="((!this.booking_advance) || (!this.booking_rate) || (!this.booking_weight))"
              (click)="openPaymentModel(payment_modal)"
              >
            Proceed to Payment (f5)
          </button>
          </div>
          <div class="flexe mt-4" *ngIf="edit_booking">
            <button
            id="proceed_btn"
              class="book_now"
              [disabled]="((!this.booking_advance) || (!this.booking_rate) || (!this.booking_weight))"
              (click)="edit_booking ? editBooking() : ''"
              >
              Edit Booking
            </button>
          </div>
        </div>
          <!-- <button (click)="sendDataToPayment()"  id="proceed_btn" class="saveBtn">
              Proceed to Payment (f5)
          </button> -->
      </div>
  </div>
</div>

<ng-template #payment_modal let-modal class="pm">
  <div class="myModal" (keydown.control.s)="preventDefault($event)" 
  (keydown.control.s)="(!clicked_booking?addBooking(add_booking):'')"
  >
    <div class="modal-header cus-mod-header">
      <div class="modal-title">Payment</div>
      <button
        type="button"
        style="border: none; border-top: none; outline: none"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="mt-1 payment_modal" (keydown.esc)="modal.dismiss('Cross click')"  >
      <div class="pm_first" id="pm_first">
        <div class="mt-2 mb-1 ti">Payment Method</div>
        <div class="mt-4" (keydown.shift.enter)="addPaymentRow(i)" *ngFor="let payment of payment_arr; let i = index" (keydown.delete)="removePaymentRow(i);focus('payment_amount')">
          <div class="row">
            <div class="col-md-2" style="width: 20%;">
              <label>Enter Amount</label>
              <input
                (keydown.enter)="focus('payment_method_' + i)"
                (keydown.enter)="payment.options[0].amount ==''?payment.options[0].amount='0':''"
                class="form-control"
                placeholder="Enter Amount"
                [id]="'payment_amount_'+i"
                [(ngModel)]="payment.options[0].amount"
                appTwoDigitDecimal

              />
            </div>
            <div class="col-md-2" style="width: 20%;">
              <label>Payment Method</label>
              <select

                class="form-select"
                (keydown.enter)="focus('payment_date_' + i)"
                [id]="'payment_method_' + i"
                [(ngModel)]="payment.mode"
              >
                <option value=7>Cash</option>
                <option value=1>Card</option>
                <option value=3>NEFT/RTGS</option>
                <option value=0>UPI/IMPS</option>
                <option value=6>Cheque</option>
                <!-- <option value=4>Online-Payment Gateway</option> -->
              </select>
            </div>
            <div class="col-md-2" style="width: 20%;">
              <label>Date</label>
              <input

                (keydown.enter)="payment.mode==7?'':fovu.focus()"
                class="form-control"
                [id]="'payment_date_' + i"
                type="date"
                [(ngModel)]="payment.options[0].date"
               

              />
            </div>
            <div class="col-md-2" style="width: 20%;">
              <label>Bank/POS Account</label>
              <!-- <input class="form-control" [(ngModel)]="payment.options[0].bank"> -->
              <ng-select
                #fovu
                class="form-select"
                [searchable]="true"
                [(ngModel)]="payment.options[0].bank"
                [notFoundText]="'Type Valid Bank'"
                [disabled]="payment.mode == 7"
                [id]="'payment_bank_' + i"
                [style]="payment.mode==7?{'background': '#DFDFDF!important'}:{}"
                (keydown.enter)="focus('payment_info_'+i)"
              >
                <ng-option
                  *ngFor="let account of account_list"
                  [value]="account.id"
                >
                  {{ account.number }}
                </ng-option>
              </ng-select>
            </div>
            <div class="col-md-2" style="width: 20%;">
              <label> UPI/UTR/Cheque No </label>
              <input

                class="form-control"
                [disabled]="payment.mode == 7"
                [style]="payment.mode==7?{'background': '#DFDFDF!important'}:{}"
                [id]="'payment_info_' + i"
                [(ngModel)]="payment.options[0].payment_info"
              />
            </div>
          </div>




          <div style="display: flex;justify-content: right;">
            <div class="add_shift_enter mt-2" (click)="removePaymentRow(i);" style="color:red;opactiy:0.4" *ngIf="payment_arr.length>1">
              - Remove (delete)
            </div>
          </div>
          <hr class="mt-3" />
          <div  *ngIf="payment_arr.length-1==i" style="display: flex;justify-content: space-between;">
            <div class="add_shift_enter mt-2" (click)="addPaymentRow(i)">
              + Add (shift+enter)
            </div>

          </div>
        </div>


      </div>

      <div class="vertical-line"></div>

      <div class="pm_second">
        <div class="my-2 ti">Payment Summary</div>
        <div class="sb my-3">
          <div class="ps_l">Booking Amount</div>
          <div class="ps_r">₹ {{ booking_advance  | number:'1.2-2'}}</div>
        </div>
        
      </div>

      <!-- model -->
    </div>
    <div
      class="f"
      style="
        justify-content: center;
        gap: 2%;
        margin-top: 3%;
        margin-bottom: 2%;
      "
    >
      <button class="save_b" 
       id="payment_save" style="width: 25%"  
       (click)="(!clicked_booking?addBooking(add_booking):'')"
       [disabled]="!amountValidation()&&clicked_booking"
      >
        Save (Ctrl+S)
      </button>
      <!-- <button
        class="cancel_b"
        style="
          width: 25%;
          border: 1px solid var(--colors-fill-fill, #3268fb);
          color: #3268fb;
        "

      >
        Save & Print (Ctrl+P)
      </button> -->
    </div>
  </div>
</ng-template>


<ng-template #sent_modal let-modal>
  <div class="myModal cardClass">
    <div class="modal-header">
      <h1 class="modal-title"></h1>

      <button
        type="button"
        style="border: none; border-top: none"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="row sid3">
      <div class="col-md-4">
        <h3 class="add">Add Installment</h3>
        <label class="enter mt-2">Enter Plan ID </label>
        <input type="text" class="plan" placeholder="Customer Pincode" />
        <label class="number mt-3">Installment Number</label>
        <input type="text" class="plan" placeholder="Number" />
        <label class="number mt-3">Installment Amount </label>
        <input type="text" class="plan" placeholder="₹" />
        <label class="number mt-3">Installment Date</label>
        <input type="text" class="plan" placeholder="--/--/----" />
        <label class="number mt-3">Customer name </label>
        <input type="text" class="plan" placeholder="Customer name" />
        <label class="number mt-3">Phone Number</label>
        <input type="text" class="plan" placeholder="Phone Number" />
        <label class="number mt-3">Enter address</label>
        <input type="text" class="plan" placeholder="Phone Number" />
        <label class="number mt-3">State</label>
        <select
          class="form-select plan"
          style="color: #3b325f; font-size: 18px; height: 50px; color: #9a9ab0"
        >
          <option value="">--</option>
          <option value="IP">In Progress</option>
          <option value="CP">Completed</option>
          <option value="CL">Cancelled</option>
        </select>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <h3 class="add">Enter Payment Details</h3>
        <div class="row">
          <div class="col-md-6">
            <label class="enter mt-2">Cash </label>
            <input type="text" class="plan1" placeholder="₹" />
            <label class="enter mt-2">Card </label>
            <input type="text" class="plan1" placeholder="₹" />
            <input
              type="text"
              class="plan1 mt-1"
              placeholder="Bank(Enter & Choose from list)"
            />
            <label class="enter mt-2">+ Add(Shift+Enter) </label>
            <label class="enter mt-2">UPI </label>
            <input type="text" class="plan1" placeholder="₹" />
            <input
              type="text"
              class="plan1 mt-1"
              placeholder="Bank(Enter & Choose from list)"
            />
            <label class="enter mt-2">+ Add(Shift+Enter) </label>
            <label class="enter mt-2">NetBanking </label>
            <input type="text" class="plan1" placeholder="₹" />
            <input
              type="text"
              class="plan1 mt-1"
              placeholder="Bank(Enter & Choose from list)"
            />
            <label class="enter mt-2">+ Add(Shift+Enter) </label>
            <label class="enter mt-2">Cheque </label>
            <input type="text" class="plan1" placeholder="₹" />
            <input
              type="text"
              class="plan1 mt-1"
              placeholder="Bank(Enter & Choose from list)"
            />
            <label class="enter mt-2">+ Add(Shift+Enter) </label>
          </div>
          <div class="col-md-6">
            <label for=""></label>
            <input type="text" class="plan2 mt-2" placeholder="" />
            <label for=""></label>
            <input type="text" class="plan2 mt-2" placeholder="" />
            <input type="text" class="plan2 mt-1" placeholder="" />
            <label for=""></label>
            <input type="text" class="plan3" placeholder="" />
            <input type="text" class="plan3 mt-1" placeholder="" />
            <label for=""></label>
            <input type="text" class="plan3" placeholder="" />
            <input type="text" class="plan3 mt-1" placeholder="" />
            <label for=""></label>
            <input type="text" class="plan3" placeholder="" />
            <input type="text" class="plan3 mt-1" placeholder="" />
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <button class="btn btn-primary side4">Add Intallment Details</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- <ng-template #showotp let-modal>
  <div class="pb-5 pt-3">
    <div class="modal-header">
      <h1 class="modal-title fbold"></h1>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <h1 class="fbold">Enter OTP</h1>
        <br />

        <p class="greyColor">
          <span>

            <ng-otp-input
            (keyup.enter)="getOtpNumberValidate();modal.dismiss('Cross click')"
              (onInputChange)="onOtpChange($event)"
              [config]="{ length: 4  , allowNumbersOnly:true }"
            ></ng-otp-input>
            <div *ngIf="counter > 0; else showThis" class="mt-2">
              <div>Resend after {{ counter }} sec</div>
            </div>
           
            <ng-template #showThis>
              <div class="mt-2">
                <a
                  (click)="
                    setCounter();
                    run = true;
                    run1 = true;
                    timerFun();
                    resendOtp(false)
                  "
                  class="resend_button"
                  type="button"
                >
                  Resend
                </a>
                <span class="mx-1" type="text"> OR </span>
                <a
                  (click)="
                    setCounter();
                    run = true;
                    run1 = true;
                    timerFun();
                    resendOtp(true)
                  "
                  type="button"
                class="resend_button"
                >
                  OTP via Call
                </a>
              </div>
            </ng-template>
          </span>
        </p>

        <button
          (click)="getOtpNumberValidate(); modal.dismiss('Cross click')"
          type="button"
          class="btn btn-primary mt-3 addbutton1"
        >
          Verify
        </button>
      </div>
    </div>
  </div>
</ng-template> -->

<ng-template #created_installment let-modal>
  <div class="myModal_new">
    <div class="modal-content p-4" style="width: 120%; height: 550px">
      <div class="modal-header" style="border: 0; height: 0">
        <h1 class="modal-title fbold"></h1>
        <button
          type="button"
          class="close closeButton btn"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <img
            src="assets/images/tickmark.svg"
            class="img-responsive img-fluid mb-3"
          />

          <h1
            class="fbold mt-2"
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              color: #262626;
            "
          >
            Booking Created Successfully !
          </h1>
          <div class="row">
            <div class="col-1"></div>
            <div class="col-8 ms-4">
              <table class="table ms-1">
                <tbody>
                  <tr>
                    <td class="tname">Plan ID</td>
                    <td>:</td>
                    <td class="dname">{{ m_planid }}</td>
                  </tr>

                  <tr>
                    <td class="tname">Plan Amount</td>
                    <td>:</td>
                    <td class="dname">{{ m_plan_amount }}</td>
                  </tr>
                  <tr>
                    <td class="tname">Customer Phone</td>
                    <td>:</td>
                    <td class="dname">{{ m_phone }}</td>
                  </tr>
                  <tr>
                    <td class="tname">Customer Name</td>
                    <td>:</td>
                    <td class="dname">{{ m_name }}</td>
                  </tr>
                  <tr>
                    <td class="tname">Installment No</td>
                    <td>:</td>
                    <td class="dname">{{ m_installment_data }}</td>
                  </tr>
                  <tr>
                    <td class="tname">Payment Mode</td>
                    <td>:</td>
                    <td class="dname">
                      {{ m_payment_mode[0] }} <span></span>
                      {{ m_payment_mode[1] ? "+Others" : "" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <button
            (click)="getBookingInvoice(m_payment_mode);modal.dismiss('Cross click')"
            type="button"
            class="btn btn-primary mt-2 addbutton1"
          >
            Print
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #add_booking let-modal>
  <div class="myModal_new">
    <div class="modal-content p-4" style="width: 120%; height: 550px">
      <div class="modal-header" style="border: 0; height: 0">
        <h1 class="modal-title fbold"></h1>
        <button
          type="button"
          class="close closeButton btn"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <img
            src="assets/images/tickmark.svg"
            class="img-responsive img-fluid mb-3"
          />

          <h1
            class="fbold mt-2"
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              color: #262626;
            "
          >
            Booking Created Successfully !
          </h1>
          <div class="row">
            <div class="col-1"></div>
            <div class="col-8 ms-4">
              <table class="table ms-1">
                <tbody>
                  <tr>
                    <td class="tname">Booking ID</td>
                    <td>:</td>
                    <td class="dname">{{ success_booking.booking_id }}</td>
                  </tr>

                  <tr>
                    <td class="tname">Rate/gm</td>
                    <td>:</td>
                    <td class="dname">{{ success_booking.rate }}</td>
                  </tr>
                  <tr>
                    <td class="tname">Booking Weight</td>
                    <td>:</td>
                    <td class="dname">{{ success_booking.weight }}</td>
                  </tr>
                  <tr>
                    <td class="tname">Customer Name</td>
                    <td>:</td>
                    <td class="dname">{{ success_booking.customer_name }}</td>
                  </tr>
                  <tr>
                    <td class="tname">Paid</td>
                    <td>:</td>
                    <td class="dname">{{ success_booking.paid }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <button
            (click)="getBookingInvoice(success_booking.invoice_url);modal.dismiss('Cross click')"
            type="button"
            class="btn btn-primary mt-2 addbutton1"
          >
            Print
          </button>
        
        </div>
      </div>
    </div>
  </div>
</ng-template>
