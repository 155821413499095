<div class="" style="height:90vh; width:100%; overflow-y:scroll;">

  <div class="">
<div class="head side">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-md-3">
                <h3 (click)="back()" class="back_title"> <mat-icon class="right_back">arrow_back_ios</mat-icon> Customers</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">
                <div class="input-group">
                    <mat-icon class="greyColor searchIcon">search</mat-icon>
                    <input type="text" class="form-control  search_box roundSquare text-indent"  id="plan_name" placeholder="Search Id, Name, phone, email or address">
                    <button mat-raised-button color="primary" class="searchButton">Search</button>
                </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <button  (click)="selectUser()" class="btn btn-primary float_right mt-4">Add New Customer</button>
            </div>
            <div class="col-md-1 mt-2">
                <button mat-raised-button color="primary" class="float_right mt-3"><mat-icon>download</mat-icon></button>
            </div>
        </div>
        <div class="row tablePadding side mt-3">
            <div class="col-md-12 text-center">
                <div class=" table-responsive">
                    <table  mat-table [dataSource]="dataSource" matSort class="">
                        <ng-container matColumnDef="customer id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer ID </th>
                            <td mat-cell *matCellDef="let element">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Customer Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Customer Name </th>
                            <td mat-cell *matCellDef="let element"></td>
                        </ng-container>
                        <ng-container matColumnDef="Phone Number">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number </th>
                            <td mat-cell *matCellDef="let element"> </td>
                        </ng-container>
                        <ng-container matColumnDef="Date Of Birth">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Birth </th>
                            <td mat-cell *matCellDef="let element"> </td>
                        </ng-container>
                        <ng-container matColumnDef="Email ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </th>
                            <td mat-cell *matCellDef="let element"> </td>
                        </ng-container>
                        <ng-container matColumnDef="Gender">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
                            <td mat-cell *matCellDef="let element"> </td>
                        </ng-container>
                        <ng-container matColumnDef="Joined On">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Joined On </th>
                            <td mat-cell *matCellDef="let element"> </td>
                        </ng-container>
                        <ng-container matColumnDef="Address">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
                            <td mat-cell *matCellDef="let element"> </td>
                        </ng-container>
                        <ng-container matColumnDef="Pin Code">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Pin Code </th>
                            <td mat-cell *matCellDef="let element"> </td>
                        </ng-container>
                        <ng-container matColumnDef="State">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>
                            <td mat-cell *matCellDef="let element"> </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[popupManager.page_size_number]" showFirstLastButtons></mat-paginator>
                </div>
            </div> <br>
        </div>
    </div>
</div>
<!-- <div class="container mt-5">
    <div class="row side">
        <h3 (click)="back()" class="back_title"> <mat-icon class="right_back">arrow_back_ios</mat-icon>Add Customer to plan</h3>
    </div>
    <div class="row side">
        <div class="col-md-4">
            <h3>Enter Phone Number</h3>
            <div class="input-group mb-5">
                <span class="input-group-text">+91</span>
                <div class="form-floating">
                  <input type="text" class="form-control" id="floatingInputGroup1" placeholder="Username">
                  <label for="floatingInputGroup1">Customer Phone Number</label>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <button class="btn btn-primary" (click)="open_sent(sent_modal)">Notification</button>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row side">
        <div class="col-md-4">
            <h3>Enter full name</h3>
            <input type="text" class="form-control" id="floatingInputGroup1" placeholder="Customer Full Name">
        </div>
        <div class="col-md-4 side1">
            <h3>Enter date of birth</h3>
            <div class="input-group mb-5">
                <span class="input-group-text"><mat-icon class="greyColor">date_range</mat-icon></span>
                  <input type="text" class="form-control" id="floatingInputGroup1" placeholder="--/--/----">
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row side">
        <div class="col-md-4">
            <h3>Enter email</h3>
            <input type="text" class="form-control" id="floatingInputGroup1" placeholder="Customer Email ID">

        </div>
        <div class="col-md-4 side1">
            <h3>Select gender</h3>
            <div class="input-group">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="-" aria-label="-" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <span class="input-group" id="basic-addon2">
                        <select id="plan_duration" [value]='1' class="form-select" aria-label="Default select example">
                            <option value="2" >2 </option>
                            <option value="3">3 </option>
                            <option value="4">4 </option>
                            <option value="5">5 </option>
                            <option value="6">6 </option>
                            <option value="7">7 </option>
                            <option value="8">8 </option>
                            <option value="9">9 </option>
                            <option value="10">10 </option>
                            <option value="11">11 </option>
                            <option value="12">12 </option>
                            <option value="13">13 </option>
                            <option value="14">14 </option>
                            <option value="15">15 </option>

                          </select>
                      </span>
                    </div>
                  </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row side mt-5">
        <div class="col-md-8">
            <h3>Enter address</h3>
            <input type="text" class="form-control address" id="floatingInputGroup1" placeholder="Customer Address">

        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row side mt-5">
        <div class="col-md-4 side1">
            <h3 class="state">State</h3>
            <select class="form-select state" style="color: #3B325F;font-size:18px; height: 50px; color: #9A9AB0;">
                <option value="">--</option>
                <option value="IP">In Progress</option>
                <option value="CP">Completed</option>
                <option value="CL">Cancelled</option>
              </select>
        </div>
        <div class="col-md-4">
            <h3>Enter pin code</h3>
            <input type="text" class="form-control" id="floatingInputGroup1" placeholder="Customer Pincode">

        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="row side">
        <div class="col-md-4"></div>
        <div class="col-md-4 mt-5 side1">
            <button class=" save"><span class="save1">Next</span></button>
        </div>
        <div class="col-md-4"></div>
    </div>
</div>

<ng-template #sent_modal let-modal>
    <div class="myModal cardClass">
    <div class="modal-header">
        <h1 class="modal-title"></h1>
        <div class="row">
            <div class="col-md-10">
                <p>6 transactions dated 18/09/22 are pending as rate/gm is not available. </p>
                <button class="btn btn-light mt-2">Enter Rate/Gm</button>
                <div class="clock mt-3">
                    <i class="fa-regular fa-clock"></i>
                    2 Hours ago
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
        <div class="ab">
            <div class="row">
                <div class="col-md-10">
                    <p class=" mt-3">6 transactions dated 18/09/22 are pending as rate/gm is not available. </p>
                    <button class="btn btn-light mt-2 ">Enter Rate/Gm</button>
                    <div class="clock mt-3 ">
                        <i class="fa-regular fa-clock mb-3"></i>
                        2 Hours ago
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10">
                <p class="sidea mt-3">6 transactions dated 18/09/22 are pending as rate/gm is not available. </p>
                <button class="btn btn-light mt-2 sidea">Enter Rate/Gm</button>
                <div class="clock mt-3 sidea">
                    <i class="fa-regular fa-clock"></i>
                    2 Hours ago
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <button class="btn btn-primary view mt-3 mb-4">View All Notifications</button>
            </div>
            <div class="col-md-1"></div>
        </div>

    </div>
  </ng-template> -->
</div>
</div>
