import { Component, Input } from '@angular/core';
import { CertificateData } from '../../models/Certificate-content';

@Component({
  selector: 'app-basic-template',
  templateUrl: './basic-template.component.html',
  styleUrls: ['./basic-template.component.css']
})
export class BasicTemplateComponent {
  @Input()  certificate_content!: CertificateData;
  @Input()  raw_certificate_content!: { key: string; value: string }[];

  handleBrokenImage(event:any){
    console.log(event)
  }
} 
