export class DesingModel{
    order_id!:string;
    size!:string;
    pieces!:number;
    order_notes!:number;
    designs!:Array<Design>;
    weight!:string;
    metal_purity!:string
}
export class Design{
    id!:string;
    image!:string;
    size! :number;
    name! :string;
}