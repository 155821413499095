import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResponseMessage } from 'src/app/models/message';
import { RolesandCounters } from '../models/rolesandcounters';

@Injectable({
  providedIn: 'root',
})
export class RolepermissionService {
  url = environment.ip + 'shop/';
  counter_url = environment.ip + 'shop/register_counter/';
  constructor(private http: HttpClient) {}

  addCounter(counter_id: any, pin: any, role: any) {
    var fd = new FormData();
    fd.append('counter_id', counter_id);
    fd.append('pin', pin);
    fd.append('role', role);
    return this.http.post<ResponseMessage>(this.counter_url, fd);
  }
  getCounter(page:number,search:string,ordering:string,status:string) {
    return this.http.get<RolesandCounters>(
      `${environment.ip}shop/counter_users/`,{params:{"search":search,"page":page,"ordering":ordering,"status":status}})

  }

  changeStatus(id: any, status: any) {
    var data = {
      status: status,
    };
    const headers = { 'content-type': 'application/json' };

    return this.http.post<ResponseMessage>(
      `${this.url}counter_users/${id}/update_status/`,
      data,
      { headers: headers }
    );
  }
  deleteCounter(id: any) {
    return this.http.post<ResponseMessage>(
      `${this.url}counter_users/${id}/delete_user/`,
      {}
    );
  }

  changeCounterPin(id: any, pin: any) {
    var data = {
      id: id,
      pin: pin,
    };
    const headers = { 'content-type': 'application/json' };
    return this.http.post<ResponseMessage>(
      `${this.url}counter_users/${id}/change_pin/`,
      data,
      { headers: headers }
    );
  }

  getPermission(id: any) {
    return this.http.get<ResponseMessage>(
      `${this.url}counter_users/${id}/permissions_editable/`
    );
  }

  editPermission(id: any, data: any) {

    const headers = { 'content-type': 'application/json' };
    return this.http.put<ResponseMessage>(
      `${this.url}counter_users/${id}/edit_permission/`,
      data,
      { headers: headers }
    );
  }

  change_mode(id:any,data:any)
  {

    const headers = { 'content-type': 'application/json' };
    return this.http.put<ResponseMessage>(
      `${this.url}counter_users/${id}/change_mode/`,
      data,
      { headers: headers }
    );
  }
}
