<div *ngIf="isOnline; else offlineMessage">
    <div *ngIf="authService.isLoggedIn();else other_content" >
        <app-main-nav *ngIf="is_mobile()" ></app-main-nav>
        <app-new-side-nav *ngIf="!is_mobile()"></app-new-side-nav>
        <app-floating-actions *ngIf="!is_mobile()"></app-floating-actions>
    </div>
    
    
    
    <ng-template #other_content>
        <app-login></app-login>
    
    </ng-template>
</div>

<ng-template #offlineMessage>
    <app-offline-page></app-offline-page>
</ng-template>


