import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OtpCommunicationService {
  private resendOtpClickedSubject = new Subject<void>();

  resendOtpClicked$ = this.resendOtpClickedSubject.asObservable();

  triggerResendOtpClick(): void {
    this.resendOtpClickedSubject.next();
  }
}
