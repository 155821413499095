import { Injectable } from '@angular/core';
import { RateServiceService } from '../rate-page/rate-service.service';
import { Observable } from 'rxjs';
import { DefaultRates, Purity } from '../models/rate';
import { CreateOrderService } from '../oms/create-order/create-order.service';

@Injectable({
  providedIn: 'root'
})
export class PurityService {
  static responseData: any;
  static purity_list: { key: string; value: string }[];
  static default_gold: DefaultRates;
  static pureties: Purity[] = [];


  constructor(private rateService : RateServiceService,
    private orderService: CreateOrderService,

  ) { }
  

  fetchData()
  {
    this.rateService.getDefaultRates().subscribe((data: any) => {
      console.log(data);
      PurityService.default_gold = new DefaultRates(data);
      PurityService.purity_list = PurityService.default_gold.toKeyValueArray();
      PurityService.purity_list = PurityService.purity_list.filter((i) => i.value != '');
    });
    this.orderService.getPurites().subscribe((data: any) => {
      PurityService.pureties = data.Purities;
    });
  }
  
  
  getData()
    {
      console.log("changeCommodityUtil")
      console.log(PurityService.pureties)
      console.log(PurityService.purity_list)
      return {default_gold:PurityService.default_gold,
          pureties:PurityService.pureties,
          purity_list:PurityService.purity_list};
    }
    
  
}
