<div class="container">
    <div class="row">
        <h2 class="back_title" (click)="back()">
            <mat-icon class="right_back">arrow_back_ios</mat-icon>
            Edit Details
        </h2>
    </div>
    <div class="sub-container">
        <div *ngIf="jewellery_model.asset_files != null" class="jewellery-images">
            <img *ngIf="current_selected_type == 1" class="selected-images-grid" [src]="current_selected_image"
                alt="images" />
            <video *ngIf="current_selected_type == 0" class="selected-images-grid" [poster]="current_selected_icon" controls>
                <source [src]="current_selected_image">
                Your browser does not support the video tag.
            </video>
            <div class="more-images horizontal-list">
                <div *ngFor="let image of jewellery_model.asset_files">
                    <img *ngIf="image.file_type == 1" (click)="setSelectedImage(image.file, image.file_type)"
                        class="sub-images" [src]="image.file" />
                    <div (click)="setSelectedImage(image.file, image.file_type);">
                        <video style="pointer-events: none;" *ngIf="image.file_type == 0" class="sub-images" [poster]="image.icon" controls>
                            <source [src]="image.file">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div *ngIf="jewellery_model.asset_files.length <= 7" class="add-image"
                    (click)="openUploadDialog(jewellery_model.id)">
                    <mat-icon class="add-image-icon">add</mat-icon>
                    <span class="add-image-text">Add</span>
                </div>
            </div>
        </div>
        <div style="margin: 10px;">
            <span class="jewellery-name-heading">Design Name</span>
            <input class="design-name-input form-control" placeholder="Design Name" type="text" id="designName"
                style="color: #262626; font-weight: 500; font-size: 14px;" [(ngModel)]="jewellery_model.name"
                (change)="getDesignName($event)" />
            <span class="jewellery-name-heading">Category</span>
            <div *ngIf="jewellery_model.category == null" class="category-box form-select"
                style="align-items: center; margin-right: 5px; cursor: pointer;" [matMenuTriggerFor]="categoryMenu"
                #menuTrigger="matMenuTrigger">
                <p style="align-items: center; justify-content: left; text-align: left; margin-top: 5px; color: #9F9F9F; font-size: 14px;"
                    id="selcted-category">
                    {{ 'Select Category' }}
                </p>
                <mat-menu #categoryMenu="matMenu" class="two-row-menu">
                    <div class="row">
                        <div>
                            <button *ngFor="let category of jewellery_category_model"
                                [class.selected-card]="category.is_checked" (click)="toggleCategory(category)">
                                {{category.name}}
                            </button>
                        </div>
                    </div>
                </mat-menu>
            </div>
            <div *ngIf="jewellery_model.category != null" class="category-box form-select"
                style="align-items: center; margin-right: 5px; cursor: pointer;" [matMenuTriggerFor]="categoryMenu"
                #menuTrigger="matMenuTrigger">
                <p style="align-items: center; justify-content: left; text-align: left; margin-bottom: 5px; color: #262626; font-weight: 500; font-size: 14px;"
                    id="selcted-category" *ngFor="let category of jewellery_category_model">
                    {{ jewellery_model.category == category.id ? category.name : '' }}
                </p>
                <mat-menu #categoryMenu="matMenu" class="two-row-menu">
                    <div class="row">
                        <div class="col-md-6">
                            <button *ngFor="let category of jewellery_category_model"
                                [class.selected-card]="category.is_checked" (click)="toggleCategory(category)">
                                {{category.name}}
                            </button>
                        </div>
                    </div>
                </mat-menu>
            </div>
            <span class="jewellery-name-heading">Metal & Purity</span>
            <div class="metal-box form-select" style="align-items: center; margin-right: 0px; cursor: pointer;"
                [matMenuTriggerFor]="purityMenu" #menuTrigger="matMenuTrigger">
                <p *ngIf="selected_purities.length == 0"
                    style="color: #262626; font-weight: 500; font-size: 14px; margin-left: 5px; margin-top: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; text-align: left; margin-right: 0px;">
                    {{ jewellery_model.material == 0 ? 'Gold' : jewellery_model.material == 2 ? 'Silver' : 'Platinum' }}
                    <ng-container *ngFor="let selectedPurity of jewellery_model.purity">
                        <span>
                            {{ selectedPurity.name }}
                        </span>
                    </ng-container>
                </p>
                <p *ngIf="selected_purities.length>0"
                    style="color: #262626; font-weight: 500; font-size: 14px; margin-left: 5px; margin-top: 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; text-align: left; margin-right: 0px;">
                    {{ selected_metal == 0 ? 'Gold' : selected_metal == 2 ? 'Silver' : 'Platinum' }}

                    <ng-container *ngFor="let selectedPurity of selected_purities">
                        <span *ngFor="let purity of metal_purity[activeLink]">
                            {{ selectedPurity == purity.id ? purity.name : '' }}
                        </span>
                    </ng-container>
                </p>
                <mat-menu #purityMenu="matMenu" class="metal-menu">
                    <div class="d-flex flex-row justify-content-between">
                        <div class="modal-body">
                            <div class="grid-container-metal"
                                style="display: flex; flex-direction: row; align-items: start; justify-items: start;">
                                <div class="metals">
                                    <mat-button-toggle-group class="mat-elevation-z0"
                                        style="flex-direction: column; border:none; width: 100%;">
                                        <mat-button-toggle class="mat-elevation-z0 d-flex justify-content: start"
                                            style="width: 100%; text-align: start; border: none;"
                                            *ngFor="let mp of metals; let i = index"
                                            (click)="$event.stopPropagation(); getSelectedMetal(i)">
                                            {{ mp == 0 ? 'Gold' : mp == 2 ? 'Silver' : 'Platinum' }}
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                                <div class="divider"></div>
                                <div class="purities">
                                    <mat-checkbox
                                        style="align-items: start; display: flex; flex-direction: column; margin-top: 20px;"
                                        *ngFor="let p of metal_purity[activeLink]" [checked]="is_metal_selected"
                                        [value]="p['id']" (click)="$event.stopPropagation()"
                                        (change)="getSelectedPurity(p['id'])">
                                        {{ p['name'] }}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <button type="button" class="primary-btn"
                                style="width: 95%; height:50px; margin-left: 5px; margin-bottom: 5px;"
                                (click)="saveSelectedPurities()">Save</button>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
        <div class="floating-menu-cancel">
            <button class="primary-btn" style="width:150px; height:50px" (click)="deleteJewellery()">Delete</button>
            <button class="secondary-btn" style="width:150px; height:50px"
                (click)="saveJewelleryChanges()">Save</button>
        </div>
    </div>