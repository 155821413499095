<ng-container *ngTemplateOutlet="upload_modal" >
    
</ng-container>


<ng-template #upload_modal let-modal >
    <div class="upload_modal" *ngIf="upload_Stage_1" style="size: xl;">
      <div class="modal-header px-5">
        <!-- <mat-icon class="back-arrow px-3" (click)="closeModal()" style="cursor: pointer;">keyboard_arrow_left</mat-icon> -->
        <h6 class="modal-title"></h6>
        <button type="button" style="outline: none;" class="close btn" aria-label="Close"
          (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ngx-dropzone class="drop-area" (change)="onSelect($event)" accept="image/jpeg, image/png, image/webp, video/mp4, video/quicktime" style="display: flex; justify-content: center;">
          <!-- ... rest of your code ... -->
          <div class="row" style="display: flex; margin-left: 0px; align-items: center; justify-content: center;">
            <div style="align-items: center; justify-content: center;" class="col-12">
              <img src="../assets/images/Group 69.png" alt=""><br>
            </div>
            <div class="col-12 mt-2" style="align-items: center; justify-content: center;">
              <ngx-dropzone-label>
                <h2>Upload or drag and drop an image </h2>
              </ngx-dropzone-label>
              Supported formats are JPEG, PNG, WEBG and MP4,mov for video <br>
              Recommended size upto 5mb per image and 50mb per video <br><br>
            </div>
            <!-- <div class="col-12">
              <ngx-dropzone-preview *ngFor="let f of selected_files" [removable]="true" (removed)="onRemove(f)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
              </ngx-dropzone-preview>
            </div>
            <div class="col-12">
              <mat-progress-bar mode="determinate" value="40" style="width: 100%; visibility: hidden;"></mat-progress-bar>
            </div> -->
            <div class="col-12">
              <button class="upload-btn" type="button">Upload</button>
            </div>
          </div>
        </ngx-dropzone>
      </div>
    </div>
    <div class="upload_status" *ngIf="upload_Stage_2">
      <div class="modal-header px-5">
        <!-- <mat-icon class="back-arrow px-3">keyboard_arrow_left</mat-icon> -->
        <h6 class="modal-title"></h6>
        <button type="button" style="outline: none;" class="close btn" aria-label="Close"
          (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="drop-area" (change)="onSelect($event)" style="padding: 5% 0% 5% 0%;">
          <div class="row">
            <div class="col-12">
              <img src="../assets/images/group 69-1.jpg" alt="" style="height: 58px; width: 58px;"><br>
            </div>
            <div class="col-12 my-3">
              <h2>Upload or drag and drop an image </h2>
              Supported formats are JPEG, PNG, WEBG and MP4,mov for video <br>
              Recommended size upto 3mb per image and 20mb per video <br><br>
            </div>
            <!-- <div class="col-12">
              <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
              </ngx-dropzone-preview>
            </div> -->
            <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; margin-bottom: 20px;">
              <div class="col-1 px-0" style="margin-right: 5px;">
                {{currentFileIndex+1}} {{'/'}} {{selected_files.length}}
              </div>
              <mat-progress-bar mode="determinate" [value]="uploadProgress" style="width: 85%;"></mat-progress-bar><br>
            </div>
            <div class="col-12">
              <button class="cancel-btn" type="button" (click)="modalService.dismissAll()" >Cancel</button>
              <button class="save-btn" type="button">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="upload_result" *ngIf="upload_Stage_3">
      <div class="modal-header px-5">
        <!-- <mat-icon class="px-3 back-arrow" (click)="closeModal()">keyboard_arrow_left</mat-icon> -->
        <h6 class="modal-title"></h6>
        <button type="button" style="outline: none;" class="close btn" aria-label="Close"
          (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="drop-area" style="padding: 5% 8%;">
          <h2>{{ uploaded_files.length }} out of {{ selected_files.length }} files are uploaded successfully.</h2>
          <div *ngIf="rejected_files.length > 0" class="upload_result_subheading">
            <img src="../assets/images/Error.png" style="height: 24px; width: 24px;">
            <h3 class="mt-3 mx-2">Below files not uploaded due to mismatch in criteria.</h3>
          </div>
          <div *ngIf="rejected_files.length > 0" class="row my-3">
            <div class="col-1"></div>
            <div class="col-2">
              <p class="row"  *ngFor="let files of rejected_files">
                <b>{{files.name}}</b>
              </p>
            </div>            
            <div class="col-1"></div>
          </div>
          <div class="col-12 mt-4">
            <button class="upload-btn" type="button" (click)="closeModal()">Okay</button>
          </div>
        </div>
  
      </div>
    </div>
    <div class="upload_success" *ngIf="upload_success">
      <div class="modal-header px-5"> 
      </div>
      <div class="modal-body text-center pb-4">
        <img src="..\assets\images\upload_success.png" alt="">
        <br><br>
        <h3><b>{{selected_files.length}} files uploaded successfully </b></h3>
        <!-- <br>
        <h5>Below file are rejected due to size constraints</h5>
        <br>
        <div class="row">
          <div class="col-1"></div>
          <span *ngFor="let rejected in rejected_files">
            {{ rejected.file.name }}<br><br>
          </span>
          <div class="col-1"></div>
        </div> -->
        <button class="okay-btn" type="button" (click)="closeModal()" >Okay</button>
      </div>
    </div>
  </ng-template>