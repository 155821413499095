export class AssetFile {
    id!: number;
    file!: string;
    icon!: string;
}

export class StoneEstimation {
    id!: number;
    name!: string;
    quantity!: number;
    weight!: number;
    carat!: number;
    rate!: number;
    cost!: number;
    charge_type!: number;
}

export class Result {
    id!: number;
    estimation_no!: string;
    date!: string;
    rate_per_gram!: number;
    metal!: number;
    purity!: number[];
    product_link!: string;
    asset_file!: AssetFile[];
    gross_weight!: number;
    net_weight!: number;
    wastage!: number;
    wastage_type!: number;
    making_charge!: number;
    making_charge_type!: number;
    stone_estimation!: StoneEstimation[];
    total_amount!: number;
    estimation_share_url!: string;
    gst!: number;
}

export class EstimationApiResponse {
    count!: number;
    next!: string | null;
    previous!: string | null;
    results!: Result[];
}

export class Purity {
    id?: number;
    name?: string;
  }
    
  export class Estimation {
    id?: number;
    estimation_no?: string;
    date?: string;
    rate_per_gram?: string;
    live_rate?: boolean;
    metal?: number;
    purity?: Purity;
    product_link?: any;
    asset_file?: AssetFile;
    gross_weight?: string;
    net_weight?: string;
    wastage?: string;
    wastage_type?: number;
    making_charge?: string;
    making_charge_type?: number;
    stone_estimation?: StoneEstimation[];
    gst?: string;
    total_amount?: string;
    estimation_share_url?: string;
  }
  