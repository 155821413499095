import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { PrimaryBtnComponent } from '../common/primary-btn/primary-btn.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DecimalPipe, NgFor, NgIf } from '@angular/common';
import { AmountDetails } from '../booking-details/booking-details.component';
import { FormsModule } from '@angular/forms';
import { DigitalCoinService } from '../services/digital-coin.service';
import { ToastrService } from 'ngx-toastr';
import { PageAccessService } from 'src/app/services/pageaccess.service';
import { NextInputDirective } from '../directives/next-input.directive';

export interface PaymentOption {
  type: string;
  options: {
    id: string;
    amount: number | undefined;
    date: Date | string;
    bank: string | number;
    payment_info: string;
  }[]
  mode: number;
}[]

export interface BankPos {
  id: number;
  number: string;
  type: number;
}

@Component({
  selector: 'app-payments-popup',
  standalone: true,
  imports: [NgIf, NgFor, MatIconModule, DecimalPipe, MatFormFieldModule, MatInputModule, PrimaryBtnComponent, MatSelectModule, MatDatepickerModule, MatNativeDateModule, FormsModule, NextInputDirective],
  templateUrl: './payments-popup.component.html',
  styleUrls: ['./payments-popup.component.css']
})
export class PaymentsPopupComponent {
  @ViewChild('payMethod') payMethod!: MatSelect;
  @ViewChild('posInput') posInput!: MatSelect;
  @ViewChild('nextInput') nextInput!: ElementRef;
  @ViewChild('nextInputs') nextInputs!: ElementRef;
  @ViewChild('picker') picker!: MatDatepicker<Date>;
  inputsIndices = [0]
  bankDropDownList: BankPos[] = []
  posDropDownList: BankPos[] = []
  @HostListener('window:keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (event.shiftKey && event.key == 'Enter') {
      event.preventDefault()
      this.addInputRow()
      // this.openCustomerDetails()
    } else if (event.ctrlKey && event.key == 's') {
      event.preventDefault()
      this.saveBooking()
    }
  }
  allowed_actions: { [key: string]: boolean } = {};
  paymentMethods: string[] = ["Card", "Cash", "UPI/MPS", "RTGS/NEFT", "Cheque"]
  modeIds: any = {
    Cash: 7,
    'RTGS/NEFT': 3,
    'UPI/MPS': 0,
    Cheque: 6,
    Card: 1
  }
  modeId: any = {
    7:'Cash',
    3:'RTGS/NEFT',
    0:'UPI/MPS',
    6:'Cheque',
    1:'Card'
  }
  paymentOptions: PaymentOption[] = [
    this.getDefaultPayment()
  ]



  constructor(
    public dialogRef: MatDialogRef<PaymentsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { paymentSummary: AmountDetails, phone: string, commodity: string, row_data: any },
    private digiService: DigitalCoinService,
    private toastService: ToastrService,
    private authservice: PageAccessService,
  ) { }

  ngOnInit(): void {
    this.allowed_actions = this.authservice.allowedActions;
    this.digiService.getBankAccounts().subscribe({
      next: res => {
        if (res.status == 200) {
          this.bankDropDownList = res.body.bank_accounts
          this.posDropDownList = res.body.pos_accounts
        } else this.toastService.error(res.body.message)
      },
      error: err => {
        this.toastService.error(err.error.message)
      }
    })
    if (this.data?.row_data) {
      let tableData = this.data.row_data
      this.digiService.getPaymentDetailsForOrder(tableData.id.toString()).subscribe({
        next: res => {
          if (res.status == 200) {
            console.log(res.body)
            this.paymentOptions = []
            res.body.forEach((order: any) => {
              let date = new Date()
              let recDate = order.date.split("/")
              date.setDate(+recDate[0])
              date.setMonth(+recDate[1] - 1)
              date.setFullYear(+recDate[2])
              this.paymentOptions.push({
                type: this.modeId[order.payment_mode_display],
                options: [{
                  id: order.id,
                  amount: order.amount,
                  date: date,
                  bank: order.offline_bank_account_id? order.offline_bank_account_id: '',
                  payment_info: order.payment_info
                }],
                mode: this.modeIds[order.payment_mode_display]
              })
            })
          } else this.toastService.error(res.body.message)
        },
        error: err => {
          this.toastService.error(err.error.message)
        }
      })
    }
  }

  addInputRow(): void {
    this.paymentOptions.push(this.getDefaultPayment())
  }
  deleteInputRow(index: number): void {
    this.paymentOptions.splice(index, 1)
  }

  getDefaultPayment(): PaymentOption {
    return {
      type: "Cash",
      options: [{
        "id": "",
        "amount": undefined,
        "date": new Date(),
        "bank": "",
        "payment_info": ""
      }],
      mode: 7
    }
  }
  isButtonDisabled: boolean = false;
  saveBooking(): void {
    let formInvalid: boolean = false

    let payload = {
      phone: this.data.phone,
      payment_options: this.paymentOptions.map((option: PaymentOption) => {
        if (typeof option.options[0].date === 'string') return option
        option.options[0].date = `${option.options[0].date.getFullYear()}-${(option.options[0].date.getMonth() + 1).toString().length < 2 ? '0' : ''}${option.options[0].date.getMonth() + 1}-${(option.options[0].date.getDate()).toString().length < 2 ? '0' : ''}${option.options[0].date.getDate()}`
        option.mode = this.modeIds[option.type]
        if (!option.options[0].amount) formInvalid = true
        return option
      }),
      weight: this.data.paymentSummary.weight,
      commodity: this.data.commodity
    }
    console.log(payload)
    if (formInvalid) {
      this.toastService.warning("Please Enter Amount")
      return
    }
    this.isButtonDisabled = true;
    if (this.data?.row_data) {
      this.digiService.editPaymentBooking(this.data.row_data.id, payload.payment_options).subscribe({
        next: res => {
          console.log(res)
          if (res.status == 200) {
            this.toastService.success("Booking Saved!")
            this.dialogRef.close({ ...res.body, weight: this.data.paymentSummary.weight })
          } else this.toastService.error(res.body.message)

        },
        error: err => {
          this.toastService.error('Something went wrong. Please try after sometime')
        },
        complete: () => {
          this.isButtonDisabled = false;
        }
      })
    } else {
      this.digiService.savePaymentBooking(payload).subscribe({
        next: res => {
          console.log(res)
          if (res.status == 201) {
            this.toastService.success("Booking Saved!")
            this.dialogRef.close({ ...res.body, weight: this.data.paymentSummary.weight })
          } else this.toastService.error(res.body.message)

        },
        error: err => {
          this.toastService.error('Something went wrong. Please try after sometime')
        },
        complete: () => {
          this.isButtonDisabled = false;
        }
      })
    }
  }
  isRowFormValid(rowIndex: number): boolean {
    const row = this.paymentOptions[rowIndex];
    const commonConditions = !!row.options[0].amount && !!row.options[0].date;
    if (row.type !== 'Cash') {
      return commonConditions && !!row.options[0].bank && !!row.options[0].payment_info;
    }
    return commonConditions;
  }

  checkRowValidity(rowIndex: number): void {
    const isValid = this.isRowFormValid(rowIndex);
  }

  onPaymentMethodChange(option: PaymentOption): void {
    option.options[0].payment_info = '';
    option.options[0].bank = ''; 
  }
  
  focusNextInput(event: any, inputName: string): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!this.payMethod.panelOpen) {
        this.nextInput.nativeElement.focus();
      }
    }
  }

  focusInput(event: any, inputName: string): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!this.posInput.panelOpen) {
        this.nextInputs.nativeElement.focus();
      }
    }
  }

  openDatepickerOnKey(event: any) {
    if (event.key === 'Enter') {
      this.picker.open();
      event.preventDefault();
    }
  }

  focus(id: string) {
    setTimeout(() => {
      const input = <HTMLInputElement>document.getElementById(id);
      input.focus();
    }, 100);
  }
}
