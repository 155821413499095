<div class="parent" (keydown.control.s)="saveDetails()">
    <div class="header">
        <div class="text">Customer Details</div>
        <mat-icon (click)="dialogRef.close()">close</mat-icon>
    </div>
    <form class="inps-con" [formGroup]="form">
        <div class="inp-row">
            <div class="inp">
                <div class="title">Full Name*</div>
                <input formControlName="name"  class="form-control" placeholder="Enter Full Name" appNextInput>
            </div>
            <div class="inp">
                <div class="title">Phone*</div>
                <input  formControlName="mobile" class="form-control"  placeholder="Enter Mobile No">
            </div>
            <div class="inp">
                <div class="title">Pan</div>
                <input
                (keydown.enter)="focus('gender')"
                formControlName="pan" class="form-control"  placeholder="Enter PAN" maxlength="10">
            </div>
            <div class="inp">
                <div class="title">Gender</div>
                <select id="gender" (keydown.enter)="focus('address')" class="form-select" formControlName="gender" placeholder="Choose Gender" appOptionalInput appNextInput>
                    <option [ngValue]="undefined" hidden>Choose Gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                </select>
            </div>
        </div>
        <div class="inp-row mt-4">
            <div class="inp" style="grid-column: 1 / span 2;">
                <div class="title">Address*</div>
                    <input id="address" formControlName="address" class="form-control"  placeholder="Enter Address" appNextInput>
            </div>
            <div class="inp">
                <div class="title">State*</div>
                    <select
                    (keydown.enter)="focus('referred_by')"
                    appNextInput
                    formControlName="state"
                    class="form-select"
                    placeholder="Choose State"
                  >
                    <option  [ngValue]="undefined" hidden>Choose State</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar Islands
                    </option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                    <option value="Daman and Diu">Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                </div>
            <div class="inp">
                <div class="title">Referred By</div>
                <input id="referred_by" formControlName="referred_by" class="form-control" placeholder="Enter Referred By" (keydown.enter)="focus('save_button')">
            </div>
        </div>
    </form>
    <button (click)="saveDetails()" [disabled]="form.invalid" id="save_button" class="saveBtn">Save(Ctrl+S)</button>
</div>