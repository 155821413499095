import { Injectable } from '@angular/core';
import { Customer } from '../models/customer';
import { HttpClient } from '@angular/common/http';
import { Rest } from 'src/app/models/rest_model';

import { environment } from 'src/environments/environment';
import { ResponseMessage } from '../models/message';
import { CounterData, CounterDataList, Dashboard } from '../models/dashboard';
import { BankAccount } from '../models/accounts';
import { Doughnut, ListGraphData } from '../models/graphdata';
import { AnalyticsData } from '../models/analyticsdata';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardnewService {
  url = environment.ip+"shop/dashboard/";
  graphurl=environment.ip+"shop/subscriptions/"
  constructor(private http:HttpClient) { }


  getCounterData(){
    return this.http.get<CounterDataList>(environment.ip+'shop/counter_data_list/')
  }
  getData()
  {
    return this.http.get<Dashboard>(this.url);
  }

  getGraph(month:any)
  {
    return this.http.get<ListGraphData>(`${this.graphurl}?month=${month}`)
  }

  getDoughnut()
  {
    return this.http.get<Doughnut>(`${environment.ip}shop/subscription_analysis/`)
  }


  getAnalytics (from_date:any,to_date:any)
  {
    return this.http.get<AnalyticsData>(`${environment.ip}shop/analytics/?from_date=${from_date}&to_date=${to_date}`)
  }
  getAbandonment(from_date:any,to_date:any,step:any)
  {
    from_date=from_date.split("-").join("")
    to_date=to_date.split("-").join("")
    return this.http.get((`${environment.ip}shop/abandoned_users/?from_date=${from_date}&to_date=${to_date}&step=${step}`))
  }
  getAbandonmentBooking(from_date:any,to_date:any,step:any)
  {
    from_date=from_date.split("-").join("")
    to_date=to_date.split("-").join("")
    return this.http.get((`${environment.ip}shop/abandoned_booking_users/?from_date=${from_date}&to_date=${to_date}&step=${step}`))
  }

  getBranchData(from_date:any,to_date:any)
  {
    return this.http.get((`${environment.ip}shop/branch_data/?from_date=${from_date}&to_date=${to_date}`))
  }

  getGoogleAnalytics(from_date:any,to_date:any)
  {
    return this.http.get((`${environment.ip}shop/google_analytics/?from_date=${from_date}&to_date=${to_date}`))
  }

  getGoogleAnalyticsBookings(from_date:any,to_date:any)
  {
    return this.http.get((`${environment.ip}shop/google_analytics_bookings/?from_date=${from_date}&to_date=${to_date}`))
  }


  getActiveUserData(from_date:any,to_date:any)
  {
    return this.http.get((`${environment.ip}shop/analytic_user_data/?from_date=${from_date}&to_date=${to_date}`))
  }

}
