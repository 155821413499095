import {  DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Rest } from "src/app/models/rest_model";
import { Settlement, SettlementOrder } from "src/app/models/settlement";
import { SettlementServiceService } from "../reports/settlement-service.service";



export class OrderDataSource extends DataSource<any> {

  private settlementData=new BehaviorSubject<SettlementOrder[]>([]);
  private loadingSettlements = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSettlements.asObservable();
  private countSettlements=new BehaviorSubject<number>(0);
  public counter$=this.countSettlements.asObservable();

  


  constructor(private settlementService:SettlementServiceService) {
    super();
  }
  connect(): Observable<SettlementOrder[]> {
    return this.settlementData.asObservable();

  }
  disconnect() { 
    this.settlementData.complete();
    this.loadingSettlements.complete();
  }
  loadData(page:number=0,from_date: any,to_date: any,search:any,filter:any){


      page=page+1;
      this.loadingSettlements.next(true);
      this.settlementService.getDetailReports(page=page,from_date=from_date,to_date=to_date,search=search,filter=filter).pipe(
        catchError(()=> of([])),
        finalize(()=>{
          this.loadingSettlements.next(false);

        })
        
      ).subscribe((settlementLogs: any)=>this.renderOrders(settlementLogs))
    
  }

  renderOrders(settlementLogs:any){
  
    this.countSettlements.next(settlementLogs.count);
    this.settlementData.next(settlementLogs.results);

    console.log("here",settlementLogs.results)
  }





}