import { Injectable } from '@angular/core';
import { Customer } from '../models/customer';
import { HttpClient } from '@angular/common/http';
import { Rest } from 'src/app/models/rest_model';

import { environment } from 'src/environments/environment';
import { ResponseMessage } from '../models/message';

@Injectable({
  providedIn: 'root'
})

export class CustomerdetailsService {
  url = environment.ip+"shop/users/";
  download_url = environment.uploaderIp+"shop/users/";
  constructor(private http:HttpClient) { }

  getUsers(page:number,search:string,ordering:string,status:string){
    return this.http.get<Rest<Customer>>(this.url,{params:{"search":search,"page":page,"ordering":ordering,"status":status}});

  }
  downloadCustomerData(f_date:any,t_date:any,email:any){
    var fd = new FormData();
    fd.append("from_date",f_date);
    fd.append("to_date",t_date);
    fd.append("email",email);
    return this.http.post(this.download_url+"download_customer/",fd);
  }

  deleteCustomerService(id:any)
  {
    return this.http.delete<ResponseMessage>(`${this.url}${id}/`);   
  }
  
  
}
