import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  HostListener,
  AfterViewInit,
} from '@angular/core';

import { Chart, ChartType } from 'chart.js/auto';

import { MatTooltipModule } from '@angular/material/tooltip';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DashboardnewService } from '../dashboardnew/dashboardnew.service';
import { formatDate } from '@angular/common';
import {
  AnalyticsData,
  BranchData,
  FunnelData,
  FunnelDropDown,
  LifeTimePerformance,
} from '../models/analyticsdata';
// import { HtmlParser } from '@angular/compiler';
import { BehaviorSubject, Observable, timeout } from 'rxjs';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { PopUpManager } from '../manager/Popup';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { TitleService } from '../services/title.service';
import { IndianNumerationPipe } from '../indian-numeration.pipe';
import { DeviceDetectorService } from 'ngx-device-detector';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { ListGraphData } from '../models/graphdata';
import { DigitalCoinService } from '../digital-coin/services/digital-coin.service';
import { GetUserDataAnalytics } from '../digital-coin/interfaces/get-user-data-analytics.interface';

export interface UserData {
  step: string;
  users: number;
  completion_rate: number;
  abandonment: string;
  abandonment_rate: number;
}
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css'],
})
export class AnalyticsComponent {
  installment: any = [];
  all_adv: any = [];
  multi_axis_chart: any;
  all_installment!: ListGraphData;
  myPlaceholder = '';
  users = [];
  displayedColumns: string[] = [
    'step',
    'users',
    'completion',
    'abandonments',
    'abandonment_rate',
  ];
  displayedColumnsNew: string[] = ['serialNumber', 'column1'];
  dataSource!: UserData[];
  dataSourceBooking!: UserData[];
  active_user_loading: boolean = false;
  pageNumberControl = new FormControl(1);
  limit: number = 10;
  date: any;
  ndate: any;
  days_in_month!: number[];
  customer_details_loading: boolean = true;

  app_per_sdate!: any;
  app_per_edate!: any;

  //for sip funnel
  funnel_edate!: any;
  funnel_sdate!: any;
  //for advance booking funnel
  funnel_edate_booking!: any;
  funnel_sdate_booking!: any;

  total_amount_of_funnel!: any;
  total_amount_booking_funnel!: any;
  currentItem: any;

  user_performance_block: any;
  app_performance!: any;
  savings_scheme!: any;
  savings_scheme_life_time!: any;

  showCalendar!: boolean;
  public chart: any;
  public chartnew: any;
  public chart_: any;
  //for sip chart
  public wave_chart_1: any;
  public wave_chart_2: any;
  public wave_chart_3: any;
  public wave_chart_4: any;
  public wave_chart_5: any;
  public wave_chart_6: any;
  // for advance booking chart
  public wave_chart_7: any;
  public wave_chart_8: any;
  public wave_chart_9: any;

  public chartDoughnut: any;
  public chartDoughnutD: any;
  public chartDoughnutBookings: any;

  public chartDoughnutMob: any;

  popupManager!: PopUpManager;
  screenHeight!: number;
  screenWidth!: number;

  gradient: any;

  anything = 'shduahdabjk';

  analytic_data!: AnalyticsData;
  branch_data!: any;
  funnel_data!: FunnelData[];
  life_time_performance!: LifeTimePerformance;
  dropdown_open = false;

  dropdown_data!: FunnelDropDown;

  loading: boolean = true;
  saving_scheme_loading: boolean = false;
  app_performance_loading: boolean = false;
  sevenDaysAgo!: any;
  today!: any;

  snakebar!: any;
  selected_month = new Date().getMonth() + 1;
  monthsWithDays: { month: string; days: number }[] = [
    { month: 'January', days: 31 },
    { month: 'February', days: 28 }, // Leap year has 29 days
    { month: 'March', days: 31 },
    { month: 'April', days: 30 },
    { month: 'May', days: 31 },
    { month: 'June', days: 30 },
    { month: 'July', days: 31 },
    { month: 'August', days: 31 },
    { month: 'September', days: 30 },
    { month: 'October', days: 31 },
    { month: 'November', days: 30 },
    { month: 'December', days: 31 },
  ];
  isMobile!: boolean;
  isDesktop!: boolean;
  public isChartReady = false;
  userBalance: any[] = []

  constructor(
    private titleService: TitleService,
    private toastr: ToastrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private dashboardservice: DashboardnewService,
    private snackBar: MatSnackBar,
    private deviceService: DeviceDetectorService,
    private digiService:DigitalCoinService
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.isDesktop = this.deviceService.isDesktop();

    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  downloadFile(data: any) {
    const replacer = (key: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map((row: { [x: string]: any }) =>
      JSON.stringify(row, replacer)
    );
    let csvArray = csv.join('\n');

    console.log('download');
    var blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, 'CustomerDetails.csv');
  }

  setCurrentItem(item: any) {
    if (this.currentItem === item) {
      this.currentItem = item;
    } else {
      this.currentItem = item;
    }

    if (this.currentItem == 'item1') {
      this.user_performance_block.style.display = 'flex';
      this.app_performance.style.display = 'none';
      this.savings_scheme.style.display = 'none';
      this.savings_scheme_life_time.style.display = 'none';
    }
    if (this.currentItem == 'item2') {
      this.app_performance.style.display = 'block';

      this.user_performance_block.style.display = 'none';
      this.savings_scheme.style.display = 'none';
      this.savings_scheme_life_time.style.display = 'none';
    }
    if (this.currentItem == 'item3') {
      this.savings_scheme.style.display = 'block';
      this.user_performance_block.style.display = 'none';
      this.app_performance.style.display = 'none';
      this.savings_scheme_life_time.style.display = 'none';
    }
    if (this.currentItem == 'item4') {
      this.savings_scheme_life_time.style.display = 'block';

      this.user_performance_block.style.display = 'none';
      this.app_performance.style.display = 'none';
      this.savings_scheme.style.display = 'none';
      if (this.life_time_performance.in_progress_count != null) {
        setTimeout(() => {
          this.chartDoughnut_();
        }, 1000);
      }
    }
    if(this.currentItem == 'item5'){
      this.savings_scheme_life_time.style.display = 'none';

      this.user_performance_block.style.display = 'none';
      this.app_performance.style.display = 'none';
      this.savings_scheme.style.display = 'none';
    }
  }
  ngAfterViewInit() {
    // Perform initialization tasks after the view is initialized
  }
  getDaysInMonth(month: number): number[] | null {
    if (month >= 1 && month <= 12) {
      const daysInMonth = Array.from(
        { length: this.monthsWithDays[month - 1].days },
        (_, index) => index + 1
      );
      this.days_in_month = daysInMonth;
      this.getGraphData(month);
      return daysInMonth;
    } else {
      console.error(
        'Invalid month number. Please provide a value between 1 and 12.'
      );
      return null;
    }
  }

  

  dropdownHandler(id: any) {
    var drop_element = <HTMLDivElement>document.getElementById(id);

    if (this.dropdown_open == false) {
      drop_element.style.display = 'block';
      this.dropdown_open = true;
    } else {
      drop_element.style.display = 'none';
      this.dropdown_open = false;
    }
  }

  getAbandonmentUsersList(string: any) {
    this.dashboardservice
      .getAbandonment(this.funnel_sdate, this.funnel_edate, string)
      .subscribe((data: any) => {
        this.customer_details_loading = false;
        this.users = data.users;
      });
  }
  getAbondonmentUsersBooking(string:any)
  {
    this.dashboardservice
    .getAbandonmentBooking(this.funnel_sdate, this.funnel_edate, string)
    .subscribe((data: any) => {
      this.customer_details_loading = false;
      this.users = data.users;
    });

  }
  listCustomer(content: any, index: any,type:any) {
    this.customer_details_loading = true;
    var string;
    this.users = [];
    if (index == 0) {
      string = 'step_1=1 AND step_2=0';
    } else if (index == 1) {
      string = 'step_2=1 AND step_3=0';
    } else if (index == 2) {
      string = 'step_3=1 AND step_4=0';
    } else if (index == 3) {
      return;
    }
    if (type==0)
    {
      this.getAbandonmentUsersList(string);
    }
    else{
      this.getAbondonmentUsersBooking(string);
    }

    this.modalService.open(content, {
      centered: true,
      windowClass: 'my-class',
    });
  }

  range(start: number, end: number) {
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  }

  removeEndDate() {
    if (this.app_per_sdate) {
      this.app_per_edate = '';
    }
  }
  removeEndDateFunnel() {
    if (this.funnel_sdate) {
      this.funnel_edate = '';
    }
  }
  removeEndDateFunnelBooking() {
    if (this.funnel_sdate_booking) {
      this.funnel_edate_booking = '';
    }
  }

  getAppDates() {
    if (this.app_per_edate && this.app_per_sdate) {
      this.app_performance_loading = true;
      if (
        this.app_per_edate < this.app_per_sdate ||
        (this.app_per_edate || this.app_per_sdate) > this.today
      ) {
        this.app_performance_loading = false;
        return;
      }

      var start_date = formatDate(this.app_per_sdate, 'yyyy-MM-dd', 'en-US');
      var end_date = formatDate(this.app_per_edate, 'yyyy-MM-dd', 'en-US');

      this.dashboardservice
        .getActiveUserData(start_date, end_date)
        .subscribe((data: any) => {
          this.app_performance_loading = false;
          this.analytic_data = data;
          this.dashboardservice
            .getBranchData(start_date, end_date)
            .subscribe((data: any) => {
              this.branch_data = data.branch_data;
            }),
            (error: any) => {
              this.toastr.info('', "Couldn't load Branch Data", {
                positionClass: 'toast-bottom-right',
              });
            };
        }),
        (error: any) => {
          this.toastr.info('', "Couldn't load Users Data", {
            positionClass: 'toast-bottom-right',
          });
        };
    }
  }

  getFunnelDates() {
    if (this.funnel_edate && this.funnel_sdate) {
      if (
        this.funnel_sdate > this.funnel_edate ||
        (this.funnel_edate || this.funnel_sdate) > this.today
      ) {
        this.saving_scheme_loading = false;

        return;
      }

      this.saving_scheme_loading = true;
      var start_date = formatDate(this.funnel_sdate, 'yyyy-MM-dd', 'en-US');
      var end_date = formatDate(this.funnel_edate, 'yyyy-MM-dd', 'en-US');

      this.dashboardservice.getGoogleAnalytics(start_date, end_date).subscribe(
        (data: any) => {
          this.saving_scheme_loading = false;
          console.log(data)
          this.funnel_data = data.funnel_data;
          this.dataSource = this.funnel_data;

          this.dashboardservice.getAnalytics(start_date, end_date).subscribe(
            (data: any) => {
              this.dropdown_data = data.funnel_drop_down;
            },
            (error: any) => {
              this.toastr.info('', 'Something Went Wrong !', {
                positionClass: 'toast-bottom-right',
              });
            }
          );
            // temp fix
           
        },
        (error: any) => {
          this.saving_scheme_loading = true;
          this.toastr.info('', "Could'nt load Funnel Data !", {
            positionClass: 'toast-bottom-right',
          });
        }
      );
    }
  }

  getFunnelDatesBooking() {
    if (this.funnel_edate_booking && this.funnel_sdate_booking) {
      if (
        this.funnel_sdate_booking > this.funnel_edate_booking ||
        (this.funnel_edate_booking || this.funnel_sdate_booking) > this.today
      ) {
        this.saving_scheme_loading = false;
        return;
      }

      this.saving_scheme_loading = true;
      var start_date = formatDate(
        this.funnel_sdate_booking,
        'yyyy-MM-dd',
        'en-US'
      );
      var end_date = formatDate(
        this.funnel_edate_booking,
        'yyyy-MM-dd',
        'en-US'
      );

      this.dashboardservice
        .getGoogleAnalyticsBookings(start_date, end_date)
        .subscribe(
          (data: any) => {
            this.saving_scheme_loading = false;
            this.dataSourceBooking = data.funnel_data;

            this.dashboardservice.getAnalytics(start_date, end_date).subscribe(
              (data: any) => {
                this.dropdown_data = data.funnel_drop_down;
              },
              (error: any) => {
                this.toastr.info('', 'Something Went Wrong !', {
                  positionClass: 'toast-bottom-right',
                });
              }
            );
          },
          (error: any) => {
            this.saving_scheme_loading = true;
            this.toastr.info('', "Could'nt load Funnel Data !", {
              positionClass: 'toast-bottom-right',
            });
          }
        );
    }
  }

  multiAxisGraph() {
    if (this.multi_axis_chart) {
      this.multi_axis_chart.clear();
      this.multi_axis_chart.destroy();
    }

    const canvas = document.getElementById(
      'multi_axis_graph'
    ) as HTMLCanvasElement | null;

    const ctx = canvas?.getContext('2d');

    const labels = this.days_in_month
      ? this.days_in_month
      : Array.from(
          {
            length: new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
            ).getDate(),
          },
          (_, index) => index + 1
        );

    // const ctxMultiAxis=<HTMLCanvasElement>(
    //   document.getElementById('multi_axis_graph')
    // );
    // document.getElementById('chartjsLegend')!.innerHTML = ctx!.generateLegend();
    const plugin = {
      beforeInit(chart: any) {
        // Get a reference to the original fit function
        const originalFit = chart.legend.fit;

        // Override the fit function
        chart.legend.fit = function fit() {
          // Call the original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)();
          // Change the height as suggested in other answers
          this.height += 15;
        };
      },
    };

    if (ctx) {
      this.multi_axis_chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,

          datasets: [
            {
              label: 'SIP',
              data: this.installment,

              backgroundColor: 'rgba(0,122,255, 0.2)',
              borderColor: 'rgba(0,122,255, 1)',
              borderWidth: 1,
              fill: false, // Ensure the line is not filled
              pointRadius: 0,
              pointBackgroundColor: '#BBDBFF',
            },
            {
              label: 'Advance Booking',
              data: this.all_adv,

              backgroundColor: 'rgba(39,174,96, 0.2)',
              borderColor: 'rgba(39,174,96, 1)',
              borderWidth: 1,
              fill: false, // Ensure the line is not filled
              pointRadius: 0,
              pointBackgroundColor: '#A6DDBE',
            },
          ],
        },
        options: {
          scales: {
            y: {
              grid: {
                display: false,
                color: '#E5E5E5', // Set this to false to remove the x-axis grid lines
              },
              beginAtZero: true,
              ticks: {
                color: '#9C9C9C',
              },
            },
            x: {
              grid: { color: '#E5E5E5' },
              ticks: {
                color: '#9C9C9C',
              },
            },
          },
          elements: {
            line: {
              tension: 0.3,
            },
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              align: 'end',
              labels: {
                usePointStyle: true,
                padding: 20,
              },
            },
          },
        },
      });
    }
  }
  viewAllClicked():void{
    this.viewAllBool = !this.viewAllBool
    if(this.viewAll === 2) this.viewAll = this.digiTable?.results?.length ?? 0
    else this.viewAll = 2
    this.fetchAnalyticsData();
  }
  OverallStats:any
  digiTable:GetUserDataAnalytics|null = null;
  getUserDataAnalytics:GetUserDataAnalytics|null = null;
  userDataState: "SUCCESS"|"ERROR"|"LOADING" ="LOADING";
  displayedColumnsDigi = ["Customer Name","Phone No","Gold 24k","Gold 22k","Silver","Total Investment"]
  viewAll:number = 2
  viewAllBool:boolean = false
  ngOnInit(): void {
    this.funnel_data = [];
    this.analytic_data = new AnalyticsData();
    this.dropdown_data = new FunnelDropDown();
    this.life_time_performance = new LifeTimePerformance();
    this.user_performance_block = <HTMLDivElement>(
      document.getElementById('user_performance')
    );
    this.app_performance = <HTMLDivElement>(
      document.getElementById('app_performance')
    );
    this.savings_scheme = <HTMLDivElement>(
      document.getElementById('savings_scheme')
    );
    this.savings_scheme_life_time = <HTMLDivElement>(
      document.getElementById('savings_scheme_life_time')!
    );

    this.digiService.getUserVaultDataForShop().subscribe({
      next: res => {
        if (res.status == 200) {
          this.userBalance = res.body.data
          // this.userData = res.body.user
        }
      },
      error: err => {
        this.toastr.error(err.error.message)
      }
    })
    
    this.digiService.getOverallStats().subscribe({
      next: res => {
        if (res.status == 200) {
          this.OverallStats = res.body
          this.chartDoughnutDigi()
        }
      },
      error: err => {
        this.toastr.error(err.error.message)
      }
    })

    // this.digiService.getAnalyticsTable(this.page,this.limit).subscribe({
    //   next: res => {
    //     if (res.status == 200) {
    //       this.digiTable = res.body.results.map((d:any,i:any)=>{d.index=i;return d})
    //     }
    //   },
    //   error: err => {
    //     this.toastr.error(err.error.message)
    //   }
    // })

    this.digiService.getAnalytics('2024-01-24','2024-03-02').subscribe({
      next: res => {
        if (res.status == 200) {
          
        }
      },
      error: err => {
        this.toastr.error(err.error.message)
      }
    })
    
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    setTimeout(() => {
      this.getGraphData(currentMonth);
    }, 1000);
    if (this.screenWidth < 800) {
      this.setCurrentItem('item1');
    }
    this.sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    this.today = new Date();
    this.sevenDaysAgo = formatDate(this.sevenDaysAgo, 'yyyy-MM-dd', 'en-US');
    this.today = formatDate(this.today, 'yyyy-MM-dd', 'en-US');
    this.loading = false;
    this.app_per_sdate = this.sevenDaysAgo;
    this.app_per_edate = this.today;
    this.funnel_sdate = this.sevenDaysAgo;
    this.funnel_edate = this.today;
    this.funnel_edate_booking=this.today;
    this.funnel_sdate_booking=this.sevenDaysAgo;
    this.dashboardservice
      .getAnalytics(this.sevenDaysAgo, this.today)
      .subscribe((data: any) => {
        console.log(data);
        this.loading = true;
        this.dropdown_data = data.funnel_drop_down;
        console.log(data);
        this.life_time_performance = data.life_time_performance_data;
        this.isChartReady = true;
        if (this.isDesktop) {
          setTimeout(() => {
            this.chartDoughnut_();
          }, 1000);
        }
        this.total_amount_of_funnel =
          Number(this.dropdown_data.app_new_customer_amount) +
          Number(this.dropdown_data.app_repeat_customer_amount) +
          Number(this.dropdown_data.store_new_customer_amount) +
          Number(this.dropdown_data.store_repeat_customer_amount);

        this.total_amount_booking_funnel =
          Number(this.dropdown_data.app_new_booking_amount) +
          Number(this.dropdown_data.app_repeat_booking_amount) +
          Number(this.dropdown_data.store_new_booking_amount) +
          Number(this.dropdown_data.store_repeat_booking_amount);
      });
    this.getGoogleAnalyticsSubcriptions(this.sevenDaysAgo, this.today);
    this.getGoogleAnalyticsBookings(this.sevenDaysAgo, this.today);
    this.getBranchData(this.sevenDaysAgo, this.today);
    this.getActiveUsers(this.sevenDaysAgo, this.today);
    this.setCurrentItem('item1');
    this.fetchAnalyticsData();
  }

  fetchAnalyticsData(pageNumber:number = 1) {
   this.userDataState = "LOADING";
    this.digiService.getAnalyticsTable(pageNumber, this.limit).subscribe({
      next: res => {
        if (res.status == 200) {
          // this.digiTable = res.body.results.map((d:any,i:any)=>{return d})
          this.digiTable = res.body;
          this.userDataState = "SUCCESS";
        }

      },
      error: err => {
        this.userDataState = "ERROR";
        this.toastr.error(err.error.message)

      }

    });
  }
  getActiveUsers(from_date: any, to_date: any) {
    this.dashboardservice
      .getActiveUserData(from_date, to_date)
      .subscribe((data: any) => {
        this.active_user_loading = true;
        this.analytic_data = data;
      }),
      (error: any) => {
        this.toastr.info('', "Couldn't load Users Data", {
          positionClass: 'toast-bottom-right',
        });
      };
  }

  getGoogleAnalyticsSubcriptions(from_date: any, to_date: any) {
    this.dashboardservice.getGoogleAnalytics(from_date, to_date).subscribe(
      (data: any) => {
        console.log(data);
        this.funnel_data = data.funnel_data;
        console.log(this.funnel_data)
        setTimeout(() => {
          this.dataSource = this.funnel_data;
          this.waveChart1();
          this.waveChart2();
          this.waveChart3();
          this.waveChart4();
          //for advance booking

         
        }, 500);


      },
      (error: any) => {
        this.toastr.info('', "Couldn't load Analytics Data", {
          positionClass: 'toast-bottom-right',
        });
      }
    );

  }

  getGoogleAnalyticsBookings(from_date: any, to_date: any) {
    this.dashboardservice
      .getGoogleAnalyticsBookings(from_date, to_date)
      .subscribe(
        (data: any) => {
          console.log(data);
          setTimeout(() => {
            this.dataSourceBooking = data.funnel_data;
            console.log(this.dataSourceBooking)
               this.waveChart7();
          this.waveChart8();
          this.waveChart9();
          }, 500);
        },
        (error: any) => {
          this.toastr.info('', "Couldn't load Analytics Data", {
            positionClass: 'toast-bottom-right',
          });
        }
      );
  }

  getBranchData(from_date: any, to_date: any) {
    this.dashboardservice
      .getBranchData(from_date, to_date)
      .subscribe((data: any) => {
        this.branch_data = data.branch_data;
        this.createChart();
      }),
      (error: any) => {
        this.toastr.info('', "Could'nt load App Download Source", {
          positionClass: 'toast-bottom-right',
        });
      };
  }

  getCurrentDate(): string {
    const today = new Date();
    let day: string = String(today.getDate());
    let month: string = String(today.getMonth() + 1);
    const year: string = String(today.getFullYear());

    if (day.length < 2) {
      day = '0' + day;
    }

    if (month.length < 2) {
      month = '0' + month;
    }

    return `${year}-${month}-${day}`;
  }

  rateUpdate(content: any) {
    this.modalService.open(content, { centered: true });
  }
  //for sip
  waveChart1() {
    const waveData1 = this.createList(1072, 450);

    const canvas = document.getElementById(
      'waveCanvas_1'
    ) as HTMLCanvasElement | null;

    const ctx = canvas?.getContext('2d');
    this.wave_chart_1 = new Chart(ctx!, {
      type: 'line',
      data: {
        labels: [0, 1, 2, 3, 4],
        datasets: [
          {
            label: 'Wave',
            data: waveData1,
            fill: true,
            backgroundColor: 'rgba(13, 67, 218, 1)',
            borderColor: 'rgba(13, 67, 218, 1)',
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            display: false,
            min: 0,
            max: 2000,
          },

          x: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
  //for advance booking
  waveChart7() {
    const waveData1 = this.createList(1072, 450);

    const canvas = document.getElementById(
      'waveCanvas_7'
    ) as HTMLCanvasElement | null;

    const ctx = canvas?.getContext('2d');
    this.wave_chart_7 = new Chart(ctx!, {
      type: 'line',
      data: {
        labels: [0, 1, 2, 3, 4],
        datasets: [
          {
            label: 'Wave',
            data: waveData1,
            fill: true,
            backgroundColor: 'rgba(13, 67, 218, 1)',
            borderColor: 'rgba(13, 67, 218, 1)',
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            display: false,
            min: 0,
            max: 2000,
          },

          x: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
  waveChart8() {
    const waveData1 = this.createList(1072, 450);

    const canvas = document.getElementById(
      'waveCanvas_8'
    ) as HTMLCanvasElement | null;

    const ctx = canvas?.getContext('2d');
    this.wave_chart_8 = new Chart(ctx!, {
      type: 'line',
      data: {
        labels: [0, 1, 2, 3, 4],
        datasets: [
          {
            label: 'Wave',
            data: this.createList2(450, 219),
            fill: true,
            backgroundColor: 'rgba(13, 67, 218, 1)',
            borderColor: 'rgba(13, 67, 218, 1)',
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            display: false,
            min: 0,
            max: 2000,
          },

          x: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
  waveChart9() {
    const canvas = document.getElementById(
      'waveCanvas_9'
    ) as HTMLCanvasElement | null;

    const ctx = canvas?.getContext('2d');
    this.wave_chart_9 = new Chart(ctx!, {
      type: 'line',
      data: {
        labels: [0, 1, 2, 3, 4, 5],
        datasets: [
          {
            label: 'Wave',
            data: this.createList2(229, 9),
            fill: true,
            backgroundColor: 'rgba(83, 126, 245, 1)',
            borderColor: 'rgba(83, 126, 245, 1)',
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            display: false,
            min: 0,
            max: 2000,
          },
          x: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
  createList(x: number, y: number): number[] {
    const length = 5;
    const increment1 = Math.round(x * 0.15); // 15% of x
    const increment2 = Math.round(y * -0.2); // -20% of y
    const list = [
      x,
      x + increment1,
      x + increment2,
      y + Math.round(y * 0.22), // 22% of y
      y,
    ];
    return list;
  }

  createList2(x: number, y: number): number[] {
    const length = 6;
    const increment1 = Math.round(x * 0.0556); // 5.56% of x
    const increment2 = Math.round(y * 0.242); // 24.20% of y
    const list = [
      x,
      x + increment1,
      x + increment2,
      y + 200,
      y + increment2 - increment1,
      y,
    ];
    return list;
  }
  createList3(x: number, y: number): number[] {
    const length = 6;
    const increment1Percent = 1 / 3; // 33.33% of x
    const increment2Percent = 1 / 3; // 33.33% of y
    const increment1 = x * increment1Percent;
    const increment2 = y * increment2Percent;
    const list = [
      x,
      x + increment1,
      x + increment1,
      y + increment1,
      y + increment2,
      y,
    ];
    return list;
  }

  waveChart2() {
    const canvas = document.getElementById(
      'waveCanvas_2'
    ) as HTMLCanvasElement | null;

    const ctx = canvas?.getContext('2d');
    this.wave_chart_2 = new Chart(ctx!, {
      type: 'line',
      data: {
        labels: [0, 1, 2, 3, 4, 5],
        datasets: [
          {
            label: 'Wave',
            data: this.createList2(450, 219),
            fill: true,
            backgroundColor: 'rgba(50, 104, 251, 1)',
            borderColor: 'rgba(50, 104, 251, 1)',
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            display: false,
            min: 0,
            max: 2000,
          },
          x: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  waveChart3() {
    const canvas = document.getElementById(
      'waveCanvas_3'
    ) as HTMLCanvasElement | null;

    const ctx = canvas?.getContext('2d');
    this.wave_chart_3 = new Chart(ctx!, {
      type: 'line',
      data: {
        labels: [0, 1, 2, 3, 4, 5],
        datasets: [
          {
            label: 'Wave',
            data: this.createList2(229, 9),
            fill: true,
            backgroundColor: 'rgba(83, 126, 245, 1)',
            borderColor: 'rgba(83, 126, 245, 1)',
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            display: false,
            min: 0,
            max: 2000,
          },
          x: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
  waveChart4() {
    const canvas = document.getElementById(
      'waveCanvas_4'
    ) as HTMLCanvasElement | null;

    const ctx = canvas?.getContext('2d');
    this.wave_chart_4 = new Chart(ctx!, {
      type: 'line',
      data: {
        labels: [0, 1, 2, 3, 4, 5],
        datasets: [
          {
            label: 'Wave',
            data: this.createList3(9, 9),
            fill: true,
            backgroundColor: 'rgba(121, 155, 247, 1)',
            borderColor: 'rgba(121, 155, 247, 1)',
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            display: false,
            min: 0,
            max: 2000,
          },
          x: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
  waveChart5() {
    const canvas = document.getElementById(
      'waveCanvas_5'
    ) as HTMLCanvasElement | null;

    const ctx = canvas?.getContext('2d');
    this.wave_chart_5 = new Chart(ctx!, {
      type: 'line',
      data: {
        labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        datasets: [
          {
            label: 'Wave',
            data: [3.4, 3.4, 3.3, 3.3, 3.2, 3.2, 3.2, 3.1, 3.1, 2.9, 2.9],
            fill: true,
            backgroundColor: 'rgba(160, 184, 250, 1)            ',
            borderColor: 'rgba(160, 184, 250, 1)            ',
            tension: 0.37,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            display: false,
            min: 0,
            max: 12,
          },
          x: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
  waveChart6() {
    const canvas = document.getElementById(
      'waveCanvas_6'
    ) as HTMLCanvasElement | null;

    const ctx = canvas?.getContext('2d');
    this.wave_chart_6 = new Chart(ctx!, {
      type: 'line',
      data: {
        labels: [0, 1, 2, 3],
        datasets: [
          {
            label: 'Wave',
            data: [788, 242, 103, 1],
            fill: true,
            backgroundColor: 'rgba(198, 213, 254, 1)            ',
            borderColor: 'rgba(198, 213, 254, 1)            ',
            tension: 0.37,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            display: false,
            min: 0,
            max: 800,
          },
          x: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  chartDoughnut_() {
    if (this.chartDoughnut) {
      this.chartDoughnut.clear();
      this.chartDoughnut.destroy();
    }
    if (this.chartDoughnutBookings) {
      this.chartDoughnutBookings.clear();
      this.chartDoughnutBookings.destroy();
    }
    const indianNumerationPipe = new IndianNumerationPipe();

    const ctxDoughnut = <HTMLCanvasElement>(
      document.getElementById('myChartDoughnut')
    );

    this.chartDoughnut = new Chart(ctxDoughnut, {
      type: 'doughnut',

      data: {
        datasets: [
          {
            label: '',
            data: [
              this.life_time_performance.in_progress_count,
              this.life_time_performance.completed_count,
              this.life_time_performance.billed_count, 
              this.life_time_performance.cancelled_count,
            ],
            backgroundColor: [' #668DF6B2', '#668DF6', '#3268FB','#082D92'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        cutout: 90,
        onResize: function (chart, size) {
          var doughnutRadius =
            Math.min(ctxDoughnut.width, ctxDoughnut.height) / 2;
          this.cutout = doughnutRadius / 100;
        },
      },

      plugins: [
        {
          id: 'customPluginName',
          beforeDraw: (chart, args, opts) => {
            var width = this.chartDoughnut.width,
              height = this.chartDoughnut.height,
              ctx = this.chartDoughnut.ctx;
            ctx.restore();

            var fontSize = (height / 110).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            ctx.textBaseline = 'middle';

            var text =
              '₹' +
              indianNumerationPipe.transform(
                this.life_time_performance.outstanding_subscription
              );
            // var newtext = `Total ${this.life_time_performance.total_count} Plans`,
            var newtext = `Outstanding`,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;
            ctx.fillText(text, textX, textY);

            var fontSize = (height / 230).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            var textx = Math.round(
              (width - ctx.measureText(newtext).width) / 2
            );
            var texty = height / 1.65;

            ctx.fillText(newtext, textx, texty);
            ctx.save();
          },
        },
      ],
    });

    // bookings
    const ctxDoughnutBookings = <HTMLCanvasElement>(
      document.getElementById('myChartDoughnutBookings')
    );

    this.chartDoughnutBookings = new Chart(ctxDoughnutBookings, {
      type: 'doughnut',

      data: {
        datasets: [
          {
            label: '',
            data: [
              this.life_time_performance.completed_booking_count,
              this.life_time_performance.in_progress_booking_count,
              this.life_time_performance.cancelled_booking_count,
            ],
            backgroundColor: [' #668DF6', '#3268FB', '#3268FB'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        cutout: 80,
        onResize: function (chart, size) {
          var doughnutRadius =
            Math.min(ctxDoughnutBookings.width, ctxDoughnutBookings.height) / 2;
          this.cutout = doughnutRadius / 100;
        },
      },

      plugins: [
        {
          id: 'customPluginName',
          beforeDraw: (chart, args, opts) => {
            var width = this.chartDoughnutBookings.width,
              height = this.chartDoughnutBookings.height,
              ctx = this.chartDoughnutBookings.ctx;
            ctx.restore();

            var fontSize = (height / 110).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            ctx.textBaseline = 'middle';

            var text =
              '₹' +
              indianNumerationPipe.transform(
                this.life_time_performance.all_booking_amount
              );
            var newtext = `Total ${this.life_time_performance.all_booking} Plans`,
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;
            ctx.fillText(text, textX, textY);

            var fontSize = (height / 230).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            var textx = Math.round(
              (width - ctx.measureText(newtext).width) / 2
            );
            var texty = height / 1.65;

            ctx.fillText(newtext, textx, texty);
            ctx.save();
          },
        },
      ],
    });
  }

  chartDoughnutDigi() {
    if (this.chartDoughnutD) {
      this.chartDoughnutD.clear();
      this.chartDoughnutD.destroy();
    }
    const indianNumerationPipe = new IndianNumerationPipe();

    const ctxDoughnut = <HTMLCanvasElement>(
      document.getElementById('myChartDoughnutDigi')
    );

    this.chartDoughnutD = new Chart(ctxDoughnut, {
      type: 'doughnut',

      data: {
        datasets: [
          {
            label: '',
            data: [
              this.OverallStats?.gold_24_total_amount ?? 0,
              this.OverallStats?.gold_22_total_amount ?? 0,
              this.OverallStats?.silver_total_amount ?? 0,
            ],
            backgroundColor: [' #668DF6', '#082d92', '#3268FB'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        cutout: 80,
        onResize: function (chart, size) {
          var doughnutRadius =
            Math.min(ctxDoughnut.width, ctxDoughnut.height) / 2;
          this.cutout = doughnutRadius / 100;
        },
      },

      plugins: [
        {
          id: 'customPluginName',
          beforeDraw: (chart, args, opts) => {
            var width = this.chartDoughnutD.width,
              height = this.chartDoughnutD.height,
              ctx = this.chartDoughnutD.ctx;
            ctx.restore();

            var fontSize = (height / 110).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            ctx.textBaseline = 'middle';

            var text =
              '₹' +
              indianNumerationPipe.transform(
                this.OverallStats.total_digital_coin_order_payments
              );
            var newtext = `Total ${this.OverallStats.all_digital_coin_order} Orders`,
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;
            ctx.fillText(text, textX, textY);

            var fontSize = (height / 230).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            var textx = Math.round(
              (width - ctx.measureText(newtext).width) / 2
            );
            var texty = height / 1.65;

            ctx.fillText(newtext, textx, texty);
            ctx.save();
          },
        },
      ],
    });
  }
  
  chartDoughnutMobile() {
    const indianNumerationPipe = new IndianNumerationPipe();

    const ctxDoughnut = <HTMLCanvasElement>(
      document.getElementById('myChartDoughnutMobile')
    );

    this.chartDoughnutMob = new Chart(ctxDoughnut, {
      type: 'doughnut',

      data: {
        datasets: [
          {
            label: '',
            data: [
              this.life_time_performance.completed_count,
              this.life_time_performance.in_progress_count,
              this.life_time_performance.cancelled_count,
            ],
            backgroundColor: [' #668DF6', '#3268FB', '#3268FB'],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        cutout: 80,
        onResize: function (chart, size) {
          var doughnutRadius =
            Math.min(ctxDoughnut.width, ctxDoughnut.height) / 2;
          this.cutout = doughnutRadius / 100;
        },
      },

      plugins: [
        {
          id: 'customPluginName',
          beforeDraw: (chart, args, opts) => {
            var width = this.chartDoughnutMob.width,
              height = this.chartDoughnutMob.height,
              ctx = this.chartDoughnutMob.ctx;
            ctx.restore();

            var fontSize = (height / 110).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            ctx.textBaseline = 'middle';

            var text =
              '₹' +
              indianNumerationPipe.transform(
                this.life_time_performance.total_subscription
              );
            var newtext = `Total ${this.life_time_performance.total_count} Plans`,
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2;
            ctx.fillText(text, textX, textY);

            var fontSize = (height / 230).toFixed(2);
            ctx.font = fontSize + 'em rubik';

            var textx = Math.round(
              (width - ctx.measureText(newtext).width) / 2
            );
            var texty = height / 1.65;

            ctx.fillText(newtext, textx, texty);
            ctx.save();
          },
        },
      ],
    });
  }

  capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  createChart() {
    const canvas = document.getElementById(
      'chartCanvas'
    ) as HTMLCanvasElement | null;

    var app_download_source = [];

    console.log('bd', this.branch_data);

    var arr = [
      'Referrals',
      'Store QR',
      'Youtube',
      'Google',
      'Whatsapp',
      'SMS',
      'Instagram',
      'Facebook',
    ];

    for (let i = 0; i < arr.length; i++) {
      this.branch_data.push({ key: arr[i], value: 0 });
    }

    for (let i = 0; i < this.branch_data.length; i++) {
      app_download_source.push(
        this.capitalizeFirstLetter(this.branch_data[i].key.split('_')[0])
      );
    }

    app_download_source = Array.from(new Set(app_download_source));

    const ctx = canvas?.getContext('2d');
    this.chart_ = new Chart(ctx!, {
      type: 'bar',
      data: {
        labels: app_download_source,
        datasets: [
          {
            label: '',
            data: this.branch_data.map(
              (value: any) => (value.value * 100) / this.branch_data.length
            ),
            backgroundColor: ['rgb(50, 104, 251)'],
            borderColor: ['rgb(50, 104, 251)'],
          },
        ],
      },
      options: {
        indexAxis: 'y',

        scales: {
          x: {
            suggestedMax: 100,
            beginAtZero: true,
            grid: {
              display: true,
              lineWidth: 1,
              color: 'rgba(0, 0, 0, 0.1)',
            },
            ticks: {
              callback: (value) => {
                return value + '%';
              },
              font: {
                family: 'Rubik',
                size: 10.342,
                weight: '400',
                style: 'normal',
                lineHeight: '13px',
              },
            },
          },

          y: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                family: 'Rubik',
                size: 10.342,
                weight: '400',
                style: 'normal',
                lineHeight: '13px',
              },
              color: '#606060',
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
  showData() {
    const canvas = document.getElementById(
      'myChart'
    ) as HTMLCanvasElement | null;

    const canvasnew = document.getElementById(
      'myNewChart'
    ) as HTMLCanvasElement | null;
    const ctx = canvas?.getContext('2d');
    const ctxnew = canvas?.getContext('2d');

    if (ctx) {
      this.gradient = ctx?.createLinearGradient(0, 0, 0, 400);
      this.gradient.addColorStop(1, 'rgba(187, 214, 167, 1)');
      this.gradient.addColorStop(0, 'rgba(187, 214, 167, 0.33)');
    }

    if (canvasnew) {
      this.gradient = ctxnew?.createLinearGradient(0, 0, 0, 400);
      this.gradient.addColorStop(1, 'rgba(187, 214, 167, 1)');
      this.gradient.addColorStop(0, 'rgba(187, 214, 167, 0.33)');
    }

    this.chartnew = canvasnew
      ? new Chart(canvasnew, {
          type: 'line',
          data: {
            labels: [1, 2, 3, 4, 5, 6, 7],
            //dates
            datasets: [
              {
                label: 'No. of Installments',
                data: [1, 2, 3, 4, 5, 6, 7],
                //installment
                borderWidth: 1,
                backgroundColor: this.gradient,
                fill: true,
                borderColor: '#BBD6A7',
                pointBackgroundColor: '#BBD6A7',
              },
            ],
          },
          options: {
            scales: {
              y: {
                grid: {
                  display: false,
                },
                beginAtZero: true,
                ticks: {
                  precision: 0,
                  font: {
                    size: 10,
                  },
                },
              },
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  font: {
                    size: 10,
                  },
                },
              },
            },

            plugins: {
              legend: {
                display: false,
              },
            },
            elements: {
              line: {
                tension: 0.3,
              },
            },
          },
        })
      : null;

    this.chart = ctx
      ? new Chart(ctx, {
          type: 'line',
          data: {
            labels: [1, 2, 3, 4, 5, 6, 7],
            //dates
            datasets: [
              {
                label: 'No. of Installments',
                data: [1, 2, 3, 4, 5, 6, 7],
                //installment
                borderWidth: 1,
                backgroundColor: this.gradient,
                fill: true,
                borderColor: '#BBD6A7',
                pointBackgroundColor: '#BBD6A7',
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  precision: 0,
                  font: {
                    size: 10,
                  },
                },
              },
              x: {
                ticks: {
                  font: {
                    size: 10,
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
            elements: {
              line: {
                tension: 0.3,
              },
            },
          },
        })
      : null;
  }
  getGraphData(month: any) {
    this.dashboardservice.getGraph(month).subscribe((data) => {
      this.all_installment = data;
      var days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
      console.log(this.all_installment);
      this.installment = [];
      this.all_adv = [];
      for (var i = 0; i < this.all_installment.activation_list.length; i++) {
        let newDate = new Date(this.all_installment.activation_list[i].date);

        if (this.all_installment.activation_list[i].type == 'sip') {
          this.installment.push(this.all_installment.activation_list[i].count);
        } else {
          this.all_adv.push(this.all_installment.activation_list[i].count);
        }
      }
      setTimeout(() => {
        this.multiAxisGraph();
      }, 100);
    });
  }



  onPaginatorPageChange(event: any): void {
    console.log("The page is ," ,event);
    // this.page = event.pageIndex + 1; // Adjust page index as needed
    this.fetchAnalyticsData(event.pageIndex + 1);
  }
}
