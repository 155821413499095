import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JewelleryCategoryApiResponse } from '../models/jewellery-category-model';
import { JewelleryMetalPurityApiResponse } from '../models/jewellery-metal-purity-model';
import { JewelleryApiResponse, JewelleryModel } from '../models/jewelleries-model';
import { environment } from 'src/environments/environment';
import { Rest } from '../models/rest_model';

@Injectable({
    providedIn: 'root',
})
export class CatalogueService {

    private getJewelleryUrl = environment.ip + 'shop/jewellery/';
    private createEmptyJewellery = environment.uploaderIp + 'shop/jewellery/createEmptyJewellery/';
    private createAdditionalEmptyJewellery = environment.uploaderIp + 'shop/jewellery/createAdditionalEmptyJewellery/';
    private sendChunkInfoUrl = environment.uploaderIp + 'shop/jewellery/sendJewelleryChunkInfo/';
    private uploadJewelleryAssetsUrl = environment.uploaderIp + 'shop/jewellery/uploadJewelleryAssets/';
    private getJewelleryCategoryUrl = environment.ip + 'shop/jewellery-category/';
    private getJewelleryMetalPurityUrl = environment.ip + 'shop/jewellery-metal-purity/';
    private deleteJewelleryUrl = environment.ip + 'shop/jewellery/deleteJewellery/';
    private updateJewelleryItemUrl = environment.uploaderIp + 'shop/jewellery/updateJewellery/';
    private mergeJewelleryItemsUrl = environment.ip + 'shop/jewellery/mergeSelectedJewellery/';
    private searchJewelleryItemUrl = environment.ip + 'shop/jewellery/';
    private applyFilterUrl = environment.ip + 'shop/jewellery/';



    constructor(private http: HttpClient) { }

    getJewelleryData(pageIndex: number): Observable<JewelleryApiResponse> {
        console.log('get jewellery data $pageIndex');
        return this.http.get<Rest<JewelleryModel>>(this.getJewelleryUrl + '?page=' + pageIndex);
    }

    getJewelleryDetails(id: any) {
        return this.http.get<Rest<JewelleryModel>>(this.getJewelleryUrl + id + '/');
    }

    getJewelleryCategoryData(): Observable<JewelleryCategoryApiResponse> {
        console.log('get jewellery category data');
        return this.http.get<JewelleryCategoryApiResponse>(this.getJewelleryCategoryUrl);
    }

    getJewelleryMetalPurityData(): Observable<JewelleryMetalPurityApiResponse> {
        console.log('get jewellery metal purity data');
        return this.http.get<JewelleryMetalPurityApiResponse>(this.getJewelleryMetalPurityUrl);
    }

    createEmptyJewelleryItem(file_name: String, file_type: number, chunk_count: number): Observable<any> {
        console.log('create empty jewellery item');
        console.log(chunk_count);
        var data = {
            "file_name": file_name,
            "file_type": file_type,
            "chunk_count": chunk_count,
        };
        return this.http.post(this.createEmptyJewellery, data);
    }

    createAdditonalJewelleryItem(file_name: String, file_type: number, chunk_count: number, jewellery_id: number): Observable<any> {
        console.log('create empty jewellery item');
        console.log(chunk_count);
        var data = {
            "file_name": file_name,
            "file_type": file_type,
            "chunk_count": chunk_count,
            "jewellery_id": jewellery_id
        };
        return this.http.post(this.createAdditionalEmptyJewellery, data);
    }

    sendChunkInfo(jewellery_id: number, chunk: any, chunk_num: number, total_chunk_count: number, uuid: any): Observable<any> {
        console.log('send chunk info' + chunk_num);
        var chunk_info = {
            "jewellery_id": jewellery_id,
            "chunk_num": chunk_num,
            "chunk": chunk,
            "total_chunk_count": total_chunk_count,
            "uuid": uuid
        };
        return this.http.post(this.sendChunkInfoUrl, chunk_info);
    }

    uploadJewelleryAssets(file: File,): Observable<any> {
        console.log('upload jewellery assets' + file + file.name);
        const formData = new FormData();
        formData.append('asset_files', file, file.name);
        console.log(this.uploadJewelleryAssetsUrl);
        return this.http.post(this.uploadJewelleryAssetsUrl, formData)
    }
    async uploadChunk(chunk: Blob) {
        const formData = new FormData();
        formData.append('fileChunk', chunk, 'chunk');

        try {
            await this.http.post(this.uploadJewelleryAssetsUrl, formData).toPromise();
            console.log('Chunk copied successfully.');
        } catch (error) {
            console.error('Error copying chunk:', error);
        }
    }

    deleteJewellery(jewellery_id: number): Observable<any> {
        console.log('delete jewellery assets');
        const formData = new FormData();
        formData.append('id', jewellery_id.toString());
        return this.http.post(this.deleteJewelleryUrl, formData)
    }

    deleteMultiple(jewellery_ids: Set<number>): Observable<any> {
        console.log('delete jewellery assets');
        const formData = new FormData();
        jewellery_ids.forEach((value) => {
            formData.append('id', value.toString());
        });
        return this.http.post(this.deleteJewelleryUrl, formData)
    }

    updateJewelleryItem(jewellery_id: number, jewellery_name: string, jewellery_category: number, jewellery_metal: number, jewellery_purity: number[]): Observable<any> {
        console.log('update jewellery item' + jewellery_id + jewellery_name + jewellery_category + jewellery_metal + jewellery_purity );
        const formData = new FormData();
        formData.append('id', jewellery_id.toString());
        if (jewellery_name != null && jewellery_name != undefined && jewellery_name != '') {
            formData.append('name', jewellery_name);
        }
        if (jewellery_category != null && jewellery_category != undefined && jewellery_category != 0) {
            formData.append('category', jewellery_category.toString());
        }
        if (jewellery_purity.length > 0) {
            if (jewellery_metal != null && jewellery_metal != undefined) {
                formData.append('material', jewellery_metal.toString());
            }
            for (let i = 0; i < jewellery_purity.length; i++) {
                formData.append('purity', jewellery_purity[i].toString());
            }
        }
        // if (jewellery_image.length > 0) {
        //     for (let i = 0; i < jewellery_image.length; i++) {
        //         formData.append('asset_files', jewellery_image[i], jewellery_image[i].name);
        //     }
        // }
        console.log(formData.getAll('purity'));
        return this.http.put(this.updateJewelleryItemUrl, formData)
    }

    mergeJewelleryItems(jewellery_ids: Set<number>, category__id: number, metal_id: number, purities: number[]): Observable<any> {
        console.log('merge jewellery items');
        var formData = new FormData();
        jewellery_ids.forEach((value) => {
            formData.append('id', value.toString());
        });
        if (category__id != null && category__id != undefined && category__id != 0) {
            formData.append('category', category__id.toString());
        }
        if (purities.length > 0) {
            formData.append('metal', metal_id.toString());
            purities.forEach((value) => {
                formData.append('purity', value.toString());
            });
        }
        console.log('merge jewellery items' + formData.getAll('id'));
        return this.http.post(this.mergeJewelleryItemsUrl, formData)
    }
    searchJewelleryItem(query: string, pageIndex: number): Observable<any> {
        console.log('this is page no: ', pageIndex)
        const searchJewelleryItemUrl = `${this.searchJewelleryItemUrl}?search=${query}&page=${pageIndex}`;

        // console.log(searchJewelleryItemUrl);
        return this.http.get(searchJewelleryItemUrl);
    }
    applyFilterOnJewelleryItems(category: number, metals: number, purities: number[], pageIndex: number) {
        const baseUrl = this.applyFilterUrl; // Your base URL

        let url = baseUrl;
        if (category !== null && category !== 0 && category !== undefined) {
            console.log('category is not null');
            url += '?category__id=' + category;
        } else {
            console.log('category is null');
            url += '?category__id=';
        }
        if (purities.length > 0) {
            if (metals !== null) {
                url += '&&material=' + metals;
            }
            for (const purity of purities) {
                url += '&&purity__id=' + purity;
            }
        }

        url += '&page=' + pageIndex;
        console.log(url);
        return this.http.get(url);
    }
}
