<div class="content" id="content">
    
    <div class="install_amount_box">
        <div class="general_heading" style="color:#262626">
            Installment Amount
        </div>
        <div class="total_amount_input mt-1">
            <div class="flex_sa">
                <div  class="total_amount" style="color:#606060" >
                    Total
                </div>
                <div class="amount_box" >
                   <span class="me-2">
                    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.37387 16.682C6.19369 16.682 6.05105 16.643 5.94595 16.5651C5.84084 16.4872 5.74324 16.3937 5.65315 16.2846L0.495496 10.1599C0.375375 10.0352 0.292793 9.92615 0.247748 9.83264C0.217718 9.72355 0.202703 9.55992 0.202703 9.34173V8.82745C0.202703 8.67161 0.247748 8.54693 0.337838 8.45342C0.442943 8.34433 0.570571 8.28979 0.720721 8.28979H1.93694C2.98799 8.28979 3.79129 8.14953 4.34685 7.86901C4.9024 7.58849 5.27778 7.21446 5.47297 6.74693C5.68318 6.2794 5.78829 5.74174 5.78829 5.13394C5.78829 4.55732 5.68318 4.03524 5.47297 3.56771C5.27778 3.08459 4.91742 2.70278 4.39189 2.42226C3.86637 2.14174 3.12312 2.00148 2.16216 2.00148H0.518018C0.367868 2.00148 0.24024 1.95472 0.135135 1.86122C0.0450451 1.75213 0 1.61966 0 1.46381V0.856022C0 0.700178 0.0450451 0.575503 0.135135 0.481996C0.24024 0.372905 0.367868 0.318359 0.518018 0.318359H9.48198C9.63213 0.318359 9.75225 0.372905 9.84234 0.481996C9.94745 0.575503 10 0.700178 10 0.856022V1.46381C10 1.61966 9.94745 1.75213 9.84234 1.86122C9.75225 1.95472 9.63213 2.00148 9.48198 2.00148H6.05856L5.4955 1.37031C6.47147 1.61966 7.13964 2.09498 7.5 2.79628C7.86036 3.49758 8.04054 4.30797 8.04054 5.22745C8.04054 6.08459 7.86036 6.8716 7.5 7.58849C7.15465 8.30537 6.5991 8.882 5.83333 9.31836C5.08258 9.73914 4.11411 9.96511 2.92793 9.99628L2.25225 10.0197L2.27477 9.08459L8.04054 15.9573C8.16066 16.082 8.22072 16.1989 8.22072 16.308C8.22072 16.4326 8.17568 16.5261 8.08559 16.5885C7.9955 16.6508 7.88288 16.682 7.74775 16.682H6.37387ZM0.518018 5.74174C0.352853 5.74174 0.225225 5.69498 0.135135 5.60148C0.0450451 5.50797 0 5.3755 0 5.20407V4.61966C0 4.44823 0.0450451 4.31576 0.135135 4.22225C0.24024 4.12875 0.367868 4.082 0.518018 4.082H9.48198C9.63213 4.082 9.75225 4.12875 9.84234 4.22225C9.94745 4.31576 10 4.44823 10 4.61966V5.20407C10 5.3755 9.94745 5.50797 9.84234 5.60148C9.75225 5.69498 9.63213 5.74174 9.48198 5.74174H0.518018Z" fill="#262626"/>
                    </svg>
                    </span> 
                    {{plan_detail.amount}}
                </div>
            </div>
        </div>
    </div>
    <div class="payment_method_block">
        <div>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Payment Method
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let payment of payment_arr; let i = index">
                    <div class="flex_sb mt-1" style="gap:2%">
                        <div>
                            <div class="payment_label">Amount</div>
                            <div><input
                                [(ngModel)]="payment.options[0].amount"
                                placeholder="enter amount"  class="form-control"></div>
                        </div>
                        <div>
                            <div class="payment_label">Method</div>
                            <div><select 
                                [(ngModel)]="payment.mode"
                                class="form-select">
                                <option value="7">Cash</option>
                                <option value="1">Card</option>
                                <option value="3">NEFT/RTGS</option>
                                <option value="0">UPI/IMPS</option>
                                <option value="6">Cheque</option>
                                <option value="4">Online-Payment Gateway</option>    
                            </select>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="payment_label">Date</div>
                        <div><input
                            [(ngModel)]="payment.options[0].date"
                            type="date" class="form-control"></div>
                    </div>
        
                    <div class="mt-2">                        
                        <div class="payment_label">Bank/ POS Account</div>
                        <div>
                            <ng-select
                            #fovu
                            class="form-select"
                            [searchable]="true"
                            [(ngModel)]="payment.options[0].bank"
                            [notFoundText]="'Type Valid Bank'"
                            [disabled]="payment.mode == 7"
                            [id]="'payment_bank_' + i"
                            [style]="payment.mode==7?{'background': '#DFDFDF!important'}:{}"
                          >
                            <ng-option
                              *ngFor="let account of account_list"
                              [value]="account.id"
                            >
                              {{ account.number }}
                            </ng-option>
                          </ng-select>
                        </div>
                    </div>
        
                    <div class="mt-2">                        
                        <div class="payment_label">UPI/ UTR/ Cheque Number*</div>
                        <div><input 
                            [disabled]="payment.mode == 7"
                            [(ngModel)]="payment.options[0].payment_info"
                            class="form-control"></div>
                    </div>
                    <hr class="px-2">
                </div>              
            </mat-expansion-panel>
        </div>
   
   
    </div>

    <div class="flex_sb" style="margin:5%">
        <div><button class="add_more" (click)="addPaymentRow()">Add More</button></div> 
        <div><button class="save_button"(click)="savePayment()">Save</button></div>   

   </div>
</div>

<ng-template #success>
    <app-lottie-popup 
    [lottie_file_path]="success_payment_popup_lottie_file_path"
    [pop_up_data]="success_payment_data"
    (proceedButtonClickEvent)="clearModal()"
    >
</app-lottie-popup>
</ng-template>