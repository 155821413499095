import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { AddInstallmentForDailyPayRequest, InstallmentDataFromID, PaymentOptions, PaymentType } from 'src/app/models/installments';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { InstallmentServiceService } from 'src/app/create_launch_plans/add-installments/installment-service.service';
import { error } from 'console';
import { AnimationOptions } from 'ngx-lottie';
import { PopUpData } from 'src/app/templates/warning-popup/warning-popup/warning-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'add-installment-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class AddPaymentComponent {
  @Input() plan_detail!:InstallmentDataFromID;
  @Output() closePayment = new EventEmitter<boolean>();

  @ViewChild('success', { read: TemplateRef })
  success_payment_template!:TemplateRef<any>;
  
  success_payment_popup_lottie_file_path:AnimationOptions = {path:'/assets/lottie/completed_animation.json'};

  success_payment_data:PopUpData = new PopUpData("Payment Successful","Installment Added Sucessfully"," ","Close");

  payment_arr: PaymentOptions[] = [new PaymentOptions('',[new PaymentType('','',formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),'',''),],7),];
  account_list: any = [];

  constructor(private toastr: ToastrService,
        private installmentService: InstallmentServiceService,
        private modalService:NgbModal){}
  
    

  ngOnInit(): void {
    this.getPaymentAccounts();
  }

  clearModal(){this.closePayment.emit(true);}

  getPaymentAccounts() {
    this.installmentService.getPayments().subscribe((data: any) => {
      for (let i of data.bank_accounts) {this.account_list.push(i);}
      for (let i of data.pos_accounts) {this.account_list.push(i);}    });
  }

  addPaymentRow() {
    var row = new PaymentOptions('',[new PaymentType('','',formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),'',''),],7);
    if (JSON.stringify(this.payment_arr[this.payment_arr.length - 1]) == JSON.stringify(row)) {
      this.toastr.info('', 'Please Fill the Required Fields', {positionClass: 'toast-bottom-right',});
      return;}
    this.payment_arr.push(row);
  }
  savePayment(){
    // this.payment_arr
    // this.plan_id
    // this.plan_detail
    

    this.installmentService.addInstallmentForDailyPay(
      new AddInstallmentForDailyPayRequest(this.plan_detail.code,this.plan_detail,this.payment_arr)
    ).subscribe(
      (data:any)=>{
        this.toastr.success(data.message, 'Success', {positionClass: 'toast-bottom-right',});
        this.modalService.open(this.success_payment_template,{centered:true,backdrop:'static'});
      }
    ),(error:any)=>{
      this.toastr.error(error.error.message, 'Error', {positionClass: 'toast-bottom-right',});
    }
  }
}
