import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ToastrService } from 'ngx-toastr';
import { NextInputDirective } from 'src/app/digital-coin/directives/next-input.directive';
import { OptionalInputDirective } from 'src/app/digital-coin/directives/optional-input.directive';
import { DigitalCoinService } from 'src/app/digital-coin/services/digital-coin.service';
import { PrimaryBtnComponent } from 'src/app/digital-coin/common/primary-btn/primary-btn.component';

export interface UserDetails {
  id: number
  first_name: string
  last_name?: string
  dob?: any
  email: string
  gender?: string
  address?: string
  state?: string
  pincode?: string
  mobile?: string
  phone?: string
  pan?: string
  referred_by?: string
}

@Component({
  selector: 'app-customer-details-dialog',
  standalone:true,
  imports: [MatIconModule, MatFormFieldModule, MatInputModule, PrimaryBtnComponent, ReactiveFormsModule, NextInputDirective, OptionalInputDirective],
  templateUrl: './customer-details-dialog.component.html',
  styleUrls: ['./customer-details-dialog.component.css']
})
export class CustomerDetailsDialogComponent {
  isMobileDisabled: boolean = true;
  form = new FormGroup({
    name: new FormControl("", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)],),
    mobile: new FormControl({ value: '', disabled: this.isMobileDisabled }, [Validators.required, Validators.pattern("[0-9]{10}")]),
    pan: new FormControl(""),
    gender:new FormControl(""),
    address: new FormControl("", [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    state: new FormControl("",[Validators.required]),
    referred_by: new FormControl(""),
    email:new FormControl("")

  })
  
  constructor(
    public dialogRef: MatDialogRef<CustomerDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { userDetails: UserDetails | undefined },
    private digiService: DigitalCoinService,
    private toastService: ToastrService,
    private destroyed: DestroyRef
  ) { }

  ngOnInit(): void {
    if (this.data.userDetails) {
      this.isMobileDisabled = true;
      this.form.patchValue({
        name: this.data.userDetails.first_name,
        mobile: this.data.userDetails.mobile,
        state: this.data.userDetails.state,
        address: this.data.userDetails.address,
        pan: this.data.userDetails.pan,
        gender: this.data.userDetails.gender,
        referred_by: this.data.userDetails.referred_by,
        email:this.data.userDetails.email
      })
    }


  }

  saveDetails(): void {
    if (this.form.valid) {
      if (this.data.userDetails?.id) {
        this.digiService.editUserDetails({
          id: this.data.userDetails.id,
          first_name: this.form.controls['name'].value!,
          dob: this.data.userDetails.dob,
          email: "",
          phone: this.data.userDetails.mobile,
          address: this.form.controls['address'].value!,
          state: this.form.controls['state'].value!,
          mobile: this.data.userDetails.phone,
          pan: this.form.controls['pan'].value!,
          gender: this.form.controls['gender'].value!,
          referred_by: this.form.controls['referred_by'].value!

        }).subscribe(
          {
            next: res => {
              console.log(res)
              if (res.status == 200) {
                this.dialogRef.close(res.body)
              } else this.toastService.error(res.body.error.message)
            },
            error: err => {
              this.toastService.error(err.error.message ?? "Error Editing Details")
            }
          })
      } else {
        this.digiService.createNewUser({
          id: NaN,
          first_name: this.form.controls['name'].value!,
          dob: "",
          email: "",
          mobile: this.data.userDetails?.mobile,
          address: this.form.controls['address'].value!,
          state: this.form.controls['state'].value!,
          pan: this.form.controls['pan'].value!,
          gender: this.form.controls['gender'].value!,
          referred_by: this.form.controls['referred_by'].value!

        }).subscribe(
          {
            next: res => {
              console.log(res)
              if (res.status == 201) {
                this.dialogRef.close(res.body)
              } else this.toastService.error(res.body.error.message)
            },
            error: err => {
              this.toastService.error(err.error.message ?? "Error Editing Details")
            }
          })
      }
    }
  }

  focus(id: string) {
    setTimeout(() => {
      const input = <HTMLInputElement>document.getElementById(id);
      input.focus();
    }, 100);
  }

}
