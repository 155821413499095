import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { EstimationApiResponse } from '../models/estimation-model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class EstimationService {
    private getEstimationNumberUrl = environment.ip + 'shop/estimations/getEstimationNo/';

    private createEstimationUrl = environment.uploaderIp + 'shop/estimations/createEstimation/';

    private getEstimationsUrl = environment.ip + 'shop/estimations/';

    private deleteEstimationUrl = environment.ip + 'shop/estimations/deleteEstimation/';

    constructor(private http: HttpClient) { }

    getEstimationNum(): Observable<any> {
        console.log('get estimation number');
        return this.http.get(this.getEstimationNumberUrl);
    }

    createNewEstimation(data: any): Observable<any> {
        console.log('create new estimation' + data);
        return this.http.post(this.createEstimationUrl, data);
    }

    getEstimationsList(): Observable<EstimationApiResponse> {
        console.log('get estimations list');
        return this.http.get<EstimationApiResponse>(this.getEstimationsUrl);
    }

    deleteEstimation(id: number): Observable<any> {
        console.log('delete estimation');
        var formData = new FormData();
        formData.append('id', id.toString());
        return this.http.post(this.deleteEstimationUrl, formData);
    }

    getEstimation(id: number): Observable<any> {
        console.log('view estimation');
        return this.http.get(this.getEstimationsUrl + id + '/');
    }
}
