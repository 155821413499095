import { Component, ElementRef, HostListener, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { RateCut, RateCutOptions } from 'src/app/models/ratecut';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RateCutService } from './rate-cut.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-ratecut',
  templateUrl: './ratecut.component.html',
  styleUrls: ['./ratecut.component.css'],
})
export class RatecutComponent {
  @ViewChildren('focus') focus: QueryList<ElementRef> | undefined;
  rate_cut!: RateCutOptions;
  existing_rate_setup!:RateCutOptions;
  rate_cut_array: any = [];
  temp=0;
  data_present=false;
  constructor(private modalService: NgbModal,
    private ratecutservice:RateCutService,
    private _location: Location,
    private toastr: ToastrService,
    private router: Router,
    private renderer: Renderer2,
    private titleService: TitleService,) {}

  addOneMoreRecord() {
    // var add= new RateCut('','','','')
    // this.rate_cut.options.push(add)
    // this.rate_cut_array.push('')

    this.temp+=1
    var weight_from=(Number(this.rate_cut.options[this.rate_cut.options.length-1].weight_to!) + 0.01).toFixed(2)
    var rc = new RateCut(0,Number(weight_from),null, '', '6',true);

    // this.rate_cut = new RateCutOptions(this.rate_cut_array);
    if (this.validatingData())
    {   this.rate_cut_array.push(rc);
      this.rate_cut.options.push(rc)
    }
    setTimeout(() => {
      if (this.focus && this.focus.length > 0) {
        const lastInputElement = this.focus.last.nativeElement;
        lastInputElement.focus();
      }
    }, 10);

  }

  removeLastRow()
  {
    console.log(this.rate_cut.options)
    if (this.rate_cut.options.length==1)
    {
      this.data_present=false
      var row=this.rate_cut.options.pop()
      if (row?.id!=0)
      {
        this.ratecutservice.removeRowRateCut(row?.id).subscribe((data:any)=>
        {
          console.log(data)
        })
      }
    }
    else{
      var row=this.rate_cut.options.pop()
      if (row?.id!=0)
      {
        this.ratecutservice.removeRowRateCut(row?.id).subscribe((data:any)=>
        {
          console.log(data)
        })
      }
    }


  }

  ngOnInit(): void {

    // localStorage.setItem('rate_reload_allowed','false')
    this.titleService.setTitle('Advance Bookings');
    this.getRate()
    if(this.data_present==true)
    {
      var rc = new RateCut(0,0, 0, '', '',true);
      this.rate_cut = new RateCutOptions([rc]);
    }
  }
  warningModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  validatingData()
  {

    for (const option of this.rate_cut.options) {
      if (option.weight_from==0 || option.weight_to==0 || option.advance_percentage=="" || option.redeem_duration=="")
      {
        this.toastr.info('', "Please Enter Valid Data", {
          positionClass: 'toast-bottom-right',
        });
        return false
      }
      if (Number(option.weight_from)==Number(option.weight_to))
      {
        this.toastr.info('', "From Weight To Weight Cannot be Same", {
          positionClass: 'toast-bottom-right',
        });
        return false
      }
      if (Number(option.weight_from!) < 0 || Number(option.weight_to!) < 0) {
        this.toastr.info('', "Negative values are not accepted", {
          positionClass: 'toast-bottom-right',
        });
        return false
      }
      if (Number(option.weight_from!) > Number(option.weight_to!)) {
        console.log(option.weight_from,option.weight_to)
        this.toastr.info('', "Weight from cannot be greater than Weight to", {
          positionClass: 'toast-bottom-right',
        });
        return false

      }



    }

    const payment_len = this.rate_cut.options.length;
    console.log(payment_len)
    for (let i = 0; i < payment_len-1; i++) {
      console.log(this.rate_cut.options[i].weight_to,this.rate_cut.options[i+1].weight_from)
        if (Number((Number(this.rate_cut.options[i + 1].weight_from!)-Number(this.rate_cut.options[i].weight_to!)).toFixed(2))  > Number(0.01)) {
          console.log(Number(this.rate_cut.options[i+1].weight_from!)-Number(this.rate_cut.options[i].weight_to))
          this.toastr.info('', "From and To weight sequence Mismatching", {
            positionClass: 'toast-bottom-right',
          });
          return false

        }

    }
    //if user is setup new rate cut first time
    if (this.rate_cut.options[0].id==0)
    {
      this.postRateCut()
      return false
    }
    return true

  }

  postRateCut()
  {

    this.ratecutservice.sendRateCut(this.rate_cut).subscribe((data:any)=>
    {
      this.toastr.success('', data.message, {
        positionClass: 'toast-bottom-right',
      });
      this.router.navigate(['/dashboard/view_bookings']);

    },(error:any)=>
    {
      this.toastr.info('', error.error.message, {
        positionClass: 'toast-bottom-right',
      });
    })
  }
  handleSetup()
  {
    this.data_present=true;
    var rc = new RateCut(0,0.01,null, '', '6',false);
    this.rate_cut = new RateCutOptions([rc]);
    setTimeout(() => {
      if (this.focus && this.focus.length > 0) {
        const lastInputElement = this.focus.last.nativeElement;
        lastInputElement.focus();
      }
    }, 10);
  }
 back() {
    this._location.back();
  }

  addZeroPointOne(index:any)
  {
    if (typeof(this.rate_cut.options[index+1])!=='undefined')
    {
      this.rate_cut.options[index+1].weight_from= Number(this.rate_cut.options[index].weight_to)+0.01
    }

  }
  getRate()
  {
    this.ratecutservice.getRateCut().subscribe((data:any)=>
    {
        var modified_data=data.setup
        for (let i of modified_data)
        {
          i['blocked']=true
        }
        console.log(modified_data)
        modified_data[0].blocked=false
        this.rate_cut=new RateCutOptions(modified_data)
      // this.existing_rate_setup=this.rate_cut.options
      this.existing_rate_setup=JSON.parse(JSON.stringify(this.rate_cut));
      this.data_present=true

    },
    (error:any)=>
    {
      this.toastr.info('', error.error.message, {
        positionClass: 'toast-bottom-right',
      });
    })



  }

  dataChanged()
  {
   if (JSON.stringify(this.existing_rate_setup)!==JSON.stringify(this.rate_cut))
   {
    return true
   }
   else
   {
    return false
   }
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if(this.rate_cut.options.length==0)
    {
      return
    }
    if (JSON.stringify(this.existing_rate_setup)!==JSON.stringify(this.rate_cut))
    {
      $event.returnValue = true;
    }

    else
    {
      return
    }

  }

  @HostListener('window:keydown.shift.enter', ['$event'])
  rowHandler() {
    this.addOneMoreRecord();
  }
  @HostListener('window:keydown.shift.delete', ['$event'])
  rowHandler2() {
    this.removeLastRow();
  }
}
