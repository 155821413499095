<div class="overall-container" style="height:90vh; width:95%; overflow-y:scroll;">
    <div class="container main-container" style="margin: 20px;">
        <div class="row">
            <h3 class="back_title" (click)="back()">
                <mat-icon class="right_back">arrow_back_ios</mat-icon>
                Estimation Details
            </h3>
        </div>
        <div *ngIf="estimation != null || estimation != undefined" class="row content-container">
            <div class="col-md-12">
                <mat-card class="blue-background" style="box-shadow: none;">
                    <mat-card-content style="margin: 00px;">
                        <p
                            style="margin: 0px; font-family: 'Rubik'; font-weight: 400; font-size: 12px; color: #DFDFDF;">
                            Estimation
                            No: <span class="highlight-white">{{estimation.estimation_no}}</span></p>
                        <mat-divider></mat-divider>
                    </mat-card-content>
                    <mat-card-content
                        style="align-items: center; justify-content: center; display: flex; flex-direction: column;">
                        <p
                            style="font-family: 'Rubik'; font-weight: 400; font-size: 12px; color: #DFDFDF; margin-top: 0px;">
                            Rate/gm:</p>
                        <p class="highlight-white"
                            style="margin: 0px; font-family: 'Rubik'; font-weight: 500; font-size: 16px;">
                            {{estimation.rate_per_gram}}</p>
                    </mat-card-content>
                    <div style="display: flex; justify-content: space-between;">
                        <mat-card-content *ngIf="estimation.purity != null" style="background-color: white; border-radius: 8px;">
                            <p
                                style="font-family: 'Rubik'; font-weight: 400; font-size: 12px; color: #606060; margin: 10px; border-radius: 8px;">
                                {{estimation.metal == 0 ? 'Gold' : estimation.metal == 2 ? 'Silver' : 'Platinum'}} {{
                                estimation.purity.name }}
                            </p>
                        </mat-card-content>
                        <mat-card-content *ngIf="estimation.product_link != null && estimation.product_link != ''"
                            style="background-color: white; border-radius: 8px;">
                            <p
                                style="font-family: 'Rubik'; font-weight: 400; font-size: 12px; color: #606060; margin: 10px; border-radius: 8px;">
                                {{estimation.product_link != null || estimation.product_link != '' ?
                                estimation.product_link : ''}}
                            </p>
                        </mat-card-content>
                        <mat-card-content *ngIf="estimation.asset_file != null"
                            style="background-color: white; border-radius: 8px;">
                            <img class="selected-images-grid" [src]="estimation.asset_file.icon"
                                style="height: 50px; width: 50px;" alt="images" />
                        </mat-card-content>
                    </div>
                </mat-card>
                <mat-card class="white-background" style="box-shadow: none; padding: 0px; margin-top: 20px;">
                    <table>
                        <thead>
                            <tr>
                                <th>Details</th>
                                <th>Values</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Gross Weight (Gms)</td>
                                <td>{{estimation.gross_weight}}</td>
                            </tr>
                            <tr>
                                <td>Net Weight (Gms)</td>
                                <td>{{estimation.net_weight}}</td>
                            </tr>
                            <tr *ngIf="estimation.wastage != null || estimation.wastage != ''">
                                <td>Wastage ( {{ wastageType(estimation.wastage_type) }} )</td>
                                <td>{{estimation.wastage}}</td>
                            </tr>
                            <tr *ngIf="estimation.making_charge != null || estimation.making_charge != ''">
                                <td>Making Charge ( {{ makingChargeType(estimation) }} )</td>
                                <td>{{ '₹' + getMakingCharge(estimation)}}</td>
                            </tr>
                            <tr *ngIf="estimation.stone_estimation != null">
                                <td>Stone Cost (₹)</td>
                                <td>{{ calculateStoneCharge(estimation) }}</td>
                            </tr>
                            <tr>
                                <td>GST (%)</td>
                                <td>{{estimation.gst}}</td>
                            </tr>
                            <tr>
                                <td>Total (₹)</td>
                                <td>{{estimation.total_amount}}</td>
                            </tr>
                        </tbody>
                    </table>

                </mat-card>
            </div>
        </div>
    </div>
</div>