import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { GoogleAnalyticsService } from '@hakimio/ngx-google-analytics';
import { getAnalytics } from "firebase/analytics";




@Injectable({
    providedIn: 'root'
  })
export class AnalyticService{
  private readonly gaService = inject(GoogleAnalyticsService);
    category_name="";
    constructor(private analytics:AngularFireAnalytics){}

    setUserId(id:string)
    {
      if(environment.is_analytics_enabled)
      {
        gtag('set', {'user_id': id});
      }
    }

    logEvent(event_name:string,event_params={}){
      if(environment.is_analytics_enabled)
      console.log("runnings")
        gtag('event',event_name,event_params)
      }
    logPageView(page_path:string){
      console.log("senidng")
        if(environment.is_analytics_enabled)
        {
          gtag('event', 'page_view', 
            {page_title: page_path,
            page_path:page_path});
        }
         
    }



    loadGoogleAnalytics(): void {
      // here i am calling the analytics script explicity
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-EB5F6PW9NE';
      document.head.appendChild(script);

      script.onload = () => {
        this.initializeGoogleAnalytics();
      };
    }

    private initializeGoogleAnalytics(): void {
      const windowWithAnalytics = window as any;
      windowWithAnalytics.dataLayer = windowWithAnalytics.dataLayer || [];
      
      // Adjust the gtag function to accept any number of arguments
      function gtag(...args: any[]) {
        windowWithAnalytics.dataLayer.push(args);
      }

      gtag('js', new Date());
      gtag('config', 'G-EB5F6PW9NE');
    }

    }