import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
   
    if (value=="EnableOrDisablePOSAccounts")
    {
      return "Enable OR Disable POS Accounts"
    }
    if (value=="EnableOrDisableBankAccounts")
    {
      return "Enable OR Disable Bank Accounts"
    }

    if (value=="DeleteBankOrPOSAccounts")
    {
      return "Delete Bank OR POS Accounts"
    }
    if(value=="EnterPlanID")
    {
      return "Enter Plan ID"
    }
    return value.replace(/([A-Z])/g, ' $1');
  }
}

