import { Component } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent {

  constructor(private location:Location,)
  {}
  back(){
    this.location.back();
  }
}
