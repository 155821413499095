import { Component } from '@angular/core';

@Component({
  selector: 'app-file-chunks-upload',
  templateUrl: './file-chunks-upload.component.html',
  styleUrls: ['./file-chunks-upload.component.css']
})
export class FileChunksUploadComponent {

}
