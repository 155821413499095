import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ActionService {
  private add = false;
  private actions=localStorage.getItem("actions");

  private pages=localStorage.getItem("pages");
  getActions() {
    if (this.actions=="admin" && this.actions=="admin")
    {
      return "admin"
    }
    var allowed_actions=this.actions?.split(",").map(Number)
    return allowed_actions;
  }
}
