export class PaymentManager {
  CASH = 'Cash';
  CASH_MODE = 7;
  CARD = 'Card';
  CARD_MODE = 1;
  UPI = 'UPI/MPS';
  UPI_MODE = 8;

  RTGS = 'RTGS/NEFT';
  NET_BANKING_MODE = 3;
  CHEQUE = 'Cheque';
  CHEQUE_MODE = 6;

  // BANK = 0;
  // POS = 1;
  payment_types = [
    { type: this.CASH, mode: this.CASH_MODE },
    { type: this.CARD, mode: this.CARD_MODE },
    { type: this.UPI, mode: this.UPI_MODE },
    {
      type: this.RTGS,
      mode: this.NET_BANKING_MODE,
    },
    { type: this.CHEQUE, mode: this.CHEQUE_MODE },
  ];
}
