<div class="content">
    <div *ngIf="show_add_installment else show_payment">
    <div class="mt-3">
        <div class="add_installment ms-3">
            Add Installment
        </div>
        <div>
            <div class="p-2 m-2" class="enter_plan">
                <div>
                    <div class="pb-1">
                        Enter Plan
                    </div>
                    <div>
                        <input 
                        [disabled]="!allowed_actions['EnterPlanID']"
                        [(ngModel)]="plan_id"
                        class="form-control"
                        placeholder="Enter Plan ID"
                        />
                    </div>
                </div>
                <!-- render it when plan data is fetched -->
                <div *ngIf="is_valid_plan">
                    <div >
                        <div class="mt-3">
                            <div class="pb-1">
                                Installment Date    
                            </div>
                            <div>
                                <input
                                [disabled]="!allowed_actions['ChangeInstallmentDate']"
                                [(ngModel)]="plan_detail.installment_date" 
                                [max]="current_date_validation"
                                type="date"
                                class="form-control"
                                placeholder="Enter Plan ID"
                                />
                            </div>
                        </div>
                        <div class="mt-3">
                            <div class="pb-1">
                                Rate/gm
                            </div>
                            <div>
                                <input
                                [disabled]="true"
                                [(ngModel)]="plan_detail.rate"
                                class="form-control"
                                placeholder="Enter Rate/gm"
                                />
                            </div>
                        </div>
                    </div>
                </div>
              
                
              
            </div>
        </div>
        <!-- plan details -->
        <div *ngIf="is_valid_plan">
            <div style="background-color:#F2F5FF;margin:15px;border-radius: 7px;">
                <div class="p-2">
                    <div class="font">Customer Name</div>
                    <div class="font3">
                        <span><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 7C8.93375 7 10.5 5.43375 10.5 3.5C10.5 1.56625 8.93375 0 7 0C5.06625 0 3.5 1.56625 3.5 3.5C3.5 5.43375 5.06625 7 7 7ZM7 8.75C4.66375 8.75 0 9.9225 0 12.25V13.125C0 13.6062 0.39375 14 0.875 14H13.125C13.6062 14 14 13.6062 14 13.125V12.25C14 9.9225 9.33625 8.75 7 8.75Z" fill="#4070F4"/>
                            </svg>
                            </span>
                        <span class="ms-2">{{plan_detail.name}}</span>
                    </div>
                </div>
                <div>
                    <div class="plan_detail_flex">
                        <div>
                            <div class="font">Phone</div>
                            <div class="font2">{{plan_detail.phone}}</div>
                        </div>
                        <div>
                            <div class="font">Installment Amount</div>
                            <div class="font2"><span>₹</span>{{plan_detail.amount}}</div>
                        </div>
                    </div>
                    <div class="plan_detail_flex">
                        <div>
                            <div class="font">Start Date</div>
                            <div class="font2"> {{plan_detail.s_date}}</div>
                        </div>
                        <div>
                            <div class="font">Installment Number</div>
                            <div class="font2">{{plan_detail.installment}}</div>
                        </div>
                    </div>
                    <div class="plan_detail_flex">
                        <div>
                            <div class="font">Due Installment</div>
                            <div class="font2">{{plan_detail.pending_installment}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </div>
    <div class="verify_block">
        <button *ngIf="!is_valid_plan"  class="text verify"
        (click)="getPlanUsingCode(plan_id)"
        >
        Verify
        </button>
        <button *ngIf="is_valid_plan"  class="text verify"
            (click)="togglePayment()"
        >
            Proceed to Payment
        </button>
    </div>
   </div>
   <ng-template #show_payment>
    <div>
        <div (click)="togglePayment()" class="payment_header ms-1">
            <svg  width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 13.8354L1 7.83545L7 1.83545" stroke="#171717" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="ps-3 payment_heading">Payment</span>
        </div>        
        <add-installment-payment
        (closePayment)="afterSuccess($event)" 
        [plan_detail]="plan_detail">
        </add-installment-payment>
    </div>
</ng-template>
        
</div>
<ng-template #already_paid>
    <app-warning-popup 
    (proceedButtonClickEvent)="proceedAfterWarning($event)"
    [warning_data]="warning_data">
</app-warning-popup>
</ng-template>

<ng-template #cancelled>
    <app-lottie-popup
    (proceedButtonClickEvent)="proceedAfterWarning(false)"
    [pop_up_data]="cancelled_plan_data"
    [lottie_file_path]="cancelled_plan_lottie_file_path"
    ></app-lottie-popup>
</ng-template>

<ng-template #completed>
    <app-lottie-popup
    (proceedButtonClickEvent)="proceedAfterWarning(false)"
    [pop_up_data]="completed_plan_data"
    [lottie_file_path]="completed_plan_lottie_file_path"
    ></app-lottie-popup>
</ng-template>

<ng-template #paused>
    <app-lottie-popup
    (proceedButtonClickEvent)="proceedAfterWarning(false)"
    [pop_up_data]="paused_plan_data"
    [lottie_file_path]="paused_plan_lottie_file_path"
    ></app-lottie-popup>
</ng-template>