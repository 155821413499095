<div class="order_list_mobile_container" #page >
    <div>
        <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5006 13.9999H14.7106L14.4306 13.7299C15.6306 12.3299 16.2506 10.4199 15.9106 8.38989C15.4406 5.60989 13.1206 3.38989 10.3206 3.04989C6.09063 2.52989 2.53063 6.08989 3.05063 10.3199C3.39063 13.1199 5.61063 15.4399 8.39063 15.9099C10.4206 16.2499 12.3306 15.6299 13.7306 14.4299L14.0006 14.7099V15.4999L18.2506 19.7499C18.6606 20.1599 19.3306 20.1599 19.7406 19.7499C20.1506 19.3399 20.1506 18.6699 19.7406 18.2599L15.5006 13.9999ZM9.50063 13.9999C7.01063 13.9999 5.00063 11.9899 5.00063 9.49989C5.00063 7.00989 7.01063 4.99989 9.50063 4.99989C11.9906 4.99989 14.0006 7.00989 14.0006 9.49989C14.0006 11.9899 11.9906 13.9999 9.50063 13.9999Z" fill="#9F9F9F"/>
                    </svg>
              </span>
            </div>
            <input  [(ngModel)]="searchOrder" type="text" class="form-control custom_input" placeholder="Search" aria-label="Search" aria-describedby="basic-addon1">
            <mat-icon
            *ngIf="searchOrder"
            (click)="clearSeachInput(); getOrders()"
            class="crossIcon cursor"
            [ngStyle]="{ color: '#9A9AB0' }"
            id="23"
            >cancel</mat-icon
          >
          <button
          id="23"
          (click)="getOrders()"
          class="cursor search_b"
        >
          Search
        </button>
          </div>
    </div>
    <div class="order_filter mt-3">
        <div>
          <!-- <input class="" (click)="addDesignModal(Filter)" style="background-color:white"> -->
            <!-- <select class="order_filter_status" (click)="addDesignModal(Filter)" style="background-color:white">
                <option>Unassigned</option>
                <option>Assigned</option>
                <option>Delivered</option>
            </select> -->
            <button type="button" (click)="openDesignModal(Filter)">
              Filter <span *ngIf="!!filter_values.length || !!vendors_values.length" class="badge ms-2">{{filter_values.length+vendors_values.length}}</span>
            </button>
        </div>
        <div class="centr ms-3 grey_text">
            <div>({{order_count}} Orders)</div>
        </div>
    </div>

    <!-- order card list -->
    <div class="order_list" *ngIf="orders">
        <div  class="order_card mt-3 p-3" *ngFor="let i of orders;let j=index;"
        >
          
          <!-- 1st row -->
            <div class="space_between">
                <div (click)="switchToOrderDetailPage(i.id)" class="grey_text">Order <span class="blue_text ms-1">{{i.code}}</span></div>
                <div class="grey_text">Esitimated Delivery</div>
            </div>
            <!-- 1st row end -->
            <!-- 2nd row stared -->
            <div class="space_between mt-1">
                <div class="grey_text">{{i.items.length}} items</div>
                <div class="dark_grey" >{{i.estimated_delivery}}
                  <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M14 8V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V8H14ZM10.6667 2C10.8435 2 11.013 2.07024 11.1381 2.19526C11.2631 2.32029 11.3333 2.48986 11.3333 2.66667V3.33333H12.6667C13.0203 3.33333 13.3594 3.47381 13.6095 3.72386C13.8595 3.97391 14 4.31304 14 4.66667V6.66667H2V4.66667C2 4.31304 2.14048 3.97391 2.39052 3.72386C2.64057 3.47381 2.97971 3.33333 3.33333 3.33333H4.66667V2.66667C4.66667 2.48986 4.7369 2.32029 4.86193 2.19526C4.98695 2.07024 5.15652 2 5.33333 2C5.51014 2 5.67971 2.07024 5.80474 2.19526C5.92976 2.32029 6 2.48986 6 2.66667V3.33333H10V2.66667C10 2.48986 10.0702 2.32029 10.1953 2.19526C10.3203 2.07024 10.4899 2 10.6667 2Z" fill="#606060"/></svg></span></div>
            </div>
            <!-- 2nd row end -->
            <hr>
            <!-- 3rd row stared -->

            <!-- loop -->
            <div *ngFor="let o of i.items;let loop_index=index">
              <hr *ngIf="loop_index>0">
              <div class="space_between" >
                <div>
                    <div class="order_desc">{{loop_index+1}}.{{o.description}}</div>
                    <div class="dark_grey_400">{{o.weight}}g</div>
                </div>
                <div><select
                  class="order_filter_status"
                  [style.background]="dropdownOptions[o.status].background"
                  [style.color]="dropdownOptions[o.status].color"
                  value="{{o.status}}"
                  (change)="handleStatus($event.target, o)"
                  >
                  <option value="" disabled selected>Choose</option>
                  <option
      
                    *ngFor="let option of dropdownOptions"
                    [style.color]="option.color"
                    [selected]="o.status === option.value"
                    value="{{ option.value }}"
                    [disabled]="option.value==4 || option.value==1 || o.status=='3'"
                  >
                    {{ option.label }}
                  </option>
                </select></div>
            </div>
              <div class="space_between mt-1">
                <div class="blue_text_400" (click)="addDesignModal(addDesign1,o)">
                    <div *ngIf="o.images.length==0">
                      <span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M6.12565 11.6666C6.12565 11.8986 6.21784 12.1212 6.38193 12.2853C6.54603 12.4494 6.76859 12.5416 7.00065 12.5416C7.23272 12.5416 7.45528 12.4494 7.61937 12.2853C7.78346 12.1212 7.87565 11.8986 7.87565 11.6666V7.87492H11.6673C11.8994 7.87492 12.1219 7.78273 12.286 7.61864C12.4501 7.45454 12.5423 7.23198 12.5423 6.99992C12.5423 6.76785 12.4501 6.54529 12.286 6.3812C12.1219 6.21711 11.8994 6.12492 11.6673 6.12492H7.87565V2.33325C7.87565 2.10119 7.78346 1.87863 7.61937 1.71453C7.45528 1.55044 7.23272 1.45825 7.00065 1.45825C6.76859 1.45825 6.54603 1.55044 6.38193 1.71453C6.21784 1.87863 6.12565 2.10119 6.12565 2.33325V6.12492H2.33398C2.10192 6.12492 1.87936 6.21711 1.71527 6.3812C1.55117 6.54529 1.45898 6.76785 1.45898 6.99992C1.45898 7.23198 1.55117 7.45454 1.71527 7.61864C1.87936 7.78273 2.10192 7.87492 2.33398 7.87492H6.12565V11.6666Z" fill="#3268FB"/></svg></span>Add Design Details</div>
                    <div *ngIf="o.images.length!=0">View Design Details</div>
                  </div>
                <div *ngIf="!o.assigned_to_vendor" (click)="addVendorModal(addVendor,o.id);searchVendor('')"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z" fill="#3268FB"/>
                    <circle cx="18" cy="17" r="5.5" fill="#3268FB" stroke="white"/>
                    <rect x="14" y="13" width="8" height="8" fill="url(#pattern0)"/>
                    <defs>
                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlink:href="#image0_5372_27222" transform="scale(0.0104167)"/>
                    </pattern>
                    <image id="image0_5372_27222" width="96" height="96" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAvJJREFUeF7tm71KJFEQRqvp4DYDq2K8Gxtu6hO4sSi+iy/hPsj+vMBubGy6bLqxoEIzHQwtLRMNTi+n+aC4+hlK1Vdzz/EyCLea8E8qgSZ1uoeHBST/EViABSQTSB7vG2AByQSSx/sGWEAygeTxvgEWkEwgebxvgAVwAn3ff2rb9mtEnG27f4/jeN113R+elttR3Q3Ywr+LiOMddPebzebzarX6l4uUTa9OwDAM3yPiYs8xv5VSrhiC3OoaBTxGxIc92B5LKYe5SNn0GgWMc0cspVR1pqo+7AR+GAYLYJdMW20BWp44zQIwMm2DBWh54jQLwMi0DRag5YnTLAAj0zZYgJYnTrMAjEzbYAFanjjNAjAybYMFaHniNAvAyLQNFqDlidMsACPTNliAlidOswCMTNtgAVqeOM0CMDJtgwVoeeI0C8DItA0WoOWJ0ywAI9M2WICWJ06zAIxM22ABWp44zQIiYud9/r6HshhupQ1PEbF4PwG/DZ15n18pP9nHXrSfgAX8532+7DSVBuH9hCUC5t7nV8pN9rHxfoIFyNi/BD2UUo5I5BIBPyPinAx5R7U/SimX5LxYwHq9Pmma5vaVJTky9y3W3o/jeNp13V9yOCxgCu/7/mPbtjcR8SUiDsjAN1g7fSf+2q7JIvgTi0UCMiH6H7FM+t4RS6ZvARagJuDvADVRmGcBEJi63ALURGGeBUBg6nILUBOFeRYAganLLUBNFOZZAASmLrcANVGYZwEQmLrcAtREYZ4FQGDqcgtQE4V5FgCBqcstQE0U5lkABKYutwA1UZhnARCYutwC1ERhngVAYOpyC1AThXkWAIGpyy1ATRTmWQAEpi6vUcDcggh+n68GSvNqFDC3n4Df51Ng6vrqBMzsJyx6n68GSvOqEzAdcGc/YfrV4vf5FJi6vkoBagiZeRaQSb/GDZlkXvLxvgFypCzQAhgvebUFyJGyQAtgvOTVFiBHygItgPGSV1uAHCkLtADGS15tAXKkLPAZTWlScG8YjgwAAAAASUVORK5CYII="/>
                    </defs>
                    </svg>
                </div>
                <div *ngIf="o.assigned_to_vendor" class="assinged_vendors_name" (click)="addVendorModal(addVendor,o.id);searchVendor('')">
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 7C8.93375 7 10.5 5.43375 10.5 3.5C10.5 1.56625 8.93375 0 7 0C5.06625 0 3.5 1.56625 3.5 3.5C3.5 5.43375 5.06625 7 7 7ZM7 8.75C4.66375 8.75 0 9.9225 0 12.25V13.125C0 13.6062 0.39375 14 0.875 14H13.125C13.6062 14 14 13.6062 14 13.125V12.25C14 9.9225 9.33625 8.75 7 8.75Z" fill="#DFDFDF"/>
                    </svg>
                    <span class="ps-2">{{o.assigned_to_vendor}}
                    <span class="ps-2">
                      <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.9082 1.5L5.95366 6.5L0.999112 1.5" stroke="#606060" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    </span>
                  
                </div>
            </div>
            </div>

            <!-- 3rd row end -->
            <!-- 4th row start -->

        </div>
        <div class="show_more_div" *ngIf="orders">
          <div class="blue_text_400" (click)="loadNextOrders()">
            Show More
          </div>
        </div>
        <div *ngIf="showBackToTopButton">
          <div class="back_to_top" (click)="backToTop()">
            <div>
              <mat-icon class="arrow-up">arrow_upward</mat-icon>
            </div>
            <div>
              BACK TO TOP
            </div>
          </div>
          
        </div>
    </div>
   
    <!-- <form id="app-cover">
  <div id="select-box">
    <input type="checkbox" id="options-view-button">
    <div id="select-button" class="brd">
      <div id="selected-value">
        <span>Select a platform</span>
      </div>
      <div id="chevrons">
        <i class="fas fa-chevron-up"></i>
        <i class="fas fa-chevron-down"></i>
      </div>
    </div>
    <div id="options">
      <div class="option">
        <input class="s-c top" type="radio" name="platform" value="codepen">
        <input class="s-c bottom" type="radio" name="platform" value="codepen">
        <span class="label">Unassigned</span>
        <span class="opt-val">CodePen</span>
      </div>
      <div class="option">
        <input class="s-c top" type="radio" name="platform" value="dribbble">
        <input class="s-c bottom" type="radio" name="platform" value="dribbble">
        <span class="label">Assigned</span>
        <span class="opt-val">Dribbble</span>
      </div>
      <div class="option">
        <input class="s-c top" type="radio" name="platform" value="behance">
        <input class="s-c bottom" type="radio" name="platform" value="behance">
        <span class="label">Ready</span>
        <span class="opt-val">Behance</span>
      </div>
      <div class="option">
        <input class="s-c top" type="radio" name="platform" value="hackerrank">
        <input class="s-c bottom" type="radio" name="platform" value="hackerrank">
        <span class="label">Delivered</span>
        <span class="opt-val">HackerRank</span>
      </div>
      <div class="option">
        <input class="s-c top" type="radio" name="platform" value="stackoverflow">
        <input class="s-c bottom" type="radio" name="platform" value="stackoverflow">
        <span class="label">Cancelled</span>
        <span class="opt-val">StackOverflow</span>
      </div>
      <div id="option-bg"></div>
    </div>
  </div>
</form> -->
</div>


<ng-template #addDesign let-modal>
 <div class="design_mod">
  <!-- Design Name and back button -->
  <div class="order_desc_16px pt-3 ps-2">
    <span (click)="modal.close()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">      <path d="M15 18L9 12L15 6" stroke="#171717" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></span> Design Details
  </div>
  <!-- Upload Section -->
  <div class="p-4 mx-1 mt-2">
    <ngx-dropzone class="drop-area" (change)="addingImage($event)" >
      <div class="row upload_block p-4" id="upload_block">
        <div class=""*ngIf="files.length == 0" >
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path d="M25 0H5C2.23858 0 0 2.23858 0 5V25C0 27.7614 2.23858 30 5.00001 30H22.8571L30 22.8571V5C30 2.23858 27.7614 0 25 0Z" fill="#3268FB"/>
            <path d="M15.4502 9.99902V20.8965" stroke="white" stroke-width="2.1795" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 15.4483H20.8975" stroke="white" stroke-width="2.1795" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8555 30V24.9533C22.8555 23.7958 23.8116 22.8571 24.9906 22.8571H29.9983" fill="#D9D9D9"/>
            </svg>
        </div>
        <div class="col-12 mt-3"  *ngIf="files.length == 0">
          <ngx-dropzone-label>
            <div class="order_desc">Upload Image</div>
          </ngx-dropzone-label>
          <div class="dark_grey_400 mt-2">
            <span class="dark_grey">5 mb</span> maximum file size<br/>
             You can upload upto <span class="dark_grey"> 5 images</span>
          </div>
        </div>
        <div class="img_div">
          <ngx-dropzone-image-preview
            ngProjectAs="ngx-dropzone-preview"
            *ngFor="let f of files"
            [file]="f"
            (removed)="removeLocalFile(f)"
            [removable]="true"
          >
          </ngx-dropzone-image-preview>
        </div>
        <div class="">
          <mat-progress-bar
            mode="determinate"
            value="40"
            style="width: 100%; visibility: hidden"
          ></mat-progress-bar>
        </div>
      </div>
    </ngx-dropzone>
    <div class="display_height" *ngIf="this.uploaded_designs.length != 0">
            <div class="row mt-2" *ngFor="let design of uploaded_designs">
              <div
              class="remove_icon"
              style="padding: 0"
              (click)="removeDesign(design.id)"
            >
              <div style="display: flex; justify-content: right">
                <mat-icon class="pointer_class mt-3" style="font-size: 21px"
                  >cancel</mat-icon
                >
              </div>
            </div>
              <div class="col-md-1" [style.width.%]="2">
                <!-- <img class="img-thumbnail" src="{{ design.image }}" /> -->
              </div>
              <div class="col-md-2">
                <img class="img-thumbnail" src="{{ design.image }}" />
              </div>
              <div class="col-md-3">
                <div class="name_color mt-1">{{ design.name }}</div>
                <div class="size_color">{{ design.size | byteToMb }}</div>
              </div>
              <div class="col-md-2"></div>
              <div
                class="col-md-3 text-right"
                style="display: flex; justify-content: right"
              >
                <p class="mt-3 text-right upload_color">Uploaded</p>
              </div>
            
              <hr class="mt-2 mb-1 px-2" style="background: #dfdfdf" />
            </div>
          </div>
  </div>
  <!-- Form -->
  <div class="design_details px-4 mx-1">
    <!-- first row -->
    <div><div class="dark_grey">Order No.</div>
    <div><input class="form-control order_desc" id="design_order_id"></div></div>
    <!-- second row -->
    <div class="weight_purity mt-3">
      <div>
        <div class="dark_grey">Weight</div>
        <div><input class="form-control order_desc" id="design_weight"></div>
      </div>
      <div>
        <div class="dark_grey">Metal Purity</div>
        <div><input class="form-control order_desc" id="purity"></div>
      </div>
    </div>
    <!-- third Row -->
    <div class="mt-3">
      <div class="dark_grey">Size</div>
      <div><input class="form-control order_desc" id="size"></div>
    </div>
    <div class="mt-3">
      <div class="dark_grey">Pieces</div>
      <div><input class="form-control order_desc" id="pieces"></div>
    </div>
    <!-- fourth row -->
    <div class="mt-3">
      <div class="dark_grey">Order Notes (optional)</div>
      <div><textarea id="notes" class="form-control order_desc long_text"></textarea></div>
    </div>
  </div>
 </div>
 <!-- bottom save button -->
 <div class="bottom_button">
  <div>
    <button class="btm_button mx-3" (click)="postDesign();modal.close()">Save</button>
  </div>
 </div>
</ng-template>

<ng-template #addDesign1 let-modal>
<div class="design_mod">
  <!-- Design Name and back button -->
  <div class="order_desc_16px pt-3 ps-2">
    <span (click)="modal.close()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">      <path d="M15 18L9 12L15 6" stroke="#171717" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></span> Design Details
  </div>
  <!-- Upload Section -->
  <!-- <div class="p-4 mx-1 mt-2">
    <ngx-dropzone class="drop-area" (change)="addingImage($event)" >
      <div class="row upload_block p-4" id="upload_block">
        <div class=""*ngIf="files.length == 0" >
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path d="M25 0H5C2.23858 0 0 2.23858 0 5V25C0 27.7614 2.23858 30 5.00001 30H22.8571L30 22.8571V5C30 2.23858 27.7614 0 25 0Z" fill="#3268FB"/>
            <path d="M15.4502 9.99902V20.8965" stroke="white" stroke-width="2.1795" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 15.4483H20.8975" stroke="white" stroke-width="2.1795" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8555 30V24.9533C22.8555 23.7958 23.8116 22.8571 24.9906 22.8571H29.9983" fill="#D9D9D9"/>
            </svg>
        </div>
        <div class="col-12 mt-3"  *ngIf="files.length == 0">
          <ngx-dropzone-label>
            <div class="order_desc">Upload Image</div>
          </ngx-dropzone-label>
          <div class="dark_grey_400 mt-2">
            <span class="dark_grey">5 mb</span> maximum file size<br/>
             You can upload upto <span class="dark_grey"> 5 images</span>
          </div>
        </div>
        <div class="img_div">
          <ngx-dropzone-image-preview
            ngProjectAs="ngx-dropzone-preview"
            *ngFor="let f of files"
            [file]="f"
            (removed)="removeLocalFile(f)"
            [removable]="true"
          >
          </ngx-dropzone-image-preview>
        </div>
        <div class="">
          <mat-progress-bar
            mode="determinate"
            value="40"
            style="width: 100%; visibility: hidden"
          ></mat-progress-bar>
        </div>
      </div>
    </ngx-dropzone>
    <div class="display_height" *ngIf="this.uploaded_designs.length != 0">
            <div class="row mt-2" *ngFor="let design of uploaded_designs">
              <div
              class="remove_icon"
              style="padding: 0"
              (click)="removeDesign(design.id)"
            >
              <div style="display: flex; justify-content: right">
                <mat-icon class="pointer_class mt-3" style="font-size: 21px"
                  >cancel</mat-icon
                >
              </div>
            </div>
              <div class="col-md-1" [style.width.%]="2">
              </div>
              <div class="col-md-2">
                <img class="img-thumbnail" src="{{ design.image }}" />
              </div>
              <div class="col-md-3">
                <div class="name_color mt-1">{{ design.name }}</div>
                <div class="size_color">{{ design.size | byteToMb }}</div>
              </div>
              <div class="col-md-2"></div>
              <div
                class="col-md-3 text-right"
                style="display: flex; justify-content: right"
              >
                <p class="mt-3 text-right upload_color">Uploaded</p>
              </div>
            
              <hr class="mt-2 mb-1 px-2" style="background: #dfdfdf" />
            </div>
          </div>
  </div> -->
  <div  class="jewellery-images">
    <div class="head-image"> 
      <ngx-dropzone *ngIf="!selected_design" (change)="onSelect2($event,1)" class="ngx_dropdown_custom">
        <div  class="no_design_found">
          <div class="upload_i">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25 0H5C2.23858 0 0 2.23858 0 5V25C0 27.7614 2.23858 30 5.00001 30H22.8571L30 22.8571V5C30 2.23858 27.7614 0 25 0Z" fill="#3268FB"/>
              <path d="M15.4502 9.99902V20.8965" stroke="white" stroke-width="2.1795" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 15.4483H20.8975" stroke="white" stroke-width="2.1795" stroke-linecap="round" stroke-linejoin="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8555 30V24.9533C22.8555 23.7958 23.8116 22.8571 24.9906 22.8571H29.9983" fill="#D9D9D9"/>
              </svg>
          </div>
          
            
            
          <div class="dark_grey_400 mt-2">
            <div class="upload_text_1 mt-3">
              Upload Image
             </div>
           <div class="mt-2" style="text-align: center;">
            <span class="dark_grey">5 mb</span> maximum file size
           </div>
             You can upload upto <span class="dark_grey"> 5 images</span>
          </div>
         
        </div>
      </ngx-dropzone>
     
     
      <img *ngIf="selected_design" class="selected-images-grid" [src]="selected_design?.image"
      alt="images" />
  <div class="delete-icon" *ngIf="selected_design" (click)="removeDesign(selected_design.id);">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="13.4583" fill="white" stroke="#606060" stroke-width="1.08333"/>
      <path d="M8.6664 10V19.7778C8.66226 19.9488 8.69186 20.119 8.75352 20.2785C8.81517 20.4381 8.90765 20.584 9.0257 20.7078C9.14374 20.8316 9.28502 20.931 9.44148 21.0002C9.59793 21.0694 9.76648 21.1071 9.93751 21.1111H18.0397C18.2126 21.11 18.3836 21.0744 18.5426 21.0065C18.7016 20.9386 18.8455 20.8397 18.9659 20.7156C19.0863 20.5915 19.1808 20.4446 19.2438 20.2836C19.3069 20.1226 19.3372 19.9506 19.3331 19.7778V10H8.6664ZM12.6664 18.8889H11.7775V12.2222H12.6664V18.8889ZM16.222 18.8889H15.3331V12.2222H16.222V18.8889Z" fill="#606060"/>
      <path d="M19.6573 8.22222H16.2218V7.77777C16.2218 7.54203 16.1281 7.31593 15.9614 7.14924C15.7947 6.98254 15.5686 6.88889 15.3329 6.88889H12.5773C12.3573 6.911 12.1534 7.01432 12.0055 7.17868C11.8576 7.34305 11.7762 7.55665 11.7773 7.77777V8.22222H8.22179C8.10391 8.22222 7.99087 8.26904 7.90752 8.35239C7.82417 8.43574 7.77734 8.54879 7.77734 8.66666C7.77734 8.78454 7.82417 8.89758 7.90752 8.98093C7.99087 9.06428 8.10391 9.11111 8.22179 9.11111H19.6573C19.7752 9.11111 19.8883 9.06428 19.9716 8.98093C20.055 8.89758 20.1018 8.78454 20.1018 8.66666C20.1018 8.54879 20.055 8.43574 19.9716 8.35239C19.8883 8.26904 19.7752 8.22222 19.6573 8.22222Z" fill="#606060"/>
      </svg>
      
      
                 
  </div>
    </div>
   
    <div class="more-images horizontal-list" *ngIf="selected_design">
        <div *ngFor="let design of uploaded_designs">
            <img  
                class="sub-images" [src]="design.image" 
                (click)="selectImage(design)"/>
        </div>
        <div  class="add-image ms-2" (click)="inputCustom.click()">
            <!-- <mat-icon class="add-image-icon"></mat-icon> -->
            <span class="add-image-text">+ Add</span>
            <input #inputCustom type="file" (change)="onSelect2($event,0)" style="display: none;">
            
              
        </div>
    </div>
</div>
  
  <!-- Form -->
  
  <div class="design_details px-4 mx-1">
    <!-- first row -->
    <div><div class="dark_grey">Order No.</div>
    <div><input class="form-control order_desc" id="design_order_id"></div></div>
    <!-- second row -->
    <div class="weight_purity mt-3">
      <div>
        <div class="dark_grey">Weight</div>
        <div><input class="form-control order_desc" id="design_weight"></div>
      </div>
      <div>
        <div class="dark_grey">Metal Purity</div>
        <div><input class="form-control order_desc" id="purity"></div>
      </div>
    </div>
    <!-- third Row -->
    <div class="mt-3">
      <div class="dark_grey">Size</div>
      <div><input class="form-control order_desc" id="size"></div>
    </div>
    <div class="mt-3">
      <div class="dark_grey">Pieces</div>
      <div><input class="form-control order_desc" id="pieces"></div>
    </div>
    <!-- fourth row -->
    <div class="mt-3">
      <div class="dark_grey">Order Notes (optional)</div>
      <div><textarea id="notes" class="form-control order_desc long_text"></textarea></div>
    </div>
  </div>
 </div>
 <!-- bottom save button -->
 <div class="bottom_button">
  <div>
    <button class="btm_button mx-3" (click)="postDesign();modal.close()">Save</button>
  </div>
 </div>
</ng-template>

<!-- add vendor -->
<ng-template #addVendor let-modal>
  <div class="add_vendor_body">
    <div class="add_vendor_mod">
      <!-- Design Name and back button -->
      <div class="assign_to_header">
       <div class="order_desc_16px p-3">
         Assign to
       </div>
       <div>
        <button
        type="button"
        style="border: none;margin-top: 12px; border-top: none; outline: none;background:transparent"
        class="close me-3"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
       </div>
      </div>
      <!-- content -->
      <div class="p-3">
       <!-- search bar -->
       <div class="mt-2">
         <div class="input-group">
           <div class="input-group-prepend">
             <span class="input-group-text" id="basic-addon1">
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5006 13.9999H14.7106L14.4306 13.7299C15.6306 12.3299 16.2506 10.4199 15.9106 8.38989C15.4406 5.60989 13.1206 3.38989 10.3206 3.04989C6.09063 2.52989 2.53063 6.08989 3.05063 10.3199C3.39063 13.1199 5.61063 15.4399 8.39063 15.9099C10.4206 16.2499 12.3306 15.6299 13.7306 14.4299L14.0006 14.7099V15.4999L18.2506 19.7499C18.6606 20.1599 19.3306 20.1599 19.7406 19.7499C20.1506 19.3399 20.1506 18.6699 19.7406 18.2599L15.5006 13.9999ZM9.50063 13.9999C7.01063 13.9999 5.00063 11.9899 5.00063 9.49989C5.00063 7.00989 7.01063 4.99989 9.50063 4.99989C11.9906 4.99989 14.0006 7.00989 14.0006 9.49989C14.0006 11.9899 11.9906 13.9999 9.50063 13.9999Z" fill="#9F9F9F"/>
                   </svg>
             </span>
           </div>
           <input 
            (input)="searchVendor($event.target)"
           type="text" class="form-control custom_input" placeholder="Search" aria-label="Search" aria-describedby="basic-addon1">
         </div>
       </div>
       <!-- vendor list -->
       <div class="mt-4" >
        <div class="mt-3" *ngFor="let searchUser of searchUsers"
        (click)="
           menuItemClicked(
              $event,
              'assign',
              custom_item_id,
              searchUser.id
            )
      ">
          <div class="vendor_list px-1">
            <div id="dynamic-svg">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#3268FB"/>
                <foreignObject x="6" y="6" width="12" height="12">
                  <div xmlns="http://www.w3.org/1999/xhtml" style="display: flex; align-items: center; justify-content: center; height: 100%;">
                    <span id="dynamic-letter" style="color: white; font-size: 12px;" *ngIf="searchUser.name">{{searchUser.name[0] ? searchUser.name[0]:""}}</span>
                  </div>
                </foreignObject>
              </svg>
            </div>
            <div class="dark_grey_16px vendor_item">
                <div>{{searchUser.phone}}</div>
                <div
                *ngIf="searchUser.selected"
                (click)="
                      menuItemClicked(
                        $event,
                        'remove',
                        custom_item_id,
                        searchUser.id
                      )
                    ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M14 8C14 5.79 12.21 4 10 4C7.79 4 6 5.79 6 8C6 10.21 7.79 12 10 12C12.21 12 14 10.21 14 8ZM2 18V19C2 19.55 2.45 20 3 20H17C17.55 20 18 19.55 18 19V18C18 15.34 12.67 14 10 14C7.33 14 2 15.34 2 18ZM18 10H22C22.55 10 23 10.45 23 11C23 11.55 22.55 12 22 12H18C17.45 12 17 11.55 17 11C17 10.45 17.45 10 18 10Z" fill="#9F9F9F"/>
                      </svg>
                </div>
            </div>
          </div>
        </div> 
      
       </div>
      </div>
     </div>
     <div class="new_vendor">
      <button (click)="createVendorModal(createVendor)">
        + New Vendor
      </button>
     </div>
  </div>

 </ng-template>

 <!-- create Vendor -->
 <ng-template #createVendor let-modal>
  <div class="create_vendor_body">
    <div class="create_vendor_mod">
      <div class="assign_to_header">
        <div class="order_desc_16px p-3">
          Create Vendor/Goldsmith
        </div>
        <div>
         <button
         type="button"
         style="border: none;margin-top: 12px; border-top: none; outline: none;background:transparent"
         class="close me-3"
         aria-label="Close"
         (click)="modal.dismiss('Cross click')"
       >
         <span aria-hidden="true">&times;</span>
       </button>
        </div>
       </div>
       <!-- form -->
       <div class="px-3 py-2">
         <!-- 1st -->
         <div class="mt-2" style="position: relative;">
          <div class="dark_grey_14px">Mobile No<span class="red_dot">*</span></div>
          <div style="position: relative;">
              <input class="form-control mt-1" id="mobile_number" placeholder="Enter mobile no" style="padding-right: 30px;"> <!-- Adjust padding-right as needed -->
              <div (click)="sendMessageToFlutter()" class="contact-icon" style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%);">
                  <img src="assets/images/contact.png" style="max-height: 100%; max-width: 100%;">
              </div>
          </div>
      </div>

         <!-- 2nd -->
         <div class="mt-3">
          <div class="dark_grey_14px">Full Name<span class="red_dot">*</span></div>
          <input class="form-control mt-1" id="full_name" placeholder="Enter full name">
         </div>
          <!-- 3rd -->
       <div class="mt-3">
        <div class="dark_grey_14px">Vendor Code<span class="red_dot">*</span></div>
        <input class="form-control mt-1" id="code_name" placeholder="Enter vendor code">
       </div>
         <!-- 4th -->
         <div class="mt-3">
          <div class="dark_grey_14px">Email</div>
          <input class="form-control mt-1" id="vendor_email" placeholder="Enter vendor's email">
         </div>
       </div>

    </div>
    <div class="add_vendor">
      <button (click)="createVendorFunction()">
        Save & Assign
      </button>
     </div>
  </div>


 </ng-template>


 <ng-template #Filter let-modal>
  <div class="design_mod" >
    <div class="spb p-3">
      <div class="order_desc_16px">Filter</div>
      <div class="dark_grey_13px" (click)="clearAllFilter()">Clear All</div>
    </div>
    <div class="filter_body" style="min-height:90vh;max-height:auto;">
      <div class="" style="border-right: 1px solid #DFDFDF;">
        <div (click)="toggleFilter()" [ngStyle]="filter_status_toggle?{'background':'#F2F5FF','border-left':'2px solid #262626'}:{'background':'none','border-left':'none'}" class="dark_400 py-2 px-3">By Status
          <span *ngIf="!!filter_values.length" class="badge ms-2">{{filter_values.length}}</span>
        </div>
        <div (click)="toggleFilter()" [ngStyle]="!filter_status_toggle?{'background':'#F2F5FF','border-left':'2px solid #262626'}:{'background':'none','border-left':'none'}" class="dark_400 py-2 px-3">By Vendor
          <span *ngIf="!!vendors_values.length" class="badge ms-2">{{vendors_values.length}}</span>
        </div>
      </div>
      <div [class.hidden]="!filter_status_toggle" class="mt-2">
        <div class="">
          <label class="dark_400">
            <input class="me-2"
            type="checkbox"
            [checked]="is_all_filter"
            (change)="checkAllFilters($event)">
            All
          </label>
        </div>
        <div *ngFor="let filter of filter_list">
          <div class="mt-2">
            <label class="dark_400">
              <input
              #checkboxRef 
              class="me-2 checkboxRef" type="checkbox"
              [checked]="filter.state"
              (change)="storeFilterValues($event,filter)"
              [value]="filter.value">
              {{filter.name}}
            </label>
          </div>
        </div>
      

      </div>
      <div [class.hidden]="filter_status_toggle" class="mt-2 pb-4">
        <div class="">
          <label class="dark_400">
            <input class="me-2" type="checkbox"
            (change)="checkVendorsAllFilters($event)"
            [checked]="is_all_vendor"
            >
            All
          </label>
        </div>
        <div *ngFor="let vendor of vendor_list;let i=index">
          <div class="mt-2">
            <label class="dark_400">
              <input class="me-2 checkBoxRefVendors" 
              [checked]="vendor.state"
              (change)="storeVendorFilterValues($event,vendor)"
              type="checkbox">
              {{vendor.name}}
            </label>
          </div>
        </div>
      </div>
    </div>
  <div class="bottom_button" style="height: auto;">
   <div class="filter_btm">
      <div class="grey_text_16" (click)="modal.close()">
        CLOSE
      </div>
      <div class="blue_text_16" (click)="this.filter_page_index=1;filterApply();modal.close();">
        APPLY
      </div>
   </div>
  </div>
  </div>
 </ng-template>