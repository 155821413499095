export class Dashboard
{
    new_installments!:number;
    new_installment_amount!:string;
    recurring_installments!:number;
    recurring_installment_amount!:string;
    total!:string;

    store_amount_total!:string;
    store_amount_total_cash!:string;
    store_amount_total_card!:string;
    store_amount_total_upi!:string;
    store_amount_total_net_banking!:string;
    store_amount_total_net_cheque!:string;
    in_online_payment!:string;



    online_jewellery_plan_payments!: number;
    online_advance_booking_payments!: number;
    cash_jewellery_plan_payments!: number;
    cash_advance_booking_payments!: number;
    card_jewellery_plan_payments!: number;
    card_advance_booking_payments!: number;
    upi_jewellery_plan_payments!: number;
    upi_advance_booking_payments!: number;
    netbanking_jewellery_plan_payments!: number;
    netbanking_advance_booking_payments!: number;
    cheque_jewellery_plan_payments!: number;
    cheque_advance_booking_payments!: number;
    cash_cancelled_jewellery_plans!: number;
    cash_cancelled_advance_booking!: number;

    ac_cancelled_jewellery_plans!: number;
    ac_cancelled_advance_booking!: number;

    count_jewellery_plans!:number;
    count_advance_booking!:number;
    total_jewellery_plans_payments!:number;
    total_advance_booking_payments!:number;
    total_jewellery_plan_weight!:number;
    total_advance_booking_weight!:number;

    closing_balance_cash!:number;

    oms_online!:number;
    oms_cash!:number;
    oms_card!:number;
    oms_upi!:number;
    oms_rtgs!:number;
    oms_cheque!:number;

    cash_cancelled_custom_order!: number;
    ac_cancelled_custom_order!: number;

    order_count!:number;
    order_total!:number;
    order_total_weight!:number;

    old_gold_wt!:number;
    count_digital_coin_order!: number;
    digital_coin_order_total_weight!: string;
    unassinged_orders!: number;
    ac_cancelled_digital_coin_order!: number;
    cash_cancelled_digital_coin_order!: number;
    cheque_digital_coin_order_payments!: number;
    netbanking_digital_coin_order_payments!: number;
    upi_digital_coin_order_payments!: number;
    card_digital_coin_order_payments!: number;
    cash_digital_coin_order_payments!: number;
    online_digital_coin_order_payments!: number;
    total_digital_coin_order_payments!: number;
}



export class CounterDataList{
    counter_data:CounterData[]=[new CounterData()];
    index: number=0;
}


export class CounterData {
    counter_id!: number;
    counter_name!: string;
    jewellery_plan_payments_cash_payments!: number;
    jewellery_plan_payments_card_payments!: number;
    jewellery_plan_payments_upi_payments!: number;
    jewellery_plan_payments_rtgs_neft_payments!: number;
    jewellery_plan_payments_cheque_payments!: number;

    advance_booking_payments_cash_payments!: number;
    advance_booking_payments_card_payments!: number;
    advance_booking_payments_upi_payments!: number;
    advance_booking_payments_rtgs_neft_payments!: number;
    advance_booking_payments_cheque_payments!: number;

    order_payments_cash_payments!: number;
    order_payments_card_payments!: number;
    order_payments_upi_payments!: number;
    order_payments_rtgs_neft_payments!: number;
    order_payments_cheque_payments!: number;

    digital_payment_cash_payments!: number;
    digital_payment_card_payments!: number;
    digital_payment_upi_payments!: number;
    digital_payment_rtgs_neft_payments!: number;
    digital_payment_cheque_payments!: number;

    total_jewellery_plan_payments!: number;
    total_advance_booking_payments!: number;
    total_order_payments!: number;
    total_digital_payments!: number;

    constructor(){
        this.total_jewellery_plan_payments=this.jewellery_plan_payments_cash_payments+this.jewellery_plan_payments_card_payments+this.jewellery_plan_payments_upi_payments+this.jewellery_plan_payments_rtgs_neft_payments+this.jewellery_plan_payments_cheque_payments;
        this.total_advance_booking_payments=this.advance_booking_payments_cash_payments+this.advance_booking_payments_card_payments+this.advance_booking_payments_upi_payments+this.advance_booking_payments_rtgs_neft_payments+this.advance_booking_payments_cheque_payments;
        this.total_order_payments=this.order_payments_cash_payments+this.order_payments_card_payments+this.order_payments_upi_payments+this.order_payments_rtgs_neft_payments+this.order_payments_cheque_payments;
        this.total_digital_payments=this.digital_payment_cash_payments+this.digital_payment_card_payments+this.digital_payment_upi_payments+this.digital_payment_rtgs_neft_payments+this.digital_payment_cheque_payments;
        
    }

  }

  export interface CounterPayments {
    cash_payments: number;
    card_payments: number;
    upi_payments: number;
    rtgs_neft_payments: number;
    cheque_payments: number;
  }