<div class="parent">
    <div class="icon">
        <img src="assets/images/Done.png" alt="">
    </div>
    <div class="title">Digital Coin Purchased Successfully !</div>
    <div class="key-value-pair">
        <div class="key">Purchase ID</div>
        <div class="colon">:</div>
        <div class="value">{{data.details.purchase_id}}</div>
    </div>
    <div class="key-value-pair">
        <div class="key">Rate/ gm</div>
        <div class="colon">:</div>
        <div class="value">₹ {{data.details.rate}}</div>
    </div>
    <div class="key-value-pair">
        <div class="key">Booking Weight</div>
        <div class="colon">:</div>
        <div class="value">{{data.details.weight}}</div>
    </div>
    <div class="key-value-pair">
        <div class="key">Customer Name</div>
        <div class="colon">:</div>
        <div class="value">{{data.details.user.first_name}} {{data.details.user.last_name}}</div>
    </div>
    <div class="key-value-pair">
        <div class="key">Amount</div>
        <div class="colon">:</div>
        <div class="value">₹ {{data.details.amount}}</div>
    </div>
    <primary-btn (click)="dialogRef.close(true)"
        [styles]="{'marginTop':'30px', 'fontSize':'18px', 'fontWeight':'500','width':'300px','borderRadius':'12px','padding':'10px 0px'}">Print</primary-btn>
</div>