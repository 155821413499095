import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'primary-btn',
  standalone: true,
  imports: [MatButtonModule, NgStyle],
  templateUrl: './primary-btn.component.html',
  styleUrls: ['./primary-btn.component.css']
})
export class PrimaryBtnComponent {
  @Input() disabled: boolean = false;
  @Input('styles') styles: { [key: string]: string | number; } = {}
}
