import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RateFinal } from '../models/rate';

import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { ResponseMessage } from '../models/message';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { count, map, Observable } from 'rxjs';
import { PopUpManager } from '../manager/Popup';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RateDataSource } from './templates-datasource';
import { TemplateServiceService } from './template-service.service';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation , bounceInDownOnEnterAnimation, rubberBandOnEnterAnimation, pulseOnEnterAnimation, tadaOnEnterAnimation, slideInUpOnEnterAnimation, slideInDownOnEnterAnimation, slideOutDownOnLeaveAnimation} from 'angular-animations';
@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
    bounceInDownOnEnterAnimation(),
    rubberBandOnEnterAnimation(),
    pulseOnEnterAnimation(),
    tadaOnEnterAnimation(),
    slideInUpOnEnterAnimation({duration:500}),
    slideOutDownOnLeaveAnimation({duration:500})
  ]
})
export class TemplatesComponent implements OnInit {
  displayedColumns: string[] = ['date','time','gold22','gold18','silver','platinum'];
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  currentDate = new Date();
  rates!: RateFinal;
  is_appear=true
  show_rates=false;
  update_button=true;
  dataSource!:RateDataSource;
  from_date: string = ""; to_date: string = "";ordering:string="id";
  gold_rate= 0;silver_rate=0;
  gold_materila = "Gold";silver_material="Silver";
  @ViewChild(MatSort, { static: true })
  sort!: MatSort;
  message!:ResponseMessage
  state$!: Observable<object>;
  popupManager!:PopUpManager;


  success_message = "Rate updated successfully";

  constructor(private activatedRoute:ActivatedRoute,private modalService:NgbModal,private rateService:TemplateServiceService,private toastr:ToastrService,private _location:Location,private router:Router,private snackBar:MatSnackBar) {

  }

  back(){
    this._location.back()
  }

  toggleAppearance()
  {this.is_appear=!this.is_appear; console.log("toggled");}

  ngOnInit(): void {
  this.dataSource=new RateDataSource(this.rateService);
  this.dataSource.loadData(0,this.from_date,this.to_date);
  this.popupManager = new PopUpManager(this.snackBar);
  if(history.state.opne_rate){
  (<HTMLElement>(document.getElementById('add_rate'))).click();

  }




  }


  // tesetRate(){
  //   this.rateService.getRates().subscribe(data=>{
  //     this.rates = data;
  //     console.log(this.rates);
  //    for(var rate in this.rates.rates){
  //     if(this.rates.rates[rate].material==this.gold_materila){
  //       this.gold_rate = parseInt(this.rates.rates[rate].rate);

  //     }else if(this.rates.rates[rate].material == this.silver_material){
  //       this.silver_rate = parseInt(this.rates.rates[rate].rate);

  //     }
  //    }
  //     this.show_rates=true;

  //   })
  // }

  getFromDate(event: any):void{
    this.from_date = moment(event).format("DD-MM-YYYY");;
    this.resetAndDisplayTable();
  }

  getToDate(event: any){
  this.to_date = moment(event).format("DD-MM-YYYY");
  this.resetAndDisplayTable();
  this.popupManager.openSnackBar("Loading ...","close")

  }




  ngAfterViewInit():void {

    // if(this.sort.sortChange.subscribe(()=> {
    //   this.sortbyId()
    // }))

    this.dataSource.counter$.subscribe(
      count => {
        this.paginator.length = count;
      }
    )
    this.paginator.page.subscribe(
      () => {
        this.dataSource.loadData(this.paginator.pageIndex,this.from_date, this.to_date);
      }
    )

  }


  sortbyId() {

    if(this.sort.direction=="asc"){
      this.ordering="id"
      this.resetAndDisplayTable()
    }
    if(this.sort.direction=="desc"){
      this.ordering="-id"
      this.resetAndDisplayTable()
    }
  }

  resetAndDisplayTable() {
    this.paginator.pageIndex = 0;
    this.dataSource.loadData(0,this.from_date, this.to_date);

  }


  getRate(content:any){
    this.rateService.getRates().subscribe((data:any)=>{
      this.rates = data;
     for(var rate in this.rates.rates){
      if(this.rates.rates[rate].material==this.gold_materila){
        this.gold_rate = parseInt(this.rates.rates[rate].rate);

      }else if(this.rates.rates[rate].material == this.silver_material){
        this.silver_rate = parseInt(this.rates.rates[rate].rate);
      }
     }
      this.show_rates=true;
      this.modalService.open(content);

    },(error:any)=>{

      this.toastr.info("","Something went wrong, please do try again later.", { positionClass: "toast-bottom-right" })



    })
  }


  openRate(content: any){
    this.getRate(content);


  }

  openBottomModal(content:any)
  {
    this.modalService.open(content);

  }
  saveRate(){
    this.update_button=false;
    var gold22 = (<HTMLInputElement>(document.getElementById('gold22'))).value;
    var gold18 = (<HTMLInputElement>(document.getElementById('gold18'))).value;
    var silver = (<HTMLInputElement>(document.getElementById('silver'))).value;
    var platinum = (<HTMLInputElement>(document.getElementById('platinum'))).value;
    var fd = new FormData();
    fd.append("gold22",gold22);
    fd.append("gold18",gold18);
    fd.append("silver",silver);
    fd.append("platinum",platinum);
    fd.append("gold24",this.gold_rate.toString());
    fd.append("silver999",this.silver_rate.toString());
    this.popupManager.openSnackBar("Loading ...","close")

    this.rateService.updateRates(fd).subscribe((val:any)=>{
      this.message = val;
        this.toastr.success("",this.message.message, { positionClass: "toast-bottom-right" });
        this.modalService.dismissAll();
        this.resetAndDisplayTable();
      this.update_button=true;
    },(error:any)=>{

      this.toastr.info("","Something went wrong, please do try again later.", { positionClass: "toast-bottom-right" })

      this.update_button=true;

    })



  }


}
