<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:50vh;">

<div class="container-fluid master-container example-container side">
  <div class="row">
    <div class="col-md-3">
      <h3 class="back_title" (click)="back()">
        <mat-icon class="right_back">arrow_back_ios</mat-icon>Select Users
      </h3>
    </div>
    <div class="col-md-3"></div>
    <div class="col-md-3">
      <!-- <h2 class="back_title">Selected Users</h2> -->
    </div>
    <div class="col-md-3"></div>
  </div>

  <div class="row">
    <div class="col-md-5">
      <div class="input-group">
        <mat-icon class="greyColor searchIcon" [ngStyle]="{ color: '#9A9AB0' }"
          >search</mat-icon
        >
        <input
          [(ngModel)]="search"
          type="text"
          class="form-control search_box roundSquare text-indent"
          id="search"
          placeholder="Search Formatted Id, Customer Name, Plan type"
          style="border: 0; height: 48px"
          (keyup.enter)="searchOption(); txtInput.blur()"
          #txtInput
        />
        <mat-icon
          *ngIf="search"
          class="crossIcon"
          (click)="clearInput(); searchOption()"
          [ngStyle]="{ color: '#9A9AB0' }"
          >cancel</mat-icon
        >
        <button
          (click)="searchData()"
          mat-raised-button
          color="primary"
          class="searchButton"
          style="background: #4070f4; color: #ffffff; height: 48px"
        >
          Search
        </button>
      </div>
      <br />
      <!-- <mat-checkbox [checked]="isAllSelected()" (change)="toggelAll()"
        >Select All</mat-checkbox
      >

      <button
        mat-raised-button
        color="primary"
        class="float_right width"
        (click)="open_sent(sent_modal)"
      >
        Send
      </button> -->
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-6">
      <!-- <ol>
        <li *ngFor="let x of listOfUser" class="user-class">{{ x.name }}</li>
      </ol>
      {{showUser.join(',')}}
      <span *ngIf="isToggled" class="user-class">Selected all users</span> -->
    </div>
  </div>
  <div class="col-md-12 pt-3">
    <div class="row tablePadding">
      <div class="col-md-12 text-center">
        <span
          class="sel_All all"
          style="cursor: pointer"
          (click)="toggelAll()"
          >{{ isToggled ? "Deselect All" : "Select All" }}</span
        >
        <div class="table-responsive" style="border-radius: 6px">
          <div>
            <!-- <mat-checkbox
            class="checky"

          ></mat-checkbox> -->
            <span class="checky"
              >{{ count_selected }}/{{ paginator.length }}</span
            >
          </div>

          <!-- <span>Select All</span> -->
          <table mat-table [dataSource]="dataSource" matSort class="">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef style="width: 70px"></th>
              <td
                mat-cell
                *matCellDef="let element"
                (click)="$event.stopPropagation()"
                style="width: 70px"
              >
                <mat-checkbox
                  (change)="onLessonToggled(element)"
                  [checked]="element.isSelected"
                ></mat-checkbox>
              </td>
            </ng-container>
            <!-- <ng-container matColumnDef="plan_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Formatted Id
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.id }}
              </td>
            </ng-container> -->
            <ng-container matColumnDef="phone">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                style="width: 150px"
              >
                Phone number
              </th>
              <td mat-cell *matCellDef="let element" style="width: 150px">
                {{ element.phone }}
              </td>
            </ng-container>
            <ng-container matColumnDef="customer_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.first_name }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              (click)="selection.toggle(element)"
            ></tr>
          </table>
          <div class="table-button" style="background: #ffffff">
            <button
              mat-raised-button
              class="table-button-new mt-2 mb-3"
              (click)="actionsCheck(37); open_sent(sent_modal)"
              color="primary"
            >
              Send
            </button>
          </div>
          <mat-paginator
            style="background-color: #f5f6f8"
            [pageSizeOptions]="[popupManager.custom_size_number]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<ng-template #sent_modal let-modal>
  <div class="myModal">
    <div class="modal-header">
      <h1 class="modal-title"></h1>

      <button
        type="button"
        style="border: none; border-top: none"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <img src="assets/images/tickmark.svg" />

        <h3
          class="bold_title mt-3"
          style="margin-left: 10px; font-weight: 700; font-size: 24px"
        >
          Notification Sent
        </h3>

        <p class="mt-1">
          Notification has been successfully sent to the <br />selected users
        </p>

        <div class="co mt-4">
          <button
            (click)="openHome()"
            mat-raised-button
            color="primary"
            class="float_right full-width padBottom mt-2 mb-1 me-3"
            style="
              background: #4070f4;
              box-shadow: 0px 8px 16px rgba(32, 10, 130, 0.06);
              border-radius: 12px;
              width: 295px;
              height: 50px;
            "
          >
            Continue
          </button>
        </div>
        <p class="pt-1"></p>
      </div>
    </div>
  </div>
</ng-template>
