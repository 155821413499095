<div class="floating-container">
  
    <div class="fab" (click)="toggleIcons()">
        
        <span class="fab-icon">
            <span *ngIf="showIcons" class="close-icon">&times;</span>
            <span *ngIf="!showIcons">?</span>
          </span> 
    </div>
    <!-- <div class="fab mt-2">
        <a href="https://api.whatsapp.com/send/?phone=%2B917019527608&text&type=phone_number&app_absent=0" class="floating" target="_blank">
            <i class="fab fa-whatsapp fab-icon"></i>
            </a>
    </div> -->
    <div class="floating-icons" [ngClass]="{'active': showIcons}">
        <div class="icon" *ngFor="let icon of icons">
            <span class="icon-wrapper" (click)="redirectToDemo(icon.url)">
              <span class="icon-description">{{ icon.description }}</span>
              <span class="icon-symbol">
                <img [src]="icon.svgContent">
              </span>
            </span>
          </div>
    </div>
  </div>
  