<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:50vh;">

<div class="container-fluid">
  <div id="show_page">
    <div class="row">
      <div class="col-md-12 text-center">
        <mat-spinner
          *ngIf="dataSource.loading$ | async"
          style="margin: 0 auto"
          mode="indeterminate"
          [diameter]="50"
        ></mat-spinner>
      </div>
    </div>
    <div class="first_row">
      <div class="first_row_div mt-2 ms-3">
        <div class="input-group" style="margin-left: 15px">
          <mat-icon class="searchIcon" [ngStyle]="{ color: '#9A9AB0' }"
            >search</mat-icon
          >

          <input
            [(ngModel)]="search"
            type="text"
            class="form-control search_box roundSquare text-indent"
            id="plan_name"
            style="border: 0"
            placeholder="Search Counter ID or Role Name"
            (keyup.enter)="searchOption(); txtInput.blur()"
            #txtInput
          />
          <mat-icon
            *ngIf="search"
            class="crossIcon"
            (click)="clearInput(); searchOption()"
            [ngStyle]="{ color: '#9A9AB0' }"
            >cancel</mat-icon
          >

          <button
            (click)="searchOption()"
            mat-raised-button
            class="searchButton"
            style="
              background: #4070f4;
              color: #ffffff;

              box-shadow: none;
            "
          >
            Search
          </button>
        </div>
      </div>


      <div class="mt-2">
        <button
          id="add_rate"
          mat-raised-button
          mat-raised-button
          color="primary"
          class="btn btn-primary addbu mt-3"
          (click)="modal(add_counter)"
          type="button"
        >
          <span class="me-1"> +</span>
          Add
        </button>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row tablePadding">
        <div class="col-md-12 text-center mt-4">
          <div *ngIf="false">
            <div class="text-center pt-5 mt-5">
              <img
                src="assets/images/ratesempty.png"
                class="img-fluid gold mt-3"
              />

              <h1 class="mt-2">No Data Found</h1>
            </div>
          </div>
          <div class="mat-elevation table-responsive">
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="serialNo">
                <th mat-header-cell *matHeaderCellDef style="width: 70px"></th>
                <td
                  mat-cell
                  style="width: 70px"
                  *matCellDef="let element; let i = index"
                >
                  {{ i + 1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="counter_id">
                <th mat-header-cell *matHeaderCellDef>Counter ID</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.counter_id }}
                </td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef>Role</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.role }}
                </td>
              </ng-container>
              <ng-container matColumnDef="permissions">
                <th mat-header-cell *matHeaderCellDef>Permissions</th>
                <td mat-cell *matCellDef="let element">
                  <a
                    (click)="switchToPermission(element.id, element.counter_id)"
                    class="viewcounter"
                    >View
                  </a>
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.status == true ? "Active" : "Inactive" }}
                  <!-- <label class="switch ms-5">
                            <input type="checkbox" [(ngModel)]="element.status" (click)="changeCounterStatus(element.id,!element.status)">
                            <span class="slider round" ></span>
                          </label> -->
                </td>
              </ng-container>
              <ng-container matColumnDef="status_t">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <label class="switch me-4">
                    <input
                      type="checkbox"
                      [(ngModel)]="element.status"
                      (click)="changeCounterStatus(element.id, !element.status)"
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>

                <td mat-cell *matCellDef="let element" style="padding: 0">
                  <span [ngStyle]=""></span>
                  <button
                    class="newbi"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                  >
                    <span
                      id="boot-icon"
                      class="bi bi-three-dots-vertical mt-1"
                      style="
                        font-size: 25px;
                        -webkit-text-stroke-color: rgb(0, 0, 0);
                      "
                    ></span>
                  </button>
                  <mat-menu #menu="matMenu" class="matmenu">
                    <a style="text-decoration: none">
                      <button
                        (click)="
                          switchToPermission(element.id, element.counter_id)
                        "
                        mat-menu-item
                      >
                        <mat-icon>edit</mat-icon>
                        <span>Edit Role & Permissions</span>
                      </button>
                    </a>
                    <a style="text-decoration: none">
                      <button
                        (click)="changePinCounterModal(element.id, showotp)"
                        mat-menu-item
                      >
                        <mat-icon>sync</mat-icon>
                        <span>Change Pin</span>
                      </button>
                    </a>
                    <a style="text-decoration: none">
                      <button
                        (click)="removeCounterModal(element.id, deleteCounter)"
                        mat-menu-item
                      >
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                      </button>
                    </a>
                  </mat-menu>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <mat-paginator
              style="background-color: #f5f6f8"
              [pageSizeOptions]="[popUpManager.page_size_number]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="show_edit_permission">
    <div class="row mt-4">
      <h3 (click)="switchToTable()" class="back_title ms-3">
        <mat-icon
          style="font-size: 18px !important; margin-top: 9px"
          class="right_back"
          >arrow_back_ios</mat-icon
        >Edit Permissions
      </h3>
      <div class="col-md-5 ms-3">
        <div
          class="mt-4 mb-3"
          style="
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;

            color: #636363;
          "
        >
          Counter ID
        </div>
        <input
          [(ngModel)]="counter_id_display"
          type="email"
          class="form-control"
          id="email"
          [disabled]="true"
          style="width: 82%; height: 52px"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-5 ms-3">
        <div
          class="mt-2 mb-3"
          style="
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;

            color: #636363;
          "
        >
          Role*
        </div>
        <select
          class="dropdown"
          style="width: 82%; height: 52px"
          (change)="change_mode_modal(changeRole, role_id)"
          [(ngModel)]="role_id"
        >
          <option *ngFor="let option of options" [value]="option.value">
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-5 ms-3">
        <div
          class="mt-4 mb-3"
          style="
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;

            color: #636363;
          "
        >
          Permissions
        </div>
      </div>
      <div class="col-md-12 ms-4">
        <div class="row mb-5"
          style="background-color: white; width: 92%; border-radius: 8px">
          <div class="col-md-3" style="padding: 0; border-right: 1px solid #eaeaea">
            <table class="newtable">
              <thead style="background: #f6f8fe">
                <tr>
                  <th style="height: 47px; padding-left: 35px">Features</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selected_features"
                [class.selected]="item.active">
                  <td
                  [ngStyle]="{'cursor': item.status?'pointer':'not-allowed'}"
                  (click)="item.status?featureClick(item.value):''"
                  style="height: 37px; padding-left: 35px; border: none;cursor: pointer;">
                    {{ item.value | capitalize }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="col-md-4"
            style="padding: 0; border-right: 1px solid #eaeaea"
          >
          <!-- List of predefined permissionss based on roles is mentioned in permission page
When a page is clicked, show list of all its actions
If a page view is disabled, all actions associated actions of the page will be disabled
If a page view is enabled, it is not required to enable all actions of the page by defaut
If actions are disabled, user can still have the page view enabled
If atleast one action is enabled, the page view will also be enabed					 -->
            <table class="newtable">
              <thead>
                <tr style="background: #f6f8fe">
                  <th style="height: 47px; padding-left: 35px; width: 60%">
                    Pages
                  </th>
                  <th style="height: 47px"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let item of pages_group"
                  [class.selected]="item === selectedRow"
                >
                  <td (click)="pageClick(item);selectRow(item)" style="height: 37px; padding-left: 35px; border: none;">
                    {{ item.name | capitalize }}
                  </td>
                  <td style="height: 37px; border: none; text-align: center">
                    <label class="switch">
                      <input type="checkbox" [(ngModel)]="item.status" (ngModelChange)="deactivateSaveButton()" />
                      <span
                        class="slider round"

                        (click)="page_to_actions(item)"
                      ></span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-5" style="padding: 0">
            <table class="newtable">
              <thead style="background: #f6f8fe">
                <tr>
                  <th style="height: 47px; padding-left: 35px">Actions</th>
                  <th style="height: 47px"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of selected_actions">
                  <td class="hover-able-text" style="height: 37px; padding-left: 35px; border: none">
                    {{ item.name | capitalize }}
                  </td>
                  <td style="height: 37px; border: none; text-align: left">
                    <label class="switch">
                      <input  type="checkbox"  (click)="item.disable?action_to_page(item):''" (ngModelChange)="deactivateSaveButton()" [(ngModel)]="item.status" />
                      <span class="slider round" ></span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-primary next-btn btnHeight"
        (click)="modal(editpermission)"
        [disabled]="is_permission_edited"
      >
        Save

        <a style="text-decoration: none"> </a>
      </button>
    </div>
  </div>

  <ng-template #add_counter let-modal>
    <div class="myModal_new">
      <div class="modal-content" style="width: 100%; height: 550px">
        <div
          class="modal-header"
          style="background: #f2f5ff; border-radius: 20px 20px 0px 0px"
        >
          <span class="modal-title"> Add Counter Account</span>
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ms-3 mt-3">
          <span
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;

              color: #262626;
            "
          >
            Store ID: {{ storeID }}
          </span>

          <div
            class="mt-4"
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;

              color: #636363;
            "
          >
            Counter ID*
          </div>
          <input
            type="text"
            class="form-control social_account_field mt-3"
            id="counterid"
            [(ngModel)]="counter_id"
            placeholder="Counter ID"
            style="width: 93%"
            #focuscounterid
            appUppercaseNoSpecial
          />
          <div
            class="mt-4 mb-3"
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;

              color: #636363;
            "
          >
            Set Pin*
          </div>

          <ng-otp-input
            (onInputChange)="addCounterSetPin($event)"
            [config]="{ length: 4 }"
          ></ng-otp-input>

          <div
            class="mt-4 mb-3"
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;

              color: #636363;
            "
          >
            Role*
          </div>

          <select
            class="dropdown"
            style="width: 93%; height: 52px"
            [(ngModel)]="roles_id_add"
          >
            <option value="" disabled selected>Select an option</option>
            <option *ngFor="let option of options" [value]="option.value">
              {{ option.label }}
            </option>
          </select>

          <div class="row mt-3">
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <button
                (click)="modal.dismiss('Cross click')"
                type="button"
                class="btn btn-primary mt-2 cancelbutton"
              >
                Cancel
              </button>
            </div>

            <div class="col-md-4">
              <button
                (click)="addNewCounter(); modal.dismiss('Cross click')"
                type="button"
                class="btn btn-primary mt-2 addbutton1"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #showotp let-modal>
    <div class="pb-3 p-3">
      <!-- <div class="modal-header" style="border: none;height: 0;padding:0">
      <h1 class="modal-title"></h1>

      <button
      type="button"

      class="close closeButton btn mt-4"
      aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div> -->
      <div class="modal-body">
        <div class="text-center">
          <h1 class="tfbold">Change Pin</h1>

          <div class="container-fluid">
            <span class="greyColor">
              <span class="change_pin_label">New Pin</span>
              <div class="mt-3"></div>
              <ng-otp-input
                class="model-otp"
                (onInputChange)="changeCounterNewPin($event)"
                [config]="{ length: 4 }"
              ></ng-otp-input>

              <!-- <div *ngIf="ShowResend == false" class="mt-2">
                  <a type="button"> Resend </a>
                </div> -->
              <div class="mt-3"></div>
              <span class="change_pin_label" style="margin-right: 31%"
                >Confirm Pin</span
              >
              <div class="mt-3"></div>
              <ng-otp-input
                class="model-otp"
                (onInputChange)="changeCounterPinConfirm($event)"
                [config]="{ length: 4 }"
              ></ng-otp-input>
            </span>
          </div>

          <button
            type="button"
            class="btn btn-primary mt-4 addbutton3"
            (click)="
              changeCounterPin(pin_changed); modal.dismiss('Cross click')
            "
          >
            Save Pin
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #editpermission let-modal>
    <div class="myModal">
      <div class="modal-content p-4" style="width: 100%">
        <div class="modal-header" style="border: 0; height: 0">
          <h1 class="modal-title fbold"></h1>
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <img
              src="assets/images/errorsign.svg"
              class="img-responsive img-fluid"
            />

            <h1 class="fbold mt-2">Warning!</h1>

            <p class="greyColor">Are you sure you want to make the changes?</p>
            <div class="row">
              <div class="col-md-6">
                <button
                  (click)="modal.dismiss('Cross click')"
                  type="button"
                  class="btn btn-primary mt-3 addbutton5"
                >
                  Discard Changes
                </button>
              </div>
              <div class="col-md-6">
                <button
                  (click)="editPermission(); modal.dismiss('Cross click')"
                  type="button"
                  class="btn btn-primary mt-3 addbutton4"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #deleteCounter let-modal>
    <div class="myModal">
      <div class="modal-content p-4" style="width: 100%">
        <div class="modal-header" style="border: 0; height: 0">
          <h1 class="modal-title fbold"></h1>
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <img
              src="assets/images/errorsign.svg"
              class="img-responsive img-fluid"
            />

            <h1 class="fbold mt-2">Warning!</h1>

            <p class="greyColor">Are you sure you want to remove account?</p>

            <button
              (click)="removeCounter(); modal.dismiss('Cross click')"
              type="button"
              class="btn btn-primary mt-3 addbutton2"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #changeRole let-modal>
    <div class="myModal">
      <div class="modal-content p-4" style="width: 100%">
        <div class="modal-header" style="border: 0; height: 0">
          <h1 class="modal-title fbold"></h1>
          <button
            type="button"
            class="close closeButton btn"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <img
              src="assets/images/errorsign.svg"
              class="img-responsive img-fluid"
            />

            <h1 class="fbold mt-2">Warning!</h1>

            <p class="greyColor">Are you sure you want to Change the Role?</p>
            <div class="row">
              <div class="col-md-6">
                <button
                  (click)="modal.dismiss('Cross click')"
                  type="button"
                  class="btn btn-primary mt-3 addbutton5"
                >
                  Dismiss
                </button>
              </div>
              <div class="col-md-6">
                <button
                  (click)="change_mode(); modal.dismiss('Cross click')"
                  type="button"
                  class="btn btn-primary mt-3 addbutton4"
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #pin_changed let-modal>
    <div class="myModal">
      <div class="modal-header" style="border: none; height: 0">
        <h1 class="modal-title"></h1>

        <button
          type="button"
          class="close closeButton btn mt-4"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <img src="assets/images/tickmark.svg" />

          <h3
            class="bold_title mt-3"
            style="margin-left: 10px; font-weight: 700; font-size: 24px"
          >
            Pin Changed
          </h3>

          <p class="mt-1">Your pin has been successfully changed</p>

          <div class="co mt-4">
            <button
              mat-raised-button
              color="primary"
              class="padBottom mt-2 mb-1"
              style="
                background: #4070f4;
                box-shadow: 0px 8px 16px rgba(32, 10, 130, 0.06);
                border-radius: 12px;
                width: 295px;
                height: 50px;
              "
              (click)="modal.dismiss('Cross click')"
            >
              Continue
            </button>
          </div>
          <p class="pt-1"></p>
        </div>
      </div>
    </div>
  </ng-template>
</div>

</div>