import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Commodity } from '../setup-save/setup-save.component';
import { UserDetails } from '../interfaces/digital-coin.interface';
import { BookingsFilters } from '../bookings/bookings.component';
import { Rest } from 'src/app/models/rest_model';
import { reportDgCoinData } from './digital-coin';
import { Router } from '@angular/router';
// TODO: add http response observe all APIs 

@Injectable({
  providedIn: 'root'
})
export class DigitalCoinService {

  constructor(private http: HttpClient, private router: Router) { }

  validateGST(gst: string, stateCode?: number): Observable<any> {
    return this.http.post(`${environment.ip}shop/validateGST`, { "gst_in": gst, "state_code": stateCode ?? null }).pipe(take(1))
  }

  setupDG(name: string, pincode: string, address: string, gst: string, stateCode: string, state: string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.ip}shop/digital_coin/setup/`, {
      name: name,
      pincode: pincode,
      address: address,
      gstin: gst,
      state_code: stateCode,
      state: state
    },
      {
        observe: 'response'
      }
    ).pipe(take(1))
  }

  fetchCommodities(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${environment.ip}shop/digital_coin/commodities/`, { observe: "response" }).pipe(take(1))
  }

  saveCommodities(commodity: Commodity): Observable<HttpResponse<any>> {
    return this.http.patch<HttpResponse<any>>(`${environment.ip}shop/digital_coin/commodities/${commodity.id}/`, {
      status: commodity.status,
      hsn_code: commodity.hsn_code,
      va_percentage: commodity.va_percentage,
      invoice_prefix: commodity.invoice_prefix,
      description: commodity.description
    },
      { observe: 'response' }
    ).pipe(take(1))
  }

  fetchDeliveryTableData(filters: BookingsFilters,page: number, limit: number): Observable<HttpResponse<any>> {
    let payload: any = { category: "DELIVERY" }
    if (filters.commodity.length) payload.commodity = filters.commodity
    if (filters.status.length) payload.status = filters.status
    if (filters.search_str.length) payload.search_str = filters.search_str
    payload.page = page
    payload.limit = limit
    return this.http.post<HttpResponse<any>>(`${environment.ip}shop/digital_coin/order/`, payload, { observe: "response" }).pipe(take(1))
  }

  fetchBuyTableData(filters: BookingsFilters, page: number, limit: number): Observable<HttpResponse<any>> {
    console.log("Page:", page);
    console.log("Limit:", limit);
    
    let payload: any = { category: "BUY" }
    if (filters.commodity.length) payload.commodity = filters.commodity
    if (filters.status.length) payload.status = filters.status
    if (filters.search_str.length) payload.search_str = filters.search_str
    payload.page = page
    payload.limit = limit
    return this.http.post<HttpResponse<any>>(`${environment.ip}shop/digital_coin/order/`, payload, { observe: "response" }).pipe(take(1))
  }

  sendOtp(mobileNo: string): Observable<HttpResponse<any>> {
    let form = new FormData()
    form.append("mobile", mobileNo)
    return this.http.post<HttpResponse<any>>(`${environment.ip}shop/users/send_otp/`, form, { observe: "response" }).pipe(take(1))
  }
  
  send_otp_existing_user(mobileNo: string): Observable<HttpResponse<any>> {
    let form = new FormData()
    form.append("mobile", mobileNo)
    return this.http.post<HttpResponse<any>>(`${environment.ip}shop/users/send_otp_existing_user/`, form, { observe: "response" }).pipe(take(1))
  }

  validate_otp_existing_user(mobileNo: string, otp: string): Observable<HttpResponse<any>> {
    let form = new FormData()
    form.append("mobile", mobileNo)
    form.append("otp", otp)
    return this.http.post<HttpResponse<any>>(`${environment.ip}shop/users/validate_otp_existing_user/`, form, { observe: "response" }).pipe(take(1))
  }

  validateOtp(mobileNo: string, otp: string): Observable<HttpResponse<any>> {
    let form = new FormData()
    form.append("mobile", mobileNo)
    form.append("otp", otp)
    return this.http.post<HttpResponse<any>>(`${environment.ip}shop/users/validate_otp/`, form, { observe: "response" }).pipe(take(1))
  }

  createNewUser(userDetails: UserDetails): Observable<HttpResponse<any>> {
    let formData = new FormData()
    formData.append("first_name", userDetails.first_name)
    formData.append("phone", userDetails.mobile ?? "")
    formData.append("dob", userDetails.dob ?? "")
    formData.append("email", userDetails.email)
    formData.append("gender", userDetails.gender ?? "")
    formData.append("address", userDetails.address ?? "")
    formData.append("pincode", userDetails.pincode ?? "")
    formData.append("state", userDetails.state ?? "")
    formData.append("pan", userDetails.pan ?? "")
    formData.append("gstin", userDetails.gstin ?? "")
    return this.http.post<any>(`${environment.ip}shop/users/`, formData, { observe: 'response' }).pipe(take(1))
  }

  editUserDetails(userDetails: UserDetails): Observable<HttpResponse<any>> {
    let formData = new FormData()
    formData.append("id", userDetails.id.toString())
    formData.append("first_name", userDetails.first_name)
    formData.append("dob", userDetails.dob ?? "")
    formData.append("email", userDetails.email)
    formData.append("gender", userDetails.gender ?? "")
    formData.append("phone", userDetails.phone ?? "")
    formData.append("address", userDetails.address ?? "")
    formData.append("pincode", userDetails.pincode ?? "")
    formData.append("state", userDetails.state ?? "")
    formData.append("pan", userDetails.pan ?? "")
    formData.append("gstin", userDetails.gstin ?? "")

    return this.http.put<any>(`${environment.ip}shop/users/${userDetails.id}/`, formData, { observe: 'response' }).pipe(take(1))
  }

  validatePincode(pincode: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${environment.ip}shop/pincode/?pincode=${pincode}/`, { observe: 'response' }).pipe(take(1))
  }

  calculateAmount(commodity: string, quantity: number, input: "amount" | "weight"): Observable<HttpResponse<any>> {
    let payload: any = { commodity: commodity }
    payload[input] = quantity
    return this.http.post<HttpResponse<any>>(`${environment.ip}shop/digital_coin/order/calculate_amount/`, payload, { observe: "response" }).pipe(take(1))
  }

  savePaymentBooking(payload: any): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.ip}shop/digital_coin/order/new/`, payload, { observe: "response" }).pipe(take(1))
  }

  editPaymentBooking(id: any, payload: any): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(`${environment.ip}shop/digital_coin/order/${id}/payments/`, payload, { observe: "response" }).pipe(take(1))
  }

  getUserTransactions(id: string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.ip}shop/digital_coin/user/${id}/orders/`, {
      page: 1,
      limit: 10000000
    }, { observe: "response" }).pipe(take(1))
  }

  getUserVaultData(id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${environment.ip}shop/digital_coin/user/${id}/`, { observe: "response" }).pipe(take(1))
  }

  getPaymentDetailsForOrder(id: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${environment.ip}shop/digital_coin/order/${id}/payments/`, { observe: "response" }).pipe(take(1))
  }

  createNewDelivery(payload: { commodity: string; weight: string; phone: string }): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.ip}shop/digital_coin/order/new_delivery/`, payload, { observe: "response" }).pipe(take(1))
  }

  getBankAccounts(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${environment.ip}shop/offlineBankAccounts/0/get_accounts/`, { observe: "response" }).pipe(take(1))
  }

  cancelBuyOrder(payload: {
    "deduction": number,
    "amount_payable": number,
    "payment_mode": number,
    "bank": string | null,
    "payment_date": string,
    "cancel_date": string,
    "comments": string
  }, id: string): Observable<HttpResponse<any>> {
    return this.http.post<HttpResponse<any>>(`${environment.ip}shop/digital_coin/order/${id}/cancel/`, payload, { observe: "response" }).pipe(take(1))
  }

  getUserFromPhone(phone: string): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${environment.ip}shop/users/get_user_data/?phone=${phone}`, { observe: "response" }).pipe(take(1))
  }

  downloadDigitalCoinOrder(fd: FormData) {
    return this.http.post(`${environment.uploaderIp}shop/digital_coin/order/download/`, fd);
  }

  digitalCoinReport(page: number, from_date: string, to_date: string, search: string, filter: string, settlement_id: string = '') {
    var online = '';
    var selected_types = filter.split(',');
    selected_types.forEach((element: any, index: any) => {
      if (element == '999') {
        online = 'true';
        selected_types.splice(index, 1);
      }
    });
    console.log(online);
    return this.http.get<Rest<reportDgCoinData>>(`${environment.ip}shop/digital_coin/report/`, {
      params: {
        from_date: from_date,
        to_date: to_date,
        page: page,
        search: search,
        settlement_id: settlement_id,
        payment_mode__in: online ? 4 : `${selected_types.toString()}`,
      },
    });
  }

  getAnalytics(from: string, to: string): Observable<HttpResponse<any>> {
    return this.http.get<Rest<reportDgCoinData>>(`${environment.ip}shop/google_analytics_digital_coin/?from_date=${from}&to_date=${to}`,
      { observe: "response" })
  }

  getUserVaultDataForShop(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${environment.ip}shop/digital_coin/vault/`, { observe: "response" }).pipe(take(1))
  }

  getOverallStats(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(`${environment.ip}shop/digital_coin/order/overall_stats/`, { observe: "response" }).pipe(take(1))
  }


  getAnalyticsTable(page: number, limit: number): Observable<HttpResponse<any>> {
    const url = `${environment.ip}shop/digital_coin/vault/user_data/?page=${page}&limit=${limit}`;
    return this.http.get<HttpResponse<any>>(url, { observe: "response" }).pipe(take(1));
  }
  

  bookingsGuard(): boolean {
    if (localStorage.getItem("digiStatus") === 'REGISTERED') return true
    else if (localStorage.getItem("digiStatus") === 'NOT_REGISTERED') {
      this.router.navigate(['/digital-coin/setup'])
    }
    return false
  }

  setupGuard(): boolean {
    if (localStorage.getItem("digiStatus") === 'NOT_REGISTERED') return true
    else if (localStorage.getItem("digiStatus") === 'REGISTERED') {
      this.router.navigate(['/digital-coin/bookings'])
    }
    return false
  }

}
