export class RateModel {
    material!:string;
    rate!:string;


}

export class RateFinal{
    rates!: Array<RateModel>;
}

export class RateTable{
    id!:string;
    date!:string;
    time!:string;
    gold22!:string;
    gold18!:string;
    silver!:string;
    platinum!:string;
}
// new Rates
export class DefaultRates {
    gold22k?: number | null;
    gold18k?: number | null;
    silver?: number | null;
    platinum?: number | null;
    gold_others?: number | null;
    gold24k?: number | null;
    silver999?: number | null;

    selected!:number;
    constructor(data: Partial<DefaultRates> = {}) {
      Object.assign(this, data);
    }
    toKeyValueArray(): { key: string, value: string }[] {
        return Object.entries(this)
          .filter(([key, value]) => value !== null && value !== '0')
          .map(([key, value]) => ({ key, value }));
      }
  }

  export class Purity {
    id!:number; //unique
    purity_name!: string;
    default_gold!: string; // Match the data type with DefaultRates
    rate!:string;
    type!:string;
    constructor(purity_name: string, default_gold: string,id:number,rate:string) {
      this.id=id;
      this.purity_name = purity_name;
      this.default_gold = default_gold;
      this.rate=rate;
    }
  }

