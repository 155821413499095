import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from '../models/message';
import { SIPPlan } from '../models/plan';
import { Rest } from '../models/rest_model';

@Injectable({
  providedIn: 'root'
})
export class PlanServiceService {
  plan_url = environment.ip+"shop/savings-plan/";

  constructor(private http:HttpClient) { }

  getPlans(){
    return this.http.get<Rest<SIPPlan>>(this.plan_url);
  }
  createSavingsPlan(fd:FormData){
    return this.http.post<ResponseMessage>(this.plan_url+"create_plan/",fd);
  }
  editSavingsPlan(fd:FormData,id:string){
    
    return this.http.post<ResponseMessage>(this.plan_url+id+"/edit_plan/",fd);
  }
  
  getPlanById(id:string){
    return this.http.get<SIPPlan>(this.plan_url+id+"/");

  }
  deletePlanById(id:string){
    return this.http.post<ResponseMessage>(this.plan_url+id+"/delete_plan/",{});

  }
  pausePlanById(id:string){
    return this.http.post<ResponseMessage>(this.plan_url+id+"/pause_plan/",{});

  }
  hasSubscription(id:string){
    return this.http.post<ResponseMessage>(this.plan_url+id+"/plan_exist/",{});

  }
  activatePlanById(id:string){
    return this.http.post<ResponseMessage>(this.plan_url+id+"/activate_plan/",{});
   
  }
  
}
