<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:50vh;">

<div class="head container-fluid side">
  <div *ngIf="show_cancel">
    <div class="row mt-5">
      <div
        class="col-md-12 card"
        style="
          height: 110px;
          width: 95.5%;
          margin-left: 22px;
          outline: none;
          border: none;
        "
      >
        <div class="row mt-3">
          <div class="col-md-2 mt-3">
            <h4 class="emiCardTitle text-center block_title">
              {{is_booking_cancel?'Booking ID':'Plan ID'}}
              <span>
                <span>
                  <h3 class="mt-2 emiCardValue block_data">
                    {{ planID }}
                  </h3>
                </span>
              </span>
            </h4>
          </div>
          <div class="col-md-2 mt-3">
            <h4 class="emiCardTitle text-center block_title">
              Customer Name
              <span>
                <span>
                  <h3 class="mt-2 emiCardValue block_data">
                    {{ name }}
                  </h3>
                </span>
              </span>
            </h4>
          </div>
          <div class="col-md-2 mt-3">
            <h4 class="emiCardTitle text-center block_title">
              Phone
              <span>
                <span>
                  <h3 class="mt-2 emiCardValue block_data">
                    {{ phone }}
                  </h3>
                </span>
              </span>
            </h4>
          </div>
          <div class="col-md-2 mt-3">
            <h4 class="emiCardTitle text-center block_title">
              {{is_booking_cancel?"Advance Paid":"Plan Amount"}}
              <span>
                <span>
                  <h3 class="mt-2 emiCardValue block_data">
                    ₹ {{ plan_amount  | indianNumeration}}
                  </h3>
                </span>
              </span>
            </h4>
          </div>
          <div class="col-md-2 mt-3">
            <h4 class="emiCardTitle text-center block_title">
              {{is_booking_cancel? "Booking Rate/gm" :"Installment Paid"}}
              <span>
                <span>
                  <h3 class="mt-2 emiCardValue block_data">
                    {{is_booking_cancel?(installment_paid | indianNumeration)+' gm' : installment_paid  }}
                  </h3>
                </span>
              </span>
            </h4>
          </div>
          <div class="col-md-2 mt-3">
            <h4 class="emiCardTitle text-center block_title">
              {{is_booking_cancel?"Booking Weight" : "Weight/Installment"}}
              <span>
                <span>
                  <h3 class="mt-2 emiCardValue block_data">
                    {{is_booking_cancel ? weight_per_installment + ' gm' : weight_per_installment}}
                  </h3>
                </span>
              </span>
            </h4>
          </div>
          <!-- <div class="col-md-2 mt-3" style="width: 12.499999995%;border-left: 1px solid #DFDFDF; height: 60px;">
                      <h4 class="emiCardTitle text-center " >
                        Total Weight
                        <span>
                          <span>
                            <h3 class="mt-2 emiCardValue"> xyz </h3>
                          </span>
                        </span>
                      </h4>
                    </div>
                    <div class="col-md-1 mt-3" style="width: 12.499999995%;border-left: 1px solid #DFDFDF; height: 60px;">
                      <h4 class="emiCardTitle text-center " >
                        Plan Status
                        <span>
                          <span>
                            <h3 class="mt-2 emiCardValue">xyz  </h3>
                          </span>
                        </span>
                      </h4>
                    </div> -->
        </div>
      </div>
    </div>
    <div class="row" style="margin-left: 4px; margin-top: 3%">
      <div class="col-md-3">
        <label class="label_cancel">Amount Collected</label>
        <input
          class="form-control text_input_css mt-3"
          [(ngModel)]="amount_collected"
          [disabled]="true"
        />
      </div>
      <div class="col-md-3">
        <label class="label_cancel">Deductions</label>
        <input
          class="form-control text_input_css mt-3"
          [disabled]="editCancel"
          (input)="amountPayable()"
          type="text"
          [(ngModel)]="deductions"
          appTwoDigitDecimaNumber

        />
      </div>
      <div class="col-md-3">
        <label class="label_cancel">Amount Payable</label>
        <input
          class="form-control text_input_css mt-3"
          [disabled]="true"
          [(ngModel)]="final_amount"
        />
      </div>
      <div class="col-md-3">
        <label class="label_cancel">Cancel Date</label>

        <input
          type="date"
          [disabled]="editCancel"
          [(ngModel)]="cancel_date"
          class="form-control text_input_css mt-3"
        />
      </div>
      <div class="row">
        <div class="col-md-3" style="margin-left: 5px; margin-top: 38px">
          <h3 class="Mode">Payment Mode</h3>
        </div>
      </div>
      <div
        class="row mt-2 p-4 ms-2"
        style="background: #ffffff; width: 96.5%; border-radius: 6px"
      >
        <div class="col-md-3">
          <label class="label_cancel">Payment Mode</label>
          <select class="form-select mt-3" (change)="defaultBank($event.target)"  [(ngModel)]="p_mode" #focus_deductions>
            <option value="7">Cash</option>
            <option value="3">NEFT/RTGS</option>
            <option value="0">UPI/IMPS</option>
            <option value="6">Cheque</option>
            <option value="9">Against Sale</option>
          </select>
        </div>
        <div class="col-md-3 ms-2">
          <label class="label_cancel">Bank</label>
          <select
            [disabled]="p_mode == 7 || p_mode == 4 || p_mode==9"
            class="form-select mt-3"
            [(ngModel)]="bank_"
          >
            <option
              *ngFor="let account of account_list.bank_accounts"
              [value]="account.id"
            >
              {{ account.number }}
            </option>
          </select>
        </div>
        <div class="col-md-3 ms-2">
          <label class="label_cancel">Payment Date</label>
          <input
            type="date"
            [(ngModel)]="payment_date"
            class="form-control text_input_css mt-3"
          />
        </div>
        <div class="row mt-2">
          <div class="col-md-9 mt-4">
            <label class="label_cancel">Comments</label>
            <input
              class="form-control text_input_css mt-3 comments"
              [(ngModel)]="comments"
            />
          </div>
        </div>
        <div class="row">
          <button
            mat-raised-button
            color="primary"
            (click)="editCancel ? editCancelPlan() : openWarning(warning_modal)"
            class="nextButton mt-5"
          >
           {{is_booking_cancel?"Cancel Booking":"Proceed"}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="account_close_voucher" #printableContent>
    <div class="row ms-2 mt-3">
      <div class="col-md-5">
        <h3 class="back_title">
          <mat-icon (click)="revertToCancel()" class="right_back"
            >arrow_back_ios</mat-icon
          >Account Cancel Voucher
        </h3>
      </div>
    </div>
    <div
    id="printable-content"
      class="row mt-3 ms-3 mb-5 p-4"
      style="background-color: #ffffff; width: 96.5%; border-radius: 6px"
    >
      <h5 class="accountClose">Account Close Voucher</h5>
      <hr width="99%" style="border: 1px solid #dfdfdf" />
      <div class="row">
        <div class="col-md-4">
          <div class="row mt-1 mb-2">
            <div class="col-md-4">
              <span class="vr" style="width: 50px">Vr No.</span>
              <h4 class="det mt-1">{{ vr_no }}</h4>
            </div>
            <div class="col-md-4">
              <span class="vr" style="width: 60px">Plan ID</span>
              <h4 class="det mt-1">{{ planID }}</h4>
            </div>
            <div class="col-md-4">
              <span class="vr" style="width: 120px">Cancelled Date</span>
              <h4 class="det mt-1" style="width: 140px">{{ cancel_date }}</h4>
            </div>
          </div>
        </div>
      </div>
      <hr width="99%" style="border: 1px solid #dfdfdf" />
      <div class="row">
        <div class="col-md-2">
          <span class="vr" style="width: 120px">Cash Paid To</span>
          <br />
          <span class="addre"
            >{{ planID }}
            <br />
            {{ data_of_cancelled_plan.address }}<br />
            {{ data_of_cancelled_plan.phone }}
          </span>
        </div>
      </div>
      <div class="row mt-3">
        <span class="vr">Note</span>
        <br />
        <span class="para">
          For my personal reasons, I am collecting the cash back instead of
          buying jewellery.</span
        >
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="row mt-4">
            <div class="col-md-7">
              <div class="vr">Amount Collected</div>

              <div class="vr mt-2">Deduction</div>

              <div class="vr mt-2">Payable Amount</div>
            </div>
            <div class="col-md-5">
              <div class="price">
                {{ data_of_cancelled_plan.amount_collected }}
              </div>

              <div class="price mt-2">
                {{ data_of_cancelled_plan.deduction }}
              </div>

              <div class="price mt-2">
                {{ data_of_cancelled_plan.amount_payable }}
              </div>
            </div>
          </div>
        </div>
        <div class="note mt-4">
          Payment Made by
          {{
            p_mode == "7"
              ? "Cash"
              : p_mode == "3"
              ? "NEFT/RTGS"
              : p_mode == "4"
              ? "Online Payment"
              : p_mode == "0"
              ? "UPI/IMPS"
              : p_mode == "6"
              ? "Cheque"
              : ""
          }}
        </div>

        <div style="display: flex; justify-content: center; margin-top: 36px" id="two_buttons">
          <div style="display: flex; justify-content: space-around">
            <button
              (click)="printDiv()"
              id="print"
              mat-raised-button
              color="primary"
              class="nextButton1"
            >
              Print
            </button>
            <button
              (click)="openModel(success)"
              mat-raised-button
              color="primary"
              class="nextButton1"
            >
              Finish
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<ng-template #warning_modal let-modal>
  <div class="myModal">
    <div class="modal-content p-4" style="width: 100%">
      <div class="modal-header" style="border: 0; height: 0">
        <h1 class="modal-title fbold"></h1>
        <button
          type="button"
          class="close closeButton btn"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
          <img
            src="assets/images/errorsign.svg"
            class="img-responsive img-fluid"
          />

          <h1 class="fbold mt-2">Warning!</h1>

          <p
            class="greyColor"
            style="
              font-family: 'Rubik';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;

              text-align: center;

              color: #606060;
            "
          >
            Are you sure you want to proceed with canceling the plan?
          </p>

          <button
            (click)="(is_booking_cancel? cancelBooking(success) :cancelPlan()); modal.dismiss('Cross click')"
            type="button"
            class="btn pause_btn mt-3"
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #success let-modal>
  <div class="myModal">
    <div class="modal-header">
      <h1 class="modal-title"></h1>

      <button
        type="button"
        style="border: none; border-top: none"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <img src="assets/images/tickmark.svg" />

        <h3
          class="bold_title mt-3"
          style="margin-left: 10px; font-weight: 700; font-size: 24px"
        >
        {{is_booking_cancel?"Booking":"Plan"}} Cancelled Successfully
        </h3>

        <p class="mt-1"> {{is_booking_cancel?"Your Booking":"Account"}} plan has been cancelled successfully.</p>

        <div class="col mt-4">
          <button
            (click)="modal.dismiss('Cross click')"
            mat-raised-button
            color="primary"
            class="padBottom mt-2 mb-1"
            style="
              background: #4070f4;
              box-shadow: 0px 8px 16px rgba(32, 10, 130, 0.06);
              border-radius: 12px;
              width: 295px;
              height: 50px;
            "
          >
            Continue
          </button>
        </div>
        <p class="pt-1"></p>
      </div>
    </div>
  </div>
</ng-template>
