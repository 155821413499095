<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:50vh;">

<div class="head side" >
  <div class="container-fluid">
    <!-- <div class="row" >
      <div class="col-md-3">
        <h3 class="bank_title">Payments Methods-Offline</h3>
      </div>
    </div>

    <div class="card mx-5 mt-5" style="width: 18rem">
      <div class="card-body">
        <h2 class="card-title mb-4">Payment Type</h2>
        <h3 class="card-subtitle mb-2 text-muted">Cash</h3>
        <h3 class="card-subtitle mb-2 text-muted">Card</h3>
        <h3 class="card-subtitle mb-2 text-muted">IMPS/UPI</h3>
        <h3 class="card-subtitle mb-2 text-muted">RTGS/NEFT</h3>
        <h3 class="card-subtitle mb-2 text-muted">Cheque</h3>
      </div>
    </div> -->

    <div class="row">
      <div class="col-md-3 mt-5">
        <h3 class="bank_title">Bank Accounts</h3>
      </div>

      <div class="col-md-6"></div>
      <div class="col-md-3 mt-5">
        <button
        id="add_bank"
          class="btn btn-primary addbutton"
          mat-raised-button
          color="primary"
          (click)="actionsCheck(8) ? (callMe(showadd) && disbale_opt()) :null;"
          style="background: #4070F4;"
        >
          Add
        </button>
      </div>
      <div class="row">
        <div *ngIf="!dataSource" class="col-md-11 mt-4">
          <div class="card mx-3">
            <div class="card-body mx-2">
               No Data Exists! Click on add to create.
            </div>
          </div>
        </div>
        <div class="row tablePadding mt-3">
          <div class="col-md-11 me-5 newtable">
            <div class="mat-elevation table-responsive pb-3" style="border-radius: 10px;">
              <table mat-table [dataSource]="dataSource" >
                <ng-container matColumnDef="payment_code">
                  <th mat-header-cell *matHeaderCellDef>Payment Code</th>
                  <td mat-cell *matCellDef="let element" >
                    {{  element.payment_code }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Account Name</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>

                <ng-container matColumnDef="number">
                  <th mat-header-cell *matHeaderCellDef>A/C No</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.number }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="ifsc">
                  <th mat-header-cell *matHeaderCellDef>IFSC</th>
                  <td   mat-cell *matCellDef="let element">{{ element.ifsc }}</td>
                </ng-container>

                <ng-container matColumnDef="branch_name" class="branch">
                  <th mat-header-cell *matHeaderCellDef>Branch</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.branch_name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="is_enabled">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="btn-group btngroupnew">
                      <button
                       
                        (click)="actionsCheck(11) ? toggle(element.id, element.number):null"
                        [ngClass]="
                          element.is_enabled === false
                            ? 'btn btn-danger'
                            : 'btn btn-default'
                        "
                        [id]="element.id"
                        style="border-radius: 6px;"
                      >
                        Disabled
                      </button>
                      <button

                        (click)="actionsCheck(11) ?togglenew(element.id, element.number):null"
                        [ngClass]="
                          element.is_enabled === true
                            ? 'btn btn-success'
                            : 'btn btn-default'
                        "
                        [id]="element.id + 50"
                        style="border-radius: 6px;"
                      >
                        Active
                      </button>

                      <!-- <button
                        class="newbi"
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                      >
                        <span
                          id="boot-icon"
                          class="bi bi-three-dots-vertical mt-1"
                          style="
                            font-size: 25px;
                            -webkit-text-stroke-color: rgb(0, 0, 0);
                          "
                        ></span>
                      </button> -->

                
                    </div>
                    <!-- <mat-menu #menu="matMenu" class="matmenu">
                      <button
                        (click)="
                          showEditAccount(element.id); editAccount(showadd);"
                        mat-menu-item
                      >
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>
                      <button (click)="showEditAccount(element.id);callme(delete);deleteNA(deletenew)" mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span> Delete </span>
                      </button>
                    </mat-menu> -->
                  </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th  mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element">
                    <button
                    class="newbi"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                  >
                    <span
                      id="boot-icon"
                      class="bi bi-three-dots-vertical mt-1"
                      style="
                        font-size: 25px;
                        -webkit-text-stroke-color: rgb(0, 0, 0);
                      "
                    ></span>
                  </button>
                  <mat-menu #menu="matMenu" class="matmenu">
                    <button
                      (click)="
                        actionsCheck(10) ?showEditAccount(element.id)&& editAccount(showadd):null;"
                      mat-menu-item
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button>
                    <button (click)=" actionsCheck(13) ?showEditAccount(element.id) &&callMe(delete)&&deleteNA(deletenew):null" mat-menu-item>
                      <mat-icon>delete</mat-icon>
                      <span> Delete </span>
                    </button>
                  </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 mt-2">
        <h3 class="bank_title">POS Accounts</h3>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-3 mt-2">
        <button
          mat-raised-button
          color="primary"
          class="btn btn-primary addbutton"
          style="background: #4070F4;"
          (click)="actionsCheck(9) ?  showPos(showpos) :null"
        >
          Add
        </button>
      </div>
      <div class="row">
        <div *ngIf="!dataSource" class="col-md-11 mt-4">
          <div class="card mx-3">
            <div class="card-body mx-2">
              No Data Exists! Click on add to create.
            </div>
          </div>
        </div>
        <div class="row tablePadding mt-3">
          <div class="col-md-5">
            <div class="mat-elevation table-responsive pb-3" style="border-radius: 10px;">
              <table mat-table [dataSource]="dataSourcePOS" >
                <ng-container matColumnDef="payment_code">
                  <th style="width: 350px;" mat-header-cell *matHeaderCellDef>Payment Code</th>
                  <td style="width: 350px;" mat-cell *matCellDef="let element">
                    {{ element.number }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="is_enabled">
                  <th  mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="btn-group btn-toggle btngroupnew" >
                      <button
                        (click)="actionsCheck(12)?  toggle(element.id, element.number) : null"
                        [ngClass]="
                          element.is_enabled === false
                            ? 'btn btn-danger'
                            : 'btn btn-default'
                        "
                        [id]="element.id"
                        style="border-radius: 6px;"
                      >
                        Disabled
                      </button>
                      <button
                        (click)=" actionsCheck(12) ?togglenew(element.id, element.number) :null"
                        [ngClass]="
                          element.is_enabled === true
                            ? 'btn btn-success'
                            : 'btn btn-default'
                        "
                        [id]="element.id + 50"
                        style="border-radius: 6px;"
                      >
                        Active
                      </button>

                      <!-- <button
                        class="newbi"
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                      >
                        <span
                          id="boot-icon"
                          class="bi bi-three-dots-vertical mt-1"
                          style="
                            font-size: 25px;
                            -webkit-text-stroke-color: rgb(0, 0, 0);
                          "
                        ></span>
                      </button> -->
                      <!-- <mat-menu #menu="matMenu" class="matmenu">
                        <button (click)="showEditAccount(element.id);callme(delete);deleteNA(deletenew)" mat-menu-item>
                          <mat-icon>delete</mat-icon>
                          <span> Delete </span>
                        </button>
                      </mat-menu> -->
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th  mat-header-cell *matHeaderCellDef> </th>
                  <td   mat-cell *matCellDef="let element">
                    <button
                    class="newbi"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                  >
                    <span
                      id="boot-icon"
                      class="bi bi-three-dots-vertical mt-1"
                      style="
                        font-size: 25px;
                        -webkit-text-stroke-color: rgb(0, 0, 0);
                      "
                    ></span>
                  </button>
                  <mat-menu #menu="matMenu" class="matmenu">
                    <!-- <button
                      (click)="
                        showEditAccount(element.id); editAccount(showadd);"
                      mat-menu-item
                    >
                      <mat-icon>edit</mat-icon>
                      <span>Edit</span>
                    </button> -->
                    <button (click)="actionsCheck(13) ?  showEditAccount(element.id)&&callMe(delete)&&deleteNA(deletenew):null" mat-menu-item>
                      <mat-icon>delete</mat-icon>
                      <span> Delete </span>
                    </button>
                  </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsPos"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsPos"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <button class="btn btn-primary addbutton" (click)="callme(delete)">
        warning 1
      </button>
      <button class="btn btn-primary addbutton mt-5" (click)="callme(deletenew)">
        warning 2
      </button> -->
  </div>

  
  <div class="row">
    <div class="col-md-3 mt-2">
      <h3 class="bank_title">Settlement Account</h3>
    </div>
    <div class="col-md-6"></div>
    <div class="col-md-3 mt-2">

    </div>
    <div class="row">
      <div *ngIf="!dataSourceSettlement" class="col-md-11 mt-4">
        <div class="card mx-3">
          <div class="card-body mx-2">
            No Data Exists!
          </div>
        </div>
      </div>
      <div class="row tablePadding mt-3">
        <div class="col-md-5">
          <div class="mat-elevation table-responsive pb-3" style="border-radius: 10px;">
            <table mat-table [dataSource]="dataSourceSettlement">
              <ng-container matColumnDef="accountname">
                <th style="width: 350px;" mat-header-cell *matHeaderCellDef>Account Name</th>
                <td style="width: 350px;" mat-cell *matCellDef="let element">
                  {{ element.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="accountno">
                <th  mat-header-cell *matHeaderCellDef>Account No</th>
                <td style="width: 350px;" mat-cell *matCellDef="let element">
                  {{ element.number }}
                </td>
              </ng-container>
              <ng-container matColumnDef="ifsc">
                <th style="width: 350px;" mat-header-cell *matHeaderCellDef>IFSC</th>
                <td style="width: 350px;" mat-cell *matCellDef="let element">
                  {{ element.ifsc }}
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th  mat-header-cell *matHeaderCellDef> </th>
                <td   mat-cell *matCellDef="let element">
                  <button
                  class="newbi"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                >
                  <span
                    id="boot-icon"
                    class="bi bi-three-dots-vertical mt-1"
                    style="
                      font-size: 25px;
                      -webkit-text-stroke-color: rgb(0, 0, 0);
                    "
                  ></span>
                </button>
                <mat-menu #menu="matMenu" class="matmenu">
                  <!-- <button
                    (click)="
                      showEditAccount(element.id); editAccount(showadd);"
                    mat-menu-item
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button> -->
                  <button (click)="openContent(ask)"  mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span> Edit </span>
                  </button>
                </mat-menu>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsSettlement"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsSettlement"
              ></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #showadd let-modal>
    <div class="showadd">
    <div class="cardClass">
      <div class="modal-content p-3" style="width:730px">
      <div class="modal-header">
        <h1 class="modal-title" style="
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;">Add Bank Account</h1>
        <button
          type="button"
          class="close closeButton btn"
          (click)="modal.dismiss('Cross click')"
          aria-label="Close"
        >
          <span class="cross" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="width: 800px;">
        <div class="row" style="margin: 0;">
          <div class="col-md-5">
            <label
              ><h3 class="payment_title">
                Account Payment Code (Nick Name)*
              </h3></label
            >
            <input
              [disabled]="disable_options"
              type="name"
              class="form-control"
              placeholder=""
              id="paymentcode"
            />
          </div>

          <div class="col-md-5 ">
            <label><h3 class="payment_title">Account Name*</h3></label>
            <input
              type="text"
              class="form-control"
              placeholder=""
              id="accountname"

            />
          </div>
        </div>
        <div class="row mt-3" style="margin: 0;">
          <div class="col-md-5">
            <label><h3 class="payment_title">Account No*</h3></label>
            <input
              type="number"
              class="form-control rid"
              placeholder=""
              id="accountno"

            />
          </div>

          <div class="col-md-5">
            <label><h3 class="payment_title">IFSC*</h3></label>
            <input type="name" class="form-control" placeholder="" id="ifsc" />
          </div>
        </div>
        <div class="row mt-3" style="margin: 0;">
          <div class="col-md-5">
            <label><h3 class="payment_title">Branch*</h3></label>
            <input
              type="name"
              class="form-control"
              placeholder=""
              id="branch"

            />
          </div>
        </div>
        </div>
<div class="row" style="margin: 0;">
  <div class="col-md-3">

  </div>
  <div class="col-md-2 ms-2">
    <button
    (click)="commonFunction();modal.dismiss('Cross click')"
    style="background: #4070F4;
    border-radius: 10px;cursor: pointer;"
    class="btn btn-primary mt-4 mb-3 addbutton1" style="width: 280px;"
  >
    Save
  </button>
  </div>
  <!-- <div class="col-md-5">

</div> -->
</div>
       
      </div>
    </div>
  </div>
  </ng-template>

  <!-- <ng-template #showadd  let-modal>
    <div class="myModal p-5 cardClass">
      <div class="modal-header">
        <h1 class="modal-title fbold">Add Bank Account</h1>
        <button
          type="button"
          class="close closeButton btn"
          (click)="modal.dismiss('Cross click')"
          aria-label="Close"
        >
          <span class="cross" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-5">
            <label
              ><h3 class="payment_title">
                Account Payment Code (Nick Name)*
              </h3></label
            >
            <input [disabled]="disable_options" type="name" class="form-control" placeholder="" id="paymentcode" />
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-5 mt-4">
            <label><h3 class="payment_title">Account Name*</h3></label>
            <input type="name" class="form-control" placeholder="" id="accountname"/>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-5">
            <label><h3 class="payment_title">Account No*</h3></label>
            <input type="number" class="form-control" placeholder=""  id="accountno"/>
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-5">
            <label><h3 class="payment_title">IFSC*</h3></label>
            <input type="name" class="form-control" placeholder=""  id="ifsc"/>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-5">
            <label><h3 class="payment_title">Branch*</h3></label>
            <input type="name" class="form-control" placeholder="" id="branch" />
          </div>
        </div>

        <button (click)="commonFunction()" class="btn btn-primary mt-4 addbutton1">Save</button>
      </div>
    </div>
  </ng-template> -->
</div>
</div>
<ng-template #showpos let-modal>
  <div class="cardClass1" >
    <div class="modal-content p-2" style="width:350px">
    <div class="modal-header ">
      <h1 class="modal-title mt-2 " style="
        font-family: Rubik;
font-size: 24px;
font-weight: 500;
">Add POS Account</h1>
      <button
        type="button"
        class="close closeButton btn"
        (click)="modal.dismiss('Cross click')"
        aria-label="Close"
      >
        <span class="cross" aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="height: 200px;">
     
          <label><h3 class="payment_title_POS">Payment POS Code*</h3></label>
          <input
            type="name"
            class="form-control"
            id="paymentcodepos"
            placeholder=""
          />

      <div class="row">
        <div class="col-3"></div>
        <div class="col-1">
          <button (click)="createPOS();" class="btn btn-primary  mt-4 addbutton1">
            Save
          </button>
        </div>
       

      </div>
    
    </div>
  </div>
  </div>
</ng-template>

<ng-template #ask let-modal>
  <div class="myModal">
    <div class="modal-content p-3"style="width:100%" >
    <div class="modal-header"  style="border: 0;height: 0;">
      <h1 class="modal-title fbold"></h1>
      <button
        type="button"
        class="close closeButton btn"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <img src="assets/images/errorsign.svg" class="img-responsive img-fluid" />

        <h1 class="fbold" >Warning!</h1>

        <p class="greyColor">
          You don’t have permission to change.<br>Kindly contact jewellers.live support to change your <br> bank account.
        </p>


      </div>
    </div>
    </div>
  </div>
</ng-template>


<ng-template #delete let-modal>
  <div class="myModal">
    <div class="modal-content p-3"style="width:100%" >
    <div class="modal-header"  style="border: 0;height: 0;">
      <h1 class="modal-title fbold"></h1>
      <button
        type="button"
        class="close closeButton btn"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <img src="assets/images/errorsign.svg" class="img-responsive img-fluid" />

        <h1 class="fbold" >Warning!</h1>

        <p class="greyColor">
          This Action cannot be undone ! <br>Are you sure you want to Delete ?
        </p>

        <button (click)="deleteRow();modal.dismiss('Cross click')" type="button" class="btn btn-primary mt-3 addbutton1" style="/* Blue */

        background: #4070F4;
        box-shadow: 0px 8px 16px rgba(32, 10, 130, 0.06);
        border-radius: 12px;
        width: 255px;">
          Delete
        </button>
      </div>
    </div>
    </div>
  </div>
</ng-template>

<ng-template #deletenew let-modal>
  <div class="myModal">
    <div class="modal-content p-3"style="width:100%" >
    <div class="modal-header" style="border: 0;height: 0;">

      <h1 class="modal-title fbold"></h1>
      <button
        type="button"
        class="close closeButton btn"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <img src="assets/images/errorsign.svg" class="img-responsive img-fluid" />

        <h1 class="fbold">Warning!</h1>

        <p class="greyColor">
          <span
            >One or more payments have been collected under this payment
            mode.<br
          /></span>
          Hence, this cannot be deleted, but you can disable it for future
          transactions.
        </p>

        <button  (click)="modal.dismiss('Cross click')" type="button" class="btn btn-primary mt-3 addbutton1" style="/* Blue */

        background: #4070F4;
        box-shadow: 0px 8px 16px rgba(32, 10, 130, 0.06);
        border-radius: 12px;
        width: 255px;">
          Dismiss
        </button>
      </div>
    </div>
  </div>
  </div>
</ng-template>
