import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, HostListener, Input, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Image from 'ngx-editor/lib/commands/Image';
import { ImageUploadService } from 'src/app/imageuploadservice.service';
import { DesignService } from './design.service';
import { data } from 'jquery';
import { Design, DesingModel } from 'src/app/models/design';
import { ResponseMessage } from 'src/app/models/message';
import { ToastrService } from 'ngx-toastr';
import { MatMenuTrigger } from '@angular/material/menu';
import { ViewOrderDataSource } from './view-order.dataSource';
import {MatPaginator} from '@angular/material/paginator';
import { PopUpManager } from 'src/app/manager/Popup';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchUser } from 'src/app/models/search-user';
import { Router } from '@angular/router';
import { TitleService } from 'src/app/services/title.service';
import * as moment from 'moment';
import { PageAccessService } from 'src/app/services/pageaccess.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { PurityService } from 'src/app/services/purity.service';
import { CreatePlanComponent } from 'src/app/create_launch_plans/create-plan/create-plan.component';
import { PopUpData } from 'src/app/templates/warning-popup/warning-popup/warning-popup.component';
import { AnimationOptions } from 'ngx-lottie';
import { CreateOrderComponent } from '../create-order/create-order.component';
import { NgSelectComponent } from '@ng-select/ng-select';


interface CustomFile extends File {
  progress?: number;
}

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.css'],
  providers: [ImageUploadService],
})
export class ViewOrderComponent {
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild('complete_order', { read: TemplateRef })
  complete_order!: TemplateRef<any>;
  @ViewChildren('checkboxRef') checkboxes!: any;
  @ViewChildren('checkBoxRefVendors') checkboxesVendors!: any;
  @ViewChild('VendorNameFocus') vendor_name_focus!: NgSelectComponent;

  @ViewChild('download', { read: TemplateRef }) vendor_report_download_template!: TemplateRef<any>;

  download_vendor_report_completion_popup: PopUpData = new PopUpData("Success! The report has been generated.", "", "", "Download Now")
  download_vendor_report_completion_lottie_file_path: AnimationOptions = { path: '/assets/lottie/download_animation.json' };

  vendor_report_download_url:string="";
  select_vendor_for_report!:any;

  selected_download_option :string = "ALL"
  selectedFilter: string = 'status'; // Default selected filter
  selected_vendors:any=[];
  filter_values:any=[];
  selected_item!:any;
  cancel_row_select!:any;
  current_date_validation!:string;
  fdate:string='';
  tdate:string=''
  from_date='';
  to_date = '';
  download_from_date='';
  download_to_date = '';
  email = '';
  files: File[] = [];
  deleteResponse: any=ResponseMessage;
  test_ng=[1,2,3,4,5,6,7,8,9];
  uploaded_designs:Design[]=[];
  selected_design?:Design;
  designModel:any=DesingModel;
  searchUsers:SearchUser[]=[];
  responseMessage!:ResponseMessage;
  show_upload=false;
  dataSource!:ViewOrderDataSource;
  popUpManager!: PopUpManager;
  show_search_spinner=false;
  shouldDismissMenu = false;
  searching_value="";
  search = "";
  vendor_list=[];
  filterd_vendor_list=[];
  vendorSearchText="";
  popupManager!: PopUpManager;
  allowed_actions: { [key: string]: boolean } = {};
  constructor(
    private modalService: NgbModal,
    private uploadService: ImageUploadService,
    private desingService:DesignService,
    private toastr:ToastrService,
    private snackBar: MatSnackBar,
    private router:Router,
    private titleService: TitleService,
    private authservice :PageAccessService,
    private purityService : PurityService,
    
  ) {
    this.popupManager = new PopUpManager(this.snackBar);
  }
  selectedImages: File[] = [];
  custom_item_id="";
  imagesWithProgress: { file: File; progress?: number }[] = [];
  displayedColumns: string[] = [
    // 'checkbox',
    'date',
    'customer',
    'order_no',
    'item',
    // 'col6',
    // 'pc',
    'weight',
    'design',
    'status',
    'estimate',
    'order_taken_by',
    'assigned',
    // 'comments',
    'action',

  ];

  focusVendorName()
{
  setTimeout(() => {
    this.vendor_name_focus.focus();
  }, 500);
}
  ngOnInit(): void {
      this.titleService.setTitle('Orders');
      this.allowed_actions=this.authservice.allowedActions;
      this.dataSource = new ViewOrderDataSource(this.desingService);
      if(localStorage.getItem('orderFilterValues')!=null)
        {
          try {
            this.filter_values = localStorage.getItem('orderFilterValues');
            this.filter_values = this.filter_values.split(",");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
            this.filter_values = []; 
        }        }
        else{
          this.filter_values=['0','1','2']
        }
      this.dataSource.loadData(this.paginator.pageIndex, this.search,this.filter_values,this.selected_vendors);
      this.popUpManager = new PopUpManager(this.snackBar);
      setTimeout(() => {
        this.selectedFilter='status'; // Default selected filter
      }, 100);
      this.getVendors();
      this.current_date_validation=this.getCurrentDate();
      
    
  }
  handlePage(event: any) {
    // Store pagination state in local storage whenever the page changes
    localStorage.setItem('orderPaginationState', JSON.stringify({
      pageIndex: event.pageIndex,
      pageSize: event.pageSize
    }));
    console.log(event.pageIndex);
    // Your pagination logic here
  }
  getVendors()
  {
    this.desingService.getVendorsForFilter().subscribe((data:any)=>
    {
      this.vendor_list=data.vendors;
      console.log(this.vendor_list)
    })
    setTimeout(() => {
      this.filterVendors()
    }, 500);
    
  }
  filterVendors()
  {
    this.filterd_vendor_list = this.vendor_list.filter((user:any) =>
      user['name'].toLowerCase().includes(this.vendorSearchText.toLowerCase())
    );
    
  }

  toggleFilter(filterType: string): void {
    setTimeout(() => {
      this.selectedFilter = filterType;
    }, 100);
    if (filterType === 'status') {
    } else if (filterType === 'vendor') {
      this.selectedFilter = 'vendor'
    }
    this.focus('vendor_search')
  }
  checkAllFilters(event:any)
  {

       if(event.checked)
       {
          if (this.checkboxes) {
            this.checkboxes._results.forEach((checkbox:any) => {
            checkbox.checked = true;
            // Perform any other logic you want on checking all checkboxes
            this.filter_values.push(checkbox.value);
          });
        }
       }
       else{
         this.checkboxes._results.forEach((checkbox:any) => {
            checkbox.checked = false;
            this.filter_values=[]
            // Perform any other logic you want on checking all checkboxes
          });
       }
  }
  checkVendorsAllFilters(event:any)
  {
    if (event.checked)
    {
      if (this.checkboxesVendors) {
        this.checkboxesVendors._results.forEach((checkbox:any) => {
        checkbox.checked = true;
        this.selected_vendors.push(checkbox.value);
        // Perform any other logic you want on checking all checkboxes
      });
    }
    }
    else{
      this.checkboxesVendors._results.forEach((checkbox:any) => {
        checkbox.checked = false;
        this.selected_vendors.pop(checkbox.value);
        this.selected_vendors=[]
        // Perform any other logic you want on checking all checkboxes
      });
    }
  }
  onFilterLoad()
  {
    this.selectedFilter='status';
  }

  storeFilterValues(event:any)
  {  if (event.checked){this.filter_values.push(event.source.value);}
    else {this.filter_values.splice(this.filter_values.indexOf(event.source.value),1);}
  console.log(this.filter_values)}
  storeVendorID(event:any)
  {if (event.checked){this.selected_vendors.push(event.source.value);}
  else {this.selected_vendors.splice(this.selected_vendors.indexOf(event.source.value),1);}}

  saveFilter()
  {
    this.paginator.pageIndex=0;
    this.dataSource.loadData(0, this.search,this.filter_values,this.selected_vendors);
   }

  ngAfterViewInit(): void {
    this.dataSource.counter$.subscribe((count) => {
      this.paginator.length = count;
    });
    this.paginator.page.subscribe(() => {

      this.dataSource.loadData(
        this.paginator.pageIndex,
        this.search,
        this.filter_values,
        this.selected_vendors
        );
    });

  }

  openCreateVendor(content:any,selected_item_id:number){
    this.selected_item=selected_item_id;
    this.modalService.open(content, { centered: true,size:'lg' , modalDialogClass: 'vendor_modal', });
    setTimeout(() => {
      (<HTMLInputElement>document.getElementById('mobile_number')).focus();
    }, 100);
  }

  createVendor(){
    var code_name = (<HTMLInputElement>document.getElementById('code_name')).value;
    var full_name = (<HTMLInputElement>document.getElementById('full_name')).value;
    var mobile_no = (<HTMLInputElement>document.getElementById('mobile_number')).value;
    var email = (<HTMLInputElement>document.getElementById('vendor_email')).value;
    if(code_name==undefined || code_name=="" || code_name.trim().length === 0){
      this.toastr.info('', "Please Provide Valid Code Name", {
        positionClass: 'toast-bottom-right',
      });
    }else if(full_name==undefined || full_name=="" || full_name.trim().length === 0){
      this.toastr.info('', "Please Provide Valid Name", {
        positionClass: 'toast-bottom-right',
      });
    }else if(mobile_no==undefined || mobile_no=="" || mobile_no.trim().length === 0 || mobile_no.length!=10){
      console.log(mobile_no)
      this.toastr.info('', "Please Provide Valid Mobile Number", {
        positionClass: 'toast-bottom-right',
      });
    }else{
      var fd = new FormData();
      fd.append("item_id",this.selected_item)
      fd.append("code_name",code_name);
      fd.append("name",full_name);
      fd.append("mobile",mobile_no);
      fd.append("email",email);
      this.desingService.postVendor(fd).subscribe(
        (data) => {
          this.responseMessage = data;
          this.toastr.success('', this.responseMessage.message, {
            positionClass: 'toast-bottom-right',
          });
          this.modalService.dismissAll();
          this.resetAndDisplayTable()
        },
        (error: any) => {
          this.toastr.info(
            '',
            error.error.message,
            { positionClass: 'toast-bottom-right' }
          );
        }
      );

    }


  }

  preventEvent(event:any)
  {
    event.preventDefault();
  }

  searchVendor(event:any,custom_id:any,actual_event:any=""){
    console.log("wokg")
    this.preventEvent(actual_event)
    var search=event.value;

    if (event=='')
    {
      this.show_search_spinner=true;
      console.log(this.custom_item_id)
      this.desingService.getVendors(custom_id,'').subscribe((data)=>{
        this.searchUsers = data.response;
        this.show_search_spinner=false;
        console.log(this.searchUsers);
      })
    }
    else{
      if(search.length >=2){
        this.show_search_spinner=true;
        this.desingService.getVendors(this.custom_item_id,search).subscribe((data)=>{
          this.searchUsers = data.response;
          this.show_search_spinner=false;
          console.log(this.searchUsers);
        })
      }
    }
    this.custom_item_id=custom_id;


    this.focus('search-input');
  }

  assignVendor(custom_id:any,vendor_id:any){
    console.log("called assing");
    this.custom_item_id = custom_id;
    var fd = new FormData();
    fd.append("item_id",this.custom_item_id);
    fd.append("vendor_id",vendor_id);
    this.desingService.assignVendor(fd).subscribe(
      (data) => {
        this.responseMessage = data;
        this.toastr.success('', this.responseMessage.message, {
          positionClass: 'toast-bottom-right',
        });
        this.modalService.dismissAll();
        this.resetAndDisplayTable()
        this.searchUsers=[];
      },
      (error: any) => {

        this.toastr.info(
          '',
          error.error.message,
          { positionClass: 'toast-bottom-right' }
        );
      }
    )



  }
  // cancel order
  openCancelOrder(content:any,row:any)
  {

    return this.router.navigate(['/dashboard/cancel_order/',{ code: row.code }])
    this.cancel_row_select=row;
   this.modalService.open(content,{centered:true})
    setTimeout(() => {
      (<HTMLInputElement>document.getElementById('cancel_order_no')).innerHTML=row.code;
      (<HTMLInputElement>document.getElementById('cancel_customer_name')).innerHTML=row.name;
    }, 100);

  }
  // send follow notification
  followRequest(row:any)
  {
    this.desingService.followUpRequest(row.id).subscribe((data:any)=>
    {
      this.toastr.success('',data.message,{
        positionClass: 'toast-bottom-right',
      });
    })
  }

  cancelOrder(id:any){

    this.custom_item_id = id;
    var fd = new FormData();
    fd.append("item_id",this.custom_item_id);
    this.desingService.cancelOrder(fd).subscribe(
      (data) => {

        this.responseMessage = data;
        this.toastr.success('', this.responseMessage.message, {
          positionClass: 'toast-bottom-right',
        });
        this.ngOnInit();

      },
      (error: any) => {

        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    )
  }

  removeVendor(custom_id:any,vendor_id:any){
    console.log("called remove");
    this.custom_item_id = custom_id;
    var fd = new FormData();
    fd.append("item_id",this.custom_item_id);
    fd.append("vendor_id",vendor_id);
    this.desingService.removeVendor(fd).subscribe(
      (data) => {
          
        this.responseMessage = data;
        this.toastr.success('', this.responseMessage.message, {
          positionClass: 'toast-bottom-right',
        });
        this.modalService.dismissAll();
        this.ngOnInit();
        this.searchUsers=[];

      },
      (error: any) => {
   
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    )


    
  }

  menuItemClicked(event:any,type:any,item_id:any,user_id:any) {
    console.log("this is clicked");
    event.stopPropagation();

    if(type=="search"){
    event.stopPropagation();


    }else if(type=="assign"){
      this.assignVendor(item_id,user_id);

    }else if(type=="remove"){
      this.removeVendor(item_id,user_id);
    }
  }


  openVendor(content:any){
    this.modalService.open(content);
  }

  toggleCheckbox(event: any, element: any): void {
    element.selected = event.checked;
  }
  // status dropdown
  dropdownOptions = [
    { value: 0, label: 'Unassigned', color: '#F03738',background:"rgba(240, 55, 56, 0.12)" },
    { value: 1, label: 'Assigned', color: '#3268FB' ,background:"rgba(50, 104, 251, 0.15)"},
    { value: 2, label: 'Ready', color: '#E9B640' , background:"rgba(255, 197, 62, 0.12)"},
    { value: 3, label: 'Delivered', color: '#288127' , background:"rgba(40, 129, 39, 0.12)"},
    { value: 4, label: 'Cancelled', color: '#082D92',  background:"rgba(40, 129, 39, 0.12)"},
  ];


  downloadfDate(event: any) {
    this.fdate = moment(event).format('DD-MM-YYYY');
    this.download_from_date = moment(event).format('YYYY-MM-DD');

  }
  downloadtDate(event: any) {
    this.tdate = moment(event).format('DD-MM-YYYY');
    this.download_to_date = moment(event).format('YYYY-MM-DD');

  }

  generateCsvData(data: any[], headers: string[]): string {
    let csvData = '';

    // Add headers to the CSV data
    csvData += headers.join(',') + '\n';

    // Iterate over the data and add it to the CSV string
    data.forEach((row: any) => {
        headers.forEach((header: string, index: number) => {
            // Ensure that the value is properly escaped
            let value = row[header];
            if (typeof value === 'string') {
                value = `"${value.replace(/"/g, '""')}"`;
            }
            csvData += (index > 0 ? ',' : '') + value;
        });
        csvData += '\n';
    });

    return csvData;
}
  
  // Download the CSV data
  downloadCsvData(data:any) {
    // Get the three months of data
   
    const headers = Object.keys(data[0]);

    // Generate the CSV data
    const csvData = this.generateCsvData(data,headers);
  
    // Create a Blob object from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });
  
    // Create a URL object from the Blob object
    const url = URL.createObjectURL(blob);
  
    // Create a new anchor element and set its href attribute to the URL object
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = 'orders.csv';
  
    // Click the anchor element to start the download
    anchorElement.click();
  }

  pendingOrderReport()
  {
    var fd = new FormData();

    if(this.select_vendor_for_report=="")
      {
        this.selected_download_option="PENDING";
       return  this.toastr.info('','Select Vendor to Download Reports')
      }
      fd.append('vendor_id', this.select_vendor_for_report=='all' ? '':this.select_vendor_for_report);
      
      this.desingService.pendingOrderReport(fd).subscribe((data:any)=>
      {
        this.toastr.info(
          '',
          'Downloading...',
          { positionClass: 'toast-bottom-right' }
        );
        this.modalService.open(this.vendor_report_download_template, { centered: true })
        this.vendor_report_download_url = data.report_url;
        this.selected_download_option="PENDING";
      },
    (error:any)=>
    {
      this.selected_download_option="PENDING";

      this.toastr.info(
        '',
        error.error.message,
        { positionClass: 'toast-bottom-right' }
      );
    })
      return 
     

  }
  afterDownloadPopUp(event:any)
  { 
    console.log("event",event)
    if(event)
    {
      window.open(this.vendor_report_download_url, '_blank');
    }
    else{
      this.modalService.dismissAll();
    }

  }

// download oms
  downloadOrder()
  {
    var fd = new FormData();

    if(this.selected_download_option=="PENDING")
    {
      this.selected_download_option="PROCESS";
      this.pendingOrderReport();
      return;
    }
    if(this.selected_download_option=="PROCESS")
    {
      this.toastr.info('', 'Generating Report Please Wait');
      return;
    }

    if (this.fdate == '') {
      this.toastr.info('', 'Please do select from date');
      return;
    } else if (this.tdate == '') {
      this.toastr.info('', 'Please do select to date');
      return;
    }


    const fromDate = moment(this.download_from_date);
    const toDate = moment(this.download_to_date);
    const differenceInDays = toDate.diff(fromDate, 'days');

    if(differenceInDays>90){
      this.toastr.info(
        '',
        'You can download only 90 days of data.please do select from and to date within 90 days.',
        { positionClass: 'toast-bottom-right' }
      );
      return;
    }

    this.modalService.dismissAll();

    fd.append('from_date', this.fdate);
    fd.append('to_date',this.tdate);
    fd.append('email', this.email);
    this.toastr.info(
      '',
      'Downloading...',
      { positionClass: 'toast-bottom-right' }
    );

    this.desingService.downloadOrder(fd).subscribe(
      (data:any) => {
        var final_data=data.data;
        if(final_data.length==0){
          this.toastr.info(
            '',
            'No data found in the selected duration.',
            { positionClass: 'toast-bottom-right' }
          );
        }else{
          this.downloadCsvData(data.data);
          this.toastr.success('', data.message, {
            positionClass: 'toast-bottom-right',
          });
        }
        
      },
      (error: any) => {
        this.toastr.info(
          '',
          'Something went wrong, please do try again later.',
          { positionClass: 'toast-bottom-right' }
        );
      }
    );
  }
  getCurrentDate(): string {
    const today = new Date();
    let day: string = String(today.getDate());
    let month: string = String(today.getMonth() + 1);
    const year: string = String(today.getFullYear());

    if (day.length < 2) {
      day = '0' + day;
    }

    if (month.length < 2) {
      month = '0' + month;
    }

    return `${year}-${month}-${day}`;
  }

  completeOrder()
  {
    var fd = new FormData();
    var delivery_date = (<HTMLInputElement>document.getElementById('delivery_date')).value;
    var invoice_no = (<HTMLInputElement>document.getElementById('invoice_no')).value;
    fd.append("id",this.selected_item.id);
    fd.append("delivery_date",delivery_date);
    fd.append("invoice_no",invoice_no);
    this.desingService.completeOrder(fd).subscribe((data)=>
    {
      this.toastr.success('',data.message,{
        positionClass: 'toast-bottom-right',
      });
      this.modalService.dismissAll();
      this.resetAndDisplayTable()
    })
  }
  openCompleteOrder(row:any){
    this.selected_item=row;
    console.log(this.selected_item)
    this.modalService.open(this.complete_order,{centered:true});
  }
  handleStatus(value:any,row:any)
  {
    if (value.value==3)
    {
      // when order is in deliverd state
      (<HTMLInputElement>document.getElementById("order_status"+row.id)).value=row.status
      this.modalService.open(this.complete_order,{centered:true});

      setTimeout(() => {
        (<HTMLInputElement>document.getElementById('delivery_date')).value=this.current_date_validation;
      }, 100);
      this.selected_item=row;
      return
    }
    row.status=value.value;
    var fd = new FormData();
    fd.append("id",row.id);
    fd.append("status",row.status);
    console.log("status",row.status);
    this.desingService.changeStatusOfOrder(fd).subscribe((data)=>
    {

      this.toastr.success('', data.message, {
        positionClass: 'toast-bottom-right',
      });
    })

  }
  addEstimationDate(element:any)
  {
    var fd = new FormData();
    fd.append("id",element.id);
    fd.append("date",element.estimated_delivery);
    this.desingService.setEstimationDate(fd).subscribe((data)=>
    {
      this.toastr.success('',data.message,{
        positionClass: 'toast-bottom-right',
      });
    })

  }
  // row click and hover effect

  onRowClick(row: any): void {
    row.selected = !row.selected;
  }
  onSelect2(event: any,type:any) {
    var selectedFile:any="";
    if(type==0)
    {
      selectedFile= event.target.files[0];
    }
    if (type==1)
    {
      selectedFile=event.addedFiles[0];
    }
    const counter=0;
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        // Assign the result of FileReader to selectedImage
        const selectedImage = reader.result as string;
        
        // Create a dummy design object with the selected image and other properties
        const dummyDesign = { id: String(this.generateRandomNegative()), image: selectedImage, size: selectedFile.size, name: selectedFile.name };
        
        // Add the dummy design to the uploaded_designs array
        this.uploaded_designs.push(dummyDesign);
        this.selected_design= dummyDesign;

      };
      // Read the selected file as a data URL
      reader.readAsDataURL(selectedFile);
      this.files.push(selectedFile);
    }
    
  }

  onSelect(event:any) {// function name should tell what it does
    console.log(event);
    // this.openSpinnerDialog();
    // this.upload_stages(2);
    this.files.push(...event.addedFiles);
    this.uploaded_designs.push(...event.addedFiles);
    // this.selected_files.push(...event.addedFiles);
    // this.onFileSelect(event);
    console.log(event.addedFiles[0]);
    // if(this.id == 0){
    //   for(let i=0; i<event.addedFiles.length; i++) {
    //     const totalFiles = event.addedFiles.length;
    //     const incrementPerFile = 100 / totalFiles;
        
    //       const res = this.catalogueService.uploadJewelleryAssets(event.addedFiles[i]);
    //       res.subscribe((data: any) => {
    //         console.log(data['success']);
    //         console.log("file uploaded");
    //         if(this.uploadProgress + incrementPerFile <= 100){
    //           this.uploadProgress += incrementPerFile;
    //           this.uploadProgress = Math.round(this.uploadProgress);
    //         }
    //         else{
    //           this.uploadProgress = 100;
    //           this.uploadProgress = Math.round(this.uploadProgress);
    //         }
    //         if (data['success'] = 'created') {
    //           // this.uploadProgress += 100/event.addedFiles.length;
    //           // console.log(this.uploadProgress)
    //           if(i == event.addedFiles.length-1){
    //             this.upload_stages(4);
    //           }
    //           // this.closeModal();
    //           // this.dialog.closeAll();
    //         }
    //       }, error => {
    //         this.dialog.closeAll();
    //         this.toastr.info(
    //           '',
    //           'Something went wrong, please do try again later.',
    //           { positionClass: 'toast-bottom-right' }
    //         );
    //       });
    //       // this.uploadProgress += 100/event.addedFiles.length;
    //       // this.startUpload()
    //     }
    // }
    // else{
      
    //     const totalFiles = event.addedFiles.length;
    //     const incrementPerFile = 100 / totalFiles;
    //     for(let i=0; i<event.addedFiles.length; i++) {
    //       if(this.uploadProgress + incrementPerFile <= 100){
    //         this.uploadProgress += incrementPerFile;
    //         this.uploadProgress = Math.round(this.uploadProgress);
    //       }
    //       else{
    //         this.uploadProgress = 100;
    //         this.uploadProgress = Math.round(this.uploadProgress);
    //       }
    //       console.log(this.uploadProgress)
    //     }
    //     // console.log(this.uploadProgress)

    //     this.catalogueService.updateJewelleryItem(
    //       this.id,
    //       this.enteredDesignName,
    //       this.selected_category,
    //       this.selected_metal,
    //       this.selected_purities,
    //       this.files
    //     ).subscribe((data: any) => {
    //       console.log(data);
          
    //       this.upload_stages(4);
    //       // this.closeModal();
    //       // this.dialog.closeAll();
    //       // console.log('its workingg',this.files);

    //     }, error => {
    //       this.toastr.info(
    //         '',
    //         'Something went wrong, please do try again later.',
    //         { positionClass: 'toast-bottom-right' }
    //       );
    //     });
        
     

    // }
      
    // if (files && files.length > 0) {
    //   this.previewImages = [];

    //   for (let i = 0; i < files.length; i++) {
    //     const file = files[i];
    //     if (file.type.startsWith('image/')) {
    //       this.previewImages.push(URL.createObjectURL(file));
    //     }
    //   }
    // }
  }
  onRemove(event:any) { //review: function should tell what it does eg: removeLocalFile
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  selectedRowIndex = -1; // Track selected row index
  onRowMouseEnter(row: any): void {
    if (this.selectedRowIndex === -1) {
      row.hovered = true;
    }
  }

  onRowMouseLeave(row: any): void {
    if (this.selectedRowIndex === -1) {
      row.hovered = false;
    }
  }


  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  selectImage(Design:Design)
  {
    this.selected_design=Design
  }

  getDesign(){
    this.desingService.getDesigns(this.custom_item_id).subscribe((data:any)=>{
      console.log(data);
      this.designModel=data;
      console.log(this.designModel)
      this.uploaded_designs = this.designModel.designs;
      this.selected_design=this.uploaded_designs[0]
      console.log(this.uploaded_designs);


     (<HTMLInputElement>document.getElementById('size')).value=this.designModel.size;
    (<HTMLInputElement>document.getElementById('pieces')).value=this.designModel.pieces;
   (<HTMLInputElement>document.getElementById('notes')).value=this.designModel.order_notes;
   (<HTMLInputElement>document.getElementById('design_weight')).innerHTML=this.designModel.weight;
   (<HTMLInputElement>document.getElementById('purity')).innerHTML=this.designModel.metal_purity;
   (<HTMLInputElement>document.getElementById('design_order_id')).innerHTML=this.designModel.order_id;

    })
  }

  getImageSize(size:number){
    return size/(1024*1024);
  }
  generateRandomNegative(): number {
    return -1 * Math.random();
}
  

  removeDesign(id:any){
    if (Number(id.id)<0)
    {
      
      this.uploaded_designs.splice(this.uploaded_designs.indexOf(id), 1);
      
      if(this.uploaded_designs.length==0)
      {
        this.selected_design=undefined;
        this.files=[];
      }
      return

    }
    var fd = new FormData();
    fd.append("id",id.id);
    fd.append("item_id",this.custom_item_id);
    this.desingService.deleteDesigns(fd).subscribe((data)=>{
      console.log(data);
      this.deleteResponse=data;
      this.getDesign();
      this.toastr.success('','Item Removed Successfully', {positionClass: 'toast-bottom-right',});
    })

  }


  postDesign(){
    console.log("custom order id",this.custom_item_id)
    this.show_upload=true;
    this.toastr.info('', "Please wait until the data is saved", {positionClass: 'toast-bottom-right',});
    var size=(<HTMLInputElement>document.getElementById('size')).value;
    var piece=(<HTMLInputElement>document.getElementById('pieces')).value;
    var notes=(<HTMLInputElement>document.getElementById('notes')).value;

    // this.toastr.info('', "Please wait until the data is saved", {
    //   positionClass: 'toast-bottom-right',
    // });
    
    for(let i=0;i<this.files.length;i++){

      console.log(this.files[i]);
        if(this.getImageSize(this.files[i].size) > 5){
          this.toastr.info('', this.files[i].name+" is not uploaded due to size greater than 5 mb.", {
            positionClass: 'toast-bottom-right',
          });
        }else if(this.uploaded_designs.length != 5){

        console.log("comes in ");
        var fd=new FormData();
        fd.append("id",this.custom_item_id);
        fd.append("size",size);
        fd.append("piece",piece);
        fd.append("note",notes);
        fd.append("image",this.files[i]);

        this.desingService.postDesign(fd).subscribe(
          percent => {

          },
          error => {
            // Handle any errors that occur during the upload
            console.error('Upload Error:', error);
          },
          () => {
            this.toastr.success('', "Data Saved successfully", {
              positionClass: 'toast-bottom-right',
            }

            )
            this.modalService.dismissAll();
            ;
            this.getDesign();
            // this.ngOnInit();

            this.resetAndDisplayTable()
          }
        );

        }else{
          this.toastr.info('', "Only 5 images can be uploaded.", {
            positionClass: 'toast-bottom-right',
          });
        }
    }
    if (this.files.length==0)
    {
      var fd=new FormData();
      fd.append("id",this.custom_item_id);
      fd.append("size",size);
      fd.append("piece",piece);
      fd.append("note",notes);
      this.desingService.postDesign(fd).subscribe(
        percent => {

        },
        error => {
          // Handle any errors that occur during the upload
          console.error('Upload Error:', error);
        },
        () => {
          this.toastr.success('', "Data Saved successfully", {
            positionClass: 'toast-bottom-right',
          })
          this.getDesign();
          this.modalService.dismissAll();

        }
      );

    }
    this.files = [];
  }




  // Design model
  openDesignModel(content: any,id:any,row:any) {
    console.log(row,id)
    console.log(this.files)
    this.custom_item_id = id;
    this.selected_item=row;
    this.getDesign();
    this.modalService.open(content, {
      centered: true,
      size: 'xl',
      backdrop: 'static',
    });
  }
  // redirect to order
  redirectToOrder(id:number)
  {
    localStorage.setItem("orderFilterValues",this.filter_values.toString());
    this.router.navigate(['/dashboard/order/',
  {
    // order id to get the data
    id:id
  }])
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.imagesWithProgress = Array.from(input.files).map((file) => ({
        file,
      }));
    }
  }

  onUpload(): void {
    if (this.imagesWithProgress.length > 0) {
      this.imagesWithProgress.forEach((imageWithProgress) => {
        const imageFile = imageWithProgress.file;

        this.uploadService.uploadImages([imageFile]).subscribe(
          (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              const progress = Math.round((100 * event.loaded) / event.total!);
              imageWithProgress.progress = progress;
            }
          },
          (error) => {
            console.error(error);
          }
        );
      });
    }
  }

  storePageNumber(){
    const paginationState = localStorage.getItem('orderPaginationState');
    if (paginationState) {
      const { pageIndex, pageSize } = JSON.parse(paginationState);
      this.paginator.pageIndex = pageIndex;
      this.paginator.pageSize = pageSize;
      console.log(this.paginator.pageIndex);
      console.log(this.paginator.pageSize);
    }
    else{
      this.paginator.pageIndex=0
    }
  }

resetAndDisplayTable() {
    this.storePageNumber()
    this.popupManager.openSnackBar('Loading', 'cancel');
    // this.paginator.pageIndex = 0;
    this.dataSource.loadData(this.paginator.pageIndex, this.search,this.filter_values,this.selected_vendors);
 }
// for handling search in view order
searchOption() {
  const regex = /^(?=.*[a-zA-Z])(?=.*\d)/;
  if (regex.test(this.search)) {
    // Remove whitespace and replace it with "-"
    this.search = this.search.replace(/\s+/g, '-');
}
this.dataSource.loadData(0, this.search,this.filter_values,this.selected_vendors);

  }

  // this.resetAndDisplayTable();

clearInput() {

  this.search = '';
}
focus(id: string) {
  console.log(id);
  setTimeout(() => {
    const input = <HTMLInputElement>document.getElementById(id);
    input.focus();
  }, 100);

}
// f
}


