import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription, timer } from 'rxjs';
import { PrimaryBtnComponent } from '../primary-btn/primary-btn.component';
import { BookingDetailsComponent } from '../../booking-details/booking-details.component';
import { OtpCommunicationService } from '../../services/0tp-communication.service';

@Component({
  selector: 'app-otp-template',
  standalone: true,
  imports: [NgIf, NgFor, FormsModule, ReactiveFormsModule, PrimaryBtnComponent, AsyncPipe],
  templateUrl: './otp-template.component.html',
  styleUrls: ['./otp-template.component.css']
})
export class OtpTemplateComponent {

  defaultOtpLength: number = 4;

  // @Output("EnterKeyEvent") enterKeyEvent: EventEmitter<any> = new EventEmitter();
  // @Output("otpFilled") otpFilled: EventEmitter<string> = new EventEmitter();

  otpForm!: FormGroup;
  showInput: boolean = false;

  defaultOtpTimeout: number = 60;
  timerVal: number = 0;
  enableOtpResend: boolean = false;
  timerSubs: Subscription | undefined = undefined;

  constructor(
    public dialogRef: MatDialogRef<OtpTemplateComponent>,
    private otpCommunicationService: OtpCommunicationService,
    @Inject(MAT_DIALOG_DATA) public data: {
      enterKeyEvent: Subject<string>, verifyClickEvent: Subject<string>, resendOtpEvent: Subject<void>, wrongOtp: Subject<boolean>
    },
  ) { }

  ngOnInit(): void {
    console.log('-- default input --', this.defaultOtpLength);

    this.otpForm = new FormGroup({
      otp: new FormArray([])
    });

    const tempOtpFotm = this.otpForm.get('otp') as FormArray
    for (let index = 0; index < this.defaultOtpLength; index++) {
      tempOtpFotm.push(this.addOtpBoxesForm())
    }
    this.showInput = true;
  }

  ngAfterViewInit(): void { // setting focus on first input box
    this.startTimer();
    let tempElement = (document.querySelector(`#digit0`) as HTMLInputElement);
    console.log(tempElement?.value)
    tempElement.focus();
  }

  getOtpValue(): string | undefined {

    let temp = this.otpForm.get('otp')?.value;
    let tempOtp: string | undefined = '';
    if (temp && temp.length == this.defaultOtpLength) {
      temp.forEach((element: any) => {
        // console.log('---', '' + tempOtp + element['digit'], element['digit'] )
        tempOtp = ('' + tempOtp + element['digit']);
      });
    }
    // console.log('-- otp input check --', temp , tempOtp);

    try {
      let t = Number(tempOtp);
    } catch (error) {
      console.error('invalid otp format', error);
      // this.comS.openSnackBar('Enter valid OTP', 'warning');
    }
    if (temp && tempOtp.toString().length == this.defaultOtpLength) {
      return tempOtp.toString();
    } else {
      // this.comS.openSnackBar('Please enter valid  OTP', 'warning');
      return undefined;
    }

  }

  private addOtpBoxesForm(): FormGroup {
    return new FormGroup({
      'digit': new FormControl('', [Validators.maxLength(1), Validators.required]),
    })
  }

  focusPrevInput(index: number, event: KeyboardEvent): void {
    if (event && (event.key.toLowerCase() == 'backspace')) {
      let prevElementSiblingId = 'digit' + (index - 1);
      let currElementSiblingId = 'digit' + (index);
      if ((index - 1 >= 0)) {
        let tempElement1 = (document.querySelector(`#${currElementSiblingId}`) as HTMLInputElement);
        let tempElement = (document.querySelector(`#${prevElementSiblingId}`) as HTMLInputElement);
        // console.log(tempElement, tempElement.value, tempElement1.value);
        if (!tempElement1.value) {
          tempElement.focus()
        }
      }
    }
  }

  get otpInfo(): any {
    return this.otpForm.get('otp') as FormArray;
  }

  focusNextInput(index: number, event: KeyboardEvent): void {
    try {
      if (event && (event.key.toLowerCase() != 'backspace')) {
        let nextElementSiblingId = 'digit' + (index + 1);
        // console.log(event);
        let tempElement1 = (document.querySelector(`#digit${index}`) as HTMLInputElement);
        // console.log(tempElement1.value);
        if ((index + 1 < this.defaultOtpLength) && tempElement1.value) {
          let tempElement = (document.querySelector(`#${nextElementSiblingId}`) as HTMLInputElement);
          // console.log(tempElement, tempElement.value);
          tempElement.focus()
        } else if (event.key.toLowerCase() == 'enter') {
          let otp = this.getOtpValue()
          if (otp && this.data?.enterKeyEvent) this.data.enterKeyEvent.next(otp)

        }
        if (index === this.defaultOtpLength - 1) {
          // last otp input box filled event

          // let otp = this.getOtpValue()
          // if (otp && this.data?.otpFilledEvent) this.data.otpFilledEvent.next(otp)
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  resendOtp(): void {
    this.data.resendOtpEvent.next();
    this.otpCommunicationService.triggerResendOtpClick();
  }

  private startTimer(): void {
    this.timerVal = 0;
    this.stopTimer();
    this.timerSubs = timer(0, 1000).subscribe((val) => {
      this.timerVal = val;
      if (val == this.defaultOtpTimeout) {
        this.stopTimer();
        this.enableOtpResend = true;
      }
    });
  }

  private stopTimer(): void {
    this.enableOtpResend = false;
    if (this.timerSubs) {
      this.timerSubs.unsubscribe();
    }
  }


  verifyClicked(): void {
    let otp = this.getOtpValue()
    if (otp && this.data?.verifyClickEvent) this.data.verifyClickEvent.next(otp)
  }

  ngOnDestroy(): void {
  }
}
