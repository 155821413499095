<div class="offline-message">
    <div class="offline-message-content">
      <div class="lottie-animation" style="height:500px; margin-right: 0px;" class="w-100">
        <ng-lottie [options]="options">
        </ng-lottie>
      </div>
      <h2>Oops!</h2>
      <p>It seems you are offline. Please check your internet connection and try again.</p>
    </div>
  </div>
  