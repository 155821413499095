<div class="enquiry-pafe p-5">
    <div class="enquiry-container container-fluid p-5">
        <h3>Whatsapp</h3>
        <p>To receive enquiries on WhatsApp please type your number below.</p>
        <div class="row">
            <div class="col-12 mt-4">
                <label class="mobileNumberLabel" for="mobileNumber">Mobile No*</label>
            </div>

            <div class="col-5 mt-3" style="display: flex;">
                <input class="countryCode text-center" type="text" value="+91" disabled>
                <!-- <input type="text"
                    id="mobileNumber" 
                    class="form-control" 
                    [(ngModel)]="phoneNumber"
                    (keypress)="validatePhoneNumber($event)" 
                    placeholder="Enter phone number"
                    (input)="updateVerifyButtonState()" 
                    style="height: 50px;" /> -->
                    <input *ngIf="!isVerified; else verifiedInput"
                    type="text"
                    id="mobileNumber" 
                    class="form-control" 
                    [(ngModel)]="phoneNumber"
                    (keypress)="validatePhoneNumber($event)" 
                    placeholder="Enter phone number"
                    (input)="updateVerifyButtonState()" 
                    style="height: 50px;" />
                <ng-template #verifiedInput>
                    <input type="text"
                        id="mobileNumber" 
                        class="form-control" 
                        [(ngModel)]="phoneNumber"
                        disabled
                        placeholder= {{phoneNumber}} 
                        style="height: 50px;" />
                </ng-template>
            </div>
            <div class="col-3 mt-3">
                <!-- <button class="verify-btn" [disabled]="!isPhoneNumberValid" (click)="verifyPhoneNumber()">Verify</button> -->
                <button *ngIf="!isVerified; else editButton" 
                    class="verify-btn" 
                    [disabled]="!isPhoneNumberValid" 
                    (click)="verifyPhoneNumber()">Verify</button>
                <ng-template #editButton>
                    <button class="edit-btn my-2" (click)="editPhoneNumber()">
                        <img src="..\assets\images\EditNumber.png" alt="" class="custom-icon">
                        <span class="edit-btn-text ml-2">Edit</span>
                    </button>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<ng-template #otp_dialog>
    <div class="dialog-box container-fluid text-center">
        <div class="modal-header ">
            <!-- <mat-icon class="back-arrow px-3" (click)="closeOTPDialog()" style="cursor: pointer;">keyboard_arrow_left</mat-icon> -->
            <h6 class="modal-title"></h6>
            <button type="button" style="outline: none;" class="close btn" aria-label="Close"
              (click)="closeOTPDialog()">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <label>Enter OTP</label><br /><br />
            <ng-otp-input 
                #otpInputRef
                (onInputChange)="onOtpChange($event)" 
                [config]="{ length: 4 }" 
                
                ></ng-otp-input><br />
            <span *ngIf="otpIncorrect" style="color: red;">Incorrect Otp</span>
            <span *ngIf="!otpIncorrect" style="color: green;">Otp Sent Successfully</span><br>
            <span *ngIf="!resendClicked">Resend OTP in {{ countdown }} sec</span>
      <button
        *ngIf="resendClicked"
        mat-button
        color="primary"
        (click)="resendOTP()"
      >
        Resend OTP
      </button>
      <br><br>
      <button class="verify-btn" [disabled]="!otpValid" (click)="verifyOTP()">Verify</button>

        </div>
    </div>
</ng-template>