import { offlineBankAccounts } from './offlinebankaccount';
import { formatDate } from '@angular/common';

export class PaymentType {
  id!: string;
  amount!: string;
  date!: string;
  bank!: string;
  payment_info!: string;
  constructor(
    id: string,
    amount: string,
    date: string,
    bank: string,
    payment_info: string
  ) {
    this.id = id;
    this.amount = amount;
    this.date = date;
    this.bank = bank;
    this.payment_info = payment_info;
  }
}

export class InvoiceDetails {
  amount!: string;
  gst!: string;
  old_gold!: string;
  total!: Number;
  advance_paid!: string;
  balance!: Number;
  old_gold_weight!: string;
  constructor(data: any) {
    this.amount = data.amount || 0;
    this.gst = data.gst || 0;
    this.old_gold = data.old_gold || 0;
    this.advance_paid = data.advance_paid || 0;
    this.total =
      Number(this.amount) + Number(this.gst) - Number(this.old_gold) || 0;
    this.balance = Number(this.total) - Number(this.advance_paid) || 0;
    this.old_gold_weight = data.old_gold_weight || 0;
  }
}
export class InvoiceDetailsForRepair {
  amount!: string;
  advance_paid!: string;
  balance!: Number;
  constructor(data: any) {
    this.amount = data.amount || 0;
    this.advance_paid = data.advance_paid || 0;
    this.balance = Number(this.amount) - Number(this.advance_paid) || 0;
  }
}

export class ResposnePayment {
  amount!: string;
  date!: string;
  offline_bank_account!: string;
  payment_mode!: string;
  payment_info!: string;
  constructor(
    amount: string,
    date: string,
    offline_bank_account: string,
    payment_mode: string
  ) {
    this.amount = amount;
    this.date = date;
    this.offline_bank_account = offline_bank_account;
    this.payment_mode = payment_mode;
  }
}
export class PaymentOptions {
  type!: string;
  mode!: number;
  options!: PaymentType[];
  constructor(type: string, options: PaymentType[], mode: number) {
    this.type = type;
    this.options = options;
    this.mode = mode;
  }
  checkMissingAmount(): boolean {
    return this.options.some((option) => option.amount.trim() === '');
  }
}

export class AdvancePaymentType {
  id!: string;
  mode!: string;
  amount!: string;
  date!: string;
  bank!: string;
  payment_info!: string;

  constructor(
    id: string,
    method: string,
    amount: string,
    date: string,
    bank: string,
    payment_info: string
  ) {
    this.id = id;
    this.mode = method;
    this.amount = amount;
    this.date = date;
    this.bank = bank;
    this.payment_info = payment_info;
  }
}

export class AddInstallmentForDailyPayRequest {
  subscription_id!: string;
  installment_data!: InstallmentDataRequest;
  payment_option!: PaymentOptions[];
  plan_type: string = 'daily_pay';

  constructor(
    subscription_id: string,
    installment_data: InstallmentDataFromID,
    payment_option: PaymentOptions[]
  ) {
    this.subscription_id = this.capitalizeFirstLetter(subscription_id);
    this.installment_data = new InstallmentDataRequest(installment_data);
    this.payment_option = payment_option;
  }

  capitalizeFirstLetter(input: string): string {
    if (input.length === 0) {
      return input;
    }

    const firstLetter = input[0];
    const restOfString = input.slice(1);

    if (firstLetter >= 'a' && firstLetter <= 'z') {
      return firstLetter.toUpperCase() + restOfString;
    } else {
      return input;
    }
  }
}

export class InstallmentData {
  plan_id!: string;
  date!: string;

  amount!: string;
  installment_number!: string;
  rate!: string;
  customer_name!: string;
  phone!: string;
  s_date!: string;
  weight!: string;
  last_paid!: string;
  plan_type!: string;
  constructor(
    plan_id: string,
    date: string,
    amount: string,
    installment_number: string,
    rate: string,
    customer_name: string,
    phone: string,
    weight: string,
    last_paid: string,
    plan_type: string
  ) {
    this.plan_id = plan_id;
    this.date = date;

    this.amount = amount;
    this.installment_number = installment_number;
    this.rate = rate;
    this.customer_name = customer_name;
    this.phone = phone;
    this.weight = weight;
    this.last_paid = last_paid;
    this.plan_type=plan_type
  }
}

export class InstallmentDataRequest {
  date!: string;
  plan_id!: string;
  rate!: string;
  weight!: string;
  installment_number!: string;

  constructor(data: InstallmentDataFromID) {
    this.date = data.installment_date;
    this.plan_id = data.code;
    this.rate = data.rate;
    this.weight = data.weight;
    this.installment_number = data.installment;
  }
}

export class InstallmentDataFromID {
  //subscription id
  code!: string;
  installment_date!: string;
  amount!: string;
  installment!: string;
  name!: string;
  rate!: string;
  phone!: string;
  weight!: string;
  s_date!: string;
  admin!: boolean;
  date!: string;
  plan_type!: string;
  last_paid!: string;
  pending_installment!: string;
  constructor(data: any) {
    this.code = data.code || '';
    this.amount = data.amount || 0;
    this.installment = data.installment || 0;
    this.name = data.name || '';
    this.rate = data.rate || 0;
    this.phone = data.phone || '';
    this.weight = data.weight || 0;
    this.s_date = data.s_date || '';
    this.admin = data.admin || false;
    this.date = data.date || '';
    this.plan_type = data.plan_type || '';
    this.last_paid = data.last_paid || '';
    this.pending_installment = data.pending_installment || '';
    this.installment_date = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
  }
}

export class InstallmentPrint {
  rc!: number;
  ins!: number;
  date!: string;
  plan_id!: string;
  rs!: string;
  wt!: string;
  rt!: string;
  tw!: string;
}


export class PendingInstallment{
  id!:number;
  plan_id!:string;
  customer_name!:string;
  phone!:string;
  plan_type!:string;
  payment_status!:string;
}