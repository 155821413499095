import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Order, OrderReportData, RepairReportData, oldGoldDetail } from 'src/app/models/orderDetail';
import { Rest } from 'src/app/models/rest_model';
import { RepairOrder } from 'src/app/models/repairOrder';


@Injectable({
  providedIn: 'root'
})
export class CreateOrderService {

  constructor(private http: HttpClient) { }

  getPurites()
  {
    return this.http.get(`${environment.ip}shop/customOrders/get_purities/`);
  }
  // add Estimation
  postEstimation(data:any,print_d:any)
  {
    console.log(data)
    console.log(print_d)
    return this.http.post(`${environment.ip}shop/estimations/createEstimation/`,data);
  }
  postOldGold(data:oldGoldDetail[])
  {
    return this.http.post(`${environment.ip}shop/customOrders/post_old_gold/`,{old_gold_rows:data})
  }
  // save Order
  postOrder(data:Order)
  {
    return this.http.post(`${environment.ip}shop/customOrders/createOrder/`,data)
  }

  
  // edit order
  editOrder(data:Order)
  {
    console.log("cehck kr",data)

    return this.http.post(`${environment.ip}shop/customOrders/editOrder/`,data)

  }

  repairOrder(data:RepairOrder)
  {
    return this.http.post(`${environment.ip}shop/repairOrders/createRepair/`,data)
  }
  editRepairOrder(data:RepairOrder)
  {
    return this.http.post(`${environment.ip}shop/repairOrders/editRepairOrder/`,data)

  }
  // for editing user
  patchUserbyId(
    id: string,
    fullname: any,
    phone:any,
    address: any,
    pincode: any,
    state: any,
    gstin:any,
    pan:any
  ) {
    var fd = new FormData();
    fd.append('first_name', fullname);
    fd.append('phone',phone)
    fd.append('address', address);
    fd.append('pincode', pincode);
    fd.append('state', state);
    fd.append('gstin',gstin);
    fd.append('pan',pan)

    return this.http.put(
      `${environment.ip}shop/users/${id}/`,
      fd
    );
  }

  // order taken by suggestion
  orderTakenSuggestion(search:string)
  {
    return this.http.get(`${environment.ip}shop/customOrders/order_taken_suggestion/?search=${search}`)
  }

  getOrder(id:number)
  {
    return this.http.get<Order>(`${environment.ip}shop/customOrders/${id}/`)
  }
  addAdvance(data:any)
  {
    return this.http.post(`${environment.ip}shop/customOrders/add_advance/`,data)
  }

  addRepairAdvance(data:any)
  {
    return this.http.post(`${environment.ip}shop/repairOrders/add_advance/`,data)
  }

  getRepairOrder(id:number)
  {
    return this.http.get<RepairOrder>(`${environment.ip}shop/repairOrders/${id}/`)
  
  }

  getOmsReport(
    page: number,
    from_date: string,
    to_date: string,
    search: string,
    filter: string,
    settlement_id: string = '',
  ) {
    var online = "";
    var selected_types = filter.split(',');

    selected_types.forEach((element: any, index: any) => {
      if (element == '999') {
        online = "true";
        selected_types.splice(index, 1);
      }
    });

    return this.http.get<Rest<OrderReportData>>(environment.ip+'shop/' + 'custom_order_report/', {
      params: {
        from_date: from_date,
        to_date: to_date,
        page: page,
        search: search,
        settlement_id: settlement_id,
        payment_mode__in: `${selected_types.toString()}`,
        order__is_online_mode: online,
      },
    });
  }
  getRepairReport(
    page: number,
    from_date: string,
    to_date: string,
    search: string,
    filter: string,
    settlement_id: string = '',
  ) {
    var online = "";
    var selected_types = filter.split(',');

    selected_types.forEach((element: any, index: any) => {
      if (element == '999') {
        online = "true";
        selected_types.splice(index, 1);
      }
    });

    return this.http.get<Rest<RepairReportData>>(environment.ip+'shop/' + 'repair_order_report/', {
      params: {
        from_date: from_date,
        to_date: to_date,
        page: page,
        search: search,
        settlement_id: settlement_id,
        payment_mode__in: `${selected_types.toString()}`,
        order__is_online_mode: online,
      },
    });
  }
  // post old gold for seperately
  addOldGold(data:any)
  {
    return this.http.post(`${environment.ip}shop/customOrders/post_old_gold/`,data)
  }

  fixRate(data:any)
  {
    return this.http.post(`${environment.ip}shop/customOrders/fix_rate/`,data)
  }

}
