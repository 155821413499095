<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:50vh;">

<div class="container-fluid master-container example-container">
    <div class="row side mt-4">
        <h3 (click)="back()" class="back_title"> <mat-icon class="right_back">arrow_back_ios</mat-icon> Notification Analytics</h3>
    </div>
    <div class="row tablePadding side mt-3">
        <div class="col-md-12 text-center">
            <div class=" table-responsive">
                <table  mat-table [dataSource]="dataSource" matSort class="">
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                        <td mat-cell *matCellDef="let element">
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                        <td mat-cell *matCellDef="let element"></td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                        <td mat-cell *matCellDef="let element"> </td>
                    </ng-container>
                    <ng-container matColumnDef="received">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Received </th>
                        <td mat-cell *matCellDef="let element"> </td>
                    </ng-container>
                    <ng-container matColumnDef="cta">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> CTA </th>
                        <td mat-cell *matCellDef="let element"> </td>
                    </ng-container>
                    <ng-container matColumnDef="conversion">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Conversion </th>
                        <td mat-cell *matCellDef="let element"> </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[popupManager.page_size_number]" showFirstLastButtons></mat-paginator>
            </div>
        </div> <br>
        <div class="col-md-3"></div>
<div class="col-md-3"></div>
<div class="col-md-3"></div>
<div class="col-md-3">
    <button class="btn btn-primary" (click)="send_notification()">Received</button>&nbsp;
    <button class="btn btn-primary" (click)="send_notification()">CTA</button> 
</div> 
        
</div>
</div>
<ng-container  *ngIf="ab">
    <div class="myModal">
        <div class="modal-header">
            <h1 class="modal-title"></h1>

          <button type="button" style="border: none; border-top: none;" class="close" aria-label="Close"  (click)="send_notification()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    <div class="cardClass">
        <h2 class="title_topic mt-3 mr-5">Received</h2>
        <table>
            <tr>
                <th>Phone Numbers</th>
                <th>Customer Name</th>
            </tr>
        </table>
    </div>
    </div>
</ng-container>
<ng-container  *ngIf="ab">
    <button type="button" style="border: none;" class="close" aria-label="Close" (click)="send_notification()">
        <span aria-hidden="true">&times;</span>
      </button>
    <div class="cardClass">
        <h2 class="title_topic mt-3 mr-5">Call to Action</h2>
        <table>
            <tr>
                <th>Phone Numbers</th>
                <th>Customer Name</th>
            </tr>
        </table>
    </div>
</ng-container>
</div>