<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:50vh;">

<div class="head side">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <!-- <h3 (click)="back()" class="back_title">
          <mat-icon class="right_back">arrow_back_ios</mat-icon> Customers
        </h3> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <mat-spinner
          *ngIf="dataSource.loading$ | async"
          style="margin: 0 auto"
          mode="indeterminate"
          [diameter]="50"
        ></mat-spinner>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <div class="input-group">
          <mat-icon class="greyColor searchIcon">search</mat-icon>
          <input
            style="border: 0"
            [(ngModel)]="search"
            type="text"
            [disabled]="search_disable"
            class="form-control search_box roundSquare text-indent"
            id="plan_name"
            placeholder="Search id, Name, phone, email, or address   "
            (keyup.enter)="searchOption(); txtInput.blur()"
            #txtInput
          />
          <mat-icon
            *ngIf="search"
            class="crossIcon"
            (click)="clearInput(); searchOption()"
            [ngStyle]="{ color: '#9A9AB0' }"
            >cancel</mat-icon
          >
          <button
            (click)="searchOption()"
            mat-raised-button
            color="primary"
            class="searchButton"
            style="background: #4070f4; color: #ffffff; box-shadow: none"
          >
            Search
          </button>
        </div>
      </div>

      <div class="col-md-4 mt-4"></div>
      <div class="col-md-2 mt-4">
        <a
          [routerLink]="actionsCheck(14) ? ['/dashboard/new-customer'] : [null]"
        >
          <button
            mat-raised-button
            color="primary"
            class="btn btn-primary add_customer"
          >
            Add New Customer
          </button>
        </a>
      </div>
      <div class="col-md-1 mt-3">
        <div
          (click)="actionsCheck(35) ? download(showdownload) : null"
          id="rcorners1"
        >
          <img
            src="assets/images/ButtonDownload.svg"
            style="width: 50px; height: 50px; cursor: pointer"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid mt-3">
    <div class="row tablePadding">
      <div class="col-md-12 text-center">
        <div class="mat-elevation table-responsive" style="border-radius: 10px">
          <table mat-table [dataSource]="dataSource" matSort class="">
            <!-- Position Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
              <td mat-cell *matCellDef="let element">
                {{ element.id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="first_name">
              <th
                style="width: 150px"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                Customer Name
              </th>
              <td style="width: 150px" mat-cell *matCellDef="let element">
                {{ element.first_name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="phone">
              <th
                style="width: 120px"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                Phone Number
              </th>
              <td style="width: 120px" mat-cell *matCellDef="let element">
                {{ element.phone }}
              </td>
            </ng-container>

            <ng-container matColumnDef="dob">
              <th
                style="width: 120px"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                Date Of Birth
              </th>
              <td style="width: 120px" mat-cell *matCellDef="let element">
                {{ element.dob | date : "dd/MM/yyyy" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th
                style="width: 230px"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                Email ID
              </th>
              <td style="width: 230px" mat-cell *matCellDef="let element">
                {{ element.email }}
              </td>
            </ng-container>

            <ng-container matColumnDef="gender">
              <th
                style="width: 85px"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                Gender
              </th>
              <td style="width: 85px" mat-cell *matCellDef="let element">
                {{
                  element.gender == "M"
                    ? "Male"
                    : element.gender == "F"
                    ? "Female"
                    : ""
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="date_joined">
              <th
                style="width: 120px"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                Joined On
              </th>

              <td style="width: 120px" mat-cell *matCellDef="let element">
                {{ element.date_joined | date : "dd/MM/yyyy" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="address">
              <th
                style="width: 192px"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                Address
              </th>
              <td style="width: 192px" mat-cell *matCellDef="let element">
                {{ element.address }}
              </td>
            </ng-container>
            <ng-container matColumnDef="pincode">
              <th
                style="width: 85px"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                Pin Code
              </th>
              <td style="width: 85px" mat-cell *matCellDef="let element">
                {{ element.pincode }}
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th
                style="width: 100px"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                State
              </th>
              <td style="width: 100px" mat-cell *matCellDef="let element">
                <span [ngStyle]="">{{ element.state }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="kebab">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td
                mat-cell
                *matCellDef="let element"
                style="padding: 0; width: 20px"
              >
                <button
                  class="newbi"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                >
                  <span
                    id="boot-icon"
                    class="bi bi-three-dots-vertical mt-1"
                    style="
                      font-size: 25px;
                      -webkit-text-stroke-color: rgb(0, 0, 0);
                    "
                  ></span>
                </button>
                <mat-menu #menu="matMenu" class="matmenu">
                  <button
                    (click)="actionsCheck(36) ? navAndEdit(element.id) : null"
                    mat-menu-item
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                  <!-- <button (click)="showWarning(deletecustomer,element.id);"  mat-menu-item>
                                        <mat-icon>delete</mat-icon>
                                        <span>
                                        Delete
                                        </span>
                                      </button> -->
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator
            style="background-color: #f5f6f8"
            [pageSizeOptions]="[popUpManager.page_size_number]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<!-- WARNING of deleting Customer -->

<ng-template #deletecustomer let-modal>
  <div class="myModal pb-5" style="border-radius: 150px">
    <div class="modal-header">
      <h1 class="modal-title fbold"></h1>
      <button
        type="button"
        class="close closeButton btn"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <img src="assets/images/Error.png" class="img-responsive img-fluid" />

        <h1 class="fbold">Warning!</h1>

        <p class="greyColor">
          <span>Are You Sure you want to delete the customer?</span>
        </p>

        <button
          (click)="deleteCustomer(); modal.dismiss('Cross click')"
          type="button"
          class="btn btn-primary mt-3 addbutton1"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Required Permisson modal -->

<ng-template #permission let-modal>
  <div class="myModal p-5">
    <div class="modal-header">
      <h1 class="modal-title fbold"></h1>
      <button
        type="button"
        class="close closeButton btn"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <img src="assets/images/Error.png" class="img-responsive img-fluid" />

        <h1 class="fbold">Warning!</h1>

        <p class="greyColor">
          <span
            >You don't have permission to change this. Kindly contact your
            admin</span
          >
        </p>

        <button type="button" class="btn btn-primary mt-3 addbutton1">
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Customer DATA  Download -->

<ng-template #showdownload1 let-modal>
  <div class="emailModal">
    <div class="modal-header" style="border: 0">
      <h1
        class="modal-title"
        style="
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 500;
          font-size: 26px;
          margin-left: 125px;
          margin-top: 15px;
          color: #000000;
        "
      >
        Download Data
      </h1>
      <button
        type="button"
        style="outline: none"
        class="close btn"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h3
        style="
          font-family: 'rubik';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        "
      >
        Choose Date
      </h3>
      <div class="row">
        <div class="col-sm-3">
          <mat-form-field
            [style.width.%]="220"
            appearance="outline"
            style="padding: 0; margin: 0"
          >
            <input
              matInput
              [matDatepicker]="picker"
              (dateInput)="downloadfDate($event.value)"
              placeholder="From"
            />
            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-3"></div>
        <div class="col-sm-3">
          <mat-form-field
            [style.width.%]="220"
            appearance="outline"
            style="padding: 0; margin: 0"
          >
            <input
              matInput
              [matDatepicker]="picker1"
              (dateInput)="downloadtDate($event.value)"
              placeholder="To"
            />
            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-ms-3"></div>
      </div>
 
      <div class="row ms-1 me-2 form-group">
      
        <button
          (click)="fileDownload()"
          mat-raised-button
          color="primary"
          class="mt-5 btn-lg mb-2"
          style="height: 52px"
        >
          Download
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showdownload let-modal>
  <div class="emailModal">
    <div class="modal-header p-3" style="border: 0">
      <h1
        class="modal-title"
        style="
          font-family: 'Rubik';
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          margin-left: 125px;
          margin-top: 15px;
          font-weight: 500;
          color: #262626;
        "
      >
        Download Data
      </h1>
      <button
        type="button"
        style="outline: none"
        class="close btn"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h3
        style="
          font-family: 'rubik';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        "
      >
        Choose Date
      </h3>
      <div class="row">
        <div class="col-sm-3">
          <mat-label class="model_labels">From</mat-label>

          <mat-form-field
            [style.width.%]="220"
            class="ms-1"
            appearance="outline"
            style="padding: 0; margin: 0"
          >
            <input
              matInput
              (click)="picker.open()"
              [matDatepicker]="picker"
              (dateInput)="downloadfDate($event.value)"
              placeholder="dd/mm/yyyy"
            />
            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle
              matPrefix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-3"></div>
        <div class="col-sm-3">
          <mat-label class="model_labels">To</mat-label>
          <mat-form-field
            [style.width.%]="220"
            appearance="outline"
            style="padding: 0; margin: 0"
          >
            <input
              matInput
              (click)="picker1.open()"
              [matDatepicker]="picker1"
              (dateInput)="downloadtDate($event.value)"
              placeholder="dd/mm/yyyy"
            />
            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle
              matPrefix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-ms-3"></div>
      </div>
      <div class="row ms-1 me-1 form-group">
    
        <div class="row" style="height: 310px"></div>
        <button
          (click)="fileDownload()"
          mat-raised-button
          color="primary"
          class="mt-5 btn-lg mb-2 ms-4"
          style="
            height: 52px;
            background: #4070f4;
            border-radius: 10px;
            width: 90%;
          "
        >
          Download
        </button>
      </div>
    </div>
  </div>
</ng-template>
