import { Component, TemplateRef, ViewChild } from '@angular/core';
import { CertificateData } from '../models/Certificate-content';
import { CertificateServiceService } from '../certificate-service.service';
import { PopUpData } from 'src/app/templates/warning-popup/warning-popup/warning-popup.component';
import { AnimationOptions } from 'ngx-lottie';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TitleService } from 'src/app/services/title.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-create-certificate',
  templateUrl: './create-certificate.component.html',
  styleUrls: ['./create-certificate.component.css'],
})
export class CreateCertificateComponent {
  raw_certificate_content: { key: string; value: string }[] = [];
  certificate_content: CertificateData = new CertificateData({});

  existing_certificate_content:CertificateData = new CertificateData({});
  
  certificate_completion_popup:PopUpData = new PopUpData( "Certificate Setup Created Successfully","","","Create Certificate")
  setup_completion_lottie_file_path:AnimationOptions = {path:'/assets/lottie/completed_animation.json'};

  certificateSetupForm = new FormGroup({
    store_name: new FormControl('', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),Validators.maxLength(30)]),
    title: new FormControl('',[Validators.maxLength(50)]),
    address: new FormControl('', [Validators.maxLength(200)]),
    footer:new FormControl('', [Validators.maxLength(200)])
  });

  is_setup_exist:Boolean=false;

  @ViewChild('completed', { read: TemplateRef })completed_template!:TemplateRef<any>;
  
  constructor(  private titleService: TitleService,private certificateSetup: CertificateServiceService,private router :Router,     private modalService: NgbModal,    private toastr: ToastrService,){ }

  ngOnInit():void{
    this.titleService.setTitle('Setup Certificate')
    this.isSetupExist()}

  afterPopup(value:boolean){this.router.navigate(["dashboard/new_certificate/"]);this.modalService.dismissAll()}
  
  removeSection() {this.raw_certificate_content.pop();}

  isSetupExist(){
    this.certificateSetup.getSetup().subscribe((data:CertificateData)=>{
      this.is_setup_exist=true;
      // this.existing_certificate_content=data;
      console.log(data)
      
      this.certificate_content=new CertificateData(data);
      console.log(this.certificate_content)
    })
  }

  onFileSelected(event: Event, type: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          this.certificate_content[type] = reader.result as string;
        };
        reader.readAsDataURL(file);
      } else {
        alert('Please upload a valid image file.');
      }
    }
  }

  postCertificateSetup() {
    if(this.certificateSetupForm.valid)
      {
        if(this.is_setup_exist){
          this.certificateSetup.updateSetupCertificate(this.certificate_content,this.certificate_content.id).subscribe((data) => {
            this.modalService.open(this.completed_template,{centered:true});
          },(error:any)=>{this.toastr.info('', 'Please Enter Valid input', {positionClass: 'toast-bottom-right',})})}
        else{
          this.certificateSetup.postSetup(this.certificate_content).subscribe((data) => {
            this.modalService.open(this.completed_template,{centered:true});
          },(error:any)=>{this.toastr.info('', 'Please Enter Valid input', {positionClass: 'toast-bottom-right',})})}
      }
      else{
        this.toastr.info('', 'Please Enter Valid input', {positionClass: 'toast-bottom-right',})
      }

    }
   
    deleteShopImage(): void {
      // this.certificate_content.deleteShopImage();
      this.certificate_content.store_image=null;
      this.certificate_content.shop_image_url="";
    }
  
    deleteSubBrandImage(): void {
      // this.certificate_content.deleteSubBrandImage();
      this.certificate_content.sub_brand_image=null;
      this.certificate_content.sub_brand_image_url="";
    }

  

}
