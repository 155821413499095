import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { offlineBankAccounts } from '../models/offlinebankaccount';
import { ResponseMessage } from '../models/message';



enum AccountType{
  BANK,             //==0
  POS,              //==1
  SETTLEMENT
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

 
  constructor(private http:HttpClient) { }


  getBankAccount(type:AccountType)
  {

    return this.http.get<offlineBankAccounts>(`${environment.ip}shop/offlineBankAccounts/?type=${type}`);
  }

  addBankAcoount(fd:FormData){
    return this.http.post<ResponseMessage>(`${environment.ip}shop/offlineBankAccounts/`,fd);
  } 
  getAccountById(id:string){
    return this.http.get<offlineBankAccounts>(`${environment.ip}shop/offlineBankAccounts/${id}/`);
  
  }
  editAccountbyId(id:string,fd:FormData)
  {
    return this.http.put<ResponseMessage>(`${environment.ip}shop/offlineBankAccounts/${id}/`,fd);
  }

  // using put method
  changeStatus(id:string,fd:FormData)
  {
    return this.http.put<ResponseMessage>(`${environment.ip}shop/offlineBankAccounts/${id}/`,fd);     
  }

  // using patch method
  deleteRow(id:string)
  {
    return this.http.delete<ResponseMessage>(`${environment.ip}shop/offlineBankAccounts/${id}/`);     
  }



 
}
