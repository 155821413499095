import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor() { }

  isLoggedIn(){
    if(localStorage.getItem("logged_in")=="true"){
      return true;
    }
    return false;
  }
}
