<div class="" style="height:90vh; width:100%; overflow-y:scroll; padding-bottom:50vh;">

<div class="container-fluid master-container example-container">
  <div class="row">
    <div class="col-md-12 text-center">
      <mat-spinner
        *ngIf="(savingsDataSource.loading$ && cancelDataSource.loading$) | async"
        style="margin: 0 auto"
        mode="indeterminate"
        [diameter]="50"
      ></mat-spinner>
    </div>
  </div>


  <div class="row">
    <div class="col-md-5">
      <!-- <h2 >Search</h2> -->

      <div class="input-group">
        <mat-icon class="greyColor searchIcon">search</mat-icon>
        <input
          [disabled]="!actionsCheck(25)"
          style="border: 0"
          (keyup.enter)="searchData(); txtInput.blur()"
          [(ngModel)]="search"
          type="text"
          class="form-control search_box roundSquare text-indent"
          id="search"
          placeholder="Search plan ID/Phone"
          #txtInput
        />
        <mat-icon
          *ngIf="search"
          class="crossIcon"
          (click)="clearInput(); searchOption()"
          [ngStyle]="{ color: '#9A9AB0' }"
          >cancel</mat-icon
        >
        <button
          (click)="searchData()"
          mat-raised-button
          color="primary"
          class="searchButton"
          style="background: #4070f4; color: #ffffff"
        >
          Search
        </button>
      </div>
    </div>
    <div class="col-md-2 mt-3">
      <mat-form-field
        [style.width.%]="99"
        [style.height.%]="83"
        [style.margin-top.px]="4"
      >
        <mat-label>Payment Mode</mat-label>
        <mat-select multiple #singleSelect [disabled]="!actionsCheck(26)">
          <!-- <mat-option  > <mat-checkbox>Online</mat-checkbox> </mat-option>
          <mat-option  > <mat-checkbox>Cash</mat-checkbox> </mat-option>
          <mat-option ><mat-checkbox>Card</mat-checkbox>  </mat-option>
          <mat-option  > <mat-checkbox>UPI/IMPS</mat-checkbox></mat-option>
          <mat-option> <mat-checkbox>RTGS/NEFT</mat-checkbox></mat-option>
          <mat-option > <mat-checkbox  >Cheque</mat-checkbox> </mat-option> -->

          <mat-option
            class="dropdown_items"
            #matOption
            [disabled]="disable_online_option"
            (click)="onlineActive(matOption.selected)"
            [value]="999"
            id="online"
            >Online
          </mat-option>
          <mat-option
            class="dropdown_items"
            #matOtherOption1
            [disabled]="disable_other_filters"
            (click)="onlineDisable(matOtherOption1.selected)"
            id="cash"
            #cash
            [value]="7"
            >Cash</mat-option
          >
          <mat-option
            class="dropdown_items"
            #matOtherOption2
            [disabled]="disable_other_filters"
            (click)="onlineDisable(matOtherOption2.selected)"
            id="card"
            #card
            [value]="1"
            >Card
          </mat-option>
          <mat-option
            class="dropdown_items"
            #matOtherOption3
            [disabled]="disable_other_filters"
            (click)="onlineDisable(matOtherOption3.selected)"
            id="upi"
            #upi
            [value]="8"
          >
            UPI/IMPS</mat-option
          >
          <mat-option
            class="dropdown_items"
            #matOtherOption4
            [disabled]="disable_other_filters"
            (click)="onlineDisable(matOtherOption4.selected)"
            id="neft"
            #neft
            [value]="3"
          >
            RTGS/NEFT</mat-option
          >
          <mat-option
            class="dropdown_items"
            #matOtherOption5
            [disabled]="disable_other_filters"
            (click)="onlineDisable(matOtherOption5.selected)"
            id="cheque"
            #cheque
            [value]="6"
          >
            Cheque</mat-option
          >

          <button
            type="button"
            (click)="store(singleSelect)"
            class="btn btn-outline-primary mb-2 mt-2 ms-3"
            style="width: 85%"
          >
            Apply
          </button>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-2 mt-1">
      <mat-form-field
        [style.width.%]="100"
        [style.height.%]="70"
        class="mt-3 date1"
      >
        <mat-label>From</mat-label>
        <input
          matInput
          (click)="picker3.open()"
          [max]="current_date_validation"
          [disabled]="!actionsCheck(27)"
          [matDatepicker]="picker3"
          (dateInput)="getFromDate($event.value)"
          [(ngModel)]="fromDate"
        />
        <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-2 mt-1">
      <mat-form-field
        [style.width.%]="100"
        [style.height.%]="70"
        class="mt-3 date2"
      >
        <mat-label>To</mat-label>
        <input
          matInput
          (click)="picker4.open()"
          [max]="current_date_validation"
          [disabled]="!actionsCheck(27)"
          [matDatepicker]="picker4"
          (dateInput)="getToDate($event.value)"
          [(ngModel)]="toDate"
        />
        <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
        <mat-datepicker-toggle
          matSuffix
          [for]="picker4"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker4></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-1 mt-3">
      <div
        (click)="actionsCheck(28) ? download(download_file) : null"
        id="rcorners1"
      >
        <img
          src="assets/images/ButtonDownload.svg"
          style="width: 50px; height: 50px; cursor: pointer"
        />
      </div>
    </div>
  </div>
  <div class="cat-report mt-3">
    <div class="cat_report_tiles">
      <div *ngFor="let i of report_cat" (click)="handleTable(i)" class="list_cat" [ngClass]="i.state?'list_cat_active':''">{{i.name}}</div>
    </div>
  </div>
  <div class="col-md-12 mt-4" [class.hidden]="!report_cat[0].state">
    <div class="row tablePadding ms-2">
      <div class="col-md-12 text-center">
        <div class="mat-elevation table-responsive">
          <table mat-table [dataSource]="savingsDataSource" matSort class="">
            <!-- Position Column -->
            <ng-container matColumnDef="payment_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Payment date
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.payment_date | date : "dd/MM/yyyy" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="plan_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Plan Id</th>
              <td mat-cell *matCellDef="let element">
                {{ element.code }}
              </td>
            </ng-container>

            <!-- Name Column -->

            <!-- Weight Column -->

            <!-- Symbol Column -->
            <ng-container matColumnDef="customer_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.customer_name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Phone number
              </th>
              <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
            </ng-container>
            <ng-container matColumnDef="installments">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Installments
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.installments }}
              </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
              <td mat-cell *matCellDef="let element"> ₹{{ element.amount }}</td>
            </ng-container>
            <ng-container matColumnDef="plan_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Plan Type
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.plan_type }}
              </td>
            </ng-container>

            <ng-container matColumnDef="payment_mode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Payment mode
              </th>
              <td mat-cell *matCellDef="let element">
                {{ transformString(element.payment_mode) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="payment_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Payment Id
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.payment_id }}
              </td>
            </ng-container>


            <ng-container matColumnDef="settlement_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Settlement ID
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.settlement_id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="settlement_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Settlement Date
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.settlement_date | date : "dd/MM/yyyy"}}
              </td>
            </ng-container>

            <ng-container matColumnDef="rate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Rate/Gm</th>
              <td mat-cell *matCellDef="let element">{{ element.rate }}/g</td>
            </ng-container>

            <ng-container matColumnDef="weight">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Weight</th>
              <td mat-cell *matCellDef="let element">
                {{ element.weight | number : "1.2-3" }}
              </td>
            </ng-container>



            <ng-container matColumnDef="gst">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Gst</th>
              <td mat-cell *matCellDef="let element">{{ element.gst }}</td>
            </ng-container>

            <ng-container matColumnDef="settlement_amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Settlement Amount
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.settlement_amount }}
              </td>
            </ng-container>

            <ng-container matColumnDef="commission">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Commission
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.commission }}
              </td>
            </ng-container>





            <tr mat-header-row *matHeaderRowDef="savingsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: savingsDisplayedColumns"></tr>
          </table>

          <mat-paginator
            #savings_paginator
            style="background-color: #f5f6f8"
            [pageSizeOptions]="[popupManager.page_size_number]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 mt-4" [class.hidden]="!report_cat[1].state" >
    <div class="row tablePadding ms-2">
      <div class="col-md-12 text-center">
        <div class="mat-elevation table-responsive">
          <table mat-table [dataSource]="bookingDataSource" matSort class="">
            <!-- Position Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.date | date : "dd/MM/yyyy" }}
              </td>
            </ng-container>

            <!-- Name Column -->

            <!-- Weight Column -->

            <!-- Symbol Column -->
            <ng-container matColumnDef="booking_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Booking ID
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.booking_id }}
              </td>
            </ng-container>
            <ng-container matColumnDef="customer_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer Name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.customer_name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Phone
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.phone }}
              </td>
            </ng-container>

            <ng-container matColumnDef="weight">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Weight
              </th>
              <td mat-cell *matCellDef="let element">{{ element.weight}}/g</td>
            </ng-container>

            <ng-container matColumnDef="advance_paid">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Amount
              </th>
              <td mat-cell *matCellDef="let element">
                ₹ {{ element.advance_paid  | number : "1.2-3" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="payment_mode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Mode</th>
              <td mat-cell *matCellDef="let element">{{ transformString(element.payment_mode) }}</td>
            </ng-container>

            <ng-container matColumnDef="payment_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment ID</th>
              <td mat-cell *matCellDef="let element">{{ element.payment_id }}</td>
            </ng-container>

            <ng-container matColumnDef="settlement_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Settlement ID</th>
              <td mat-cell *matCellDef="let element">
                {{ element.settlement_id }}
              </td>
            </ng-container>
            <ng-container matColumnDef="settlement_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Settlement Date
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.settlement_date | date : "dd/MM/yyyy"  }}
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="bookingDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: bookingDisplayedColumns"></tr>
          </table>

          <mat-paginator
            #bookings_paginator
            style="background-color: #f5f6f8"
            [pageSizeOptions]="[10]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 mt-4" [class.hidden]="!report_cat[2].state" >
    <div class="row tablePadding ms-2">
      <div class="col-md-12 text-center">
        <div class="mat-elevation table-responsive">
          <table mat-table [dataSource]="customOrderDataSource" matSort class="">
            <!-- Position Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.date | date : "dd/MM/yyyy" }}
              </td>
            </ng-container>

            <!-- Name Column -->

            <!-- Weight Column -->

            <!-- Symbol Column -->
            <ng-container matColumnDef="order_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Order ID
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.order_id }}
              </td>
            </ng-container>
            <ng-container matColumnDef="customer_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer Name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.customer_name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Phone
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.phone }}
              </td>
            </ng-container>

            <ng-container matColumnDef="old_gold_wt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Weight
              </th>
              <td mat-cell *matCellDef="let element">{{ element.weight}}/g</td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Amount
              </th>
              <td mat-cell *matCellDef="let element">
                ₹ {{ element.amount  | number : "1.2-3" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="payment_mode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Mode</th>
              <td mat-cell *matCellDef="let element">{{ transformString(element.payment_mode) }}</td>
            </ng-container>

            <ng-container matColumnDef="payment_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment ID</th>
              <td mat-cell *matCellDef="let element">{{ element.payment_id }}</td>
            </ng-container>

            <ng-container matColumnDef="settlement_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Settlement ID</th>
              <td mat-cell *matCellDef="let element">
                {{ element.settlement_id }}
              </td>
            </ng-container>
            <ng-container matColumnDef="settlement_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Settlement Date
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.settlement_date | date : "dd/MM/yyyy"  }}
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="customOrderDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: customOrderDisplayedColumns"></tr>
          </table>

          <mat-paginator
            #custom_order_paginator
            style="background-color: #f5f6f8"
            [pageSizeOptions]="[10]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 mt-4" [class.hidden]="!report_cat[3].state" >
    <div class="row tablePadding ms-2">
      <div class="col-md-12 text-center">
        <div class="mat-elevation table-responsive">
          <table mat-table [dataSource]="digitalCoinDataSource" matSort class="">
            <!-- Position Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Purchase Date</th>
              <td mat-cell *matCellDef="let element">{{ element.date | date : "dd/MM/yyyy" }}</td>
            </ng-container>

            <ng-container matColumnDef="booking_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Purchase ID</th>
              <td mat-cell *matCellDef="let element">{{ element.booking_id }}</td>
            </ng-container>

            <ng-container matColumnDef="customer_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
              <td mat-cell *matCellDef="let element">{{ element.customer_name }}</td>
            </ng-container>

            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
              <td mat-cell *matCellDef="let element">{{ element.phone}}</td>
            </ng-container>

            <ng-container matColumnDef="weight">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Weight</th>
              <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
              <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
            </ng-container>

            <ng-container matColumnDef="payment_mode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Mode</th>
              <td mat-cell *matCellDef="let element"> ₹ {{ element.payment_mode }}</td>
            </ng-container>

            <ng-container matColumnDef="payment_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment ID</th>
              <td mat-cell *matCellDef="let element">{{element.payment_id}}</td>
            </ng-container>

            <ng-container matColumnDef="settlement_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Settlement ID</th>
              <td mat-cell *matCellDef="let element">{{ element.settlement_id }}</td>
            </ng-container>

            <ng-container matColumnDef="settlement_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Settlement Date</th>
              <td mat-cell *matCellDef="let element">{{ element.settlement_date | date : "dd/MM/yyyy"}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="digitalCoinDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: digitalCoinDisplayedColumns"></tr>
          </table>

          <mat-paginator #digital_coin_paginator style="background-color: #f5f6f8" [pageSizeOptions]="[10]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 mt-4" [class.hidden]="!report_cat[5].state" >
    <div class="row tablePadding ms-2">
      <div class="col-md-12 text-center">
        <div class="mat-elevation table-responsive">
          <table mat-table [dataSource]="cancelDataSource" matSort class="">
            <!-- Position Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.cancel_date | date : "dd/MM/yyyy" }}
              </td>
            </ng-container>

            <!-- Name Column -->

            <!-- Weight Column -->

            <!-- Symbol Column -->
            <ng-container matColumnDef="transaction_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Transaction ID
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.transaction_id }}
              </td>
            </ng-container>
            <ng-container matColumnDef="transaction_type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Transaction Type
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.transaction_type }}
              </td>
            </ng-container>

            <ng-container matColumnDef="customer_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer Name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.customer_name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Phone
              </th>
              <td mat-cell *matCellDef="let element">{{ element.phone}}</td>
            </ng-container>

            <ng-container matColumnDef="cancellation_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Cancellation ID
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.cancellation_id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="refund_amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Refund Amount</th>
              <td mat-cell *matCellDef="let element"> ₹ {{ element.amount_payable }}</td>
            </ng-container>

            <ng-container matColumnDef="refund_mode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Refund Mode</th>
              <td mat-cell *matCellDef="let element">   {{
                element.payment_mode == "7"
                ? "Cash"
                :  element.payment_mode== "3"
                ? "NEFT/RTGS"
                :  element.payment_mode== "4"
                ? "Online Payment"
                :  element.payment_mode == "0"
                ? "UPI/IMPS"
                :  element.payment_mode== "6"
                ? "Cheque"
                : element.payment_mode== "9"
                ? "Against Sales"
                : ""}}</td>
            </ng-container>


            <ng-container matColumnDef="refund_ac">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Refund A/C
              </th>
              <td mat-cell *matCellDef="let element">
                 {{element.offline_bank_account}}
              </td>
            </ng-container>
            <ng-container matColumnDef="refund_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Refund ID</th>
              <td mat-cell *matCellDef="let element">
                {{ element.refund_id }}
              </td>
            </ng-container>
            <ng-container matColumnDef="refund_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Refund Date
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.payment_date | date : "dd/MM/yyyy"  }}
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="cancelDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: cancelDisplayedColumns"></tr>
          </table>

          <mat-paginator
          #cancel_paginator

            style="background-color: #f5f6f8"

            [pageSizeOptions]="[10]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 mt-4" [class.hidden]="!report_cat[4].state" >
    <div class="row tablePadding ms-2">
      <div class="col-md-12 text-center">
        <div class="mat-elevation table-responsive">
          <table mat-table [dataSource]="repairOrderDataSource" matSort class="">
            <!-- Position Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.date | date : "dd/MM/yyyy" }}
              </td>
            </ng-container>

            <!-- Name Column -->

            <!-- Weight Column -->

            <!-- Symbol Column -->
            <ng-container matColumnDef="order_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Order ID
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.repair_id }}
              </td>
            </ng-container>
            <ng-container matColumnDef="customer_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer Name
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.customer_name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Phone
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.phone }}
              </td>
            </ng-container>

            <ng-container matColumnDef="old_gold_wt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Weight
              </th>
              <td mat-cell *matCellDef="let element">{{ element.weight}}/g</td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Amount
              </th>
              <td mat-cell *matCellDef="let element">
                ₹ {{ element.amount  | number : "1.2-3" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="payment_mode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Mode</th>
              <td mat-cell *matCellDef="let element">{{ transformString(element.payment_mode) }}</td>
            </ng-container>

            <ng-container matColumnDef="payment_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment ID</th>
              <td mat-cell *matCellDef="let element">{{ element.payment_id }}</td>
            </ng-container>

            <ng-container matColumnDef="settlement_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Settlement ID</th>
              <td mat-cell *matCellDef="let element">
                {{ element.settlement_id }}
              </td>
            </ng-container>
            <ng-container matColumnDef="settlement_date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Settlement Date
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.settlement_date | date : "dd/MM/yyyy"  }}
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="customOrderDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: customOrderDisplayedColumns"></tr>
          </table>

          <mat-paginator
            #custom_order_paginator
            style="background-color: #f5f6f8"
            [pageSizeOptions]="[10]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<!-- <ng-template #download_file1 let-modal>
  <div class="emailModal">
    <div class="modal-header" style="border: 0">
      <h1
        class="modal-title"
        style="
          font-family: 'Rubik';
          font-style: normal;
          font-weight: 500;
          font-size: 26px;
          margin-left: 125px;
          margin-top: 15px;
          color: #000000;
        "
      >
        Download Data
      </h1>
      <button
        type="button"
        style="outline: none"
        class="close btn"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h3
        style="
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        "
      >
        Choose Date
      </h3>
      <div class="row">
        <div class="col-sm-3">
          <mat-form-field
            [style.width.%]="220"
            appearance="outline"
            style="padding: 0; margin: 0"
          >
            <input
              matInput
              [matDatepicker]="picker"
              (dateInput)="downloadfDate($event.value)"
              placeholder="From"
            />

            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-3"></div>
        <div class="col-sm-3">
          <mat-form-field
            [style.width.%]="220"
            appearance="outline"
            style="padding: 0; margin: 0"
          >
            <input
              matInput
              [matDatepicker]="picker1"
              (dateInput)="downloadtDate($event.value)"
              placeholder="To"
            />

            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-ms-3"></div>
      </div>
      <h3
        style="
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        "
      >
        Enter Email
      </h3>
      <div class="row ms-1 me-2 form-group">
        <input
          [(ngModel)]="email"
          id="email_field"
          type="text"
          class="form-control roundSquare"
          id="plan_name"
          placeholder="Enter one or more email ID’s separated by commas"
        />
        <button
          (click)="downloadReport()"
          mat-raised-button
          color="primary"
          class="mt-5 btn-lg mb-2"
          style="height: 52px"
        >
          Send
        </button>
      </div>
    </div>
  </div>
</ng-template> -->

<ng-template #download_file let-modal>
  <div class="emailModal">
    <div class="modal-header p-3" style="border: 0">
      <h1
        class="modal-title"
        style="
          font-family: 'Rubik';
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          margin-left: 125px;
          margin-top: 15px;
          font-weight: 500;
          color: #262626;
        "
      >
        Download Data
      </h1>
      <button
        type="button"
        style="outline: none"
        class="close btn"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h3
        style="
          font-family: 'rubik';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        "
      >
        Choose Date
      </h3>
      <div class="row">
        <div class="col-sm-3">
          <mat-label class="model_labels">From</mat-label>

          <mat-form-field
            [style.width.%]="220"
            class="ms-1"
            appearance="outline"
            style="padding: 0; margin: 0"
          >
            <input
              (click)="picker.open()"
              matInput
              [max]="current_date_validation"
              [matDatepicker]="picker"
              id="fdate"
              (dateInput)="downloadfDate($event.value)"
              placeholder="dd-mm-yyyy"
            />
            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle
              matPrefix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-3"></div>
        <div class="col-sm-3">
          <mat-label class="model_labels">To</mat-label>
          <mat-form-field
            [style.width.%]="220"
            appearance="outline"
            style="padding: 0; margin: 0"
          >
            <input
              matInput
              [max]="current_date_validation"
              (click)="picker1.open()"
              [matDatepicker]="picker1"
              id="tdate"
              (dateInput)="downloadtDate($event.value)"
              placeholder="dd-mm-yyyy"
            />
            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle
              matPrefix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-ms-3"></div>
      </div>
      <div class="row ms-1 me-1 form-group">
   
        <div class="row" style="height: 310px"></div>
        <button
          (click)="downloadReport()"
          mat-raised-button
          color="primary"
          class="mt-5 btn-lg mb-2 ms-4 shad"
          style="
            height: 52px;
            background: #4070f4;
            border-radius: 10px;
            width: 90%;
          "
        >
          Download
        </button>
      </div>
    </div>
  </div>
</ng-template>
